import {Component, ElementRef, EventEmitter, Output, Type} from '@angular/core';
import {
    IwTextFieldMultiautocompleteComponent
} from '@app/sam-base/components/iw-textfield-multiautocomplete/iw-textfield-multiautocomplete.component';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';

@Component({
    selector: 'iw-multiautocomplete-select', templateUrl: './iw-multiautocomplete-select.component.html'
})
export class IwMultiautocompleteSelectComponent<T> extends IwTextFieldMultiautocompleteComponent<T> {

    public entityType?: Type<T>;

    @Output() public selectedValueChanged: EventEmitter<T | T[] | undefined> = new EventEmitter();

    constructor(elRef: ElementRef, _restService: RestApiService, _modalService: ModalService) {
        super(elRef, _restService, _modalService);
    }

    public emitSelectedValue(value?: T | T[]) {
        this.selectedValueChanged.emit(value);
    }

}
