import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {ModalComponent, RowClickEvent} from '@app/sam-base/models';
import {lastValueFrom} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ModalSelectTableOption} from './modal-select-table.model';

/**
 * Component to select a value from a table.
 *
 * @example
 * const select = await modalService.showModal(IwSelectTableComponent, {});
 */
@Component({
    templateUrl: './modal-select-table.component.html', styleUrls: ['./modal-select-table.component.scss']
})
export class IwModalSelectTableComponent<T, K> implements ModalComponent<K[], ModalSelectTableOption<T, K>>, OnInit {

    @ViewChild('restTable', {static: false}) public grid?: IwRestGridComponent<T>;

    @Output() public resSelected: EventEmitter<T[]> = new EventEmitter();

    public display = true;


    /** Select items */
    public selected: T[] = [];
    /** Modal params to display table */
    private _options?: ModalSelectTableOption<T, K>;
    private _onOk?: (result: K[]) => void;
    private _onCancel?: (error?: any) => void;

    private _loading = false;

    /** Loading state */
    public get loading() {
        return this._loading;
    }

    private _tableData: T[] = [];

    /** Items to show in table */
    public get tableData() {
        return this._tableData;
    }

    /** Used to enable/disable ok button */
    public get isOkDisabled() {
        return this.selected.length <= 0;
    }

    /** Selection mode to use in table */
    public get selectMode() {
        return (this._options && this._options.selection) || 'single';
    }

    public get hasFilter(): boolean {
        if (!this._options) {
            return false;
        }
        if (this._options.hasFilter === undefined) {
            return false;
        }
        return (this._options.hasFilter !== undefined && this._options.hasFilter);
    }

    /** Columns to show in table */
    public get columns() {
        return (this._options && this._options.columns) || [];
    }

    /** FetchAction */
    public get fetchAction() {
        return (this._options && this._options.fetchAction) || undefined;
    }

    /** FetchAction */
    public get type() {
        return (this._options && this._options.type) || undefined;
    }

    public get query() {
        return (this._options && this._options.query) || undefined;
    }

    public get size() {
        return (this._options && this._options.size) || 500;
    }

    public get innerWidth() {
        return (this._options && this._options.innerWidth) || 'auto';
    }

    /** On ok press */
    public onOkPress() {
        if (this._onOk && this._options) {
            // Map T to K
            const mapAction = this._options.map;
            const data = this.selected.filter(e => !!e)
                .map(mapAction);
            this._onOk(data);
            this.resSelected.emit(this.selected);
        }
        this.display = false;
    }

    public ngOnInit() {
        if (this.type) {
            setTimeout(() => {
                this.grid?.addParentEvents();
            });
        }
    }

    /** On cancel press */
    public onCancelPress() {
        if (this._onCancel) {
            this._onCancel();
        }
        this.display = false;
    }

    /** Called on row double click */
    public onRowDoubleCLick(event: RowClickEvent<T>) {
        this.selected = [event.row];
        this.onOkPress();
    }

    /** Set the component data */
    public setData(data: ModalSelectTableOption<T, K>): Promise<void> {
        this._options = data;
        return new Promise<void>(res => {
            setTimeout(() => {
                res();
            }, 300);
        }).then(() => this.fetchData());
    }

    /** Register ok action */
    public registerOk(action: (result: K[]) => void): void {
        this._onOk = action;
    }

    /** Register cancel action */
    public registerCancel(action: (error?: any) => void): void {
        this._onCancel = action;
    }

    /** Fetch data from fetch action and fill table */
    private fetchData() {
        if (this._options) {
            this._loading = true;
            if (!this._options.fetchAction) {
                return;
            }
            return lastValueFrom(this._options.fetchAction()
                .pipe(catchError(() => []), map(e => {
                    this._tableData = e;
                    this._loading = false;
                })));
        }
        return Promise.resolve();
    }
}
