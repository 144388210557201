import {IwRestEntity, IwRestEntityId} from 'src/app/sam-base/core/rest-api';

@IwRestEntity('glclscpt')
export class Glclscpt {
    @IwRestEntityId() public compte?: string;
    public parent?: string;
    public ctype?: string;
    public corc?: string;
    public devise?: string;
    public titre?: string;
    public solde?: number;
    public soldedebex?: number;
    public soldefinex?: number;
    public soldeoldex?: number;
    public soldedev?: number;
    public soldedevde?: number;
    public soldedevfe?: number;
    public soldedevoe?: number;
    public grp1?: string;
    public grp2?: string;
    public grp3?: string;
    public grp100?: string;
    public repdefId?: string;
    public cc?: string;
    public cenoblig?: string;
    public tvaoblig?: string;
    public cptgenre?: string;
    public regrpmode?: number;
    public orderkey?: string;
    public userId?: string;
}
