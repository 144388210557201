/** Helper functions */
import {SamUserRole} from '@sam-base/models/admin/sam-user';

function isMainEntity(entity: string): boolean {
    const mainEntities = [
        'ppemp',
        'ppcli',
        'ppclicdes'];
    return mainEntities.includes(entity);
}

function isMisAcoInd(entity: string): boolean {
    const mainEntities = [
        'ppclimis',
        'ppaco',
        'ppind'];
    return mainEntities.includes(entity);
}

function isSuivi(entity: string): boolean {
    return entity === 'ppsuivi';
}

function isSalary(entity: string): boolean {
    const salaryEntities = [
        'saemsview',
        'saems',
        'saempview',
        'saemp'];
    return salaryEntities.includes(entity);
}

function isFacture(entity: string): boolean {
    const invoiceEntities = [
        'gefacliview',
        'gefacli',
        'gecliview',
        'gecli'];
    return invoiceEntities.includes(entity);
}

/**
 * Defines the roles that have access to the contextMenus
 */
export function contextMenusRoles(entity: string): string[] {
    if (isMainEntity(entity)) {
        return [
            SamUserRole.SAM_PLACEMENT_RW,
            SamUserRole.SAM_PLACEMENT_ADMIN,
            SamUserRole.SAM_ADMIN_GOD_MODE];
    } else if (isMisAcoInd(entity)) {
        return [
            SamUserRole.SAM_PLACEMENT_RW,
            SamUserRole.SAM_PLACEMENT_ADMIN,
            SamUserRole.SAM_ADMIN_GOD_MODE];
    } else if (isSuivi(entity)) {
        return [
            SamUserRole.SAM_PLACEMENT_RW,
            SamUserRole.SAM_PLACEMENT_ADMIN,
            SamUserRole.SAM_ADMIN_GOD_MODE];
    } else if (isSalary(entity)) {
        return [
            SamUserRole.SAM_SALAIRE_ADMIN,
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_SALAIRE_COM,
            SamUserRole.SAM_SALAIRE_PRINT]
    } else if (isFacture(entity)) {
        return [
            SamUserRole.SAM_FACTURE_ADMIN,
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_FACTURE_COM,
            SamUserRole.SAM_FACTURE_PRINT
        ];
    }
    {
        return [];
    }
}

