import {Component, Type} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppshcalcmode} from '@app/sam-base/models/common/ppshcalcmode';

@Component({
    selector: 'iw-ppshcalcmode-combo-box',
    templateUrl: './calc-mode-combo-box.component.html',
    providers: [buildCustomValueProvider(CalcModeComboBoxComponent)]
})
export class CalcModeComboBoxComponent extends BaseEntityDropdown<Ppshcalcmode> {
    protected getEntityType(): Type<Ppshcalcmode> {
        return Ppshcalcmode;
    }

    protected buildLabel(e: Ppshcalcmode) {
        return e.titre || '';
    }
}
