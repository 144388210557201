import {AfterViewInit, Component, EventEmitter, OnDestroy} from '@angular/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {Ppmis} from '@app/sam-base/models/placement/ppmis';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


import {BaseEntryComponent} from '../../../base/base-entry.component';
import {FormHostData} from '../../../core/form-handler/models/form-host-data.model';
import {IwStoreService} from '../../../core/store';
import {UpdateDialogState} from '../../../core/store/actions/dialog.actions';
import * as globalActions from '../../../core/store/actions/global-form.actions';

/** Dialog to host entity forms */
@Component({
    templateUrl: './form-host-dialog.component.html', styleUrls: ['./form-host-dialog.component.scss']
})
export class FormHostDialogComponent<T> extends BaseEntryComponent implements AfterViewInit, OnDestroy {
    public focus = new EventEmitter<void>();

    public formUUID?: string;
    public formTitle = '';
    public formWidth = 500;
    public formModal = false;
    public hasSideMenu = false;
    public hasStatus = false;
    public hasInit = false;
    public fermeDisabled = false;

    public loading = true;
    private _formHostData?: FormHostData<T>;
    private _subs = new Subject();

    constructor(private readonly _store: IwStoreService, private _restService: RestApiService) {
        super();
    }

    public get className() {
        return `form-host-dialog ${this.formModal ? 'iw-modal' : ''}`;
    }

    /** Get current host data */
    public get hostData() {
        if (this._formHostData) {
            return this._formHostData;
        }
        throw Error('Host data not avaible');
    }

    public set hostData(v: FormHostData<T>) {
        this._formHostData = v;
        this.onHostDataReady();
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public onHostDataReady() {
        this.formUUID = this.hostData.state.uuid;
        this.formTitle = this.hostData.state.diagTitle;
        this.hasSideMenu = this.hostData.state.diagShowSideMenu;
        const sideMenuSize = this.hasSideMenu ? 200 : 0;
        this.formWidth = this.hostData.state.width + sideMenuSize;
        this.formModal = this.hostData.state.diagModal;
        this.hasStatus = this.hostData.state.diagShowStatus;
        this.hasInit = true;
        this.checkCreating();
    }

    public ngAfterViewInit() {
        const state = this.hostData;
        this._store.globalForm(this.uuid)
            .isLoading
            .pipe(takeUntil(this._subs))
            .subscribe(l => setTimeout(() => {
                this.loading = l;
                this.updateStoreId();
                this.checkCreating(state);
            }, 0));
        this.updateDialogState();
    }

    public onFocus() {
        this.focus.emit();
    }

    public closeDialog() {
        if (this.formUUID && !this.fermeDisabled) {
            this._store.dispatch(new globalActions.DestroyForm(this.uuid));
        }
    }

    private checkCreating(state?: FormHostData<T>) {
        const s = state ? state : this.hostData;
        if (s.state.navProfile?.entity === 'ppclimis') {
            this._restService.getEntityClient(Ppmis)
                .getById(this.hostData.state.entityId.toString())
                .subscribe((mis: Ppmis) => {
                    this.fermeDisabled = mis.creating || false;
                });
        }
    }

    private close() {
        this.reference.destroy();
    }


    private updateStoreId() {
        if (this.formUUID) {
            this._store.dispatch(new UpdateDialogState(this.formUUID));
        }
    }

    private updateDialogState() {
        // Listening for destruction
        this._store.globalForm(this.uuid)
            .available
            .pipe(takeUntil(this._subs))
            .subscribe(available => {
                if (!available) {
                    this.close();
                }
            });
    }
}
