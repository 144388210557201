import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {isRestEntity} from '@app/sam-base/core/rest-api';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {
    Ppaco,
    Ppcde,
    Ppcdf,
    Ppcli,
    Ppemp,
    Ppind,
    Ppmis,
    Ppplf,
    Pprapview,
    Ppsuivi
} from '@app/sam-base/models/placement';
import {
    acoMsgMap,
    cliMsgMap,
    cmdFixeMsgMap,
    cmdMsgMap,
    deletableEntities,
    deleteWarningMap,
    empMsgMap,
    EntityEndpoints,
    gefachdrMsgMap,
    gepaihdrMsgMap,
    indMsgMap,
    misMsgMap,
    ppplfMsgMap,
    rapMsgMap,
    saempcMsgMap,
    sagsMsgMap,
    sapardedMsgMap
} from '@app/sam-base/models/placement/entity-delete-maps';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {GepaihdrService} from '@sam-base/core/services/gepaihdr.service';
import {SapardedService} from '@sam-base/core/services/saparded.service';
import {Gefachdr} from '@sam-base/models/invoices/gefachdr';
import {Gepaihdr} from '@sam-base/models/invoices/gepaihdr';
import {Sags, Saparded} from '@sam-base/models/salary';
import {Saempc} from '@sam-base/models/salary/saempc';
import {firstValueFrom, lastValueFrom, Observable, of} from 'rxjs';

import {MsgMap} from '../../models/placement/entity-delete-maps';

export interface EntityValidationMap {
    [key: string]: () => Promise<EntityDeleteValidation>
}

export interface EntityDeleteValidation {
    canDelete: boolean;
    messages: string[];
}

@Injectable()
export class EntityDeleteService {

    private currentEntity?: any;
    private entityActionMap: EntityValidationMap = {
        ppemp: () => this.canDeleteEmp(),
        ppcli: () => this.canDeleteClient(),
        ppmis: () => this.canDeleteMission(),
        ppcde: () => this.canDeleteCommande(),
        ppcdf: () => this.canDeleteCommandeFixe(),
        ppsuivi: () => this.canDeleteSuivi(),
        ppaco: () => this.canDeleteAcompte(),
        ppind: () => this.canDeleteIndemnite(),
        pprapview: () => this.canDeleteRapport(),
        ppplf: () => this.canDeletePlacFixe(),
        sags: () => this.canDeleteGenreSalaire(),
        saempc: () => this.canDeleteSaempc(),
        saparded: () => this.canDeleteDeduction(),
        gepaihdr: () => this.canDeleteGepaihdr(),
        gefachdr: () => this.canDeleteGefachdr()
    };

    constructor(private _translate: TranslateService, private _http: HttpClient, private _modalService: ModalService,
                private _restService: RestApiService, private readonly _sapardedService: SapardedService,
                private readonly _gepaihdrService: GepaihdrService,) {
    }

    public async openEntityDeleteDialog(e?: unknown): Promise<boolean> {
        const deleteInfo: EntityDeleteValidation = await this.canDeleteEntity(e);
        const options: ModalMessageOptions = {
            message: !deleteInfo.canDelete ? deleteInfo.messages : [],
            showCancel: true,
            title: this._translate.instant('supprimer'),
            alertsMessage: !deleteInfo.canDelete ? this._translate.instant('no_delete') : undefined,
            confirmMessage: deleteInfo.canDelete ? deleteInfo.messages[0] : undefined,
            okDisabled: !deleteInfo.canDelete
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            return true;
        } catch (error) {
            return false;
        }
    }

    public async openEntitiesDeleteDialog(e?: unknown[]): Promise<boolean> {
        const options: ModalMessageOptions = {
            message: ['invoice_delete_bulk_confirm'],
            showCancel: true,
            title: this._translate.instant('supprimer'),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            return true;
        } catch (error) {
            return false;
        }
    }

    public isEntityInWarningList(e: unknown): boolean {
        if (!isRestEntity(e)) {
            return false;
        }
        return deletableEntities.includes(e.$entity);
    }

    /** Builds the validation output
     *
     * @param entity Name of the entity endpoint
     * @param id Array with the blocking messages
     * @param extraInfo Extra information to print after the warning messages
     */
    public validationOutput(entity: EntityEndpoints, messages: string[],
                            extraInfo: string | string[]): EntityDeleteValidation {
        if (messages.length) {
            return <EntityDeleteValidation>{
                canDelete: false,
                messages
            };
        }

        if (entity === 'rapport') {
            return <EntityDeleteValidation>{
                canDelete: true,
                messages: [this._translate.instant(deleteWarningMap[entity]()[0]) + ' ' + extraInfo[0] + ' ' + this._translate.instant(deleteWarningMap[entity]()[1]) + ' ' + extraInfo[1] + '. ' + this._translate.instant('delete_warning')]
            };
        } else {
            return <EntityDeleteValidation>{
                canDelete: true,
                messages: [this._translate.instant(deleteWarningMap[entity]()) + ' ' + extraInfo + ', ' + this._translate.instant('delete_warning')]
            };
        }
    }

    private async canDeleteEntity(e: unknown): Promise<EntityDeleteValidation> {
        if (!this.isEntityInWarningList(e) || !isRestEntity(e)) {
            this.currentEntity = undefined;
            return <EntityDeleteValidation>{
                canDelete: true,
                messages: []
            };
        }
        this.currentEntity = e;
        return this.entityActionMap[e.$entity]();
    }

    /** Endpoint that returns the entities blocking the chosen entity
     *
     * @param entity Name of the entity endpoint
     * @param id Id of the entity
     */
    private deleteCheck(entity: EntityEndpoints, id?: string): Observable<string[]> {
        if (!id) {
            return of([]);
        }
        return (this._http.get<string[]>(environment.backendURL + `${entity}/blocking-delete/${id}`));
    }

    /** Returns the warning messages that are preventing the entity delete
     *
     * @param be array with the warning preventing the delete
     * @param map message map containing the errors translations
     */
    private entityHasChildren(be: string[], map: MsgMap): string[] {
        return be.map(condition => this._translate.instant(map[condition]()));
    }

    private async canDeleteEmp(): Promise<EntityDeleteValidation> {
        const emp: Ppemp = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('employee', emp.empId)), empMsgMap);
        return this.validationOutput('employee', messages, `${emp.nom ?? ''} ${emp.prenom ?? ''}`);
    }

    private async canDeleteClient(): Promise<EntityDeleteValidation> {
        const cli: Ppcli = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('client', cli.cliId)), cliMsgMap);
        return this.validationOutput('client', messages, cli.nom ?? '');
    }

    private async canDeleteMission(): Promise<EntityDeleteValidation> {
        const mis: Ppmis = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('mission', mis.misId)), misMsgMap);
        return this.validationOutput('mission', messages, mis.misId ?? '');
    }

    private async canDeleteCommande(): Promise<EntityDeleteValidation> {
        const cde: Ppcde = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('commande', cde.cdeId)), cmdMsgMap);
        return this.validationOutput('commande', messages, cde.cdeId ?? '');
    }

    private async canDeleteCommandeFixe(): Promise<EntityDeleteValidation> {
        const cdf: Ppcdf = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('commandefixe', cdf.cdfId)), cmdFixeMsgMap);
        return this.validationOutput('commandefixe', messages, cdf.cdfId ?? '');
    }

    private async canDeleteAcompte(): Promise<EntityDeleteValidation> {
        const acompte: Ppaco = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('acompte', acompte.acoId)), acoMsgMap);
        const employee = await lastValueFrom(this._restService
            .getEntityClient(Ppemp)
            .getById(acompte.empId ?? ''));
        const empNom = `${employee.nom ?? ''} ${employee.prenom ?? ''}`;
        return this.validationOutput('acompte', messages, `[${acompte.montant}, ${empNom}]`);
    }

    private async canDeleteSuivi(): Promise<EntityDeleteValidation> {
        const suivi: Ppsuivi = this.currentEntity;
        return this.validationOutput('suivi', [], suivi.suiId ?? '');
    }

    private async canDeleteIndemnite(): Promise<EntityDeleteValidation> {
        const ind: Ppind = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('indemnite', ind.indId)), indMsgMap);
        const employee = await lastValueFrom(this._restService
            .getEntityClient(Ppemp)
            .getById(ind.empId ?? ''));
        const empNom = `${employee.nom ?? ''} ${employee.prenom ?? ''}`;
        return this.validationOutput('indemnite', messages, empNom);
    }

    private async canDeleteRapport(): Promise<EntityDeleteValidation> {
        const rapport: Pprapview = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('rapport', rapport.rapId)), rapMsgMap);
        const employee = await lastValueFrom(this._restService
            .getEntityClient(Ppemp)
            .getById(rapport.empId ?? ''));
        const empNom = `${employee.nom ?? ''} ${employee.prenom ?? ''}`;
        return this.validationOutput('rapport', messages, [
            `${rapport.rapId}`,
            `${empNom}`]);
    }

    private async canDeletePlacFixe(): Promise<EntityDeleteValidation> {
        const plf: Ppplf = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('ppplf', plf.plfId)), ppplfMsgMap);
        return this.validationOutput('ppplf', messages, plf.plfId ?? '');
    }

    private async canDeleteGenreSalaire(): Promise<EntityDeleteValidation> {
        const sags: Sags = this.currentEntity;
        if (!isRestEntity(sags)) {
            return <EntityDeleteValidation>{
                canDelete: true,
                messages: []
            };
        }
        const messages: string[] = this.genreSalaireHasChildren(await lastValueFrom(this.deleteCheck('sags', sags.gsId)));
        return this.validationOutput('sags', messages, sags.gsId ?? '');
    }

    private async canDeleteDeduction(): Promise<EntityDeleteValidation> {
        const saparded: Saparded = this.currentEntity;
        const canDel = await firstValueFrom(this._sapardedService.canDeleteDeduction(saparded));
        if (!isRestEntity(saparded)) {
            return <EntityDeleteValidation>{
                canDelete: true,
                messages: []
            };
        }
        let messages: string[] = [];
        if (!canDel) {
            messages = [this._translate.instant(sapardedMsgMap['default']())];
        }
        return this.validationOutput('saparded', messages, saparded.id ?? '');
    }

    private async canDeleteGepaihdr(): Promise<EntityDeleteValidation> {
        const gepaihdr: Gepaihdr = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('gepaihdr', gepaihdr.payId)), gepaihdrMsgMap);
        return this.validationOutput('gepaihdr', messages, gepaihdr.payId ?? '');
    }

    private async canDeleteGefachdr(): Promise<EntityDeleteValidation> {
        const gefachdr: Gefachdr = this.currentEntity;
        const messages: string[] = this.entityHasChildren(await lastValueFrom(this.deleteCheck('gefachdr', gefachdr.facId)), gefachdrMsgMap);
        return this.validationOutput('gefachdr', messages, gefachdr.payId ?? '');
    }

    /** Returns the warning messages that are preventing the
     * genre de salaire delete
     *
     * @param probs Problems that are blocking the genre de salaire delete
     */
    private genreSalaireHasChildren(probs: string[]): string[] {
        return probs.map(msg => this._translate.instant(sagsMsgMap[msg]()));
    }

    private async canDeleteSaempc(): Promise<EntityDeleteValidation> {
        const saempc: Saempc = this.currentEntity;
        if (!isRestEntity(saempc)) {
            return <EntityDeleteValidation>{
                canDelete: true,
                messages: []
            };
        }
        const messages: string[] = this.saempcHasChildren(await lastValueFrom(this.deleteCheck('saempc', saempc.empId)));
        return this.validationOutput('saempc', messages, saempc.empId ?? '');
    }

    /** Returns the warning messages that are preventing the
     * saempc delete
     *
     * @param probs Problems that are blocking the saempc delete
     */
    private saempcHasChildren(probs: string[]): string[] {
        return probs.map(msg => this._translate.instant(saempcMsgMap[msg]()));
    }
}
