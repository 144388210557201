import {NavigationStore} from '../models';
import {AppState} from '../reducers';

export function selectNavigation<T>(state: AppState, uuid: string): NavigationStore<T> | undefined;
export function selectNavigation(state: AppState, uuid: string): NavigationStore<any> | undefined {
    const store = state.navigation && state.navigation[uuid];
    if (store && store.uuid) {
        return store;
    }
}

export function selectNavigationId(state: AppState, uuid: string): (string | number) | undefined {
    const store = selectNavigation(state, uuid);
    if (store) {
        const pos = store.position || 0;
        return store.values[pos];
    }
}
