import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import {IwGridColumn} from '../../../../models';

export interface FilterEvent<T> {
    column: IwGridColumn<T>;
    event: MouseEvent;
    element: ElementRef;
}

@Component({
    selector: 'iw-table-header',
    templateUrl: './iw-table-header.component.html',
    styles: [':host { display: none }']
})
export class IwTableHeaderComponent<T> implements OnInit {
    @ViewChild('template', {static: true}) public template?: TemplateRef<any>;

    @Input() public hasCheckbox = false;

    @Input() public isChecked = false;

    @Input() public columns: IwGridColumn<T>[] = [];

    @Input() public showRowGrouping = false;

    @Input() public propToGroup?: Extract<keyof T, string | number>;

    @Input() public showFilter = true;

    @Input() public showSortIcon = true;

    @Input() public rawTableMode = false;

    @Output() public checkboxClick = new EventEmitter<void>();

    @Output() public filter = new EventEmitter<FilterEvent<T>>();

    constructor(private viewContainerRef: ViewContainerRef) {
    }

    public canShowSort(column: IwGridColumn<T>) {
        // before we were not letting the boolean type to be sorted. Why ? We have no idea so we removed this condition for now. Kept the old one just in case
        // return this.showSortIcon && this.getTypeColumn(column) !== 'boolean' && column.indexed !== false;
        return this.showSortIcon && column.indexed !== false;
    }

    public canShowFilter(col: IwGridColumn<T>) {
        return this.showFilter && col.indexed !== false;
    }

    public isStatusFilterActive(arr: boolean[]): boolean {
        return arr?.some(val => val);
    }

    public ngOnInit() {
        if (this.template) {
            // Render template out of host
            this.viewContainerRef.createEmbeddedView(this.template);
        }
    }

    public onHeaderCheckboxClick() {
        this.checkboxClick.emit();
    }

    public onFilter(column: IwGridColumn<T>, event: any) {
        event.stopPropagation();
        this.filter.emit({
            column,
            event,
            element: event.target
        });
    }

    private getTypeColumn(column: IwGridColumn<T>) {
        if (column) {
            return column.type !== undefined ? column.type : 'string';
        }
        return 'string';
    }
}
