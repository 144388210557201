import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('pdflist', 'common')
export class Pdflist {
    @IwRestEntityId() public pdftype?: string;
    public pdffile?: string;
    public pdfdir?: string;
    public lcustom?: boolean;
    public pdfname?: string;
    public pdfname1?: string;
    public pdfname2?: string;
    public pdfname3?: string;
    public pdfname4?: string;
    public pdfname5?: string;
    public filldata?: string;
    public notes?: string;
}
