import {IwColumn} from '@app/sam-base/core/column';
import {
    IwContextMenuClass
} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

import {ppplfContextMenu} from './context-menu/ppplf-context-menu';

@IwContextMenuClass(...ppplfContextMenu) @IwRestEntity('ppplf')
export class Ppplf {
    @IwColumn({index: 0, name: 'Id', width: 70}) @IwRestEntityId() public plfId?: string;
    @IwColumn({index: 1, hidden: true, type: 'translate', width: 70}) public plfstatustext?: string;
    @IwColumn({index: 2, type: 'date', width: 120}) public ctaDate?: string;
    @IwColumn({index: 3, hidden: true}) public ctaCliId?: string;
    @IwColumn({index: 4, name: 'client'}) public nomclient?: string;
    @IwColumn({index: 5, hidden: true}) public ctaEmpId?: string;
    @IwColumn({index: 6, name: 'employe'}) public nomemploye?: string; // Only avaible when quering from ES
    @IwColumn({index: 7}) public ctaFct?: string;
    @IwColumn({index: 8, type: 'date', width: 120}) public ctaDebut?: string;
    @IwColumn({index: 9, hidden: true}) public ageId?: string;
    @IwColumn({index: 10, hidden: true}) public conId?: string;
    @IwColumn({index: 11, hidden: true}) public secId?: string;
    @IwColumn({index: 12, hidden: true, type: 'dateTime'}) public dateCreat?: string;
    @IwColumn({index: 13, type: 'dateTime'}) public dateModif?: string;
    public plfstatus?: number;
    public empAdr1?: string;
    public empAdr2?: string;
    public empNpa?: string;
    public empLieu?: string;
    public conInitiales?: string;
    public attn?: string;
    public ctaDebtxt?: string;
    public cliattId?: string;
    public facTexte?: string;
    public conId2?: string;
    public conid2Pc?: number;
    public plffrx?: string;
    public ctaRemcli?: string;
    public facNo?: string;
    public cdfId?: string;
    public facDate?: string;
    public ctaMnt?: number;
    public dtAnyModif?: string;
    public ctaHon?: string;
    public ctaRem?: string;
    public userId?: string;
    public cliadrId?: string;
    public ctaSal?: string;
    public facMnt?: number;
    public facId?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public ppsecteur?: string;
    public ppcon?: string;
    public ppagence?: string;
    public attention?: string;

}
