import {IwColumn} from '@app/sam-base/core/column/decorators';
import {IwContextMenuClass} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwRestEntity, IwRestEntityId, SearchEntityStringOperator} from '@app/sam-base/core/rest-api';
import {Saems} from '@sam-base/models/salary/saems';
import {SaemsStatusEnum} from '@sam-base/models/salary/saems-status.enum';
import {saemsviewContextMenu} from './contextmenu/saemsview-context-menu';



export function saemsStatusColorMapper(row: Saemsview | Saems | undefined): string {
    let color: string = '#C0C0C0'
    if (row === undefined) return color;
    if (row.calculated) {
        color = '#A4CEECFF';
    }
    if (row.paid) {
        color = '#0189bf';
    }
    if (row.published) {
        color = '#10a503';
    }
    return color;
}

export function saemsStatusResolver(row: Saemsview | Saems | undefined): SaemsStatusEnum {
    if (row === undefined) return SaemsStatusEnum.GENERATED;
    let statusKey: SaemsStatusEnum = SaemsStatusEnum.GENERATED;
    if (row.calculated) {
        statusKey = SaemsStatusEnum.CALCULATED;
    }
    if (row.paid) {
        statusKey = SaemsStatusEnum.PAID;
    }
    if (row.published) {
        statusKey = SaemsStatusEnum.PUBLISHED;
    }
    return statusKey;
}

@IwContextMenuClass(...saemsviewContextMenu) @IwRestEntity('saemsview')
export class Saemsview {
    @IwRestEntityId() @IwColumn({
        name: 'id',
        index: -1,
        hidden: true
    }) public id?: string;

    @IwColumn({
        name: 'empId',
        colorMapper: saemsStatusColorMapper,
        index: 1
    }) public empId?: string;
    @IwColumn({
        name: 'nom',
        index: 2
    }) public nom?: string;
    @IwColumn({
        name: 'prenom',
        index: 3
    }) public prenom?: string;

    @IwColumn({
        name: 'periode',
        index: 4,
        type: 'salId',
        filterQuery: '',
        filterOperator: SearchEntityStringOperator.Like
    }) public salId?: string;

    @IwColumn({
        name: 'salarynet',
        align: 'right',
        index: 5,
        type: 'mnt'
    }) public salnet?: number;
    @IwColumn({
        name: 'salaryPaid',
        align: 'right',
        index: 6,
        type: 'mnt'
    }) public salpaid?: number;
    @IwColumn({
        name: 'calcule',
        index: 7,
        type: 'boolean'
    }) public calculated?: boolean;
    @IwColumn({
        name: 'paid',
        index: 8,
        type: 'boolean'
    }) public paid?: boolean;
    @IwColumn({
        name: 'published',
        index: 9,
        type: 'boolean'
    })
    public published?: boolean;

    @IwColumn({
        name: 'categorie1',
        index: 10
    }) public categorie1?: string;
    @IwColumn({
        name: 'categorie2',
        index: 11
    }) public categorie2?: string;
    @IwColumn({
        name: 'categorie3',
        index: 12
    }) public categorie3?: string;
    @IwColumn({
        name: 'sendByMail',
        index: 17,
        type: 'boolean'
    }) public sendByMail?: boolean;
    @IwColumn({
        name: 'email',
        index: 18
    }) public email?: boolean;
    @IwColumn({
        name: 'firstDay',
        type: 'date',
        index: 13
    }) public firstday?: string;
    @IwColumn({
        name: 'lastDay',
        type: 'date',
        index: 14
    }) public lastday?: string;

    @IwColumn({
        name: 'dateCreat',
        type: 'dateTime',
        index: 15,
        hidden: true
    }) public dateCreat?: string;
    @IwColumn({
        name: 'dateModif',
        type: 'dateTime',
        index: 16
    }) public dateModif?: string;
}
