<div class="row">
    <div class="col-xs-10">
        <form [formGroup]="formGroup"
              class="row">
            <div class="row col-xs-12">
                <iw-date-picker #txtDateFrom
                                [isDisabled]="!isWriteMode"
                                [labelAlign]="'top'"
                                [label]="'datedebut' | translate"
                                class="iw-input col-xs-2 no-padding"
                                formControlName="dateStart"
                                id="txtDateFrom"></iw-date-picker>
                <iw-date-picker #txtDateTo
                                [isDisabled]="!isWriteMode"
                                [labelAlign]="'top'"
                                [label]="'datefin' | translate"
                                class="iw-input col-xs-2 no-padding"
                                formControlName="dateEnd"
                                id="txtDateTo"></iw-date-picker>
            </div>

            <div *ngIf="singleEntryMode"
                 class="row col-xs-12">
                <iw-radio-list (valueChange)="onCalendarTypeChange($event)"
                               [isGroupDisabled]="!isWriteMode"
                               [radioOptions]="calendarTypeOptions"
                               [value]="calendarType"></iw-radio-list>

            </div>

            <ng-container *ngIf="singleEntryMode && calendarType === 'flexible';
         then flexibleCalendar else regularCalendar"></ng-container>

        </form>
    </div>

    <ng-container *ngIf="singleEntryMode; then buttonsTemplate"></ng-container>

</div>

<div *ngIf="!singleEntryMode"
     [style.height]="height"
     class="iw-table-edit flex flex-h">
    <iw-rest-grid #scheduleEventGrid
                  (selected)="onSelect($event)"
                  [autoFormClick]="false"
                  [hasGridColumnMenu]="false"
                  [hasSortIcon]="false"
                  [isDisabled]="isWriteMode"
                  [queryStatements]="query"
                  [type]="entity"
                  class="flex-grow padding-5"></iw-rest-grid>

    <ng-container *ngIf="!singleEntryMode; then buttonsTemplate"></ng-container>

</div>

<ng-template #buttonsTemplate>
    <div [style.margin-left]="isAvailability? '50px' : '10px'"
         class="flex-shrink flex flex-v flex-evenly padding 5">
        <iw-button (press)="onAdd()"
                   *ngIf="!isWriteMode"
                   [isDisabled]="singleEntryMode && selectedRow"
                   [text]="'sidemenu_new' | translate"
                   class="btn-block"
                   id="new"></iw-button>
        <iw-button (press)="onEdit()"
                   *ngIf="!isWriteMode"
                   [isDisabled]="btnEditDisabled"
                   [text]="'sidemenu_edit' | translate"
                   class="btn-block"
                   id="edit"></iw-button>
        <iw-button (press)="onSave()"
                   *ngIf="isWriteMode"
                   [isDisabled]="!isReady"
                   [text]="'sidemenu_save' | translate"
                   class="btn-block"
                   id="save"></iw-button>
        <iw-button (press)="onCancel()"
                   *ngIf="isWriteMode"
                   [text]="'sidemenu_cancel' | translate"
                   class="btn-block"
                   id="cancel"></iw-button>
        <iw-button (press)="onDelete()"
                   *ngIf="!isWriteMode"
                   [isDisabled]="btnDeleteDisabled || singleEntryMode"
                   [text]="'sidemenu_delete' | translate"
                   class="btn-block"
                   id="delete"></iw-button>

        <iw-button (press)="onClose()"
                   [text]="'fermer' | translate"
                   class="btn-block"
                   id="close"></iw-button>
    </div>

</ng-template>

<ng-template #flexibleCalendar
             [formGroup]="formGroup">
    <div class="col-xs-4">
        <iw-textfield #txtNoHours
                      [decimals]="0"
                      [isDisabled]="!isWriteMode"
                      [label]="'hours_week' | translate"
                      [type]="'number'"
                      class="col-xs-4"
                      formControlName="weeklyHours"></iw-textfield>
    </div>
    <div class="col-xs-6"></div>
    <div class="col-xs-4">
        <iw-textfield #txtOcupation
                      [decimals]="0"
                      [isDisabled]="!isWriteMode"
                      [label]="'ocupation_rate' | translate"
                      [type]="'number'"
                      class="col-xs-4"
                      formControlName="ocupation"></iw-textfield>
    </div>
    <div class="col-xs-6">
        <iw-textfield #txtOcupation
                      [isDisabled]="!isWriteMode"
                      [label]="'additional_text' | translate"
                      class="col-xs-6"
                      formControlName="additionalText"></iw-textfield>
    </div>
</ng-template>

<ng-template #regularCalendar
             [formGroup]="formGroup">
    <div class="col-xs-12"
         id="weekDays">
        <h4>{{ getDaysTitle() | translate }}</h4>
        <iw-weekday-picker #weekdayPicker
                           (selectionChange)="setWeekdays()"
                           [disabled]="!isWriteMode"></iw-weekday-picker>
    </div>

    <div class="col-xs-12"
         id="weekHours">
        <h4>{{ getHoursTitle() | translate }}</h4>
        <iw-time-slots #timeSlots
                       (timeSlotsUpdated)="setSchedule($event)"
                       [isAvaiability]="isAvailability"
                       [isDisabled]="!isWriteMode"
                       class="col-xs-12"></iw-time-slots>
    </div>

    <div *ngIf="!isAvailability"
         class="row col-xs-12">
        <iw-ppabscode-combo-box [isDisabled]="!isWriteMode"
                                [label]="'abs_code' | translate"
                                className="iw-input col-xs-4"
                                formControlName="absType"></iw-ppabscode-combo-box>

        <iw-entity-textfield #txtMis
                             [allowSearch]="true"
                             [entity]="entityPpclimis"
                             [filter]="filterPpclimis"
                             [isDisabled]="!isWriteMode || !isFlexMission()"
                             [label]="'mission' | translate"
                             class="iw-input col-xs-6"
                             formControlName="flexMisid"></iw-entity-textfield>
    </div>
</ng-template>
