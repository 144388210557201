import {RestPagination} from './rest-pagination.model';

export type RestResponse<T> = { page: RestPagination } & RestHalResponse<T>;

export type RestQueryResponse<T> = {
    last: boolean; totalPages: number; totalElements: number; sort: {
        sorted: boolean; unsorted: boolean; empty: boolean;
    }; numberOfElements: number; first: boolean; size: number; // eslint-disable-next-line id-blacklist
    number: number; empty: boolean;
} & RestHalResponse<T>;

export interface RestHalResponse<T> {
    _embedded?: { [key: string]: T[] | undefined };
    content?: T[];
}

/**
 * Load _embedded data from backend response
 *
 * @export
 * @template T
 * @param r
 * @param [name]
 * @returns
 */
export function ExtractContent<T>(r: RestHalResponse<T>, name?: string) {
    return (r._embedded && name && r._embedded[name]) || r.content || [];
}
