import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';

@Component({
    selector: 'iw-search-box',
    templateUrl: './iw-search-box.component.html',
    providers: [buildCustomValueProvider(IwSearchBoxComponent)]
})
export class IwSearchBoxComponent extends BaseFormControl<string> implements OnInit, OnDestroy {
    @Input() public placeholder = 'search';

    @Input() public debounce = 500; // Timeout before triggering a search

    @Output() public searchChange = new EventEmitter<string>();

    private _subs = new Subject();

    public ngOnInit() {
        this.valueChange
            .pipe(takeUntil(this._subs), debounceTime(this.debounce))
            .subscribe(ev => this.searchChange.emit(ev));
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }
}
