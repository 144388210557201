import {HttpClientPerms} from '@sam-base/core/rest-api/httpclient/http.client.perms';
import {ConfigTemplateEffects} from './config-template.effect';
import {EntitySelectEffects} from './entity-select.effect';
import {EntityEffects} from './entity.effect';
import {FormEntityEffects} from './form-entity.effect';
import {FormEffects} from './form.effect';
import {GlobalFormEffects} from './global-form.effect';
import {GridProfileEffects} from './grid-profile.effect';
import {LicenseEffects} from './license.effects';
import {LookupsEffects} from './lookups.effect';
import {NavigationEffects} from './navigation.effect';
import {SearchGridEffects} from './search-grid.effect';

export const effects: any[] = [EntityEffects, FormEntityEffects, FormEffects, GridProfileEffects, ConfigTemplateEffects, NavigationEffects, SearchGridEffects, LookupsEffects, GlobalFormEffects, EntitySelectEffects, LicenseEffects, HttpClientPerms];

export * from './entity.effect';
export * from './form-entity.effect';
export * from './form.effect';
export * from './grid-profile.effect';
export * from './navigation.effect';
export * from './search-grid.effect';
export * from './license.effects';
