import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ToastService} from '../../toast/toast.service';
import {ErrorBlock} from './error.model';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private _toast: ToastService) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(catchError((error: HttpErrorResponse) => {
                if (error.status === 417) {
                    this._toast.businessExceptionMessage(error?.error);
                } else if (error.status !== 404) {
                    this._toast.error('system_error', this.buildErrorBlock(request, error), true);
                }
                return throwError(error);
            }));
    }

    private buildErrorBlock(request: HttpRequest<any>, error: HttpErrorResponse): string {
        const errorBlock: ErrorBlock = {
            requestUrl: this.getRequestUrl(request),
            requestMethod: request.method,
            requestBody: request.body,
            responseStatus: error.status,
            errorPayload: error.error ?? error
        };
        return btoa(JSON.stringify(errorBlock));
    }

    private getRequestUrl(request: HttpRequest<any>) {
        return window.location.protocol + '//' + window.location.host + request.url;
    }
}
