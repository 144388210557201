import {Directive, EventEmitter, Input, OnDestroy, Output, Type} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {lastValueFrom, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {ValidationsService} from '../core/validations/validations.service';
import {FormKeys} from '../models';
import {BaseEntryComponent} from './base-entry.component';

function addIfValid<T>(res: T[], val: T | undefined) {
    if (val) {
        res.push(val);
    }
}

/** Class that implements the logic to update a form group */
@Directive()
export abstract class BaseFormComponent<T> extends BaseEntryComponent implements OnDestroy {

    public formGroup: UntypedFormGroup = new UntypedFormGroup({});
    @Input() public isEditable = true;
    @Output() public formValueChange = new EventEmitter<T>();
    protected formChangeSubscription = new Subscription();
    private _fieldsSubs: Subscription[] = [];

    constructor() {
        super();
        this.formSubscription();
        this.registerControls();
    }

    public get formValue(): T | undefined {
        return this.getFormData();
    }

    @Input()
    public set formValue(val: T | undefined) {
        if (val) {
            this.fillFormData(val);
        }
    }

    public ngOnDestroy() {
        this.clearSubscriptions();
        this.formChangeSubscription.unsubscribe();
    }

    public onFormChange() {
        this.formValueChange.emit(this.getFormData());
    }

    /** Get current form state */
    public getFormData(): T | undefined {
        let value = this.formGroup.value;

        if (value) {
            value = {...value};
            for (const key in value) {
                // fixme : maybe uncheck this once we better understand how the diff undefined/null handling is done in our app...
                // check other commented code (base-form-control.ts -> setValue) in case we need to un comment this one we need to uncomment the other one to keep it working
                // for reference, we comment this code in order to make the inputnumber from prime ng work
                // eslint-disable-next-line no-null/no-null
                // if (value[key] === null) {
                //     delete value[key];
                // }
            }
        }

        return value;
    }

    /**
     * Subscribe to changes to a specific key
     *
     * @param k key to subscribe
     * @param fn callback
     */
    public subscribeValueChange<TValue>(k: keyof T, fn: (e: TValue | undefined) => any, delay: number = 50) {
        if (!this.formGroup.contains(<string>k)) {
            // eslint-disable-next-line no-console
            console.warn('Cant find control for ', k);
            return;
        }

        const sub = this.formGroup.controls[<string>k].valueChanges
            .pipe(debounceTime(delay), distinctUntilChanged())
            .subscribe(fn);
        if (sub) {
            this._fieldsSubs.push(sub);
        }
        return sub;
    }

    /** Fill form controls with object */
    public fillFormData(data: T) {
        const formKeys = this.getFormControlKeys();

        for (const key of formKeys) {
            this.setFormValue(key, data[key]);
        }
    }

    public clearForm() {
        this.formChangeSubscription.unsubscribe();

        const formKeys = this.getFormControlKeys();

        for (const key of formKeys) {
            this.setFormValue(key, undefined);
        }

        this.formSubscription();
    }

    /** Get control object with key as name */
    public getFormControl(key: keyof T): AbstractControl | undefined {
        return this.formGroup.controls[<string>key];
    }

    /** Set control value with key as name */
    public setFormValue(key: keyof T, value: any) {
        const control = this.getFormControl(key);
        if (control) {
            const oldValue = control.value;
            if (control.pristine || oldValue !== value) {
                control.setValue(value);
                control.markAsDirty();
                control.updateValueAndValidity();
            }
        }
    }

    /** Get control value with key as name */
    public getFormValue(key: keyof T): any {
        const control = this.getFormControl(key);
        if (control) {
            return control.value;
        }
        return undefined;
    }

    public isFormKey(key: keyof T): boolean {
        return this.getFormControlNames()
            .indexOf(key) !== -1;
    }

    /** Compare with current value */
    // eslint-disable-next-line complexity
    public equals(value: T | undefined): boolean {
        const current = this.getFormData();
        if (!current) {
            return false;
        }
        if (!value) {
            return false;
        }
        for (const key in value) {
            if (!this.isFormKey(<keyof T>key)) {
                continue;
            }
            if (value[key] !== current[key]) {
                return false;
            }
        }
        return true;
    }

    protected getFormControlKeys(): Array<keyof T> {
        return <Array<keyof T>>Object.keys(this.formGroup.controls);
    }

    protected invalidForm(keys: FormKeys<T>): boolean {
        const controls = this.getKeyNamesFromForm(keys);
        for (const key of controls) {
            const control = this.getFormControl(key);
            if (control && control.invalid) {
                return true;
            }
        }

        return false;
    }

    /** Method to register controls */
    protected getFormControlNames(): FormKeys<T> {
        return [];
    }

    /** Validates if all fields are valid */
    protected validateFields(e: T | undefined): boolean {
        this.validateAllFields(this.formGroup);
        return this.formGroup.valid;
    }

    protected clearSubscriptions() {
        for (const sub of this._fieldsSubs) {
            if (!sub) {
                continue;
            }
            sub.unsubscribe();
        }
    }

    /** Used to allow validation for empty forms */
    protected getValidationType(): Type<T> | undefined {
        return;
    }

    /** Register a new form control */
    protected registerControl(name: keyof T, val?: UntypedFormControl | string) {
        const formControl = val instanceof UntypedFormControl ? val : new UntypedFormControl(val);

        this.formGroup.registerControl(<string>name, formControl);
    }

    protected formSubscription() {
        this.formChangeSubscription = this.formGroup.valueChanges
            .pipe(debounceTime(200))
            .subscribe(() => this.onFormChange());
    }

    /** Register control declared in inherit class */
    private registerControls() {
        const controls = this.getFormControlNames();

        for (const c of controls) {
            if (c instanceof Array) {
                this.registerControl(c[0], c[1]);
            } else {
                this.registerControl(c);
            }
        }

        // Registers the controls validators for the first time
        this.registerControlValidators(this.getKeyNamesFromControl());

    }

    /**
     * Extracts the formcontrol names from registered controls
     */
    private getKeyNamesFromControl(): (keyof T)[] {
        const formKeys: (keyof T)[] = [];
        for (const property in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(property)) {
                // Do things here
                formKeys.push(property as keyof T);
            }
        }
        return formKeys;
    }

    /**
     * Extracts the control names from the form controls declared in tab forms
     *
     * @param keys Formcontrols from which to retrieve the key names
     */
    // eslint-disable-next-line complexity
    private getKeyNamesFromForm(keys: FormKeys<T>): (keyof T)[] {
        const formKeys: (keyof T)[] = [];
        const controls = keys ?? this.formGroup.controls;
        for (const property in controls) {
            if (controls[property] instanceof Array) {
                // The formcontrol has a validator
                const key: any = controls[property];
                if (this.formGroup.controls.hasOwnProperty(<keyof T>key[0])) {
                    formKeys.push(<keyof T>key[0]);
                }
            } else {
                // The formcontrol has no validator
                if (this.formGroup.controls.hasOwnProperty(<string>controls[property])) {
                    formKeys.push(<keyof T>controls[property]);
                }
            }

        }
        return formKeys;
    }

    /** Registers controls validators gotten from backend */
    private async registerControlValidators(formKeys: (keyof T)[]) {
        const type = this.getValidationType();
        for (const k of formKeys) {
            const control = this.getFormControl(k);
            if (!control) {
                continue;
            }
            const validators = await lastValueFrom(ValidationsService.getInstance()
                .loadValidators(type, k));
            addIfValid(validators, this.getFormControlValidators(k));
            control.setValidators(validators);
            control.updateValueAndValidity();
        }
    }

    private getFormControlValidators(key: keyof T) {
        const ctrl = this.getFormControl(key);
        return ctrl?.validator ?? undefined;
    }

    private validateAllFields(form: UntypedFormGroup) {
        Object.keys(form.controls)
            .forEach(k => {
                const ctrl = form.get(k);
                if (ctrl instanceof UntypedFormControl) {
                    ctrl.markAsTouched({onlySelf: true});
                    ctrl.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false
                    });
                } else if (ctrl instanceof UntypedFormGroup) {
                    this.validateAllFields(ctrl);
                }
            });
    }
}
