import {NavigationExtras} from '@app/sam-base/models/nav-extras.model';
import {createSelector, select} from '@ngrx/store';
import {Observable} from 'rxjs';

import {Dictionary} from '../../../models/dictionary.model';
import {SearchGridState} from '../models';
import {AppState} from '../reducers';

export const selectSearchGridStore = (e: AppState) => e.searchGrid;

export function selectSearchGridState<T>(uuid: string | undefined): (source$: Observable<AppState>) => Observable<SearchGridState<T> | undefined> {
    const selector = (gridUuid: string | undefined) => createSelector(selectSearchGridStore, (searchGrid) => gridUuid ? searchGrid.profiles[gridUuid] : undefined);
    return select(selector(uuid));
}

export function selectSearchGridFilter<T>(uuid: string): (source$: Observable<AppState>) => Observable<Dictionary<string>> {
    const selector = (gridUuid: string) => createSelector(selectSearchGridStore, (gridState) => gridState.profiles[gridUuid].filter || {});
    return select(selector(uuid));
}

export function selectSearchGridWildcards<T>(uuid: string): (source$: Observable<AppState>) => Observable<Dictionary<string>> {
    const selector = (gridUuid: string) => createSelector(selectSearchGridStore, (gridState) => gridState.profiles[gridUuid].wildcards || {});
    return select(selector(uuid));
}

export function getFormNavExtras(state: AppState): NavigationExtras<any> {
    const formState = selectSearchGridStore(state);
    return formState.profiles[formState.current || ''].navExtras;
}
