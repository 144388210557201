import {Type} from '@angular/core';
import {createSelector, select} from '@ngrx/store';

import {getEntityMetadata} from '../../rest-api';
import {EntitySelectState} from '../models/entity-select.state';
import {AppState} from '../reducers';

type StateResponse<T> = EntitySelectState<T> | undefined;

export const selectEntitiesSelect = (state: AppState) => state.entitySelect;

export function selectEntitySelectState<T>(entity: Type<T> | string) {
    const $entityName = typeof entity === 'string' ? entity : getEntityMetadata(entity).$entity;

    const selector = (entityName: string) => createSelector(selectEntitiesSelect, (entitySelect) => entitySelect[entityName]);
    return select(selector($entityName));
}
