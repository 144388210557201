// ##############################################################
// ###############  Help functions to handle strings ############
// ##############################################################

/** Remove match from start and end of string ('aaTesTaa' 'a') => 'TesT' */
export function trimString(src: string, toRemove: string): string {
    return removeStart(removeEnd(src, toRemove), toRemove);

}

/** Remove match from start of string ('aaTesTaa' 'a') => 'TesTaa' */
export function removeStart(src: string, toRemove: string): string {
    if (!toRemove) {
        return src;
    }

    while (src.length > 0 && src.indexOf(toRemove) === 0) {
        src = src.substr(1) || '';
    }

    return src;
}

/** Remove match from end of string ('aaTesTaa' 'a') => 'aaTesT' */
export function removeEnd(src: string, toRemove: string): string {
    if (!toRemove) {
        return src;
    }

    while (src.length > 0 && src.lastIndexOf(toRemove) === (src.length - 1)) {
        src = src.substring(0, (src.length - 1)) || '';
    }

    return src;
}

export function stringifyIfNotString(value: any): string {
    if (typeof value === 'string') {
        return value;
    }
    return JSON.stringify(value);
}

export function returnStringValueIfNotEmpty(str?: string): string | undefined {
    // eslint-disable-next-line no-null/no-null
    return (str !== '' && str !== undefined && str !== null) ? str : undefined;
}
