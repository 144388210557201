/** Available form descriptions */
export enum PlacementForm {
    Client = 'ppcli',
    Employee = 'ppemp',
    EmployeEnf = 'ppempenf',
    EmployeConjoint = 'saempconj',
    CommandesTemporaires = 'ppcde',
    CommandeFixe = 'ppcdf',
    CommandesFixes = 'ppclicdfs',
    CommandesView = 'ppclicdes',
    ContactNew = 'ppcliatt',
    ContactEdit = 'ppcliatt_edit',
    SuiviNew = 'suivi',
    SuiviEdit = 'suivi_edit',
    AcompteReadOnly = 'ppaco',
    Missions = 'ppclimis',
    Rapport = 'pprapview',

    PeriodManagement = 'ppper',

    IndemniteReadonly = 'ppind',
    Ppmismdl = 'ppmismdl',
    Misnewdef = 'misnewdef',
    Misheuspc = 'misheuspc_update',
    Misheuspcnew = 'misheupc_create',
    Ppheusaid2def = 'ppheusaid2def',
    IndemniteCreate = 'ind_create',
    IndemniteUpdate = 'ind_update',
    Rapports = 'ppraps',
    ProfessionsConfig = 'ppqua',
    KeywordConfig = 'ppkw',
    PpgrhConfig = 'ppgrh',
    Agetime = 'agetime',

    PpsecteurConfig = 'ppsecteur',
    AgenceConfig = 'ppagence',
    PpcctConfig = 'ppcct',
    Ppdoc_Type = 'ppdoc_type',
    TextesParametrables = 'ppopttxt',
    Lookups = 'lookups',
    MisIndemniteCreate = 'misind_create',
    MisIndemniteUpdate = 'misind_update',
    ParametresGeneraux = 'pppar',
    CalendarJoursFeries = 'ppjfconfig',
    GenresIndemnites = 'ppgri',
    Conseillers = 'ppcon',
    ScheduleEvent = 'schedule_event',
    GestionModeles = 'gestion_modeles',
    GenreTravail = 'genre_travail',

    Ppheusais = 'Ppheusais',
    CdeNew = 'ppcde_new',
    CdfNew = 'ppcdf_new',
    Ppindemnite = 'ppind_new',
    Ppjfcalc = 'ppjf',
    InvoicesPreparation = 'facturation',
    InvoicesParameters = 'ppofa',
    SalaryParameters = 'ppoptsal',
    SalaryPreparation = 'salaries',
    Aginter = 'ppagi',
    Ppagiview = 'ppagiview',
    AgiCreate = 'ppagi_create',
    Ppclistats = 'ppclistats',
    Ppclicha = 'ppclicha',
    Ppplf = 'ppplf',
    PpplfNew = 'ppplf_new',
    Ppacattest = 'ppattestemp',
    PpacattestView = 'ppacattestview',
    PpacattestCreate = 'ppacattest_new',
    PpempCreate = 'ppemp_create_dialog',
    PpempCandidateCreate = 'ppemp_candidate_dialog',
    Interijob = 'Interijob_config',
    UserRoles = 'tt6user',
    UserTeams = 'userTeams',
    Payments = 'payments',
    AutoAcomptes = 'autoacomptes',
}
