import {Component, Input, Type} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppdoctype} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-doc-types-combo-box',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(DocTypesComboBoxComponent)]
})
export class DocTypesComboBoxComponent extends BaseEntityDropdown<Ppdoctype> {
    @Input() public width?: string | number;
    @Input() public type?: string;

    /** Filter to use when showing list of pdtPartype */
    public resultFilters: (e: Ppdoctype) => boolean = (e) => e ? e.pdtPartype === this.type : false;

    protected getEntityType(): Type<Ppdoctype> {
        return Ppdoctype;
    }

    protected buildLabel(e: Ppdoctype) {
        return `${e.pdtLibelle}`;
    }
}
