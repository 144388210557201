import { HttpClient } from '@angular/common/http';
import {Injectable, Type} from '@angular/core';
import {EsRequest} from '@app/sam-base/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {SortDirection} from '@app/sam-base/models';
import {
    AdvMatchingParams
} from '@app/sam-base/models/adv-matching-params';
import {EsAdvancedMatch} from '@app/sam-base/models/es-advanced-match';

@Injectable()
export class EsAdvancedMatchService<T> extends RestClient<EsAdvancedMatch> {

    constructor(_http: HttpClient) {
        super(_http);
    }

    public getEsAdvancedResults(type: Type<T>, esReq: EsRequest<T>, advMatchParams: AdvMatchingParams, sortDir?: SortDirection) {

        const esQuery = JSON.stringify(esReq);

        const body: EsAdvancedMatch = {
            esQuery, ...advMatchParams, sortDir
        };
        return this.POST<T[]>(body, undefined, '/employee/matching');
    }
}
