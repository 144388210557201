import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('satbl')
export class Satbl {
    @IwRestEntityId() public recType?: number;
    public annee?: string;
    @IwRestEntityId() public tableNo?: string;
    public elemId?: string;
    public titre?: string;
    public titre1?: string;
    public titre2?: string;
    public titre3?: string;
    public titre4?: string;
    public titre5?: string;
    public cvalue?: string;
    public nvalue1?: number;
    public nvalue2?: number;
    public userId?: string;
}
