import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam
} from '@app/sam-base/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {
    MatchingHelpers
} from '@app/sam-base/core/services/matching-helpers.service';
import {
    convertBinaryHours
} from '@app/sam-base/helpers/placement-matching';
import {
    AdvMatchingParams
} from '@app/sam-base/models/adv-matching-params';
import {Ppqua, ScheduleEvent} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {IwDateHelper} from '@sam-base/core/dates/iw-date-helper';

export interface ScheduleEventData {
    uuid: string;
    data: string;
    startDate?: string;
}

@Injectable()
export class MatchingService extends RestClient<AdvMatchingParams> {

    public scheduleEventData = new Subject<ScheduleEventData>();

    public ppquaRestClient: RestEntityClient<Ppqua>;

    constructor(public readonly _http: HttpClient, private _translate: TranslateService, private _rest: RestApiService, private _matchingHelpers: MatchingHelpers) {
        super(_http);
        this.ppquaRestClient = this._rest.getEntityClient(Ppqua);
    }

    public getProfessionNames(professionIds: string): Observable<(string | undefined)[]> {
        if (!professionIds) {
            return of([]);
        }
        const idsList = professionIds.split(';');

        return this.ppquaRestClient
            .getRefData()
            .pipe(map(e => e.filter(q => q.qualif && q.quaId && idsList.includes(q.quaId))
                .map((p => p.qualif))));
    }

    public getProfessions(entityId: string) {
        return this.GET<any>(undefined, `/ppemp/${entityId}/professions`);
    }

    public getKeywords(entityId: string) {
        return this.GET<any>(undefined, `/ppcde/${entityId}/cdeKeywords`);
    }

    public getCdeOdos(cdeId: string) {
        return this.GET<any>(undefined, `/ppodos/filter?page=0&size=1000&sort=&s=parentId;eq=${cdeId}`);
    }

    public getEmpAbsences(empId: string) {
        return this.GET<any>(undefined, `/ppemp/${empId}/absences`);
    }

    public saveOdos(params: AdvMatchingParams) {
        return this.POST(params, undefined, '/matching/odos');
    }

    public deletePpabs(absId: string) {
        return this.DELETE(undefined, `/ppabs/${absId}`);
    }

    public deletePpodos(odId: string) {
        return this.DELETE(undefined, `/ppodos/${odId}`);
    }

    public saveRegions(entityId: string, regions: string[]) {
        const body = {regions};
        return this.POST(body, undefined, `/employee/${entityId}/regions`);
    }

    public saveCdeRegions(entityId: string, regions: string[]) {
        const body = {regions};
        return this.POST(body, undefined, `/ppcde/${entityId}/regions`);
    }

    public submitAdvancedSearch(params: AdvMatchingParams) {
        return this.POST(params, undefined, '/employee/matching');
    }

    public updateNeedSummary(event: ScheduleEvent | undefined, uuid: string) {

        let needSummary: string;
        if (!event) {
            needSummary = '';
        } else {
            needSummary = this.buildNeedSummary(event);
        }

        const scheduleEventData: ScheduleEventData = {
            uuid, data: needSummary, startDate: event?.dateStart
        };

        this.scheduleEventData.next(scheduleEventData);
    }

    public getScheduleEvent(entityId: string, entityType: 'ppmis' | 'ppcde' | 'ppemp' | 'ppcdf'): Observable<ScheduleEvent[]> {

        const scheduleEventQuery: RestQueryParam<ScheduleEvent, string>[] = [{
            operation: RestQueryOperation.Equals, prop: 'parentId', value: entityId
        }, {
            operation: RestQueryOperation.Equals, prop: 'parentType', value: entityType
        }];
        return this._rest
            .getEntityQuery(ScheduleEvent, ...scheduleEventQuery)
            .scroll();
    }

    // eslint-disable-next-line complexity
    public buildNeedSummary(e: ScheduleEvent): string {
        let summary = '';

        if (!e) {
            return summary;
        }
        /* eslint-disable max-len */
        if (e.dateStart) {
            summary += `${this.translate('schedule_event_date_start')}: ${IwDateHelper.dateFormatFromString(e.dateStart, 'DD.MM.YYYY')}\n`;
        }
        if (e.dateEnd) {
            summary += `${this.translate('schedule_event_date_end')}: ${IwDateHelper.dateFormatFromString(e.dateEnd, 'DD.MM.YYYY')}\n`;
        }
        if (e.schedule && !e.flexible) {
            summary += `${this.translate('schedule_event_hours')}: ${convertBinaryHours(e.schedule)}\n`;
        }
        if (e.weekday && !e.flexible) {
            summary += `${this.translate('schedule_event_weekday')}: ${this._matchingHelpers.convertNumericWeekdays(e.weekday)}\n`;
        }
        if (e.weeklyHours && e.ocupation) {
            summary += `${this.translate('ocupation_rate')} ${e.ocupation}% ${this.translate('hours_week')} ${e.weeklyHours} ${this.translate('SE_heures')}\n`;
            if (e.ocupation !== 100) {
                summary += `${this.translate('soit')} ${this.getWeekHours(e)} ${this.translate('occupation_heures')} `;
            }
        }
        if (e.additionalText && e.flexible) {
            summary += `${e.additionalText}\n`;
        }
        return summary;
    }

    /**
     * Function to calculate the number of worked hours in a week given
     * the occupation and the number of week hours
     *
     * @param e Given schedule event
     * @returns number of worked hours
     */
    private getWeekHours(e: ScheduleEvent): number {
        if (!e.ocupation || !e.weeklyHours) {
            return 0;
        }
        return Number(((e.ocupation / 100) * e.weeklyHours).toFixed(2));
    }

    private translate(w: string) {
        return this._translate.instant(w);
    }
}
