import {IwRestEntity} from '@app/sam-base/core/rest-api';

import {Ppgrh} from './ppgrh';
import {Ppheu} from './ppheu';
import {Ppind} from './ppind';
import {Ppmis} from './ppmis';
import {Ppmisih} from './ppmisih';
import {Pprap} from './pprap';
import {Pprapihdet} from './pprapihdet';
import {ScheduleEvent} from './schedule-event';

@IwRestEntity('ppheusaid')
export class Ppheusaid {
    public rapnoref?: boolean;
    public rap?: Pprap;
    public mis?: Ppmis;
    public empName?: string;
    public clientName?: string;
    public misih?: Ppmisih[];
    public grh?: Ppgrh[];
    public heu?: Ppheu[];
    public ind?: Ppind[];
    public rapihdet?: Pprapihdet[][];
    public scheduleEvent?: ScheduleEvent;
    public chantier?: string;
}
