import {RowClickEvent} from '../models';

// TODO: refactor decorators in order to simplify process
/** Implemented by components to be loaded in table details */
export abstract class BaseColumnDetail<T> {
    public onRowDoubleClick(event: RowClickEvent<T>): void {
    }

    public abstract instaciate(row: T): void;

    public abstract getEntityName(): string;
}
