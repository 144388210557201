import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppcliatt')
export class Ppcliatt {
    @IwRestEntityId() public cliattId?: string;
    public allowsms?: string;
    @IwColumn() public prenom?: string;
    public dateCreat?: string;
    public userCreat?: string;
    public remarques?: string;
    public userDelet?: string;
    public prefixe?: string;
    public cliId?: string;
    @IwColumn() public nom?: string;
    public fax?: string;
    public cpolitesse?: string;
    public userId?: string;
    public natel?: string;
    @IwColumn({name: 'departement'}) public dept?: string;
    public dateModif?: string;
    public tel?: string;
    public nomailing?: string;
    public nomcomplet?: string;
    public dateanniv?: string;
    public categories?: string;
    public email?: string;
    public dateDelet?: string;
    @IwColumn({
        name: 'actif',
        type: 'boolean'
    })
    public actif?: string;
    public attstatus?: number;
    @IwColumn() public fonction?: string;
    public userModif?: string;
}
