<span #component>
  <p-button #item
            (onClick)="onClick($event)"
            [disabled]="isDisabled"
            [iconPos]="iconPos"
            [icon]="iconClass"
            [id]="text"
            [label]="text"
            [ngClass]="className"
            [ngStyle]="styleValue"
            [pTooltip]="tooltip"
            tooltipPosition="bottom"
            tooltipStyleClass="button-tooltip"></p-button>
</span>
