/* eslint-disable no-null/no-null */
// PRIMENG uses NULL for no date

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {parseDateToString, parseStringToDate} from '../../helpers/date';

@Component({
    selector: 'iw-date-picker',
    templateUrl: './iw-date-picker.component.html',
    providers: [buildCustomValueProvider(IwDatePickerComponent)]
})
export class IwDatePickerComponent extends BaseFormControl<string> {

    public dateFormat: string;

    @Input() public yearFrom = 1900;

    @Input() public yearTo = 2000;

    @Input() public isReadOnly = false;

    @Input() public maxDate = null;

    @Input() public minDate = null;

    @Input() public showWeekDay = false;

    // Input to show week number with date
    @Input() public showWeekNumber = false;

    /** Input to set background color week if needed */
    @Input() public bckColor = '';

    /** Input to set background color input date if needed */
    @Input() public bckClrCalendar = '';

    @Output() public selectEvent = new EventEmitter<Event>();

    public yearRange = `${this.yearFrom}:${new Date().getFullYear() + 25}`;

    private _primeValue: Date | null = null;
    private _lastValue?: string;

    constructor() {
        super();
        this.dateFormat = this.getDateFormat();
    }

    public get weekDay() {
        if (!this.showWeekDay) {
            return '';
        }

        return this.getWeekDay();
    }

    public get weekNumber() {
        if (!this.showWeekNumber) {
            return '';
        }

        return this.getWeekNumber();
    }

    public get primeNgValue() {
        return this._primeValue;
    }

    public set primeNgValue(v: Date | null) {
        if (v === this._primeValue) {
            return;
        }
        this.value = !!v ? parseDateToString(v, 'YYYY-MM-DD') : undefined;
        this._lastValue = this.value;
        this._primeValue = v;
    }

    public onSelectEvent(event: Event) {
        this.selectEvent.emit(event);
    }

    public writeValue(obj: any): void {
        this.value = obj;
    }

    public setValue(v: string | undefined) {
        super.setValue(v);
        if (v !== this._lastValue) {
            setTimeout(() => {
                this._lastValue = v;
                this._primeValue = v ? parseStringToDate(v, 'YYYY-MM-DD') || null : null;
            }, 0);
        }
    }

    private getWeekDay() {
        const date: Date | null = this.primeNgValue;

        if (!date) {
            return '';
        }

        return 'week_short_' + (date.getDay() + 1);
    }

    private getWeekNumber() {
        return IwDateHelper.getWeekNumber(this.primeNgValue, this.showWeekDay);
    }

    private getDateFormat() {
        return IwDateHelper.getDateFormat()
            .toLowerCase()
            .replace('yyyy', 'yy');
    }
}
