import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class HttpClientPerms {
    constructor(private readonly _http: HttpClient) {
    }

    public get<T>(url: string): Observable<T> {
        return this._http.get<T>(url);
    }
}
