import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FormHostDialogComponent} from '@app/sam-base/components/form-handler/dialog/form-host-dialog.component';
import {IwSideMenuComponent} from '@app/sam-base/components/form-handler/side-menu/iw-side-menu.component';
import {AccessGroupsDirective} from '@app/sam-base/core/directives/access-groups.directive';
import {ContainerDirective} from '@app/sam-base/core/directives/container.directive';
import {TranslateModule} from '@ngx-translate/core';
import {ProfileService} from '@shared/profile/profile.service';
import {BlockUIModule} from 'primeng/blockui';
import {DropdownModule} from 'primeng/dropdown';
import {MessageModule} from 'primeng/message';

import {FormComponentsModule} from './widgets/form-components/form-components.module';
import {IwComponentsModule} from './widgets/iw-components/iw-components.module';
import {ModalComponentsModule} from './widgets/modal-components/modal-components.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormComponentsModule,
        IwComponentsModule,
        ModalComponentsModule,
        DropdownModule,
        FormsModule,
        MessageModule,
        BlockUIModule],
    declarations: [
        FormHostDialogComponent,
        IwSideMenuComponent,
        AccessGroupsDirective],
    providers: [ProfileService],
    exports: [
        TranslateModule,
        FormComponentsModule,
        IwComponentsModule,
        ModalComponentsModule,
        ContainerDirective,
        AccessGroupsDirective,
        DropdownModule,
        FormsModule,
        MessageModule,
        BlockUIModule]
})
export class SharedModule {
}
