export enum SearchEntityStringOperator {
    Like = 's_lk', NotLike = 's_nlk',
}

export enum SearchEntityNumberOperator {
    GreaterThan = 'n_gt', LessThan = 'n_lt', EqualsTo = 'n_et', Between = 'd_bet', NotBetween = 'd_not_bet'
}

export enum SearchEntityDateOperator {
    Before = 'd_bef', After = 'd_af', On = 'a_on',
}

export enum SearchEntityDateTimeOperator {
    Between = 'd_bet', NotBetween = 'd_not_bet', On = 'a_on', NotOn = 'a_not_on',
}

export enum SearchEntityStatusOperator {
    NotInclude = 's_not_inc',
}

export enum SearchExactMatch {
    Equals = 's_e_match', Or = 's_o_match',
}

export const searchOperators = Object.assign({}, SearchEntityStringOperator, SearchEntityNumberOperator, SearchEntityDateOperator, SearchEntityDateTimeOperator, SearchEntityStatusOperator);

export type SearchEntityOperator =
    SearchEntityNumberOperator
    | SearchEntityStringOperator
    | SearchEntityDateTimeOperator
    | SearchEntityDateOperator
    | SearchEntityStatusOperator
    | SearchExactMatch;
