<iw-modal-form
        [btnCancel]="false"
        [btnOk]="false"
        [height]="507"
        [title]="title"
        [width]="900">
    <form
            [formGroup]="formGroup"
            class="row space-form">

        <div class="row col-xs-12">
            <iw-textfield
                #txtGrh_id
                [isDisabled]="true"
                class="iw-input col-xs-2"
                formControlName="grhId"></iw-textfield>

            <iw-grh-combo-box
                #cboGrh_id
                (selectedChange)="setGrhForms($event)"
                [grhType]="['A', 'B']"
                [isDisabled]="isEditing"
                class="iw-input col-xs-9"
                formControlName="grhName"
                id="cboGrh_id"></iw-grh-combo-box>

            <iw-textfield
                #txtGrh_type
                [isDisabled]="true"
                class="iw-input col-xs-1"
                formControlName="grhType"></iw-textfield>
        </div>
        <div class="row col-xs-12">
            <iw-dropdown
                #cboshbaseglob
                [isDisabled]="true"
                [options]="cboshbaseglobOptions"
                class="iw-input col-xs-9 col-xs-offset-2"
                formControlName="shbaseglob"></iw-dropdown>

        </div>

        <div class="row col-xs-12 padding-top-20">
            <iw-textfield
                    [isDisabled]="true"
                    [labelAlign]="'left'"
                    [label]="'salaire_base' | translate"
                    class="iw-input col-xs-4"
                    formControlName="salBase"
                    txtSal_base
                    type="number"></iw-textfield>

            <iw-shcalcmode-combo-box
                #cboShCalcMode
                (selectedChange)="setShcalmodeForms($event)"
                [(selectedValue)]="selectedCalcMode"
                [isDisabled]="true"
                class="col-xs-7 flex-start"
                formControlName="shcalcmodetitle"></iw-shcalcmode-combo-box>

            <iw-textfield
                #txtShcalcmode
                [isDisabled]="true"
                class="iw-input col-xs-1"
                formControlName="shcalcmode"></iw-textfield>

            <iw-textfield
                #txtGrh_facteurt
                [isDisabled]="true"
                [labelAlign]="'left'"
                [label]="'facteur' | translate"
                class="iw-input col-xs-4"
                formControlName="facteur"
                type="number"></iw-textfield>
        </div>
        <div class="row col-xs-12 padding-bottom-20">
            <iw-textfield
                #txtSal_base2
                [isDisabled]="true"
                [labelAlign]="'left'"
                [label]="'total' | translate"
                class="iw-input col-xs-4"
                formControlName="salBase2"
                type="number"></iw-textfield>
        </div>
        <div class="row col-xs-12 padding-bottom-20">

            <iw-textfield
                #txtShSupMode
                [isDisabled]="true"
                [label]="'apply_factor' | translate"
                class="iw-input col-xs-5 no-padding-right"
                formControlName="shsupdata"></iw-textfield>
            <iw-button #btnShsupmode
                       (press)="onBtnShupmodeClick()"
                       [isDisabled]="isEditing"
                       class="iw-button col-xs-1 no-padding-left"
                       style="margin-bottom: 4px; padding-top: 24px;"
                       text="..."></iw-button>


            <div class="col-xs-12">
                <div class="row col-xs-12">
                    <iw-checkbox
                        #chkLJF
                        [isDisabled]="!(ppparLjfh && !this.lMisHasSal)"
                        [labelAlign]="'right'"
                        [label]="'jf' | translate"
                        class="iw-input col-xs-2"
                        formControlName="ljfh"
                        style="padding-top: 38px"></iw-checkbox>

                    <iw-textfield
                        #txtMnt_jfMission
                        [isDisabled]="true"
                        [label]="'mission' | translate"
                        [value]="currentMission.mntJf"
                        class="iw-input col-xs-2"
                        labelAlign="top"
                        type="number"></iw-textfield>
                    <iw-textfield
                        #txtMnt_jf
                        [isDisabled]="!(getFormValue('ljfh') && (getFormValue('shtxmode') > 0))"
                        [label]="'genres_heures' | translate"
                        class="iw-input col-xs-2"
                        formControlName="mntJf"
                        labelAlign="top"
                        type="number"></iw-textfield>

                    <iw-textfield
                        #txtTx_jf
                        [isDisabled]="true"
                        [label]="'-'"
                        class="iw-input col-xs-2"
                        formControlName="txJf"
                        labelAlign="top"
                        suffix="%"
                        type="number"></iw-textfield>

                    <iw-checkbox
                        #chkLprov_jf
                        *ngIf="ppparLprovjfh"
                        [isDisabled]="!chkLprovJfEnabled('2A')"
                        [labelAlign]="'right'"
                        [label]="'prov' | translate"
                        class="iw-input col-xs-1"
                        formControlName="lprovJf"
                        style="padding-top: 38px"></iw-checkbox>
                    <div
                            *ngIf="!ppparLprovjfh"
                            class="col-xs-1"></div>


                    <iw-checkbox
                        #Standardlogic1
                        [isDisabled]="!chkLprovJfEnabled('12AB')"
                        [labelAlign]="'right'"
                        [label]="'nonPaye' | translate"
                        class="iw-input col-xs-3"
                        formControlName="lnopayJf"
                        style="padding-top: 38px"></iw-checkbox>
                </div>
                <div class="row col-xs-12">
                    <iw-checkbox
                        #chkL13
                        [isDisabled]="!(ppparL13h && !this.lMisHasSal)"
                        [labelAlign]="'right'"
                        [label]="'13Eme'"
                        class="iw-input col-xs-2"
                        formControlName="l13"></iw-checkbox>

                    <iw-textfield
                        #txtMnt_13Mission
                        [isDisabled]="true"
                        [value]="currentMission.mnt13"
                        class="iw-input col-xs-2"
                        type="number"></iw-textfield>

                    <iw-textfield
                        #txtMnt_13
                        [isDisabled]="!(getFormValue('l13') && (this.getFormValue('shtxmode') > 0))"
                        class="iw-input col-xs-2"
                        formControlName="mnt13"
                        type="number"></iw-textfield>

                    <iw-textfield
                        #txtTx_13
                        [isDisabled]="true"
                        class="iw-input col-xs-2"
                        formControlName="tx13"
                        suffix="%"
                        type="number"></iw-textfield>

                    <iw-checkbox
                        #Standardlogic2
                        *ngIf="ppparLprov13"
                        [isDisabled]="!chkLprov13Enabled('2A')"
                        [labelAlign]="'right'"
                        [label]="'prov' | translate"
                        class="iw-input col-xs-1"
                        formControlName="lprov13"></iw-checkbox>

                </div>

                <div class="row col-xs-12">
                    <iw-checkbox
                        #chkLvac41
                        [isDisabled]="this.lMisHasSal"
                        [label]="'vacances' | translate"
                        class="iw-input col-xs-2"
                        formControlName="lvac"></iw-checkbox>

                    <iw-textfield
                        #txtMnt_vacMission
                        [isDisabled]="true"
                        [value]="currentMission.mntVac"
                        class="iw-input col-xs-2"
                        type="number"></iw-textfield>

                    <iw-textfield
                        #txtMnt_vac
                        [isDisabled]="!(getFormValue('lvac') && (getFormValue('shtxmode') > 0))"
                        class="iw-input col-xs-2"
                        formControlName="mntVac"
                        type="number"></iw-textfield>

                    <iw-textfield
                        #txtTx_vac
                        [isDisabled]="true"
                        class="iw-input col-xs-2"
                        formControlName="txVac"
                        suffix="%"
                        type="number"></iw-textfield>

                    <iw-checkbox
                        #Standardlogic3
                        *ngIf="ppparLprovvac"
                        [isDisabled]="!chkLprovVacEnabled('2A')"
                        [labelAlign]="'right'"
                        [label]="'prov' | translate"
                        class="iw-input col-xs-1"
                        formControlName="lprovVac"></iw-checkbox>

                </div>
            </div>


        </div>

        <div class="row col-xs-12">
            <iw-textfield
                #txtMntEmp
                [isDisabled]="!txtMntEmpnCoeffCliEnabled()"
                [labelAlign]="'left'"
                [label]="'salaire_global' | translate"
                class="iw-input col-xs-4"
                formControlName="mntemp"
                type="number"></iw-textfield>

            <div class="col-xs-1"></div>
            <iw-textfield
                #txtncoeff_emp
                [isDisabled]="false"
                [labelAlign]="'left'"
                [label]="'coefficient' | translate"
                class="iw-input col-xs-4"
                decimals="3.0"
                formControlName="ncoeffEmp"
                type="number"></iw-textfield>
            <iw-button
                    (press)="calcCoeffEmp()"
                    [iconClass]="'fas fa-calculator'"
                    [tooltip]="'calculate_coeff' | translate"
                    class="col-xs-1 no-padding-left"></iw-button>

            <iw-textfield
                #txtNcoeff
                [hidden]="true"
                [isDisabled]="true"
                class="iw-input col-xs-2"
                formControlName="ncoeff"
                type="number"></iw-textfield>
        </div>
        <div class="row col-xs-12">
            <iw-textfield
                #txtMntCli
                [labelAlign]="'left'"
                [label]="'montantClient' | translate"
                class="iw-input col-xs-4"
                formControlName="mntcli"
                type="number"></iw-textfield>

            <div class="col-xs-1"></div>
            <iw-textfield
                #txtNcoeff_cli
                [hidden]="true"
                [isDisabled]="true"
                [labelAlign]="'left'"
                [label]="'coefficient' | translate"
                class="iw-input col-xs-4"
                decimals="3.0"
                formControlName="ncoeffCli"
                type="number"></iw-textfield>
        </div>


        <div class="row col-xs-12">
            <iw-textfield
                #txtLibelle
                [labelAlign]="'left'"
                [label]="'libelle' | translate"
                class="iw-input col-xs-12"
                formControlName="libelle"></iw-textfield>
        </div>
        <div class="row col-xs-12">
        </div>
        <div class="row col-xs-12 padding-top-20">
            <iw-button
                #btnCancel
                (press)="onPressCancel()"
                [text]="'annuler' | translate"
                class="iw-button col-xs-2 col-xs-offset-8"></iw-button>


            <iw-button
                #btnOk
                (press)="submitHour()"
                [isDisabled]="!btnOkEnabled()"
                [text]="'enregistrer' | translate"
                class="iw-button col-xs-2"
                id="btnOk"></iw-button>
        </div>


    </form>
</iw-modal-form>
