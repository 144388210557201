import {Component, EventEmitter} from '@angular/core';
import {IwGridColumn, ModalComponent} from '@app/sam-base/models';
import {HtmlElementStatus} from '@app/sam-base/models/html-element-status.model';
import {RappelsModel} from './../../../models/rappels.model';
import {ModalRappelsOptions} from './modal-rappels.component';


@Component({
    selector: 'iw-modal-message', templateUrl: './modal-rappels.component.html'
})
export class ModalRappelsComponent implements ModalComponent<void, ModalRappelsOptions> {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public okDisabled: HtmlElementStatus = {
        enabled: true, value: 'Ok', visible: true
    };
    public cancelShow: HtmlElementStatus = {
        enabled: true, value: 'Cancel', visible: true
    };

    public tableColumns: IwGridColumn<RappelsModel>[] = [{
        prop: 'rpsId', name: 'session', index: 1
    }, {
        prop: 'rpsDate', name: 'date', type: 'date', index: 0
    }, {
        prop: 'norappel', name: 'norappel', index: 2
    }];

    private _options!: ModalRappelsOptions;
    private _ok?: (result: void) => void;
    private _cancel?: (error?: any) => void;

    public get content() {
        return this._options.content;
    }

    public get title() {
        return this._options.title;
    }

    public get width() {
        return this._options.width;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok();
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: ModalRappelsOptions): Promise<void> {
        this._options = data;
        if (this._options.okDisabled) {
            this.okDisabled = {
                ...this.okDisabled, enabled: false, visible: true
            };
        }

        if (!this._options.showCancel) {
            this.cancelShow = {
                ...this.cancelShow, enabled: false, visible: false
            };
        }
    }

    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }
}
