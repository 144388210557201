import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Type} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {BaseStoreFormComponent} from '@sam-base/base';
import {
    EntityCreate,
    EntityUpdate,
    IwStoreService,
    ModalService,
    RestApiService,
    RestEntityClient
} from '@sam-base/core';
import {ComboboxItem, FormKeys, ModalComponent, Ppshcalcmode} from '@sam-base/models';
import {Ppgrh, Ppmis, Ppmisih} from '@sam-base/models/placement';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import lodash from 'lodash';
import {forkJoin, lastValueFrom, Observable, of} from 'rxjs';

import {
    CalcCoeffCliInput,
    CalcCoeffCliOutput,
    CalcCoeffEmpInput,
    CalcCoeffEmpOutput,
    CalcCoeffInput,
    CalcCoeffOutput,
    CalcSalaireInput,
    CalcSalaireOutput,
    CalcShsupdataInput,
    CalcShsupdataOutput,
} from './misheu-calculations';
import {MisheuOptions} from './misheu-options.model';
import {Misheu} from './misheu.model';

@Component({
    templateUrl: './misheu.component.html',
})
export class MisheuComponent extends BaseStoreFormComponent<Misheu> implements ModalComponent<Ppmis, MisheuOptions>, OnInit {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public ppparLjfh = false;
    public ppparLprovjfh = false;
    public ppparL13h = false;
    public ppparLprov13 = false;
    public ppparLprovvac = false;
    public ppparCanSaisieSalByMnt = false;
    public isAdding ? = true;
    public isEditing ? = false;
    public lMisHasSal ? = false;
    public shtxmode = false;
    public readonly listOption: ModalSelectTableOption<ComboboxItem<number>, number>;
    public selectedCalcMode: Ppshcalcmode = {};
    public cboshbaseglobOptions: ComboboxItem<number>[] =
        [
            {
                name: this._translate.instant('saisieGlobal'),
                value: 1
            },
            {
                name: this._translate.instant('saisieBase'),
                value: 2
            },
        ];
    public cboShsupmodeOptions: ComboboxItem<number>[] =
        [
            {
                name: 'Base',
                value: 1
            },
            {
                name: 'B+V',
                value: 2
            },
            {
                name: 'B+13',
                value: 3
            },
            {
                name: 'B+V+13',
                value: 4
            },
            {
                name: 'B+JF',
                value: 5
            },
            {
                name: 'B+JF+V',
                value: 6
            },
            {
                name: 'B+JF+13',
                value: 7
            },
            {
                name: 'Global',
                value: 8
            },
            {
                name: 'CCTLS (13e s/base)',
                value: 9
            },
            {
                name: 'CCTLS (13e selon mission)',
                value: 10
            },
        ];
    public currentMission: Ppmis = {};
    private _options = new MisheuOptions();
    private _ok?: (result: Ppmisih) => void;
    private _cancel?: (error?: any) => void;
    private pauseCalcSal = true;
    private pauseCalcCoeffs = true;
    private pauseCalcShsup = true;
    private readonly _ppshcalcmodeRest: RestEntityClient<Ppshcalcmode>;
    private readonly _ppmisihRest: RestEntityClient<Ppmisih>;

    constructor(
        store: IwStoreService,
        private _translate: TranslateService,
        _restService: RestApiService,
        private _parametersService: ParametersService,
        private http: HttpClient,
        private readonly _modalService: ModalService,
    ) {
        super(store);
        this._ppshcalcmodeRest = _restService.getEntityClient(Ppshcalcmode);
        this._ppmisihRest = _restService.getEntityClient(Ppmisih);
        this.listOption = {
            columns: [{prop: 'name'}],
            map: (e: ComboboxItem<number>) => e.value,
            selection: 'single',
        };

    }

    public get title() {
        return this._options.title;
    }

    // eslint-disable-next-line complexity
    public ngOnInit() {
        this.isEditing = this._options.isEditing;
        this.isAdding = this._options.isAdding;
        this.lMisHasSal = this._options.lMisHasSal;

        const dt: Ppmis | undefined = this._options.ppmis;

        if (dt && this.isAdding) {
            this.currentMission = dt;
            this.setBaseMisih(dt);
        }

        forkJoin(
            [
                this._parametersService.getParameterValue('ljfh', false),
                this._parametersService.getParameterValue('lprovjfh', false),
                this._parametersService.getParameterValue('l13H', false),
                this._parametersService.getParameterValue('lprov13', false),
                this._parametersService.getParameterValue('lprovvac', false),
                this._parametersService.getDataText('ppData')],
        ).subscribe(
            // eslint-disable-next-line complexity
            (data: any) => {
                this.ppparLjfh = (data[0] ?? true) as boolean;
                this.ppparLprovjfh = (data[1] ?? true) as boolean;
                this.ppparL13h = (data[2] ?? true) as boolean;
                this.ppparLprov13 = (data[3] ?? true) as boolean;
                this.ppparLprovvac = (data[4] ?? true) as boolean;
                if (data[5].MISALLOWMISSALMODE === '1') {
                    this.ppparCanSaisieSalByMnt = true;
                } else {
                    this.ppparCanSaisieSalByMnt = false;
                }
            });

        // eslint-disable-next-line complexity
        if (dt && this.isEditing) {
            this.currentMission = dt;
            const mishhview: Ppmisih | undefined = this._options.ppmisih;

            if (!mishhview) {
                return;
            }
            if (!mishhview.misihId) {
                return;
            }

            this._ppmisihRest
                .getById(mishhview.misihId.toString()).subscribe((misih: Ppmisih) => {
                this.setEditedMisih(misih);
            });
        }
    }

    public async setData(data: MisheuOptions): Promise<void> {
        this._options = data;
    }

    public setShtxmode(mode: boolean) {
        if (mode) {
            this.setFormValue('shtxmode', 1);
            this.shtxmode = true;
        } else {
            this.setFormValue('shtxmode', 0);
            this.shtxmode = false;
        }
    }

    public chkShTxModeEnabled() {
        if (!this.currentMission.missalmode) {
            return false;
        }
        return (this.currentMission.missalmode <= 1 &&
            this.getFormValue('shbaseglob') > 1 &&
            !this.lMisHasSal &&
            this.ppparCanSaisieSalByMnt);
    }

    public chkLprovJfEnabled(grhTypes: string): boolean {
        return (
            this.getFormValue('ljfh') &&
            !this.lMisHasSal &&
            grhTypes.includes(this.getFormValue('grhType'))
        );
    }

    public chkLprov13Enabled(grhTypes: string): boolean {
        return (
            this.getFormValue('l13') &&
            !this.lMisHasSal &&
            grhTypes.includes(this.getFormValue('grhType'))
        );
    }

    public chkLprovVacEnabled(grhTypes: string): boolean {
        return (
            this.getFormValue('lvac') &&
            !this.lMisHasSal &&
            grhTypes.includes(this.getFormValue('grhType'))
        );
    }

    public setGrhForms(e: Ppgrh) {
        if (!e) {
            return;
        }
        this.setFormValue('grhId', e.grhId);
        this.setFormValue('grhType', e.grhType);
        this.setFormValue('facteur', e.facteur);
        this.setFormValue('libelle', e.grhName);
        this.setFormValue('salBase2',
            (e.facteur ?? 1) * this.getFormValue('salBase'));
        this.calcSalaire();
    }

    public setShcalmodeForms(e: Ppshcalcmode) {
        if (!e) {
            return;
        }
        this.setFormValue('shcalcmode', e.shcalcmode);
        this.calcSalaire();
    }

    public txtMntEmpnCoeffCliEnabled() {
        return this.getFormValue('shbaseglob') <= 1;
    }

    public btnOkEnabled() {
        return (
            this.getFormValue('grhName') &&
            this.getFormValue('shcalcmode') &&
            this.getFormValue('shsupmode'));
    }

    public ppparLprovValidation() {
        if (!this.getFormValue('l13')) {
            this.setFormValue('tx13', 0);
            this.calcSalaire();
        }
    }

    // eslint-disable-next-line complexity
    public async calcSalaire() {
        if (this.pauseCalcSal
            || !this.getFormValue('grhType')
            || !this.getFormValue('shsupdata')) {
            return;
        }
        this.pauseCalcSal = true;
        const salary: CalcSalaireOutput = await lastValueFrom(this.CalculateSalary());

        this.setFormValue('mntemp', this.valueNotEmpty(salary.mntemp) ??
            this.getFormValue('mntemp'));
        this.setFormValue('mntJf',
            this.valueNotEmpty(salary.mntJf) ?? this.getFormValue('mntJf'));
        this.setFormValue('mnt13',
            this.valueNotEmpty(salary.mnt13) ?? this.getFormValue('mnt13'));
        this.setFormValue('mntVac',
            this.valueNotEmpty(salary.mntVac) ?? this.getFormValue('mntVac'));
        this.pauseCalcSal = false;
    }

    public async calcCoeff() {
        if (this.pauseCalcCoeffs) {
            return;
        }
        this.pauseCalcCoeffs = true;
        const coeff: CalcCoeffOutput = await lastValueFrom(this.CalculateCoeff());
        this.setFormValue('ncoeff', coeff.ncoeff);
        this.setFormValue('ncoeffCli', coeff.ncoeffCli);
        this.setFormValue('ncoeffEmp', coeff.ncoeffEmp);
        this.pauseCalcCoeffs = false;
    }

    public async calcCoeffEmp() {
        if (this.pauseCalcShsup) {
            return;
        }
        this.pauseCalcShsup = true;
        const coeff: CalcCoeffEmpOutput = await lastValueFrom(this.CalculateCoeffEmp());
        this.setFormValue('ncoeff', this.valueNotEmpty(coeff.ncoeff));
        this.setFormValue('ncoeffCli', this.valueNotEmpty(coeff.ncoeffCli));
        this.setFormValue('ncoeffEmp', this.valueNotEmpty(coeff.ncoeffEmp));
        this.setFormValue('mntcli', this.valueNotEmpty(coeff.mntcli));
        this.pauseCalcShsup = false;
    }

    public async calcCoeffCli() {
        if (this.pauseCalcCoeffs) {
            return;
        }
        this.pauseCalcCoeffs = true;
        const coeff: CalcCoeffCliOutput = await lastValueFrom(this.CalculateCoeffCli());
        this.setFormValue('ncoeff', this.valueNotEmpty(coeff.ncoeff));
        this.setFormValue('ncoeffCli', this.valueNotEmpty(coeff.ncoeffCli));
        this.setFormValue('ncoeffEmp', this.valueNotEmpty(coeff.ncoeffEmp));
        this.setFormValue('mntemp', this.valueNotEmpty(coeff.mntemp));
        this.pauseCalcCoeffs = false;
    }

    // eslint-disable-next-line complexity
    public async calcShsupdata() {
        if (this.pauseCalcShsup) {
            return;
        }
        this.pauseCalcShsup = true;
        const shsupdata: CalcShsupdataOutput =
            await lastValueFrom(this.CalculateShsupdata());
        this.setFormValue('shsupdata', this.valueNotEmpty(shsupdata.shsupdata));
        this.setFormValue('lnopay13', this.valueNotEmpty(shsupdata.lnopay13) ??
            this.getFormValue('lnopay13'));
        this.setFormValue('lnopayVac', this.valueNotEmpty(shsupdata.lnopayVac) ??
            this.getFormValue('lnopayVac'));
        this.setFormValue('lnopayJf', this.valueNotEmpty(shsupdata.lnopayJf) ??
            this.getFormValue('lnopayJf'));
        this.setFormValue('l13', this.valueNotEmpty(shsupdata.l13) ??
            this.getFormValue('l13'));
        this.setFormValue('ljfh', this.valueNotEmpty(shsupdata.ljfh) ??
            this.getFormValue('ljfh'));
        this.setFormValue('lvac', this.valueNotEmpty(shsupdata.lvac) ??
            this.getFormValue('lvac'));
        this.setFormValue('txJf', this.valueNotEmpty(shsupdata.txJf) ??
            this.getFormValue('txJf'));
        this.setFormValue('txVac', this.valueNotEmpty(shsupdata.txVac) ??
            this.getFormValue('txVac'));
        this.pauseCalcShsup = false;
    }

    public submitHour() {
        let ppmisih: Ppmisih = new Ppmisih();
        if (this.isAdding) {
            ppmisih = this.buildPpmisih('add');
            this._store.dispatch(new EntityCreate<Ppmisih>(ppmisih));
        } else if (this.isEditing) {
            ppmisih = this.buildPpmisih('edit');
            this._store.dispatch(new EntityUpdate<Ppmisih>(ppmisih));
        }
        this.onPressOk(ppmisih);
    }

    public onPressOk(misih: Ppmisih) {
        if (this._ok) {
            this._ok(misih);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public registerOk(action: (result: Ppmis) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public async onBtnShupmodeClick() {
        if (!this.cboShsupmodeOptions) {
            return;
        }
        // eslint-disable-next-line max-len
        const comp: Type<ModalComponent<number[], ComboboxItem<number>[]>> = <any>IwModalSelectTableComponent;
        try {
            this.listOption.fetchAction = () => of(this.cboShsupmodeOptions);
            const data = await this._modalService
                .showModal<number[], ComboboxItem<number>[]>(comp, <any>this.listOption);
            this.setFormValue('shsupmode', data[0]);
        } catch {
        }

    }

    protected getFormControlNames(): FormKeys<Misheu> {
        return [
            'grhId',
            [
                'grhName',
                new FormControl(undefined, Validators.required)],
            'grhType',
            'shbaseglob',
            'shtxmode',
            'salBase',
            'salBase2',
            'shcalcmodetitle',
            [
                'shcalcmode',
                new FormControl(undefined, Validators.required)],
            'facteur',
            'fact2Vj13',
            'shsupmode',
            'shsupdata',
            'ljfh',
            'mntJf',
            'txJf',
            'lprovJf',
            'lnopayJf',
            'basJf',
            'l13',
            'mnt13',
            'tx13',
            'lprov13',
            'lnopay13',
            'bas13',
            'lvac',
            'mntVac',
            'txVac',
            'lprovVac',
            'lnopayVac',
            'basVac',
            'mntemp',
            'ncoeffEmp',
            'ncoeff',
            'mntcli',
            'ncoeffCli',
            'libelle',
            'notes'];
    }

    private buildPpmisih(mode: 'add' | 'edit'): Ppmisih {
        let ppmisih: Ppmisih = new Ppmisih();
        if (mode === 'add') {
            ppmisih = lodash.cloneDeep(ppmisih);
            ppmisih.misId = this.currentMission.misId;
            ppmisih.cid = this.currentMission.misId;
            ppmisih.cdeormis = 'M';
            ppmisih.indorheu = 'H';
            ppmisih.saisiemode = 2;
            ppmisih.grhId = this.getFormValue('grhId');
        } else {
            ppmisih = lodash.cloneDeep(this._options.ppmisih ?? new Ppmisih());
        }
        ppmisih.grhId = this.getFormValue('grhId');
        ppmisih.salBase = this.getFormValue('salBase');
        ppmisih.mntJf = this.getFormValue('mntJf');
        ppmisih.mntVac = this.getFormValue('mntVac');
        ppmisih.mnt13 = this.getFormValue('mnt13');
        ppmisih.txJf = this.getFormValue('txJf');
        ppmisih.txVac = this.getFormValue('txVac');
        ppmisih.tx13 = this.getFormValue('tx13');
        ppmisih.ljfh = this.getFormValue('ljfh');
        ppmisih.lvac = this.getFormValue('lvac');
        ppmisih.l13 = this.getFormValue('l13');
        ppmisih.lprovJf = this.getFormValue('lprovJf');
        ppmisih.lprovVac = this.getFormValue('lprovVac');
        ppmisih.lprov13 = this.getFormValue('lprov13');
        ppmisih.shcalcmode = this.getFormValue('shcalcmode');
        ppmisih.shsupmode = this.getFormValue('shsupmode');
        ppmisih.mntemp = this.getFormValue('mntemp');
        ppmisih.mntcli = this.getFormValue('mntcli');
        ppmisih.lnopay13 = this.getFormValue('lnopay13');
        ppmisih.lnopayJf = this.getFormValue('lnopayJf');
        ppmisih.lnopayVac = this.getFormValue('lnopayVac');
        ppmisih.libelle = this.getFormValue('libelle');
        ppmisih.notes = this.getFormValue('notes');
        ppmisih.shbaseglob = this.getFormValue('shbaseglob');
        ppmisih.shtxmode = this.getFormValue('shtxmode');
        ppmisih.facteur = this.getFormValue('facteur');
        ppmisih.fact2Vj13 = this.getFormValue('fact2Vj13');

        return ppmisih;
    }

    // eslint-disable-next-line complexity
    private CalculateSalary():
        Observable<CalcSalaireOutput> {
        const salaryInput: CalcSalaireInput = {
            shcalcmode: Number(this.getFormValue('shcalcmode')) ?? 0,
            misSalBase: this.currentMission.salBase ?? 0,
            misMnt13: this.currentMission.mnt13 ?? 0,
            grhType: this.getFormValue('grhType'),
            shsupdata: this.getFormValue('shsupdata'),
            shsupmode: Number(this.getFormValue('shsupmode')) ?? 0,
            txJf: Number(this.getFormValue('txJf')) ?? 0,
            tx13: Number(this.getFormValue('tx13')) ?? 0,
            txVac: Number(this.getFormValue('txVac')) ?? 0,
            shtxmode: Number(this.getFormValue('shtxmode')) ?? 0,
            fact2Vj13: this.getFormValue('fact2Vj13'),
            facteur: Number(this.getFormValue('facteur')) ?? 0,
            lnopayVac: this.getFormValue('lnopayVac'),
            lnopay13: this.getFormValue('lnopay13'),
            lnopayJf: this.getFormValue('lnopayJf'),
            mntJf: Number(this.getFormValue('mntJf')) ?? 0,
            mnt13: Number(this.getFormValue('mnt13')) ?? 0,
            mntVac: Number(this.getFormValue('mntVac')) ?? 0,
            ljfh: this.getFormValue('ljfh'),
            l13: this.getFormValue('l13'),
            lvac: this.getFormValue('lvac'),
        };
        return this.http.post<CalcSalaireOutput>(
            environment.backendURL + `mission/hours/calculations/salary`,
            salaryInput,
        );
    }

    private CalculateCoeff():
        Observable<CalcCoeffOutput> {
        const coeffInput: CalcCoeffInput = {
            mntemp: Number(this.getFormValue('mntemp')) ?? 0,
            mntcli: Number(this.getFormValue('mntcli')) ?? 0,
        };
        return this.http.post<CalcCoeffOutput>(
            environment.backendURL + `mission/hours/calculations/coeff`,
            coeffInput,
        );
    }

    private CalculateCoeffCli():
        Observable<CalcCoeffCliOutput> {
        const coeffCliInput: CalcCoeffCliInput = {
            ncoeff: Number(this.getFormValue('ncoeff')) ?? 0,
            mntemp: Number(this.getFormValue('mntemp')) ?? 0,
            mntcli: Number(this.getFormValue('mntcli')) ?? 0,
            ncoeffCli: Number(this.getFormValue('ncoeffCli')) ?? 0,
        };
        return this.http.post<CalcCoeffCliOutput>(
            environment.backendURL + `mission/hours/calculations/coeffcli`,
            coeffCliInput,
        );
    }

    private CalculateCoeffEmp():
        Observable<CalcCoeffEmpOutput> {
        const coeffEmpInput: CalcCoeffEmpInput = {
            ncoeff: Number(this.getFormValue('ncoeff')) ?? 0,
            mntemp: Number(this.getFormValue('mntemp')) ?? 0,
            mntcli: Number(this.getFormValue('mntcli')) ?? 0,
            ncoeffEmp: Number(this.getFormValue('ncoeffEmp')) ?? 0,
        };
        return this.http.post<CalcCoeffEmpOutput>(
            environment.backendURL + `mission/hours/calculations/coeffemp`,
            coeffEmpInput,
        );
    }

    // eslint-disable-next-line complexity
    private CalculateShsupdata():
        Observable<CalcShsupdataOutput> {
        const coeffEmpInput: CalcShsupdataInput = {
            shsupmode: Number(this.getFormValue('shsupmode')) ?? 0,
            txJf: Number(this.getFormValue('txJf')) ?? 0,
            txVac: Number(this.getFormValue('txVac')) ?? 0,
            mistxJf: this.currentMission.txJf ?? 0,
            mistxVac: this.currentMission.txVac ?? 0,
            isAdding: this.isAdding,
        };
        return this.http.post<CalcShsupdataOutput>(
            environment.backendURL + `mission/hours/calculations/shsupdata`,
            coeffEmpInput,
        );
    }

    private async subscribeParams() {
        this.pauseCalcSal = false;
        this.pauseCalcCoeffs = false;
        this.pauseCalcShsup = false;
        await this.calcShsupdata();
        await this.calcSalaire();
        await this.calcCoeff();
        this.subscribeValueChange('fact2Vj13', () => {
            this.calcSalaire();
            this.calcCoeff();
        }, 500);
        this.subscribeValueChange('salBase', () => {
            this.calcSalaire();
            this.calcCoeff();
        }, 500);
        this.subscribeValueChange('shsupmode', () => {
            this.calcCoeff();
            this.calcShsupdata();
        }, 500);
        this.subscribeValueChange('shsupdata', () => this.calcSalaire(), 500);
        this.subscribeValueChange('mntJf', () => this.calcSalaire(), 500);
        this.subscribeValueChange('lnopayJf', () => this.calcSalaire(), 500);
        this.subscribeValueChange('mnt13', () => this.calcSalaire(), 500);
        this.subscribeValueChange('lnopay13', () => this.calcSalaire(), 500);
        this.subscribeValueChange('mntVac', () => this.calcSalaire(), 500);
        this.subscribeValueChange('lnopayVac', () => this.calcSalaire(), 500);
        this.subscribeValueChange('mntemp', () => {
            this.calcSalaire();
            this.calcCoeff();
        }, 500);
        this.subscribeValueChange('mntcli', () => this.calcCoeff(), 500);
        this.subscribeValueChange('shtxmode', (value?: boolean) => {
            this.setShtxmode(value ?? false);
            this.calcSalaire();
        }, 500);
        this.subscribeValueChange('shbaseglob', (value?: number) => {
            if (value && value < 2) {
                this.setShtxmode(false);
            }
            this.calcSalaire();
        }, 500);
        this.subscribeValueChange('facteur', () => this.calcSalaire(), 500);
        this.subscribeValueChange('shcalcmode', () => this.calcSalaire(), 500);
        this.subscribeValueChange('l13', (value?: boolean) => this.calcSalaire(), 500);
        this.subscribeValueChange('ljfh', (value?: boolean) => this.calcSalaire(), 500);
        this.subscribeValueChange('lprovJf', () => {
            this.ppparLprovValidation();
        }, 500);
        this.subscribeValueChange('lvac', (value?: boolean) => {
            this.calcSalaire();
        }, 500);
        this.subscribeValueChange('lprov13', () => {
            this.ppparLprovValidation();
        }, 500);
        this.subscribeValueChange('lprovVac', () => {
            this.ppparLprovValidation();
        }, 500);
    }

    private setBaseMisih(mis: Ppmis) {
        this.setFormValue('salBase', mis.salBase);
        this.setFormValue('mntJf', mis.mntJf);
        this.setFormValue('mntVac', mis.mntVac);
        this.setFormValue('mnt13', mis.mnt13);
        this.setFormValue('txJf', mis.txJf);
        this.setFormValue('txVac', mis.txVac);
        this.setFormValue('tx13', mis.tx13);
        this.setFormValue('ljfh', mis.ljfh);
        this.setFormValue('lvac', mis.lvac);
        this.setFormValue('l13', mis.l13);
        this.setFormValue('lprovJf', mis.lprovJf);
        this.setFormValue('lprovVac', mis.lprovVac);
        this.setFormValue('lprov13', mis.lprov13);
        this.setFormValue('shcalcmode', mis.shcalcmode);
        this.setFormValue('shsupmode', mis.shsupmode);
        this.setFormValue('shtxmode', mis.shtxmode);
        this.shtxmode = mis.shtxmode === 1;
        this.setFormValue('mntemp', 0);
        this.setFormValue('mntcli', 0);
        this.setFormValue('facteur', 0);
        this.setFormValue('ncoeffCli', 0);
        this.setFormValue('ncoeffEmp', 0);
        this.setFormValue('ncoeff', 0);
        this.setFormValue('salBase2', 0);
        this.setFormValue('shbaseglob', 2);
        this.setFormValue('fact2Vj13', true);

        if (mis.shcalcmode) {
            this._ppshcalcmodeRest.getById(mis.shcalcmode.toString())
                .subscribe((calcmode: Ppshcalcmode) => {
                    this.setFormValue('shcalcmodetitle', mis.shcalcmode);
                    this.selectedCalcMode = calcmode;
                    this.subscribeParams();
                });
        } else {
            this.subscribeParams();
        }
    }

    private setEditedMisih(misih: Ppmisih) {
        this.setFormValue('grhName', misih.grhId);
        this.setFormValue('salBase', misih.salBase);
        this.setFormValue('mntJf', misih.mntJf);
        this.setFormValue('mntVac', misih.mntVac);
        this.setFormValue('mnt13', misih.mnt13);
        this.setFormValue('txJf', misih.txJf);
        this.setFormValue('txVac', misih.txVac);
        this.setFormValue('tx13', misih.tx13);
        this.setFormValue('ljfh', misih.ljfh);
        this.setFormValue('lvac', misih.lvac);
        this.setFormValue('l13', misih.l13);
        this.setFormValue('lprovJf', misih.lprovJf);
        this.setFormValue('lprovVac', misih.lprovVac);
        this.setFormValue('lprov13', misih.lprov13);
        this.setFormValue('shcalcmode', misih.shcalcmode);
        this.setFormValue('shsupmode', misih.shsupmode);
        this.setFormValue('mntemp', misih.mntemp);
        this.setFormValue('mntcli', misih.mntcli);
        this.setFormValue('facteur', misih.facteur);
        this.setFormValue('ncoeffCli', 0);
        this.setFormValue('ncoeffEmp', 0);
        this.setFormValue('ncoeff', 0);
        this.setFormValue('salBase2', 0);
        this.setFormValue('shbaseglob', misih.shbaseglob);
        this.setFormValue('libelle', misih.libelle);
        this.setFormValue('fact2Vj13', misih.fact2Vj13);
        this.setFormValue('lnopay13', misih.lnopay13);
        this.setFormValue('lnopayJf', misih.lnopayJf);

        setTimeout(() => {
            this.calcShsupdata();
            this.calcSalaire();

            if (misih.shcalcmode) {
                this._ppshcalcmodeRest.getById(misih.shcalcmode.toString())
                    .subscribe((calcmode: Ppshcalcmode) => {
                        this.setFormValue('shcalcmodetitle', misih.shcalcmode);
                        this.selectedCalcMode = calcmode;
                        this.subscribeParams();
                    });
            } else {
                this.subscribeParams();
            }
        }, 0);
    }

    private valueNotEmpty(value?: any): any {
        return value !== '' ? value : undefined;
    }
}
