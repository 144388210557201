/* eslint-disable max-classes-per-file */

import {Action} from '@ngrx/store';

import {GridProfile} from '../../grid-profile/models';

export const PROFILE_LOAD_LIST = 'LOAD DYNAMIC GRID PROFILES';
export const PROFILE_LOAD_LIST_SUCCESS = 'LOAD DYNAMIC GRID PROFILES SUCCESS';
export const PROFILE_LOAD_LIST_FAIL = 'LOAD DYNAMIC GRID PROFILES FAIL';
export const PROFILE_CREATE_DYNAMIC = 'CREATE DYNAMIC GRID PROFILE';
export const PROFILE_CREATE_DYNAMIC_SUCCESS = 'CREATE DYNAMIC GRID PROFILE SUCCESS';
export const PROFILE_CREATE_DYNAMIC_FAIL = 'CREATE DYNAMIC GRID PROFILE FAIL';
export const PROFILE_UPDATE_DYNAMIC = 'UPDATE DYNAMIC GRID PROFILE';
export const PROFILE_UPDATE_DYNAMIC_SUCCESS = 'UPDATE DYNAMIC GRID PROFILE SUCCESS';
export const PROFILE_UPDATE_DYNAMIC_FAIL = 'UPDATE DYNAMIC GRID PROFILE FAIL';
export const PROFILE_UPDATE_DEFAULT = 'UPDATE DEFAULT GRID PROFILE';
export const PROFILE_UPDATE_DEFAULT_SUCCESS = 'UPDATE DEFAULT GRID PROFILE SUCCESS';
export const PROFILE_UPDATE_DEFAULT_FAIL = 'UPDATE DEFAULT GRID PROFILE FAIL';
export const PROFILE_ADD_LOCAL = 'ADD LOCAL GRID PROFILE';
export const PROFILE_DELETE_DYNAMIC = 'DELETE DYNAMIC GRID PROFILE';
export const PROFILE_DELETE_DYNAMIC_SUCCESS = 'DELETE DYNAMIC PROFILE SUCCESS';
export const PROFILE_DELETE_DYNAMIC_FAIL = 'DELETE DYNAMIC PROFILE DELETE FAIL';

export class LoadGridProfiles implements Action {
    public readonly type = PROFILE_LOAD_LIST;
}

export class LoadGridProfilesSuccess implements Action {
    public readonly type = PROFILE_LOAD_LIST_SUCCESS;

    constructor(public readonly payload: GridProfile<any>[]) {
    }
}

export class LoadGridProfilesFail implements Action {
    public readonly type = PROFILE_LOAD_LIST_FAIL;

    constructor(public readonly error: any) {
    }
}

export class CreateGridProfiles implements Action {
    public readonly type = PROFILE_CREATE_DYNAMIC;

    constructor(public readonly payload: GridProfile<any>) {
    }
}

export class CreateGridProfilesSuccess implements Action {
    public readonly type = PROFILE_CREATE_DYNAMIC_SUCCESS;

    constructor() {
    }
}

export class CreateGridProfilesFail implements Action {
    public readonly type = PROFILE_CREATE_DYNAMIC_FAIL;

    constructor(public readonly error: any) {
    }
}

export class UpdateGridProfiles implements Action {
    public readonly type = PROFILE_UPDATE_DYNAMIC;

    constructor(public readonly payload: GridProfile<any>) {
    }
}

export class UpdateGridProfilesSuccess implements Action {
    public readonly type = PROFILE_UPDATE_DYNAMIC_SUCCESS;

    constructor() {
    }
}

export class UpdateGridProfilesFail implements Action {
    public readonly type = PROFILE_UPDATE_DYNAMIC_FAIL;

    constructor(public readonly error: any) {
    }
}

export class UpdateGridProfileDefault implements Action {
    public readonly type = PROFILE_UPDATE_DEFAULT;

    constructor(public readonly payload: GridProfile<any>) {
    }
}

export class UpdateGridProfileDefaultSuccess implements Action {
    public readonly type = PROFILE_UPDATE_DEFAULT_SUCCESS;

    constructor(public readonly payload: GridProfile<any>) {
    }
}

export class UpdateGridProfileDefaultFail implements Action {
    public readonly type = PROFILE_UPDATE_DEFAULT_FAIL;

    constructor(public readonly error: any) {
    }
}

export class DeleteGridProfile implements Action {
    public readonly type = PROFILE_DELETE_DYNAMIC;

    constructor(public readonly cfgid: string) {
    }
}

export class DeleteGridProfileSuccess implements Action {
    public readonly type = PROFILE_DELETE_DYNAMIC_SUCCESS;

    constructor() {
    }
}

export class DeleteGridProfileFail implements Action {
    public readonly type = PROFILE_DELETE_DYNAMIC_FAIL;

    constructor(public readonly error: any) {
    }
}

export class AddLocalProfile implements Action {
    public readonly type = PROFILE_ADD_LOCAL;

    constructor(public readonly payload: GridProfile<any>[]) {
    }
}

export type GridProfileActions =
    | LoadGridProfiles
    | LoadGridProfilesFail
    | LoadGridProfilesSuccess
    | CreateGridProfiles
    | CreateGridProfilesFail
    | CreateGridProfilesSuccess
    | UpdateGridProfiles
    | UpdateGridProfilesFail
    | UpdateGridProfilesSuccess
    | DeleteGridProfile
    | DeleteGridProfileSuccess
    | DeleteGridProfileFail
    | AddLocalProfile
    | UpdateGridProfileDefault
    | UpdateGridProfileDefaultSuccess
    | UpdateGridProfileDefaultFail;
