import {IwColumn} from '@app/sam-base/core/column';
import {IwContextMenuClass} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwRestEntity, IwRestEntityId, SearchEntityStatusOperator} from '@app/sam-base/core/rest-api';


import {ppcliContextMenu} from './context-menu/ppcli-context-menu';

@IwContextMenuClass(...ppcliContextMenu) @IwRestEntity('ppcli')
export class Ppcli {
    @IwColumn({
        index: 0,
        type: 'cliStatus',
        width: 80
    }) @IwRestEntityId() public cliId?: string;
    @IwColumn({
        index: 1,
        type: 'status',
        filterQuery: '',
        statusQuery: [],
        valueFormat: 'ppcli_status_',
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public clistatustext?: string;
    @IwColumn({
        index: 2,
        hidden: true
    }) public prefixe?: string;
    @IwColumn({index: 3}) public nom?: string;
    @IwColumn({
        index: 4,
        hidden: true
    }) public adresse1?: string;
    @IwColumn({
        index: 5,
        hidden: true
    }) public adresse2?: string;
    @IwColumn({
        index: 6,
        hidden: true
    }) public adresse3?: string;
    @IwColumn({
        index: 7,
        hidden: true,
        width: 70,
        type: 'keyword'
    }) public npa?: string;
    @IwColumn({index: 8}) public lieu?: string;
    @IwColumn({
        index: 9,
        hidden: true,
        width: 70,
        type: 'keyword'
    }) public pays?: string;
    @IwColumn({
        index: 10,
        type: 'phonenumber',
    }) public tel1?: string;
    @IwColumn({
        index: 11,
        type: 'phonenumber'
    }) public natel?: string;
    @IwColumn({index: 12}) public email?: string;
    @IwColumn({
        index: 13,
        hidden: true
    }) public ageId?: string;
    @IwColumn({
        index: 14,
        hidden: true,
        type: 'dateTime'
    }) public dateCreat?: string;
    public conId?: string;
    @IwColumn({
        name: 'nomConseiller',
        index: 15
    }) public nomconseiller?: string;
    @IwColumn({
        index: 17,
        type: 'dateTime'
    }) public dateModif?: string;
    public noGestion?: string;
    public cliIndex?: string;
    public clistatus?: number;
    public ccliStatus?: string;
    public cclistatu2?: string;
    public cclistatu3?: string;
    public firstMis?: string;
    public lastMis?: string;
    public dateDelet?: string;
    public dtAnyModif?: string;
    public faceleord?: string;
    public facadrId?: string;
    public facnpmode?: string;
    public paicondId?: string;
    public ijData?: string;
    public kchkNo?: string;
    public ccemail?: string;
    public ijEmail?: string;
    public cctoblig?: string;
    public cdeclilieu?: string;
    public pointMode?: string;
    public usertag?: string;
    public ccadrId?: string;
    public misnoteemp?: string;
    public regions?: string;
    public langue?: string;
    public ifac2ijmod?: string;
    public icc2ijmod?: string;
    public geoLat?: string;
    public cliintype?: string;
    public noteBo?: string;
    public categids?: string;
    public www?: string;
    public lexpfac2ij?: string;
    public ccfrx?: string;
    public escompte?: string;
    public modData?: string;
    public modCnt?: string;
    public noteFo?: string;
    public tel2?: string;
    public remarques?: string;
    public cliadrId?: string;
    public lpointeuse?: string;
    public nomailing?: string;
    public missalmode?: string;
    public modecreat?: string;
    public clitarmd6f?: string;
    public mfm5def?: string;
    public cmfrx?: string;
    public cmavfrx?: string;
    public kredchk?: string;
    public kchkNote?: string;
    public forcecha?: string;
    public xmlData?: string;
    public clitarifmd?: string;
    public syncflag?: string;
    @IwColumn({
        name: 'cct',
        index: 16,
        type: 'keyword'
    }) public cctids?: string;
    public misnotecli?: string;
    public pointclino?: string;
    public evafrx?: string;
    public miscoeff?: string;
    public hpausaimod?: string;
    public aptids?: string;
    public shsupmode?: string;
    public kchkdate?: string;
    public ccavfrx?: string;
    public nbemp?: string;
    public allowsms?: string;
    public fax?: string;
    public cliData?: string;
    public rapnoref?: string;
    public heusaimode?: string;
    public cliattId?: string;
    public kchkUser?: string;
    public misfrais?: string;
    public pointData?: string;
    public kchkrating?: string;
    public lexp2ij?: string;
    public ijRapnote?: string;
    public lexpmis2ij?: string;
    public modefactu?: string;
    public lhhmm?: string;
    public userId?: string;
    public hpautarmod?: string;
    public categories?: string;
    public misfacmode?: string;
    public pointtype?: string;
    public geoLon?: string;
    public misnoteint?: string;
    public formejuri?: string;
    public clitarcct?: string;
    public horaire?: string;
    public ijRapSm?: string;
    public hpaufacmod?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public ppcon?: string;
    public ppagence?: string;
    public timeslotsOptional?: boolean;
    @IwColumn({
        name: 'keyword',
        index: 17,
        type: 'keyword'
    })
    public keywords?: string;
}
