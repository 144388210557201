import {Dictionary, MenuItem} from '../../../models';

export * from './event.model';

export const METADATA_IW_MENU_ITEM_OBJECT = '$METADATA#IW_MENU_ITEM_OBJECT';
export const METADATA_IW_MENU_ITEM_PROP = '$METADATA#IW_MENU_ITEM_PROP';

export interface IwObjectMenuItemMetadata {
    [METADATA_IW_MENU_ITEM_OBJECT]: MenuItem[];
    [METADATA_IW_MENU_ITEM_PROP]: Dictionary<MenuItem[]>;
}
