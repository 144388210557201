import {OperationEvents} from '@app/modules/sam-main/salary/events';
import {MenuItem} from '../../components';
import * as roles from '@sam-base/core/auth/access-rules/facture-roles';


export const saemsviewContextMenu: MenuItem[] = [
    {
        label: 'calculer',
        event: OperationEvents.FICHE_SAL_CALCULATE,
        accessRoles: roles.salaryRWtRoles(),
        contextMenuVisibleMode: 'single',
    },
    {
        label: 'Publish',
        event: OperationEvents.FICHE_SAL_PUBLISH,
        accessRoles: roles.salaryPrintRoles(),
        contextMenuVisibleMode: 'single',
        filter: {
            'paid': true,
            'published': false
        }
    },
    {
        label: 'download',
        event: OperationEvents.FICHE_SAL_DOWNLOAD,
        accessRoles: roles.salaryPrintRoles(),
        contextMenuVisibleMode: 'all',
        filter: {'calculated': true}
    },
    {
        label: 'send_email',
        event: OperationEvents.FICHE_SAL_MAIL,
        accessRoles: roles.salaryComRoles(),
        contextMenuVisibleMode: 'single',
        filter: {
            'published': true,
            'sendByMail': true
        }
     }
     //,
    // {
    //     label: 'supprimer',
    //     event: OperationEvents.FICHE_SAL_DELETE,
    //     contextMenuVisibleMode: 'all'
    // }
];
