import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {IwReportsService} from './iw-reports.service';

@NgModule({
    imports: [CommonModule], providers: [IwReportsService]
})
export class IwReportsModule {
}
