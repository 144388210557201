import {ComboboxItem} from '@sam-base/models';
import moment from 'moment';

export interface Ppheusaid2Input {
    gridHours: Ppheusaid2Hours[];
    heurepause: Heurepause; // HeurePause exists
    datelundi?: moment.Moment;
    datestart: string;
    gridDays?: boolean[];
}

export interface Ppheusaid2Output {
    gridHours: Ppheusaid2Hours[];
    gridCalculations: GridCalculations;
    timeformat: string;
}

export interface Ppheusaid2Hours {
    rapId: string;
    indorheu: string;
    cid: string;
    grhType: string;
    grhId: string;
    griId: string;
    gsId: string;
    day1Hour?: number;
    day2Hour?: number;
    day3Hour?: number;
    day4Hour?: number;
    day5Hour?: number;
    day6Hour?: number;
    day7Hour?: number;
    totalHours?: number | string;
    libelle: string;
    lhmm?: string;
    mntemp: number;
    mntcli: number;
    misihId: string;
    misId: string;
}

export interface Heurepause {
    exists: boolean;
    pauseMode: number;
    pauseTx: number;
    pauseMontant: number;
    auto: boolean;
    rapHpause?: boolean;
}

export interface GridCalculations {
    mntAvsEmp: number;
    totEmp: number;
    totCli: number;
    totalDay1Hours: number;
    totalDay2Hours: number;
    totalDay3Hours: number;
    totalDay4Hours: number;
    totalDay5Hours: number;
    totalDay6Hours: number;
    totalDay7Hours: number;
    totalHours: number;
}

export interface HoursPerDay {
    day1: number | string;
    day2: number | string;
    day3: number | string;
    day4: number | string;
    day5: number | string;
    day6: number | string;
    day7: number | string;
}

export const hoursTypesCombo: ComboboxItem<string | undefined>[] = [{name: '---', value: undefined}, {
    name: 'H100', value: 'H100'
}, {name: 'H125', value: 'H125'}, {name: 'H150', value: 'H150'}, {name: 'H200', value: 'H200'}];

export interface HourAddEvent {
    hourType: string;
    lhmm: '1/60' | '1/100';
}
