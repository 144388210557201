import {SamUserRole} from '@sam-base/models/admin/sam-user';

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function decompteLPP(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];
}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function allocationsFamiliales(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];
}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function debutFinMissions(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];
}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function missionsActives(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function historiqueMissions(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function recapMissions(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS_ENTREPRISE];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function suva(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS_ENTREPRISE];

}

export function extractionPlacementMenu(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];
}

export function extractionFactureMenu(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_EXTRACTIONS];
}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function extractionSalairesMenu(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_EXTRACTION];
}

export function pilotageFacturesMenu(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN];
}

export function pilotagePlacementMenu(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];
}


/**
 * Defines the roles that have access to the sitdebiteur
 */
export function extractionjournauxmensuels(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_EXTRACTION];
}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function extractionjdecomptesAnnuels(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_SALAIRE_EXTRACTION];
}


/**
 * Defines the roles that have access to the sitdebiteur
 */
export function contratNonRetournes(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function periodeFacture(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_ADMIN_PERIOD,
        SamUserRole.SAM_ADMIN_GOD_MODE];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function statEtatRecouvrement(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function heuresProduites(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function statsheuresProduites(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];

}

export function followUpManager(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];

}


/**
 * Defines the roles that have access to the sitdebiteur
 */
export function statsMargesRevenus(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_STATS_ENTREPRISE];
}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function extractionDecompteResumeParcompte(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_EXTRACTIONS];
}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function statsresume(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE]
}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function rapportAnnuelmissions(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS_ENTREPRISE];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function rapportAnnuelPLacementsFixes(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS_ENTREPRISE];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function documentsEmployesNonRecus(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function listeAbsences(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];

}

/**
 * Defines the roles that have access to the sitdebiteur
 */
export function echeancesermis(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];

}


/**
 * Defines the roles that have access to the sitdebiteur
 */
export function rapportsVierges(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_EXTRACTIONS];
}

/**
 * Defines the roles that have access to the new client
 */
export function extractionsituationDebiteur(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_FACTURE_EXTRACTIONS];
}

/**
 * Defines the roles that have access to the new client
 */
export function extractionsCAClient(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_EXTRACTIONS];
}

/**
 * Defines the roles that have access to the new client
 */
export function extractionNouveauxCLients(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_STATS_ENTREPRISE];
}

/**
 * Defines the roles that have access to the new client
 */
export function extractionRelevecomoteDebiteurs(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_EXTRACTIONS];
}

export function acomptes(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_ACOMPTE_ADMIN];
}

