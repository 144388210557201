import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppoptsal')
export class Ppoptsal {
    @IwRestEntityId() public parId?: string;
    public ageId?: string;
    public aptId?: string;
    public coeffMin?: number;
    public ccfrx?: string;
    public modCnt?: number;
    public lieuuc?: boolean;
    public smmode?: string;
    public ppXmldata?: string;
    public shbaseglob?: number;
    public lnoaco?: boolean;
    public rapverhor?: number;
    public cmfinfrx?: string;
    public lanstrict?: boolean;
    public clitarifmd?: number;
    public yearArc?: string;
    public cdedefhor?: string;
    public deftypejob?: number;
    public hpausaimod?: number;
    public shsupcalmd?: number;
    public smioIdI?: string;
    public lshbglob?: boolean;
    public allosal0?: boolean;
    public acoacolibs?: string;
    public cmfrx?: string;
    public atttravfrx?: string;
    public defcodpgmh?: string;
    public lprvhnudef?: boolean;
    public defcodpgmf?: string;
    public evafrx?: string;
    public rapprtfrx?: string;
    public mbexclgs?: string;
    public rapnoref?: boolean;
    public ppempfrx?: string;
    public lprvjfhdef?: boolean;
    public ijData?: string;
    public misfacmode?: number;
    public acofactreq?: number;
    public nomempuc?: boolean;
    public hmultisai?: boolean;
    public adrescli?: boolean;
    public cliremtpl?: string;
    public pgmcodexpl?: string;
    public lheupause?: boolean;
    public matchflist?: string;
    public cdedefdur?: string;
    public cmreceived?: boolean;
    public aptcdeobli?: boolean;
    public smioimpid?: string;
    public usertext2?: string;
    public usertext6?: string;
    public cdedefcen?: string;
    public faceleord?: number;
    public lprovhnuit?: boolean;
    public acomntsafe?: number;
    public aptcdfobli?: boolean;
    public cdetempo?: boolean;
    public nomcliuc?: boolean;
    public agi2frx?: string;
    public smioimpdat?: string;
    public empremtpl?: string;
    public lprovvac?: boolean;
    public hSeuil4?: number;
    public misarcnbj?: number;
    public grtstrict?: boolean;
    public acoround?: number;
    public ccreceived?: boolean;
    public lheunuit?: boolean;
    public cdedeflieu?: string;
    public lprv13def?: boolean;
    public acofact?: number;
    public cmavfrx?: string;
    public ppcdffrx?: string;
    public quamode?: number;
    public lcctoblig?: boolean;
    public agifrx?: string;
    public cdeoblig?: boolean;
    public smioDtE?: string;
    public lppmode?: number;
    public boAgeId?: string;
    public pgmcodesf?: string;
    public pccharges?: number;
    public lieu?: string;
    public lprov13?: boolean;
    public ccavfrx?: string;
    public defcodcna?: string;
    public txVac4?: number;
    public lclitarcct?: boolean;
    public ppcdefrx?: string;
    public webioData?: string;
    public ppclifrx?: string;
    public indmntsafe?: number;
    public smioIdE?: string;
    public misremtpl?: string;
    public mbexclcpt?: string;
    public txheunuit?: number;
    public l13h?: boolean;
    public usertext7?: string;
    public mbfocgcpt?: string;
    public shcalcmode?: number;
    public maxlevlan?: number;
    public maxlevinf?: number;
    public lprvvacdef?: boolean;
    public cdfremtpl?: string;
    public langue?: number;
    public maxlevrat?: number;
    public coefcalcmd?: number;
    public acomntmin?: number;
    public lij?: boolean;
    public caisselpp?: string;
    public hSeuil1?: number;
    public shclear?: boolean;
    public shautofin?: boolean;
    public aptoblig?: boolean;
    public lfraish1?: boolean;
    public pgmcodesh?: string;
    public lcct?: boolean;
    public txVac5?: number;
    public usertext3?: string;
    public userId?: string;
    public agePrefix?: string;
    public actSalId?: string;
    public suistrict?: boolean;
    public defpaytype?: number;
    public lsCctId?: string;
    public shautoclos?: boolean;
    public indmntsafr?: number;
    public tx13?: number;
    public ljfh?: boolean;
    public missalmode?: number;
    public cmrem1?: string;
    public prenomempu?: boolean;
    public ppData?: string;
    public lfraish2?: boolean;
    public acowithind?: boolean;
    public acoraplibs?: string;
    public usertext4?: string;
    public usertext9?: string;
    public acoquifrx?: string;
    public smioDtI?: string;
    public cliadrmode?: number;
    public usertext1?: string;
    public acocalcmod?: number;
    public txJf?: number;
    public hSeuil2?: number;
    public allotarif0?: boolean;
    public plffrx?: string;
    public lppnbwim?: number;
    public cderemtpl?: string;
    public lwebio?: boolean;
    public hSeuil3?: number;
    public acosemlibs?: string;
    public usertext8?: string;
    public cnacodes?: string;
    public usertext5?: string;
    public lnoacorap?: boolean;
    public acoinrap?: boolean;
    public lnoacoemp?: boolean;
    public modData?: string;
    public suiprivate?: boolean;
    public ccrem1?: string;
    public modefactu?: number;
    public acofactis?: number;
    public caissecomp?: string;
    public lprovjfh?: boolean;
    public cnacodexpl?: string;
    public quastrict?: boolean;

    // PPOPSA
    public libheu?: string;
    public sagsnuiprv?: string;
    public sagspause?: string;
    public sagsjfhprv?: string;
    public libhnuit?: string;
    public sags13prv?: string;
    public alfmode?: number;
    public sagsvacprv?: string;
    public sagsxxx?: string;
    public satblreq?: string;
    public sags100prv?: string;
    public sagsnuit?: string;
    public iscantmode?: number;
    public sagsnuippr?: string;
    public sagsjour?: string;
    public acobankids?: string;
    public sags200?: string;
    public sagsjf?: string;
    public raplibmode?: number;
    public satblded1f?: string;
    public satblded4h?: string;
    public sagsfrais1?: string;
    public saded2def?: boolean;
    public libhequip?: string;
    public sagsjfhprz?: string;
    public satblded2h?: string;
    public libweek?: string;
    public pgmmode?: number;
    public sags125?: string;
    public grivacppr?: string;
    public sags13?: string;
    public sagsvac?: string;
    public saded3def?: boolean;
    public sagsjfhppr?: string;
    public raplibdata?: string;
    public libhpause?: string;
    public gri13ppr?: string;
    public cctwbase?: boolean;
    public sags13ppr?: string;
    public lprevsalid?: boolean;
    public sags100?: string;
    public osaData?: string;
    public grijfhppr?: string;
    public impotmode?: number;
    public satblded1h?: string;
    public sagsmois?: string;
    public saded4def?: boolean;
    public libhequix?: string;
    public sags150?: string;
    public sagsop?: string;
    public satblded3h?: string;
    public satblded4f?: string;
    public sagsfrais2?: string;
    public satblded2f?: string;
    public sagsvacppr?: string;
    public trcantmode?: number;
    public cctlsinsa?: boolean;
    public paucalcmod?: number;
    public sagshlpp?: string;
    public saded1def?: boolean;
    public sagsjfhor?: string;
    public cnamode?: number;
    public sagsequip?: string;
    public sagsequix?: string;
    public satblded3f?: string;

    //SAGSpublic gsId?: string;
    public titre?: string;
    public titre1?: string;
    public titre2?: string;
    public titre3?: string;
    public titre4?: string;
    public titre5?: string;
    public gsGenre?: string;
    public gsCtype?: string;
    public csType?: number;
    public csType2?: number;
    public cs06Type?: string;
    public cs06Type2?: string;
    public cs06Type3?: string;
    public sdsType?: string;
    public sd4Statan?: string;
    public sd4Statoct?: string;
    public sd4Istype?: string;
    public sd4Caftype?: number;
    public baseBrut?: number;
    public retInd?: number;
    public baseAvs?: number;
    public baseCna?: number;
    public baseLac?: number;
    public baseLpp?: number;
    public baseImp?: number;
    public basePgm?: number;
    public baseCu1?: number;
    public baseCu2?: number;
    public baseCu3?: number;
    public baseCu4?: number;
    public baseCu5?: number;
    public baseCu6?: number;
    public baseMj1?: number;
    public baseMj2?: number;
    public baseMj3?: number;
    public baseMj4?: number;
    public avsacNb?: boolean;
    public cptdebit?: string;
    public cptcredit?: string;
    public debitsplit?: number;
    public creditsplit?: number;
    public detdebit?: boolean;
    public detcredit?: boolean;
    public analytique?: boolean;
    public roundIt?: boolean;
    public printIt?: boolean;
    public salBase?: boolean;
    public salHor?: boolean;
    public salHormnt?: boolean;
    public salJour?: boolean;
    public salHorlpp?: boolean;
    public salLppmnt?: boolean;
    public corrmode?: number;
    public lpphormode?: number;
    public gsPaiPrv?: string;
    public gsRepPrv?: string;
    public gsOpp?: string;
    public cumformule?: string;
    public basishmode?: number;
    public gsisop?: boolean;
    public gsData?: string;
    public xmlData?: string;
    public inactif?: boolean;

    // SAPAR
    public anee?: string;
    public lieuSoc?: string;
    public cantonSoc?: string;
    public avsTxEmp?: number;
    public avsTxSoc?: number;
    public avsTxAdm?: number;
    public avsTxaMd?: number;
    public avsMntEx?: number;
    public avsGsBas?: string;
    public avsGsSal?: string;
    public avsGsCot?: string;
    public avsGsCoe?: string;
    public avsGsAdm?: string;
    public avsGsNs?: string;
    public avsGsEx?: string;
    public avsGsBex?: string;
    public avsExAnn?: number;
    public avsAuto?: number;
    public avsNocli?: string;
    public acTxEmp?: number;
    public acTxSoc?: number;
    public ac2TxEmp?: number;
    public ac2TxSoc?: number;
    public acGsSal?: string;
    public ac2GsSal?: string;
    public acGsNs?: string;
    public ac2GsNs?: string;
    public acGsCot?: string;
    public ac2GsCot?: string;
    public acGsCoe?: string;
    public ac2GsCoe?: string;
    public acGsNb?: string;
    public ac2GsNb?: string;
    public acsalmax?: number;
    public ac2salmax?: number;
    public acAuto?: number;
    public avsacCnd?: string;
    public cnaGsBas?: string;
    public cnaGsSal?: string;
    public cnaGsCot?: string;
    public cnaGsCoe?: string;
    public cnaGsBpr?: string;
    public cnasalmax?: number;
    public cnaheumax?: number;
    public cnaAuto?: number;
    public cnaNocli?: string;
    public lacActive?: number;
    public lacAuto?: number;
    public pgmBasmod?: number;
    public pgmGsAba?: string;
    public pgmGsBas?: string;
    public pgmGsSal?: string;
    public pgmGsCot?: string;
    public pgmGsCoe?: string;
    public pgmGsBpr?: string;
    public pgmGsPpr?: string;
    public pgmsalmax?: number;
    public pgmAuto?: number;
    public pgmNocli?: string;
    public lppGsBas?: string;
    public lppGsSal?: string;
    public lppGsCot?: string;
    public lppGsCoe?: string;
    public lppGsCoo?: string;
    public lppGsNs?: string;
    public lppGsCft?: string;
    public lppGsCfe?: string;
    public lppCoord?: number;
    public lppCoomod?: number;
    public lppCoomnt?: number;
    public lppSalmin?: number;
    public lppSalmax?: number;
    public lppMinded?: number;
    public lppHor?: boolean;
    public lppHorMa?: number;
    public lppHorMi?: number;
    public lppHorCo?: number;
    public lppGshlpp?: number;
    public lppPrasai?: string;
    public lppHsmode?: number;
    public lppAuto?: number;
    public lppNbhan?: number;
    public impGsBas?: string;
    public impGsCot?: string;
    public impGsCof?: string;
    public impGsCor?: string;
    public impGsAis?: string;
    public imcGsCot?: string;
    public imcGsCof?: string;
    public imcGsCor?: string;
    public impGsNbj?: string;
    public impComtra?: string;
    public ishlkupmod?: number;
    public repGsCot?: string;
    public alfGsCoe?: string;
    public alfGsCot?: string;
    public alfAuto?: number;
    public alfGsind?: string;
    public alfGsInm?: string;
    public cu1Titre?: string;
    public cu1Shtit?: string;
    public cu1Active?: boolean;
    public cu1Basmod?: number;
    public cu1GsAba?: string;
    public cu1GsBas?: string;
    public cu1GsCot?: string;
    public cu1CsCoe?: string;
    public cu1GsCnd?: string;
    public cu1Auto?: number;
    public cu2Titre?: string;
    public cu2Shtit?: string;
    public cu2Active?: boolean;
    public cu2Basmod?: number;
    public cu2GsAba?: string;
    public cu2GsBas?: string;
    public cu2GsCot?: string;
    public cu2GsCoe?: string;
    public cu2GsCnd?: string;
    public cu2Auto?: number;
    public cu3Titre?: string;
    public cu3Shtit?: string;
    public cu3Active?: boolean;
    public cu3Basmod?: number;
    public cu3GsAba?: string;
    public cu3GsBas?: string;
    public cu3GsSal?: string;
    public cu3GsCot?: string;
    public cu3GsCoe?: string;
    public cu3GsNs?: string;
    public cu3Auto?: number;
    public cu3Salmax?: number;
    public cu3Salmin?: number;
    public cu3Subl?: number;
    public cu4Titre?: string;
    public cu4Shtit?: string;
    public cu4Active?: boolean;
    public cu4Basmod?: number;
    public cu4GsAba?: string;
    public cu4GsBas?: string;
    public cu4GsSal?: string;
    public cu4GsCot?: string;
    public cu4GsCoe?: string;
    public cu4GsNs?: string;
    public cu4Salmax?: number;
    public cu4Salmin?: number;
    public cu4Subl?: number;
    public cu4Auto?: number;
    public cu5Active?: boolean;
    public cu6Active?: boolean;
    public gsSalBru?: string;
    public gsSalNet?: string;
    public gsSalNap?: string;
    public gsTotDed?: string;
    public gsTotRet?: string;
    public gsTotCoe?: string;
    public gsTotPrv?: string;
    public gsSalNeg?: string;
    public gsNbH?: string;
    public gsNbJ?: string;
    public gsNbJavs?: string;
    public gsNbJis?: string;
    public hsOp?: string;
    public mj1Active?: number;
    public mj1Titre?: string;
    public mj1Shtit?: string;
    public mj1GsBas?: string;
    public mj1GsInd?: string;
    public mj1Prv?: string;
    public mj1Auto?: number;
    public mj2Active?: number;
    public mj2Titre?: string;
    public mj2Shtit?: string;
    public mj2GsBas?: string;
    public mj2GsInd?: string;
    public mj2GsPrv?: string;
    public mj2Auto?: number;
    public mj3Active?: number;
    public mj3Titre?: string;
    public mj3Shtit?: string;
    public mj3GsBas?: string;
    public mj3GsInd?: string;
    public mj3GsPrv?: string;
    public mj3Auto?: number;
    public mj4Active?: number;
    public mj4Titre?: string;
    public mj4Shtit?: string;
    public mj4GsBas?: string;
    public mj4GsInd?: string;
    public mj4GsPrv?: string;
    public mj4Auto?: number;
    public langueBs?: number;
    public bsfrx?: string;
    public csfrx?: string;
    public cs06frx?: string;
    public chqfrx?: string;
    public compteNap?: string;
    public anNap?: number;
    public cptagrpemp?: number;
    public cptagrpgs?: number;
    public cptagrpmod?: number;
    public cptaan?: number;
    public cptaanspc?: number;
    public cptaanobli?: number;
    public cptatxfcoe?: number;
    public cptatxfpay?: number;
    public cptatxfchq?: number;
    public jParAn?: number;
    public hParJ?: number;
    public lpayifnotc?: number;
    public mpaId1?: string;
    public mpaId2?: string;
    public mpaId3?: string;
    public mpaId4?: string;
    public mpaId5?: string;
    public mpaId8?: string;
    public mpaId9?: string;
    public allownb?: number;
    public lastnochq?: number;
    public libcatemp1?: string;
    public libcatemp2?: string;
    public libcatemp3?: string;
    public cat1force?: number;
    public cat2force?: number;
    public cat3force?: number;
    public libuserdat?: string;
    public libuserda2?: string;
    public libuserda3?: string;
    public libusercha?: string;
    public libuserch2?: string;
    public libuserch3?: string;
    public saData?: string;
    public saXmldata?: string;
    public dateModif?: string;
    public userCreat?: string;
    public userModif?: string;
}
