import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Geper} from '@sam-base/models/invoices/geper';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class GeperService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public checkFacDate(salId?: string): Observable<boolean> {
        return this._http.get<boolean>(this._baseUrl + `geper/checkFacDate/${salId}`);
    }

    public list(): Observable<Geper[]> {
        return this._http.get<Geper[]>(this._baseUrl + `geper/list`);
    }
}
