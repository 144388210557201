<iw-dialog #dialog (beforeHide)="onBeforeHide()" [bodyPadding]="'10'" [cls]="'iw-modal'" [height]="height"
           [modal]="true" [resizable]="false" [title]="title | translate" [visible]="display" [width]="width">
    <iw-busy [ajustLeft]="leftBusy" [ajustTop]="topBusy" [isBusy]="loading"></iw-busy>
    <div #form style="width: 100%; height: 100%">
        <div class="form" id="dialog-window">
            <div class="content">
                <ng-content></ng-content>
            </div>
            <div *ngIf="btnOk.enabled || btnCancel.enabled" class="bottom">
                <iw-button (press)="onPressOk()" *ngIf="btnOk.visible" [isDisabled]="!btnOk.enabled"
                           [text]="btnOk.value | translate" [width]="'70'" id="ok-create"></iw-button>
                <iw-button (press)="onPressCancel()" *ngIf="btnCancel.visible" [isDisabled]="!btnCancel.enabled"
                           [text]="btnCancel.value | translate" [width]="'70'" id="cancel-create"></iw-button>
            </div>
        </div>
    </div>
</iw-dialog>
