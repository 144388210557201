import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pplpp')
export class Pplpp {
    @IwRestEntityId() public empId?: string;
    public datelundi?: string;
    public idlecount?: string;
    public semcount1y?: string;
    public heucount?: string;
    public heucount1y?: string;
    public forcerlpp?: string;
    public lppsoumis?: string;
    public lppcalcmod?: string;
    public workcount?: string;
    public heusemaine?: string;
    public semstatus?: number;
    public semcount?: string;
    public pplppId?: string;
}
