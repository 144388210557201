import {Directive, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom} from 'rxjs';

import {IwEventHubService} from '../core/events';
import {MenuItem} from '../models';

/** Base class to handle menu entries that call an event */
@Directive()
export abstract class BaseEventMenuComponent {
    @Input() public context?: any;

    constructor(protected readonly _eventsHub: IwEventHubService<string>, protected readonly _translate: TranslateService) {
    }

    protected _items: MenuItem[] = [];

    public get items(): MenuItem[] {
        return this._items;
    }

    @Input()
    public set items(v: MenuItem[]) {
        this.setItems(v);
    }

    protected getTranslation(key: string) {
        return this._translate.get(key);
    }

    protected emitEvent(event: string) {
        this._eventsHub.emit(event, this.context);
    }

    protected async addComandToMenuItems(items: MenuItem[]): Promise<MenuItem[]> {
        const list: MenuItem[] = [];

        for (const m of items) {
            if (m.items) {
                m.items = await this.addComandToMenuItems(m.items);
            }
            if (m.label) {
                m.label = await lastValueFrom(this.getTranslation(m.label));
            }
            if (m.event) {
                const event = m.event;
                m.command = () => this.emitEvent(event);
            }

            list.push(m);
        }

        return list;
    }

    protected async setItems(v: MenuItem[]) {
        this._items = await this.addComandToMenuItems(v);
    }
}
