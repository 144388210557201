import {HttpClient} from '@angular/common/http';
import {Type} from '@angular/core';
import {RestServiceStore} from '../core/rest-store';
import {RestEntityClient} from '../entity';

export class EntityServiceStore extends RestServiceStore<RestEntityClient<any>> {

    constructor(private readonly http: HttpClient) {
        super();
    }

    protected factory<T>(t: Type<T>): RestEntityClient<T> {
        return new RestEntityClient(this.http, t);
    }
}
