import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppempdocview')
export class Ppempdocview {
    @IwRestEntityId() public empdocId?: string;
    public docData?: string;
    public empId?: string;
    public received?: boolean;
    @IwColumn({
        index: 1,
        name: 'type',
        type: 'translate'
    }) public empdoctype?: string;
    public oblig?: boolean;
    public wxdocId?: string;
    @IwColumn({
        index: 3,
        name: 'file'
    }) public docName?: string;
    @IwColumn({
        index: 2,
        name: 'received',
        type: 'date'
    }) public dateRece?: string;
}
