import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {Langue} from '@sam-base/models/common/langue.enum';

@IwRestEntity('saemp')
export class Saemp {
    @IwRestEntityId() @IwColumn() public empId?: string;
    public empNo?: string;
    public mfId?: string;
    public nom?: string;
    public prenom?: string;
    public adresse1?: string;
    public npa?: string;
    public lieu?: string;
    public pays?: string;
    public natel?: string;
    public email?: string;
    public datenaiss?: string;
    public nation?: string;
    public etatcivil?: string;
    public ageId?: string;
    public permis?: string;
    public permisno?: string;
    public dateInlas?: string;
    public profession?: string;
    public dateTrack?: string;
    public dateCreat?: string;
    public adresse2?: string;
    public ofsnr?: number;
    public commune?: string;
    public commutrav?: string;
    public canton?: string;
    public tel1?: string;
    public tel2?: string;
    public telinterne?: string;
    public sexe?: string;
    public cpolitesse?: string;
    public noavs?: string;
    public noavs13?: string;
    public lieunaiss?: string;
    public cantnaiss?: string;
    public paysnaiss?: string;
    public origine?: string;
    public datetatciv?: string;
    public ctaType?: string;
    public ctaDuree?: string;
    public ctaDebut?: string;
    public ctaFin?: string;
    public confession?: string;
    public permDtde?: string;
    public permDta?: string;
    public permLieu?: string;
    public permNote?: string;
    public permis2?: string;
    public permisno2?: string;
    public perm2Dtde?: string;
    public perm2Dta?: string;
    public perm2Lieu?: string;
    public perm2Note?: string;
    public attfisc?: boolean;
    public dateInfir?: string;
    public dateOutla?: string;
    public categorie1?: string;
    public categorie2?: string;
    public categorie3?: string;
    public centotal?: number;
    public orpquaId?: string;
    public profDate?: string;
    public codeAvs?: number;
    public codeAc?: number;
    public avsacNb?: boolean;
    public avsmanuel?: boolean;
    public acmanuel?: boolean;
    public codeCna?: number;
    public tableCna?: string;
    public cnamanuel?: boolean;
    public codeLac?: number;
    public tableLac?: string;
    public lacmanuel?: boolean;
    public codeLpp?: number;
    public tableLpp?: string;
    public lppHor?: boolean;
    public lppmanuel?: boolean;
    public lppCoomod?: number;
    public codePgm?: number;
    public tablePgm?: string;
    public pgmmanuel?: boolean;
    public codeImp?: number;
    public tableImp?: string;
    public tauxImp?: number;
    public txImpCom?: number;
    public baremImp?: string;
    public baremImps?: string;
    public modcalcimp?: number;
    public impmodfroz?: boolean;
    public skifaz?: number;
    public impAf?: boolean;
    public impmanuel?: boolean;
    public imptyeonu?: boolean;
    public impNote?: string;
    public impusertag?: string;
    public impathee?: boolean;
    public impsalfact?: number;
    public codeReq?: number;
    public tableReq?: string;
    public codeAlf?: number;
    public tableAlf?: string;
    public alfmanuel?: boolean;
    public codeCu1?: number;
    public tableCu1?: string;
    public cu1manuel?: boolean;
    public codeCu2?: number;
    public tableCu2?: string;
    public cu2manuel?: boolean;
    public codeCu3?: number;
    public tableCu3?: string;
    public cu3manuel?: boolean;
    public codeCu4?: number;
    public tableCu4?: string;
    public cu4manuel?: boolean;
    public codeCu5?: number;
    public tableCu5?: string;
    public cu5manuel?: boolean;
    public codeCu6?: number;
    public tableCu6?: string;
    public cu6manuel?: boolean;
    public codeMj1?: number;
    public tableMj1?: string;
    public mj1manuel?: boolean;
    public codeMj2?: number;
    public tableMj2?: string;
    public mj2manuel?: boolean;
    public codeMj3?: number;
    public tableMj3?: string;
    public mj3manuel?: boolean;
    public codeMj4?: number;
    public tableMj4?: string;
    public mj4manuel?: boolean;
    public lalfHor?: boolean;
    public alfhorMn?: number;
    public alfhorMa?: number;
    public alfdateD?: Date;
    public alfdateF?: Date;
    public codeCct?: number;
    public cctId?: string;
    public cctmanuel?: boolean;
    public freqSal?: number;
    public ifactsal?: number;
    public ifactsalfu?: number;
    public ifactsal2?: number;
    public ifactsal2f?: number;
    public salhorauto?: number;
    public salhoraire?: number;
    public saljour?: number;
    public saljour30?: number;
    public salmensuel?: number;
    public salannuel?: number;
    public salannimp?: number;
    public salimptx?: number;
    public salannforc?: number;
    public vacmode?: number;
    public nbjVac?: number;
    public notes?: string;
    public nomjf?: string;
    public enfnb?: number;
    public enfnbcharg?: number;
    public enfnballoc?: number;
    public conjNom?: string;
    public conjprenom?: string;
    public conjnation?: string;
    public conjWork?: boolean;
    public conjWkch?: boolean;
    public conjFi?: boolean;
    public conCh?: boolean;
    public conjEmpl?: string;
    public conjnoas13?: string;
    public conjNaiss?: string;
    public conjWkdeb?: string;
    public conjWkfin?: string;
    public enfMenind?: boolean;
    public enfConcub?: boolean;
    public paytype?: number;
    public dtatype?: number;
    public mpaId?: string;
    public banque?: string;
    public banqueCpt?: string;
    public banqueAdr?: string;
    public banqueClr?: string;
    public banqueSwi?: string;
    public iban?: string;
    public paybloque?: boolean;
    public nofraischq?: boolean;
    public jparAn?: number;
    public hparJ?: number;
    public hparS?: number;
    public lecparS?: number;
    public cantontrav?: string;
    public cantonimp?: string;
    public cantonhab?: string;
    public grade?: string;
    public step?: string;
    public usegsa?: boolean;
    public langue?: Langue;
    public bstoemail?: boolean;
    public empnotok?: boolean;
    public userdate1?: string;
    public userdate2?: string;
    public userdate3?: string;
    public userchamp1?: string;
    public userchamp2?: string;
    public userchamp3?: string;
    public txactivite?: number;
    public salgenfreq?: number;
    public lsunet?: boolean;
    public lrgdp?: boolean;
    public lprv13blq?: boolean;
    public empData?: string;
    public xmlData?: string;
    public syncflag?: number;
    public modCnt?: number;
    public modData?: string;
    public userId?: string;
    public dtanymodif?: string;
    public userModif?: string;
    public userCreat?: string;
    public salhebdo?: string;
    public degreeofrel?: string;
    public lparS?: number;
    public statpaytype?: string;
    public taxId?: string;
    public crossborderValidAsOf?: string;
    public txactioth?: number;
    public noIsCode?: string;
    public noIsCatOpen?: string;
    public pad?: boolean;
    public ppap?: boolean;
    public profilage?: string;
    public enfnoconcub?: boolean;
    public enfmajbigsal?: boolean;
    public enfconjbigsal?: boolean;
    public monthContSalary?: number;
    public monthCont13?: number;
    public monthCont14?: number;
    public hourlyContPaidByHour?: number;
    public hourlyContPaidByLesson?: number;
    public hourlyContVac?: number;
    public hourlyContJf?: number;
    public hourlyCont13?: number;
    public noTimeContSalary?: number;
    public noTimeContVac?: number;
    public noTimeContJf?: number;
    public noTimeCont13?: number;
    public payadoth?: boolean;
    public payfromoth?: string;
    public paynomeoth?: string;
    public payprenomoth?: string;
    public payadroth?: string;
    public paynpaoth?: string;
    public paylieuoth?: string;
    public paypaysoth?: string;
    public impGrant?: boolean;
}
