import {OperationEvents} from '@app/modules/sam-main/salary/events';
import {MenuItem} from '../../components';
import * as roles from '@sam-base/core/auth/access-rules/facture-roles';
import * as StatisticsRoles from '@app/sam-base/core/auth/access-rules/statistics-menu';


export const gecliContextMenu: MenuItem[] = [
    {
        label: 'gecli_send_by_email',
        event: OperationEvents.GECLI_EMAIL,
        accessRoles: roles.factureComRoles(),
        contextMenuVisibleMode: 'all'
    },
    {
        label: 'gecli_factures',
        event: OperationEvents.GECLI_FACNC,
        accessRoles: roles.factureRWRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'facture-send-open_invoice',
        event: OperationEvents.SEND_OPEN_INVOICES,
        accessRoles: roles.factureComRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'create_encaissement',
        event: OperationEvents.GECLI_ENCAISSEMENT,
        accessRoles: roles.factureEncaissementsRole(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'gecli_deb_sit', //Missing spec
        event: OperationEvents.GECLI_DEBSIT,
        accessRoles: StatisticsRoles.extractionsituationDebiteur(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'gecli_compte_debiteur', //Missing spec
        event: OperationEvents.GECLI_RELEVE,
        accessRoles: StatisticsRoles.extractionRelevecomoteDebiteurs(),
        contextMenuVisibleMode: 'single'
    }
];
