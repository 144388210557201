import {Injectable, Type} from '@angular/core';
import {ProfileService} from '@shared/profile/profile.service';
import {EntityDetail} from '../../../modules/sam-main/placement/models/entity-detail.model';
import {OptionalDictionary} from '../../models';
import {getEntityMetadata} from '../rest-api';

/** Service to provide details components to use in SmartGrid */
@Injectable()
export class EntityDetailProviderService {
    private _store: OptionalDictionary<Type<any>> = {};

    constructor(private _profileService: ProfileService) {
    }

    public getDetailComponent<T>(e: Type<T> | T) {
        try {
            const meta = getEntityMetadata(e);
            return this._store[meta.$entity];
        } catch {
        }
    }

    public setDetailComponent<T>(entityDetails: EntityDetail) {
        const meta = getEntityMetadata(entityDetails.entity);
        if (this._profileService.checkUserPermission(entityDetails.accessRoles)) {
            this._store[meta.$entity] = entityDetails.component;
        }
    }

    // fixme ULTIMATE maybe use same system as above
    public setDetailComponentSalary<T>(e: Type<T> | T, component: Type<any>) {
        const meta = getEntityMetadata(e);
        this._store[meta.$entity] = component;
    }

}
