import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppsmioage')
export class Ppsmioage {
    @IwRestEntityId() public ageId?: string;
    public smioDtE?: string;
    public smioIdI?: string;
    public userModif?: string;
    public userDelet?: string;
    public smioIdIp?: string;
    public smioIdE?: string;
    public userCreat?: string;
    public smioDtI?: string;
}
