import {Component, ElementRef, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Sapaimode} from '@sam-base/models/placement/sapaimode';

@Component({
    selector: 'iw-sapaimode-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwSapaimodeDropdownComponent)]
})
export class IwSapaimodeDropdownComponent extends BaseEntityDropdown<Sapaimode> {
    @Input() public width?: string | number;

    @Input() public warningMessage?: string;

    @Input() public payType = [1, 2, 3, 4, 5, 6, 8, 9];

    constructor(private readonly elRef: ElementRef) {
        super();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public resultFilters: (e: Sapaimode) => boolean = (e) => this.payType.includes(e.payType || 0);

    protected getEntityType() {
        return Sapaimode;
    }

    protected buildLabel(e: Sapaimode): string {
        return e.titre || '';
    }
}
