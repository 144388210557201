import {Component, Input, Type} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppsecteur} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-sector-combo-box',
    templateUrl: './sector-combo-box.component.html',
    providers: [buildCustomValueProvider(SectorComboBoxComponent)]
})
export class SectorComboBoxComponent extends BaseEntityDropdown<Ppsecteur> {

    @Input() public noLabel = false;

    protected getEntityType(): Type<Ppsecteur> {
        return Ppsecteur;
    }

    protected buildLabel(e: Ppsecteur) {
        return `${e.secLib}`;
    }
}
