import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';

@Component({
    selector: 'iw-cell-status-client',
    templateUrl: './iw-cell-status.component.html',
    providers: [buildCustomValueProvider(IwCellStatusClientComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IwCellStatusClientComponent implements OnInit {

    @Input() public row?: { clistatus: number };

    @Input() public color = '#00C000'; // Default color

    @Input() public value = '';

    constructor() {
    }

    public ngOnInit() {
        if (this.row && (this.row.clistatus !== undefined)) {
            this.checkColor(this.row.clistatus);
        }
    }

    public checkColor(status: number) {
        switch (status) {
            case 0:
                this.color = '#008000';
                break;
            case 1:
                this.color = '#80ff80';
                break;
            case 2:
                this.color = '#c0c0c0';
                break;
            case 3:
                this.color = '#faba8f';
                break;
            default:
                this.color = '#00C000';
                break;
        }
    }
}
