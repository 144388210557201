<div [ngClass]="className" [style.height]="styleValue.height === '100%'? '100%' : 'inherit'"
     class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" class="iw-form-group-label" for="item">{{ label | translate }}
    </div>
    <div (dblclick)="onDoubleClick()" [style.height]="styleValue.height === '100%'? '100%' : 'inherit'"
         class="p-inputgroup iw-input">
    <textarea [(ngModel)]="value" [class.active-input]="multiselTextarea && !isReadOnly" [disabled]="isDisabled"
              [maxLength]="maxLength" [ngStyle]="styleValue" [readOnly]="isReadOnly || disabledInput"
              pInputTextarea></textarea> <textarea *ngIf="valueMask"
                                                   [class.active-input]="multiselTextarea && !isReadOnly"
                                                   [disabled]="isDisabled" [maxLength]="maxLength"
                                                   [readOnly]="isReadOnly|| disabledInput" pInputTextarea
                                                   style="height: 22px;">{{ valueToShow }}</textarea>
    </div>
</div>
<div #TxtWarningMessage *ngIf="isInvalid && warningMessage">
    <small class="iw-input col-xs-12 padding-left-0"
           style="color: #ff0000; font-size:10px"> {{ warningMessage | translate }} </small>
</div>
