import {Component, EventEmitter, Input, Output, Type} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppgrh} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-grh-combo-box',
    templateUrl: './grh-combo-box.component.html',
    providers: [buildCustomValueProvider(GrhComboBoxComponent)]
})
export class GrhComboBoxComponent extends BaseEntityDropdown<Ppgrh> {

    public selectedValue = undefined;
    @Output() public selectedChange = new EventEmitter<Ppgrh>();

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    @Input()
    public set grhType(type: string[]) {
        this.resultFilters = type ? (e: Ppgrh) => type.some(el => {
            if (!e.grhType) {
                return;
            }
            return e.grhType.includes(el);
        }) : (_: Ppgrh) => false;
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    public emitSelected(e: Ppgrh) {
        this.selectedChange.emit(e);
    }

    public getEntityType(): Type<Ppgrh> {
        return Ppgrh;
    }

    public buildLabel(e: Ppgrh) {
        return `${e.grhName}`;
    }
}
