<div #tpCont @tooltip class="tooltip-container">
    <span class="circle-missions" style="margin-right: 10px"> - {{ 'mission' | translate }}</span><br> <span
        class="circle-maladie" style="margin-right: 10px"> - {{ 'maladie' | translate }}</span><br> <span
        class="circle-accident" style="margin-right: 10px"> - {{ 'accident' | translate }}</span><br> <span
        class="circle-maternite" style="margin-right: 10px"> - {{ 'maternite' | translate }}</span><br> <span
        class="circle-militaire" style="margin-right: 10px"> - {{ 'militaire' | translate }}</span><br> <span
        class="circle-abspaye" style="margin-right: 10px"> - {{ 'abspaye' | translate }}</span><br> <span
        class="circle-absnonpaye" style="margin-right: 10px"> - {{ 'absnonpaye' | translate }}</span><br> <span
        class="circle-vacances" style="margin-right: 10px"> - {{ 'vacances' | translate }}</span><br> <span
        class="circle-noabsence" style="margin-right: 10px"> - {{ 'noabsense' | translate }}</span><br> <span
        class="circle-abmission" style="margin-right: 10px"> - {{ 'absmission' | translate }}</span><br> <span
        class="circle-flexmission" style="margin-right: 10px"> - {{ 'flexmission' | translate }}</span><br>

</div>