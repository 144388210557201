import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppgri} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-ppgri-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwPpgriDropdownComponent)]
})
export class IwPpgriDropdownComponent extends BaseEntityDropdown<Ppgri> {

    @Input() public width?: string | number;

    public mainGriTypes = [2, 3, 6];

    public defaultGriTypes = [1, 4, 5];

    /** Filter to use when showing list of ppgri */
    @Input()
    public set type(v: number) {
        this.resultFilters = v ? (e) => !!e.griType && this.mainGriTypes.includes(e.griType) : (e) => !!e.griType && this.defaultGriTypes.includes(e.griType);
    }

    protected getEntityType() {
        return Ppgri;
    }

    protected buildLabel(e: Ppgri): string {
        return e.griName || '';
    }
}
