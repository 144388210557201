import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    RappreprtMisModalComponent
} from '@app/modules/sam-decompte/components/rappreprt/rappreprt-mis-modal/rappreprt-mis-modal.component';
import {MiscpyComponent} from '@app/modules/sam-main/placement/components/missions/miscpy/miscpy.component';
import {
    MisheuComponent
} from '@app/modules/sam-main/placement/components/missions/tab-ppmis-ind/misheu/misheu.component';

import {SignatureComponent} from '@app/modules/sam-main/placement/components/signature/signature.component';
import {GestionComponent} from '@app/sam-base/components/gestion/gestion.component';
import {IjTimesheetsComponent} from '@app/sam-base/components/modal/ij-timesheets/ij-timesheets.component';
import {ModalContractComponent} from '@app/sam-base/components/modal/modal-confirm-dialog/modal-contract.component';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ContainerDirective} from '@app/sam-base/core/directives';
import {PpcliadrComponent} from '@modules/sam-main/placement/components/ppcliadr/ppcliadr.component';
import {TranslateModule} from '@ngx-translate/core';
import {SamComponentsModule} from '@sam-base/components';
import {ModalPaiementsComponent} from '@sam-base/components/modal/modal-paiements/modal-paiements.component';
import {ModalRappelsComponent} from '@sam-base/components/modal/modal-rappels/modal-rappels.options';
import {ModalConsChangeComponent} from '@shared/widgets/modal-components/modal-cons-change/modal-cons-change';
import {ModalManualdtaComponent} from '@shared/widgets/modal-components/modal-manualdta/modal-manualdta.component';
import {ModalPrintComponent} from '@shared/widgets/modal-components/modal-print/modal-print.component';
import {
    ModalSalaryChoiceComponent
} from '@shared/widgets/modal-components/modal-salarychoice/modal-salarychoice.component';
import {ContextMenuModule} from 'primeng/contextmenu';
import {TableModule} from 'primeng/table';
import {
    LookupsSelectionComponent
} from 'src/app/shared/widgets/modal-components/lookups-selection/lookups-selection.component';
import {FormComponentsModule} from '../form-components/form-components.module';
import {IwComponentsModule} from '../iw-components/iw-components.module';
import {CctDynamicFormComponent} from './cct-dynamic-form/cct-dynamic-form.component';
import {DocTypeEditComponent} from './doc-type-edit/doc-type-edit.component';
import {ModalCategorySelectionComponent} from './modal-category-selection/modal-category-selection.component';
import {ModalInputComponent} from './modal-input/modal-input.component';
import {ModalLookupComponent} from './modal-lookup/modal-lookup.component';
import {ModalNumberSubmitComponent} from './modal-number-submit/modal-number-submit.component';
import {ModalRapportInvoiceComponent} from './modal-rapport-invoice/modal-rapport-invoice.component';
import {ModalRapportSalaryComponent} from './modal-rapport-salary/modal-rapport-salary.component';
import {ModalSelectManagementComponent} from './modal-select-management/modal-select-management.component';
import {IwModalSelectTableComponent} from './modal-select-table/modal-select-table.component';
import {ModalSelectionListsComponent} from './modal-selection-lists/modal-selection-lists.component';
import {ModalTextAreaComponent} from './modal-text-area/modal-text-area.component';
import {PpaptmsComponent} from './ppaptms/ppaptms.component';
import {PpcctmsComponent} from './ppcctms/ppcctms.component';
import {PpchaComponent} from './ppcha/ppcha.component';
import {PpclicatmsComponent} from './ppclicatms/ppclicatms.component';
import {PpempmsComponent} from './ppempms/ppempms.component';
import {PpquamsComponent} from './ppquams/ppquams.component';
import {PpselkwComponent} from './ppselkw/ppselkw.component';

const EXPORTS: any[] = [
    ModalTextAreaComponent,
    ModalInputComponent,
    ModalContractComponent,
    ModalLookupComponent,
    ModalSelectManagementComponent,
    ModalSelectionListsComponent,
    ModalCategorySelectionComponent,
    PpclicatmsComponent,
    PpcctmsComponent,
    PpquamsComponent,
    PpchaComponent,
    PpaptmsComponent,
    LookupsSelectionComponent,
    PpselkwComponent,
    GestionComponent,
    ModalMessageComponent,
    ModalConsChangeComponent,
    ModalNumberSubmitComponent,
    IwModalSelectTableComponent,
    MiscpyComponent,
    PpcliadrComponent,
    MisheuComponent,
    DocTypeEditComponent,
    IjTimesheetsComponent,
    CctDynamicFormComponent,
    ModalRapportInvoiceComponent,
    ModalRapportSalaryComponent,
    RappreprtMisModalComponent,
    SignatureComponent,
    PpempmsComponent,
    ModalPrintComponent,
    ModalSalaryChoiceComponent,
    ModalManualdtaComponent,
    ModalRappelsComponent,
    ModalPaiementsComponent];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IwComponentsModule,
        FormComponentsModule,
        ReactiveFormsModule,
        TranslateModule,
        TableModule,
        ContextMenuModule,
        SamComponentsModule],
    declarations: [
        ...EXPORTS,
        ContainerDirective],
    exports: [
        ...EXPORTS,
        ContainerDirective]
})
export class ModalComponentsModule {
}
