import {Type} from '@angular/core';
import {ConseillerFilters} from '@app/sam-base/models/entity-textfield.model';

import {ObjectMap, TableSelectionMode} from '../../../models';
import {GridProfile} from '../../grid-profile/models';

/** Store state for EntityState */
export interface EntitySelectStore<T> {
    [key: string]: EntitySelectState<T>;
}

/** Store state to handle a grid to select an entity */
export interface EntitySelectState<T> {
    /** Type of entity to list */
    entity: Type<T>;
    /** Selection mode */
    selectionMode: TableSelectionMode;
    /** Grid profile to present data */
    profile?: GridProfile<T>;
    /** Filter to apply to list */
    filter?: ObjectMap<T>;
    /** Selected elements */
    selected: T[];
    /** Mark if selection proccess has completed */
    completed: boolean;
    /** Gives the roles that show the results filtered by conseiller */
    conseillerFilters?: ConseillerFilters;
}

/** Create a state for EntitySelect */
export function createEntitySelectState<T>(entity: Type<T>, mode: TableSelectionMode = 'single', profile?: GridProfile<T>, filter?: ObjectMap<T>): EntitySelectState<T> {
    return {
        entity, selectionMode: mode, profile, filter, selected: [], completed: false
    };
}
