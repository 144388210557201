<iw-busy *ngIf="showBusy" [ajustLeft]="0" [ajustTop]="0" [isBusy]="loading"></iw-busy>
<div class="print-entity-container padding-10">
    <iw-dropdown *ngIf="hasTemplateCombo" [(value)]="selectedTemplate" [labelAlign]="'top'" [label]="'print_template'"
                 [options]="templates"></iw-dropdown>

    <iw-dropdown [(value)]="selectedFormat" [labelAlign]="'top'" [label]="'print_format'"
                 [options]="formatOptions"></iw-dropdown>

    <iw-button (press)="onPrint()" *ngIf="showPrintButton" [text]="'print' | translate"
               class="btn-block padding-top-5"></iw-button>
</div>
