import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IwColumnDataType} from '@sam-base/models';
import {OverlayPanel} from 'primeng/overlaypanel';

import {EntityDetailProviderService} from '../../../../core/services/entity-detail.service';
import {IwGridColumn, RowClickEvent} from '../../../../models';

@Component({
    selector: 'iw-table-body-row',
    templateUrl: './iw-table-body-row.component.html',
    styles: [':host { display: none; }']
})
export class IwTableBodyRowComponent<T> implements OnInit {

    /** Enables raw table mode - hide headers and cell lines */
    @Input() public rawTableMode = false;

    /** Column list */
    @Input() public columns: IwGridColumn<T>[] = [];

    /** Visible items */
    @Input() public items: T[] = [];

    /** Current item */
    @Input() public rowItem?: T;

    @Input() public rowIndex = -1;

    @Input() public hasCheckbox = false;

    @Input() public isChecked = false;

    @Input() public isClicked = false;

    @Input() public showGridDetails = true;
    @Input() public virtualScrollItemSize = 22;

    @Output() public rowClick = new EventEmitter<RowClickEvent<T>>();

    @Output() public checkboxClick = new EventEmitter<RowClickEvent<T>>();

    @Output() public rowDoubleClick = new EventEmitter<RowClickEvent<T>>();

    @Output() public contextClick = new EventEmitter<RowClickEvent<T>>();

    @ViewChild('template', {static: true}) public template?: TemplateRef<any>;

    @ViewChild('mouseOverDetails', {static: true}) public mouseOverDetails?: OverlayPanel;
    public showMouseOver = false;
    public readonly specialCells: IwColumnDataType[] = [
        'boolean',
        'activePer',
        'simpleFilter',
        'preview',
        'rapMisStatus',
        'cliStatus',
        'empStatus',
        'comStatus',
        'misStatus',
        'misStatusRap'];
    private _timeout: any;

    constructor(private viewContainerRef: ViewContainerRef, private detailProvide: EntityDetailProviderService,
                private _translate: TranslateService) {
    }

    private _currentTooltip = '';

    public get currentTooltip(): string {
        return this._currentTooltip;
    }

    public set currentTooltip(tooltip: string) {
        this._currentTooltip = tooltip;
    }

    public colorMapper(column: IwGridColumn<T>): ((value: any) => string) | undefined {
        return column?.colorMapper;
    }

    public ngOnInit() {
        if (this.template) {
            // Render template out of host
            this.viewContainerRef.createEmbeddedView(this.template);
        }
    }

    public onRowClick(event: MouseEvent, col: IwGridColumn<T>) {
        // Thor hack to prevent detail to show when double click
        setTimeout(() => {
            this.showDetail(this.rowItem);
        }, 300);
        this.rowClick.emit(this.buildClickEvent(event, col));
    }

    public onRowDoubleClick(event: MouseEvent, col: IwGridColumn<T>) {
        this.rowDoubleClick.emit(this.buildClickEvent(event, col));
    }

    public onCheckboxClick(event: MouseEvent) {
        this.checkboxClick.emit(this.buildClickEvent(event));
    }

    public onContextMenuClick(event: MouseEvent, col: IwGridColumn<T>) {
        this.contextClick.emit(this.buildClickEvent(event, col));
    }

    public onMouseEnter(event: MouseEvent, el?: HTMLElement, col?: IwGridColumn<T>) {
        this.getToolTip(el, col);
        if (this.rowItem && (<any>this.rowItem).type === 'ppemp') {
            this._timeout = setTimeout(() => {
                if (!this.mouseOverDetails) {
                    return;
                }
                this.mouseOverDetails.show(event);
                this.showMouseOver = true;
            }, 500);
        }
    }

    // eslint-disable-next-line complexity
    public getToolTip(el?: HTMLElement, col?: IwGridColumn<T>) {
        if (!el || !col) {
            return;
        }
        const cellElement = el.getElementsByClassName('table-cell');
        if (cellElement.length && cellElement[0].scrollWidth > cellElement[0].clientWidth && this.rowItem && col.prop) {
            this.currentTooltip = this._translate.instant('' + this.rowItem[col.prop]);
        }
    }

    // eslint-disable-next-line complexity
    public onMouseLeave() {
        this.currentTooltip = '';
        if (this.rowItem && (<any>this.rowItem).type === 'ppemp') {
            if (!this.mouseOverDetails) {
                return;
            }
            if (this.mouseOverDetails.overlayVisible) {
                this.mouseOverDetails.hide();
                this.showMouseOver = false;
            }
            if (this._timeout) {
                clearTimeout(this._timeout);
                return;
            }
        }
    }

    public getRowId(item: T) {
        if (this.columns?.length) {
            const prop = this.columns[0].prop;
            if (prop) {
                return item[prop];
            }
        }
        return '' + Math.random();
    }

    public isSpecialCell(column: IwGridColumn<T>): boolean {
        try {
            if (!column.type) {
                return false;
            }
            return this.specialCells.includes(column?.type);
        } catch (error) {
            return false;
        }
    }

    public hasColorMapper(column: IwGridColumn<T>): boolean {
        return !!column?.colorMapper;
    }

    private buildClickEvent(event: MouseEvent, col?: IwGridColumn<T>): RowClickEvent<T> {
        return {
            index: this.rowIndex,
            column: col || this.columns[0],
            row: this.items[this.rowIndex],
            base: event
        };
    }

    private showDetail(item?: T) {
        if (this.showGridDetails && item && this.detailProvide.getDetailComponent(item)) {
            const currentState = (<any>this.rowItem).__isActive;
            this.items.forEach((e: T) => {
                (<any>e).__isActive = false;
            });
            if (!currentState) {
                (<any>this.rowItem).__isActive = !(<any>this.rowItem).__isActive;
            }
        }
    }
}
