import {Component, EventEmitter} from '@angular/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalComponent} from '@app/sam-base/models';
import {Ppclicat} from '@app/sam-base/models/placement';

import {PpclicatmsCategories} from './pplclicatms-categories.model';

@Component({
    selector: 'iw-ppclicatms', templateUrl: './ppclicatms.component.html'
})
export class PpclicatmsComponent implements ModalComponent<string, string[]> {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public selectionList: string[] = [];

    public sortKeys = ['title', 'id'];
    public newInput: PpclicatmsCategories[] = [];
    public newOutput: PpclicatmsCategories[] = [];

    private _ok?: (result: string) => void;
    private _cancel?: (error?: any) => void;

    constructor(private rest: RestApiService) {
        this.updateLists();
    }

    public label(e: PpclicatmsCategories) {
        return e.value + ' ' + e.label;
    }

    public async setData(data: string[]): Promise<void> {
        this.selectionList = data;
        this.updateLists();
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onPressOk(event: any) {
        const seletions = event.map((e: any) => ({
            id: e.value, title: e.label
        }));
        if (this._ok) {
            this._ok(seletions);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    private updateLists() {
        this.rest.entityClient.getService(Ppclicat)
            .getRefData()
            .subscribe(res => {
                const input: PpclicatmsCategories[] = [];
                const output: PpclicatmsCategories[] = [];

                // eslint-disable-next-line complexity
                res.forEach((e: Ppclicat) => {
                    if (e.clicatId && !this.selectionList.includes(e.clicatId)) {
                        input.push({value: e.clicatId, label: e.libelle || ''});
                    }
                    if (e.clicatId && this.selectionList.includes(e.clicatId)) {
                        output.push({value: e.clicatId, label: e.libelle || ''});
                    }
                });
                this.newInput = input;
                this.newOutput = output;
            });
    }
}
