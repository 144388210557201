<div class="component">
    <div class="name">
        <iw-textfield-autocomplete (doubleClick)="onDoubleClick()"
                                   (selectedFromAutocomplete)="selectedFromAutocomplete($event)"
                                   (valueClear)="clearValue()"
                                   [(value)]="entityValue"
                                   [doubleClickable]="true"
                                   [entity]="entity"
                                   [filter]="filter"
                                   [forceSelection]="true"
                                   [isDisabled]="!(allowSearch && !isDisabled)"
                                   [labelAlign]="labelAlign"
                                   [label]="label | translate"
                                   [propsToFilter]="name"
                                   class="iw-input padding-0"></iw-textfield-autocomplete>
    </div>
    <div [class.hide]="!showIdField"
         class="value">
        <iw-textfield #item
                      (change)="onChange()"
                      (valueChange)="onValueChange($event)"
                      [isDisabled]="true"
                      [value]="value"></iw-textfield>
    </div>
</div>
