import {Injectable} from '@angular/core';
import {ConfigTemplate} from '@app/sam-base/core/config-template/models/config-template.model';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

import * as actions from '../actions/config-template.actions';

@Injectable()
export class ConfigTemplateEffects {

    public restEntityClient: RestEntityClient<ConfigTemplate<any>>;

    public addConfigTemplate$ = createEffect(() => this._actions.pipe(ofType(actions.CONFIG_TEMPLATE_ADD), mergeMap(action => this.restEntityClient
        .create(action.payload)
        .pipe(catchError((err: any) => {
            ERROR('error creating config template', err);
            return EMPTY;
        }), map(e => ({type: actions.CONFIG_TEMPLATE_ADD_SUCCESSFUL, config: e}))))));

    constructor(private _actions: Actions<actions.ConfigTemplateActions<any>>, _rest: RestApiService) {
        this.restEntityClient = _rest.getEntityClient(ConfigTemplate);
    }
}
