import {Injectable} from '@angular/core';
import {ReportOutputType} from '@app/sam-base/core/reports';
import {IwReportsService} from '@app/sam-base/core/reports/iw-reports.service';
import {isRestEntity} from '@sam-base/core';
import {Gefachdr} from '@sam-base/models/invoices/gefachdr';
import {Saems} from '@sam-base/models/salary/saems';
import {lastValueFrom} from 'rxjs';

@Injectable()
export class PrintReportService {

    constructor(private readonly _service: IwReportsService) {
    }

    // fixme ULTIMATE use this one for operations side by renaming
    public getPdfReport<T>(report: string, entityName: string, entityId: string | number, entity?: T,
                           typeOfPrint?: string,
                           templateId?: number) {
        // if (this.isFacture(entity)) {
        //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        //     entityId = entity.cliId!;
        // }
        if (this.isSalary(entity)) {
            entityId = entity.empId + '_' + entity.salId;
        }
        let templateName;
        let fileName;
        if (typeof entityName === 'string') {
            templateName = entityName.toLowerCase();
            fileName = `${entityName.toLowerCase()}_${entityId}`;
        } else {
            templateName = entityName;
            fileName = `${typeOfPrint}_${entityId}`;
        }
        return lastValueFrom(this._service.downloadReport({
            report: report,
            outputType: ReportOutputType.Pdf,
            id: entityId + '',
            params: entity,
            templateId: templateId
        }, fileName));
    }


    // public getPdfReport<T>(entityName: string, entityId: string, entity?: T) {
    //     const templateName = entityName.toLowerCase();
    //     const fileName = `${entityName.toLowerCase()}_${entityId}`;
    //     return lastValueFrom(this._service.downloadReport({
    //         template: templateName, type: IwReportType.Pdf, id: entityId, params: entity, dynamic: false
    //     }, fileName));
    // }

    private isFacture(e: unknown): e is Gefachdr {
        return isRestEntity(e) && e.$entity === 'gefachdr';
    }

    private isSalary(e: unknown): e is Saems {
        return isRestEntity(e) && e.$entity === 'saems';
    }
}
