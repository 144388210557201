import {Injectable} from '@angular/core';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {environment} from '@root/environments/environment';
import {HttpClientPerms} from '@sam-base/core/rest-api/httpclient/http.client.perms';
import {camelCase} from 'lodash';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ToastService} from '../../toast/toast.service';
import * as actions from '../actions/license.actions';
import {LicenseOptionDetails, LicenseOptions} from '../models/license.state';

@Injectable()
export class LicenseEffects {

    public loadLicenseOptionsEffects$ = createEffect(() => this._actions.pipe(ofType(actions.LICENSE_OPTIONS_LOAD), mergeMap(() => this._http.get<LicenseOptionDetails[]>(environment.backendURL + 'tenant/options')
        .pipe(catchError(err => {
            ERROR(err);
            return [];
        }), map(optionsDetails => new actions
            .LoadLicenseOptionsSuccess(getOptions(optionsDetails)))))));

    constructor(private readonly _actions: Actions<actions.LicenseActions>, private readonly _toastService: ToastService, private readonly _http: HttpClientPerms) {
    }

}

/**
 * Parses the options names from the licensing options object
 *
 * @param optionsDetails Array with the raw data coming from licensing server
 */
function getOptions(optionsDetails: LicenseOptionDetails[]): LicenseOptions {
    const options: LicenseOptions = {};
    optionsDetails.forEach(optionDetails => {
        const optionName = camelCase(optionDetails.optionName)
            .toLowerCase();
        const opts = {[optionName]: true};
        if (isLicenseOptions(opts)) {
            options[optionName as keyof LicenseOptions] = true;
        }
    });
    return options;
}

/**
 * Checks if the licensing option name is valid
 *
 * @param opts Object with the first licensing option converted to camelcase
 */
// eslint-disable-next-line complexity
function isLicenseOptions(opts: any): opts is LicenseOptions {
    return opts && ((opts.interijobwebaccess && typeof opts.interijobwebaccess === 'boolean') || (opts.swissdec && typeof opts.swissdec === 'boolean') || (opts.s3asdefaultstorage && typeof opts.s3asdefaultstorage === 'boolean') || (opts.smsalert && typeof opts.smsalert === 'boolean') || (opts.indexfulltextdocs && typeof opts.indexfulltextdocs === 'boolean') || (opts.signrequest && typeof opts.signrequest === 'boolean') || (opts.mfiles && typeof opts.mfiles === 'boolean'));
}
