import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';

import {LayoutEvents} from '../../../layout/events/events.models';
import {IwEventHubService} from '../../events/core/iw-event-hub.service';
import {getEntityMetadata} from '../../rest-api';
import * as selectActions from '../actions/entity-select.actions';

@Injectable()
export class EntitySelectEffects<T> {

    public onSelectInit$ = createEffect(() => this._actions.pipe(ofType(selectActions.ENTITY_SELECT_INIT), map(action => {
        const $entity = getEntityMetadata(action.state.entity).$entity;
        this._router.navigate([{
            outlets: {
                globalPanel: ['select', $entity]
            }
        }]);
        this._events.emit(LayoutEvents.TOGGLE_GLOBAL_SLIDE_ON);

        return new selectActions.EntitySelectInitComplete(action.state.entity);
    })));

    public onSelect$ = createEffect(() => this._actions.pipe(ofType(selectActions.ENTITY_SELECT_SUCCESS, selectActions.ENTITY_SELECT_CANCEL), map(action => {
        this._events.emit(LayoutEvents.TOGGLE_GLOBAL_SLIDE_OFF);
        return new selectActions.EntitySelectComplete(action.entityType);
    })));

    constructor(private readonly _actions: Actions<selectActions.EntitySelectAction<T>>, private readonly _events: IwEventHubService<string>, private readonly _router: Router) {
    }
}

