import {Component, EventEmitter} from '@angular/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalComponent} from '@app/sam-base/models';
import {Ppcct} from '@app/sam-base/models/placement';

import {ListItem} from '../../form-components/list-box/list-item.model';

@Component({
    selector: 'iw-ppcctms', templateUrl: './ppcctms.component.html'
})
export class PpcctmsComponent implements ModalComponent<string, string[]> {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();

    public selectionList: string[] = [];

    public input: ListItem<string>[] = [];

    public output: ListItem<string>[] = [];

    private _ok?: (result: string) => void;
    private _cancel?: (error?: any) => void;

    constructor(private rest: RestApiService) {
    }

    public async setData(data: string[]): Promise<void> {
        this.selectionList = data;
        this.updateLists();
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onPressOk(event: ListItem<string>[]) {
        const seletions = event.map(e => e.value)
            .join(';');
        if (this._ok) {
            this._ok(seletions);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    // Creates both lists and filters the cct by active
    private updateLists() {
        const input: ListItem<string>[] = [];
        const output: ListItem<string>[] = [];

        this.rest.entityClient.getService(Ppcct)
            .getRefData()
            .subscribe((res) => {
                res.forEach((e: Ppcct) => {
                    if (this.isInput(e)) {
                        input.push({label: e.cctId + ' | ' + e.cctLib, value: e.cctId ?? ''});
                    }
                    if (this.isOutput(e)) {
                        output.push({label: e.cctId + ' | ' + e.cctLib, value: e.cctId ?? ''});
                    }
                });

                this.output = output;
                this.input = input;
            });
    }

    private isInput(e: Ppcct): boolean {
        return !!e.cctId && !this.selectionList.includes(e.cctId) && e.cctstatus === '1';
    }

    private isOutput(e: Ppcct) {
        return e.cctId && this.selectionList.includes(e.cctId) && e.cctstatus === '1';
    }
}
