import {Type} from '@angular/core';
import {Team} from '@sam-base/models/admin/team.model';
import {Sacs06def} from '@sam-base/models/common/sacs06def';
import {Sagssd4istype} from '@sam-base/models/common/sagssd4istype';
import {Sagssd4statantype} from '@sam-base/models/common/sagssd4statantype';
import {Sagssd4statocttype} from '@sam-base/models/common/sagssd4statocttype';
import {Sagstype} from '@sam-base/models/common/sagstype';
import {Sasdstbl} from '@sam-base/models/common/sasdstbl';
import {SmtpConfig} from '@sam-base/models/common/smtpconfig';
import {Sags} from '@sam-base/models/salary';
import {Bank} from 'src/app/sam-base/models/common/bank';

import {Canton} from './canton';
import {Frxlist} from './frxlist';
import {Lookups} from './lookups';
import {Npa} from './npa';
import {Pays} from './pays';
import {Pdflist} from './pdflist';
import {Ppshcalcmode} from './ppshcalcmode';
import {Saconj} from './saconj';
import {Saenf} from './saenf';
import {TT6Ges} from './tt6Ges';

export const COMMON_ENTITIES: Type<any>[] = [
    Canton,
    Bank,
    Frxlist,
    Lookups,
    Npa,
    Pays,
    SmtpConfig,
    Ppshcalcmode,
    Saconj,
    Saenf,
    Sags,
    TT6Ges,
    Pdflist,
    Sacs06def,
    Sagssd4statantype,
    Sagssd4istype,
    Sagssd4statocttype,
    Sagstype,
    Sasdstbl,
    Team];

export * from './canton';
export * from 'src/app/sam-base/models/common/bank';
export * from './frxlist';
export * from './ijrapsaisiemode';
export * from './lookups';
export * from './npa';
export * from './pays';
export * from './pdflist';
export * from './ppshcalcmode';
export * from './saconj';
export * from './saenf';
export * from '../salary/sags';
export * from './tt6Ges';
