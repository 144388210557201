import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppofa')
export class Ppofa {
    @IwRestEntityId() public ofaId = '0';
    public libconc?: string;
    public ofaData?: string;
    public libheu?: string;
    public userCreat?: string;
    public libsupcct?: string;
    public qua2lib?: boolean;
    public lclitar2ht?: boolean;
    public annee?: string;
    public eletexte?: string;
    public libheunuit?: string;
    public userId?: string;
    public libconcfix?: string;
    public elernd05?: boolean;
    public ilangue?: number;
    public libunite?: string;
    public userModif?: string;
    public lprevsalid?: boolean;
    public hpaufacmod?: number;
    public defchamode?: number;
    public userDelet?: string;
    public textplfix?: string;
    public libheupaus?: string;
    public libheuequx?: string;
    public faceleord?: number;
    public chant2Conc?: boolean;
    public dateModif?: string;
    public fixgenmode?: number;
    public libconcpr?: string;
    public libheuequi?: string;
    public lclitar2Ht?: boolean;
}
