import {SortProp} from '../models';

export function getSortRequest<T>(...sort: SortProp<T>[]): string | undefined {
    return sort.map(buildSortRequest)
        .join('&') || undefined;
}

export function buildSortRequest<T>(sort: SortProp<T>) {
    return `${sort.prop},${sort.dir}`;
}

export function sortObjectArray<T>(array: T[], property: keyof T, direction: 'asc' | 'desc' = 'asc') {
    if (direction === 'desc') {
        return array
            .sort((a: any, b: any) => b[property].localeCompare(a[property]));
    }
    return array.sort((a: any, b: any) => a[property].localeCompare(b[property]));
}
