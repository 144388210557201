import {Directive, OnDestroy} from '@angular/core';
import {FormKeys} from '@app/sam-base/models';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {TabFormControl} from '../core/form-handler/models';
import {IwStoreService} from '../core/store';
import {TabItem} from '../models/tab-item.model';
import {BaseStoreFormComponent} from './base-store-form.component';

@Directive()
export abstract class BaseTabFormComponent<T> extends BaseStoreFormComponent<T> implements OnDestroy {

    public selectedTab: TabItem | undefined;
    private _sub = new Subject();

    constructor(store: IwStoreService) {
        super(store);
        this.refreshTabList();
        this.formGroup.valueChanges
            .pipe(takeUntil(this._sub))
            .subscribe(_ => {
                this._invalidTabList = this.generateInvalidTabList();
            });
        this.writeModeChange
            .pipe(takeUntil(this._sub))
            .subscribe((_: boolean) => {
                this._invalidTabList = this.generateInvalidTabList();
            });
    }

    private _tabList: TabItem[] = [];

    /** List of tabs to use */
    public get tabList() {
        return this._tabList;
    }

    public set tabList(_: TabItem[]) {
        throw new Error('tabs should be set by overriding the [generateTabList]');
    }

    private _invalidTabList: string[] = [];

    /** List of tabs to use */
    public get invalidTabList() {
        return this._invalidTabList;
    }

    public ngOnDestroy() {
        this._sub.next(undefined);
        this._sub.complete();
        super.ngOnDestroy();
    }

    public refreshTabList() {
        this._tabList = this.generateTabList();
        this._invalidTabList = this.generateInvalidTabList();
        this.selectedTab = this._tabList[0];
    }

    public getTabsFormControls(tabFormControls: TabFormControl<T>[]): FormKeys<T> {
        let formControls: FormKeys<T> = [];
        tabFormControls.forEach(tab => {
            formControls.push(...tab.formControls);
        });

        formControls = [...new Set(formControls)];
        return formControls;
    }

    /** Return list of tab names to use */
    protected abstract getTabsValues(): TabFormControl<T>[];

    /** Return FormKeys list of the entity  */
    protected abstract getFormControlNames(): FormKeys<T>;

    private generateTabList(): TabItem[] {
        // Shows the tab if show is not explicitly false
        return this.getTabsValues()
            .filter(t => t.show !== false && t.title)
            .map(t => ({title: t.title})) as TabItem[];
    }

    private generateInvalidTabList(): string[] {
        const invalidTabs: string[] = [];
        if (!this.isWriteMode) {
            return invalidTabs;
        }

        this.getTabsValues()
            .forEach((tab: TabFormControl<T>) => {
                if (!tab.title) {
                    return;
                }
                if (this.invalidForm(tab.formControls)) {
                    invalidTabs.push(tab.title);
                }
            });
        return invalidTabs;
    }
}
