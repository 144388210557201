/** Converts binary hours array to a friendly string format */
export function convertBinaryHours(hours: (0 | 1)[]): string {
    // If all hours are selected (1)[] returns 24h
    if (hours.every(h => h === 1)) {
        return '24h';
    }
    return hours.reduce((acc, _, index) => convertHour(hours, index, acc[0], acc[1]), ['', [0, 0]] as [string, [0 | 1, number]])[0];
}


function convertHour(hours: (0 | 1)[], index: number, convHours: string, lHour: [0 | 1, number]): [string, [0 | 1, number]] {
    let convertedHours = convHours;
    let lastHour = lHour;
    if (hours[index] === 1 && lastHour[0] === 0) {
        lastHour = [1, index];
    }

    // Creates the time interval
    if (isMultipleTimespan(hours, index)) {
        [convertedHours, lastHour] = createTimeInterval(index, convertedHours, lastHour, hours);
    }

    // Special case if there is a single 1
    if (isSingleTimespan(hours, index, lastHour)) {
        [convertedHours, lastHour] = createHourInterval(index, convertedHours);
    }
    return [convertedHours, lastHour];
}

function isMultipleTimespan(hours: (0 | 1)[], index: number): boolean {
    return ((hours[index] === 0 || hours[index + 1] === undefined) && hours[index - 1] === 1 && hours[index - 2] === 1);
}

function isSingleTimespan(hours: (0 | 1)[], index: number, lastHour: [0 | 1, number]): boolean {

    return (hours[index] === 1 && lastHour[1] === index && (hours[index + 1] === 0 || index === 23));
}

function createTimeInterval(index: number, convertedHours: string, lastHour: [0 | 1, number], hours: (0 | 1)[]): [string, [0 | 1, number]] {
    let hour = convertedHours;
    let currentIndex = index;
    if (hours[index + 1] === undefined && hours[index] === 1) {
        currentIndex++;
    }
    if (hour === '') {
        hour = `${lastHour[1]}h-${currentIndex}h`;
    } else {
        hour = hour + `, ${lastHour[1]}h-${currentIndex}h`;
    }
    return [hour, [0, 0]];
}

function createHourInterval(index: number, convertedHours: string): [string, [0 | 1, number]] {
    let hour = convertedHours;
    if (hour === '') {
        if (index === 23) {
            hour = `${index}h-0h`;
        } else {
            hour = `${index}h-${index + 1}h`;
        }
    } else {
        if (index === 23) {
            hour = hour + `, ${index}h-0h`;
        } else {
            hour = hour + `, ${index}h-${index + 1}h`;
        }
    }
    return [hour, [0, 0]];
}
