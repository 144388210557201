import {
    IwColumn
} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppjf')
export class Ppjf {
    @IwRestEntityId() public jfId?: string;
    @IwColumn({index: 0, name: 'date', type: 'date'}) public jfdate?: string;
    @IwColumn({index: 1, name: 'libelle'}) public jflibelle?: string;
    public dateCreat?: string;
    public userModif?: string;
    public userId?: string;
    public canton?: string;
    public dateDelet?: string;
    public dateModif?: string;
    public userDelet?: string;
    public userCreat?: string;
    public cantons?: string;
    public griType?: number;

    public hmin?: string;
    public nbjperiode?: string;
    public l2Jsuiv?: string;
}
