import {
    IwColumn
} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppagiview')
export class Ppagiview {
    @IwRestEntityId() @IwColumn({index: 0, name: 'id'}) public agiId?: string;
    @IwColumn({index: 1, name: 'date', type: 'date'}) public dateagi?: string;
    @IwColumn({index: 2, name: 'mois'}) public salId?: string;
    @IwColumn({index: 3, name: 'nomemploye'}) public nomemploye?: string;
    @IwColumn({index: 4, name: 'empId'}) public empId?: string;
}
