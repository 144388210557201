import {ValidatorFn, Validators} from '@angular/forms';

import {ValidatorsTypes} from './swagger.model';

/**
 * Maps swagger validators to reactive forms validators
 *
 * @param validator name of the swagger validator
 * @param value value of the swagger validator
 */
// eslint-disable-next-line complexity
export function mapSwaggerdefToValidators(validator: ValidatorsTypes, value?: string | number): ValidatorFn | false {
    if (typeof value === undefined) {
        return false;
    }
    switch (validator) {
        case 'minimum':
            return Validators.min(Number(value));
        case 'maximum':
            return Validators.max(Number(value));
        case 'minLength':
            return Validators.minLength(Number(value));
        case 'maxLength':
            return Validators.maxLength(Number(value));
        case 'pattern':
            return typeof value === 'string' ? Validators.pattern(value) : false;
        default:
            return false;
    }
}
