<div [ngClass]="className" class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" class="iw-form-group-label" for="item">{{ label | translate }}
    </div>
    <div *ngIf="visible" class="p-inputgroup iw-input flex flex-h">
        <p-calendar #control (onSelect)="onSelectEvent($event)" [(ngModel)]="primeNgValue" [appendTo]="'body'"
                    [dateFormat]="dateFormat" [disabled]="isDisabled"
                    [inputStyle]="{'background-color': bckClrCalendar}" [monthNavigator]="true"
                    [readonlyInput]="isReadOnly" [yearNavigator]="true" [yearRange]="yearRange" class="iw-date-picker"
                    view="month"></p-calendar>
    </div>
