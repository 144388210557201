import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppclipointeuse')
export class Ppclipointeuse {
    @IwRestEntityId() public cliId?: string;
    public userCreat?: string;
    public pointtype?: string;
    public dateCreat?: string;
    public userDelet?: string;
    public actif?: string;
    public dateDelet?: string;
    public dateModif?: string;
    public pointNo?: string;
    public pointData?: string;
    public userModif?: string;
}
