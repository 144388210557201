import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('pays', '', 'common')
export class Pays {
    @IwRestEntityId() public codepays2?: string;
    public codepays3?: string;
    public codepaysco?: string;
    public nompays?: string;
    public codepaysn3?: string;
    public nompays2?: string;
    public nompays3?: string;
    public nompays4?: string;
    public nompays5?: string;
    public codemon?: string;
    public nommon?: string;
}
