import * as diagActions from '../actions/dialog.actions';

export interface DialogState {
    formId?: string; // UUID of focused form
}

function getInitialState(): DialogState {
    return {};
}

const defaultInitialState = getInitialState();

export function reducer(state = defaultInitialState, action: diagActions.DialogActions): DialogState {
    switch (action.type) {
        case diagActions.UPDATE_DIALOG_ID: {
            return {
                ...state, formId: action.formId
            };
        }
    }

    return state;
}
