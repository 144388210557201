import {createSelector, select} from '@ngrx/store';
import {Observable} from 'rxjs';

import {FormEditMode, GlobalFormState} from '../models';
import {AppState} from '../reducers';

type StateResponse<T> = GlobalFormState<T> | undefined;

const selectGlobalForm = (state: AppState) => state.globalForm;

/** Selector to select a state */
export function selectGlobalFormState<T>(uuid: string): (source$: Observable<AppState>) => Observable<StateResponse<T>> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => form[globalFormUuid]);
    return select(selector(uuid));
}

/** Selector for the [LOADING] state */
export function selectGlobalFormLoading(uuid: string): (source$: Observable<AppState>) => Observable<boolean> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => !!form[globalFormUuid]?.loading);
    return select(selector(uuid));
}

/** Selector for the [ENTITY] state */
export function selectGlobalFormValue<T>(uuid: string): (source$: Observable<AppState>) => Observable<T | undefined> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => form[globalFormUuid]?.entity);
    return select(selector(uuid));
}

/** Selector for the [IS_NEW] state */
export function selectGlobalFormNewState(uuid: string): (source$: Observable<AppState>) => Observable<boolean> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => !!form[globalFormUuid]?.isNew);
    return select(selector(uuid));
}

/** Selector for the [IS_VALID] state */
export function selectGlobalFormValidState(uuid: string): (source$: Observable<AppState>) => Observable<boolean> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => !!form[globalFormUuid]?.isValid);
    return select(selector(uuid));
}

/** Selector for the [EDIT_MODE] state */
export function selectGlobalFormEditState(uuid: string): (source$: Observable<AppState>) => Observable<FormEditMode> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => (form[globalFormUuid]?.editMode) || 'read');
    return select(selector(uuid));
}

/** Selector for the [READONLY] state */
export function selectGlobalFormReadonly(uuid: string): (source$: Observable<AppState>) => Observable<boolean> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => !!form[globalFormUuid]?.readonly);
    return select(selector(uuid));
}

/** Selector for the [DIRTY] state */
export function selectGlobalFormDirty(uuid: string): (source$: Observable<AppState>) => Observable<boolean> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => !!form[globalFormUuid]?.dirty);
    return select(selector(uuid));
}

/** Selector for the [ENTITY_ID] state */
export function selectGlobalFormEntityId(uuid: string): (source$: Observable<AppState>) => Observable<string | number | undefined> {
    const selector = (globalFormUuid: string) => createSelector(selectGlobalForm, (form) => form[globalFormUuid]?.entityId);
    return select(selector(uuid));
}

/** Select first opened form by entity name */
export function selectGlobalFormByEntityName(entity: string): (source$: Observable<AppState>) => Observable<string | undefined> {
    const selector = (globalFormEntity: string) => createSelector(selectGlobalForm, forms => {
        for (const formKey in forms) {
            if (forms[formKey]?.navProfile?.entity === globalFormEntity) {
                return formKey;
            }
        }
    });
    return select(selector(entity));
}

/** Select opened form by entity name and entity id */
export function selectGlobalFormByEntityNameAndEntityId(entity: string, entityId: string | number): (source$: Observable<AppState>) => Observable<string | undefined> {
    const selector = (globalFormEntity: string) => createSelector(selectGlobalForm, forms => {
        for (const formKey in forms) {
            if (forms[formKey]?.navProfile?.entity === globalFormEntity && forms[formKey]?.entityId === entityId) {
                return formKey;
            }
        }
    });
    return select(selector(entity));
}
