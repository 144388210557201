import {Component, ElementRef, EventEmitter, Input, Output, Type} from '@angular/core';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {stringifyIfNotString} from '@app/sam-base/helpers/string';
import {QueryBuilder, Terms} from '@sam-base/core';
import {ObjectMap} from '@sam-base/models';
import {EmployeeStatus} from '@sam-base/models/placement/employee-status';
import {Ppemp} from '@sam-base/models/placement/ppemp';
import {AutoCompleteCompleteEvent, AutoCompleteSelectEvent} from 'primeng/autocomplete';
import {Observable} from 'rxjs';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {QueryString} from '../../core/rest-api/elastic-search/models/query/query-string.model';
import {EsQueryStatement} from '../../core/rest-api/elastic-search/query-builder/es-query-statement';
import {RestApiService} from '../../core/rest-api/rest-api.service';

@Component({
    selector: 'iw-textfield-autocomplete',
    templateUrl: './iw-textfield-autocomplete.component.html',
    providers: [buildCustomValueProvider(IwTextFieldAutocompleteComponent)]
})
export class IwTextFieldAutocompleteComponent<T> extends BaseFormControl<string> {
    @Input() public icon?: string;


    @Input() public warningMessage?: string;

    @Input() public inputClass?: string;


    @Input() public tooltip?: string;

    @Input() public tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';

    @Input() public suffix?: string;

    /** Query to get autocomplete results */
    @Input() public fetchAction?: () => Observable<T[]>;

    /** Properties from which to filter the results */
    @Input() public propsToFilter?: (keyof T)[];

    @Input() public doubleClickable = false;

    @Input() public forceSelection = false;
    @Input() public filter?: ObjectMap<T>;
    @Output() public doubleClick = new EventEmitter<void>();
    @Output() public selectedFromAutocomplete: EventEmitter<T> = new EventEmitter();
    @Output() public valueClear: EventEmitter<void> = new EventEmitter();
    public filteredSuggestions?: any[];
    public filteredSuggestionsObject: T[] = [];

    constructor(private elRef: ElementRef, private _restService: RestApiService) {
        super();
    }

    private _entity?: Type<T>;

    /** Set entity is mandatory if that autocomplete data comes from elastic */
    @Input()
    public set entity(type: Type<T>) {
        this._entity = type;
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public onBlur() {
        super.onBlur();
    }

    public filterSuggestions(event: AutoCompleteCompleteEvent) {
        this.filteredSuggestionsObject = [];
        if (!this.propsToFilter) {
            this.filteredSuggestions = [];
            return;
        }

        const query = event.query;

        if (this._entity) {
            this.elasticRequest(query);
        } else {
            if (!this.fetchAction) {
                ERROR('No fetch action defined!');
            }
            this.normalRequest(query);
        }
    }

    /**
     * Regular rest request if the data doesn't come from elastic
     *
     * @param eventQuery query request
     * @returns void
     */
    public normalRequest(eventQuery: string) {
        if (!this.fetchAction) {
            return;
        }
        this.fetchAction()
            .subscribe({
                next: e => {
                    this.filteredSuggestionsObject = e;
                    this.filteredSuggestions = e
                        .map(data => this.objectPropsToString(data))
                        .filter((filtered: any) => filtered.replace(/\s+/g, '')
                            .toLowerCase()
                            // eslint-disable-next-line max-len
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '') // Removes accents
                            .indexOf(eventQuery.toLowerCase()) !== -1);
                },
                error: () => this.filteredSuggestions = []
            });
    }

    /**
     * Elasticsearch request for data coming from elastic
     *
     * @param eventQuery query request
     * @returns void
     */
    public elasticRequest(eventQuery: string) {
        if (!this._entity) {
            ERROR('The entity is not set!');
            return;
        }
        const builder = QueryBuilder.fromEntity(this._entity);
        // Query string to search
        const query: QueryString<T> = {
            query: this.addWildcard(eventQuery),
            fields: this.propsToFilter
        };

        /** In the case of a ppemp entity we want to
         *  filter out the blocked and desactivated emps from the list
         */
        if (new this._entity() instanceof Ppemp) {
            const ppempQuery: Terms<any> = {
                'cempstatus.keyword': [
                    EmployeeStatus.BLOCKED,
                    EmployeeStatus.DEACTIVATED,
                    EmployeeStatus.CANDIDATE]
            };
            builder.addStatement(EsQueryStatement.fromTerms(ppempQuery, 'must_not'));
        }

        if (this.filter) {

            for (let key in this.filter) {
                if (this.filter.hasOwnProperty(key)) {
                    const obj: Partial<Record<keyof typeof this.filter, any>> = {};
                    obj[key] = this.filter[key];
                    builder.addStatement(EsQueryStatement.fromTerms(obj, 'must'));
                }
            }
        }

        // Adds wildcard query
        builder.addStatement(EsQueryStatement
            .fromQueryString(query));

        if (this.propsToFilter?.length) {
            builder.setSort(this.propsToFilter[0], 'asc');
        }

        this._restService.elasticSearchService.getScroll(this._entity, builder.getRequest(), 300)
            .next()
            .subscribe({
                next: e => {
                    this.filteredSuggestionsObject = e;
                    this.filteredSuggestions = e
                        .map(data => this.objectPropsToString(data));
                },
                error: () => this.filteredSuggestions = []
            });
    }

    public emitFullObject(event?: AutoCompleteSelectEvent) {
        const objectToEmit = this.filteredSuggestionsObject
            .find(object => this.objectPropsToString(object) === event?.value);
        this.selectedFromAutocomplete.emit(objectToEmit);
    }

    public onDoubleClick() {
        if (this.isDisabled) {
            return;
        }
        this.doubleClick.emit();
    }

    public inputCleared() {
        this.valueClear.emit();
    }

    private objectPropsToString(data: T) {
        const stringToReturn: string[] = [];

        this.propsToFilter?.forEach(prop => {
            const value = data[prop];
            if (value) {
                stringToReturn.push(stringifyIfNotString(value));
            }
        });

        return stringToReturn.join(' ');
    }

    private addWildcard(eventQuery: string) {
        const eventQuerySplitted: string[] = eventQuery.split(' ');

        return eventQuerySplitted.map(word => '*' + word + '*')
            .join(' ');
    }
}
