<div [ngClass]="className" class="iw-form-group iw-form-group-primeng iw-dropdown-group iw-dropdown-group--multi">
    <div class="iw-form-group-label" for="item">{{ label }}
    </div>
    <p-autoComplete #control (completeMethod)="onQueryChange($event)" (onKeyUp)="onKeyUp($event)"
                    (onSelect)="addValue($event)" (onUnselect)="removeValue($event)" [(ngModel)]="currentValue"
                    [disabled]="isDisabled" [dropdown]="true" [field]="renderText" [forceSelection]="!allowNewEntries"
                    [multiple]="true" [readonly]="isReadOnly" [suggestions]="dropdownValues"
                    class="iw-autocomplete iw-autocomplete--multi iw-form-group-autocomplete size-block">
        <ng-template let-item pTemplate="item">
            <div>
                {{ textSource(item) }}
            </div>
        </ng-template>
    </p-autoComplete>
</div>
