import {Directive, EventEmitter, OnDestroy, Output} from '@angular/core';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FormEditMode, IwStoreService} from '../core/store';
import * as actions from '../core/store/actions/global-form.actions';
import {BaseFormComponent} from './base-form.component';

/**
 * Base component, to be extended by EntityForms
 *
 * It uses a store UUID to update/navigate throught results
 * It is directly connected to GlobalFormStatus and to FormHandlerService
 */
@Directive()
export abstract class BaseStoreFormComponent<T> extends BaseFormComponent<T> implements OnDestroy {

    /** Trigger on is new status change */
    @Output() public isNewChanged = new EventEmitter<boolean>();
    @Output() public writeModeChange = new EventEmitter<boolean>();
    @Output() public dirtyChanged = new EventEmitter<boolean>();
    private _readonly = true;
    private _subs = new Subject();

    constructor(protected readonly _store: IwStoreService) {
        super();
    }

    /** Readonly status */
    public get isReadonly() {
        return this._readonly;
    }

    public get isWriteMode() {
        return this.isNew || this.editMode === 'edit';
    }

    private _entityValue: T | undefined;

    public get entityValue() {
        return this._entityValue;
    }

    public set entityValue(v: T | undefined) {
        this._entityValue = v;
    }

    private _isNew = false;

    /** Defines if is a new entry */
    public get isNew() {
        return this._isNew;
    }

    private _editMode: FormEditMode = 'read';

    /** Edit mode status */
    public get editMode() {
        return this._editMode;
    }

    private _isValid = false;

    /** Is valid */
    public get isValid() {
        return this._isValid;
    }

    private _isDirty = false;

    /** Is dirty */
    public get isDirty() {
        return this._isDirty;
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
        super.ngOnDestroy();
    }

    /** Called once, when the UUID value is set */
    public onUuidSet() {
        super.onUuidSet();
        this.initStoreSubscriptions();
    }

    /** Update store on every form change */
    public onFormChange() {
        super.onFormChange();
        const value = this.getFormData();
        if (this.isWriteMode && value) {
            this._store.dispatch(new actions.UpdateEntity(this.uuid, value));
            this.validateFormFields();
        }
    }

    /** Overrides the base method to return multiple fields */
    public getFormData() {
        const data = super.getFormData();
        if (data && this._entityValue) {
            return {
                ...this._entityValue, ...data
            };
        } else {
            return data;
        }
    }

    /** Emit event DESTROY FORM */
    public destroyForm() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    /** Emit Save EVENT */
    public saveChanges() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        setTimeout(() => {
            this._store.dispatch(new actions.SaveEntity(this.uuid));
        }, 1000);
    }

    /** Validates current entity and trigger state change */
    public triggerValidation() {
        const value = this.getFormData();
        if (value) {
            const toSatus = this.validateReadonlyStatus(value);
            if (toSatus !== this.isReadonly) {
                this._store.dispatch(new actions.SetReadonly(this.uuid, toSatus));
            }
            this.validateFormFields();
        }
    }

    /** Update current value changes */
    protected mergeEntityChanges(value: Partial<T> | undefined) {
        const baseValue = <T>(this.getFormData() || {});
        if (baseValue && value) {
            this._entityValue = {
                ...baseValue, ...value
            };
        }
        this.onFormChange();
    }

    /** Set current loading state */
    protected setLoadingState(loading: boolean) {
        this._store.dispatch(new actions.SetLoading(this.uuid, loading));
    }

    /** Subscribe to state changes in store */
    protected initStoreSubscriptions() {
        this._store.globalForm<T>(this.uuid)
            .entity
            .pipe(takeUntil(this._subs))
            .subscribe(e => this.onStoreValueUpdate(e));
        this._store.globalForm<T>(this.uuid)
            .isNew
            .pipe(takeUntil(this._subs))
            .subscribe(e => {
                this.setNewStatus(e);
                this.writeModeChange.emit(true);
            });
        this._store.globalForm<T>(this.uuid)
            .readonly
            .pipe(takeUntil(this._subs))
            .subscribe(e => {
                this._readonly = e;
                this.writeModeChange.emit(true);
            });
        this._store.globalForm<T>(this.uuid)
            .mode
            .pipe(takeUntil(this._subs))
            .subscribe(e => {
                this._editMode = e;
                this.writeModeChange.emit(true);
                this.validateFormFields();
            });
        this._store.globalForm<T>(this.uuid)
            .isValid
            .pipe(takeUntil(this._subs))
            .subscribe(e => this._isValid = e);
        this._store.globalForm<T>(this.uuid)
            .isDirty
            .pipe(takeUntil(this._subs))
            .subscribe(e => {
                this._isDirty = e;
                this.dirtyChanged.emit(e);
            });
    }

    /** Called when the store value changes for this entity */
    protected onStoreValueUpdate(e: T | undefined) {
        if (e) {
            this._entityValue = e;
            this.fillFormData(e);
            this.triggerValidation();
        } else {
            this._entityValue = undefined;
            this.clearForm();
        }
    }

    /**
     * Validates if current entity is in readonly status
     *
     * @param e current entity
     */
    protected validateReadonlyStatus(e: T): boolean {
        return false;
    }

    private validateFormFields() {
        const value = this.getFormData();
        if (value && this.isWriteMode) {
            const isValid = this.validateFields(value);
            this._store.dispatch(new actions.SetValid(this.uuid, isValid));
        }
    }

    private setNewStatus(isNew: boolean) {
        if (isNew !== this._isNew) {
            this._isNew = isNew;
            this.isNewChanged.emit(isNew);
        }
    }
}
