/* eslint-disable max-classes-per-file */
import {Type} from '@angular/core';
import {Action} from '@ngrx/store';

// ################### INIT FORM ENTITY STATE #######################
export const FORM_ENTITY_INIT = 'form entity init';

export class FormEntityInit<T> implements Action {
    public readonly type = FORM_ENTITY_INIT;

    constructor(public readonly uuid: string, public readonly entityType: Type<T>, public readonly entityId: string | number, public readonly useNavigation: boolean = true) {
    }
}

// #
export const FORM_ENTITY_LOAD = 'form entity load';

export class FormEntityLoad<T> implements Action {
    public readonly type = FORM_ENTITY_LOAD;

    constructor(public readonly uuid: string) {
    }
}

// ################### SET FORM ENTITY STATE #######################
export const FORM_ENTITY_NAVIGATE = 'form entity navigate to entity';

export class FormEntityNavigate<T> implements Action {
    public readonly type = FORM_ENTITY_NAVIGATE;

    constructor(public readonly uuid: string, public readonly entity: T) {
    }
}

// ################### UPDATE FORM ENTITY #######################
export const FORM_ENTITY_UPDATE = 'form entity update';

export class FormEntityUpdate<T> implements Action {
    public readonly type = FORM_ENTITY_UPDATE;

    constructor(public readonly uuid: string, public readonly entity: T) {
    }
}

// ################### SAVE FORM ENTITY #######################
export const FORM_ENTITY_SAVE = 'form entity save';

export class FormEntitySave implements Action {
    public readonly type = FORM_ENTITY_SAVE;

    constructor(public readonly uuid: string) {
    }
}

// #################### FORM ENTITY ACTIONS #########################
export type FormEntityActions<T> = | FormEntityInit<T> | FormEntityNavigate<T> | FormEntityUpdate<T> | FormEntitySave;
