import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {KeycloakService} from 'keycloak-angular';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment.test';
import {RestApiService, RestEntityClient} from '../rest-api';
import {GridProfile} from './models';

@Injectable()
export class GridProfileService {
    protected readonly _entityService: RestEntityClient<GridProfile<any>>;

    constructor(protected readonly _restService: RestApiService, private readonly _keycloak: KeycloakService, private _http: HttpClient) {
        this._entityService = this._restService
            .entityClient.getService(GridProfile);
    }

    public GetProfiles<T>(): Observable<GridProfile<T>[]> {
        return this._entityService.getRefData()
            .pipe(map(e => e.filter(v => v.userid === this._keycloak.getUsername())));
    }

    public AddProfiles<T>(p: GridProfile<T>): Observable<boolean> {
        return this._entityService
            .create(p)
            .pipe(catchError((err: any) => {
                ERROR('error creating profile', err);
                return of(false);
            }), map(e => !!e));
    }

    public UpdateProfiles<T>(p: GridProfile<T>): Observable<boolean> {
        return this._entityService
            .update(p)
            .pipe(catchError((err: any) => {
                ERROR('error updating profile', err);
                return of(false);
            }), map(e => !!e));
    }

    public UpdateProfileDefault<T>(p: GridProfile<T>): Observable<boolean> {
        return this._http.put(environment.backendURL + 'tableconfig/toggledefault/' + p.cfgid, p)
            .pipe(catchError((err: any) => {
                ERROR('error updating profile', err);
                return of(false);
            }), map(e => !!e));
    }

    public DeleteProfiles<T>(cfgid: string): Observable<boolean> {
        return this._entityService
            .delete(cfgid)
            .pipe(catchError((err: any) => {
                ERROR('error updating profile', err);
                return of(false);
            }), map(e => !!e));
    }
}
