import {SamUserRole} from '@sam-base/models/admin/sam-user';

/**
 * Defines the roles that have access to mission creation
 */
export function createMissionRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}
