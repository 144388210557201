import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HtmlElementStatus} from '../../../models';

@Component({
    selector: 'iw-modal-form', templateUrl: './modal-form.component.html', styleUrls: ['./modal-form.component.scss']
})
export class ModalFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public width = 500;

    @Input() public height = 500;

    @Input() public loading = false;

    @Input() public title = '';

    @Input() public leftBusy = 10;

    @Input() public topBusy = 10;

    @Input() public isDisabled = false;

    @Input() public btnOk: HtmlElementStatus = {
        enabled: true, value: 'Ok', visible: true
    };

    @Input() public btnCancel: HtmlElementStatus = {
        enabled: true, value: 'Cancel', visible: true
    };

    @Input() public display = true;

    @Output() public pressOk = new EventEmitter<void>();

    @Output() public pressCancel = new EventEmitter<void>();
    public clickMode: 'cancel' | 'ok' = 'cancel';
    private listen: any;

    public ngOnInit(): void {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }

    public ngAfterViewInit() {
        document.addEventListener('keypress', this.onEnter);
    }

    public ngOnDestroy() {
        document.removeEventListener('keypress', this.onEnter);
    }

    public onEnter(e: KeyboardEvent) {
        const buttonElement = document
            .getElementById('ok-create')?.firstElementChild?.firstElementChild?.firstElementChild;

        if (e.key === 'Enter') {
            if (buttonElement instanceof HTMLElement) {
                buttonElement.focus();
                buttonElement.click();
            }
        }
    }

    public onPressOk() {
        this.clickMode = 'ok';
        this.display = false;
    }

    public onPressCancel() {
        this.clickMode = 'cancel';
        this.display = false;
    }

    public onBeforeHide() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.clickMode === 'ok' ? this.pressOk.emit() : this.pressCancel.emit();
    }

}
