import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('reports')
export class PrintTemplate {
    @IwRestEntityId() public templateName = 'unkown';
    public reportName = 'unkown';
    public pdf = true;
    public xls = false;
    public grid = false;
    public entity = 'unkown';

    public static fromName(name: string) {
        const template = new PrintTemplate();
        template.templateName = name;
        return template;
    }
}
