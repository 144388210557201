import {Component, Type} from '@angular/core';
import {PlacementForm} from '@app/modules/sam-main/placement/placement.forms';
import {BaseColumnDetail} from '@app/sam-base/base/base-column-detail';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {EsQueryStatement, getEntityMetadata} from '@app/sam-base/core/rest-api';
import {IwGridColumn, RowClickEvent} from '@app/sam-base/models';
import {Ppsuivi} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {SaemsService} from '@sam-base/core/services/saems.service';
import {Saempc} from '@sam-base/models/salary/saempc';

@Component({
    templateUrl: './entity-detail.component.html'
})
export class EntityDetailEmployeeComponent<T> extends BaseColumnDetail<T> {
    public entity: Type<any> = Ppsuivi;

    public query: EsQueryStatement<T>[] = [];

    public noData = false;

    public empId = '';

    public tableData: {
        value: number; month: string; din: string; dout: string; nap: string; mntrep: string;
    }[] = [];

    public saemsObj: {
        value: number; nap: string; mntrep: string;
    }[] = [];

    public columns: IwGridColumn<any>[] = [
        {
            index: 0,
            prop: 'dateCreat',
            type: 'date',
            name: 'date'
        },
        {
            index: 1,
            prop: 'daterappel',
            type: 'date',
            name: 'rappel'
        },
        {
            index: 2,
            prop: 'nomconseille',
            name: 'conseiller'
        },
        {
            index: 3,
            prop: 'nomclient'
        },
        {
            index: 4,
            prop: 'action'
        }];

    public months = [
        this._translate.instant('january'),
        this._translate.instant('february'),
        this._translate.instant('march'),
        this._translate.instant('april'),
        this._translate.instant('may'),
        this._translate.instant('june'),
        this._translate.instant('july'),
        this._translate.instant('august'),
        this._translate.instant('september'),
        this._translate.instant('october'),
        this._translate.instant('november'),
        this._translate.instant('december')];

    constructor(private readonly _saemsService: SaemsService, private readonly _translate: TranslateService,
                private _formHandler: FormHandlerService<PlacementForm>) {
        super();
    }

    public async instaciate(row: T) {
        try {
            const meta = getEntityMetadata(row);
            const sta = EsQueryStatement.fromTerm({
                [meta.$pk]: meta.$getPk()
            }, 'filter');
            this.query = [
                sta];
        } catch {
        }
    }

    // fixme ULTIMATE Operations below
    // public async instaciateOperations(row: T) {
    //     const saemp = row as Saempview;
    //     this.empId = saemp.empId || '';
    //
    //     if (saemp.empId && saemp.annee) {
    //         this._saemsService
    //             .getSaemsByEmpIdAndYear(saemp.empId, saemp.annee)
    //             .subscribe((saems: Saems[]) => {
    //                 for (const element of saems) {
    //                     const salId = `${element.salId}`;
    //                     this.saemsObj.push({
    //                         value: parseInt(salId.substring(salId.length - 2), 10),
    //                         nap: `${element.mntnap}`,
    //                         mntrep: `${element.mntrep}`
    //                     });
    //                 }
    //                 this.mergeArrayObjects();
    //             });
    //     }
    // }

    // public getEntityName() {
    //     return 'saempview';
    // }

    public onDataChanged(data?: T[]) {
        if (data) {
            this.noData = data.length === 0;
        }
    }

    public getEntityName() {
        return 'ppemp';
    }

    public onRowDoubleClick(rowEvent: RowClickEvent<T>) {
        const entity = rowEvent.row;
        const meta = getEntityMetadata(entity);
        const pk = meta.$getPk() || '';

        this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
            suitype: 'E',
            action: 'PPEMPACTION'
        }, s => ({
            ...s,
            entityId: pk,
            diagTitle: 'title_ppempsui'
        }));
    }

    private mergeArrayObjects() {
        this.getSaempcData();
        this.tableData = this.tableData.map((data) => ({
            ...data, ...this.saemsObj.find((rep) => rep.value === data.value), ...this.saemsObj.find((nap) => nap.nap === data.nap)
        }));
    }

    private getSaempcData() {

        this._saemsService
            .getSaempcByEmpId(this.empId)
            .subscribe(saempc => {
                for (let i = 1; i <= 12; i++) {
                    const dinValue = `din${i}` as keyof Saempc;
                    const doutValue = `dout${i}` as keyof Saempc;
                    const napValue = `nap${i}` as keyof Saempc;
                    this.tableData.push({
                        value: i,
                        month: this.months[i - 1],
                        din: saempc[dinValue] as keyof Saempc,
                        dout: saempc[doutValue] as keyof Saempc,
                        nap: saempc[napValue] as keyof Saempc,
                        mntrep: ''
                    });
                }
            });
    }
}
