import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class DashboardNavigationService {

    constructor(private readonly _router: Router) {
    }

    public navigateToDashboard() {

        this._router.navigate([{
            outlets: {
                topPanel: ['dashboard']
            }
        }]);
    }

}
