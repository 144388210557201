import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppempaptattr')
export class Ppempaptattr {
    @IwRestEntityId() public aptattrId?: string;
    public aptId?: string;
    public empId?: string;
    public valeur?: string;
}
