import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

export enum PpgrhtypeEnum {
    heures_100 = '1',
    heures_supp = '2',
    heures_equipe = '3',
    heures_nuit = '4',
    heures_pause = '5',
    heures_supp_sup100 = 'A',
    heures_supp_inf100 = 'B',
    heures_equipe_custom = 'C',
}

@IwRestEntity('ppgrh')
export class Ppgrh {
    @IwRestEntityId() @IwColumn({index: 0, name: 'code'}) public grhId?: string;
    public cliId?: string;
    public grhName2?: string;
    public pprGsId?: string;
    @IwColumn({index: 2, name: 'gs'}) public gsId?: string;
    @IwColumn({index: 3, name: 'compte'}) public compte?: string;
    public followsh?: number;
    public grhActif?: string;
    public modCnt?: number;
    public grhName5?: string;
    public facteur?: number;
    public shortlib?: string;
    public fact2Gs?: string;
    public grhName4?: string;
    public prvGsId?: string;
    public userId?: string;
    public cligrpId?: string;
    public grhData?: string;
    public grhName3?: string;
    @IwColumn({index: 1, name: 'libelle'}) public grhName?: string;
    @IwColumn({index: 4, name: 'type'}) public grhType?: string;
    public grhName1?: string;
    public cpymiscpy?: string;
    public modData?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
