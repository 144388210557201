import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

export enum GePaytype {
    ENTREE = 'ENTREE', ATTRIBUTION = 'ATTRIBUTION', ENTREE_ATTRIBUTION = 'ENTREE_ATTRIBUTION'
}

@IwRestEntity('gepaimode')
export class Gepaimode {
    @IwRestEntityId() @IwColumn({
        name: 'code',
        index: 0
    }) public mpaId?: string;
    @IwColumn({
        name: 'titre',
        index: 1
    }) public titre?: string;
    public paytype?: GePaytype;
    public devise?: string;
    @IwColumn({
        name: 'compte',
        index: 2
    }) public compte?: string;
    public ltva?: boolean;
    public lbvr?: boolean;
    public lcamt?: boolean;
    public lqr?: boolean;
    public bvrnobnk?: string;
    public bvrnocli?: string;
    public bvrrefdef?: string;
    public bvrfileext?: string;
    public bvrdata?: string;
    public qriban?: string;
    public qrdata?: string;
    public pmdata?: string;
    public actif?: boolean;
    public userId?: string;

}
