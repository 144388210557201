import {Type} from '@angular/core';

import {MenuItem} from '../../../models/components/menu-item.model';
import {METADATA_IW_MENU_ITEM_OBJECT, METADATA_IW_MENU_ITEM_PROP} from '../models';
import {isObjectMenuItemMetadata} from './guards';

/** Push MenuItems into Object Metadata */
export function setMenuItemMetadata(target: any, items: MenuItem[], prop?: string): any {
    if (isObjectMenuItemMetadata(target)) {
        if (prop) {
            const propItems = target[METADATA_IW_MENU_ITEM_PROP][prop] || [];
            target[METADATA_IW_MENU_ITEM_PROP][prop] = [...propItems, ...items];
        } else {
            target[METADATA_IW_MENU_ITEM_OBJECT].push(...items);
        }

        return target;
    }

    return target;
}

/** Initialize metadata properties for a MenuItemObject */
export function initObjectMenuItemMetadata(target: any) {
    // eslint-disable-next-line no-null/no-null
    if (typeof target !== 'undefined' && target !== null) {
        target[METADATA_IW_MENU_ITEM_OBJECT] = target[METADATA_IW_MENU_ITEM_OBJECT] || [];
        target[METADATA_IW_MENU_ITEM_PROP] = target[METADATA_IW_MENU_ITEM_PROP] || {};

        return target;
    }

    return target;
}

/** Load METADATA from object */
export function getMenuItemMetadata(target: any) {
    if (isObjectMenuItemMetadata(target)) {
        return target;
    }

    return undefined;
}

/** Load METADATA from type */
export function loadMenuItemMetadata<T>(type: Type<T>) {
    const instance = new type();
    return getMenuItemMetadata(instance);
}

/** Load context menu items from Type */
export function loadMenuItems<T>(type: Type<T>, ...props: string[]) {
    const metadata = loadMenuItemMetadata(type);

    if (metadata) {
        const items = [...metadata[METADATA_IW_MENU_ITEM_OBJECT]];
        for (const p of props) {
            if (p) {
                const extraItems = metadata[METADATA_IW_MENU_ITEM_PROP][p] || [];
                items.push(...extraItems);
            }
        }
        return items;
    }

    return [];
}
