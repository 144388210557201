import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'iw-search-tooltip',
    templateUrl: './iw-search-tooltip.component.html',
    styleUrls: ['./iw-search-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [trigger('tooltip', [transition(':enter', [style({opacity: 0}), animate(300, style({opacity: 1}))]), transition(':leave', [animate(300, style({opacity: 0}))])])]
})
export class IwSearchTooltipComponent {

    constructor() {
    }
}
