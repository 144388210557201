import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pppointrgen')
export class Pppointrgen {
    @IwRestEntityId() public ptrgenId?: string;
    public pointtype?: string;
    public dtStart?: string;
    public ptficId?: string;
    public ptrData?: string;
    public userId?: string;
    public dtEnd?: string;
    public nbrap?: number;
}
