import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pphma')
export class Pphma {
    @IwRestEntityId() public matchname?: string;
    public matchtype?: string;
    public dateCreat?: string;
    public matchdata?: string;
    public matchkw?: string;
    public dateDelet?: string;
    public userDelet?: string;
    public dateModif?: string;
    public userId?: string;
    public userModif?: string;
    public userCreat?: string;
}
