export enum PlacementEvent {
    CREATE_ABSCENSE = 'open_modal#ppabssai',
    SHOW_EMPLOYEE_PLANING = 'open_modal#ppempplan',
    DELETE_DOCUMENT = 'delete_document',
    RENAME_DOCUMENT = 'rename_document',
    PPCLI_SEND_SMS = 'ppcli_send_sms',
    PPEMP_SEND_SMS = 'ppemp_send_sms',
    PPMIS_EMP_SEND_SMS = 'ppmis_emp_send_sms',
    PPMIS_CLI_SEND_SMS = 'ppmis_cli_send_sms',
    SEND_EMAIL_CLIENT = 'send_email_client',
    SEND_EMAIL_EMP = 'send_email_emp',
    SEND_EMAIL_CONTRACT_CLIENT = 'send_email_contract_client',
    SEND_EMAIL_CONTRACT_EMP = 'send_email_contract_emp',
    ABSCENCES = 'abscences',
    ACOMPTES = 'acomptes',
    INDEMNITES = 'indemnites',
    ATTESTATION_GAIN = 'attestation_gain',
    ATTESTATION_GAIN_INTERMEDAIRE = 'attestation_gain_intermedaire',
    ATTESTATION_EMPLOYER = 'attestation_employer',
    PPEMP_PRINT = 'ppemp_print',
    PPACO_NEW = 'ppaco_new',
    PPACO_MODIFICATION = 'ppaco_modification',
    PPACO_DELETE = 'ppaco_delete',
    SHOW_MISSIONS_PLANING = 'open_modal',
    PPINDSAID_CREATE = 'ppindsaid_create',
    PPINDSAID_UPDATE = 'ppindsaid_update',
    PPINDSAID_DELETE = 'ppindsaid_delete',
    PPSUI_UPDATE = 'ppsui_update',
    PPSUI_DELETE = 'ppsui_delete',
    PPSUI_NEW = 'ppsui_new',
    CLIENT = 'open_client',
    EMPLOYE = 'open_employe',
    MISSION = 'open_mission',
    ACOMPTES_RAPID = 'acompte_rapid',
    INVOICE = 'related_invoice',
    PLAC_FIXE = 'plac_fixe',
    SALARY = 'related_salary',
    PRINT_REPORT_EMP = 'print_report_emp',
    PRINT_REPORT_CLI = 'print_report_cli',
    PRINT_REPORT_MISCLI = 'print_report_miscli',
    PRINT_REPORT_MISEMP = 'print_report_misemp',
    PPCDE_LAUNCH_ADVSEARCH = 'ppcde_launch_advanced_search',
    PPCDF_LAUNCH_ADVSEARCH = 'ppcdf_launch_advanced_search',
    PPMISS_NEW = 'ppmis_new',
    PPEMP_OPEN_MISSION = 'ppemp_open_mission',
    PPEMP_OPEN_RAPPORTS = 'ppemp_open_rapports',
    PPCLI_OPEN_MISSION = 'ppcli_open_mission',
    PPCLI_OPEN_RAPPORTS = 'ppcli_open_rapports',
    PPMIS_OPEN_RAPPORTS = 'ppmis_open_rapports',
    PPCLI_OPEN_PPCDE_GRID = 'ppcli_open_ppcde_grid',
    PPCLI_OPEN_PPCDFS_GRID = 'ppcli_open_ppcdfs_grid',
}
