import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

/**
 * Columns defined according SAM-4181
 */
@IwRestEntity('ppind')
export class Ppind {
    @IwColumn({
        index: 0,
        name: 'nom'
    }) public nomemploye?: string;
    @IwColumn({
        index: 1,
        name: 'periode',
        type: 'activePer'
    }) public salId?: string;
    @IwColumn({
        index: 2,
        name: 'date',
        type: 'date'
    }) public indDate?: string;
    @IwColumn({
        index: 3,
        name: 'typeIndemnite',
        hidden: true
    }) public indType?: string;
    @IwColumn({
        index: 4,
        name: 'type'
    }) public gritext?: string;
    @IwColumn({
        index: 5,
        type: 'number'
    }) public qte?: number;

    @IwColumn({
        index: 6,
        type: 'number',
        name: 'montant',
        decimals: 2,
        align: 'right'
    }) public mntemp?: number;

    @IwColumn({
        index: 7,
        type: 'number',
        name: 'total',
        decimals: 2,
        align: 'right'
    }) public totemp?: number;

    @IwColumn({
        index: 8,
        type: 'number',
        name: 'montantClient',
        hidden: true,
        decimals: 2,
        align: 'right'
    }) public mntcli?: number;

    @IwColumn({
        index: 9,
        type: 'number',
        name: 'totalClient',
        hidden: true,
        decimals: 2,
        align: 'right'
    }) public totcli?: number;

    @IwColumn({index: 10}) public libelle?: string;
    @IwColumn({
        index: 11,
        type: 'number',
        name: 'sal'
    }) public sent2saltext?: string;
    @IwColumn({
        index: 12,
        hidden: true
    }) public session?: string;
    @IwColumn({
        index: 13,
        name: 'utilisateur'
    }) public userCreat?: string;
    @IwColumn({index: 14}) public cctId?: string;
    @IwColumn({
        index: 15,
        name: 'mission'
    }) public misId?: string;
    @IwColumn({
        index: 16,
        name: 'id',
        hidden: true
    }) public misihId?: string;
    @IwColumn({
        index: 17,
        name: 'rapport',
        hidden: true
    }) public rapId?: string;
    @IwRestEntityId() @IwColumn({
        index: 18,
        name: 'idIndemnite',
        hidden: true
    }) public indId?: string;

    public griId?: string;
    public griName?: string;
    public empId?: string;
    public userModif?: string;
    public ageId?: string;
    public dateA?: string;
    public modecreat?: string;
    public dateDe?: string;
    public dateCreat?: string;
    public userId?: string;
    public unite?: string;
    public dtanymodif?: string;
    public dateDelet?: string;
    public dateModif?: string;
    public totcli2?: number;
    public jfId?: string;
    public userDelet?: string;
    public salIdSal?: string;
    public mntcli2?: number;
    public saisiemode?: string;
    public notes?: string;
    public datasessionid?: string;
    public lgeneaco?: string;
    public sent2sal?: boolean;
}
