<div [ngClass]="classValue" class="iw-form-group iw-form-group-primeng iw-dropdown-group">
    <div *ngIf="!noLabel" class="iw-form-group-label" for="item">{{ label }}
    </div>
    <p-autoComplete #item (completeMethod)="onQueryChange($event)" (onBlur)="onBlur()"
                    (onSelect)="onChange()" [(ngModel)]="selected" [appendTo]="'body'"
                    [disabled]="isDisabled" [dropdown]="true" [field]="getLabelValueGenerator()"
                    [forceSelection]="forceSelection" [inputStyle]="styleValue" [ngStyle]="styleValue"
                    [suggestions]="dropdownOptions" class="iw-autocomplete iw-form-group-autocomplete">
        <ng-template let-item pTemplate="item">
            <div>
                {{ getLabelValue(item) }}<span *ngIf="entityExtraProperties" style="float: right">
          {{ getEntityExtraPropertiesLabel(item) }}
        </span>
            </div>
        </ng-template>
    </p-autoComplete>
</div>
