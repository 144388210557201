import {IwColumn} from '@app/sam-base/core/column';

import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('sarep')
export class Sarep {
    @IwRestEntityId() @IwColumn({index: 0, name: 'code'}) public key?: string;
    public centre?: string;
    public centype?: string;
    @IwColumn({index: 1, name: 'valeur', decimals: 2}) public valeur?: number;
    public userModif?: string;
    public userCreat?: string;
    public userDelete?: string;
    public dateModif?: string;
    public dateCreat?: string;
    public dateDelete?: string;
}
