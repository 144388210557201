import {Injectable} from '@angular/core';
import {jwtDecode} from 'jwt-decode';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile} from 'keycloak-js';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {ProfileAttributes, TokenParameters, UserKeycloakProfile, UserProfile} from './user-profile.model';

/** Service to acces user configuration */
@Injectable({providedIn: 'root'})
export class ProfileService {

    constructor(private readonly _keycloak: KeycloakService) {
    }

    public static langParser(lang: string) {
        switch (Number(lang)) {
            case 1:
                return 'fr';
            case 3:
                return 'de';
            case 4:
                return 'it';
            case 5:
                return 'es';
            default:
                return 'en';
        }
    }

    public static langDateParser(lang: string) {
        switch (Number(lang)) {
            case 1:
                return 'fr-ch';
            case 3:
                return 'de-DE';
            case 4:
                return 'it-IT';
            case 5:
                return 'es-ES';
            default:
                return 'en-gb';
        }
    }

    public loadProfile(): Observable<UserProfile> {
        return this.loadKeycloakProfile()
            .pipe(switchMap(p => this.buildProfile(p)));
    }

    public checkUserPermission(groupsIds: string[]): boolean {
        const userGroups: string[] = this._keycloak.getUserRoles().map(role => role.toUpperCase());

        if (userGroups.length === 0) {
            return false;
        }

        return groupsIds.some(group => this.checkPermissionGroup(userGroups, group));
    }

    public checkUserPermissionMandatory(groupsIds: string[], mandatoryGroupsIds: string[]): boolean {
        const userGroups: string[] = this._keycloak.getUserRoles();
        if (!this.checkMandatoryPermissionGroup(userGroups, mandatoryGroupsIds)) {
            return false;
        }
        return this.checkUserPermissionMandatoryHelper(groupsIds, userGroups);
    }

    public async getTenant(): Promise<string> {
        const token: TokenParameters = jwtDecode(await this._keycloak.getToken());
        return token.tenant;
    }

    private checkUserPermissionMandatoryHelper(groupsIds: string[], userGroups: string[]) {
        if (!groupsIds || groupsIds.length === 0) {
            return true;
        } else if (userGroups.length === 0) {
            return false;
        }
        return this.checkGroupPermissions(groupsIds, userGroups);
    }

    private checkGroupPermissions(groupsIds: string[], userGroups: string[]) {
        for (const group of groupsIds) {
            if (this.checkPermissionGroup(userGroups, group)) {
                return true;
            }
        }
        return false;
    }

    private checkPermissionGroup(userGroups: string[], group: string): boolean {
        return userGroups.includes(group);
    }

    private checkMandatoryPermissionGroup(userGroups: string[], mandatoryGroups: string[]): boolean {
        if (!mandatoryGroups || mandatoryGroups.length === 0) {
            return true;
        }
        for (const group of mandatoryGroups) {
            const permissionFound = this.checkPermissionGroup(userGroups, group);
            if (!permissionFound) {
                return false;
            }
        }
        return true;
    }

    private buildProfile(p: UserKeycloakProfile): Observable<UserProfile> {
        return from((async () => ({
            firstName: p.firstName,
            lastName: p.lastName,
            username: p.username,
            userId: p.attributes.userId[0],
            lang: p.attributes.lang[0] ?? '2', // Default english
            gestions: this.loadGestions(p.attributes.gestallowd),
            groups: this._keycloak.getUserRoles(),
            uriPattern: p.attributes.uriPattern?.length ? p.attributes.uriPattern[0] : ' '
        } as UserProfile))());
    }

    private loadGestions(list: string[]): string[] {
        return list.flatMap(e => e.split('-')
            .filter(z => !!z))
            .map((gestion: string) => gestion.toUpperCase());
    }

    private mapKeycloakProfileToUserKeycloakProfile(profile: KeycloakProfile): UserKeycloakProfile {
        let profileAttributes: ProfileAttributes = {
            fullName: profile.attributes?.fullName as string[],
            gestallowd: profile.attributes?.gestallowd as string[],
            gestrigths: profile.attributes?.gestrigths as string[],
            groups: profile.attributes?.groups as string[],
            lang: profile.attributes?.lang as string[],
            locale: profile.attributes?.locale as string[],
            opback: profile.attributes?.opback as string[],
            userId: profile.attributes?.userId as string[],
            uriPattern: profile.attributes?.uriPattern as string[]
        };
        return {
            emailVerified: profile.emailVerified ?? false,
            username: profile.username ?? '',
            firstName: profile.firstName ?? '',
            lastName: profile.lastName ?? '',
            attributes: profileAttributes
        };
    }

    private loadKeycloakProfile(): Observable<UserKeycloakProfile> {
        const promise = new Promise<UserKeycloakProfile>((res, rej) => {
            this._keycloak.loadUserProfile()
                .then(e => res(this.mapKeycloakProfileToUserKeycloakProfile(e)), err => rej(err))
                .catch(err => rej(err));
        });
        return from(promise);
    }
}
