import {IwColumn} from '@app/sam-base/core/column';
import {IwContextMenuClass} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwRestEntity, IwRestEntityId, SearchEntityStatusOperator} from '@app/sam-base/core/rest-api';
import {MenuItem} from '@app/sam-base/models';
import * as clientRoles from '@sam-base/core/auth/access-rules/client-form';

import {PlacementEvent} from './events.models';

const entityContextMenu: MenuItem[] = [
    {
        label: 'ppmis_contextmenu_open_emp',
        event: PlacementEvent.EMPLOYE,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppmis_contextmenu_open_cli',
        event: PlacementEvent.CLIENT,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppmis_contextmenu_open_reports',
        event: PlacementEvent.PPMIS_OPEN_RAPPORTS,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppmis_contextmenu_sendsms_cli',
        event: PlacementEvent.PPMIS_CLI_SEND_SMS,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppmis_contextmenu_sendsms_emp',
        event: PlacementEvent.PPMIS_EMP_SEND_SMS,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppmis_contextmenu_sendemail_cli',
        event: PlacementEvent.SEND_EMAIL_CLIENT,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppmis_contextmenu_sendemail_emp',
        event: PlacementEvent.SEND_EMAIL_EMP,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'send_mission_contract_employee',
        event: PlacementEvent.SEND_EMAIL_CONTRACT_EMP,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'send_mission_contract_client',
        event: PlacementEvent.SEND_EMAIL_CONTRACT_CLIENT,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppemp_contextmenu_print_location',
        event: PlacementEvent.PRINT_REPORT_MISEMP,
        accessRoles: clientRoles.contextMenuPrintRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppemp_contextmenu_print_contract',
        event: PlacementEvent.PRINT_REPORT_MISCLI,
        accessRoles: clientRoles.contextMenuPrintRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'contextmenu_suivi',
        event: PlacementEvent.PPSUI_NEW,
        contextMenuVisibleMode: 'single'
    },
    {
        separator: true
    }];

@IwContextMenuClass(...entityContextMenu) @IwRestEntity('ppclimis')
export class Ppclimis {
    @IwColumn({
        index: 0,
        type: 'misStatus'
    }) @IwRestEntityId() public misId?: string;
    @IwColumn({
        index: 1,
        type: 'status',
        filterQuery: '',
        statusQuery: [
            true,
            true],
        valueFormat: 'ppmis_status_',
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public misstatustext?: string;
    public misstatus?: number;
    @IwColumn({
        index: 2,
        hidden: true
    }) public cliId?: string;
    @IwColumn({index: 3}) public nomclient?: string;
    @IwColumn({
        index: 4,
        name: 'ctaEmpId',
        hidden: true
    }) public empId?: string;
    @IwColumn({
        index: 5,
        name: 'nomemploye'
    }) public empNom?: string;
    @IwColumn({
        index: 5,
        name: 'prenom'
    }) public empPrenom?: string;
    @IwColumn({
        index: 6,
        hidden: true
    }) public permis?: string;
    @IwColumn({
        index: 7,
        hidden: true
    }) public permisno?: string;
    @IwColumn({
        index: 8,
        indexed: false
    }) public genretrav?: string;
    @IwColumn({index: 9}) public qualif?: string;
    @IwColumn({
        index: 10,
        type: 'date'
    }) public datedebut?: string;
    @IwColumn({
        index: 11,
        type: 'date'
    }) public datefin?: string;
    @IwColumn({
        index: 12,
        type: 'status',
        valueFormat: 'ppmis_duration_status_',
        width: 80,
        filterQuery: '',
        statusQuery: [],
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public duree?: string;
    @IwColumn({
        index: 13,
        hidden: true,
        indexed: false
    }) public horaire?: string;
    @IwColumn({
        index: 14,
        indexed: false
    }) public lieu1?: string;
    @IwColumn({
        index: 15,
        indexed: false
    }) public lieu2?: string;
    @IwColumn({
        index: 16,
        indexed: false
    }) public lieu3?: string;
    @IwColumn({
        index: 17,
        type: 'mnt',
        name: 'tarifClient',
        decimals: 2
    }) public clitarif?: number;
    @IwColumn({
        index: 18,
        type: 'mnt',
        decimals: 2
    }) public salGlobal?: number;
    @IwColumn({
        index: 19,
        hidden: true
    }) public resp?: string;
    @IwColumn({
        index: 20,
        hidden: true
    }) public chantier?: string;
    @IwColumn({
        index: 21,
        hidden: true
    }) public conseiller?: string;
    @IwColumn({
        index: 22,
        hidden: true
    }) public ageId?: string;
    @IwColumn({index: 21}) public cctId?: string;
    public conId?: string;
    @IwColumn({
        index: 23,
        hidden: true
    }) public secId?: string;
    @IwColumn({
        index: 24,
        name: 'syncIJ',
        type: 'boolean',
        hidden: true
    }) public syncIj?: boolean;
    @IwColumn({
        index: 25,
        type: 'boolean',
        hidden: true
    }) public dateFlexible?: boolean;
    public nomsecteur?: string;
    @IwColumn({index: 26}) public employeEmail?: string;
    @IwColumn({index: 27}) public employeNatel?: string;
    @IwColumn({index: 28}) public tel?: string;
    @IwColumn({index: 29}) public secLib?: string;
    @IwColumn({
        index: 30,
        name: 'creating',
        hidden: true,
        type: 'boolean'
    }) public creating?: boolean;
    @IwColumn({
        index: 31,
        hidden: true,
        type: 'dateTime'
    }) public dateCreat?: string;
    @IwColumn({
        index: 32,
        type: 'dateTime'
    }) public dateModif?: string;
    @IwColumn({
        index: 33,
        name: 'modeSalary',
        type: 'status',
        valueFormat: 'ppmis_salmode_',
        width: 80,
        filterQuery: '',
        statusQuery: [],
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public missalmodetxt?: string;
    @IwColumn({
        index: 34,
        name: 'modeFacture',
        type: 'status',
        valueFormat: 'ppmis_facmode_',
        width: 80,
        filterQuery: '',
        statusQuery: [],
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public misfacmodetxt?: string;
    public cdeId?: string;
    public datefinp?: string;
    public finmotif?: string;
    public clitarifra?: string;
    public salTotal?: string;
    public misfacmode?: string;
    public missalmode?: string;
    public coeff?: string;
    public usertag?: string;
    public conId2?: string;
    public conid2Pc?: string;
    public ijRapSm?: string;
    public ccnbofprt?: number;
    public cmnbofprt?: number;
    public mistermin?: boolean;
    @IwColumn({
        index: 35,
        hidden: true
    })
    public conseillersec?: string;
}
