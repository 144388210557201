import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {DEBUG} from '@sam-base/core';
import {saveAs} from 'file-saver';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ToastService} from '../toast';
import {isArrayBufferResponse} from './helpers/validate-response.helper';
import {IwReportOption} from './models/report-options.model';
import {IwReportContentType} from './models/report-type.model';

@Injectable()
export class IwReportsService extends RestClient<unknown> {

    constructor(http: HttpClient, private readonly _toast: ToastService) {
        super(http);
    }

    /**
     * Build the report and downloads it
     *
     * @template T type of template params
     * @param option report options
     * @param [fileName] file name to save as
     * @returns success status
     * @memberof IwReportsService
     */
    public downloadReport<T>(option: IwReportOption<T>, fileName?: string): Observable<boolean> {
        return <Observable<boolean>>this.reportHandling(option, false, fileName);
    }

    /**
     * Build the report and returns the report
     *
     * @template T type of template params
     * @param option report options
     * @returns generated report
     * @memberof IwReportsService
     */
    public getReport<T>(option: IwReportOption<T>, fileName?: string): Observable<Blob> {
        return <Observable<Blob>>this.reportHandling(option, true, fileName);
    }

    /**
     * Build a report and return result, false if failed
     *
     * @template T
     * @param option report options
     * @returns result
     * @memberof IwReportsService
     */
    public buildReport<T>(option: IwReportOption<T>): Observable<string | ArrayBuffer | boolean> {
        return this.buildReportTemplate(option)
            .pipe(catchError(err => {
                DEBUG('Error building report', err);
                return of(false);
            }));
    }

    protected getRequestUrl(...parts: string[]) {
        return super.getRequestUrl('report', ...parts);
    }

    /**
     * Logic to deal with report handling
     *
     * @template T type of template params
     * @param option report options
     * @param [fileName] file name to save as
     * @param returnReportOnly allows the return of the report only
     * @returns success status
     * @memberof IwReportsService
     */
    private reportHandling<T>(option: IwReportOption<T>, returnReportOnly: boolean,
                              fileName?: string): Observable<Blob | boolean> {
        return this.buildReport(option)
            .pipe(map(data => {
                if (typeof data === 'boolean') {
                    return false;
                }
                const mimeType = IwReportContentType[option.outputType];
                const name = `${fileName || option.report}.${option.outputType}`;

                const blobData = new Blob([data], {type: mimeType});
                if (!returnReportOnly) {
                    saveAs(blobData, name);
                }

                return returnReportOnly ? blobData : true;
            }));
    }

    private buildReportTemplate<T>(option: IwReportOption<T>): Observable<string | ArrayBuffer> {
        const urlParts = [
            option.outputType.toUpperCase(),
            option.report.toUpperCase()];

        const url = this.getRequestUrl(...urlParts);
        const body = option.params || {};

        let params: HttpParams = new HttpParams()
        if (option.templateId) {
            params = params.append('templateId', option.templateId);
        }
        if (option.id) {
            params = params.append('entityId', option.id);
        }

        return isArrayBufferResponse(option) ? this.POST_ARRAYBUFFER(url, params, body) : this.POST_TEXT(url, params, body);
    }

    private POST_TEXT(url: string, params: HttpParams, body: any) {
        return this._http.post(url, body, {
            params,
            responseType: 'text'
        });
    }

    private POST_ARRAYBUFFER(url: string, params: HttpParams, body: any) {
        return this._http.post(url, body, {
            params,
            responseType: 'arraybuffer'
        });
    }
}
