import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';

import {
    EsExists,
    EsMatch,
    EsQueryBool,
    EsQueryEntryTypes,
    EsQueryString,
    EsRange,
    EsSimpleQueryString,
    EsTerm,
    EsTerms,
    EsWildcard,
    Match,
    Range,
    SimpleQueryString,
    Term,
    Terms
} from '../models';
import {QueryString} from '../models/query/query-string.model';

export type QueryStatementType = keyof EsQueryBool<any>;

export class EsQueryStatement<Z> {
    public readonly statement: EsQueryEntryTypes<Z>;

    constructor(statement: EsQueryEntryTypes<Z>, public readonly type: QueryStatementType = 'must') {
        this.statement = statement;
    }

    public static fromSimpleQuery<Z>(query: SimpleQueryString<Z>, type: QueryStatementType = 'must') {
        return new EsQueryStatement(<EsSimpleQueryString<Z>>{simple_query_string: query}, type);
    }

    public static fromQueryString<Z>(query: QueryString<Z>, type: QueryStatementType = 'must') {
        // todo : we removed the add keyword for now as it does not seem to be working after our changes of elastic indexing from java.
        // return new EsQueryStatement(<EsQueryString<Z>>{query_string: this.addKeywordAnnotation<Z>(query)}, type);
        return new EsQueryStatement(<EsQueryString<Z>>{query_string: query}, type);
    }

    public static fromMatch<Z>(match: Match<Z>, type: QueryStatementType = 'must') {
        return new EsQueryStatement(<EsMatch<Z>>{match}, type);
    }

    public static fromTerm<Z>(term: Term<Z>, type: QueryStatementType = 'must') {
        return new EsQueryStatement(<EsTerm<Z>>{term}, type);
    }

    public static fromTerms<Z>(terms: Terms<Z>, type: QueryStatementType = 'must') {
        return new EsQueryStatement(<EsTerms<Z>>{terms}, type);
    }

    public static fromWildcard<Z>(term: Term<Z>, type: QueryStatementType = 'must') {
        return new EsQueryStatement(<EsWildcard<Z>>{wildcard: term}, type);
    }

    public static fromRange<Z>(range: Range<Z>, type: QueryStatementType = 'must') {
        return new EsQueryStatement(<EsRange<Z>>{range}, type);
    }

    public static fromExists<Z>(field: Extract<keyof Z, string>, type: QueryStatementType = 'must') {
        return new EsQueryStatement(<EsExists<Z>>{exists: {field}}, type);
    }

    private static addKeywordAnnotation<Z>(query: QueryString<Z>): QueryString<Z> {
        const parsedQuery: QueryString<Z> = getDeepCopy(query);
        if (parsedQuery.fields?.length && parsedQuery.fields !== '*') {
            parsedQuery.fields = parsedQuery.fields
                .map(field => String(field) + '.keyword') as (keyof Z)[];
            return parsedQuery;
        }
        return parsedQuery;
    }
}
