import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Fofou} from '@app/sam-base/models/fornisseurs/fofou';
import {Fofoudp} from '@app/sam-base/models/fornisseurs/fofoudp';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class FofouService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getAllFofou(): Observable<Fofou[]> {
        return this._http.get<Fofou[]>(`/api/fofou/`);
    }

    public getNomFromFofou(fofouId: string): Observable<Fofou> {
        return this._http.get<Fofou>(`/api/fofou/${fofouId}`);
    }

    public getFofoudpByFouId(fouId: string): Observable<Fofoudp[]> {
        return this._http.get<Fofoudp[]>(`/api/fofoudp/find/${fouId}`);
    }

    public getFofoudp(fofoudpId: string): Observable<Fofoudp> {
        return this._http.get<Fofoudp>(`/api/fofoudp/${fofoudpId}`);
    }
}
