import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppmismdl')
export class Ppmismdl {
    @IwRestEntityId() public mismdlId?: string;
    @IwColumn({index: 0, name: 'libelle'}) public mismdlLib?: string;
    @IwColumn({index: 1, name: 'client'}) public nomclient?: string;
    @IwColumn({index: 2}) public qualif?: string;
    @IwColumn({index: 3, name: 'cct'}) public cctId?: string;
    @IwColumn({index: 4, name: 'mission'}) public misId?: string;
    public cliId?: string;
    public modData?: string;
    public userCreat?: string;
    public nogestion?: string;
    public notes?: string;
    public dateCreat?: string;
    public secId?: string;
    public cligrpId?: string;
    public mdlData?: string;
    public dateModif?: string;
    public mdlstatus?: number;
    public mistype?: string;
    public quagrpId?: string;
    public userId?: string;
    public dtanymodif?: string;
    public userDelet?: string;
    public dateDelet?: string;
    public xmlData?: string;
    public modCnt?: number;
    public userModif?: string;
    public lallcli?: boolean;
}
