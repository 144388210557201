import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable, Type} from '@angular/core';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {joinPath} from '../../../helpers/path';
import {getEnvironment} from '../../environment/environment';
import {EsScrollQuery} from './es-scroll-query';
import {EsError, EsRequest, EsSearchSuccess} from './models';
import {EsCount} from './models/response/es-count.model';
import {mapEsCountResult, mapEsSearchResult} from './models/response/es-response.model';

/**
 * Service to query the Elastic search endpoint
 */
@Injectable()
export class EsQueryService {
    /** Max number of results per request */
    public static MAX_RESULTS = 10000;

    constructor(private readonly _http: HttpClient) {
    }

    // Elastic search url endpoint
    protected get url() {
        return getEnvironment().esURL;
    }

    public getScroll<T>(type: Type<T>, req: EsRequest<T>, size = 100) {
        return new EsScrollQuery(this._http, type, req, size);
    }

    /** Query Elastic search */
    public query<T>(req: EsRequest<T>) {
        const requestUrl = joinPath(this.url, 'search');
        return this._http
            .post<EsSearchSuccess<T>>(requestUrl, req)
            .pipe(catchError((err: HttpErrorResponse) => of(<EsError>err.error)), map(e => mapEsSearchResult<T>(e)));
    }

    /** Count results for a query */
    public queryCount<T>(req: EsRequest<T>) {
        const requestUrl = joinPath(this.url, 'count');
        return this._http
            // Only the query is passed
            .post<EsCount>(requestUrl, <EsRequest<T>>{query: req.query})
            .pipe(catchError((err: HttpErrorResponse) => of(<EsError>err.error)), map(e => mapEsCountResult(e)));
    }
}
