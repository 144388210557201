import {ElementRef, Injectable} from '@angular/core';
import {WARNING} from '@app/sam-base/core/logger';

import {ElementRefService} from '../tools/elem-ref.service';
import {DomEvent} from './dom-event';

@Injectable()
export class DomEventService {
    public window = {
        resize: DomEvent.fromWindow<UIEvent>('resize'), keydown: DomEvent.fromWindow<KeyboardEvent>('keydown')
    };

    constructor(public readonly elemRef: ElementRefService) {
    }

    /**
     * Add event listener
     *
     * @param elem Element to add event
     * @param type Type of event
     * @param selector select an element in source
     */
    public addListener<T>(elem: ElementRef | Element, type: string, selector?: string): DomEvent<T> {
        return DomEvent.create<T>(type, (eventType, listener) => {
            const el = elem instanceof ElementRef ? this.elemRef.querySelector(elem, selector) : elem;

            if (el) {
                el.addEventListener(eventType, e => listener(<any>e));
            } else {
                WARNING('element not found');
            }
        });
    }
}
