import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {IwEventHubService, RestApiService} from '@app/sam-base/core';
import {isObject, isStringArray} from '@app/sam-base/helpers/guards';
import {Ppcct, Ppcli} from '@app/sam-base/models/placement';
import {AutoComplete, AutoCompleteCompleteEvent} from 'primeng/autocomplete';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'iw-cct-client-dropdown',
    templateUrl: './iw-cct-client-dropdown.component.html',
    providers: [buildCustomValueProvider(IwCctClientDropdownComponent)]
})
export class IwCctClientDropdownComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input() public labelAlign: 'top' | 'left' | 'right' = 'top';

    @Input() public label = '';

    @Input() public warningMessage?: string;

    @Input() public size: 'small' | 'medium' | 'large' | 'auto' = 'medium';

    @Input() public isDisabled = false;
    @Input() public selected?: Ppcct;
    /** Field to use has id */
    @Input() public idField: keyof Ppcct = 'cctId';
    public clientId?: string;
    @Output() public selectedIdChange = new EventEmitter<string>();
    @Output() public valueChange = new EventEmitter<string>();
    @Output() public selectedChange = new EventEmitter<Ppcct>();
    @Output() public listUpdated = new EventEmitter<void>();
    @ViewChild('item', {static: true}) public autoComplete?: AutoComplete;
    public items: Ppcct[] = [];
    public dropdownOptions: Ppcct[] = [];
    protected _clientCcts?: string[];
    protected _onChange?: any;
    protected _subscription = new Subject();
    protected _lkname = '';

    constructor(private elRef: ElementRef, private _restService: RestApiService, private readonly _events: IwEventHubService<string>) {
        this._events.forType<string>('mission_cliId')
            .pipe(takeUntil(this._subscription))
            .subscribe((e) => {
                this._restService
                    .getEntityClient(Ppcli)
                    .getById(e.payload || '')
                    .subscribe((cli: Ppcli) => {
                        this._clientCcts = cli.cctids?.split(';');
                        this.subscriveValues();
                    });
            });
    }

    public get value() {
        return this.selectedId;
    }

    @Input()
    public set value(v: string | undefined) {
        this.selectedId = v;
    }

    public get classValue() {
        return {
            [this.labelAlign]: true, ['size-' + this.size]: true
        };
    }

    protected _selectedId?: string;

    public get selectedId() {
        return this._selectedId;
    }

    @Input() /** The id of the selected option */ public set selectedId(id: string | undefined) {
        if (this._selectedId === id) {
            return;
        }
        this._selectedId = id;
        this.loadSelected();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public ngOnInit() {
        this.listUpdated.emit();
    }

    public ngOnDestroy() {
        this._subscription.next(undefined);
        this._subscription.complete();
    }

    public writeValue(obj: any): void {
        this.selectedId = obj;
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public onChange() {
        this._selectedId = this.getSelectedId();
        this.selectedChange.emit(this.selected);
        this.selectedIdChange.emit(this.selectedId);
        this.valueChange.emit(this.value);

        if (typeof this._onChange === 'function') {
            this._onChange(this.selectedId);
        }
    }

    // eslint-disable-next-line complexity
    public onBlur() {
        if (!this.selected && this.dropdownOptions) {
            const data = this.dropdownOptions;
            if (data && data.length === 1) {
                this.selected = data[0];
                this.onChange();
            }
        }
    }

    public getLabelValueGenerator() {
        return (e: Ppcct) => this.getLabelValue(e);
    }

    public onQueryChange({query}: AutoCompleteCompleteEvent) {
        if (!query) {
            this.dropdownOptions = [...this.items];
            return;
        }

        this.dropdownOptions = this.items
            .filter(l => this.compareEntityToQuery(l, query));
    }

    public getLabelValue(e: Ppcct) {
        return e.cctLib;
    }

    private getSelectedId(): string | undefined {
        if (isObject(this.selected)) {
            return <string>(this.selected)[this.idField];
        }

        return undefined;
    }

    private loadSelected() {
        if (!this._selectedId && !!this.selected) {
            this.selected = undefined;
            this.selectedChange.emit(this.selected);
            return;
        }
        this.selected = this.items.find(e => e[this.idField] === this._selectedId);
        this.selectedChange.emit(this.selected);
    }

    private subscriveValues() {
        this._restService
            .getEntityClient(Ppcct)
            .getRefData()
            .subscribe(r => {
                this.items = r;
                // Filter by the cct present on the client and the active cct (status 1)
                this.items = this.items.filter((v: Ppcct) => this._clientCcts?.includes(v.cctId || '') && v.cctstatus === '1');
                this.dropdownOptions = [...this.items];
                this.loadSelected();
            });
    }

    private generateLabelFromArray(e: Ppcct, list: (keyof Ppcct)[]): string {
        if (isStringArray(list)) {
            return list.map(s => e[s])
                .join(' ');
        }

        return '';
    }

    private compareEntityToQuery(e: Ppcct, query: string): boolean {
        if (!query) {
            return true;
        }
        const value = '' + this.getLabelValue(e);
        return value.toLocaleLowerCase()
            .indexOf(query.toLocaleLowerCase()) !== -1;
    }

}
