<div [ngClass]="className" class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" [ngStyle]="{'max-width': labelWidth }" class="iw-form-group-label"
         for="item">{{ label | translate }}
    </div>
    <p-editor [(ngModel)]="value" [ngClass]="{'iw-editor': true, 'hidden-toolbar': isDisabled}" [readonly]="isDisabled"
              [style]="{'height': labelHeight}">
        <!-- in disabled, we remove the toolbar, hence the empty p-header -->
        <p-header *ngIf="isDisabled"></p-header>
    </p-editor>
</div>
