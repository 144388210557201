import {Component, ComponentRef} from '@angular/core';

import {ModalInputData} from './modal-input.model';

@Component({
    selector: 'iw-modal-input', templateUrl: './modal-input.component.html'
})
export class ModalInputComponent {

    public data: ModalInputData = {
        value: '', title: '0', inputName: '0'
    };

    // eslint-disable-next-line
    public __ref?: ComponentRef<ModalInputComponent>;

    public onOk() {
        if (this.data.onOk) {
            this.data?.onOk(this.data.value);
        }
        this.destroy();
    }

    public onCancel() {
        if (this.data.onCancel) {
            this.data.onCancel();
        }
        this.destroy();
    }

    private destroy() {
        if (this.__ref) {
            this.__ref.destroy();
        }
    }
}
