import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Dialog} from 'primeng/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {DomEventService} from '../../core/dom';
import {DialogStateService} from '../../core/services/dialog-state.service';
import {UuidService} from '../../core/uuid/uuid.service';
import {getCssMeasure} from '../../helpers/css';

@Component({
    selector: 'iw-dialog', templateUrl: './iw-dialog.component.html'
})
export class IwDialogComponent implements OnInit, OnDestroy {
    // ########## PROPERTIES ##############
    @Input() /** Padding to body content */ public bodyPadding?: string;
    @Input() /** Set true to show close button */ public closable = false;
    @Input() /** Set true to close on escape */ public closeOnEscape = false;
    @Input() /** Aditional class to add to this element */ public cls: string | string[] = '';
    @Input() /** Set the display state */ public displayed = true;
    @Input() /** Allow user to resize window */ public resizable = false;
    @Input() /** Dialog height */ public height?: string | number;
    @Input() /** Path to image to use as icon */ public icon?: string;
    @Input() /** Set to true to allow the user to maximize the dialog. */ public maximizable?: boolean;
    @Input() /** Set to true to maximize the dialog */ public maximized?: boolean;
    @Input() /** True to make this Component modal. */ public modal = false;
    @Input() /** Title for the dialog header */ public title?: string;
    @Input() /** Width of component */ public width?: number | string;

    @Input() /** Set if dialog is visible */ public visible = true;

    @Input() /** Set to false to hide header */ public showHeader = true;
    @Input() /** Set to false if not draggable */ public draggable = true;
    // ########## PROPERTIES ##############

    @Output() /** Invoke when form has finish render */ public ready = new EventEmitter<Dialog>();

    @Output() /** Invoke when dialog is focus */ public dialogFocused = new EventEmitter<void>();

    @Output() /** Invoke when dialog closes */ public beforeHide = new EventEmitter<void>();

    public dialogStyle?: { width: string; height: string };
    private readonly _dialogId: string;
    private _diag?: Dialog;
    private _subs = new Subject();

    constructor(uuidService: UuidService, private readonly _stateService: DialogStateService, private readonly _dom: DomEventService) {
        this._dialogId = uuidService.generateString();
        this._stateService.focusChange
            .pipe(takeUntil(this._subs))
            .subscribe((e: string) => this.stateFocusChange(e));
    }

    /** [ReadOnly] dialog unique id */
    public get dialogId() {
        return this._dialogId;
    }

    public get componentWidth() {
        return getCssMeasure(this.width);
    }

    public get componentHeight() {
        return getCssMeasure(this.height);
    }

    public get className() {
        return this.getClassName();
    }

    private _hasFocus = false;

    public get hasFocus() {
        return this._hasFocus;
    }

    public ngOnInit() {
        this.setDialogDimensions();
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public onDialogReady(dialog: Dialog) {
        this._diag = dialog;
        this.updateListeners(dialog);
        this.ready.emit(dialog);
        this.onFocus();
    }

    public onHide() {
        this.beforeHide.emit();
    }

    public onFocus() {
        this._stateService.setFocusId(this._dialogId);
        this.dialogFocused.emit();
    }

    public destroy() {
        if (this._diag) {
            this.visible = false;
        }
    }

    public stateFocusChange(id?: string) {
        setTimeout(() => {
            const hasFocus = id === this._dialogId;
            if (hasFocus !== this._hasFocus) {
                this._hasFocus = hasFocus;
            }
        }, 0);
    }

    protected getClassName() {
        const baseClass = this._hasFocus ? 'focus ' : '';
        const cls = typeof this.cls === 'string' ? this.cls : this.cls.join(' ');
        return baseClass + ' ' + cls;
    }

    private setDialogDimensions() {
        this.dialogStyle = {
            width: getCssMeasure(this.width), height: getCssMeasure(this.height)
        };
    }

    private updateListeners(diag: Dialog) {
        const TARGET = '.p-dialog';
        this._dom.elemRef.enableFocus(diag.el, TARGET);

        const el: HTMLElement = <any>this._dom.elemRef.querySelector(diag.el, TARGET);
        el.addEventListener('focus', () => this.onFocus());

        const closeIcon: HTMLElement = <any>this._dom.elemRef.querySelector(diag.el, '.p-dialog-titlebar-close');
        if (closeIcon) {
            closeIcon.addEventListener('click', () => this.onHide());
        }
    }
}
