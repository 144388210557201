import {Injectable, Injector, Type} from '@angular/core';

import {loadInjector, registerInjector} from '../local-injector';

@Injectable({providedIn: 'root'})
export class LocalInjectorService {

    public constructor(private readonly _injector: Injector) {
        registerInjector(_injector);
    }

    /**
     * Load instance of LocalInjectorService
     *
     * @static
     * @returns
     * @memberof LocalInjectorService
     */
    public static getInstance() {
        return loadInjector(LocalInjectorService);
    }

    /**
     * Load instance of T
     *
     * @static
     * @template T
     * @param type
     * @returns
     * @memberof LocalInjectorService
     */
    public static load<T>(type: Type<T>) {
        return loadInjector(LocalInjectorService)
            .loadService(type);
    }

    public loadService<T>(type: Type<T>) {
        return this._injector.get(type, undefined);
    }

}
