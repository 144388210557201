import {Component, EventEmitter} from '@angular/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalComponent} from '@app/sam-base/models';
import {Ppqua} from '@app/sam-base/models/placement';
import {ListItem} from '@shared/widgets/form-components/list-box/list-item.model';

@Component({
    selector: 'iw-ppquams', templateUrl: './ppquams.component.html'
})
export class PpquamsComponent implements ModalComponent<string, string> {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();

    public selectionListIds: string[] = [];

    public input: ListItem<string>[] = [];

    public output: ListItem<string>[] = [];

    private _ok?: (result: string) => void;
    private _cancel?: (error?: any) => void;

    constructor(private rest: RestApiService) {
    }

    public async setData(data: string): Promise<void> {
        this.selectionListIds = data ? data.split(';') : [''];
        this.updateLists();
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onPressOk(event: ListItem<string>[]) {
        const seletions = event.map(e => e.value)
            .join(';');
        if (this._ok) {
            this._ok(seletions);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    private updateLists() {
        const input: ListItem<string>[] = [];
        const output: ListItem<string>[] = [];

        this.rest.entityClient.getService(Ppqua)
            .getRefData()
            .subscribe((res) => {
                // eslint-disable-next-line complexity
                res.forEach((e: Ppqua) => {
                    if (e.active) {
                        if (e.quaId && e.qualif && !this.selectionListIds.includes(e.quaId)) {
                            input.push({label: e.quaId + ' | ' + e.qualif, value: e.quaId});
                        }
                        if (e.quaId && e.qualif && this.selectionListIds.includes(e.quaId)) {
                            output.push({label: e.quaId + ' | ' + e.qualif, value: e.quaId});
                        }
                    }
                });

                this.output = output;
                this.input = input;
            });
    }

}
