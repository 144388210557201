import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
    ContactNcsComboBoxComponent
} from '@shared/widgets/form-components/contact-ncs-combo-box/contact-ncs-combo-box.component';
import {
    DocumentManagementComponent
} from '@shared/widgets/form-components/document-management/document-management.component';
import {
    IwTextfieldGeCliAttComponent
} from '@shared/widgets/form-components/input-select/iw-textfield-ge-cli-att.component';
import {IwTextfieldFofouComponent} from '@shared/widgets/form-components/input-select/iw-textfiled-fofou.component';
import {IwTextfieldFofoudpComponent} from '@shared/widgets/form-components/input-select/iw-textfiled-fofoudp.component';
import {
    IwTextfieldProfessionComponent
} from '@shared/widgets/form-components/input-select/iw-textfiled-professions.component';
import {
    IwLookupsDropdownComponent
} from '@shared/widgets/form-components/iw-lookups-dropdown/iw-lookups-dropdown.component';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {EditorModule} from 'primeng/editor';
import {FileUploadModule} from 'primeng/fileupload';

import {DocTypesComboBoxComponent} from '../iw-components/dropdown/doc-types-combo-box.component';
import {IwComponentsModule} from '../iw-components/iw-components.module';
import {CalcModeComboBoxComponent} from './calc-mode-combo-box/calc-mode-combo-box.component';
import {ContactClientComboboxComponent} from './contact-client-combo-box/contact-client-combo-box.component';
import {EmployeeComboBoxComponent} from './employee-combo-box/employee-combo-box.component';
import {FormHeaderComponent} from './form-header/form-header.component';
import {FormStatusComponent} from './form-status/form-status.component';
import {FrxmisComboBoxComponent} from './frxmis-combo-box/frxmis-combo-box.component';
import {GrhComboBoxComponent} from './grh-combo-box/grh-combo-box.component';
import {IwTextfieldActionComponent} from './input-select/iw-textfield-action.component';
import {IwTextfieldChantiersComponent} from './input-select/iw-textfield-chantiers.component';
import {IwTextfieldCliAttComponent} from './input-select/iw-textfield-cliatt.component';
import {IwTextfieldCompteComponent} from './input-select/iw-textfield-compte.component';
import {IwTextfieldContactComponent} from './input-select/iw-textfield-contact.component';
import {IwTextfieldGenretravailComponent} from './input-select/iw-textfield-genretravail.component';
import {IwTextfieldListComponent} from './input-select/iw-textfield-list.component';
import {IwTextfieldLookupsComponent} from './input-select/iw-textfield-lookups.component';
import {IwCctClientDropdownComponent} from './iw-cct-client-dropdown/iw-cct-client-dropdown.component';
import {IwTableEditComponent} from './iw-table-edit/iw-table-edit.component';
import {ListBoxComponent} from './list-box/list-box.component';
import {
    IwMultiautocompleteChantiersComponent
} from './multiautocomplete-select/iw-multiautocomplete-chantiers.component';
import {IwMultiautocompleteEmployeComponent} from './multiautocomplete-select/iw-multiautocomplete-employe.component';
import {PrintFormatComboBoxComponent} from './print-format-combo-box/print-format-combo-box.component';
import {SectorComboBoxComponent} from './sector-combo-box/sector-combo-box.component';
import {SelectionListsComponent} from './selection-lists/selection-lists.component';
import {ShcalcmodeComboBoxComponent} from './shcalmode-combo-box/shcalcmode-combo-box.component';
import {SideMenuComponent} from './side-menu/side-menu.component';

const COMPONENTS: any[] = [
    DocumentManagementComponent,
    FormStatusComponent,
    SideMenuComponent,
    ListBoxComponent,
    SelectionListsComponent,
    FormHeaderComponent,
    IwTableEditComponent,
    IwTextfieldListComponent,
    IwTextfieldLookupsComponent,
    IwTextfieldActionComponent,
    IwTextfieldCompteComponent,
    IwLookupsDropdownComponent,
    IwTextfieldProfessionComponent,
    IwTextfieldFofouComponent,
    IwTextfieldFofoudpComponent,
    IwTextfieldCliAttComponent,
    IwTextfieldGeCliAttComponent,
    ContactNcsComboBoxComponent,
    SectorComboBoxComponent,
    FrxmisComboBoxComponent,
    EmployeeComboBoxComponent,
    ContactClientComboboxComponent,
    IwTextfieldContactComponent,
    IwTextfieldChantiersComponent,
    IwTextfieldGenretravailComponent,
    DocTypesComboBoxComponent,
    ShcalcmodeComboBoxComponent,
    GrhComboBoxComponent,
    CalcModeComboBoxComponent,
    PrintFormatComboBoxComponent,
    IwCctClientDropdownComponent,
    IwMultiautocompleteChantiersComponent,
    IwMultiautocompleteEmployeComponent];

@NgModule({
    imports: [
        CommonModule,
        AutoCompleteModule,
        TranslateModule,
        FileUploadModule,
        FormsModule,
        IwComponentsModule,
        EditorModule,
        NgxMaskDirective,
        NgxMaskPipe],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    providers: [provideNgxMask()]
})
export class FormComponentsModule {
    constructor() {

    }
}
