import { HttpClient } from '@angular/common/http';

import {joinPath} from '../../../helpers/path';
import {getEnvironment} from '../../environment/environment';
import {RestParams} from '../models';

export abstract class RestClient<T> {
    protected get _baseUrl() {
        return getEnvironment().backendURL;
    }

    constructor(protected _http: HttpClient) {
    }

    //#region Base request method
    protected GET<TResult>(params?: RestParams, ...path: string[]) {
        return this._http.get<TResult>(this.getRequestUrl(...path), {params});
    }

    protected POST<TResult>(body: T, params?: RestParams, ...path: string[]) {
        return this._http.post<TResult>(this.getRequestUrl(...path), body, {params});
    }

    protected PUT<TResult>(body: T, params?: RestParams, ...path: string[]) {
        return this._http.put<TResult>(this.getRequestUrl(...path), body, {params});
    }

    protected PATCH<TResult>(body: T, params?: RestParams, ...path: string[]) {
        return this._http.patch<TResult>(this.getRequestUrl(...path), body, {params});
    }

    protected DELETE<TResult>(params?: RestParams, ...path: string[]) {
        return this._http.delete<TResult>(this.getRequestUrl(...path), {params});
    }

    //#endregion

    protected getRequestUrl(...parts: string[]) {
        return joinPath(this._baseUrl, ...parts);
    }
}
