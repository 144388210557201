import {IwColumn} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {IwContextMenuClass} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {MenuItem} from '@app/sam-base/models/components/menu-item.model';

import {SearchEntityDateOperator} from '../../core/rest-api/models/search-entity-operator.model';
import {PlacementEvent} from './events.models';

const entityContextMenu: MenuItem<Pprapview>[] = [
    {
        label: 'pprap_contextmenu_related_inv',
        event: PlacementEvent.INVOICE,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'pprap_contextmenu_related_sal',
        event: PlacementEvent.SALARY,
        contextMenuVisibleMode: 'single',
    },
    {
        label: 'pprap_contextmenu_mission',
        event: PlacementEvent.MISSION,
        contextMenuVisibleMode: 'single',
    },
    {
        label: 'pprap_contextmenu_emp',
        event: PlacementEvent.EMPLOYE,
        contextMenuVisibleMode: 'single',
    },
    {
        label: 'ppra_contextmenu_client',
        event: PlacementEvent.CLIENT,
        contextMenuVisibleMode: 'single',
    },
    {
        label: 'contextmenu_accomptes',
        event: PlacementEvent.ACOMPTES_RAPID,
        contextMenuVisibleMode: 'single',
    },
    {
        separator: true
    }];

@IwContextMenuClass(...entityContextMenu) @IwRestEntity('pprapview')
export class Pprapview {
    @IwColumn({
        index: 0,
        name: 'mis_id'
    })
    public misId?: string;
    @IwColumn({
        index: 2,
        name: 'rapId'
    }) @IwRestEntityId() public rapId?: string;
    @IwColumn({
        index: 3,
        name: 'semaine',
        type: 'number'
    })
    public semaine?: number;
    @IwColumn({
        index: 4,
        name: 'datelundi',
        type: 'date',
        filterQuery: IwDateHelper.dateIsoString(new Date().setFullYear(new Date().getFullYear() - 2)),
        filterOperator: SearchEntityDateOperator.After
    })
    public datelundi?: string;
    @IwColumn({
        index: 5,
        name: 'nomemploye'
    })
    public empNom?: string;
    @IwColumn({
        index: 5,
        name: 'prenom'
    })
    public empPrenom?: string;
    @IwColumn({
        index: 6,
        name: 'nomclient'
    })
    public nomclient?: string;
    @IwColumn({
        index: 7,
        name: 'nbheutot',
        type: 'mnt',
        align: 'right',
        indexed: false,
        decimals: 2
    })
    public nbheutot?: number;
    @IwColumn({
        index: 8,
        name: 'mntindemp',
        type: 'mnt',
        align: 'right',
        indexed: false,
        decimals: 2
    })
    public mntindemp?: number;
    @IwColumn({
        index: 1,
        type: 'number',
        name: 'rapNo'
    })
    public rapNo?: string;
    @IwColumn({
        index: 9,
        name: 'mntindcli',
        hidden: true,
        type: 'mnt',
        align: 'right',
        indexed: false
    })
    public mntindcli?: number;
    @IwColumn({
        index: 10,
        type: 'mnt',
        align: 'right',
        name: 'chantier'
    })
    public chantier?: string;
    @IwColumn({
        index: 11,
        name: 'mntheuemp',
        hidden: true,
        type: 'mnt',
        align: 'right',
        indexed: false
    })
    public mntheuemp?: number;
    @IwColumn({
        index: 12,
        name: 'mntheucli',
        hidden: true,
        type: 'mnt',
        align: 'right',
        indexed: false
    })
    public mntheucli?: number;
    @IwColumn({
        index: 15,
        name: 'salId',
        type: 'activePer'
    })
    public salId?: string;
    public empId?: string;
    public cliId?: string;
    @IwColumn({
        index: 14,
        type: 'boolean',
        align: 'center',
        name: 'sent2Salaux'
    })
    public sent2sal?: boolean;
    @IwColumn({
        index: 13,
        type: 'boolean',
        align: 'center',
        name: 'sent2Facaux'
    })
    public sent2fac?: boolean;
    public dateDelet?: string;
    public invoiceProcessStatus?: string; // TODO remove because always null
    public salaryProcessStatus?: string; // TODO remove because always null
}
