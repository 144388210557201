import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppempview')
export class Ppempview {
    @IwRestEntityId() public empId?: string;
    public datenaiss?: string;
    public firstMis?: string;
    public lastMis?: string;
    public datedossie?: string;
    public permDtde?: string;
    public permDta?: string;
    public conjNaiss?: string;
    public nom?: string;
    public nomjf?: string;
    public prenom?: string;
    public email?: string;
    public adresse1?: string;
    public adresse2?: string;
    public npa?: string;
    public lieu?: string;
    public pays?: string;
    public tel1?: string;
    public tel2?: string;
    public conId?: string;
    public nomconseiller?: string;
    public ageId?: string;
    public permis?: string;
    public permisno?: string;
    public natel?: string;
    public noavs13?: string;
    public nation?: string;
    public profession?: string;
    public profOther?: string;
    public lastjob?: string;
    public parcours?: string;
    public apprent?: string;
    public certificat?: string;
    public note?: string;
    public apprec?: string;
    public usertag?: string;
    public langue0?: string;
    public langue00?: string;
    public langue1?: string;
    public langue2?: string;
    public langue3?: string;
    public langue4?: string;
    public postdesire?: string;
    public saldesire?: string;
    public typehortxt?: string;
    public preavis?: string;
    public regions?: string;
    public iban?: string;
    public keywords?: string;
    public empData?: string;
    public modData?: string;
    public vehpernote?: string;
    public vehNote?: string;
    public dateTrack?: string;
    public dateModif?: string;
    public dateCreat?: string;
    public dateDelet?: string;
    public cempstatus?: string;
    public cempstatustext?: string;
    public allowsms?: boolean;
    public listenoir?: boolean;
    public nomemploye?: boolean;
}
