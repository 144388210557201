<div [ngClass]="className" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition"
     class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" class="iw-form-group-label" for="item" title="{{label | translate}}">{{ label | translate }}
    </div>
    <div class="input-container">
        <div [attr.data-suffix]="suffix" class="p-inputgroup iw-input">
            <input [(ngModel)]="value" [disabled]="isDisabled" [mode]="'time'" iwDurationInput>
        </div>
        <div #TxtwarningMessage *ngIf="isInvalid && warningMessage">
            <small class="iw-input col-xs-12 padding-left-0"
                   style="color: #ff0000; font-size:10px"> {{ warningMessage | translate }} </small>
        </div>
    </div>
</div>
