<div (keydown.enter)="onApplyFiltersDelay()"
     class="iw-grid-column-config padding-15">
    <div class="group-by margin-bottom-15">
        <iw-button (press)="onGroupBy()"
                   *ngIf="getTypeColumn() !== 'boolean' &&
      getTypeColumn() !== 'simpleFilter' &&
      showGrouping"
                   [text]="groupByText | translate"
                   class="btn-block"></iw-button>
    </div>
    <div class="filters">
        <iw-enum-dropdown
                *ngIf="getTypeColumn() !== 'status' && getTypeColumn() !== 'enum' && getTypeColumn() !== 'activePer' && getTypeColumn() !== 'misStatusRap' && getTypeColumn() !== 'cdeType'  && getTypeColumn() !== 'boolean' && getTypeColumn() !== 'simpleFilter'"
                [(value)]="op"
                [inputEnum]="operators"
                [labelAlign]="'top'"
                [label]="'filter_' | translate"></iw-enum-dropdown>
        <iw-textbox
                *ngIf="getTypeColumn() !== 'date' && getTypeColumn() !== 'dateTime' && getTypeColumn() !== 'status' && getTypeColumn() !== 'enum' && getTypeColumn() !== 'activePer' && getTypeColumn() !== 'misStatusRap' && getTypeColumn() !== 'cdeType' && getTypeColumn() !== 'boolean'"
                [(value)]="q"
                [focusOnLoad]="true"
                class="iw-input col-xs-3"></iw-textbox>

        <ng-container *ngIf="getTypeColumn() !== 'simpleFilter'">
            <iw-date-picker
                    *ngIf="getTypeColumn() === 'date' || getTypeColumn() === 'dateTime' && getTypeColumn() !== 'misStatusRap'"
                    [(value)]="q"
                    class="iw-input col-xs-3"></iw-date-picker>
            <iw-date-picker
                    *ngIf="getTypeColumn() === 'dateTime' && (op === 'd_bet' || op === 'd_not_bet') && getTypeColumn() !== 'misStatusRap'"
                    [(value)]="q2"
                    [label]="'combo_to' | translate"
                    class="iw-input col-xs-3"></iw-date-picker>

            <ng-container *ngIf="getTypeColumn() === 'number'">
                <iw-textbox
                        *ngIf="(op === 'd_bet' || op === 'd_not_bet')"
                        [(value)]="q2"
                        [label]="'period_to' | translate"
                        class="iw-input col-xs-3"></iw-textbox>
            </ng-container>


            <iw-periode-dropdown (selectedChanged)="onPeriodSelect($event)"
                                 *ngIf="getTypeColumn() === 'activePer' && getTypeColumn() !== 'misStatusRap'"
                                 [(value)]="q"
                                 [labelAlign]="'top'"
                                 [label]="'Filter'"></iw-periode-dropdown>
            <div *ngIf="getTypeColumn() === 'status' || getTypeColumn() === 'misStatusRap' || getTypeColumn() === 'enum'"
                 class="check-box-group">
                <iw-checkbox *ngFor="let s of columnStatus; let i = index"
                             [(value)]="statusQuery[i]"
                             [label]="((column?.valueFormat || '') + s) | translate"
                             class="iw-input"></iw-checkbox>
            </div>

            <div *ngIf="getTypeColumn() === 'cdeType' || getTypeColumn() === 'boolean'"
                 class="radio-button-group">
                <iw-radio-list #radioOptions
                               [(value)]="q"
                               [radioOptions]="getRadioOptions(getTypeColumn())"
                               [type]="getTypeColumn() === 'boolean' ? 'boolean' : undefined"></iw-radio-list>
            </div>

        </ng-container>


        <div class="margin-top-5">
            <iw-button (press)="onApplyFilters()"
                       [text]="'apply_filters' | translate"
                       class="btn-block"></iw-button>
        </div>
        <div *ngIf="getTypeColumn() !== 'cdeType'  && getTypeColumn() !== 'boolean'"
             class="margin-top-5">
            <iw-button (press)="onClearFilters()"
                       [text]="'clear_filters' | translate"
                       class="btn-block"></iw-button>
        </div>
    </div>
</div>
