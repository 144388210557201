import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppempcct')
export class Ppempcct {
    @IwRestEntityId() public cctId?: string;
    public empId?: string;
    public noempcct?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
