import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ijtemprapportview')
export class Ijtemprapportview {
    @IwRestEntityId() @IwColumn({name: 'id'}) public ijRapId?: string;
    @IwColumn({name: 'datelundi'}) public dateLundi?: string;
    @IwColumn({name: 'mis_id'}) public misId?: string;
    @IwColumn({name: 'rapNo'}) public rapNo?: string;
    public clichaId?: string;
    public chantier?: string;
    public comment?: string;
    @IwColumn({name: 'client'}) public client?: string;
    @IwColumn({name: 'employee'}) public employee?: string;
    public finmission?: string;
    public finmotif?: string;
    public result?: string;
    @IwColumn({name: 'conseiller'}) public conseiller?: string;
    public conId?: string;
}
