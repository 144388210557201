import {Component, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';
import {BaseFormControl} from '@sam-base/base';
import {buildCustomValueProvider} from '@sam-base/base/build-value-provider';
import {PpconService} from '@sam-base/core';
import {ComboboxItem} from '@sam-base/models';
import {Ppcon} from '@sam-base/models/placement';

@Component({
    selector: 'iw-conseiller-dropdown',
    templateUrl: './iw-conseiller-dropdown.component.html',
    providers: [buildCustomValueProvider(IwConseillerDropdownComponent)]
})
export class IwConseillerDropdownComponent extends BaseFormControl<Ppcon> implements OnInit {

    public options: ComboboxItem<string>[] = [];


    @Input() public warningMessage?: string;
    @Input() public width?: string | number;
    @Input() public labelWidth?: string;

    public selectedValue = undefined;

    constructor(private elRef: ElementRef, private readonly ppconservice: PpconService) {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }
    
    ngOnInit(): void {
        this.ppconservice.getLoggedUserConseillers().then((conseillers => {
            conseillers?.forEach((conseiller) => {
                this.options.push({
                    name: this.buildLabel(conseiller),
                    value: conseiller.conId!
                });
            })
        }));

    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    /**
     * Function called when a option is selected
     *
     * @param event event caused by option selection
     */
    public change(event: EventEmitter<Ppcon | number>) {
    }

    protected buildLabel(e: Ppcon) {
        return `${e.nom} ${e.prenom}`;
    }

}
