import {EventEmitter, Injectable} from '@angular/core';
import {mapError} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {Ppemp} from '@app/sam-base/models/placement';

/** TODO: Refactor this, iw-planing should open using formHandler */
@Injectable({providedIn: 'root'})
export class PlanningComponentService {

    public displayChange = new EventEmitter<[boolean, Ppemp | undefined]>();

    public constructor(private readonly _restApi: RestApiService) {
    }

    public show(empId?: string) {
        if (!empId) {
            return;
        }
        this._restApi.getEntityClient(Ppemp)
            .getById(empId)
            .pipe(mapError('Cant find emp id'))
            .subscribe(e => {
                this.displayChange.emit([!!e, e]);
            });
    }

    public hide() {
        this.displayChange.emit([false, undefined]);
    }
}
