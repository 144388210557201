// eslint-disable-next-line complexity
export function getMask(value?: string): string | undefined {
    const phone = value ?? '';
    let mask;
    switch (true) {
        case /^(\+351)/.test(phone): // Portuguese
            mask = '+000 000 000 000';
            break;
        case /^\+41/.test(phone): // Swiss
            mask = '+0000 000 00 00';
            break;
        case /^\+33/.test(phone): // French
            mask = '+000 00 00 00 00';
            break;
        case /^\+49/.test(phone): // German
            mask = '+00 00 000000';
            break;
        case /^\+39/.test(phone): // Italian
            mask = '+00 000 000 0000';
            break;
        default:
            mask = undefined;
    }
    return mask;
}
