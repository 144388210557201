import {AppState} from '../../store';

let _state: AppState | undefined;
export const EVENT_HANDLERS: EventHandlers = {};
export const EVENT_HANDLER_METADATA = '__#EVENT_HANDLER#__';

export function registerCurrentState(appState: AppState) {
    _state = appState;
}

export function IwEventHandler(...args: string[]): MethodDecorator {
    return (target: any, key: string | symbol) => {
        const constructor = target[key];
        const meta: string[] = (<any>constructor).hasOwnProperty(EVENT_HANDLER_METADATA) ? (constructor as any)[EVENT_HANDLER_METADATA] : Object.defineProperty(constructor, EVENT_HANDLER_METADATA, {value: []})[EVENT_HANDLER_METADATA];
        Array.prototype.push.apply(meta, args);
    };
}

export function registerEventHandler(key: string, handler: (state?: any, payload?: any) => void) {
    EVENT_HANDLERS[key] = [handler];
}

export function runEventHandlers(key: string, payload?: any) {
    const groupHandler = EVENT_HANDLERS[key] || [];
    for (const fn of groupHandler) {
        fn(_state, payload);
    }
}

export interface EventHandlers {
    [key: string]: ((state?: any, payload?: any) => void)[];
}
