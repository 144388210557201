import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Saems} from '@app/sam-base/models/salary/saems';
import {environment} from '@root/environments/environment';
import {Observable, Subject} from 'rxjs';
import {RestClient} from '../rest-api/core';

export interface ElSalSubjectOpts {
    uuid: string;
    saved: boolean;
}

@Injectable()
export class SaemsService extends RestClient<any> {
    public elSalSubject: Subject<ElSalSubjectOpts> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getSaemsByEmpIdAndSalId(empId?: string, salId?: string): Observable<Saems> {
        return this._http.get(this._baseUrl + `saems/empsal/${empId}/${salId}`);
    }

    public getSaemsByEmpIdAndSalIdStartingWith(empId: string, salId: string): Observable<Saems[]> {
        return this._http.get<Saems[]>(this._baseUrl + `saems/starts/${empId}/${salId.substring(0, 4)}`);
    }

    public getSaemsByEmpIdAndYear(empId: string, annee: string): Observable<Saems[]> {
        return this._http.get <Saems[]>(this._baseUrl + `saems/${empId}/${annee}`);
    }

    public getSaempcByEmpId(empId: string): Observable<Saempc> {
        return this._http.get<Saempc>(this._baseUrl + `saempc/${empId}`);
    }

    public saveblockStatus(id: string, saems: Saems): Observable<Saems> {
        return this._http.put(`${this._baseUrl}saems/toggleblock/${id}`, saems);
    }

    public getJoursIS(empId: string, annee: string): Observable<string> {
        return this._http.get(this._baseUrl + `saems/joursis/${empId}/${annee}`, {
            responseType: 'text'
        });
    }

    public getSaemsData(empId: string, salId: string): Observable<Saems> {
        return this._http.get(this._baseUrl + `saems/${empId}_${salId}`);
    }

    public getAnalytique(empId: string, salId: string): Observable<String> {
        return this._http.get(this._baseUrl + `saems/analytique/${empId}/${salId}`, {
            responseType: 'text'
        });
    }

    public getPreviousRepartition(empId: string, salId: string): Observable<String> {
        return this._http.get(this._baseUrl + `saems/copyprevana/${empId}/${salId}`, {
            responseType: 'text'
        });
    }

    public saveSaemsCalculated(empId?: string, salId?: string, isCalculated?: boolean): Observable<Saems> {
        return this._http.put<Saems>(this._baseUrl + `saems/calculated/${empId}/${salId}/${isCalculated}`, undefined);
    }

    public deleteSaems(empId?: string, salId?: string): Observable<void> {
        return this._http.post<void>(this._baseUrl + `salary/delete/${empId}/${salId}`, undefined);
    }

    public deleteSaemsBulk(ids: string[]): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `salary/delete/bulk`, {
            body: ids
        });
    }

    public getSalIdsByEmpId(empId: string): Observable<string[]> {
        return this._http.get<string[]>(environment.backendURL + `saems/${empId}/salIds`);
    }


}
