import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SagenService} from '@app/sam-base/core/services/sagen.service';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, HtmlElementStatus, ModalComponent} from '@app/sam-base/models';
import {Saempview} from '@app/sam-base/models/salary';
import {Sagen} from '@app/sam-base/models/salary/sagen';
import {IsSalaryCalculableResp} from '@modules/sam-main/salary/components/fiche-salaire/SalAllowResponce';
import {SalaryService} from '@modules/sam-main/salary/services/salary-service';
import {TranslateService} from '@ngx-translate/core';
import {
    Choicemodal,
    ModalSalaryChoiceOptions
} from '@shared/widgets/modal-components/modal-salarychoice/modal-salarychoice.options';


function strToCbp(lst: string[]): ComboboxItem<string>[] {
    return lst.map(e => (<ComboboxItem<string>>{
        value: e,
        name: e
    }));
}

@Component({
    selector: 'iw-modal-textarea',
    templateUrl: './modal-salarychoice.component.html'
})
export class ModalSalaryChoiceComponent implements ModalComponent<Choicemodal | undefined, ModalSalaryChoiceOptions>, OnInit {
    @Output() public pressOk = new EventEmitter<Choicemodal>();
    @Output() public pressCancel = new EventEmitter<void>();

    public choicemodal: Choicemodal = {};
    public data!: ModalSalaryChoiceOptions;
    public entityEmploye = Saempview;
    public optionPeriode: ComboboxItem<string>[] = [];
    public btnOk: HtmlElementStatus = {
        enabled: false,
        value: 'Ok',
        visible: true
    };
    public loading = false;
    private _options!: ModalSalaryChoiceOptions;
    private _ok?: (result: Choicemodal | undefined) => void;
    private _cancel?: (error?: any) => void;

    constructor(private readonly _sagenService: SagenService,
                private readonly _salaryService: SalaryService,
                private readonly _translate: TranslateService,
                private readonly _toastService: ToastService) {
    }


    public setData(data: any): Promise<void> {
        this._options = data;
        this.choicemodal = data;
        return data;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok(this.choicemodal);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public registerOk(action: (result?: Choicemodal) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public ngOnInit() {
        this.loadPeriodes();
    }

    public onEmpChange(empId: string) {
        this.choicemodal.empId = empId;
        this.checkCreateFicheSalaryPossible();
    }

    public onPeriodChange(salId: string) {
        this.choicemodal.salId = salId;
        this.checkCreateFicheSalaryPossible();
    }

    private loadPeriodes() {
        this._sagenService.getOpenSalId()
            .subscribe((sagenList: Sagen[]) => {
                this.optionPeriode = strToCbp(sagenList.map((sagen: Sagen) => sagen.salId ?? ''));
            });
    }

    private checkCreateFicheSalaryPossible() {
        if (this.choicemodal?.empId && this.choicemodal?.salId) {
            this.loading = true;
            this.btnOk.enabled = false;
            this._salaryService
                .checkCreateFicheSalaryPossible(this.choicemodal?.empId, this.choicemodal?.salId)
                .subscribe((isCalculableResp: IsSalaryCalculableResp) => {
                    this.loading = false;
                    if (!isCalculableResp.allowCreate) {
                        if (isCalculableResp.alreadyCreated) {
                            return this._toastService.warning(this._translate.instant('fiche_sal_emp_already_exist'));
                        }
                        if (!isCalculableResp.empHasworkActivity) {
                            return this._toastService
                                .warning(this._translate.instant('fiche_sal_emp_has_no_work_activity'));
                        }
                        if (!isCalculableResp.empHasworkForced) {
                            return this._toastService
                                .warning(this._translate.instant('fiche_sal_emp_has_no_work_activity'));
                        }
                    } else {
                        this.btnOk.enabled = true;
                    }
                });
        }
    }
}
