import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppclicat')
export class Ppclicat {
    @IwRestEntityId() public clicatId?: string;
    public libelle?: string;
    public libelle1?: string;
    public libelle3?: string;
    public userCreat?: string;
    public libelle2?: string;
    public libelle4?: string;
    public libelle5?: string;
    public userModif?: string;
    public userDelet?: string;
}
