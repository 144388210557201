import {createSelector, select} from '@ngrx/store';
import {Observable} from 'rxjs';

import {GridProfilesState} from '../';
import {GridProfile} from '../../grid-profile/models';
import {AppState} from '../reducers';

export const selectGridProfileState = (state: AppState) => state.gridProfiles;

/** Select profiles for entity */
// eslint-disable-next-line complexity
export function getSelectByEntity<T>(pState: GridProfilesState, entity: string, advSearch = false): GridProfile<T>[] {
    // eslint-disable-next-line complexity
    const list: GridProfile<T>[] = [];

    if (pState && advSearch) {
        const advSearchLocalProfiles = pState.$local[entity]?.filter(p => p.advsearch) || [];
        const advSearchDynamicProfiles = pState.$dynamic[entity]?.filter(p => p.advsearch) || [];

        list.push(...advSearchLocalProfiles, ...advSearchDynamicProfiles);

        return list;
    }

    if (pState) {

        list.push(...(pState.$local[entity] || []));
        list.push(...(pState.$dynamic[entity] || []));

        return list;
    }

    return [];
}

export function selectGridProfiles<T>(entity: string, advSearchMode = false): (source$: Observable<AppState>) => Observable<GridProfile<T>[]> {
    const selector = (profileEntity: string, profileAdvSearchMode: boolean) => createSelector(selectGridProfileState, (profiles) => getSelectByEntity(profiles, profileEntity, profileAdvSearchMode));
    return select(selector(entity, advSearchMode));
}
