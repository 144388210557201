import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Type} from '@angular/core';
import {IwActionService} from '@app/sam-base/core';
import {PpkwService} from '@app/sam-base/core/services/ppkw.service';
import {Ppkw} from '@app/sam-base/models/placement';
import {initEntity} from '@sam-base/core/rest-api/entity/rest-entity-helpers';
import {forkJoin, lastValueFrom, Observable, of, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

type MultiSelectValue = Ppkw | { value: string; type: 'new' };

@Component({
    selector: 'iw-ppkw-matching', templateUrl: './ppkw-matching.component.html'
})
export class PpkwMatchingComponent implements OnInit, OnDestroy {
    public formLoadSub: Subscription = new Subscription();

    @Input()
    public set parentId(v: string) {
        this.setParentId(v);
    }

    public get parentId() {
        return this._parentId;
    }

    @Input()
    public set type(v: 'ppcli' | 'ppemp' | 'ppcde') {
        this.setType(v);
    }

    public get type() {
        return this._type;
    }

    @Input()
    public set formId(v: string) {
        this.setFormId(v);
    }

    public get formId() {
        return this._formId;
    }

    @Input() public isReadonly = true;

    @Input() public label = '';

    @Input() public allowNewEntries = true;

    @Output() public valueChange = new EventEmitter();

    public matchSource: Type<any> = Ppkw;
    public matchTarget: Type<any> = Ppkw;

    private _parentId = '';
    private _type: 'ppcli' | 'ppemp' | 'ppcde' = 'ppcli';
    public value: Ppkw[] = []; // Values shown
    private _memory: Ppkw[] = []; // Values in memory

    private _hasInit = false;
    private _formId = '';

    private _subSetReadMode?: Subscription;

    constructor(private readonly _ppkwService: PpkwService, private readonly _iwActionService: IwActionService<any>) {
    }

    public ngOnInit() {
        this._hasInit = true;
        this.refreshData();
    }

    public ngOnDestroy() {
        this.saveToStore();
        this.formLoadSub.unsubscribe();
    }

    public matchSourceName = (e: Ppkw) => `${e.kw}`;
    public matchTargetName = (e: Ppkw) => `${e.kw}`;
    public matchValues = (l: Ppkw[], t: Ppkw) => !!l.find(e => e.kw === t.kw);

    public async onValueUpdated(list: (Ppkw | { value: string; type: 'new' })[]) {
        if (this.isReadonly) {
            return;
        }

        if (list.length === 0) {
            this.value = [];
            this.saveToStore();
            this.valueChange.emit();
        }

        // Convert values into PPEKW
        const data = list.map(e => this.getPpkw(e));
        forkJoin(data)
            .subscribe(keywords => {
                // Check if current list has new values
                if (!this.listHasChanges(keywords)) {
                    return;
                }

                keywords = this.removeRepeatedValues(keywords);
                for (const v of this._memory) {
                    if (!keywords.find(e => e.kwId === v.kwId)) {
                        // Remove item from memory
                        this._memory = this._memory.filter(e => e.kwId === v.kwId);
                    }
                }
                keywords = [...new Set(keywords)]; // Remove duplicates
                this.valueChange.emit();
                this.value = keywords;
                this.saveToStore();
            });
    }

    private refreshData() {
        if (!this._parentId || !this._hasInit) {
            return;
        }

        if (!this.isReadonly && this._formId) {
            this.loadFromStore();
        } else {
            this.loadFromService();
        }
    }

    private loadFromStore() {
        const data = this._ppkwService.store[this._formId];

        if (!data) {
            this.loadFromService();
            return;
        }

        this.value = data;

        this._memory = data;
    }

    private async loadFromService() {
        let data = await lastValueFrom(this._ppkwService.getKeywords(this.parentId, this.type));
        data = data.map(element => initEntity(Ppkw, element));
        this.value = data;
        this._memory = [...data];
    }

    private setParentId(v: string) {
        if (this._parentId === v) {
            return;
        }
        this._parentId = v;
        this.refreshData();
    }

    private setType(v: 'ppcli' | 'ppemp' | 'ppcde') {
        if (this._type === v) {
            return;
        }
        this._type = v;
        this.refreshData();
    }

    private setFormId(v: string) {
        if (this._formId === v) {
            return;
        }
        this._formId = v;
        this.refreshBinds();
    }

    private refreshBinds() {
        if (this._subSetReadMode) {
            this._subSetReadMode.unsubscribe();
            this._subSetReadMode = undefined;
        }

        this.formLoadSub = this._iwActionService
            .globalFormSetReadModeByUuid(this.formId)
            .subscribe(() => {
                this._ppkwService.store[this._formId] = undefined;
                this.refreshData();
            });
    }

    private getPpkw(item: MultiSelectValue): Observable<Ppkw> {

        if (item instanceof Ppkw) {
            return of(item);
        }

        if (item.value === undefined) {
            return of(item) as Observable<Ppkw>;
        }

        return this._ppkwService.getKeywordByName(item.value)
            .pipe(catchError(() => of(this.createPpkw(item))), map((keyword: Ppkw) => (initEntity(Ppkw, {
                ...keyword, value: keyword.kw ?? ''
            }))));
    }

    private createPpkw(e: { kw?: string; value?: string }): Ppkw {
        return this._ppkwService
            .create(e.kw || e.value || '');
    }

    private saveToStore() {
        const data = [...this.value];
        this._ppkwService.store[this._formId] = data;
    }

    /**
     * Check if [list] has changes
     *
     * @param list list to compare
     */
    private listHasChanges(list: Ppkw[]) {
        if (list.length !== this.value.length) {
            return true;
        }
        for (const e of list) {
            if (!this.value.find(s => s === e)) {
                return true;
            }
        }
        return false;
    }

    private removeRepeatedValues(list: Ppkw[]): Ppkw[] {
        const newItemsList: Ppkw[] = [];
        list.forEach(element => {
            if (!element.kwId) {
                newItemsList.push(element);
            }
        });
        const val: Ppkw[] = Object.values(list.reduce((acc: any, item: any) => {
            if (item.kwId) {
                if (acc[item.kwId]) {
                    acc[item.kwId].count = acc[item.kwId].count + item.count;
                } else {
                    acc[item.kwId] = item;
                }
            }
            return acc;
        }, {}));
        return [...val, ...newItemsList];
    }
}
