import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {SortDirection} from '@app/sam-base/models';
import {Sags} from '@sam-base/models/salary';

@Component({
    selector: 'iw-sags-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwSagsDropdownComponent)]
})
export class IwSagsDropdownComponent extends BaseEntityDropdown<Sags> {

    @Input() public width?: string | number;
    public selectedValue = undefined;

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
    }

    @Input()
    public set types(v: string[]) {
        if (v.length) {
            this.resultFilters = v ? (e: Sags) => v.includes(e.gsCtype ?? '') : (_: Sags) => true;
        } else {
            this.resultFilters = e => true;
        }
    }

    public getSortProperty(): keyof Sags {
        return 'gsId';
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType() {
        return Sags;
    }

    protected buildLabel(e: Sags): string {
        return e.gsId + ' ' + e.titre || '';
    }
}
