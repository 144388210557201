import {ReportOutputType} from '../models';
import {IwReportOption} from '../models/report-options.model';

export function isArrayBufferResponse<T>({outputType}: IwReportOption<T>): boolean {
    if (outputType === ReportOutputType.Html) {
        return false;
    }

    return true;
}
