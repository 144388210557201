import {Injectable} from '@angular/core';
import {lastValueFrom, Observable, of} from 'rxjs';

import {DynamicPrintService} from './dynamic-print.service';
import {isDynamicPrint, isPrintReportEntity, PrintEntity} from './models/print-entity';
import {PrintTemplate} from './models/print-template.model';
import {ReportPrintService} from './report-print.service';

@Injectable()
export class PrintService {
    constructor(private readonly _dynamicPrint: DynamicPrintService, private readonly _reportPrint: ReportPrintService) {
    }

    /**
     * Load list of avaible templates for an entity
     *
     * @param entity entity to load
     * @param useDynamic if true, load dynamic templates
     */
    public loadTemplates<T>(useDynamic = false): PrintTemplate[] {
        return useDynamic ? [this._dynamicPrint.getDynamicTemplate()] : [];
    }

    public print<T>(c: PrintEntity<T>): Promise<boolean> {
        let obs: Observable<boolean> | undefined;
        if (isDynamicPrint(c)) {
            obs = this._dynamicPrint.print(c);
        } else if (isPrintReportEntity(c)) {
            obs = this._reportPrint.print(c);
        } else {
            obs = this._reportPrint
                .printReport(c.templateName, c.format, c.parameters);
        }

        if (!obs) {
            obs = of(false);
        }

        return lastValueFrom(obs);
    }
}
