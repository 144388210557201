import {Type} from '@angular/core';
import {isConstructor} from '@app/sam-base/helpers';

import {GridProfile} from '../grid-profile/models/grid-profile.model';
import {SearchGridNavigateAction} from '../store/actions/search-grid.actions';

/** Search navigation params */
export interface SearchOptions<T, K extends {}> {
    /** Entity to navigate to */
    entity: Type<T>;
    /** Profile to load */
    profile?: GridProfile<T>;
    /** Form id that triggers the navigation */
    formUUID?: string;
    /** Key-value based filters */
    baseFilters?: { [key in keyof T]: string };
    /** Object to pass to angular navigation extras */
    extras?: K;
}

/**
 * Convert a {SearchOptions<T, K>} to a {SearchGridNavigateAction<T>}
 *
 * @export
 * @template T
 * @param o
 * @returns
 */
export function toSearchGridNavigateAction<T, K extends {}>(o: SearchOptions<T, K>): SearchGridNavigateAction<T> {
    return new SearchGridNavigateAction(o.entity, o.profile, o.formUUID, o.baseFilters, o.extras);
}

export function isSearchOptions<T, K extends {}>(input: unknown): input is SearchOptions<T, K> {
    return typeof input === 'object'
        // eslint-disable-next-line no-null/no-null
        && input !== null && isConstructor((<SearchOptions<T, K>>input).entity);
}
