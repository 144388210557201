import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {IwRestEntity} from '@app/sam-base/core/rest-api';
import {compareDates, parseStringToDate} from '@app/sam-base/helpers';

import {Ppemp} from './ppemp';

@IwRestEntity('ppemp', 'calendar')
export class CalendarEmploye extends Ppemp {
    // Return aniversary date for current year
    public get aniversary() {
        const currentYear = (new Date().getFullYear()).toString();
        return this.getAniversary(currentYear);
    }

    public getAniversary(year: string) {
        if (year?.length !== 4) {
            return this.datenaiss;
        }
        return this.datenaiss?.replace(/\d{4}/g, year);
    }

    public getAniversaryDate(year: string) {
        return parseStringToDate(this.getAniversary(year) || '');
    }

    /** Creates filter for the aniverssary */
    public static createFilterByAniverssary(start?: string, end?: string) {
        const startDate = parseStringToDate(start || '');
        const endDate = parseStringToDate(end || '');

        // If parse failed, return empty results
        if (!startDate || !endDate) {
            return (e: Observable<CalendarEmploye[]>) => e.pipe(map(_ => []));
        }

        const year = startDate.getFullYear()
            .toString();

        const filterAction = (res: CalendarEmploye[]) => res.filter(r => compareDates(r.getAniversary(year), startDate) >= 0 && compareDates(r.getAniversary(year), endDate) <= 0);
        return (e: Observable<CalendarEmploye[]>) => e.pipe(map(filterAction));
    }
}
