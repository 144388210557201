import { HttpClient } from '@angular/common/http';
import {Injectable, Type} from '@angular/core';
import {getEntityMetadata} from '@app/sam-base/core/rest-api';
import {environment} from '@root/environments/environment';
import {of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {SwaggerAPI, SwaggerEndpoint} from './swagger.model';

@Injectable({providedIn: 'root'})
export class SwaggerLoaderService {

    private readonly _url = environment.backendURL + 'swagger.json';
    private _documentation?: SwaggerAPI;

    public constructor(private readonly _http: HttpClient) {
    }

    public loadApi() {
        return !!this._documentation ? of(this._documentation) : this.requestApiDocs();
    }

    public loadApiForEntity<T>(value: string | Type<T>) {
        const name = typeof value === 'string' ? this.buildEntityName(value) : this.getEntityName(value);
        return this.loadApi()
            .pipe(map(e => e.components.schemas[name] as SwaggerEndpoint<T> | undefined));
    }

    private requestApiDocs() {
        return this._http.get<SwaggerAPI>(this._url)
            .pipe(tap(e => this._documentation = e));
    }

    private getEntityName<T>(type: Type<T>) {
        return this.buildEntityName(getEntityMetadata(type).$entity);
    }

    /**
     * Checks the entityModel property por the givan entity
     * for the validations
     *
     * @param name
     * @returns Provperty from swagger with validations
     */
    private buildEntityName(name: string) {
        if (!name) {
            return '';
        }
        return `EntityModel${name[0].toUpperCase()}${(name.substring(1) || '')}`;
    }

}
