/* eslint-disable max-classes-per-file */

import {Type} from '@angular/core';
import {NavigationExtras} from '@app/sam-base/models/nav-extras.model';
import {Action} from '@ngrx/store';

import {Dictionary} from '../../../models';
import {GridProfile} from '../../grid-profile/models';

export const SEARCH_GRID_INIT = '[grid] search grid: initialize grid';
export const SEARCH_GRID_SET_PROFILE = '[grid] search grid: set profile';
export const SEARCH_GRID_NAVIGATE = '[grid] search grid: navigate to new';
export const SEARCH_GRID_SET_NAV_EXTRA = '[grid] search grid: set nav extra';

export class SearchGridInitAction<T> implements Action {
    public readonly type = SEARCH_GRID_INIT;

    constructor(public readonly stateId: string, public readonly entityType: Type<T>, public readonly profile?: GridProfile<T>, public readonly sourceFormUuid?: string, public readonly filter?: Dictionary<string>, public readonly extras?: Dictionary<T>) {
    }
}

/** Change to a new profile */
export class SearchGridSetProfileAction<T> implements Action {
    public readonly type = SEARCH_GRID_SET_PROFILE;

    constructor(public readonly stateId: string, public readonly profile?: GridProfile<T>) {
    }
}

/** Navigate to a specif type */
export class SearchGridNavigateAction<T> implements Action {
    public readonly type = SEARCH_GRID_NAVIGATE;

    constructor(public readonly entityType: Type<T>, public readonly profile?: GridProfile<T>, public readonly sourceFormUuid?: string, public readonly filter?: Dictionary<string>, public readonly extras?: NavigationExtras<T>) {
    }
}

export type SearchGridActions =
    SearchGridInitAction<any>
    | SearchGridSetProfileAction<any>
    | SearchGridNavigateAction<any>;
