import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppemp000')
export class Ppemp000 {
    @IwRestEntityId() public empId?: string;
    public tel2?: string;
    public permDtde?: string;
    public modecreat?: string;
    public syncflag?: string;
    public info4?: string;
    public nextSm?: string;
    public permis?: string;
    public webfrstexp?: string;
    public permDta?: string;
    public apt9?: string;
    public urgence?: string;
    public disponible?: string;
    public datedossie?: string;
    public parcours?: string;
    public lvoiture?: string;
    public lan2Lu?: string;
    public apt3?: string;
    public info3?: string;
    public dateCreat?: string;
    public lan2Ecrit?: string;
    public cmAdr?: string;
    public lastjob?: string;
    public weblastexp?: string;
    public lperchan?: string;
    public langue00?: string;
    public lan1Parle?: string;
    public opMois?: string;
    public permNote?: string;
    public lppforce1?: string;
    public banque?: string;
    public dipl2?: string;
    public preavis?: string;
    public agiFrom?: string;
    public salgenfreq?: string;
    public empNo?: string;
    public adresse1?: string;
    public etatsante?: string;
    public origine?: string;
    public cpolitesse?: string;
    public firstAct?: string;
    public apprent?: string;
    public profession?: string;
    public lppsemcnt1?: string;
    public apt4?: string;
    public info1?: string;
    public adresse2?: string;
    public pregtexte?: string;
    public lperrem?: string;
    public webFin?: string;
    public apt5?: string;
    public lan3Lu?: string;
    public apt2?: string;
    public noavs?: string;
    public conjEmpl?: string;
    public iban?: string;
    public lvelo?: string;
    public imgfname?: string;
    public lconjalloc?: string;
    public lppforce0?: string;
    public tel1?: string;
    public lan2Parle?: string;
    public lan4Parle?: string;
    public webimpnote?: string;
    public langue2?: string;
    public modData?: string;
    public lan4Ecrit?: string;
    public enfnb?: string;
    public keywords?: string;
    public quaId?: string;
    public lppstart0?: string;
    public dateEng?: string;
    public profOther?: string;
    public soumisis?: string;
    public lan1Ecrit?: string;
    public lan3Ecrit?: string;
    public cempstatus?: string;
    public npa?: string;
    public bstoemail?: string;
    public lastMis?: string;
    public lperpl?: string;
    public usertag?: string;
    public forcelpp?: string;
    public dipl1?: string;
    public vehNote?: string;
    public banqueSwi?: string;
    public langue?: string;
    public lmoto?: string;
    public aptbin?: string;
    public email?: string;
    public lnoaco?: string;
    public conjpedta?: string;
    public lieunaiss?: string;
    public cmotifnoir?: string;
    public conjprof?: string;
    public caissemal?: string;
    public nom?: string;
    public sexe?: string;
    public svceMil?: string;
    public empstatus?: string;
    public lastAct?: string;
    public diplbin?: string;
    public souhaits?: string;
    public regions?: string;
    public prenom?: string;
    public congdesire?: string;
    public apt6?: string;
    public empintype?: string;
    public misId?: string;
    public allowsms?: string;
    public lpervoi?: string;
    public conjWkch?: string;
    public dateccadre?: string;
    public empData?: string;
    public conjpermis?: string;
    public lanData?: string;
    public nation?: string;
    public concuctxt?: string;
    public postdesire?: string;
    public cdfId?: string;
    public photo?: string;
    public profFrom?: string;
    public info5?: string;
    public info6?: string;
    public certificat?: string;
    public etatcivil?: string;
    public langue1?: string;
    public lppstart1?: string;
    public enfConcub?: string;
    public conjFi?: string;
    public natel?: string;
    public nobenalloc?: string;
    public paytype?: string;
    public nogestion?: string;
    public listenoir?: string;
    public info2?: string;
    public lppheucnt0?: string;
    public firstMis?: string;
    public apprec?: string;
    public typehor?: string;
    public lan1Lu?: string;
    public lpermoto?: string;
    public webimpfile?: string;
    public lanNote?: string;
    public apt1?: string;
    public typejob?: string;
    public casier?: string;
    public lchomage?: string;
    public permisno?: string;
    public banqueCpt?: string;
    public acofact?: string;
    public opVital?: string;
    public langue4?: string;
    public ppempfrx?: string;
    public firstRap?: string;
    public opNote?: string;
    public conjNom?: string;
    public lweb?: string;
    public ageId?: string;
    public typehortxt?: string;
    public requerant?: string;
    public lallocs?: string;
    public empaptId?: string;
    public aptId?: string;
    public webimpdate?: string;
    public xmlData?: string;
    public pays?: string;
    public langue0?: string;
    public dtatype?: string;
    public enfMenind?: string;
    public inquiry?: string;
    public saldesire?: string;
    public caissecho?: string;
    public userId?: string;
    public lppheucnt1?: string;
    public modCnt?: string;
    public dipl5?: string;
    public noteBo?: string;
    public agi?: string;
    public pregnant?: string;
    public lppsemcnt0?: string;
    public lieu?: string;
    public concuc?: string;
    public rating?: string;
    public dipl4?: string;
    public datenaiss?: string;
    public datetatciv?: string;
    public conId?: string;
    public vehpernote?: string;
    public extrcasier?: string;
    public webimpdata?: string;
    public apt7?: string;
    public noavs13?: string;
    public langue3?: string;
    public enfnbcharg?: string;
    public note?: string;
    public conjnation?: string;
    public apt8?: string;
    public dateDelet?: string;
    public dtanymodif?: string;
    public apt10?: string;
    public ofsnr?: string;
    public banqueAdr?: string;
    public conjWork?: string;
    public enfnballoc?: string;
    public banqueClr?: string;
    public references?: string;
    public dateModif?: string;
    public lan3Parle?: string;
    public dipl3?: string;
    public textSm?: string;
    public poursuites?: string;
    public lan4Lu?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
