/* eslint-disable no-null/no-null */
import {isArray} from 'lodash';

import {IwObjectMenuItemMetadata, METADATA_IW_MENU_ITEM_OBJECT, METADATA_IW_MENU_ITEM_PROP} from '../models';

export function isObjectMenuItemMetadata(input: any): input is IwObjectMenuItemMetadata {
    if (typeof input !== 'undefined' && input !== null) {
        const hasClassMetadata = isArray(input[METADATA_IW_MENU_ITEM_OBJECT]);
        const hasPropMetadata = typeof (input[METADATA_IW_MENU_ITEM_PROP]) === 'object' && (input[METADATA_IW_MENU_ITEM_PROP]) !== null;

        return hasClassMetadata && hasPropMetadata;
    }

    return false;
}
