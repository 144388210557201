import moment, {Moment, unitOfTime} from 'moment';

export class IwDateHelper {

    private static _locale = 'en-gb';

    public static get locale() {
        return this._locale;
    }

    public static set locale(v: string) {
        this._locale = v;
        moment.locale(v);
    }

    public static dateToShortMoment(date?: Date): Moment | undefined {
        if (!date) {
            return undefined;
        }
        return moment(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
    }

    public static momentToShortMoment(date?: Moment): Moment | undefined {
        if (!date) {
            return undefined;
        }
        return moment(date.year() + '-' + (date.month() + 1) + '-' + date.date());
    }

    public static dateFormat(date?: string | Date | Moment | undefined): string {
        return moment(date)
            .format('L');
    }

    public static dateFormatFromString(date: string | Date | Moment | undefined, value: string): string {
        return moment(date)
            .format(value)
            .replace(/\//g, '-');
    }

    public static dateTimeFormat(date: string | Date | Moment | undefined): string {
        return IwDateHelper.dateFormat(date) + ' ' + IwDateHelper.dateFormatFromString(date, 'HH:mm:ss');
    }

    public static dateFormatNonDefaultValueFromString(date: string | Date | Moment | undefined, currentFormat: string,
                                                      desiredFormat: string) {
        return moment(date, currentFormat)
            .format(desiredFormat)
            .replace(/\//g, '-');
    }

    public static dateWeek(date: string | Date): number {
        return moment(date)
            .week();
    }

    public static dateWeekDay(date: string | Date): number {
        return moment(date)
            .day();
    }

    public static dateMonth(date: string | Date): number {
        return moment(date)
            .month();
    }

    public static dateMonthString(date: string | Date): string {
        return moment(date)
            .format('MMMM');
    }

    public static dateYear(date: string | Date): number {
        return moment(date)
            .year();
    }

    /** Format date to string YYYY-MM-DD */
    public static dateIsoString(date?: string | Date | number | Moment): string {
        return moment(date)
            .toISOString(true)
            .substring(0, 10);
    }

    /** Format date to string YYYY-MM-DDTmm:hh:ss.zzz */
    public static dateIso(date?: string | Date | Moment): string {
        return moment(date)
            .toISOString(true);
    }

    /** Format date to string YYYY-MM-DDTmm:hh:ss.zzz */
    public static dateIsoNoTimezone(date?: string | Date | Moment): Date {
        const dateWithTimezone = moment(date)
            .toDate();
        const userTimezoneOffset = dateWithTimezone.getTimezoneOffset() * 60000;
        return new Date(dateWithTimezone.getTime() + userTimezoneOffset);
    }

    public static getDate(date?: string | Moment | undefined): Date {
        return moment(date)
            .toDate();
    }

    public static getMoment(date?: string | Date | undefined): Moment {
        return moment(date);
    }

    public static addToDate(value: number, type: any, date?: string | Moment | Date): Moment {
        return moment(date)
            .add(value, type);
    }

    public static addToDuration(value: number, dur: moment.Duration): moment.Duration {
        return dur.add(value);
    }

    public static datesDiff(date1: string | Moment | Date, date2: string | Moment | Date, unit: any): number {
        return moment(date2)
            .diff(moment(date1), unit);
    }

    /**
     * Function to get age by date of birthday in month precision
     *
     * @param dateOfBirth date of birthday
     * @returns age in months
     */
    public static getAgeInMonthsByDateOfBirth(dateOfBirth: string): number {
        return moment()
            .diff(dateOfBirth, 'months');
    }

    public static dateSubtract(date: string | Moment | Date, amount: number, unit: any): Moment {
        return moment(date)
            .subtract(amount, unit);
    }

    public static isValid(date: string | Moment | Date): boolean {
        return moment(date)
            .isValid();
    }

    public static startOf(unit: any, date?: string | Moment | Date): Moment {
        return moment(date)
            .startOf(unit);
    }

    public static endOf(unit: any, date?: string | Moment | Date): Moment {
        return moment(date)
            .endOf(unit);
    }

    public static getDuration(value: string | moment.Duration): moment.Duration {
        return moment.duration(value);
    }

    public static asHours(value: moment.Duration): number {
        return value.asHours();
    }

    public static isoWeekday(date: string | Moment | Date): number {
        return moment(date)
            .isoWeekday();
    }

    public static dateSet(date: string | Moment | Date, unit: unitOfTime.All, value: number): Moment {
        return moment(date)
            .set(unit, value);
    }

    /**
     * Build the string format for a date in current locale.
     * eg: fr-ch -> DD.MM.YYYY
     *
     * @static
     * @returns
     * @memberof IwDateHelper
     */
    public static getDateFormat(): string {
        return moment(new Date(2000, 11, 22))
            .format('L')
            .replace('2000', 'YYYY')
            .replace('12', 'MM')
            .replace('22', 'DD');
    }

    /**
     * Build the string format for a month in current locale.
     * eg: fr-ch -> MMM.YYYY
     *
     * @static
     * @returns
     * @memberof IwDateHelper
     */
    public static getMonthFormat(): string {
        return moment(new Date(2000, 11))
            .format('MM-YYYY')
            .replace('2000', 'YYYY')
            .replace('11', 'MM');
    }

    public static getCurrentDateMonthFormatDDMMYYYY(): string {
        return moment()
            .format('DD.MM.YYYY');
    }

    public static getCurrentYear(): string {
        return moment()
            .format('YYYY');
    }

    public static getWeekNumber(date: Date | null, showWeekDay = false): string {
        if (!date) {
            return '';
        }

        if (showWeekDay) {
            return '/w' + (IwDateHelper.dateWeek(date));
        }
        return 'w' + (IwDateHelper.dateWeek(date));
    }

    public static areDatesEqual(date1: Date | string, date2: Date | string): boolean {
        const dateOneToMoment = moment(date1)
            .format('DD-MM-YYYY');
        const dateTwoToMoment = moment(date2)
            .format('DD-MM-YYYY');
        return dateOneToMoment === dateTwoToMoment;
    }

    public static isSameOrBefore(date1: Date | string | Moment, date2: Date | string): boolean {
        return moment(date1)
            .isSameOrBefore(moment(date2));
    }

    public static getSalId(date: string | Date | undefined): string {
        return moment(date)
            .format('YYYY MM');
    }

    public static getMomentSalId(date: string | Date | undefined): Moment {
        return moment(date, 'YYYY MM');
    }

    public static getCurrentDateAndTime(): string {
        return moment()
            .toISOString();
    }

    public static getCurrentDateAndTimeWithTimezone(): string {
        return (new Date()).toString();
    }

    public static getMonthFromNumber(monthNumber?: number): string {
        if (!monthNumber && monthNumber !== 0) {
            return '';
        }
        return moment()
            .month(monthNumber)
            .format('MMMM');
    }


    public static getYearsFromDate(date: Date | string | Moment) {
        return moment()
            .diff(date, 'years');
    }

    public static getMonthsFromDate(date: Date | string | Moment) {
        return moment()
            .diff(date, 'months');
    }

    public static getDateNow(): Date {
        return this.getDate(moment());
    }

    public static convertDateFormatToISO8601(dateStr: string | undefined, format: string = 'DD/MM/YYYY'): string {
        return moment(dateStr, format).format('YYYY-MM-DD');
    }
}
