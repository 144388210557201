import {Component, ElementRef} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Glclscpt} from '@sam-base/models/accounting/glclscpt';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {map} from 'rxjs/operators';

import {IwTextfieldListComponent} from './iw-textfield-list.component';

/**
 * Component created regarding SAM-4538
 * Comptes.
 */
@Component({
    templateUrl: './iw-textfield-list.component.html',
    selector: 'iw-textfield-compte',
    providers: [buildCustomValueProvider(IwTextfieldCompteComponent)]
})
export class IwTextfieldCompteComponent extends IwTextfieldListComponent<Glclscpt> {
    public readonly listOption: ModalSelectTableOption<Glclscpt, string>;

    constructor(modalService: ModalService, restService: RestApiService, elRef: ElementRef) {
        super(modalService, elRef, restService);
        this.propsToFilter = ['compte'];
        this.listOption = {
            columns: [
                {
                    prop: 'compte',
                    name: 'no',
                    width: 50
                },
                {prop: 'titre'}],
            map: (e: Glclscpt) => e.compte || '',
            selection: 'single',
            fetchAction: () => restService
                .getEntityClient(Glclscpt)
                .getRefData()
                .pipe(map(g => g.filter(e => e.corc === '0')
                    .sort((a, b) => (a.compte || '') < (b.compte || '') ? -1 : 1)))
        };
    }
}
