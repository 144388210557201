import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppabs')
export class Ppodos {
    @IwRestEntityId() public odId?: string;
    public type?: string;
    public date?: string;
    public odos?: string;
    public parendId?: string;
    public timeslot?: string;
}
