import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppprasadec')
export class Ppprasadec {
    @IwRestEntityId() public salId?: string;
    public dateModif?: string;
    public totDed?: number;
    public userCreat?: string;
    public totSal?: number;
    public decData?: string;
    public nbcalc?: number;
    public decstatus?: number;
    public log?: string;
    public userModif?: string;
    public dateCreat?: string;
    public nbemp?: number;
    public dateCalc?: string;
}
