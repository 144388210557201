import {Component, EventEmitter, Input, OnInit, Output, Type, ViewChild} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {AutoComplete, AutoCompleteCompleteEvent} from 'primeng/autocomplete';
import {lastValueFrom, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {RestApiService, RestEntityClient} from '../../core/rest-api';
import {isObject, isStringArray} from '../../helpers/guards';
import {SortDirection} from '../../models';

@Component({
    selector: 'iw-entity-dropdown',
    templateUrl: './entity-dropdown.component.html',
    providers: [buildCustomValueProvider(EntityDropdownComponent)]
})
export class EntityDropdownComponent<T> implements ControlValueAccessor, OnInit {
    @Input() public labelAlign: 'top' | 'left' | 'right' = 'top';

    @Input() public label = '';

    @Input() public noLabel = false;

    @Input() /** The string to show for each option */ public display?: string | string[] | ((e: T) => string);

    @Input() public isDisabled = false;

    @Input() /** When set to true needs to be taking into account in buildLabel */ public hasEmptyChoice = false;

    @Input() public emptyChoice?: T;

    @Input() public loadAtStartUp = false;

    @Input() public selectedChoice?: number;

    @Input() public preselectedId?: number;

    @Input() public extraOptions?: T[];
    @Input() public forceSelection = true;
    @Input() public selected?: T;
    // Adds extra element properties when opening the dropdown
    @Input() public entityExtraProperties?: (keyof T)[];
    @Input() public sortingProperty?: (keyof T);
    @Input() public sortDirection: SortDirection = 'asc';
    @Input() public defaultOption?: string;
    @Output() public selectedIdChange = new EventEmitter<string | number>();
    @Output() public valueChange = new EventEmitter<string | number>();
    @Output() public selectedChange = new EventEmitter<T>();
    @Output() public entitySelected = new EventEmitter<void>();
    public dropdownOptions: T[] = [];
    @ViewChild('item', {static: true}) public autoComplete?: AutoComplete;
    protected _entityService?: RestEntityClient<T>;
    protected _onChange?: any;

    constructor(protected readonly _apiService: RestApiService) {
    }

    public get value() {
        return this.selectedId;
    }

    @Input()
    public set value(v: string | number | undefined) {
        this.selectedId = v;
    }

    public get EntityIdProp() {
        if (this._entityService) {
            return this._entityService.EntityIdProp;
        }

        return '';
    }

    public get classValue() {
        return {
            [this.labelAlign]: true
        };
    }

    public get styleValue() {
        return {
            width: this._width
        };
    }

    private _width?: string;

    public get width() {
        return this._width;
    }

    @Input()
    public set width(v: undefined | string | number) {
        this._width = typeof v === 'number' ? v + 'px' : v;
    }

    protected _selectedId?: string | number;

    public get selectedId() {
        return this._selectedId;
    }

    @Input() /** The id of the selected option */ public set selectedId(id: string | number | undefined) {
        if (this._selectedId === id && !!this.selected) {
            return;
        }
        this._selectedId = id;
        this.loadSelected();
    }

    protected _entityType?: Type<T>;

    public get entityType() {
        return this._entityType;
    }

    @Input()
    public set entityType(val: Type<T> | undefined) {
        this._entityType = val;
        if (val) {
            this._entityService = this._apiService.getEntityClient(val);
            this.loadSelected();
        }
    }

    public ngOnInit() {
        if (this.loadAtStartUp) {
            this.loadDropdownOptions()
                .subscribe(options => {
                    this.dropdownOptions = options;
                    if (this.selectedChoice || this.selectedChoice === 0) {
                        const selectedTemp = this.selectedChoice;
                        this.selected = options[selectedTemp];
                        this._selectedId = this.getSelectedId();
                        this.onChange();
                    }

                });


            if (this.preselectedId) {
                this.loadDropdownOptions()
                    .subscribe(options => {
                        options.forEach((score: T) => {
                            if ((<any>score)[this.EntityIdProp] === this.preselectedId) {
                                this.selected = score;
                                this._selectedId = this.getSelectedId();
                                this.onChange();
                            }
                        });
                    });
            }
        }
    }

    /** Method used to filter dropdown options */
    @Input() public resultFilters: (e: T) => boolean = e => true;

    public writeValue(obj: any): void {
        this.selectedId = obj;
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public getLabelValueGenerator() {
        return (e: T) => this.getLabelValue(e);
    }

    public getLabelValue(e: T) {
        switch (typeof this.display) {
            case 'function':
                return this.display(e);
            case 'string':
                return <string>((<any>e || {})[this.display]);
            case 'object':
                return this.generateLabelFromArray(e, this.display);
            default:
                return e;
        }
    }

    public getEntityExtraPropertiesLabel(e: T) {
        let extraProperties = '';

        if (!this.entityExtraProperties) {
            return;
        }

        this.entityExtraProperties.forEach(property => {
            extraProperties += ' | ' + e[property];
        });

        return extraProperties;
    }

    public onQueryChange(event: AutoCompleteCompleteEvent) {
        this.loadDropdownOptions()
            .subscribe(options => {
                this.dropdownOptions = options.filter(option => this.compareEntityToQuery(option, event.query || ''));
            });
    }

    public onChange() {
        this._selectedId = this.getSelectedId();
        this.selectedChange.emit(this.selected);
        this.selectedIdChange.emit(this.selectedId);
        this.valueChange.emit(this.value);

        if (typeof this._onChange === 'function') {
            this._onChange(this.selectedId);
        }
    }

    public async onBlur() {
        if (!this.selected && this.dropdownOptions && this.dropdownOptions.length === 1) {
            this.selected = this.dropdownOptions[0];
            this.onChange();
        }
    }

    protected loadDropdownOptions(): Observable<T[]> {
        if (this._entityService) {
            const filterAction = this.resultFilters;
            return this._entityService.getRefData()
                .pipe(/** Add empty selection when hasEmptyChoice */
                    map(e => this.hasEmptyChoice ? (this.emptyChoice ? [<T>this.emptyChoice].concat(e) : [{} as T].concat(e)) : e), map(e => this.extraOptions && this.extraOptions.length > 0 ? (e.concat(this.extraOptions)) : e), map(e => e.filter(filterAction)), map(e => this.applySort(e)));
        }

        return of([]);
    }

    private applySort(list: T[]) {
        const sort = this.sortingProperty;
        if (!sort) {
            return list;
        }
        return this.sortDirection === 'desc' ? list.sort((a, b) => (b[sort] as any).localeCompare(a[sort], undefined, {
            numeric: true, sensitivity: 'base'
        })) : list.sort((a, b) => (a[sort] as any).localeCompare(b[sort], undefined, {
            numeric: true, sensitivity: 'base'
        }));
    }

    private getSelectedId() {
        if (isObject(this.selected)) {
            return (<any>this.selected)[this.EntityIdProp];
        }

        return undefined;
    }

    private async loadSelected() {
        this.selected = !!this._selectedId ? await this.getEntityById(this._selectedId) : undefined;

        this.selectedChange.emit(this.selected);
    }

    private async getEntityById(id: string | number) {
        if (this._entityService) {
            return lastValueFrom(this._entityService.getById(id.toString()));
        }

        return undefined;
    }

    private generateLabelFromArray(e: T, list: any): string {
        if (isStringArray(list)) {
            return list.map(s => (<any>e)[s])
                .join(' ');
        }

        return '';
    }

    private compareEntityToQuery(e: T, query: string): boolean {
        if (!query) {
            return true;
        }
        const value = '' + this.getLabelValue(e);
        return value.toLocaleLowerCase()
            .indexOf(query.toLocaleLowerCase()) !== -1;
    }
}
