import {
    ChangeDetectionStrategy, Component, Input, OnInit
} from '@angular/core';
import {
    buildCustomValueProvider
} from '@app/sam-base/base/build-value-provider';

@Component({
    selector: 'iw-cell-status-commandes',
    templateUrl: './iw-cell-status.component.html',
    providers: [buildCustomValueProvider(IwCellStatusCommandesComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IwCellStatusCommandesComponent implements OnInit {

    @Input() public row?: { status: number };

    @Input() public color = '#80ff80'; // Default color

    @Input() public value = '';

    constructor() {
    }

    public ngOnInit() {
        if (this.row && (this.row.status !== undefined)) {
            this.checkColor(this.row.status);
        }
    }

    // eslint-disable-next-line complexity
    public checkColor(status: number) {
        if (status === 0) {
            this.color = '#008000';
        } else if (status === 1) {
            this.color = '#80ff80';
        } else if (status >= 2) {
            this.color = '#ff8080';
        } else {
            this.color = '#80ff80';
        }
    }
}
