import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppevent')
export class Ppevent {
    @IwRestEntityId() public id?: string;
    public information?: string;
    public parentId?: string;
    public parenType?: string;
    public dateAlert?: string;
}
