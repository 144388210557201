import {Component, Input} from '@angular/core';

/**
 * Fills the whole space with a overlay and a spinning animation
 */

@Component({
    templateUrl: './iw-busy.component.html', selector: 'iw-busy'
})
export class IwBusyComponent {

    /** Ajust the horizontal position  */
    @Input() public ajustLeft = 12;

    /** Ajust the vertical position */
    @Input() public ajustTop = 12;

    /** Size measure to use when ajusting the position */
    @Input() public ajustMeasure: 'px' | 'rem' | '%' = 'px';

    @Input() public hasDelay = true;

    public get style() {
        return {
            'margin-left': (-1 * this.ajustLeft) + this.ajustMeasure,
            'margin-top': (-1 * this.ajustTop) + this.ajustMeasure
        };
    }

    public get class() {
        return this.hasDelay ? 'iw-busy' : 'iw-busy-no-delay';
    }

    public get spinnerClass() {
        const base = 'form-spinner pi pi-spinner ';
        return base + (this.hasDelay ? 'pi-spin' : 'pi-spin-no-delay');
    }

    /** Sets component visibility */
    private _isBusy = false;

    public get isBusy() {
        return this._isBusy;
    }

    /** Sets component visibility */
    @Input()
    public set isBusy(v: boolean) {
        setTimeout(() => {
            this._isBusy = v;
        }, 0);
    }
}
