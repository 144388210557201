<iw-modal-form [btnCancel]="false" [btnOk]="false" [height]="250" [title]="''" [width]="550">
    <form [formGroup]="formGroup" class="row bottom-xs">
        <div class="col-xs-9">

            <div class="row space-form">

                <iw-textfield #txtAdrlib [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                              [label]="'description' | translate" class="iw-input col-xs-8"
                              formControlName="adrlib"></iw-textfield>

                <iw-textfield #txtCliAdr_id [isDisabled]="true" class="iw-input col-xs-4"
                              formControlName="cliadrId"></iw-textfield>

                <iw-textfield #txtPrefixe [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                              [label]="'prefixe' | translate" class="iw-input col-xs-12"
                              formControlName="prefixe"></iw-textfield>

                <iw-textfield #txtNom [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'nom' | translate"
                              class="iw-input col-xs-12" formControlName="nom"></iw-textfield>

                <iw-textfield #txtAdresse1 [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                              [label]="'adresse' | translate" class="iw-input col-xs-12"
                              formControlName="adresse1"></iw-textfield>

                <iw-textfield #txtAdresse2 [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="' '"
                              class="iw-input col-xs-12" formControlName="adresse2"></iw-textfield>

                <iw-textfield #txtAdresse3 [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="' '"
                              class="iw-input col-xs-12" formControlName="adresse3"></iw-textfield>

                <iw-textfield #txtNpa [isDisabled]="!isWriteMode" [labelAlign]="'left'" [label]="'npaLieu' | translate"
                              class="iw-input col-xs-6" formControlName="npa"></iw-textfield>

                <iw-textfield #txtLieu [isDisabled]="!isWriteMode" class="iw-input col-xs-6"
                              formControlName="lieu"></iw-textfield>

                <iw-pays-dropdown #cboPays [isDisabled]="!isWriteMode" [labelAlign]="'left'"
                                  [label]="'pays' | translate" [width]="'100%'"
                                  class="iw-input col-xs-11 col-no-padding" formControlName="pays"></iw-pays-dropdown>
            </div>
        </div>

        <div class="col-xs-3 space-form">
            <div class="row col-xs-12">
                <iw-button #btnOk (press)="saveChanges()" [text]="'enregistrer' | translate"
                           class="iw-button btn-block"></iw-button>
            </div>

            <div class="row col-xs-12">
                <iw-button #Closebutton1 (press)="closeDialog()" [text]="'annuler' | translate"
                           class="iw-button btn-block"></iw-button>
            </div>
        </div>

    </form>
</iw-modal-form>
