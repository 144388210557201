import {IwColumn} from '../../core/column';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('saenf')
export class Saenf {
    @IwRestEntityId() public enfId?: string;
    public empId?: string;
    @IwColumn({
        index: 0,
        name: 'nom'
    }) public nom?: string;
    @IwColumn({
        index: 1,
        name: 'prenom'
    }) public prenom?: string;
    public sexe?: string;
    public noavs13?: string;
    public acharge?: boolean;
    public allocation?: boolean;
    public datenaiss?: string;
    public alfBegin?: string;
    public alfEnd?: string;
    public alfMnt?: string;
    public alfuniqMnt?: string;
}
