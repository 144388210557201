import * as formActions from '../actions/form.actions';

export type FormMode = 'read' | 'edit' | 'new';

export interface FormState {
    mode: FormMode;
    focus: boolean;
    loading: boolean;
    sidemenu: SidemenuState;
    showSidemenu: boolean;
    formStatus?: FormStatus;
    showFormStatus: boolean;
    width: number;
    isModal: boolean;
    readonly?: boolean;
    title: string;
}

export interface FormStatus {
    dateModif?: string;
    dateCreat?: string;
    userModif?: string;
    userCreat?: string;
}

export interface SidemenuState {
    navigationButtons?: boolean;
    orderBySelect?: boolean;
    filters?: boolean;
    searcher?: boolean;
    create?: boolean;
    edit?: boolean;
    delete?: boolean;
    print?: boolean;
    close?: boolean;
}

export interface FormStore {
    [uuid: string]: FormState | undefined;
}

function getInitialState(): FormState {
    return {
        loading: false,
        mode: 'read',
        focus: false,
        sidemenu: {},
        showSidemenu: false,
        isModal: false,
        showFormStatus: false,
        width: 800,
        title: ''
    };
}

const defaultInitialState: FormStore = {};

// eslint-disable-next-line complexity
export function reducer(state = defaultInitialState, action: formActions.FormActions): FormStore {
    switch (action.type) {
        case formActions.INIT_FORM_DIALOG: {
            return {
                ...state, [action.uuid]: getInitialState()
            };
        }

        case formActions.INIT_FORM_ENTITY: {
            const formState = state[action.uuid];
            if (!formState) {
                return state;
            }

            const opt = action.options;

            return {
                ...state, [action.uuid]: <FormState>{
                    ...formState,
                    sidemenu: opt.sideMenu,
                    showFormStatus: opt.showFormStatus,
                    showSidemenu: opt.showSidemenu,
                    isModal: opt.isModal,
                    width: opt.width,
                    title: opt.title
                }
            };
        }

        case formActions.DESTROY_FORM_DIALOG: {
            return {
                ...state, [action.uuid]: undefined
            };
        }

        case formActions.UPDATE_SIDE_MENU_STATE: {
            const formState = state[action.uuid];
            if (!formState) {
                return state;
            }

            const menuState = formState.sidemenu;

            return {
                ...state, [action.uuid]: {
                    ...formState, sidemenu: {
                        ...menuState, ...action.state
                    }
                }
            };
        }

        case formActions.UPDATE_FORM_WITH: {
            const formState = state[action.uuid];
            if (!formState) {
                return state;
            }

            return {
                ...state, [action.uuid]: {
                    ...formState, width: action.width
                }
            };
        }

        case formActions.SET_LOADING_STATE: {
            const formState = state[action.uuid];
            if (!formState) {
                return state;
            }

            return {
                ...state, [action.uuid]: {
                    ...formState, loading: action.isLoading
                }
            };
        }

        case formActions.START_EDIT_MODE: {
            const formState = state[action.uuid];
            if (!formState) {
                return state;
            }

            return {
                ...state, [action.uuid]: <FormState>{
                    ...formState, mode: 'edit'
                }
            };
        }

        case formActions.START_READ_MODE: {
            const formState = state[action.uuid];
            if (!formState) {
                return state;
            }

            return {
                ...state, [action.uuid]: <FormState>{
                    ...formState, mode: 'read'
                }
            };
        }

        case formActions.SET_READONLY: {
            const formState = state[action.uuid];
            if (!formState) {
                return state;
            }

            return {
                ...state, [action.uuid]: {
                    ...formState, readonly: action.readonly
                }
            };
        }

        case formActions.SET_MODAL: {
            const formState = state[action.uuid];
            if (!formState) {
                return state;
            }

            return {
                ...state, [action.uuid]: {
                    ...formState, isModal: action.isModal
                }
            };
        }
    }
    return state;
}
