import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestEntityClient} from '@app/sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {Ppshcalcmode} from '@app/sam-base/models';
import {Dictionary} from '@app/sam-base/models/dictionary.model';
import {Ppcct, Ppemp} from '@app/sam-base/models/placement';
import {CctElement, CctResponse, EditionsCct, VersionCct} from '@app/sam-base/models/placement/cct.model';
import {Cpolitesse} from '@app/sam-base/models/placement/employee.enums';
import {CalculationTypes, MinSalaryCalc} from '@app/sam-base/models/placement/min-salary-calc.model';
import {environment} from '@root/environments/environment';
import {ProfileService} from '@shared/profile/profile.service';
import {UserProfile} from '@shared/profile/user-profile.model';
import {forkJoin, lastValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {CctSearchTerm} from '../../models/placement/cct-search-term';

export enum EmployeeGender {
    male = 0, female = 1
}

export interface PgmOptions {
    default: string;
    options: string;
}

@Injectable()
export class CctService {

    public currentStructure: CctElement[] = [];
    public childQuestions: CctElement[] = [];
    protected _ppshcalcmodeRestClient?: RestEntityClient<Ppshcalcmode>;
    private responsesCollection: CctResponse[] = [];
    private readonly _ppempRest: RestEntityClient<Ppemp>;
    private readonly _ppcctRest: RestEntityClient<Ppcct>;
    private _userLanguage = 'fr';

    constructor(private _http: HttpClient, protected readonly _apiService: RestApiService,
                private _profile: ProfileService) {
        this._ppshcalcmodeRestClient = this._apiService.getEntityClient(Ppshcalcmode);
        this._ppempRest = this._apiService.getEntityClient(Ppemp);
        this._ppcctRest = this._apiService.getEntityClient(Ppcct);
        this.getUserLanguage();
    }

    public getStruct(editionId: string, language: string): Observable<CctElement[]> {
        // eslint-disable-next-line max-len
        const url = environment.backendURL + `cct/edition/${editionId}?language=${language}`;
        return this._http.get<CctElement[]>(url);
    }

    public getInputList(editionId: string, criteriaId: number, language: string): Observable<CctResponse[]> {
        const url = // eslint-disable-next-line max-len
            environment.backendURL + `cct/edition/${editionId}/criteria/${criteriaId}?language=${language}`;
        return this._http.post<CctResponse[]>(url, this.responsesCollection);
    }

    public getListOfVersions(contractNumber: string, date: string, language: string): Observable<VersionCct[]> {
        const url = environment.backendURL + `cct/versions/${contractNumber}/date/${date}?language=${language}`;
        return this._http.get<VersionCct[]>(url);
    }

    public getListOfEditions(contractNumber: string, versionNumber: number): Observable<EditionsCct[]> {
        const url = environment.backendURL + `cct/editions/${contractNumber}/${versionNumber}`;
        return this._http.get<EditionsCct[]>(url);
    }

    public getMinimumSalary(editionId: string, language: string) {
        // eslint-disable-next-line
        const url = environment.backendURL + `cct/minimumsalary/edition/${editionId}/roundedResults=false?language=${language}`;

        // Tempdata expects that all questions must have a response, even if the question is not explicitly asked due to
        //  conditional logic
        const ids = this.collectAllIdsFromCctElements(this.currentStructure)
        ids.forEach(id => {
            if (this.findIndexInColletion(id) === -1) {
                this.responsesCollection.push({
                    criteriaStructureId: id,
                    value: null,
                    criteriaListEntryId: null
                })

            }
        });
        return this._http
            .post<MinSalaryCalc>(url, this.responsesCollection);
    }

    public getCalculationTypes() {
        const url = environment.backendURL + 'cct/minimumsalary/calculationtypes';

        return this._http.get<Dictionary<string>>(url);
    }

    public getSuggestionsByTerm(searchTerm: string, language: string) {
        const url = environment.backendURL + `cct/${searchTerm}?language=${language}`;

        return this._http
            .get<CctSearchTerm[]>(url);
    }

    public getCaclModeComboValue(holidayType: number, vacationType: number, month13thType: number) {

        const holVal = this.getTypeValue(holidayType);
        const vacVal = this.getTypeValue(vacationType);
        const month13Val = this.getTypeValue(month13thType);

        if (!this._ppshcalcmodeRestClient) {
            return;
        }

        return lastValueFrom(this._ppshcalcmodeRestClient.getRefData()
            .pipe(map(e => e.filter(i => i.calcjf === holVal && i.calcvac === vacVal && i.calc13 === month13Val))));
    }

    // eslint-disable-next-line complexity
    public getTypeValue(type: number) {
        switch (type) {
            case 1:
                return CalculationTypes.type_1;
            case 2:
                return CalculationTypes.type_2;
            case 3:
                return CalculationTypes.type_3;
            case 4:
                return CalculationTypes.type_4;
            case 5:
                return CalculationTypes.type_5;
            case 6:
                return CalculationTypes.type_6;
            case 7:
                return CalculationTypes.type_7;
            case 8:
                return CalculationTypes.type_8;
        }
    }

    /** Registers cct responses in a pool by adding
     *  or replacing if already registered
     */
    public registerResponse(cctResp: CctResponse) {
        const index = this.findIndexInColletion(cctResp.criteriaStructureId);

        if (index === -1) {
            this.responsesCollection.push(cctResp);
        } else {
            this.responsesCollection.splice(index, 1, cctResp);
        }
        console.log('responsesCollection', this.responsesCollection)
    }

    /** Removes response of the pool of responses */
    public removeReponse(cctRespId: number) {
        this.removeChildReponses(cctRespId);

        const index = this.findIndexInColletion(cctRespId);
        if (index === -1) {
            return;
        }
        this.responsesCollection.splice(index, 1);
        console.log('responsesCollection', this.responsesCollection)
    }

    public resetCollection() {
        this.responsesCollection = [];
        this.currentStructure = [];
        console.log('responsesCollection', this.responsesCollection)
    }

    public getTempdataText() {
        const qaList: { question: string; answer: string }[] = [];

        /** Find all children of children recursily */
        this.childQuestions = [];
        this.currentStructure.forEach(e => this.flatten(e.children));

        const flattedCompleteStrucuture = this.currentStructure.concat(this.childQuestions);

        flattedCompleteStrucuture
            .forEach(e => {
                if (this.hasAnswer(e.id)) {
                    qaList.push({
                        question: e.title,
                        answer: this.findAnswer(e.id)
                    });
                }
            });

        let label = '';

        qaList
            .forEach((e, index) => label += `${e.question} \n ${e.answer} \n \n`);

        return label;
    }

    /**
     * Returns the code pgm options based on employee gender
     */
    public getCodePgmOptions(cctId: string, empId: string): Observable<PgmOptions> {
        return forkJoin([
            this._ppempRest.getById(empId),
            this._ppcctRest.getById(cctId)])
            // eslint-disable-next-line complexity
            .pipe(map(([employee, cct]: [Ppemp, Ppcct]) => {
                const gender: EmployeeGender = this.getEmployeeGender(employee.cpolitesse);
                if (gender === EmployeeGender.male) {
                    return {
                        default: cct.defcodpgmh ?? '',
                        options: cct.pgmcodesh ?? ''
                    };
                }
                return {
                    default: cct.defcodpgmf ?? '',
                    options: cct.pgmcodesf ?? ''
                };
            }));
    }

    private collectAllIdsFromCctElements(elements: CctElement[]): number[] {
        let ids: number[] = [];
        elements.forEach(element => {
            ids.push(element.id);
            ids = ids.concat(this.collectAllIdsFromCctElements(element.children));
        });
        return ids;
    }

    private getEmployeeGender(gender?: string): EmployeeGender {
        return gender === Cpolitesse.monsieur ? EmployeeGender.male : EmployeeGender.female;
    }

    private findIndexInColletion(index: number): number {
        return this.responsesCollection
            .findIndex(r => r.criteriaStructureId === index);
    }

    private removeChildReponses(id: number) {
        if (!this.currentStructure) {
            return;
        }
        for (const node of this.currentStructure) {
            if (node.id === id) {
                let idsToRemove: number[] = [];
                node.children.forEach(c => {
                    idsToRemove = idsToRemove.concat(this.collectChildIds(c));
                });

                idsToRemove
                    .forEach(e => this.responsesCollection
                        .splice(this.findIndexInColletion(e), 1));
            }
        }
    }

    private collectChildIds(child: CctElement): number[] {
        let list: number[] = [];

        list.push(child.id);
        if (child.children.length > 0) {
            child.children.forEach(c => list = [
                ...list,
                ...this.collectChildIds(c)]);
        }

        return list;
    }

    private findAnswer(id: number): string {
        const answerObj = this.responsesCollection.find(e => e.criteriaStructureId === id);

        return answerObj?.value?.toString() || '';
    }

    private hasAnswer(id: number): boolean {
        return this.responsesCollection
            .some(e => e.criteriaStructureId === id && e.value);
    }

    private flatten(children: CctElement[]) {
        if (children.length) {
            this.childQuestions.push(...children);
            children
                .filter(c => !!c.children.length)
                .forEach(cObj => this.flatten(cObj.children));
        }
    }

    private getUserLanguage() {
        this._profile.loadProfile()
            .subscribe((userProfile: UserProfile) => {
                this._userLanguage = ProfileService.langParser(userProfile?.lang) || 'fr';
            });
    }
}
