import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppprasa')
export class Ppprasa {
    @IwRestEntityId() public empId?: string;
    public salId?: string;
    public sal2?: string;
    public sortie?: string;
    public userCreat?: string;
    public base?: string;
    public oldnoavs13?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public entree?: string;
    public mutation?: string;
    public cotEmp?: string;
    public taux?: string;
    public degreinv?: string;
    public sal?: string;
    public dateCreat?: string;
    public userModif?: string;
    public coeEmp?: string;
    public userDelet?: string;
    public note?: string;
    public modified?: string;
    public langue?: string;
}
