import {Type} from '@angular/core';
import {createSelector, select} from '@ngrx/store';
import {Observable} from 'rxjs';

import {AppState} from '../reducers';

/** Select store of FormEntity */
export const selectFormEntityStore = (e: AppState) => e.formEntity;

export const selectFormEntityState = (e: AppState, uuid: string) => e.formEntity[uuid];

export function selectFormEntity<T>(uuid: string): (source$: Observable<AppState>) => Observable<T | undefined> {
    const selector = (formUuid: string) => createSelector(selectFormEntityStore, (formEntity) => formEntity[formUuid].entity);
    return select(selector(uuid));
}

export function selectFormIsLoading<T>(uuid: string): (source$: Observable<AppState>) => Observable<boolean> {
    const selector = (formUuid: string) => createSelector(selectFormEntityStore, (formEntity) => formEntity[formUuid].loading);
    return select(selector(uuid));
}

export function selectFormType<T>(uuid: string): (source$: Observable<AppState>) => Observable<Type<T> | undefined> {
    const selector = (formUuid: string) => createSelector(selectFormEntityStore, (formEntity) => formEntity[formUuid].type);
    return select(selector(uuid));
}
