import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {Langue} from '@sam-base/models/common/langue.enum';

/** Parameters for an agency */
@IwRestEntity('pppar')
export class Pppar {
    @IwRestEntityId() public ppparId = '0';
    public ageId?: string;
    public aptId?: string;
    public coeffMin?: number;
    public ccfrx?: string;
    public modCnt?: number;
    public lieuuc?: boolean;
    public smmode?: string;
    public ppXmldata?: string;
    public shbaseglob?: number;
    public lnoaco?: boolean;
    public rapverhor?: number;
    public dateModif?: string;
    public cmfinfrx?: string;
    public lanstrict?: boolean;
    public clitarifmd?: number;
    public yearArc?: string;
    public cdedefhor?: string;
    public deftypejob?: number;
    public hpausaimod?: number;
    public shsupcalmd?: number;
    public smioIdI?: string;
    public lshbglob?: boolean;
    public allosal0?: boolean;
    public acoacolibs?: string;
    public cmfrx?: string;
    public atttravfrx?: string;
    public defcodpgmh?: string;
    public lprvhnudef?: boolean;
    public defcodpgmf?: string;
    public evafrx?: string;
    public rapprtfrx?: string;
    public mbexclgs?: string;
    public rapnoref?: boolean;
    public ppempfrx?: string;
    public lprvjfhdef?: boolean;
    public ijData?: string;
    public misfacmode?: number;
    public acofactreq?: number;
    public nomempuc?: boolean;
    public hmultisai?: boolean;
    public adrescli?: boolean;
    public cliremtpl?: string;
    public pgmcodexpl?: string;
    public lheupause?: boolean;
    public matchflist?: string;
    public cdedefdur?: string;
    public cmreceived?: boolean;
    public aptcdeobli?: boolean;
    public smioimpid?: string;
    public usertext2?: string;
    public usertext6?: string;
    public cdedefcen?: string;
    public faceleord?: number;
    public lprovhnuit?: boolean;
    public acomntsafe?: number;
    public aptcdfobli?: boolean;
    public cdetempo?: boolean;
    public nomcliuc?: boolean;
    public userCreat?: string;
    public agi2Frx?: string;
    public smioimpdat?: string;
    public empremtpl?: string;
    public lprovvac?: boolean;
    public misarcnbj?: number;
    public grtstrict?: boolean;
    public acoround?: number;
    public ccreceived?: boolean;
    public lheunuit?: boolean;
    public cdedeflieu?: string;
    public lprv13def?: boolean;
    public acofact?: number;
    public cmavfrx?: string;
    public ppcdffrx?: string;
    public quamode?: number;
    public lcctoblig?: boolean;
    public agifrx?: string;
    public cdeoblig?: boolean;
    public smioDtE?: string;
    public lppmode?: number;
    public boAgeId?: string;
    public pgmcodesf?: string;
    public userModif?: string;
    public pccharges?: number;
    public lieu?: string;
    public lprov13?: boolean;
    public lprov13Def?: boolean;
    public ccavfrx?: string;
    public defcodcna?: string;
    public txVac4?: number;
    public lclitarcct?: boolean;
    public ppcdefrx?: string;
    public webioData?: string;
    public ppclifrx?: string;
    public indmntsafe?: number;
    public smioIdE?: string;
    public misremtpl?: string;
    public mbexclcpt?: string;
    public userDelet?: string;
    public txheunuit?: number;
    public l13H?: boolean;
    public usertext7?: string;
    public mbfocgcpt?: string;
    public shcalcmode?: number;
    public maxlevlan?: number;
    public maxlevinf?: number;
    public lprvvacdef?: boolean;
    public cdfremtpl?: string;
    public langue?: Langue;
    public maxlevrat?: number;
    public coefcalcmd?: number;
    public acomntmin?: number;
    public lij?: boolean;
    public caisselpp?: string;
    public shclear?: boolean;
    public shautofin?: boolean;
    public aptoblig?: boolean;
    public lfraish1?: boolean;
    public pgmcodesh?: string;
    public lcct?: boolean;
    public txVac5?: number;
    public usertext3?: string;
    public userId?: string;
    public agePrefix?: string;
    public actSalId?: string;
    public suistrict?: boolean;
    public defpaytype?: number;
    public lsCctId?: string;
    public shautoclos?: boolean;
    public indmntsafr?: number;
    public tx13?: number;
    public ljfh?: boolean;
    public missalmode?: number;
    public cmrem1?: string;
    public prenomempu?: boolean;
    public ppData?: string;
    public lfraish2?: boolean;
    public acowithind?: boolean;
    public acoraplibs?: string;
    public usertext4?: string;
    public usertext9?: string;
    public acoquifrx?: string;
    public smioDtI?: string;
    public cliadrmode?: number;
    public usertext1?: string;
    public acocalcmod?: number;
    public txJf?: number;
    public allotarif0?: boolean;
    public plffrx?: string;
    public lppnbwim?: number;
    public cderemtpl?: string;
    public lwebio?: boolean;
    public acosemlibs?: string;
    public usertext8?: string;
    public cnacodes?: string;
    public usertext5?: string;
    public lnoacorap?: boolean;
    public acoinrap?: boolean;
    public lnoacoemp?: boolean;
    public modData?: string;
    public suiprivate?: boolean;
    public ccrem1?: string;
    public modefactu?: number;
    public acofactis?: number;
    public caissecomp?: string;
    public lprovjfh?: boolean;
    public cnacodexpl?: string;
    public quastrict?: boolean;
    public contractmis?: string;
    public avecontractmis?: string;
    public contractloc?: string;
    public avecontractloc?: string;
    public placefix?: string;
    public dossiercan?: string;
    public signature?: string;
    public good?: string;
    public medium?: string;
    public bad?: string;
    public autocloseMission?: boolean;
    public forceUseCctTempData?: boolean;
    public entityMultiWindow?: boolean;
    public suvaPeActivationDate?: string;
    public hpauseAuto?: boolean;
}
