import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity} from '@app/sam-base/core/rest-api';

export enum SamUserRole {
    SAM_FACTURE_RO = 'SAM_FACTURE_RO',
    SAM_FACTURE_RW = 'SAM_FACTURE_RW',
    SAM_FACTURE_PRINT = 'SAM_FACTURE_PRINT',
    SAM_FACTURE_DELETE = 'SAM_FACTURE_DELETE',
    SAM_FACTURE_COMPTA = 'SAM_FACTURE_COMPTA',
    SAM_FACTURE_COM = 'SAM_FACTURE_COM',
    SAM_SALAIRE_RO = 'SAM_SALAIRE_RO',
    SAM_SALAIRE_RW = 'SAM_SALAIRE_RW',
    SAM_SALAIRE_PRINT = 'SAM_SALAIRE_PRINT',
    SAM_SALAIRE_COM = 'SAM_SALAIRE_COM',
    SAM_PLACEMENT_RO = 'SAM_PLACEMENT_RO',
    SAM_PLACEMENT_RW = 'SAM_PLACEMENT_RW',
    SAM_PLACEMENT_PRINT = 'SAM_PLACEMENT_PRINT',
    SAM_PLACEMENT_PARAM = 'SAM_PLACEMENT_PARAM',
    SAM_SALAIRE_COMPTA = 'SAM_SALAIRE_COMPTA',
    SAM_PLACEMENT_COM = 'SAM_PLACEMENT_COM',
    SAM_PLACEMENT_ADMIN_PERIOD = 'SAM_PLACEMENT_ADMIN_PERIOD',
    SAM_FACTURE_ADMIN_PERIOD = 'SAM_FACTURE_ADMIN_PERIOD',
    SAM_SALAIRE_ADMIN_PERIOD = 'SAM_SALAIRE_ADMIN_PERIOD',
    SAM_ADMIN_SWISSDEC = 'SAM_ADMIN_SWISSDEC',
    SAM_ADMIN_USERS = 'SAM_ADMIN_USERS',
    SAM_ADMIN_GOD_MODE = 'SAM_ADMIN_GOD_MODE',
    SAM_FACTURE_ADMIN = 'SAM_FACTURE_ADMIN',
    SAM_SALAIRE_ADMIN = 'SAM_SALAIRE_ADMIN',
    SAM_PLACEMENT_ADMIN = 'SAM_PLACEMENT_ADMIN',
    SAM_PLACEMENT_ACOMPTE_ADMIN = 'SAM_PLACEMENT_ACOMPTE_ADMIN',
    SAM_PLACEMENT_CLIENT_ADMIN = 'SAM_PLACEMENT_CLIENT_ADMIN',
    SAM_PLACEMENT_ATTESTATIONS = 'SAM_PLACEMENT_ATTESTATIONS',
    SAM_PLACEMENT_EXTRACTIONS = 'SAM_PLACEMENT_EXTRACTIONS',
    SAM_PLACEMENT_EXTRACTIONS_ENTREPRISE = 'SAM_PLACEMENT_EXTRACTIONS_ENTREPRISE',
    SAM_PLACEMENT_STATS_ENTREPRISE = 'SAM_PLACEMENT_STATS_ENTREPRISE',
    SAM_FACTURE_EXTRACTIONS = 'SAM_FACTURE_EXTRACTIONS',
    SAM_SALAIRE_EXTRACTION = 'SAM_SALAIRE_EXTRACTION'
}


@IwRestEntity('sam-user')
export class SamUser {

    id?: string;

    @IwColumn({index: 1})
    username?: string;

    userId?: string;

    @IwColumn({index: 2})
    email?: string;

    @IwColumn({index: 3})
    firstName?: string;

    @IwColumn({index: 4})
    lastName?: string;

    locale?: string;
    
    @IwColumn({index: 5})
    tenant?: string;

    @IwColumn({index: 6})
    gestions?: string[];

    password?: string;

    confirmPassword?: string;

    roles?: SamUserRole[];

    // userRepresentation?: UserRepresentation;

}
