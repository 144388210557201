import {Component, EventEmitter} from '@angular/core';
import {IwGridColumn, ModalComponent} from '@app/sam-base/models';
import {HtmlElementStatus} from '@app/sam-base/models/html-element-status.model';
import {PaiementsModel} from './../../../models/paiements.model';
import {ModalPaiementsOptions} from './modal-paiements-options.model';


@Component({
    selector: 'iw-modal-message',
    templateUrl: './modal-paiements.component.html'
})
export class ModalPaiementsComponent implements ModalComponent<void, ModalPaiementsOptions> {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public okDisabled: HtmlElementStatus = {
        enabled: true,
        value: 'Ok',
        visible: true
    };
    public cancelShow: HtmlElementStatus = {
        enabled: true,
        value: 'Cancel',
        visible: true
    };

    public tableColumns: IwGridColumn<PaiementsModel>[] = [
        {
            prop: 'payId',
            name: 'payId',
            index: 0
        },
        {
            prop: 'datePay',
            name: 'date',
            type: 'date',
            index: 1
        },
        {
            prop: 'montant',
            name: 'montant',
            index: 2,
            type: 'mnt',
            align: 'right'
        },
        {
            prop: 'facNo',
            name: 'nofac',
            index: 3
        },
        {
            prop: 'compte',
            name: 'compte',
            index: 4
        },
        {
            prop: 'lettrage',
            name: 'l',
            index: 5
        },
        {
            prop: 'origine',
            name: 'o',
            index: 6
        }];

    private _options!: ModalPaiementsOptions;
    private _ok?: (result: void) => void;
    private _cancel?: (error?: any) => void;

    public get content() {
        return this._options.content;
    }

    public get title() {
        return this._options.title;
    }

    public get width() {
        return this._options.width;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok();
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: ModalPaiementsOptions): Promise<void> {
        this._options = data;
        if (this._options.okDisabled) {
            this.okDisabled = {
                ...this.okDisabled,
                enabled: false,
                visible: true
            };
        }

        if (!this._options.showCancel) {
            this.cancelShow = {
                ...this.cancelShow,
                enabled: false,
                visible: false
            };
        }
    }

    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }
}
