import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';

import {isRestEntity, RestEntityMetadata} from '../../rest-api';
import {QueryBuilder} from '../../rest-api/elastic-search';
import {getEntityMetadata, initEntity} from '../../rest-api/entity';
import {RestApiService} from '../../rest-api/rest-api.service';
import {ToastService} from '../../toast/toast.service';
import * as entityActions from '../actions/entity.actions';
import * as actions from '../actions/global-form.actions';
import {IwStoreService} from '../iw-store.service';
import {GlobalFormError, GlobalFormState} from '../models';
import {AppState} from '../reducers';

@Injectable()
export class GlobalFormEffects<T> {

    /** Trigger entity load or navigation init */
    public initialize$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_INIT), withLatestFrom(this._store, (action,
                                                                                                                              state) => ({
            action,
            state
        })), // eslint-disable-next-line complexity
        map(({
                 action,
                 state
             }) => {
            // Check if is already init
            if (isInit(action.uuid, state)) {
                return {type: '[GLOBAL FORM] is init'};
            }
            if (action.state.editMode === 'waiting') {
                return new actions.InitializeWaiting(action.uuid);
            }
            if (action.state.editMode === 'waitLoading') {
                return new actions.InitializeWaitLoading(action.uuid);
            }
            if (!action.state.useNavigation && !action.state.isNew) {
                // If form has no navigation, trigger entity load
                if (action.state.entityId) {
                    return new actions.LoadEntity(action.uuid);
                } else {
                    return new actions.SetLoading(action.uuid, false);
                }
            } else if (action.state.useNavigation) {
                // If form has navigation, trigger navigation init
                return new actions.NavigationInit<T>(action.state.uuid);
            }
            return {type: '[GLOBAL FORM] new entity without nav'};
        })));

    /** Load navigation values and trigger a navigation success */
    public navigationInit$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_NAVIGATION_INIT), // Grab state for UUID
        selectState<T>(this._store), mergeMap(({
                                                   state,
                                                   uuid
                                               }) => {
            if (!state) {
                return of(buildError(uuid, GlobalFormError.UUID));
            }
            const profile = state.navProfile;
            if (!profile) {
                return of(buildError(uuid, GlobalFormError.Profile));
            }
            // Build query from [GridProfile]
            const query = QueryBuilder.fromGridProfile(profile, state.entityType)
                .getRequest();
            const instance = new state.entityType();
            if (isRestEntity(instance)) {
                query._source = [instance.$pk];
            }
            delete query.from; // Make sure it starts at 0
            query.size = 10000; // Max allowed items in elastic

            // Fetch query from elastic search
            return this._restService.elasticSearchService
                .query(query)
                .pipe(map(resp => {
                    // Check if query was successfully
                    if (resp.success) {
                        try {
                            // Instantiate values
                            const values = resp.result.hits.hits
                                .map(e => initEntity(state.entityType, e._source)
                                    .$getPk())
                                .filter(e => !!e); // Remove values without pk

                            return new actions.NavigationInitSuccess(uuid, values);
                        } catch (err) {
                            return buildError(uuid, GlobalFormError.NavigationFailQuery);
                        }
                    }

                    return buildError(uuid, GlobalFormError.NavigationFailQuery);
                }));
        })));

    /** Trigger reload for current entity */
    public navigationInitSuccess$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_NAVIGATION_INIT_SUCCESS), selectState<T>(this._store), filter(s => !!s.state && !s.state.isNew), map(a => new actions.LoadEntity(a.uuid))));

    /** Trigger load of previous entity */
    public navigationPrev$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_NAVIGATION_PREV), selectState<T>(this._store), navigateState<T>(-1)));

    /** Trigger load of next entity */
    public navigationNext$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_NAVIGATION_NEXT), selectState<T>(this._store), navigateState<T>(1)));

    /** Trigger load of first entity */
    public navigationFirst$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_NAVIGATION_FIRST), selectState<T>(this._store), navigateState<T>(0)));

    /** Trigger load of last entity */
    public navigationLast$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_NAVIGATION_LAST), selectState<T>(this._store), navigateState<T>(2)));

    /** Trigger load of current entity */
    public navigationEntity$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_NAVIGATION_ENTITY), map(a => new actions.LoadEntity(a.uuid))));

    /** Trigger load of current entity when changing to readmode */
    public changeToReadMode$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_SET_READ_MODE), map(a => new actions.LoadEntity(a.uuid))));

    /** Trigger load of entity id */
    public resetEntity$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_ENTITY_RESET), map(({uuid}) => new actions.LoadEntity(uuid))));

    public loadEntity$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_ENTITY_LOAD), selectState<T>(this._store), mergeMap(({
                                                                                                                                                   state,
                                                                                                                                                   uuid
                                                                                                                                               }) => {
        // If no entity is avaible, throw error
        if (!state) {
            return of(buildError(uuid, GlobalFormError.UUID));
        }
        if (state.isNew) {
            return of(buildError(uuid, GlobalFormError.NotWriteState));
        }

        const client = this._restService.getEntityClient(state.entityType);
        return client.getById(state.entityId.toString())
            .pipe(map(e => new entityActions.EntityLoadSuccess(e)), catchError((err) => of(new entityActions
                .EntityLoadFail(state.entityType, state.entityId, err), new actions.LoadEntityFailed(uuid))));
    })));

    /** Trigger save of entity id */
    public saveEntity$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_ENTITY_SAVE), selectState<T>(this._store), switchMap(({
                                                                                                                                                    state,
                                                                                                                                                    uuid
                                                                                                                                                }) => {
        if (!state || !state.entity) {
            return of(buildError(uuid, GlobalFormError.UUID));
        }

        if (state.isNew) {
            return of(new actions.SaveNewEntity(uuid));
        }

        const entityToSave = new state.entityType(state.entity);
        const client = this._restService.getEntityClient(state.entityType);
        return client.update(entityToSave)
            .pipe(map((entity) => new actions.SaveEntitySuccess<T>(uuid, entity)), catchError((err) => of(new entityActions.EntityUpdateFail(state.entity, err), new actions.LoadEntityFailed(uuid))));
    })));

    /** Create new entity in database */
    public createEntity$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_ENTITY_SAVE_NEW), selectState<T>(this._store), mergeMap(({
                                                                                                                                                         state,
                                                                                                                                                         uuid
                                                                                                                                                     }) => {
        // If no entity is avaible, throw error
        if (!state || !state.entity) {
            return of(buildError(uuid, GlobalFormError.UUID));
        }

        const client = this._restService.getEntityClient(state.entityType);
        return client.create(state.entity)
            .pipe(map(entity => {
                const entityId = getEntityMetadata(entity)
                    .$getPk();
                return new actions.SaveNewEntitySuccess(uuid, entityId, entity);
            }), catchError((err) => of(new entityActions.EntityUpdateFail(state.entity, err), new actions.LoadEntityFailed(uuid))));
    })));

    /** Trigger form destroy on save */
    public saveSuccess$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_ENTITY_SAVE_NEW_SUCCESS, actions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS), selectState<T>(this._store), map(({state}) => {
        if (state && state.destroyOnSave) {
            return new actions.DestroyForm(state.uuid);
        } else {
            return {type: 'save completed'};
        }
    })));

    /** Trigger delete of entity id */
    public deleteEntity$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_ENTITY_DELETE), selectState<T>(this._store), map(({
                                                                                                                                                  state,
                                                                                                                                                  uuid
                                                                                                                                              }) => {
        if (!state || !state.entity) {
            return buildError(uuid, GlobalFormError.UUID);
        }

        const entityToDelete = new state.entityType(state.entity);
        return (new entityActions.EntityDelete(entityToDelete, state.entityId));
    })));

    /** On error show error TOAST */
    public onError$ = createEffect(() => this._actions.pipe(ofType(actions.GLOBAL_FORM_ERROR), map(action => {
        this._toast.error(action.code);
        return {type: 'show_toast'};
    })));

    /**
     * Trigger form update when a entity is reloaded
     *
     * This subscribe to events of the entity store, and
     * when a load or update is SUCCESS, it will trigger a LOAD_SUCCESS
     * in the GlobalFormStore for each form that has the same entity
     */
    public onEntityLoaded$ = createEffect(() => this._actionsEntity.pipe(ofType(entityActions.ENTITY_LOAD_SUCCESS, entityActions.ENTITY_UPDATE_SUCCESS), withLatestFrom(this._store, (action,
                                                                                                                                                                                      state) => ({
        action,
        state
    })), mergeMap(({
                       action,
                       state
                   }) => {
        const updateEvents: Action[] = [];
        const meta = action.getMetadata();

        // Check all existing forms and update the value if required
        for (const formState of Object.values(state.globalForm)) {
            const uuid = isTargetUpdate<T>(meta, formState);
            if (uuid) {
                // Trigger save success
                if (isSaveState(action, formState)) {
                    updateEvents.push(new actions.SaveEntitySuccess<T>(uuid, formState?.entity));
                }

                updateEvents.push(new actions
                    .LoadEntitySuccess(uuid, action.entity));
            }
        }

        return updateEvents;
    })));

    public onEntityLoadFail$ = createEffect(() => this._actionsEntity.pipe(
        ofType(actions.GLOBAL_FORM_ENTITY_LOAD_FAIL),
        map((action: actions.LoadEntityFailed<T>) => {
            this._toast.warning('global_form_entity_load_fail_msg');
            return new actions.DestroyForm(action.uuid);
        }),
    ));

    constructor(private readonly _actions: Actions<actions.GlobalFormActions<T>>,
                private readonly _actionsEntity: Actions<entityActions.EntityActions<T>>,
                private readonly _store: IwStoreService, private readonly _restService: RestApiService,
                private readonly _toast: ToastService) {
    }
}

function buildError(uuid: string, error: GlobalFormError) {
    return new actions.TriggerError(uuid, error);
}

/** Select the current global form state for a action */
function selectState<T>(store: IwStoreService) {
    return withLatestFrom(store, (action: SelectStateResult<T>, state) => ({
        state: state.globalForm[action.uuid],
        uuid: action.uuid
    }));
}

/**
 * Return the ID base on the step direction
 *
 * @param state state to grab value from
 * @param step -1 back, 1 forward, 0 start, 2 last
 */
// eslint-disable-next-line complexity
function getNavigationID(state: GlobalFormState<any>, step: -1 | 1 | 0 | 2): string | number | undefined {
    let pos = 0;
    if (step === 2) {
        pos = state.navValues.length - 1;
    }
    if (step === -1 || step === 1) {
        pos = state.navValues.findIndex(e => e === state.entityId);
        pos = pos + step; // Walk back or forward
    }

    // If pos is over length, go to last
    if (pos >= state.navValues.length) {
        pos = state.navValues.length - 1;
    }

    if (pos < 0) {
        pos = 0;
    }

    return state.navValues[pos];
}

/**
 * Return event for an entity id, based on navigation position
 *
 * @param step -1 back, 1 forward, 0 start, 2 last
 */
function navigateState<T>(step: -1 | 1 | 0 | 2) {
    return map(({
                    state,
                    uuid
                }: SelectStateResult<T>) => {
        if (!state) {
            return buildError(uuid, GlobalFormError.UUID);
        }
        const id = getNavigationID(state, step);
        if (typeof id === 'undefined') {
            return buildError(uuid, GlobalFormError.NavigationFail);
        }

        return new actions.NavigationEntity(uuid, id);
    });
}

/** Check if target state is an update target */
function isTargetUpdate<T>(meta: RestEntityMetadata, state?: GlobalFormState<T>): string | false {
    // PICASSO ALERT
    // Basicaly I'm checking:
    return !!meta // Meta is not undefined
        && !!state // State is not undefined
        && state.entityId === meta.$getPk() // Check ID match
        // Check is the same entity
        && getEntityMetadata(state.entityType).$entity === meta.$entity && state.uuid; // Return UUID
}

function isSaveState<T>(action: entityActions.EntityActions<T>, state?: GlobalFormState<T>): boolean {
    return !!(action.type === entityActions.ENTITY_UPDATE_SUCCESS && state && !!state.__save__);
}

/** Check if state uuid is INIT */
function isInit<T>(uuid: string, state: AppState): boolean {
    if (!state || !state.globalForm) {
        return false;
    }
    const gState = state.globalForm[uuid];
    if (!gState) {
        return false;
    }
    return !!gState.__init__;
}

interface SelectStateResult<T> {
    state?: GlobalFormState<T>;
    uuid: string;
}
