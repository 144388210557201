import {Type} from '@angular/core';
import {EsAdvancedMatch} from '@app/sam-base/models/es-advanced-match';

import {loadColumnMetadata} from '../../column';
import {EsQueryStatement, getEntityMetadata, IwRestEntity, IwRestEntityId} from '../../rest-api';
import {arrayToObject} from '../helpers/convert';
import {GridProfileColumn} from './grid-profile-column.model';

export interface GridProfileData<T> {
    cfgid: string;
    userid: string;
    name: string;
    search: string;
    entity: string;
    isSystem?: boolean;
    columns: GridProfileColumn<T>;
    advsearch?: boolean;
    advsearchparams?: EsAdvancedMatch;
    defaultProfile: boolean;
    customFilter?: EsQueryStatement<any>;
}

@IwRestEntity('tableconfig')
export class GridProfile<T> implements GridProfileData<T> {
    @IwRestEntityId() public cfgid = '';
    public userid = '';
    public name = '';
    public search = '';
    public entity = '';
    public groupby = '';
    public advsearch = false;
    public isSystem = false;
    public columns: GridProfileColumn<T> = {available: {}};
    public advsearchparams?: EsAdvancedMatch;
    public defaultProfile = false;
    public isCreateSaems = false;
    public customFilter?: EsQueryStatement<any>;


    constructor(p?: GridProfileData<T>) {
        this.setProfile(p);
    }

    public static async fromResponse<T>(promise: Promise<GridProfile<T>>): Promise<GridProfile<T>> {
        return new GridProfile(await promise);
    }

    public static fromRawData<T>(data: GridProfileData<T>) {
        return new GridProfile(data);
    }

    public static fromType<T>(type: Type<T>): GridProfile<T> {
        const metadata = getEntityMetadata(type);
        return this.fromRawData({
            cfgid: '',
            entity: metadata.$entity,
            name: 'temp',
            search: '',
            userid: '',
            advsearch: false,
            isSystem: false,
            defaultProfile: false,
            columns: {
                available: arrayToObject(loadColumnMetadata(type) || [])
            }
        });
    }

    private setProfile(p?: GridProfileData<T>) {
        if (p) {
            this.cfgid = p.cfgid;
            this.userid = p.userid;
            this.name = p.name;
            this.search = p.search;
            this.entity = p.entity;
            this.advsearch = p.advsearch || false;
            this.isSystem = p.isSystem || false;
            this.columns = p.columns;
            this.advsearchparams = p.advsearchparams;
            this.defaultProfile = p.defaultProfile;
        }
    }
}
