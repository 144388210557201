import {IwRestEntity} from '@app/sam-base/core/rest-api';

import {IwRestEntityId} from '../../core/rest-api/entity/rest-entity-id.decorator';

@IwRestEntity('sasdstbl')
export class Sasdstbl {
    public ctype?: string;
    @IwRestEntityId() public elemId?: string;
    public titre?: string;
    public xmlvalue?: string;
}
