import {Component, EventEmitter} from '@angular/core';
import {RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core';
import {ComboboxItem, ModalComponent} from '@app/sam-base/models';
import {HtmlElementStatus} from '@app/sam-base/models/html-element-status.model';
import {Ppempdoc, Ppwxdocs} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom} from 'rxjs';

import {DocTypeEditOptions} from './doc-type-edit.options';

@Component({
    selector: 'iw-doc-type-edit', templateUrl: './doc-type-edit.component.html'
})
export class DocTypeEditComponent implements ModalComponent<Ppempdoc, DocTypeEditOptions> {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public ppempdoc: Ppempdoc = new Ppempdoc();
    public btnOk: HtmlElementStatus = {
        enabled: true, value: 'enregistrer', visible: true
    };
    public filenameOptions: ComboboxItem<string | undefined>[] = [];
    private _options!: DocTypeEditOptions;
    private _ok?: (result: Ppempdoc) => void;
    private _cancel?: (error?: any) => void;

    constructor(private _restService: RestApiService, private _translate: TranslateService) {
    }

    public get docId() {
        return this._options.docId;
    }

    public get isDocumentReceived(): boolean {
        return this.ppempdoc.received ?? false;
    }

    public onPressOk() {
        if (this._ok && this.ppempdoc) {
            this._ok(this.ppempdoc);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: DocTypeEditOptions): Promise<void> {
        this._options = data;
        this.ppempdoc = await lastValueFrom(this._restService.getEntityClient(Ppempdoc)
            .getById(data.docId));
        this.setFilenameOptions();
    }

    public registerOk(action: (result: Ppempdoc) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public removeFile() {
        this.ppempdoc.wxdocId = undefined;
    }

    private async setFilenameOptions() {
        this.filenameOptions.push({name: this._translate.instant('combo_aucun'), value: undefined});
        this.filenameOptions = this.filenameOptions.concat(await this.getFilenameDropdownOptions(this.ppempdoc.empId));
    }

    private async getFilenameDropdownOptions(empId?: string): Promise<ComboboxItem<string | undefined>[]> {
        if (!empId) {
            return [];
        }
        const query: RestQueryParam<Ppwxdocs, any>[] = [{
            operation: RestQueryOperation.Equals, prop: 'parentid', value: empId
        }];
        const docs = await lastValueFrom(this._restService.getEntityQuery(Ppwxdocs, ...query)
            .scroll());
        return docs
            .map(doc => ({name: doc.docfname ?? '', value: doc.wxdocId ?? ''}))
            .sort((a, b) => a.name.localeCompare(b.name));
    }
}
