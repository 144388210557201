import {IwColumn} from '../../core/column/decorators/iw-column.decorator';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('saparded')
export class Saparded {
    @IwRestEntityId() @IwColumn({name: 'id', index: 0}) public id?: string;

    public annee?: string;
    public dedCode?: string;
    public assCode?: string;
    public dedMaster?: string;
    public dedTitre?: string;
    public dedShtit?: string;
    public dedActive?: number;
    public dedType?: string;
    public dedOrder?: string;
    public assMany?: number;
    public dedOblig?: number;
    public mastermode?: number;
    public basemode?: number;
    public dedBasaut?: string;
    public gsBasaut?: string;
    public gsBas?: string;
    public gsSal?: string;
    public gsNs?: string;
    public gsCot?: string;
    public gsCoe?: string;
    public gsCnd?: string;
    public gsCotFix?: string;
    public gsCoeFix?: string;
    public gsBasCor?: string;
    public gsSalCor?: string;
    public gsCotCor?: string;
    public gsCoeCor?: string;
    public gs1?: string;
    public gs2?: string;
    public gs3?: string;
    public gs4?: string;
    public gs5?: string;
    public mnt1?: number;
    public mnt2?: number;
    public mnt3?: number;
    public mnt4?: number;
    public mnt5?: number;
    public tauxmode?: number;
    public tauxmany?: number;
    public tauxauto?: number;
    public txcot?: number;
    public txcoe?: number;
    public minmaxmode?: number;
    public salmin?: number;
    public salmax?: number;
    public nbjmode?: number;
    public olddedcode?: string;
    public dedData?: string;
    public assurance?: string;
}
