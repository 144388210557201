import * as licenseActions from '../actions/license.actions';
import {LicenseStore} from '../models/license.state';

function defaultLicenseState(): LicenseStore {
    return {
        options: {
            interijobwebaccess: false,
            swissdec: false,
            s3asdefaultstorage: false,
            smsalert: false,
            indexfulltextdocs: false,
            signrequest: false,
            mfiles: false
        }
    };
}

export function reducer(state: LicenseStore = defaultLicenseState(), action: licenseActions.LicenseActions): LicenseStore {
    switch (action.type) {
        case licenseActions.LICENSE_OPTIONS_LOAD_SUCCESS: {
            return {
                ...state, options: {
                    ...state.options, ...action.options
                }
            };
        }
    }

    return state;
}
