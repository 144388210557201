import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('contactncs')
export class ContactNcs {
    @IwRestEntityId() public id?: string;
    public department?: string;
    public address?: string;
    public npa?: string;
    public lieu?: string;
    public phone?: string;
    public fullName?: string;
}
