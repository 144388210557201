import {Injectable, Injector, Type} from '@angular/core';

import {EVENT_HANDLER_METADATA, registerEventHandler} from './iw-event-handler.decorator';

@Injectable({providedIn: 'root'})
export class IwEventRegisterService {
    constructor(private _injector: Injector) {
    }

    public registerEventHandlerProviders(...types: Type<any>[]) {
        for (const t of types) {
            this.registerEventHandlerProvider(t);
        }
    }

    public registerEventHandlerProvider(type: Type<any>) {
        const instance = this._injector.get(type);
        const prototype = Object.getPrototypeOf(instance);
        for (const prop of Object.getOwnPropertyNames(prototype)) {
            if (prototype[prop] && prototype[prop].hasOwnProperty && prototype[prop].hasOwnProperty(EVENT_HANDLER_METADATA)) {
                const events: string[] = prototype[prop][EVENT_HANDLER_METADATA];
                events.forEach(e => registerEventHandler(e, (s, p) => instance[prop](s, p)));
            }
        }
    }
}
