<div (dblclick)="onDoubleClick()" class="component">
    <div class="name">
        <iw-textfield [entityTextfield]="true" [inputClass]="inputClass" [isDisabled]="!(allowSearch && !isDisabled)"
                      [isReadOnly]="true" [labelAlign]="labelAlign" [label]="label"
                      [value]="entityValue"></iw-textfield>
    </div>
    <div [class.hide]="!showIdField" class="value">
        <iw-textfield #item (change)="onChange()" [isDisabled]="true" [value]="value"></iw-textfield>
    </div>
</div>
