import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('pprap')
export class Pprap {
    @IwRestEntityId() @IwColumn({
        index: 14,
        name: 'id'
    }) public rapId?: string;
    @IwColumn({
        index: 0,
        name: 'no'
    }) public rapNo?: string;
    @IwColumn({
        index: 1,
        name: 'periode'
    }) public salId?: string;
    @IwColumn({
        index: 2,
        name: 'heures'
    }) public nbheutot?: number;
    @IwColumn({index: 3}) public nbheu100?: number;
    @IwColumn({index: 4}) public nbheu125?: number;
    @IwColumn({index: 5}) public nbheu150?: number;
    @IwColumn({index: 6}) public nbheu200?: number;
    @IwColumn({index: 7}) public nbheuequip?: number;
    @IwColumn({index: 8}) public nbheupause?: number;
    @IwColumn({
        index: 9,
        name: 'indemnites'
    }) public mntindemp?: number;
    @IwColumn({
        index: 10,
        name: 'acomptes'
    }) public montant?: number;
    @IwColumn({index: 11}) public chantier?: string;
    @IwColumn({
        index: 12,
        name: 'facture'
    }) public facNo?: string;
    @IwColumn({
        index: 13,
        name: 'salary'
    }) public sent2Saltext?: string;
    @IwColumn({
        index: 15,
        name: 'employe'
    }) public nomemploye?: string;
    @IwColumn({index: 16}) public nomclient?: string;

    public mntindavs?: number;
    public sent2Fac?: boolean;
    public sent2Factext?: boolean;
    public userId?: string;
    public mntindcli?: number;
    public session?: string;
    public primId?: number;
    public facId?: string;
    public note?: string;
    public nbheu100p?: number;
    public mntheuemp?: number;
    public nbjours?: number;
    public finmotif?: string;
    public salIdFac?: string;
    public datelundi?: string;
    public nbjequip?: number;
    public salIdSal?: string;
    public dateDelet?: string;
    public facDate?: string;
    public lnoaco?: string;
    public modecreat?: string;
    public modCnt?: number;
    public nbheuequix?: number;
    public dtanymodif?: string;
    public parent?: string;
    public empId?: string;
    public majhist?: string;
    public userDelet?: string;
    public lhhmm?: string;
    public mntavscli?: number;
    public heusaimode?: string;
    public userCreat?: string;
    public workdays?: string;
    public rapData?: string;
    public faclintxt?: string;
    public ptrgenId?: string;
    public lastday?: string;
    public firstday?: string;
    public clichaId?: string;
    public hpauseauto?: boolean;
    public forceFacHeurePause?: boolean;
    public ijficId?: string;
    public dateModif?: string;
    public tauxNuit?: number;
    public cliId?: string;
    public tauxEquix?: number;
    public nbheunuit?: number;
    public mntheucli?: number;
    public ijData?: string;
    public acompte?: number;
    public modData?: string;
    public finmission?: string;
    public sent2Sal?: boolean;
    public lsinglefac?: string;
    public tauxXxx?: number;
    public nbheuxxx?: number;
    public nbnuits?: number;
    public userModif?: string;
    public pointData?: string;
    public ijrapId?: string;
    public salh4calc?: number;
    public ijNote?: string;
    public mntheuavs?: number;
    public dateCreat?: string;
    public misId?: string;
    public ijAlert?: string;
    public datasessionid?: string;

}
