import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppmisih')
export class Ppmisih {
    @IwRestEntityId() public misihId?: string;
    public modCnt?: number;
    public salBase?: number;
    public griId?: string;
    public facteur?: number;
    public tx13?: number;
    public txVac?: number;
    public mntempmode?: number;
    public lnopayVac?: string;
    public lnopay13?: string;
    public modData?: string;
    public qte?: number;
    public lprov13?: string;
    public indorheu?: string;
    public mnt13?: number;
    public txJf?: number;
    public mntJf?: number;
    public lvac?: string;
    public modified?: string;
    public cdeormis?: string;
    public l13?: string;
    public lprovBase?: string;
    public cid?: string;
    public ljfh?: string;
    public lprovJf?: string;
    public followsh?: number;
    public dateCreat?: string;
    public libelle?: string;
    public notes?: string;
    public cdeId?: string;
    public userCreat?: string;
    public shtxmode?: number;
    public mntJfz?: number;
    public lnopayJf?: string;
    public lprovVac?: string;
    public mntclimode?: number;
    public mntcli2?: number;
    public shcalcmode?: number;
    public dateDelet?: string;
    public userDelet?: string;
    public saisiemode?: number;
    public shbaseglob?: number;
    public calculated?: string;
    public shsupmode?: number;
    public dateModif?: string;
    public qtemode?: number;
    public misId?: string;
    public userModif?: string;
    public mntemp?: number;
    public grhId?: string;
    public cpymiscpy?: string;
    public mntVac?: number;
    public unite?: string;
    public mntcli?: number;
    public fact2Vj13?: boolean;
    public modecreat?: string;
    public ncoeffEmp?: string;
    public ncoeff?: string;
    public ncoeffCli?: string;

    public gsId?: string;
}
