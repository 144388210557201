<iw-dialog #dialog
           (dialogFocused)="onFocus()"
           [cls]="className"
           [displayed]="true"
           [modal]="formModal"
           [resizable]="true"
           [title]="formTitle | translate"
           [width]="formWidth">
    <div #form
         [id]="formTitle"
         class="row panel-container">
        <div *ngIf="loading"
             class="fas iw-busy">
            <div class="form-spinner pi pi-spin pi-spinner"></div>
        </div>
        <div [ngClass]="{'col-lg-10' : hasSideMenu, 'col-lg-12' : !hasSideMenu}"
             class="form-container">
            <ng-container [iwContainerHost]="formUUID"></ng-container>
        </div>
        <div *ngIf="hasSideMenu"
             class="col-lg-2 side-bar-container">
            <iw-side-menu [uuid]="formUUID"></iw-side-menu>
            <ng-container [iwContainerHost]="'side-' + (formUUID || '')"></ng-container>
            <div class="close-button">
                <iw-button (press)="closeDialog()"
                           [isDisabled]="fermeDisabled"
                           [text]="'sidemenu_close' | translate"
                           class="btn-block"></iw-button>
            </div>
        </div>
        <div *ngIf="hasStatus"
             class="formstatus-container">
            <iw-form-status [formId]="formUUID"></iw-form-status>
        </div>
    </div>
</iw-dialog>
