import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppempdoc')
export class Ppempdoc {
    @IwRestEntityId() @IwColumn({index: 0, name: 'id'}) public empdocId?: string;
    public docData?: string;
    public empId?: string;
    @IwColumn({index: 3, name: 'received'}) public received?: boolean;
    @IwColumn({index: 1, name: 'type'}) public empdoctype?: string;
    @IwColumn({index: 2, name: 'mandatory'}) public oblig?: boolean;
    public wxdocId?: string;
    public dateRece?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
