import {IwColumn} from '@app/sam-base/core/column';
import {IwContextMenuClass} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {MenuItem} from '@app/sam-base/models';

import {PlacementEvent} from './events.models';

const entityContextMenu: MenuItem[] = [
    {
        label: 'doc_manag_delete',
        event: PlacementEvent.DELETE_DOCUMENT,
        contextMenuVisibleMode: 'all'
    },
    {
        label: 'doc_manag_edit',
        event: PlacementEvent.RENAME_DOCUMENT,
        contextMenuVisibleMode: 'all'
    },
    {
        separator: true
    }];

@IwContextMenuClass(...entityContextMenu) @IwRestEntity('ppwxdocs')
export class Ppwxdocs {
    @IwColumn({
        index: 3,
        name: 'doc_manag_preview',
        type: 'preview'
    }) @IwRestEntityId() public wxdocId?: string;
    public docpath?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public userCreat?: string;
    @IwColumn({
        index: 0,
        name: 'doc_manag_document'
    }) public libelle?: string;
    public pathtype?: string;
    public userId?: string;
    public partype?: string;
    public doctype?: string;
    public doclnktype?: string;
    public userModif?: string;
    public parentid?: string;
    public doclnk?: string;
    public dateDelet?: string;
    public userDelet?: string;
    public docfname?: string;
    @IwColumn({
        index: 1,
        name: 'doc_manag_date',
        type: 'date'
    }) public dateDoc?: string;
    public mfId?: string;
    @IwColumn({
        index: 2,
        name: 'doc_manag_categories',
        type: 'translate'
    }) public doccategory?: string;
}
