<div class="col-xs-12">
    <iw-table #data
              [columns]="columns"
              [data]="saemcDetails"
              [defaultSelected]="true"
              [isDisabled]="false"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              [labelAlign]="'top'"
              class="iw-grid col-xs-12 no-padding user-config-grid padding-top-15 flex saempAnnesTable"
              style="height: 200px"></iw-table>
</div>