import {SamUserRole} from '@sam-base/models/admin/sam-user';

/**
 * Defines the roles for accounting
 */
export function accountingRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_COMPTA,
        SamUserRole.SAM_SALAIRE_COMPTA
    ];
}

/**
 * Defines the roles for managing salaries
 */
export function salaryMgtRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN
    ];
}

/**
 * Defines the roles for managing salaries
 */
export function salaryRWtRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_RW,
        SamUserRole.SAM_SALAIRE_ADMIN
    ];
}

/**
 * Defines the roles for managing communication
 */
export function salaryComRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_COM
    ];
}

export function salaryPrintRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_SALAIRE_PRINT
    ];
}


export function factureComRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_COM
    ];
}

export function debitorRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_COM,
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_FACTURE_RO,
        SamUserRole.SAM_FACTURE_PRINT,
        SamUserRole.SAM_FACTURE_PRINT
    ];
}


export function facturePrintRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_PRINT
    ];
}

export function factureRWRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_FACTURE_ADMIN
    ];
}

export function factureEncaissementsRole(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN
    ];
}

export function factureSuppressionRole(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_DELETE
    ];
}

/**
 * Defines the roles for managing salary period
 */
export function salaryMgtPeriodRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN_PERIOD,

    ];
}
