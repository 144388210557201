import {MenuItem} from '@app/sam-base/models';

import {PlacementEvent} from '../events.models';

export const ppsuiviContextMenu: MenuItem[] = [{
    label: 'ppsuivi_contextmenu_open', event: PlacementEvent.PPSUI_UPDATE, contextMenuVisibleMode: 'all'
}, {
    label: 'ppsuivi_contextmenu_delete', event: PlacementEvent.PPSUI_DELETE, contextMenuVisibleMode: 'all'
}, {
    label: 'ppsuivi_contextmenu_new', event: PlacementEvent.PPSUI_NEW, contextMenuVisibleMode: 'all'
}];
