import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {IwEventHubService, IwEventResult} from '@app/sam-base/core';
import {CctService} from '@app/sam-base/core/services/cct.service';
import {ComboboxItem} from '@app/sam-base/models';
import {CctComplete, CctResponse} from '@app/sam-base/models/placement/cct.model';
import {Subscription} from 'rxjs';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';

@Component({
    selector: 'iw-cct-element-dropdown',
    templateUrl: 'iw-cct-elem-dropdown.component.html',
    providers: [buildCustomValueProvider(IwCctElemDropdownComponent)]
})
export class IwCctElemDropdownComponent extends BaseFormControl<string | number> implements OnInit, OnDestroy {

    @Input() public width?: string | number;
    @Input() public labelWidth?: string;
    public options: ComboboxItem<string | number | CctResponse>[] = [];
    public toHide = true;
    protected changeSubscription = new Subscription();
    protected delSubscription = new Subscription();

    constructor(private readonly _cctService: CctService, private readonly _eventHub: IwEventHubService<string>) {
        super();
        this.toHide = true;
    }

    public _input?: CctComplete;

    @Input()
    public set input(v: CctComplete | undefined) {
        if (!v) {
            return;
        }
        this._input = v;
    }

    private get language() {
        return this._input?.translationLanguage ?? 'fr';
    }

    public ngOnInit() {
        if (!this._input) {
            return;
        }
        if (!this._input.node.parentId) {
            this.toHide = false;
            const resps: ComboboxItem<string | number | CctResponse>[] = [];
            // Filling parent data
            this._cctService.getInputList(this._input.editionId, this._input.node.id, this.language)
                .subscribe((resp: CctResponse[]) => {
                    resp.forEach((elem: CctResponse) => {
                        resps.push({
                            name: elem.value!,
                            value: elem
                        });
                    });
                    this.options = [
                        {
                            name: '',
                            value: this._input ? this._input.node.id : ''
                        },
                        ...resps];
                });
        }
        const parent = this._input;
        // Event emmited when a response is choosen
        this.changeSubscription = this._eventHub.forType<CctResponse>('cctresp')
            .subscribe((event) => {
                this.cctRespSubscribe(parent, event);
                if (!event.payload) {
                    return;
                }
                this._cctService.registerResponse(event.payload);
            });
        // Event emmited when the empty option is selected
        this.delSubscription = this._eventHub.forType<CctResponse>('cctrespdel')
            .subscribe((event) => {
                if (typeof event.payload === 'number' && event.payload === parent.node.parentId) {
                    this.toHide = true;

                    // Loop emiting in this sub to scale all node family and hide
                    this._eventHub.emit('cctrespdel', parent.node.id);
                }
            });
    }

    /**
     * Function called when a option is selected
     *
     * @param event event caused by option selection
     */
    public change(event: EventEmitter<CctResponse | number>) {
        if (typeof event === 'number') {
            this._eventHub.emit<number>('cctrespdel', event);
            this._cctService.removeReponse(event);
        } else {
            this._eventHub.emit('cctresp', event);
        }
    }

    public ngOnDestroy() {
        this.changeSubscription.unsubscribe();
        this.delSubscription.unsubscribe();
    }

    /**
     * Function called after the subscription is called to get child data
     *
     * @param parent object representing the actual node
     * @param event option present on the selected option
     */
    private cctRespSubscribe(parent: CctComplete, event: IwEventResult<string, CctResponse>): void {
        if (!event || !event.payload) {
            return;
        }
        if (this.isParent(parent, event)) {
            this._cctService.getInputList(parent.editionId || '', parent.node.id, this.language)
                .subscribe((resp: CctResponse[]) => {
                    // eslint-disable-next-line no-null/no-null
                    if (resp.length === 1 && resp[0].value === null) {
                        this._cctService.registerResponse(resp[0]);
                        this.toHide = true;
                    } else {
                        this.toHide = false;
                        const res: ComboboxItem<string | number | CctResponse>[] = [];
                        resp.forEach((elem: CctResponse) => {
                            res.push({
                                name: elem.value!,
                                value: elem
                            });
                        });
                        this.options = [
                            {
                                name: '',
                                value: parent.node.id || ''
                            },
                            ...res];
                    }
                });
        }
    }

    /**
     * Function called to check actual node parent
     *
     * @param parent object representing the actual node
     * @param event option present on the selected option
     */
    private isParent(parent: CctComplete, event: IwEventResult<string, CctResponse>): boolean {
        if (!event || !event.payload || !event.payload.criteriaStructureId || !parent.node.parentId) {
            return false;
        }
        return parent.node.parentId.toString() === event.payload.criteriaStructureId.toString();
    }
}
