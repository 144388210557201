import {Injectable} from '@angular/core';
import {OperationDetail} from '@modules/sam-main/admin/models/operation.model';
import {TranslateService} from '@ngx-translate/core';
import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';

import {IwEventHubService} from '../events/core';
import {TOAST_EVENT, ToastDuration, ToastLevel, ToastMessage} from './toast.model';

@Injectable()
export class ToastService {
    constructor(private readonly _eventService: IwEventHubService<string>,
                private readonly _translate: TranslateService) {
    }

    /**
     * Create a new toast entry
     *
     * @param messages messages to show
     * @param level color of message
     * @param timeout time to auto destroy
     */
    public create(messages: string | string[], level: ToastLevel = 'info', timeout: number = ToastDuration.normal,
                  sticky?: boolean, errorMessage?: string, isSystem?: boolean) {
        const message: string = (Array.isArray(messages)) ? messages.map(e => this._translate.instant(e))
            .join(' ') : this._translate.instant(messages);

        this._eventService.emit<ToastMessage>(TOAST_EVENT, {
            detail: message,
            severity: level,
            life: timeout,
            sticky,
            summary: errorMessage,
            isSystem
        });
    }

    public info(message: string | string[], errorMessage?: string) {
        this.create(message, 'info', ToastDuration.normal, undefined, errorMessage);
    }

    public success(message: string | string[]) {
        this.create(message, 'success', ToastDuration.normal);
    }

    public warning(message: string | string[]) {
        this.create(message, 'warn', ToastDuration.long);
    }

    public error(message: string | string[], errorMessage?: string, isSystem?: boolean) {
        this.create(message, 'error', ToastDuration.long, true, errorMessage, isSystem);
    }

    public businessExceptionMessage(exceptions: BusinessExceptionErrors) {
        if (!exceptions || !exceptions.errors || exceptions.errors.length === 0) {
            return;
        }
        // if one or more of the errors object has the code value to 'business-error.unexpected' we must put the severity to error
        const hasUnexpectedError = exceptions.errors.some(error => error.code === 'business-error.unexpected');
        const severity = hasUnexpectedError ? 'error' : 'warn';
        const isHtml = true;
        const formattedMessage = `<ul>${exceptions.errors.map(e => `<li>${this._translate.instant(e.code, e.params)}</li>`).join('')}</ul>`;
        this._eventService.emit<ToastMessage>(TOAST_EVENT, {
            detail: formattedMessage,
            isHtml,
            severity: severity,
            life: ToastDuration.long,
            sticky: true,
            summary: btoa(JSON.stringify(exceptions)),
            isSystem: true,
            closable: false
        });
    }

    public operationFeedback(operationDetail: OperationDetail) {
        const isHtml = true;
        this._eventService.emit<ToastMessage>(TOAST_EVENT, {
            summary: this._translate.instant('summary_operation_feedback', {type: operationDetail.operation?.type}),
            detail: this._translate.instant('detail_operation_feedback', {type: operationDetail.operation?.type}),
            isHtml,
            severity: 'info',
            isOperation: true,
            life: ToastDuration.long,
            sticky: true,
            // summary: btoa(JSON.stringify(exceptions)),
            closable: true
        });
    }

}
