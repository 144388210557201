import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Saemc} from '@app/sam-base/models/salary/saemc';
import {Observable} from 'rxjs';

import {environment} from '../../../../environments/environment';

@Injectable()
export class SaemcService {
    constructor(private _http: HttpClient) {
    }

    public findSaemcByEmpIdAndAnee(empId: string, annee: string): Observable<Saemc> {
        return this._http.get<Saemc>(environment.backendURL + `saemc/${empId}/${annee}`);
    }

    public saveByEmpIdAndAnnee(empId: string, annee: string, saemc: Saemc): Observable<Saemc> {
        return this._http.put(environment.backendURL + `saemc/${empId}/${annee}`, saemc);
    }
}
