import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pppoint1')
export class Pppoint1 {
    @IwRestEntityId() public ptficId?: string;
    public userCreat?: string;
    public dateDelet?: string;
    public rapId?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public modecreat?: string;
    public pointNo?: string;
    public dt?: string;
    public userModif?: string;
    public userDelet?: string;
    public pt1Id?: string;
    public ptstatus?: string;
    public rfid?: string;
    public misId?: string;
}
