import {createSelector, select} from '@ngrx/store';
import {Observable} from 'rxjs';

import {AppState} from '../reducers';
import {FormState, SidemenuState} from '../reducers/form.reducer';

export const selectForm = (state: AppState) => state.formStore;

export const getFormState = (state: AppState, id: string) => state.formStore[id];

export function getSidemenuState(state: AppState, id: string): SidemenuState | undefined {
    const sidemenuState = getFormState(state, id);
    if (!sidemenuState) {
        return;
    }
    return sidemenuState.sidemenu;
}

export function getFormStateLoading(state: AppState, id: string) {
    const formState = getFormState(state, id);
    if (!formState) {
        return true;
    }
    return formState.loading;
}

export function selectFormState(uuid: string): (source$: Observable<AppState>) => Observable<FormState | undefined> {
    const selector = (formUuid: string) => createSelector(selectForm, (form) => form[formUuid]);

    return select(selector(uuid));
}
