import {Injectable} from '@angular/core';

import {AppState, DestroyFormDialog, IwStoreService} from '../../store';
import {DialogEvents} from '../actions';
import {IwEventHandler} from '../core';

@Injectable()
export class DialogEventHandlers {

    public constructor(private _store: IwStoreService) {
    }

    @IwEventHandler(DialogEvents.closeFocusDialog)
    public closeFocusDialog(state: AppState) {
        if (state.dialogState.formId) {
            this._store.dispatch(new DestroyFormDialog(state.dialogState.formId));
        }
    }

}
