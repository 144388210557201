import {Component, ElementRef, Input} from '@angular/core';
import {BaseEntityDropdown, buildCustomValueProvider} from '@app/sam-base/base';
import {Gepaicond} from '@app/sam-base/models/invoices/gepaicond';


@Component({
    selector: 'iw-gepaicond-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwGepaicondDropdownComponent)]
})
export class IwGepaicondDropdownComponent extends BaseEntityDropdown<Gepaicond> {
    @Input() public width?: string | number;

    @Input() public warningMessage?: string;

    constructor(private elRef: ElementRef) {
        super();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    protected getEntityType() {
        return Gepaicond;
    }

    protected buildLabel(e: Gepaicond): string {
        return e.libelle || '';
    }
}
