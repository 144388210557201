import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppempapt')
export class Ppempapt {
    @IwRestEntityId() public aptId?: string;
    public apt10?: string;
    public info5?: string;
    public empId?: string;
    public apt8?: string;
    public apt5?: string;
    public info3?: string;
    public apt3?: string;
    public aptbin?: string;
    public dipl3?: string;
    public apt6?: string;
    public dipl4?: string;
    public userId?: string;
    public keywords?: string;
    public info1?: string;
    public apt1?: string;
    public apt7?: string;
    public info2?: string;
    public dtanymodif?: string;
    public info6?: string;
    public diplbin?: string;
    public dipl1?: string;
    public apt2?: string;
    public dipl5?: string;
    public info4?: string;
    public apt9?: string;
    public empaptId?: string;
    public apt4?: string;
    public dipl2?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
