import {HttpClient} from '@angular/common/http';
import {Injectable, Type} from '@angular/core';
import {CalculationClient} from './calculations/calculation-client';
import {EsQueryService} from './elastic-search';
import {getEntityName, isRestType, RestEntityClient} from './entity';
import {RestEntityQuery} from './entity/res-entity-query';
import {EntityServiceStore} from './local-store';
import {RestQueryParam} from './models';

/** Agregates services to do backend calls */
@Injectable()
export class RestApiService {
    public readonly entityClient: EntityServiceStore;
    public readonly calculationClient: CalculationClient;

    constructor(public readonly http: HttpClient, public readonly elasticSearchService: EsQueryService) {
        this.entityClient = new EntityServiceStore(http);
        this.calculationClient = new CalculationClient(http);
    }

    /** Register entity to be used in services */
    public registerEntityType<T>(type: Type<T>) {
        this.entityClient.register(type);
    }

    /** Register entities to be used in services */
    public registerEntityTypes(...types: Type<any>[]) {
        types.forEach(t => this.registerEntityType(t));
    }

    /** Returns a rest client for a entity */
    public getEntityClient<T>(t: T | Type<T> | string): RestEntityClient<T> {
        // Is string
        if (typeof t === 'string') {
            return this.entityClient.getServiceByName(t);
        }

        // Is Type<T>
        if (isRestType(t)) {
            return this.entityClient.getService(t);
        }

        // Is instance (T)
        const name = getEntityName(t);
        if (name) {
            return this.entityClient.getServiceByName(name);
        }

        throw new Error('Cant find service for ' + name);
    }

    /** Returns a query client for an entity */
    public getEntityQuery<T>(t: Type<T> | string, // eslint-disable-next-line comma-dangle
                             ...queries: RestQueryParam<T, any>[]): RestEntityQuery<T> {
        const client = this.getEntityClient(t);
        return RestEntityQuery.fromQueries(client, ...queries);
    }
}
