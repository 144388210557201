import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppapt')
export class Ppapt {
    @IwRestEntityId() public aptId?: string;
    public aptLib10?: string;
    public aptLib3?: string;
    public aptLib6?: string;
    public infoLib5?: string;
    public aptLib5?: string;
    public diplLib2?: string;
    public aptLib4?: string;
    public descr?: string;
    public aptLib8?: string;
    public diplLib5?: string;
    public actif?: string;
    public aptLib7?: string;
    public infoLib2?: string;
    public infoLib4?: string;
    public aptData?: string;
    public diplLib4?: string;
    public aptLib2?: string;
    public infoLib1?: string;
    public aptLib9?: string;
    public aptLib1?: string;
    public userId?: string;
    public diplLib3?: string;
    public infoLib3?: string;
    public diplLib1?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
