import {IwColumn} from '@app/sam-base/core/column/decorators';
import {IwContextMenuClass,} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {OperationEvents} from '@modules/sam-main/salary/events';
import {salaryComRoles} from '@sam-base/core/auth/access-rules/facture-roles';
import {MenuItem} from '@sam-base/models';

const entityContextMenu: MenuItem[] = [
    {
        label: 'ppemp_contextmenu_sendemail',
        event: OperationEvents.SAEMP_EMAIL,
        accessRoles: salaryComRoles(),
        contextMenuVisibleMode: 'all'
    },
    {
        label: 'fiche_sal_cur_year_by_mail',
        event: OperationEvents.SAEMP_BS_CURYEAR,
        accessRoles: salaryComRoles(),
        contextMenuVisibleMode: 'single',
        filter: {
            'bsToEmail': true
        }
    },
];

@IwContextMenuClass(...entityContextMenu) @IwRestEntity('saempview')
export class Saempview {
    @IwRestEntityId() @IwColumn({
        name: 'empId',
        index: 0,
        type: 'empStatus'
    })
    public empId?: string;
    @IwColumn({
        name: 'activeYears',
        index: 1,
        type: 'string',
        hidden: true
    })
    public activeYears?: string;
    @IwColumn({
        name: 'nom',
        index: 3
    })
    public nom?: string;
    @IwColumn({
        name: 'prenom',
        index: 4
    })
    public prenom?: string;
    @IwColumn({
        name: 'adresse',
        index: 12,
        hidden: true
    })
    public adresse1?: string;
    @IwColumn({
        name: 'npa',
        index: 20,
        hidden: true
    })
    public npa?: string;
    @IwColumn({
        name: 'lieu',
        index: 5
    })
    public lieu?: string;
    @IwColumn({
        name: 'pays',
        index: 13,
        hidden: true
    })
    public pays?: string;
    @IwColumn({
        name: 'natel',
        index: 8
    })
    public natel?: string;
    @IwColumn({
        name: 'email',
        index: 9
    }) public email?: string;
    @IwColumn({
        name: 'sendByMail',
        index: 10,
        type: 'boolean'
    }) public bsToEmail?: boolean;
    @IwColumn({
        name: 'datenaiss',
        type: 'date',
        index: 6
    })
    public datenaiss?: string;
    @IwColumn({
        name: 'nationalite',
        index: 13,
        hidden: true
    })
    public nation?: string;
    @IwColumn({
        name: 'etat_civil_column',
        index: 15,
        hidden: true
    })
    public etatcivil?: string;
    @IwColumn({
        name: 'agence',
        index: 16,
        hidden: true
    })
    public ageId?: string;
    @IwColumn({
        name: 'permis',
        index: 17,
        hidden: true
    })
    public permis?: string;
    @IwColumn({
        name: 'permisno',
        index: 18,
        hidden: true
    })
    public permisno?: string;
    @IwColumn({
        name: 'derniere_entree',
        index: 19,
        hidden: true
    })
    public dateInlas?: string;
    @IwColumn({
        name: 'profession',
        index: 7
    })
    public profession?: string;

    @IwColumn({
        name: 'noavs',
        index: 7
    })
    public noavs13?: string;
    @IwColumn({
        name: 'categorie1',
        index: 7,
        hidden: true
    })
    public categorie1?: string;
    @IwColumn({
        name: 'categorie2',
        index: 7,
        hidden: true
    })
    public categorie2?: string;
    @IwColumn({
        name: 'codeImp',
        index: 7,
        hidden: true
    })
    public codeImp?: number;
    @IwColumn({
        name: 'iban',
        index: 7,
        hidden: true
    })
    public iban?: string;

    @IwColumn({
        name: 'date_modif',
        type: 'dateTime',
        index: 10
    })
    public dateModif?: string;
    @IwColumn({
        name: 'date_creat',
        type: 'dateTime',
        index: 19,
        hidden: true
    })
    public dateCreat?: string;
    public dateTrack?: string;
    public dateDelet?: string;
}
