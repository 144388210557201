<iw-table #table
          (contextMenuClick)="onContextMenu($event)"
          (dataChanged)="onDataChanged($event)"
          (rowDoubleClick)="onRowDoubleClick($event)"
          (selectAllFromServer)="onSelectAllFromServer($event)"
          (selectedChange)="onSelection($event)"
          [allServerEntities]="allServerEntities"
          [columnResize]="true"
          [columns]="columns"
          [dataKey]="entityPk"
          [defaultSelected]="true"
          [gridType]="gridType"
          [isFilterEnable]="hasFilter"
          [isGridColumnMenuEnable]="hasGridColumnMenu"
          [isSortIconEnable]="hasSortIcon"
          [options]="tableOptions"
          [pageSize]="itemsPerPage"
          [rawTableMode]="rawTableMode"
          [selectAllFromServerEnabled]="selectAllFromServerEnabled"
          [selectedRow]="selectedData"
          [selectionMode]="selectMode"
          [showCaption]="true"
          [showGridDetails]="showGridDetails"
          [totalHits]="totalHits"
          [virtualScroll]="virtualScroll"></iw-table>

<iw-context-menu #menu
                 [contextMenuEnabled]="contextMenuEnabled"
                 [context]="contextMenuData"
                 [items]="contextMenuItems"
                 [popup]="true"></iw-context-menu>
