import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Type} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {ModalComponent} from '@app/sam-base/models';
import {BaseFormControl} from '@sam-base/base';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {AutoCompleteCompleteEvent, AutoCompleteSelectEvent, AutoCompleteUnselectEvent} from 'primeng/autocomplete';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {getEntityMetadata} from '../../core/rest-api/entity/rest-entity-helpers';
import {stringifyIfNotString} from '../../helpers/string';

@Component({
    selector: 'iw-textfield-multiautocomplete',
    templateUrl: './iw-textfield-multiautocomplete.component.html',
    providers: [buildCustomValueProvider(IwTextFieldMultiautocompleteComponent)]
})
export class IwTextFieldMultiautocompleteComponent<T> extends BaseFormControl<string> implements OnInit, OnDestroy {

    @Input() public icon?: string;
    @Input() public warningMessage?: string;
    @Input() public inputClass?: string;
    @Input() public tooltip?: string;
    @Input() public tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
    @Input() public suffix?: string;
    @Input() public doubleClickable = false;
    @Input() public multiple = false;
    @Output() public doubleClick = new EventEmitter<void>();
    public filteredSuggestions?: T[];
    @Input() public propToFilter?: keyof T;
    @Input() public fetchAction?: () => Observable<T[]>;
    @Input() public listOption?: ModalSelectTableOption<T, T>;
    @Input() public multiSelectComponent?: Type<ModalComponent<any, any>>;
    @Input() public multiSelectInputs?: any;
    public selectedValue?: T | T[] = undefined;
    @Output() public selectedValueChanged: EventEmitter<T | T[] | undefined> = new EventEmitter();
    private subscriptions = new Subject();
    private _entityPk?: keyof T;

    constructor(private elRef: ElementRef, private _restService: RestApiService,
                private readonly _modalService: ModalService) {
        super();
        this.valueChange.pipe(takeUntil(this.subscriptions))
            .subscribe(value => {
                if (!this._entity || !this._entityPk) {
                    return;
                }
                this.setComponentValue(value);
            });
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    private _entity?: Type<T>;

    @Input()
    public set entity(type: Type<T>) {
        if (!type) {
            return;
        }
        this._entity = type;
        this._entityPk = getEntityMetadata(type).$pk as keyof T;
    }

    ngOnInit(): void {
        this.setComponentValue(this.getValue());
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.unsubscribe();
    }

    public onBlur() {
        super.onBlur();
    }

    public filterSuggestions(event: AutoCompleteCompleteEvent) {
        if (!this.fetchAction || !this.propToFilter) {
            return;
        }
        const query = event.query;

        this.fetchAction()
            .subscribe({
                next: e => {
                    this.filteredSuggestions = e
                        .filter((data: T) => stringifyIfNotString(data[this.propToFilter as keyof T])
                            .toLowerCase()
                            // eslint-disable-next-line max-len
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '') // Removes accents
                            .indexOf(query.toLowerCase()) !== -1);
                },
                error: () => this.filteredSuggestions = []
            });
    }

    /**
     * Triggered when selecting a value while writing and
     * when clearing the form
     *
     * @param event Selected value
     * @param clear true if the user input has no matches
     */
    // eslint-disable-next-line complexity
    public setFormValue(event: AutoCompleteSelectEvent, clear?: boolean) {
        if (!this._entityPk) {
            return;
        }
        if (this.multiple && !clear) {
            // Sets the first quaId if the formcontrol was empty
            if (!this.value) {
                this.setValue(clear ? undefined : stringifyIfNotString(event.value[this._entityPk]));
            } else {
                // Adds the semicolon if the formcontrol had a value
                this.setValue(clear ? undefined : this.value.concat(';' + stringifyIfNotString(event.value[this._entityPk])));
            }
        } else if (!this.multiple) {
            this.setValue(clear ? undefined : stringifyIfNotString(event.value[this._entityPk]));
        }
    }

    /**
     * Triggered when in multiselect mode and unselecting a value
     *
     * @param event Unselected value
     */
    // eslint-disable-next-line complexity
    public unselectFormValue(event: AutoCompleteUnselectEvent) {
        // Remove value and semicolon
        if (!this._entityPk) {
            return;
        }
        let newValue = this.value?.replace((event.value[this._entityPk] ?? '') + ';', '') ?? '';
        // Remove only value if it is the last of the list
        newValue = newValue.replace(stringifyIfNotString(event.value[this._entityPk] ?? ''), '');
        // Remove last semicolon if exists
        newValue = newValue.substring(newValue.length - 1) === ';' ? newValue.slice(0, -1) : newValue;
        this.value = newValue !== '' ? newValue : undefined;
    }

    public async onDoubleClick() {
        if (this.isDisabled) {
            return;
        }
        this.doubleClick.emit();
        if (this.multiple) {
            this.openMultipleSelection();
        } else {
            this.openSingleSelection();
        }
    }

    // eslint-disable-next-line complexity
    private async openMultipleSelection() {
        if (!this.multiSelectComponent) {
            return;
        }
        try {
            let res;
            if (!this.multiSelectInputs) {
                // Opens multiselect component without extra properties
                res = await this._modalService.showModal(this.multiSelectComponent, !this.value ? ' ' : this.value);
            } else {
                // Opens multiselect component with extra properties
                res = await this._modalService.showModal(this.multiSelectComponent, {
                    ids: !this.value ? ' ' : this.value, ...this.multiSelectInputs
                });
            }
            this.setComponentValue(res);
        } catch (err) {
        }
    }

    private async openSingleSelection() {
        try {
            const comp: Type<ModalComponent<T[], T>> = <any>IwModalSelectTableComponent;
            const data: T[] = await this._modalService
                .showModal<T[], T>(comp, <any>this.listOption);
            this.setComponentValue(this.entityToEntityIdArray(data));
        } catch (err) {
        }
    }

    /**
     * Used to set the form value according to the selected values
     * and vice-versa
     *
     * @param quaIds selected qualif ids
     * @returns void
     */
    private setComponentValue(ids?: string) {
        if (!this._entity || !this._entityPk) {
            return;
        }
        if (!ids) {
            this.setSelectedValue();
            return;
        }
        const idsArray = ids.split(';');

        this._restService.getEntityClient(this._entity)
            .getRefData()
            .subscribe({
                next: e => {
                    // Filters the results based on the given ids
                    const newSelected = (e as any[])
                        .filter(ent => idsArray.includes(ent[this._entityPk] ?? ''));

                    // Checks if the new values are the same as the ones selected
                    // In the autocomplete component
                    if (stringifyIfNotString(newSelected) !== stringifyIfNotString(this.selectedValue)) {

                        this.setSelectedValue(this.multiple ? newSelected : newSelected[0]);

                        const oldValues = stringifyIfNotString(this.value?.split(';')
                            .sort());
                        const newValues = JSON
                            .stringify(newSelected.map(ent => ent[this._entityPk])
                                .sort());

                        // Checks if the values in the formcontrol are the same
                        // As the new ones
                        if (oldValues !== newValues) {
                            this.value = ids;
                        }
                    }
                },
                error: () => this.setSelectedValue()
            });
    }

    private entityToEntityIdArray(entities: T[]): string {
        if (!entities.length) {
            return '';
        }
        return entities.reduce((ids, entity) => {
            const ent: any = entity;
            return ids.concat(stringifyIfNotString(ent[this._entityPk] ?? ''))
                .concat(';');
        }, '')
            .slice(0, -1); // Remove last semicolon
    }

    private setSelectedValue(selectedValue?: T | T[]) {
        this.selectedValue = selectedValue;
        this.selectedValueChanged.emit(selectedValue);
    }
}
