import {isAlt, isCtrl, isMeta, isShift} from '../tools/modifiers';
import {HotkeyEvent} from './hotkey-event.model';
import {KeyboardKey} from './key-map.model';
import {KeyModifier} from './key-modifier.model';

export interface BaseHotkey {
    ctrl: boolean;
    shift: boolean;
    alt: boolean;
    meta: boolean;
    code: string;
}

export interface TogglePanelModel {
    hotkey: Hotkey;
    event: HotkeyEvent;
    e: { e: KeyboardEvent; h: Hotkey };
}

export class Hotkey {
    public preventDefaultAction = false;

    constructor(public readonly modifiers: KeyModifier, public readonly key: KeyboardKey, public readonly eventType: string, public readonly fn?: (event: HotkeyEvent) => void) {
    }

    public preventDefault() {
        this.preventDefaultAction = true;
        return this;
    }

    public getBase(): BaseHotkey {
        return {
            ctrl: isCtrl(this.modifiers),
            alt: isAlt(this.modifiers),
            meta: isMeta(this.modifiers),
            shift: isShift(this.modifiers),
            code: this.key
        };
    }
}
