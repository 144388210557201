import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GefachdrNew} from '@app/sam-base/models/gefachdr-new';
import {GefachdrFactors} from '@app/sam-base/models/salary/gefachdrFactors';
import {environment} from '@root/environments/environment';
import {RestParams} from '@sam-base/core/rest-api';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestClient} from '../rest-api/core';
import {Gefachdr} from './../../models/invoices/gefachdr';

export interface FactureSubscrition {
    uuid: string;
    saved: boolean;
}


@Injectable()
export class FactureService extends RestClient<any> {

    public factureSubscrition: Subject<FactureSubscrition> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getByfacId(facId?: string): Observable<Gefachdr> {
        return this._http.get<Gefachdr>(this._baseUrl + `gefachdr/${facId}`);
    }

    public bvrCalcNoRef(facNo?: string): Observable<void> {
        return this._http.get<void>(this._baseUrl + `gefachdr/bvrCalcNoRef/${facNo}`);
    }

    public copyFacture(facId?: string, isFac?: number): Observable<Gefachdr> {
        return this._http.post(this._baseUrl + `gefachdr/copy/${facId}/${isFac}`, undefined);
    }

    public save(gefachdrNew?: GefachdrNew): Observable<Gefachdr> {
        return this._http.post<Gefachdr>(this._baseUrl + `gefachdr/create`, gefachdrNew);
    }

    public factorsDataChanged(gefachdrFactors?: GefachdrFactors): Observable<void> {
        return this._http.post<void>(this._baseUrl + `gefachdr/factorsDataChanged`, gefachdrFactors);
    }

    public update(gefachdr: Gefachdr): Observable<Gefachdr> {
        return this._http.put<Gefachdr>(this._baseUrl + `gefachdr/${gefachdr.facId}/update`, gefachdr);
    }

    public compute(gefachdr: Gefachdr): Observable<Gefachdr> {
        return this._http.post<Gefachdr>(this._baseUrl + `gefachdr/${gefachdr.facId}/compute`, gefachdr);
    }

    public deleteBulk(ids: string[]): Observable<Gefachdr> {
        const options = {
            body: ids,
        };
        return this._http.delete<Gefachdr>(this._baseUrl + `gefachdr/bulk`, options);
    }

    public publish(facIds: string[]): Observable<void> {
        return this._http
            .post<void>(environment.backendURL + 'gefachdr/publish', facIds);
    }

    public sendByMail(facId: string): Observable<void> {
        return this._http
            .post<void>(environment.backendURL + `gefachdr/send-by-mail/${facId}`, null);
    }

    public download(facIds: string[]) {
        return this._http
            .post(environment.backendURL + 'gefachdr/download', facIds, {
                observe: 'response',
                responseType: 'blob'
            })
            .pipe(map((res: HttpResponse<Blob>) => {
                let blob = new Blob([res.body!], {type: res.headers.get('Content-Type') || ''});
                let fileUrl = URL.createObjectURL(blob);

                let link = document.createElement('a');
                link.href = fileUrl;

                let contentDisposition = res.headers.get('Content-Disposition');
                let filename = contentDisposition?.slice(contentDisposition?.indexOf('=') + 1);
                link.download = filename ?? '';

                link.click();
                URL.revokeObjectURL(link.href);
            }));
    }


    protected override GET<TResult>(params?: RestParams, ...path: string[]) {
        console.log('FactureService', 'GET')
        return this._http.get<TResult>(this.getRequestUrl(...path), {params});
    }
}
