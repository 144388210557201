/* eslint-disable no-console */
import {getEnvironment} from '../environment/environment';
import {LogLevel} from './logger-level';

export function getLogLevel(): number {
    return Number(localStorage.getItem('LOG_LEVEL')) || getEnvironment().logLevel;
}

/**
 * Whrite log to console
 *
 * @export
 * @param level
 * @param message
 * @param optional
 * @returns
 */
// eslint-disable-next-line complexity
export function writeLog(level: LogLevel, message?: string, ...optional: any[]): undefined {
    if (level < getLogLevel()) {
        return;
    }
    switch (level) {
        case LogLevel.Debug:
        case LogLevel.Info:
            console.log(message, ...optional);
            return;
        case LogLevel.Warning:
            console.warn(message, ...optional);
            return;
        case LogLevel.Error:
        case LogLevel.Fatal:
            console.error(message, ...optional);
    }
}

export function INFO(message?: string, ...optional: any[]): undefined {
    return writeLog(LogLevel.Info, message, ...optional);
}

export function DEBUG(message?: string, ...optional: any[]): undefined {
    return writeLog(LogLevel.Debug, message, ...optional);
}

export function WARNING(message?: string, ...optional: any[]): undefined {
    return writeLog(LogLevel.Warning, message, ...optional);
}

export function ERROR(message?: string, ...optional: any[]): undefined {
    return writeLog(LogLevel.Error, message, ...optional);
}

export function FATAL(message?: string, ...optional: any[]): undefined {
    return writeLog(LogLevel.Fatal, message, ...optional);
}
