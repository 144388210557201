import {IwColumn} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';


@IwRestEntity('ppsecteur')
export class Ppsecteur {
    @IwRestEntityId() @IwColumn({name: 'code', index: 0, width: 40}) public secId?: string;
    public mhlppMin?: number;
    public actif?: boolean;
    public cptRist?: string;
    public cptTxxx?: string;
    public cptProll?: string;
    public mhMin?: number;
    public cptTnuit?: string;
    public cptT200?: string;
    @IwColumn({name: 'charges', index: 5, width: 40}) public pccharges?: number;
    public cptT125?: string;
    @IwColumn({name: 'coeff', index: 4, width: 40}) public coeffMin?: number;
    @IwColumn({name: 'libelle', index: 1}) public secLib?: string;
    @IwColumn({name: 'fixe', index: 3, width: 40}) public cptFixe?: string;
    public cptT150?: string;
    public cptTequ?: string;
    public codecna?: string;
    public secData?: string;
    public cptPari?: string;
    public cptTempo?: string;
    public cptTpause?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public dateTrack?: string;
}
