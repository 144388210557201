import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FactureDetail} from '@app/sam-base/models/invoices/factureDetail';
import {Gepaiele} from '@app/sam-base/models/invoices/gepaiele';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class GepaieleService extends RestClient<Gepaiele> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getPaieles(facId: string): Observable<FactureDetail[]> {
        return this._http.get<FactureDetail[]>(environment.backendURL + `gepaiele/getPaieleWithDatePay/${facId}`);
    }
}
