import {Component, Type} from '@angular/core';
import {BaseColumnDetail} from '@app/sam-base/base/base-column-detail';
import {EsQueryStatement, getEntityMetadata} from '@app/sam-base/core/rest-api';
import {IwGridColumn} from '@app/sam-base/models';
import {Ppsuivi} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './entity-detail.component.html'
})
export class EntityDetailCommandFixeComponent<T> extends BaseColumnDetail<T> {
    public entity: Type<any> = Ppsuivi;

    public query: EsQueryStatement<T>[] = [];

    public noData = false;

    public columns: IwGridColumn<any>[] = [
        {
            index: 0,
            prop: 'dateCreat',
            type: 'date',
            name: 'date'
        },
        {
            index: 1,
            prop: 'daterappel',
            type: 'date',
            name: 'rappel'
        },
        {
            index: 2,
            prop: 'nomconseille',
            name: 'conseiller'
        },
        {
            index: 3,
            prop: 'nomemploye',
            name: 'employe'
        },
        {
            index: 4,
            prop: 'nomcliatt',
            name: 'contact'
        },
        {
            index: 5,
            prop: 'action'
        }];

    constructor() {
        super();
    }

    public async instaciate(row: T) {
        try {
            const meta = getEntityMetadata(row);
            const sta = EsQueryStatement.fromTerm({
                [meta.$pk]: meta.$getPk()
            }, 'filter');
            this.query = [sta];
        } catch {
        }
    }

    public getEntityName() {
        return 'ppclicdfs';
    }

    public onDataChanged(data?: T[]) {
        if (data) {
            this.noData = data.length === 0;
        }
    }
}
