import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppcon')
export class Ppcon {
    @IwRestEntityId() @IwColumn({
        index: 0,
        name: 'id',
        width: 80
    }) public conId?: string;
    public modData?: string;
    @IwColumn({
        index: 3,
        name: 'utilisateur'
    }) public samuserId?: string;
    public dtAnyModif?: string;
    public cpolitesse?: string;
    public natel?: string;
    public email?: string;
    public fonction?: string;
    public tel?: string;
    public modCnt?: number;
    public noGestion?: string;
    public initiales?: string;
    @IwColumn({
        index: 4,
        name: 'agence'
    }) public ageId?: string;
    // public actif?: boolean; // not used anymore
    public ijData?: string;
    @IwColumn({
        index: 1,
        name: 'nom'
    }) public nom?: string;
    public lexp2ij?: boolean;
    public conData?: string;
    @IwColumn({
        index: 2,
        name: 'prenom'
    }) public prenom?: string;
    public signfName?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public dateTrack?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public ppagence?: string;
    public emailSignature?: string;
    public signaturePreview?: string;
}
