import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppmissal')
export class Ppmissal {
    @IwRestEntityId() public ppmissalId?: string;
    public misId?: string;
    public salId?: string;
    public facId?: string;
    public sent2Fac?: boolean;
    public sent2Sal?: boolean;
    public facNo?: string;
}
