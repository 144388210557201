import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppekw')
export class Ppekw {
    @IwRestEntityId() public ekwId?: string;
    public userModif?: string;
    public empId?: string;
    public kw?: string;
    public userDelet?: string;
    public cliId?: string;
    public ctype?: string;
    public dateDelet?: string;
    public dateCreat?: string;
    public userCreat?: string;
    public dateModif?: string;
}
