import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {RestClient} from '../core';
import {CalculationRequest} from './calculation-request';
import {CalculationResponse, CalculationResponseError} from './calculation-response';

export class CalculationClient extends RestClient<any> {

    public calculateType<T, TKey extends Extract<keyof T, string>, TRes>(type: TKey, params: T[TKey]) {
        const req: CalculationRequest<TKey, T[TKey]> = {type, params};
        return this.calculate<TKey, T[TKey], TRes>(req);
    }

    /** Raw request */
    public calculate<TType extends string, TParams, TRes>(params: CalculationRequest<TType, TParams>) {
        return this.POST<CalculationResponse<TRes>>(params, {}, 'calculation/execute')
            .pipe(catchError(err => of(<CalculationResponseError>{
                errorMessage: err, success: false
            })));
    }
}
