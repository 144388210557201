import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saemi')
export class Saemi {
    @IwRestEntityId() @IwColumn() public emiId?: number;
    public empId?: string;
    public emiGenre?: string;
    public emiType?: string;
    public emaCode?: string;
    public emaReason?: string;
    public emaDate?: string;
    public salId?: string;
    public salIdCor?: string;
    public corType?: number;
    public cantonimp?: string;
    public cantonOld?: string;
    public categorie1?: string;
    public cat1Old?: string;
    public barc3?: string;
    public barc3Old?: string;
    public mntsalOld?: number;
    public mntdedOld?: number;
    public mntSal?: number;
    public mntDed?: number;
    public tauxOld?: number;
    public taux?: number;
    public gsId?: string;
    public mnt?: number;
    public texte?: string;
    public esparent?: string;
    public esparOld?: string;
    public esId?: string;
    public emiData?: string;
    public modecreat?: string;
    public baseLppY?: number;
    public dureeCotisationFrom?: string;
    public dureeCotisationTo?: string;
    public payAfterLeave?: boolean;
}
