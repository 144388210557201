import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';

const invDate = 'Invalid Date';

export const dateValue = (value: Date) => {
    let val = value;

    if (typeof val === 'string') {
        const date = new Date(val);
        if (date.toString() === invDate) {
            return '';
        }
        val = date;
    }

    if (val instanceof Date) {
        return IwDateHelper.dateFormat(val)
            .replace(/\//g, '-');
    }

    return '';
};

export const dateTimeValue = (value: Date) => {
    let val = value;

    if (typeof val === 'string') {
        const date = new Date(val);
        if (date.toString() === invDate) {
            return '';
        }
        val = date;
    }

    if (val instanceof Date) {
        return IwDateHelper.dateFormat(val)
            .replace(/\//g, '-') + ' ' + IwDateHelper.dateFormatFromString(val, 'HH:mm:ss');
    }

    return '';
};

export const dateDebutValue = (value: Date) => {
    let val = value;

    if (typeof val === 'string') {
        const date = new Date(val);
        if (date.toString() === invDate) {
            return val;
        }
        val = date;
    }

    if (val instanceof Date) {
        return IwDateHelper.dateFormat(val);
    }

    return '';
};
