import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {snakeCase} from 'lodash';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {ComboboxItem} from '../../models';

@Component({
    selector: 'iw-dropdown',
    templateUrl: './iw-dropdown.component.html',
    providers: [buildCustomValueProvider(IwDropdownComponent)]
})
export class IwDropdownComponent<T> extends BaseFormControl<T | T[keyof T]> {

    @Input() public options: ComboboxItem<T>[] = [];
    @Input() public labelWidth?: string;
    @Input() public warningMessage?: string;
    @Input() public hintMessage?: string;
    /** Property to set in value */
    @Input() public prop?: keyof T;
    @Input() public showClear = false;
    @Input() public translateOptionsLabel = true;
    @Output() public valueChange = new EventEmitter<T | T[keyof T] | undefined>();
    @Output() public press = new EventEmitter<MouseEvent>();
    @Output() public controlFocus = new EventEmitter<boolean>();
    @Output() public valueSelected = new EventEmitter<T>();
    @Input() public defaultOption?: T;

    constructor(public readonly _translateService: TranslateService, private elRef: ElementRef) {
        super();
    }

    /** Convert string of string[] to a ComboboxItem<string>[] */
    @Input()
    public set textOptions(v: string | string[] | undefined) {
        this.setTextOptions(v);
    }

    public get primeNgValue() {
        if (!this.prop) {
            return this.options?.find(o => o.value === this._value);
        } else {
            const prop = this.prop;
            return this.options?.find(o => o.value[prop] === this._value);
        }
    }

    public set primeNgValue(v: ComboboxItem<T> | undefined) {
        if (!this.prop) {
            this.value = v && v.value;
        } else {
            this.value = v && v.value && v.value[this.prop];
        }
    }

    public get styleValue() {
        return {
            width: this._width
        };
    }

    private _width?: string;

    public get width() {
        return this._width;
    }

    @Input()
    public set width(v: undefined | string | number) {
        this._width = typeof v === 'number' ? v + 'px' : v;
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public onClick(event: MouseEvent) {
        this.press.emit(event);
    }

    private setTextOptions(v: string | string[] | undefined) {
        if (!v) {
            return;
        }

        const txtOptions = typeof v === 'string' ? v.split(',') : v;

        const op: ComboboxItem<T>[] = [];
        for (const txt of txtOptions.filter(z => !!z)) {
            op.push({
                name: this.translateOptionsLabel ? this._translateService.instant('combo_' + snakeCase(txt)) : txt,
                value: <any>txt
            });
        }
        this.options = op;
    }
}
