import {Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Ppcde, Ppcdf} from '@app/sam-base/models/placement';
import {environment} from '@root/environments/environment';

@Injectable()
export class CommandeService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    /**
     * Check if commande is satisfied
     */
    public isCommandeSatisfied(misId: string): Observable<boolean> {
        return this.GET<boolean>(undefined, `/commande/satisfied/${misId}`);
    }

    /**
     * Check if commande is satisfied
     */
    public isCommandeClosed(cdeId: string): Observable<boolean> {
        return this.GET<boolean>(undefined, `/commande/closed/${cdeId}`);
    }

    /**
     * Close commande
     */
    public closeCommande(cdeId: string): Observable<Ppcde> {
        return this._http.put<Ppcde>(environment.backendURL + 'commande/close/' + cdeId, undefined);
    }

    /**
     * Copy a commande
     */
    public copyCommande(cdeId: string): Observable<Ppcde> {
        return this.POST<Ppcde>(undefined, undefined, `/commande/copy/${cdeId}`);
    }

    /**
     * Copy a commande
     */
    public copyCommandeCdf(cdfId: string): Observable<Ppcdf> {
        return this.POST<Ppcdf>(undefined, undefined, `/commande/copy/${cdfId}`);
    }
}
