import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {Pppar} from '@app/sam-base/models/placement';
import {environment} from '@root/environments/environment';
import {Gepar} from '@sam-base/models/invoices';
import {Sapar} from '@sam-base/models/salary';
import {Sagen} from '@sam-base/models/salary/sagen';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

/**
 * Service to get data from PPPAR
 * Its loaded at the placement module start time
 * TODO: this should be handle by the STORE
 */
@Injectable()
export class ParametersService {
    protected readonly _baseUrl: string;
    private _dataSapar?: Sapar;
    private _restSapar: RestEntityClient<Sapar>;
    private _currentSapar?: Sapar;

    // fixme ULTIMATE operations has the same service parameters -> I moved it to a service called sapar-paramters.service.ts but we should find a way to merge those

    private _dataPpar?: Pppar;
    private _restPppar: RestEntityClient<Pppar>;

    constructor(private readonly restService: RestApiService, private readonly _http: HttpClient,) {
        this._restPppar = this.restService.getEntityClient(Pppar);
        this._restSapar = restService.getEntityClient(Sapar);
        this._baseUrl = environment.backendURL;
    }

    public get activeYear() {
        return this._currentSapar?.annee ?? '';
    }

    /**
     * Get a property and convert to string if needed
     * If string is empty or undefined, [defaultValue] is returned
     *
     * @param k
     * @param [defaultValue='']
     * @param [update=true]
     * @returns
     * @memberof ParametersService
     */
    public getString(k: keyof Pppar, defaultValue = '', update = true): Observable<string> {
        const mapToString = (e: any | undefined) => (e ? ('' + e) : '') || defaultValue;

        return this.getParameterValue(k, update)
            .pipe(map(mapToString));
    }

    /**
     * Get a property and convert to string if needed
     * If string is empty or undefined, [defaultValue] is returned
     *
     * @param k
     * @param [defaultValue='']
     * @param [update=true]
     * @returns
     * @memberof ParametersService
     */
    public getStringSapar(k: keyof Sapar, defaultValue = '', update = true): Observable<string> {
        const mapToString = (e: any | undefined) => (e ? '' + e : '') || defaultValue;

        return this.getParameterValueSapar(k, update)
            .pipe(map(mapToString));
    }


    /**
     * Get a value from PPPAR
     *
     * @param key key to retrieve
     * @param [update=true] If true, won't load from back, just use from memory
     * @returns
     * @memberof ParametersService
     */
    public getParameterValue(key: keyof Pppar, update = true): Observable<Pppar[keyof Pppar] | undefined> {
        if (this._dataPpar && !update) {
            return of(this._dataPpar[key]);
        }

        return this.getParameter()
            .pipe(map(e => e ? e[key] : undefined));
    }

    /**
     * Get a value from SAPAR
     *
     * @param key key to retrieve
     * @param [update=true] If true, won't load from back, just use from memory
     * @returns
     * @memberof ParametersService
     */
    public getParameterValueSapar(key: keyof Sapar, update = true): Observable<Sapar[keyof Sapar] | undefined> {
        if (this._dataSapar && !update) {
            return of(this._dataSapar[key]);
        }

        return this.getCurrentSapar()
            .pipe(map((e) => (e ? e[key] : undefined)));
    }

    /**
     * Load PPPAR entity, returns success status
     *
     * @returns
     * @memberof ParametersService
     */
    public InitializeParameters(): Observable<boolean[]> {
        return forkJoin([
            this.getParameter().pipe(map(e => !!e)),
            this.getCurrentSapar().pipe(map(e => !!e))]);
    }

    public getCurrentSapar(forceLoad = false): Observable<Sapar> {
        if (this._currentSapar && !forceLoad) {
            return of(this._currentSapar);
        }

        return this._http.get<Sapar>(`${this._baseUrl}sapar/current`)
            .pipe(map((sapar) => {
                this._currentSapar = sapar;
                return sapar;
            }));
    }

    public getGepar(): Observable<Gepar> {
        this._http.get<Gepar>(`${this._baseUrl}gepar`);
        return this.restService.getEntityClient(Gepar)
            .getRefData().pipe(map(geparTab => geparTab[0]));
    }

    public getDefaultParameter(printType: string): Observable<string> {
        return this._http.get<string>(`${this._baseUrl}template/default/${printType}`);
    }

    /**
     * Load PPPAR, and assign it to _data
     *
     * @private
     * @returns
     * @memberof ParametersService
     */
    public getParameter(): Observable<Pppar | undefined> {
        return this._restPppar.getById('0')
            .pipe(map(e => {
                this._dataPpar = e;
                return e;
            }), catchError(err => of(undefined)));
    }

    public getSaparDataText<T>(columnName: string): Observable<T> {
        return this.getCurrentSapar()
            .pipe(switchMap(sapar => this._http
                .get<T>(environment.backendURL + `datatexts/sapar/${sapar.annee}/${columnName}`)));
    }


    /**
     * Get data texts from a pppar column
     *
     * @param columnName name of the column to be parsed
     */
    public getDataText<T>(columnName: string): Observable<T> {
        return (this._http
            .get<T>(environment.backendURL + `datatexts/pppar/0/${columnName}`));
    }

    public saveData(columnName: string, data: any) {
        return this._http.post(environment.backendURL + `datatexts/pppar/0/${columnName}`, data, {responseType: 'text'});
    }

    /**
     * Get memo field from gedata
     *
     * @param columnName name of the column to be parsed
     */
    public getMemoField(columnName: string): Observable<string> {
        return this._http.get(environment.backendURL + `gepar/memo-field/${columnName}`, {responseType: 'text'});
    }

    public getActiveSagenCurrentYear(): Observable<Sagen[]> {
        return this._http
            .get<Sagen[]>(environment.backendURL + `sagen/active`);
    }

    public getSagenCurrentYear(): Observable<Sagen[]> {
        return this._http
            .get<Sagen[]>(environment.backendURL + `sagen/current`);
    }
}
