import {Type} from '@angular/core';

import {EntityError} from '../../../models';
import {GridProfile} from '../../grid-profile/models';

/** Form edit status */
export type FormEditMode = 'read' | 'edit' | 'waiting' | 'waitLoading';

export interface GlobalFormStore {
    [uuid: string]: GlobalFormState<any> | undefined;
}

/** The global state for each open dialog */
export interface GlobalFormState<T> {
    /** Unique universal identifier for this form */
    uuid: string;
    /** Use navigation to load entities */
    useNavigation: boolean;
    /** Edit state */
    editMode: FormEditMode;
    /** If true, block editing and show loading screen */
    loading: boolean;
    /** Size of form width, in PX */
    width: number;
    /** Defines if allows edit action */
    readonly: boolean;
    /** Mark if entity is new */
    isNew: boolean;
    /** If true, form is closed after save ends successfully */
    destroyOnSave: boolean;

    /** Check if user can save */
    isValid: boolean;

    /** RestEntity construtor */
    entityType: Type<T>;
    /** Current entity Id */
    entityId: string | number;
    /** Entity data */
    entity?: T;
    /** Mark if form has changes */
    dirty: boolean;
    /** Collection of form errors */
    errors: EntityError<T>;
    /** Use PATCH insead of UPDATE when saving changes */
    patch: boolean;

    /** Profile used to load the entity */
    navProfile?: GridProfile<T>;
    /** List of Id order by profile */
    navValues: Array<string | number>;

    /** Title to show when using a dialog */
    diagTitle: string;
    /** Set if dialog blocks other dialogs */
    diagModal: boolean;
    /** If true, show side menu */
    diagShowSideMenu: boolean;
    /** If true, show dialog status */
    diagShowStatus: boolean;
    /** Side menu state */
    diagSideMenu: GlobalFormSideMenuState<T>;

    /** Used to overrite load of entity URL */
    customEntityURL?: string;

    /** Used to mark store as updated */
    __init__?: boolean;
    /** Used to mark store in save state */
    __save__?: boolean;


}

export interface GlobalFormSideMenuState<T> {
    /** Component to load in the side menu */
    component?: boolean;
    /** Show button to print */
    showPrint?: boolean;
    /** Show button to load list */
    showList?: boolean;
    /** Show button to set edit mode */
    showEdit?: boolean;
    /** Show button to cancel action */
    showCancel?: boolean;
    /** Show button to close */
    showClose?: boolean;
    /** Show delete action */
    showDelete?: boolean;
}

export enum GlobalFormError {
    UUID = 'global_form_error_invalid_uuid',
    Profile = 'global_form_error_invalid_profile',
    NavigationFail = 'global_form_error_navigate_fail',
    NavigationFailQuery = 'global_form_error_navigate_query',
    NavigationDisabled = 'global_form_navigation_disabled',
    NotReadState = 'global_form_not_read_state',
    NotWriteState = 'global_form_not_write_state',
}

/** Return a GlobalFormState for a TYPE */
export function buildDefaultState<T>(type: Type<T>): GlobalFormState<T> {
    return {
        isValid: false,
        diagModal: false,
        diagShowSideMenu: false,
        diagShowStatus: false,
        diagSideMenu: {},
        diagTitle: '',
        dirty: false,
        editMode: 'edit',
        entityId: '',
        entityType: type,
        errors: {},
        isNew: false,
        loading: true,
        navValues: [],
        patch: false,
        readonly: false,
        useNavigation: false,
        uuid: '',
        width: 500,
        destroyOnSave: false
    };
}
