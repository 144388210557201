/* eslint-disable max-classes-per-file */

import {Action} from '@ngrx/store';

import {LicenseOptions} from '../models/license.state';

export const LICENSE_OPTIONS_LOAD = 'license options load list';
export const LICENSE_OPTIONS_LOAD_SUCCESS = 'license options load list success';
export const LICENSE_OPTIONS_LOAD_FAIL = 'license options load list fail';

export class LoadLicenseOptions implements Action {
    public readonly type = LICENSE_OPTIONS_LOAD;
}

export class LoadLicenseOptionsFail implements Action {
    public readonly type = LICENSE_OPTIONS_LOAD_FAIL;

    constructor(public readonly error: any) {
    }
}

export class LoadLicenseOptionsSuccess implements Action {
    public readonly type = LICENSE_OPTIONS_LOAD_SUCCESS;

    constructor(public readonly options: LicenseOptions) {
    }
}

export type LicenseActions = LoadLicenseOptions | LoadLicenseOptionsFail | LoadLicenseOptionsSuccess;
