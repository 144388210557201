import {EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {ListenerSubscriber} from './dom-listener-subscriber';

/** Handler for a dom event */
export class DomEvent<T> {
    /** Event listener for the DOM event */
    private _event = new EventEmitter<T>();
    /** True, if the event has a listener */
    private _registered = false;

    constructor(public readonly type: string, private readonly _listener: ListenerSubscriber<T>) {
    }

    /** True, if the event has a listener */
    public get isRegistered() {
        return this._registered;
    }

    public static create<T>(type: string, listenerBuilder: ListenerSubscriber<T>) {
        return new DomEvent<T>(type, listenerBuilder);
    }

    /** Create event listener from window */
    public static fromWindow<T>(type: keyof WindowEventMap) {
        return this.create<T>(type, (eventType, listener) => {
            window.addEventListener(eventType, (e: any) => listener(e));
        });
    }

    public subscribe(next: (value: T) => void, dueTime: number = 300): Subscription {
        return this.get(dueTime)
            .subscribe(next);
    }

    public get(dueTime: number = 300) {
        return this.getListener()
            .pipe(debounceTime(dueTime));
    }

    private getListener() {
        if (!this._registered) {
            this._listener(this.type, (e) => this._event.emit(e));
            this._registered = true;
        }

        return this._event;
    }

}
