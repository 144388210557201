import {Type} from '@angular/core';
import {isArray} from 'lodash';
import {DynamicComponent} from '../models';

export function isNullOrUndefined(input: unknown): input is null | undefined {
    // eslint-disable-next-line no-null/no-null
    return typeof input === 'undefined' || input === null;
}

export function isObject(input: unknown): input is Object {
    return !isNullOrUndefined(input) || typeof input !== 'object';
}

/** Checks if input is a number not NaN */
export function isNumber(input: unknown): input is number {
    return typeof input === 'number' && !isNaN(input);
}

/** Check if input is DynamicComponent */
export function isDynamicComponent(input: unknown): input is DynamicComponent {
    return typeof input === 'object'
        // eslint-disable-next-line no-null/no-null
        && input !== null && typeof (<any>input).type !== 'undefined';
}

export function isStringArray(input: unknown): input is string[] {
    return isArray(input) && typeof input[0] === 'string';
}

export function isConstructor<T>(input: unknown): input is Type<T>;
export function isConstructor(input: unknown): input is Type<any> {
    try {
        Reflect.construct(String, [], input as Function);
    } catch {
        return false;
    }

    return true;
}
