import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';
import {
    IwColumn
} from '@sam-base/core/column/decorators/iw-column.decorator';

@IwRestEntity('ppcdf')
export class Ppcdf {
    @IwRestEntityId() @IwColumn({type: 'string'}) public cdfId?: string;
    public cliadrId?: string;
    public aptId?: string;
    public cliId?: string;
    public webFin?: string;
    public ljobup?: boolean;
    public lorpeurdis?: boolean;
    public jobsbra?: string;
    public lorppubdis?: boolean;
    public userId?: string;
    public jupsubcat?: string;
    public jupcanton?: string;
    public apt7?: boolean;
    public orpEnd?: string;
    public fax?: string;
    public jrResp?: string;
    public userCreat?: string;
    public datefactur?: string;
    public jobstype?: string;
    public tel?: string;
    public lexp2ij?: boolean;
    public natel?: string;
    public typehortxt?: string;
    public service?: string;
    public jupemploy?: string;
    public nofacture?: string;
    public langue1?: string;
    public fermee?: boolean;
    public lan2Parle?: number;
    public modData?: string;
    public orpData?: string;
    public keywords?: string;
    public foncdet?: string;
    public attn?: string;
    public orprsncan?: string;
    public lan3Ecrit?: number;
    public orpquaTxt?: string;
    public swissonly?: boolean;
    public fin?: string;
    public apt3?: boolean;
    public jrResp2?: string;
    public jupspecrit?: string;
    public apt6?: boolean;
    public debut?: string;
    public orpstatus?: string;
    public weblastexp?: string;
    public plfLieu?: string;
    public modecreat?: string;
    public langue3?: string;
    public lan4Ecrit?: number;
    public lan4Parle?: number;
    public qualif?: string;
    public qualifDescription?: string;
    public dipl5?: boolean;
    public ageMax?: number;
    public ljobs?: boolean;
    public langue4?: string;
    public orpfoncdet?: string;
    public refinterne?: string;
    public salaire?: string;
    public xmlData?: string;
    public typehor?: number;
    public region?: string;
    public resp?: string;
    public dipl1?: boolean;
    public apt1?: boolean;
    public cliId2?: string;
    public jobroomId?: string;
    public orpquaId?: string;
    public datedebut?: string;
    public ageId?: string;
    public apt5?: boolean;
    public ljobtic?: boolean;
    public userDelet?: string;
    public refclient?: string;
    public plfId?: string;
    public websendhtm?: boolean;
    public permis?: string;
    public jupbra?: string;
    public lan1Ecrit?: number;
    public motifSat?: string;
    public diplbin?: number;
    public relances?: string;
    public secId?: string;
    public primId?: number;
    public noGestion?: string;
    public lweb?: boolean;
    public plfNpa?: string;
    public webData?: string;
    public dipl3?: boolean;
    public lan2Ecrit?: number;
    public wkldMin?: number;
    public lasap?: boolean;
    public wkldMax?: number;
    public webfrstexp?: string;
    public typecdf?: number;
    public orpfrstexp?: string;
    public dipl2?: boolean;
    public isexe?: number;
    public note?: string;
    public jobspos?: string;
    public orpNote?: string;
    public ijData?: string;
    public jobsprof?: string;
    public email?: string;
    public dipl4?: boolean;
    public apt10?: boolean;
    public jobsreg?: string;
    public usertag?: string;
    public jobticcat?: string;
    public orplastexp?: string;
    public webFdmode?: number;
    public apt8?: boolean;
    public refclitofa?: boolean;
    public dateModif?: string;
    public apt9?: boolean;
    public cdfstatus?: number;
    public cliattId?: string;
    public lan1Lu?: number;
    public userModif?: string;
    public conId?: string;
    public dateCreat?: string;
    public lan1Parle?: number;
    public fonction?: string;
    public status?: number;
    public lan3Parle?: number;
    public dtAnyModif?: string;
    public lan4Lu?: number;
    public dateDelet?: string;
    public modCnt?: number;
    public orpFin?: string;
    public lorp?: boolean;
    public ageMin?: number;
    public expmin?: string;
    public empfacture?: string;
    public lan2Lu?: number;
    public apt2?: boolean;
    public orpHis?: string;
    public apt4?: boolean;
    public lan3Lu?: number;
    public satisfaite?: boolean;
    public aptbin?: number;
    public cdfData?: string;
    public orpdtcan?: string;
    public orpDeb?: string;
    public jrStatus?: string;
    public langue2?: string;
    public dateCdf?: string;
    public websendrtf?: boolean;

    public cdffrx?: string;
    public cddtPlace?: string;
    public needSummary?: string;
    public profession?: string;

}

export enum TypeEmploi {
    stable = 1, tryHire = 2
}
