import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppmis')
export class Ppmis {
    @IwColumn({name: 'no'}) @IwRestEntityId() public misId?: string;
    public coeffMin?: number;
    public hpars?: string;
    public geoLon?: string;
    public mnteqcli2?: number;
    public ccreceived?: string;
    public refinterne?: string;
    public misNpa?: string;
    @IwColumn({name: 'coeff'}) public coeff?: number;
    public misIdOri?: string;
    public pointtype?: string;
    public horairetxt?: string;
    public lieu1?: string;
    public natel?: string;
    public quaId?: string;
    public hparm?: string;
    public horaire?: string;
    @IwColumn({name: 'motifFin'}) public finmotif?: string;
    public facadrId?: string;
    public paucalcmod?: number;
    public jparm?: number;
    public freezcha?: boolean;
    public dureetype?: string;
    public modefactu?: number;
    public mntAvspgn?: number;
    public evreceived?: string;
    public nxtmotif?: string;
    public misIdNxt?: string;
    public shsupmode?: number;
    public misData?: string;
    public lpointeuse?: string;
    public avemottext?: string;
    public ijRapnote?: string;
    public note?: string;
    public datealert?: string;
    public lieu?: string;
    public cmfrx?: string;
    public ccfrxpf?: string;
    public cmfrxpf?: string;
    public clitarifmd?: number;
    public nogestion?: string;
    public clitariffc?: number;
    @IwColumn({
        name: 'debut',
        type: 'date'
    }) public datedebut?: string;
    public tauxeq?: number;
    public primId?: number;
    public mnteqemp?: number;
    public hparj?: string;
    public clitarmd6f?: number;
    public lieu2?: string;
    public mntVac?: number;
    public mntnuicli2?: number;
    public ljfh?: string;
    public txJf?: number;
    public attn?: string;
    public mntFrais2?: number;
    public mntJf?: number;
    public frais?: string;
    public noteemp?: string;
    public dateDelet?: string;
    public misrating?: string;
    @IwColumn({name: 'cct'}) public cctId?: string;
    public cmreceived?: string;
    public clitarh125?: number;
    public hpausaimod?: number;
    public lprovVac?: string;
    public heurepause?: boolean;
    public resp?: string;
    public pointNo?: string;
    public tel?: string;
    @IwColumn({name: 'status'}) public misstatus?: number;
    public vref2fac?: string;
    public pointMode?: number;
    public ijData?: string;
    public lvac?: string;
    public shtxmode?: number;
    public modecreat?: string;
    public cmemail?: string;
    public firstday?: string;
    @IwColumn({name: 'tag'}) public usertag?: string;
    public mntLpp?: number;
    public avemotif?: string;
    public lieu3?: string;
    public faclintxt?: string;
    public secId?: string;
    public userId?: string;
    public concerne?: string;
    public cliadrId?: string;
    public clitarifra?: number;
    public ijRapSm?: string;
    @IwColumn({name: 'finPrevue'}) public datefinp?: string;
    public lastday?: string;
    public cliattId?: string;
    public evnbofprt?: number;
    public cctRa?: boolean;
    public shcalcmode?: number;
    public lexpfac2ij?: string;
    public lprov13?: string;
    public empId?: string;
    public chantier2?: string;
    @IwColumn({name: 'salGlobal'}) public salGlobal?: number;
    public salBase?: number;
    @IwColumn({type: 'date'}) public dateModif?: string;
    public icm2Ijmod?: number;
    public pointRfid?: string;
    public cliId?: string;
    public cctFc?: boolean;
    public mntPause?: number;
    public service?: string;
    public mntnuitcli?: number;
    public cctEx?: boolean;
    public nbrapports?: number;
    @IwColumn({name: 'qualification'}) public qualif?: string;
    public l13?: string;
    public ccfrx?: string;
    public lprovNuit?: string;
    public cctNote?: string;
    public chargesdet?: string;
    public heueqwtx?: string;
    @IwColumn({type: 'date'}) public dateCreat?: string;
    @IwColumn({name: 'chantier'}) public chantier?: string;
    @IwColumn({name: 'tarif'}) public clitarif?: number;
    public userModif?: string;
    public misLieu?: string;
    public lprovJf?: string;
    public datecontra?: string;
    public modCnt?: number;
    public tx13?: number;
    public mntCct?: number;
    public horAmEnd?: string;
    public mnteqcli?: number;
    public modData?: string;
    public workdays?: string;
    public codecna?: string;
    public iscoCatCode?: string;
    public nbfactures?: number;
    public grtId?: string;
    public txtheueq?: string;
    public cantonis?: string;
    public pointData?: string;
    public cmnbofprt?: number;
    @IwColumn({name: 'salTotal'}) public salTotal?: number;
    public clitarifex?: number;
    public cdeId?: string;
    public horAmBeg?: string;
    public heurequipe?: string;
    public txnuit?: number;
    public notecli?: string;
    public shbaseglob?: number;
    public quagrpId?: string;
    public heurenuit?: string;
    public codepgm2?: string;
    public geoLat?: string;
    public alertMotif?: string;
    public clitarh200?: number;
    public ccemail?: string;
    public ageId?: string;
    public missalmode?: number;
    public userDelet?: string;
    @IwColumn({name: 'cons'}) public conId2?: string;
    @IwColumn({
        name: 'fin',
        type: 'date'
    }) public datefin?: string;
    public clitarif2?: number;
    @IwColumn({name: 'duree'}) public duree?: string;
    public forcecha?: string;
    public manycha?: boolean;
    @IwColumn({name: 'partage'}) public conid2Pc?: number;
    public icc2Ijmod?: number;
    public horPmEnd?: string;
    public codepgm3?: string;
    public refclient?: string;
    public misfacmode?: number;
    public mntJfz?: number;
    public horairetyp?: number;
    public avenant?: number;
    public conId?: string;
    public txVac?: number;
    public clichaId?: string;
    public cctData?: string;
    public xmlData?: string;
    public hpauseauto?: boolean;
    public heuredebut?: string;
    public genretrav?: string;
    public horPmBeg?: string;
    public mntAvsetc?: number;
    public mntpauscli?: number;
    public nxtmottext?: string;
    public ccnbofprt?: number;
    public nref2fac?: string;
    public dtanymodif?: string;
    public forcelpp?: string;
    public workdates?: string;
    public lieurdv?: string;
    public clitarh150?: number;
    public mistype?: string;
    public txPause?: number;
    public pxrevhor?: number;
    public factbyrap?: string;
    public mntnuitemp?: number;
    public cliId2?: string;
    public jpars?: number;
    public mntFrais1?: number;
    public refclient2?: string;
    @IwColumn({name: 'ij'}) public lexpmis2Ij?: string;
    public userCreat?: string;
    public heusaimode?: string;
    public codepgm?: string;
    public mnt13?: number;
    public chaids?: string;
    public chantiers?: string;
    public nom?: string;
    public remarques?: string;
    public empdedccttxt?: string;
    public empdedtxt?: string;
    public totheu?: string;
    public totrap?: string;
    public totjou?: string;
    public totsem?: string;
    public nbjmis?: string;
    public notes?: string;
    public suiId?: string;
    public cctMinValue?: number;
    public tempdataText?: string;
    public tempdataObject?: string;

    public needSummary?: string;
    public creating?: boolean;
    public jfNonPayes?: boolean;
    public avs?: number;
    public pgm?: number;
    public laa?: number;
    public alf?: number;
    public lpp?: number;
    public cu1?: number;

    // This is not sync with back end, just to handle the calculation on the front end side
    public isCalculating?: string;

    public iscoDescriptionFr?: string;
}
