import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('smtpconfig', '', 'common')
export class SmtpConfig {
    @IwRestEntityId()
    id?: number
    name?: string
    smtpServer?: string
    smtpUser?: string
    smtpPort?: number
    authLogin?: string
    authPassword?: string
    sendFrom?: string
    sendFromName?: string
    mailSignFile?: string
}
