import {Type} from '@angular/core';

import {getEntityMetadata} from '../../rest-api';
import * as actions from '../actions/entity-select.actions';
import {EntitySelectState, EntitySelectStore} from '../models/entity-select.state';

// eslint-disable-next-line complexity
export function reducer<T>(state: EntitySelectStore<T> = {}, action: actions.EntitySelectAction<T>): EntitySelectStore<T> {

    switch (action.type) {
        case actions.ENTITY_SELECT_INIT: {
            const $entity = getEntityMetadata(action.state.entity).$entity;
            return {
                ...state, [$entity]: {...action.state}
            };
        }

        case actions.ENTITY_SELECT_CANCEL: {
            return applyState(state, action.entityType, s => ({
                ...s, completed: true, selected: []
            }));
        }

        case actions.ENTITY_SELECT_SUCCESS: {
            return applyState(state, action.entityType, s => ({
                ...s, completed: true, selected: action.selected
            }));
        }

        case actions.ENTITY_SELECT_COMPLETE: {
            const $entity = getEntityMetadata(action.entityType).$entity;
            const newState = {...state};
            delete newState[$entity];
            return newState;
        }
    }

    return state;
}

/** Check if type is valid and apply state change */
function applyState<T>(store: EntitySelectStore<T>, type: Type<T>, reduce: (s: EntitySelectState<T>) => EntitySelectState<T>): EntitySelectStore<T> {
    let $entity = 'unkown';

    /** Grabe entity name */
    try {
        $entity = getEntityMetadata(type).$entity;
    } catch {
        return {...store};
    }

    const oldState = store[$entity];
    if (!oldState) {
        return {...store};
    }

    const newState = reduce(oldState);
    return {
        ...store, [$entity]: {...newState}
    };
}
