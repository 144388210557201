export interface EnvironmentModel {
    backendURL: string;
    production: boolean;
    logLevel: number;
    esURL: string;
}

const environment: EnvironmentModel = {
    backendURL: '', production: false, logLevel: 0, esURL: ''
};

export function getEnvironment() {
    return environment;
}

function isKeyofEnvModel(k: string): k is (keyof EnvironmentModel) {
    return Object.keys(environment)
        .indexOf(k) !== -1;
}

/**
 * Set environment configuration
 *
 * @export
 * @param env
 */
export function setEnvironment(env: Partial<EnvironmentModel>) {
    for (const key of Object.keys(env)) {
        if (isKeyofEnvModel(key)) {
            environment[key] = env[key] as never;
        }
    }
}

