import * as searchGridActions from '../actions/search-grid.actions';
import {SearchGridGlobalState} from '../models';

const initialState: SearchGridGlobalState = {
    history: [],
    profiles: {}
};

// eslint-disable-next-line complexity
export function reducer(state = initialState, action: searchGridActions.SearchGridActions): SearchGridGlobalState {

    switch (action.type) {
        case searchGridActions.SEARCH_GRID_INIT: {
            const filter = {
                ...(action.filter || {}), ...(action.profile && action.profile.columns.filter || {})
            };
            const wildcards = {
                ...(action.profile && action.profile.columns.wildcard || {})
            };

            return <SearchGridGlobalState>{
                ...state,
                current: action.stateId,
                profiles: {
                    ...state.profiles,
                    [action.stateId]: {
                        type: action.entityType,
                        profile: action.profile,
                        sourceFormUuid: action.sourceFormUuid,
                        navExtras: action.extras || {},
                        filter,
                        wildcards
                    }
                }
            };
        }

        case searchGridActions.SEARCH_GRID_SET_PROFILE: {
            const profile = state.profiles[action.stateId];

            return <SearchGridGlobalState>{
                ...state,
                profiles: {
                    ...state.profiles,
                    [action.stateId]: {
                        ...profile,
                        profile: action.profile
                    }
                }
            };
        }

    }

    return state;
}
