/* eslint-disable max-classes-per-file */

import {Type} from '@angular/core';
import {Action} from '@ngrx/store';

import {GridProfile} from '../../grid-profile/models';

export const NAVIGATION_INIT = 'navigation init';
export const NAVIGATION_INIT_FAIL = 'navigation init FAIL';
export const NAVIGATION_INIT_SUCCESS = 'navigation init SUCCESS';

export const NAVIGATION_NEXT = 'navigation move next';
export const NAVIGATION_PREV = 'navigation move prev';
export const NAVIGATION_FIRST = 'navigation move first';
export const NAVIGATION_LAST = 'navigation move last';

export const NAVIGATION_GOTO_ID = 'navigation jump to entity id';
export const NAVIGATION_GOTO_ID_FAIL = 'navigation jump to entity id fail';
export const NAVIGATION_GOTO_POSITION = 'navigation jump to position';

export const NAVIGATION_FAIL = 'navigation fail';

/** Init a navigation store */
export class NavigationInit<T> implements Action {
    public readonly type = NAVIGATION_INIT;

    constructor(public readonly uuid: string, public readonly entity: Type<T>, public readonly profile: GridProfile<T>, public readonly startFromId?: string | number) {
    }
}

export class NavigationInitSuccess<T> implements Action {
    public readonly type = NAVIGATION_INIT_SUCCESS;

    constructor(public readonly uuid: string, public readonly entity: Type<T>, public readonly profile: GridProfile<T>, public readonly position: number, public readonly values: Array<string | number>) {
    }
}

export class NavigationInitFail implements Action {
    public readonly type = NAVIGATION_INIT_FAIL;

    constructor(public readonly error: string) {
    }
}

export class NavigationNext implements Action {
    public readonly type = NAVIGATION_NEXT;

    constructor(public readonly uuid: string) {
    }
}

export class NavigationPrev implements Action {
    public readonly type = NAVIGATION_PREV;

    constructor(public readonly uuid: string) {
    }
}

export class NavigationFirst implements Action {
    public readonly type = NAVIGATION_FIRST;

    constructor(public readonly uuid: string) {
    }
}

export class NavigationLast implements Action {
    public readonly type = NAVIGATION_LAST;

    constructor(public readonly uuid: string) {
    }
}

export class NavigationGoToEntity implements Action {
    public readonly type = NAVIGATION_GOTO_ID;

    constructor(public readonly uuid: string, public readonly entityId: string | number) {
    }
}

export class NavigationGoToEntityFail implements Action {
    public readonly type = NAVIGATION_GOTO_ID_FAIL;

    constructor(public readonly uuid: string, public readonly entityId: string | number) {
    }
}

export class NavigationGoToPostion implements Action {
    public readonly type = NAVIGATION_GOTO_POSITION;

    constructor(public readonly uuid: string, public readonly position: number) {
    }
}

export class NavigationFail implements Action {
    public readonly type = NAVIGATION_FAIL;

    constructor(public readonly error: string) {
    }
}

export type NavigationActions =
    | NavigationFail
    | NavigationInit<any>
    | NavigationInitFail
    | NavigationInitSuccess<any>
    | NavigationNext
    | NavigationPrev
    | NavigationFirst
    | NavigationLast
    | NavigationGoToEntity
    | NavigationGoToEntityFail
    | NavigationGoToPostion;
