import {IwGridColumn} from '../../../models/components/grid-column.model';

/** Extract params required to store */
export function extractGridParams<T>(c: IwGridColumn<T>): IwGridColumn<T> {
    return {
        prop: c.prop,
        index: c.index,
        filterQuery: c.filterQuery,
        filterQuery2: c.filterQuery2,
        statusQuery: c.statusQuery,
        valueFormat: c.valueFormat,
        filterOperator: c.filterOperator,
        type: c.type
    };
}

/** Extract the visible columns and order them by index */
export function arrayToObject<T>(columns: IwGridColumn<T>[]): ({ [key: number]: IwGridColumn<T> }) {
    const result: { [key: number]: IwGridColumn<T> } = {};

    let count = 0;
    for (const c of columns) {
        if (c.hidden) {
            continue;
        }
        let index = c.index || count;
        while (result[index]) {
            index++;
        }
        result[index] = extractGridParams(c);
        count = index + 1;
    }

    return result;
}


/** Apply configuration to a existing array of columns */
export function mergeColumnsObject<T>(columns: IwGridColumn<T>[], configs: {
    [key: number]: IwGridColumn<T>
}): IwGridColumn<T>[] {
    for (const col of columns) {
        const data = Object.values(configs)
            .find(c => c.prop === col.prop);
        if (data) {
            col.filterOperator = data.filterOperator;
            col.filterQuery = data.filterQuery;
            col.filterQuery2 = data.filterQuery2;
            col.statusQuery = data.statusQuery;
            col.index = data.index;
            col.type = data.type;
            col.hidden = false;
        } else {
            col.hidden = true;
        }
    }

    return columns;
}
