import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pppoint4')
export class Pppoint4 {
    @IwRestEntityId() public pt4Id?: string;
    public misId?: string;
    public pointNo?: string;
    public userCreat?: string;
    public userModif?: string;
    public userDelet?: string;
    public rfid?: string;
    public dateDelet?: string;
    public dateModif?: string;
    public rapId?: string;
    public modecreat?: string;
    public dateCreat?: string;
    public ptstatus?: string;
    public ptficId?: string;
    public dt?: string;
}
