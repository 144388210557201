import {MenuItem} from '../../../models/components/menu-item.model';
import {initObjectMenuItemMetadata, setMenuItemMetadata} from '../helpers';

/**
 * Define a property context items
 *
 * @param items items to show on context menu
 */
export function IwContextMenuProperty(...items: MenuItem[]): PropertyDecorator {
    return (target: Object, name: string | symbol) => {
        initObjectMenuItemMetadata(target.constructor.prototype);
        setMenuItemMetadata(target.constructor.prototype, items, name.toString());
    };
}

/**
 * Define menu items to use in context menu for object
 *
 * @param items items to show on context menu
 */
export function IwContextMenuClass(...items: MenuItem[]): ClassDecorator {
    return <TFunction extends Function>(target: TFunction) => {
        initObjectMenuItemMetadata(target.prototype);
        setMenuItemMetadata(target.prototype, items);

        return target;
    };
}
