import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[iwDurationInput]'
})
export class DurationInputDirective {
    @Output() public ngModelChange: EventEmitter<any> = new EventEmitter();
    @Input() public indemnity = false;
    private numberRegex = new RegExp(/^\-?\d*\.?\d{0,2}$/g);
    private timeRegex = new RegExp(/^\-?\d*\:?\d{0,2}$/g);
    private specialKeys: Array<string> = ['Backspace', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Tab'];

    constructor(private el: ElementRef) {
    }

    private _mode: 'number' | 'time' = 'number';

    @Input()
    public set mode(type: 'number' | 'time') {
        if (!this.indemnity) {
            this._mode = type;
        }
    }

    @HostListener('blur', ['$event'])
    public onBlur(event: KeyboardEvent | MouseEvent) {
        const input = event.target as HTMLInputElement;
        const current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), current.slice(position)].join('');

        input.value = this.autoFillData(next, input);
        if (this._mode === 'number') {
            this.ngModelChange.emit(Number(input.value));
        } else {
            this.ngModelChange.emit(input.value);
        }
    }

    @HostListener('focus', ['$event'])
    public onFocus() {
        const current = this.el.nativeElement.value;
        this.el.nativeElement.selectionStart = 0;
        this.el.nativeElement.selectionEnd = current.length;
    }

    @HostListener('keydown', ['$event']) // eslint-disable-next-line complexity
    public onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;

        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        const current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        let next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');

        // Checks if the the whole input is selected
        if (this.el.nativeElement.selectionStart === 0 && this.el.nativeElement.selectionEnd === current.length) {
            next = event.key;
        }

        // Number number validations
        if (next && this._mode === 'number' && !String(next)
            .match(this.numberRegex)) {
            event.preventDefault();
        }

        if (this._mode === 'number' && String(next)
            .match(this.numberRegex) && !this.indemnity) {
            if (Number(next) > 24 || Number(next) < -24) {
                event.preventDefault();
            }
        }

        // Time validations
        if (next && this._mode === 'time' && !String(next)
            .match(this.timeRegex)) {
            event.preventDefault();
        }

        if (next && this._mode === 'time' && String(next)
            .match(this.timeRegex)) {
            const splitString = next.split(':');
            if (!splitString[1]) {
                if ((Number(splitString[0]) > 24 || Number(splitString[0]) < -24) && !this.indemnity) {
                    event.preventDefault();
                    return;
                }
                if (!splitString[0].includes('-')) {
                    if (splitString[0].length === 2) {
                        if (!next.includes(':')) {
                            input.value = next + ':';
                            event.preventDefault();
                        }
                    }
                } else {
                    if (splitString[0].length === 3) {
                        if (!next.includes(':')) {
                            input.value = next + ':';
                            event.preventDefault();
                        }
                    }
                }
            } else {
                if (Number(splitString[0]) > 23 || Number(splitString[0]) < -23 || Number(splitString[1]) > 59 || Number(splitString[1]) < 0) {
                    event.preventDefault();
                }
            }
        }
        // // auto-fill the data
        if (event.key === 'Enter') {
            input.value = this.autoFillData(next, input);
            if (this._mode === 'number') {
                this.ngModelChange.emit(Number(input.value));
            } else {
                this.ngModelChange.emit(input.value);
            }
        }
    }

    // eslint-disable-next-line complexity
    private autoFillData(next: string, input: HTMLInputElement): string {
        next = next.replace('Enter', '');
        if (this._mode === 'number') {
            if (next === '' || next === '.') {
                return '0';
            } else if (next.includes('.')) {
                const splitString = next.split('.');
                if (splitString[0].includes('-') && splitString[0].length === 1 && splitString[1] === '') {
                    return '0';
                }
                if (!splitString[1]) {
                    return next.replace('.', '');
                }
                if (splitString[0] === '') {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    splitString[1] ? (input.value = '0' + '.' + splitString[1]) : (input.value = splitString[0]);
                    return input.value;
                }
                if (splitString[0].includes('-') && splitString[0].length === 1) {

                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    splitString[1] && Number(splitString[1]) !== 0 ? (input.value = '-' + '0' + '.' + splitString[1]) : (input.value = '0');
                    return input.value;
                }
            } else if (next === '-') {
                return '0';
            }
            if (next !== '') {
                return next;
            }
            return '0';
        } else {
            if (next === '') {
                return '00:00';
            } else if (next.includes(':')) {
                const splitString = next.split(':');
                if (splitString[1] !== '' && splitString[1].length < 2) {
                    splitString[1] = '0' + splitString[1];
                }
                if (splitString[1] === '') {
                    splitString[1] = '00';
                }
                if (splitString[0].includes('-')) {
                    const hour = splitString[0].replace('-', '');
                    if (hour.length < 2) {
                        if (hour === '') {
                            if (splitString[1] === '00') {
                                splitString[0] = '00';
                            } else {
                                splitString[0] = '-00';
                            }
                        } else {
                            splitString[0] = '-' + '0' + hour;
                        }
                    }
                } else {
                    if (splitString[0].length < 2) {
                        if (splitString[0] === '') {
                            splitString[0] = '00';
                        } else {
                            splitString[0] = '0' + splitString[0];
                        }
                    }
                }
                if (splitString[0].replace('-', '') === '00' && splitString[1] === '00') {
                    return '00:00';
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                splitString[1] ? (input.value = splitString[0] + ':' + splitString[1]) : (input.value = splitString[0]);
                return input.value;
            } else {
                if (next.length < 2 && !next.includes('-')) {
                    return '0' + next + ':00';
                } else if (next.length === 2 && next.includes('-')) {
                    return '-' + '0' + next.replace('-', '') + ':00';
                }
            }
            return '00:00';
        }
    }
}
