<p-fileUpload #fileUploader
              (onSelect)="fileSelected($event)"
              (uploadHandler)="uploadDocument($event)"
              [accept]="acceptedFileType"
              [auto]="true"
              [chooseLabel]="label"
              [ngClass]="className"
              chooseIcon="pi pi-upload"
              customUpload="true"
              invalidFileTypeMessageDetail=""
              invalidFileTypeMessageSummary="Invalid"
              mode="basic"
              name="myfile[]"
              showCancelButton="false"
              showUploadButton="false"></p-fileUpload>