import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('gltvadef', '', 'common')
export class Gltvadef {
    @IwRestEntityId() public tvaCode?: string;
    public tvaLib?: string;
    public tvaTaux?: number;
    public tvaCpt?: string;
    public tvaDue?: boolean;
    public tvaCredit?: boolean;
    public tvaPure?: boolean;
    public tvaRecode?: string;
    public tvaType?: string;
    public inactif?: boolean;
}
