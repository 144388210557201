import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppijfiohis')
export class Ppijfiohis {
    @IwRestEntityId() public ijficId?: string;
    public ackxml?: string;
    public fictype?: string;
    public ficstatus?: number;
    public fname?: string;
    public userId?: string;
    public dtStop?: string;
    public cerrors?: string;
    public fcontent?: string;
    public nbrecprc?: number;
    public nbrec?: number;
    public dtStart?: string;
    public ficData?: string;
}
