export interface MinSalaryCalc {
    salary: SalaryValues;
    variableText: {
        pctHolidaysCompensation: {
            title: string; text: string; description: string;
        }; pctVacationCompensation: {
            title: string; text: string; description: string;
        }; compensation13thSalary: {
            title: string; text: string; description: string;
        };
    };
    additionalText: AdditionalText[];
    footnotes: any;
    alternativeText: any;
}

export interface SalaryValues {
    monthSalary: number;
    vacationDays: number;
    publicHolidays: number;
    basicSalary: number;
    vacationCompensation: number;
    publicHolidaysCompensation: number;
    compensation13thSalary: number;
    hasERWContribution: boolean;
    erwContribution: any;
    ereContribution: any;
    has13thMonthSalary: boolean;
    pctVacationCompensation: number;
    pctHolidaysCompensation: number;
    pct13thMonthSalary: number;
    publicHolidaysCalculationType: number;
    vacationCalculationType: number;
    month13thSalaryCalculationType: number;
}

export interface AdditionalText {
    title: string;
    text: string;
    description: string;
}

export interface TempdataFormResponse {
    minSalarycalc: MinSalaryCalc;
    tempdataText: string;
}

export enum CalculationTypes {
    type_1 = '+B',
    type_2 = '+B+VAC',
    type_3 = '+B+JF',
    type_4 = '+B+JF+VAC',
    type_5 = '+B+13+VAC',
    type_6 = '+B+JF+VAC',
    type_7 = '+B+13',
    type_8 = '+B+13+JF+VAC'
}

