import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppcliadr')
export class Ppcliadr {
    @IwRestEntityId() public cliadrId?: string;
    @IwColumn({name: 'Description'}) public adrlib?: string;
    public userDelet?: string;
    public dtAnyModif?: string;
    public dateCreat?: string;
    public userId?: string;
    public nom?: string;
    public dateDelet?: string;
    public pays?: string;
    public adresse2?: string;
    public cliId?: string;
    public adresse1?: string;
    public npa?: string;
    public userModif?: string;
    public adresse3?: string;
    public dateModif?: string;
    public prefixe?: string;
    public lieu?: string;
    public userCreat?: string;
}
