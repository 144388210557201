import {Directive, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

import {Dictionary} from '../models';

@Directive()
export abstract class BaseFormControl<T> implements ControlValueAccessor {
    @Input() public labelAlign: 'top' | 'left' | 'right' = 'top';

    @Input() public textAlign: 'left' | 'center' | 'right' = 'left';

    @Input() public label = '';

    @Input() public size: 'small' | 'medium' | 'large' | 'auto' = 'medium';

    @Input() public selectedChoice?: number;

    /** Add class no-label, to add padding in top */
    @Input() public noLabel = false;
    /** If true, user can not edit the [value] */
    @Input() public isReadOnly = false;
    @Output() public valueChange = new EventEmitter<T | undefined>();
    @Output() public press = new EventEmitter<MouseEvent>();
    @Output() public controlFocus = new EventEmitter<boolean>();
    @ViewChild('control', {static: true}) public control?: ElementRef;
    // Timeout value, to prevent set overload
    // private _assignTimeoutId?: any;

    public get className() {
        return {
            ['size-' + this.size]: true,
            [this.labelAlign]: true,
            ['text-align-' + this.textAlign]: true, ...this.getClassName()
        };
    }

    protected _value?: T;

    public get value() {
        return this._value;
    }

    @Input()
    public set value(val: T | undefined) {
        this.setValue(val);
    }

    protected _isDisabled = false;

    public get isDisabled() {
        return this._isDisabled;
    }

    @Input()
    public set isDisabled(val: boolean) {
        this._isDisabled = val;
    }

    protected _hasFocus = false;

    public get hasFocus() {
        return this._hasFocus;
    }

    public writeValue(obj: any): void {
        // eslint-disable-next-line no-null/no-null
        this._value = obj ?? null;
        this.onChange();
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public getValue() {
        return this._value;
    }

    public setValue(v: T | undefined) {
        if (v === this._value) {
            return;
        }
        // todo : we disabled the _assignTimeoutId because it created issues sometime. But will this break something else ?...
        // if (this._assignTimeoutId) {
        //     clearTimeout(this._assignTimeoutId);
        // }

        // this._assignTimeoutId = setTimeout(() => {
        this._value = v;
        // fixme : maybe uncheck this once we better understand how the diff undefined/null handling is done in our app...
        // check other commented code (base-form.component.ts -> getFormData) in case we need to un comment this one we need to uncomment the other one to keep it working
        // if (v === null) {
        // this._value = undefined;
        // }
        this.onChange();
        // }, 300);
    }

    public onChange() {
        if (typeof this._onChange === 'function') {
            this._onChange(this._value);
        }
        this.valueChange.emit(this._value);
    }

    public onClick(event: MouseEvent) {
        if (!this._isDisabled) {
            this.press.emit(event);
        }
    }


    public onFocus() {
        this.controlFocus.emit(true);
        this._hasFocus = true;
    }

    public onBlur() {
        this.controlFocus.emit(false);
        this._hasFocus = false;
    }

    public getFocus(wait?: number) {
        setTimeout(() => {
            if (this.control && this.control.nativeElement && this.control.nativeElement.focus) {
                this.control.nativeElement.focus();
            }
        }, wait || 0);
    }

    protected getClassName(): Dictionary<boolean> {
        return {};
    }

    protected _onChange(val: any) {
    }

    protected _onTouched() {
    }
}
