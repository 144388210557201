<iw-dialog #dialog [bodyPadding]="'10'" [modal]="true" [resizable]="false" [title]="'modal_select_itm' | translate"
           [visible]="display" [width]="size">
    <div class="row select-body" id="dialog-window">
        <div class="fit-parent-modal col-xs-9">
            <iw-table (rowDoubleClick)="onRowDoubleCLick($event)" *ngIf="fetchAction" [(selected)]="selected"
                      [columns]="columns" [data]="tableData" [isFilterEnable]="false" [isGridColumnMenuEnable]="false"
                      [isLoading]="loading" [isSortIconEnable]="false" [selectionMode]="selectMode"></iw-table>
            <iw-rest-grid #restTable (rowDoubleClick)="onRowDoubleCLick($event)" (selected)="selected = $event"
                          *ngIf="type" [autoFormClick]="false" [customColumns]="columns" [defaultSelected]="true"
                          [hasFilter]="hasFilter" [hasGridColumnMenu]="false" [hasSortIcon]="true"
                          [queryStatements]="query" [type]="type"></iw-rest-grid>
        </div>
        <div class="fit-parent-modal col-xs-3 flex flex-v flex-between">
            <iw-textfield *ngIf="fetchAction" [isReadOnly]="true" [label]="'label_results' | translate"
                          [value]="tableData.length"></iw-textfield>
            <div class="btn-group">
                <iw-button (press)="onOkPress()" [isDisabled]="isOkDisabled" [text]="'ok' | translate"
                           class="btn-block"></iw-button>
                <iw-button (press)="onCancelPress()" [text]="'cancel' | translate" class="btn-block"></iw-button>
            </div>
        </div>
    </div>
</iw-dialog>
