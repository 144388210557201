import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppmissalview')
export class PpmissalView {
    @IwColumn({index: 0, name: 'periode'}) @IwRestEntityId() public period?: string;
    @IwColumn({index: 1, name: 'salary'}) public sal?: string;
    @IwColumn({index: 2, name: 'facture'}) public fac?: string;
    @IwColumn({index: 3, name: 'invoice_number'}) public facNo?: string;
    public misId?: string;
}
