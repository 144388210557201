import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('gepaicond')
export class Gepaicond {
    @IwRestEntityId() public paiCondId?: string;
    public libelle?: string;
    public nbjours?: number;
    public tauxesc?: string;
    public paiCdText?: string;
    public paicdtext1?: string;
    public paicdtext2?: string;
    public paicdtext3?: string;
    public paicdtext4?: string;
    public paicdtext5?: string;

}
