import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {InvoiceBill} from '@sam-base/models/placement/prepare';

export enum InvoiceClientEvent {
    UNE_FACTURE = 'UNE_FACTURE',
    EMPLOYE = 'EMPLOYE',
    MOIS = 'MOIS',
    RAPPORT = 'RAPPORT',
    CHANTIER = 'CHANTIER',
    CHANTIER_EMPLOYE = 'CHANTIER_EMPLOYE',
    SEMAINE = 'SEMAINE',
    SEMAINE_CHANTIER = 'SEMAINE_CHANTIER',
    RAPPORT_FORCE = 'RAPPORT_FORCE',
    SEMAINE_EMPLOYE = 'SEMAINE_EMPLOYE',
    EMPLOYE_MOIS = 'EMPLOYE_MOIS',
    MANUEL = 'MANUEL',
    SEMAINE_CHANTIER_EMPLOYE = 'SEMAINE_CHANTIER_EMPLOYE',
    MISSION = 'MISSION',
    REF_CLIENT = 'REF_CLIENT',
    EMPLOYE_REF = 'EMPLOYE_REF',
}

export enum InvoiceProcessEvent {
    REASSIGN = 'REASSIGN'
}

export enum InvoiceProcessType {
    HOURS = 'HOURS',
    MONTHLY = 'MONTHLY',
    FIXE = 'FIXE'
}

export enum InvoiceFixeStatusEnum {
    INIT = 0, PREPARED = 1, GENERATED = 2, CONFIRMED = 3
}


export interface InvoiceClient {
    id: number;
    invoiceProcessId: number;
    cliNom?: string;
    cliId: string;
    nbRap?: number;
    nbPlf?: number;
    nbMis?: number;
    mntht?: number;
    modeFactu?: number;
}

export interface TvaParameters {
    tvaCode: String;
    tvaPeriode: String;
    tvaTaux: String;
}

export interface InvoiceLockResponse {
    invoiceProcessId: number;
}

export interface InvoiceCountResponse {
    ageId?: string;
    secId?: string;
    clientIds: string[];
    clientTotal: number;
}

export interface InvoiceCountRequest {
    salId: string;
    invoiceProcessType: InvoiceProcessType;
}

export interface InitInvoiceRequest {
    salId: string;
    invoiceProcessType: InvoiceProcessType;
    cliIds: string[];
}


export interface InvoiceProcess {
    id: number;
    owner: string;
    type: InvoiceProcessType;
    version: string;
    salId: string;
    dateFactu: string;
    dateCompta: string;
}


export interface InvoiceProcessDetails {
    invoiceProcess: InvoiceProcess;

    invoiceClients: InvoiceClient[];

    invoiceBills: InvoiceBill[];

    faceleord: number;

    tvaParameters: TvaParameters;
}

@IwRestEntity('invoice')
export class Invoice {
    @IwRestEntityId() public invoiceProcessId?: number;

    public salId?: string;
    public tvaTaux?: number;
    public sessionOwner?: string;
    public rndto05?: boolean;
    public tvaCode?: string;
    public celeord?: number;
    public clients?: InvoiceClient[];
    public bvrMpaId?: string;
    public errorMsg?: string;
    public actSalId?: string;
    public bvractif?: boolean;
    public selAgeId?: string;
    public dateFactu?: string;
    public cnoclibvr?: string;
    public tvaRecode?: string;
    public tvaCodeHt?: string;
    public cnobnkbvr?: string;
    public faceleord?: number;
    public lfindemois?: string;
    public defchamode?: number;
    public tvaPeriode?: string;
    public aModeFactu?: string[];
    public cbvrrefdef?: string;
    public dateCompta?: string;
    // public facgrpmode2?: string; // not needed anymore
    public tvaRecodeHt?: string;
    public lRndModeAdv?: boolean;
    // public facprepmode?: string; // not needed anymore
    // public nbCliWithEle?: number;
    public tvaParameters?: TvaParameters;
    public previousPeriod?: string;
    public lwithprevsalid?: boolean;
    public lBvrMpaByAgence?: boolean;
}
