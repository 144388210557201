import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'iw-calendar-tooltip',
    templateUrl: './iw-calendar-tooltip.component.html',
    styleUrls: ['./iw-calendar-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [trigger('tooltip', [transition(':enter', [style({opacity: 0}), animate(300, style({opacity: 1}))]), transition(':leave', [animate(300, style({opacity: 0}))])])]
})
export class IwCalendarTooltipComponent {

    @Input() public text?: string[];

    constructor() {
    }
}
