import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppgri')
export class Ppgri {
    @IwRestEntityId() public griId?: string;
    public griUnite?: string;
    public userId?: string;
    public actif?: string;
    public griPuemp?: number;
    public shortlib?: string;
    public griName3?: string;
    public griTxcha?: number;
    public griName?: string;
    public absCode?: string;
    public griPumode?: number;
    public griName5?: string;
    public griGsId?: string;
    public tvaCode?: string;
    public mismode?: number;
    public griName2?: string;
    public griName4?: string;
    public griPucli?: number;
    public griData?: string;
    public griName1?: string;
    public griCompte?: string;
    public griType?: number;
    public modData?: string;
    public modCnt?: number;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public dateTrack?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public exclstat?: boolean;
    public retInd?: number;
}
