import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Ppmis} from '@app/sam-base/models/placement';
import {SalaryValues} from '@app/sam-base/models/placement/min-salary-calc.model';
import {
    MissionApplyCoeff,
    MissionCalcApplyCoeffResponse,
    MissionCalcCCT,
    MissionCalcCCTResponse,
    MissionCalcCCTSup,
    MissionCalcCoeff,
    MissionCalcCoeffResponse,
    MissionCalcRapportResult,
    MissionCalcTarif,
    MissionCalcTarifResponse,
    MissionStatsParams,
    MissionStatsResponse,
    SalaryDeduction,
    SalaryDeductionResponse,
    TempdataTotalCalculation,
    TempdataTotalCalculationResponse
} from '@app/sam-base/models/placement/mission-calculations-result.model';
import {Observable, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {ToastService} from '../toast';

export interface CctSupCalc {
    misId: string;
    supCct: number;
}

@Injectable()
export class MissionCalculationsService extends RestClient<any> implements OnDestroy {

    public warnMessagesBuffer: Subject<string> = new Subject();
    public supCttCalculation: Subject<CctSupCalc> = new Subject();
    private warnMessagesBufferSub = new Subject();

    constructor(client: HttpClient, private _toastService: ToastService) {
        super(client);
        this.warnMessagesBuffer
            .pipe(takeUntil(this.warnMessagesBufferSub), debounceTime(500))
            .subscribe((message) => this._toastService.warning(message));
    }

    public ngOnDestroy() {
        this.warnMessagesBufferSub.next(undefined);
        this.warnMessagesBufferSub.complete();
    }

    // eslint-disable-next-line complexity
    public caculateDeductions(mis: Ppmis) {

        const data: SalaryDeduction = {
            ageId: mis.ageId || '',
            empId: mis.empId || '',
            salGlobal: Number(mis.salGlobal) || 0,
            codepgm: mis.codepgm || '',
            codecna: mis.codecna || '',
            cctRa: mis.cctRa || false,
            cctEx: mis.cctEx || false,
            cctFc: mis.cctFc || false,
            mntFrais1: mis.mntFrais1 || 0,
            mntFrais2: mis.mntFrais2 || 0,
            cct: mis.cctId || '',
            misId: mis.misId || '',
            missalmode: mis.missalmode || 1
        };

        return this.POST<SalaryDeductionResponse>(data, undefined, 'ppmis', 'calculations', 'pxrevhor');
    }

    public calcTempdataTotal(sal: SalaryValues, shcalcMode: number, salMode: number) {

        /** SalMode refers to missalmode from ppmis where 1 is hourly mode
         * and 3 monthly mode. We are only considering the 13eme value besides
         * base monthSalary when calculating the sum for the montly mode
         */
        const data: TempdataTotalCalculation = {
            salBase: salMode === 1 ? sal.basicSalary : sal.monthSalary,
            mntJf: salMode === 1 ? sal.publicHolidaysCompensation : 0,
            mnt13: salMode === 1 ? sal.compensation13thSalary : sal.pct13thMonthSalary,
            mntVac: salMode === 1 ? sal.vacationCompensation : 0,
            shcalcMode,
            missalmode: salMode
        };

        return this.POST<TempdataTotalCalculationResponse>(data, undefined, 'ppmis', 'calculations', 'tempdataTotals');
    }

    public getRapportCalc(misId: string): Observable<MissionCalcRapportResult> {
        return this.GET(undefined, 'miscalc', 'rapport', misId);
    }

    // eslint-disable-next-line complexity
    public calculateInvoice(value: number, clitarifra: number, total: number, salD: SalaryDeduction,
                            useTotal = false): Observable<MissionCalcTarifResponse> {
        const data: MissionCalcTarif = {
            changeField: 'tarif',
            clitarifra,
            clitarif: value,
            clitariftotal: total,
            calcByTarifTotal: useTotal,
            ageId: salD.ageId,
            empId: salD.empId,
            codepgm: salD.codepgm,
            codecna: salD.codecna,
            cctRa: salD.cctRa,
            cctEx: salD.cctEx,
            cctFc: salD.cctFc,
            mntFrais1: salD.mntFrais1,
            mntFrais2: salD.mntFrais2,
            cct: salD.cct,
            misId: salD.misId,
            salGlobal: salD.salGlobal,
            missalmode: salD.missalmode
        };
        return this.POST<MissionCalcTarifResponse>(data, undefined, 'ppmis', 'calculations');
    }

    /** Calculate total based on current coeff */
    public calculateCoeff(salTotal: number, clitarifra: number, coeff: number,
                          salD: SalaryDeduction): Observable<MissionCalcApplyCoeffResponse> {
        const data: MissionApplyCoeff = {
            changeField: 'apply-coeff',
            clitarifra,
            coeff,
            salTotal,
            ageId: salD.ageId,
            empId: salD.empId,
            codepgm: salD.codepgm,
            codecna: salD.codecna,
            cctRa: salD.cctRa,
            cctEx: salD.cctEx,
            cctFc: salD.cctFc,
            mntFrais1: salD.mntFrais1,
            mntFrais2: salD.mntFrais2,
            cct: salD.cct,
            misId: salD.misId,
            salGlobal: salD.salGlobal,
            missalmode: salD.missalmode
        };
        return this.POST<MissionCalcApplyCoeffResponse>(data, undefined, 'ppmis', 'calculations');
    }

    /** Calculate coeff between total and salaire */
    public calculateCurrentCoeff(clitarif: number, salTotal: number): Observable<MissionCalcCoeffResponse> {
        const data: MissionCalcCoeff = {
            changeField: 'coeff',
            clitarif,
            salTotal
        };

        return this.POST<MissionCalcCoeffResponse>(data, undefined, 'ppmis', 'calculation');
    }

    public calculateCCTSup(data: MissionCalcCCTSup): Observable<number> {
        return this.POST<number>(data, undefined, 'ppmis', 'calculations', 'cctSup');
    }

    // eslint-disable-next-line complexity
    public getCalculationsWithCCT(mis: Ppmis) {
        const data: MissionCalcCCT = {
            changeField: 'cct',
            calcByTarifTotal: false,
            cct: mis.cctId || '',
            clitarif: Number(mis.clitarif) || 0,
            l13: !!mis.l13,
            ljfh: !!mis.ljfh,
            lvac: !!mis.lvac,
            misId: mis.misId || '',
            salBase: Number(mis.salBase) || 0,
            salGlobal: Number(mis.salGlobal) || 0,
            shbaseglob: mis.shbaseglob || 1,
            shcalcmode: Number(mis.shcalcmode) || 1,
            missalmode: mis.missalmode || 1,
            tx13: Number(mis.tx13) || 0,
            txJf: Number(mis.txJf) || 0,
            txVac: Number(mis.txVac) || 0,
            jfNonPayes: !!mis.jfNonPayes,
            tempdataon: (Number(mis.cctMinValue) || 0) > 0, // Deductions fields
            ageId: mis.ageId || '',
            empId: mis.empId || '',
            codepgm: mis.codepgm || '',
            codecna: mis.codecna || '',
            cctRa: mis.cctRa || false,
            cctEx: mis.cctEx || false,
            cctFc: mis.cctFc || false,
            mntFrais1: mis.mntFrais1 || 0,
            mntFrais2: mis.mntFrais2 || 0
        };
        return this.POST<MissionCalcCCTResponse>(data, undefined, 'ppmis', 'calculations/cct');
    }

    // eslint-disable-next-line complexity
    public calculateStats(mis: Ppmis) {
        const data: MissionStatsParams = {
            clitarif: mis.clitarif || 1,
            clitarifra: mis.clitarifra || 0,
            mntAvsetc: mis.mntAvsetc || 0,
            mntAvspgn: mis.mntAvspgn || 0,
            mntCct: mis.mntCct || 0,
            mntJfz: mis.mntJfz || 0,
            mntLpp: mis.mntLpp || 0,
            salTotal: mis.salTotal || 1,
            sectId: mis.secId || '',
            salMode: mis.missalmode || 0,
            facMode: mis.misfacmode || 0,
            codepgm: mis.codepgm || ''
        };

        return this.POST<MissionStatsResponse>(data, undefined, 'ppmis', 'stats');
    }

    public getEmployeeAvailabilityForCurrentMission(misId: string, empId: string): Observable<number> {
        return this.GET(undefined, 'mission', 'employee-availability', misId, empId);
    }

    public canApplyFormcontrol(k: string, result: any, formGroup?: UntypedFormGroup): boolean {
        if (!formGroup) {
            return false;
        }
        return k !== 'misId' && (<any>result)[k] !== '' && this.isPropInForm(k, formGroup);
    }

    public isPropInForm(k: string, formGroup: UntypedFormGroup): boolean {
        return formGroup?.contains(k) ?? false;
    }

}
