<div class="auto-scroll">
    <div class="flex">
        <iw-planning-day [widthLabel]="'170px'"></iw-planning-day>
        <iw-planning-day *ngFor="let weekday of weekdays; let i = index" [label]="weekday"
                         [widthLabel]="mode === 'weekly' ? '168px' : '35px'"></iw-planning-day>
    </div>
    <div class="flex">
        <iw-planning-day [widthLabel]="'170px'"></iw-planning-day>
        <iw-planning-day *ngFor="let monthday of monthdays; let i = index" [label]="monthday"
                         [widthLabel]="mode === 'weekly' ? '168px' : '35px'"></iw-planning-day>
    </div>

    <div *ngFor="let planning of planningMonth; let i = index" class="flex">
        <iw-planning-day *ngIf="planning?.name === 'Leaves'" [enableClick]=false [label]="planning?.name | translate"
                         [widthLabel]="'170px'"></iw-planning-day>
        <iw-planning-day *ngIf="planning?.name !== 'Leaves'" [label]="planning?.name | translate"
                         [sourcePlaning]="planning" [widthLabel]="'170px'" enableClick="true"></iw-planning-day>
        <iw-planning-day *ngFor="let pm of planning?.days;" [day]="pm" [enableClick]="true" [mode]="mode"
                         [widthLabel]="mode === 'weekly' ? '168px' : '35px'"></iw-planning-day>
    </div>
