import {Component, ComponentRef} from '@angular/core';
import {IwTextareaComponent} from '@app/sam-base/components/iw-textarea/iw-textarea.component';
import {HtmlElementStatus} from '@app/sam-base/models';

@Component({
    selector: 'iw-modal-textarea', templateUrl: './modal-text-area.component.html'
})
export class ModalTextAreaComponent {

    public data: ModalTextAreaData = {
        value: 'texto', btnOk: {
            enabled: true, value: 'Ok', visible: true
        }
    };
    // eslint-disable-next-line
    public __ref?: ComponentRef<ModalTextAreaComponent>;

    public get isReadonly() {
        return !!(this.data.state && this.data.state.isReadOnly);
    }

    public onOk() {
        if (this.data.onOk) {
            this.data.onOk(this.data.value);
        }
        this.destroy();
    }

    public onCancel() {
        if (this.data.onCancel) {
            this.data.onCancel();
        }
        this.destroy();
    }

    private destroy() {
        if (this.__ref) {
            this.__ref.destroy();
        }
    }
}

export interface ModalTextAreaData {
    value: string;
    onOk?: (value: string) => void;
    onCancel?: () => void;
    state?: IwTextareaComponent;
    btnOk?: HtmlElementStatus;
}
