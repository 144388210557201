import {
    BehaviorSubject, interval, Observable, Subject, Subscription
} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {IwStoreService} from '@app/sam-base/core/store';
import {Ijtemprapportview} from '@app/sam-base/models/placement';
import {environment} from '@root/environments/environment';

import {RestClient} from '../rest-api/core';

@Injectable()
export class IjTimesheetsService extends RestClient<{}> implements OnDestroy {
    public ijTimesheets2Import: BehaviorSubject<number> = new BehaviorSubject(0);
    public interijobActive$ = false;
    private ijSyncInterval = interval(environment.ijTimesheetScheduler);
    private ijSyncSub: Subscription = new Subscription();
    private _subs = new Subject();

    constructor(http: HttpClient, private _store: IwStoreService) {
        super(http);
        this._store.getLicenseOption('interijobwebaccess')
            .pipe(takeUntil(this._subs))
            .subscribe((active: boolean) => {
                this.interijobActive$ = active;
                this.ijSyncScheduler();
            });
    }

    public ngOnDestroy() {
        this.ijSyncSub.unsubscribe();
        this._subs.next(undefined);
        this._subs.complete();
    }

    /**
     * Returns the number of interijob timesheets to import
     */
    public getTimesheetsToImport() {
        this.GET<number>(undefined, 'ij/reports/unsynched/count')
            .subscribe((count: number) => this.ijTimesheets2Import.next(count));
    }

    /**
     * Triggers the manual import of interijob timesheets
     */
    public importTempTimesheets(): Observable<void> {
        return this.POST<void>({}, undefined, 'ij/reports/import')
            .pipe(map(() => this.getTimesheetsToImport()));
    }

    /**
     * Imports selected timesheets to placemenet
     */
    public importTempTimesheets2Placement(rapports: Ijtemprapportview[]): Observable<void> {
        const rapportIds: string[] = rapports
            .map(rap => rap.ijRapId ?? '')
            .filter(id => id !== '');
        return this.POST<void>(rapportIds, undefined, 'ij/reports/sync')
            .pipe(map(() => this.getTimesheetsToImport()));
    }

    /**
     * Scheduler to update the number of ij reports to import
     */
    private ijSyncScheduler() {
        if (this.interijobActive$) {
            this.getTimesheetsToImport();
            this.ijSyncSub = this.ijSyncInterval
                .subscribe(() => this.getTimesheetsToImport());
        }
    }
}
