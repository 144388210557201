<iw-modal-form (pressCancel)="onPressCancel()" (pressOk)="onPressOk()" [btnCancel]="cancelShow" [height]="height"
               [title]="title" [width]="width">

    <div class="row" style="margin-top: 20px;">
        <div class="col-xs-7" style="margin-bottom: 1rem;">
            <div class="col-xs-10">
                <strong>{{
                        obj['header'].facornc === 'F' ?
                            ('facture_upper' | translate) :
                            ('credit_note' | translate)
                    }} No {{ obj['header'].fac_no }}</strong>
            </div>
            <div class="col-xs-10">No client: {{ obj['header'].cli_id }}</div>
            <br>
            <div class="col-xs-10">Concerne: {{ obj['header'].concerne }}</div>
        </div>
        <div class="col-xs-5" style="margin-bottom: 1rem;">
            <div class="col-xs-10">{{ obj['client'].nom }}</div>
            <div class="col-xs-10">{{ obj['client'].adresse1 }}</div>
            <div class="col-xs-10">{{ obj['client'].npa }}, {{ obj['client'].lieu }}</div>
        </div>
    </div>
    <p-table [columns]="tableColumn" [value]="obj['facele']">
        <ng-template let-columns pTemplate="header">
            <tr>
                <th *ngFor="let col of columns" id="{{col.prop}}">
                    {{ col.name | translate }}
                </th>
            </tr>
        </ng-template>
        <ng-template let-columns="columns" let-rowData pTemplate="body">
            <tr>
                <ng-container *ngFor="let col of columns">
                    <td *ngIf="col.name === 'desig' || col.name === 'rapport'">
                        {{ rowData[col.prop] }}
                    </td>
                    <td *ngIf="col.name !== 'desig' && col.name !== 'rapport' && col.name !== 'qte'"
                        style="text-align: right;">
                        {{ maskMnt(rowData[col.prop]) }}
                    </td>
                    <td *ngIf="col.name === 'qte'" style="text-align: right;">
                        {{ rowData[col.prop] }}
                    </td>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
    <div class="row" style="margin-top: 20px;">
        <div class="col-xs-3 col-xs-offset-9">
            <div class="row">
                <div class="col-xs-8">Sous-total</div>
                <div class="col-xs-4" style="text-align: right;"><strong>{{ maskMnt(obj['header'].mntht) }}</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-4">TVA</div>
                <div class="col-xs-4">{{ obj['header'].tva_taux }}%</div>
                <div class="col-xs-4" style="text-align: right;">{{ maskMnt(obj['header'].mnttva) }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-xs-8">Total</div>
                <div class="col-xs-4" style="text-align: right;"><strong>{{ maskMnt(obj['header'].mntttc) }}</strong>
                </div>
            </div>
        </div>
    </div>


</iw-modal-form>
