import {Directive, Input, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {WARNING} from '../core/logger';

/**
 * Base class to be used by form tabs and
 * components that implement a partial formgroup
 */
@Directive()
export class BasePartialFormComponent<T> implements OnDestroy {

    @Input() public isReadonly = true;

    public formGroupReady = false;
    protected _subs: Subscription[] = [];
    private _delay = 300;
    private _subscription?: Subscription;

    constructor() {
    }

    private _formGroup = new UntypedFormGroup({});

    public get formGroup() {
        return this._formGroup;
    }

    /** FormGroup passed by parent component */
    @Input()
    public set formGroup(v: UntypedFormGroup | undefined) {
        this.setFormGroup(v);
    }

    protected get changeDelay() {
        return this._delay;
    }

    /** Number of seconds until value change is trigger after a update */
    protected set changeDelay(v: number) {
        this._delay = v;
        this.updateSubscription();
    }

    public ngOnDestroy(): void {
        this.clearSubscriptions();
    }


    /** Get current form state */
    public getFormData(): T | undefined {
        let value = this._formGroup.value;

        if (value) {
            value = {...value};
            for (const key in value) {
                // eslint-disable-next-line no-null/no-null
                if (value[key] === null) {
                    delete value[key];
                }
            }
        }

        return value;
    }

    /** Get control value with key as name */
    public getFormValue<Z>(key: keyof T): Z | undefined;
    public getFormValue(key: keyof T): T[keyof T] | undefined {
        const control = this.getFormControl(key);
        if (control) {
            return control.value;
        }
        return undefined;
    }

    /** Define the current form group */
    public setFormGroup(f: UntypedFormGroup | undefined): void {
        this.clearSubscriptions();
        this._formGroup = f instanceof UntypedFormGroup ? f : new UntypedFormGroup({});
        this.updateSubscription();
        this.formGroupReady = Object.keys(this._formGroup.controls).length > 0;
        this.onFormGroupSet();
    }

    /** Set control value with key as name */
    public setFormValue(key: keyof T, value: T[keyof T]) {
        const control = this.getFormControl(key);
        if (control) {
            const oldValue = control.value;
            if (oldValue !== value) {
                control.setValue(value);
            }
        }
    }

    protected clearSubscriptions() {
        for (const sub of this._subs) {
            if (!sub) {
                continue;
            }
            sub.unsubscribe();
        }
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    /**
     * @override
     * Trigged when form changes
     */
    protected onFormChange(_?: T): void {
    }

    /**
     * @override
     * Trigged when the formgroup is set
     */
    protected onFormGroupSet() {
    }

    /** Get control object with key as name */
    protected getFormControl(key: keyof T): AbstractControl | undefined {
        return this._formGroup.controls[<string>key];
    }

    /**
     * Subscribe to changes to a specific key
     *
     * @param k key to subscribe
     * @param fn callback
     */
    protected subscribeValueChange<TValue>(k: keyof T, fn: (e: TValue | undefined) => any, delay: number = 50) {
        if (!this._formGroup.contains(<string>k)) {
            return WARNING('Cant find control for ' + String(k));
        }

        const sub = this._formGroup.controls[<string>k].valueChanges
            .pipe(debounceTime(delay))
            .subscribe(fn);
        if (sub) {
            this._subs.push(sub);
        }
    }

    private updateSubscription(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }

        this._subscription = this._formGroup
            .valueChanges
            .pipe(debounceTime(this._delay))
            .subscribe((e?: T) => this.onFormChange(e));
    }

}
