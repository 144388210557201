import {Component, EventEmitter, Input, Output} from '@angular/core';

import {TabItem} from '../../models/tab-item.model';

@Component({
    selector: 'iw-tab', templateUrl: './iw-tab.component.html', styleUrls: ['./iw-tab.component.scss']
})
export class IwTabComponent {

    @Input() public selectedTab: TabItem | undefined;
    @Input() public tabs: TabItem[] | undefined;
    @Input() public invalidTabs?: string[];
    @Input() public disabledTabs?: string[];

    @Output() public selectedTabChange = new EventEmitter<TabItem>();

    constructor() {
    }

    public onTabClick(tab: TabItem) {
        this.selectedTab = tab;
        this.selectedTabChange.emit(this.selectedTab);
    }

    public isInvalid(tabTitle: string) {
        if (!this.invalidTabs) {
            return false;
        }
        return this.invalidTabs.includes(tabTitle);
    }

    public isDisabled(tabTitle: string) {
        if (!this.disabledTabs) {
            return false;
        }
        return this.disabledTabs.includes(tabTitle);
    }
}
