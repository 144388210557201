import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {EventsModule} from '../events';
import {DomEventService} from './events/dom-events.service';
import {ElementRefService} from './tools/elem-ref.service';

@NgModule({
    imports: [CommonModule, EventsModule], providers: [DomEventService, ElementRefService]
})
export class DomModule {
}
