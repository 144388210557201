import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('gecliatt')
export class Gecliatt {
    @IwRestEntityId() public cliattId?: string;
    @IwColumn() public prenom?: string;
    public dateCreat?: string;
    public userCreat?: string;
    public remarques?: string;
    public userDelet?: string;
    public prefixe?: string;
    public cliId?: string;
    public nom?: string;
    public fax?: string;
    public cpolitesse?: string;
    public userId?: string;
    public natel?: string;
    public dept?: string;
    public dateModif?: string;
    public tel?: string;
    public nomcomplet?: string;
    public dateanniv?: string;
    public categories?: string;
    public email?: string;
    public dateDelet?: string;
    public fonction?: string;
    public userModif?: string;
}
