import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core';

@IwRestEntity('ppabscode', 'common')
export class Ppabscode {
    @IwRestEntityId() public id?: string;
    public desc?: string;
    public abv?: string;
    public agiCode?: string;
    public semStatus?: number;
}
