import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ProfileService} from '@shared/profile/profile.service';
import {KeycloakService} from 'keycloak-angular';

import {AuthenticationInterceptor} from './interceptors/authentication.interceptor';
import {HttpErrorInterceptor} from './interceptors/error.interceptor';
import {HeadersInterceptor} from './interceptors/headers.interceptor';
import {AgencyHandlerService} from './services/agency-handler.service';
import {BootstrapAuthService} from './services/bootstrap-auth.service';

const keycloakService = new KeycloakService();

@NgModule({
    providers: [BootstrapAuthService,
        {
            provide: KeycloakService, useValue: keycloakService
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true
        },
        ProfileService,
        AgencyHandlerService]
})
export class AuthModule {
}
