import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {getMask} from '../../core/masks/phone-number';

@Component({
    selector: 'iw-phone-textfield',
    templateUrl: './iw-phone-textfield.component.html',
    providers: [buildCustomValueProvider(IwPhoneTextFieldComponent)]
})
export class IwPhoneTextFieldComponent extends BaseFormControl<string> implements OnInit, OnDestroy {

    @Input() public icon?: string;
    @Input() public placeholder = '';
    @Input() public warningMessage?: string;
    @Input() public inputClass?: string;
    @Input() public tooltip?: string;
    @Input() public tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
    @Input() public suffix?: string;
    /**
     * Set fraction decimals to format value
     * -1 disable this
     *
     * @type {(-1 | 1 | 2 | 3 | 4 | 5)}
     * @memberof IwTextFieldComponent
     */
    @Input() public decimals: -1 | 1 | 2 | 3 | 4 | 5 = 2;
    public mask?: string;
    private _subs = new Subject();

    constructor(private elRef: ElementRef) {
        super();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public ngOnInit() {
        this.valueChange
            .pipe(takeUntil(this._subs), debounceTime(1000))
            .subscribe(value => this.setMask(value));
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public onBlur() {
        super.onBlur();
    }

    public setMask(value?: string) {
        this.mask = getMask(value);
    }

    protected getClassName() {
        return {
            ['status-undefined']: true, ['suffix']: !!this.suffix
        };
    }
}
