import {EventEmitter, Injectable} from '@angular/core';

import {LayoutEvents} from '../../layout/events/events.models';

@Injectable()
export class SideMenuStateService {

    public sideMenuActiveChange = new EventEmitter<LayoutEvents>();

    private _sideMenuActive: LayoutEvents | undefined;

    public setSideMenuActive(menu: LayoutEvents) {
        if (menu === this._sideMenuActive) {
            this._sideMenuActive = undefined;
        } else {
            this._sideMenuActive = menu;
        }
        this.sideMenuActiveChange.emit(this._sideMenuActive);
    }

    public getSideMenuActive() {
        return this._sideMenuActive;
    }
}
