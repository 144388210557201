// eslint-disable-next-line max-classes-per-file
import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';


@IwRestEntity('sags')
export class Sags {
    @IwRestEntityId()
    @IwColumn({
        name: 'id',
        index: 0
    })
    public gsId?: string;
    @IwColumn({
        name: 'genre',
        index: 1
    })
    public titre?: string;
    public titre1?: string;
    public titre2?: string;
    public titre3?: string;
    public titre4?: string;
    public titre5?: string;
    public gsGenre?: string;
    @IwColumn({
        name: 'type',
        index: 2
    })
    public gsCtype?: string;
    public csType?: number;
    public csType2?: number;
    public sdsType?: string;
    @IwColumn({
        name: 'brut',
        index: 3
    })
    public baseBrut?: number;
    @IwColumn({
        name: 'avcac',
        index: 4
    })
    public baseAvs?: number;
    @IwColumn({
        name: 'laa',
        index: 5
    })
    public baseCna?: number;
    @IwColumn({
        name: 'laac',
        index: 6
    })
    public baseLac?: number;
    @IwColumn({
        name: 'pgm',
        index: 7
    })
    public basePgm?: number;
    @IwColumn({
        name: 'title_lpp',
        index: 8
    })
    public baseLpp?: number;
    @IwColumn({
        name: 'is',
        index: 9
    })
    public baseImp?: number;
    @IwColumn({
        name: 'retind',
        index: 10
    })
    public retInd?: number;
    @IwColumn({
        name: 'ncs',
        index: 11,
        hidden: true
    })
    public cs06Type?: string;
    public cs06Type2?: string;
    public cs06Type3?: string;
    @IwColumn({
        name: 'statan',
        index: 12
    })
    public sd4Statan?: string;
    public sd4Statoct?: string;
    @IwColumn({
        name: 'is',
        index: 14
    })
    public sd4Istype?: string;
    public sd4Caftype?: number;
    public baseCu1?: number;
    public baseCu2?: number;
    public baseCu3?: number;
    public baseCu4?: number;
    public baseCu5?: number;
    public baseCu6?: number;
    public baseMj1?: number;
    public baseMj2?: number;
    public baseMj3?: number;
    public baseMj4?: number;
    public avsacNb?: boolean;
    @IwColumn({
        name: 'comptedebit',
        index: 15,
        hidden: true
    })
    public cptdebit?: string;
    @IwColumn({
        name: 'comptecredit',
        index: 16,
        hidden: true
    })
    public cptcredit?: string;
    public debitsplit?: number;
    public creditsplit?: number;
    public detdebit?: boolean;
    public detcredit?: boolean;
    public analytique?: boolean;
    public roundIt?: boolean;
    public printIt?: boolean;
    public salBase?: boolean;
    public salHor?: boolean;
    public salHormnt?: boolean;
    public salJour?: boolean;
    public salHorlpp?: boolean;
    public salLppmnt?: boolean;
    public corrmode?: number;
    public lpphormode?: number;
    public gsPaiPrv?: string;
    public gsRepPrv?: string;
    public gsOpp?: string;
    public cumformule?: string;
    public basishmode?: number;
    public gsisop?: boolean;
    public gsData?: string;
    public xmlData?: string;
    public inactif?: boolean;
    public modData?: string;
    public modCnt?: number;
    public userId?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public dateTrack?: string;
    @IwColumn({
        name: 'lppprev',
        type: 'boolean',
        index: 17,
        hidden: true
    })
    public lppprev?: boolean;
    @IwColumn({
        name: 'lppfact',
        type: 'number',
        index: 18,
        hidden: true
    })
    public lppfact?: string;
    @IwColumn({
        name: 'lppretro',
        type: 'boolean',
        index: 19,
        hidden: true
    })
    public lppretro?: boolean;
    public sdsCaf?: string;
}

export class SagsAud extends Sags {
    public revisionNumber?: number;
    public revisionDate?: Date;
}
