export const environment = {
    production: true,
    backendURL: '/api/',
    authURL: '/',
    activeMocks: false,
    googleAPI: 'AIzaSyCP7gt9s6NVYcH9FQRg5Xg_Xa6tn5QdjzA',
    esURL: '/api/proxy/es/',
    logLevel: 0, // Minimum log level
    ijTimesheetScheduler: 600000,
    maxSignatureHeight: 135,
    maxSignatureWidth: 210,
    maxEmailSignatureHeight: 500,
    maxEmailSignatureWidth: 1000
};
