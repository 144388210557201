import {EntityStatusMap} from '../../../../components/iw-grid-column-config/entity-status-map';
import {IwGridColumn} from '../../../../models/components/grid-column.model';
import {GridProfile} from '../../../grid-profile/models';
import {
    SearchEntityDateOperator,
    SearchEntityDateTimeOperator,
    SearchEntityNumberOperator,
    SearchEntityStatusOperator,
    SearchEntityStringOperator,
    SearchExactMatch
} from '../../models';
import {Match, Range, Term} from '../models';
import {EsQueryStatement} from './es-query-statement';

export class EsGridProfileQuery {
    // eslint-disable-next-line complexity
    public static buildStatements<T>(p: GridProfile<T>): EsQueryStatement<T>[] {
        const list: EsQueryStatement<T>[] = [];

        list.push(EsQueryStatement.fromTerm(<Term<any>>{type: p.entity}, 'filter'));

        if (p.search) {
            list.push(EsQueryStatement.fromSimpleQuery({
                query: p.search
            }));
        }

        for (const c of Object.values(p.columns.available)) {
            const sta = this.getStatement(c);
            if (sta) {
                list.push(...sta);
            }
        }

        if (p.columns.filter) {
            for (const [key, value] of Object.entries(p.columns.filter)) {
                const sta = EsQueryStatement
                    .fromTerm({[key]: value}, 'filter');
                list.push(sta);
            }
        }
        if (p.columns.wildcard) {
            for (const [key, value] of Object.entries(p.columns.wildcard)) {
                const sta = EsQueryStatement
                    .fromWildcard({[key]: '*' + value + '*'});
                list.push(sta);
            }
        }
        if (p.customFilter) {
            list.push(p.customFilter);
        }

        return list;
    }

    // eslint-disable-next-line complexity
    public static getStatement<T>(column: IwGridColumn<T>): EsQueryStatement<T>[] | undefined {
        let prop = column.prop?.toString() || '';
        const op = column.filterOperator;
        const q = column.filterQuery;
        const q2 = column.filterQuery2;
        const valueFormat = column.valueFormat || '';

        const statusQuery = column.statusQuery?.some(value => value) ? [...column.statusQuery] : [];

        if (op && typeof prop === 'string' && ((typeof q === 'string' && q.length > 0) || typeof q === 'boolean') || (typeof valueFormat === 'string' && valueFormat.length > 0)) {
            // eslint-disable-next-line max-len
            switch (op) {
                case SearchEntityStringOperator.Like:
                    if (!q) {
                        return;
                    }
                    const esqstat = [];
                    for (const elem of q.split(' ')) {
                        esqstat.push(EsQueryStatement
                            .fromWildcard(<Term<any>>{[prop]: '*' + elem + '*'}));
                    }
                    // eslint-disable-next-line max-len
                    return esqstat;
                case SearchEntityStringOperator.NotLike:
                    // eslint-disable-next-line max-len
                    return [EsQueryStatement.fromWildcard(<Term<any>>{[prop]: q}, 'must_not')];
                case SearchEntityNumberOperator.GreaterThan:
                    return [
                        EsQueryStatement.fromRange(<Range<T>>{
                            [prop]: {gt: q}
                        }, 'filter')];
                case SearchEntityNumberOperator.LessThan:
                    return [
                        EsQueryStatement.fromRange(<Range<T>>{
                            [prop]: {lt: q}
                        }, 'filter')];
                case SearchEntityNumberOperator.EqualsTo:
                    return [EsQueryStatement.fromTerm(<Term<any>>{[prop]: q})];
                case SearchEntityDateOperator.Before:
                    return [
                        EsQueryStatement.fromRange(<Range<T>>{
                            [prop]: {
                                lte: q,
                                format: 'yyyy-mm-dd'
                            }
                        }, 'filter')];
                case SearchEntityDateOperator.After:
                    return [
                        EsQueryStatement.fromRange(<Range<T>>{
                            [prop]: {gte: q}
                        }, 'filter')];
                case SearchEntityDateOperator.On:
                    return [EsQueryStatement.fromTerm(<Term<any>>{[prop]: q})];
                case SearchEntityDateTimeOperator.NotOn:
                    return [
                        EsQueryStatement
                            .fromTerm(<Term<any>>{[prop]: q}, 'must_not')];
                case SearchEntityDateTimeOperator.Between:
                    return [
                        EsQueryStatement
                            .fromRange(<Range<T>>{
                                [prop]: {
                                    gte: q,
                                    lte: q2
                                }
                            }, 'must')];
                case SearchEntityDateTimeOperator.NotBetween:
                    return [
                        EsQueryStatement
                            .fromRange(<Range<T>>{
                                [prop]: {
                                    gte: q,
                                    lte: q2
                                }
                            }, 'must_not')];
                case SearchExactMatch.Equals:
                    return [
                        EsQueryStatement.fromMatch(<Match<any>>{
                            [prop]: {
                                operator: 'and',
                                query: q
                            }
                        }, 'must')];
                case SearchExactMatch.Or:
                    return [
                        EsQueryStatement.fromMatch(<Match<any>>{
                            [prop]: {
                                operator: 'or',
                                query: q
                            }
                        }, 'must')];
                case SearchEntityStatusOperator.NotInclude:
                    const list: EsQueryStatement<T>[] = [];

                    const statusList = EntityStatusMap.getStatusValue(valueFormat as any);
                    for (const i in statusList) {
                        if (!statusQuery[i] && statusQuery.length > 0) {
                            let mustNotValue = (valueFormat + statusList[i]);

                            // in case of type enum we do not want the prefix of value_format, as the column is not saved with that prefix in the index
                            if (column.type === 'enum') {
                                mustNotValue = statusList[i] as string;
                            }
                            list.push(EsQueryStatement.fromTerm(<Term<any>>{
                                [prop]: mustNotValue
                            }, 'must_not'));
                        }
                    }

                    return list;
            }
        }

        return;
    }
}
