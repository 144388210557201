export interface ModalRapportDataOptions {
    showCancel?: boolean;
    title?: string;
    width?: number;
    height?: number;
    obj?: any;
}

export enum SagsTitre {
    'Elément de salaire' = '1',
    'Déduction fixe' = '2',
    'Retenue ou indemnité non soumise' = '3',
    'Déduction automatique' = '4',
    'Correction Net / Brut' = '5',
    'Base ou montant soumis' = '6',
    'Indemnité automatique' = '7',
    'Déduction automatique / Employeur' = '8',
    'Déduction fixe / Employeur' = '9',
    'Correction de bases (coefficient)' = 'A',
    'Indemnité provisionnée' = 'B',
    'Paiement provision' = 'C',
    'Cumul annuel' = 'D',
    'Report année précédente' = 'E',
    'Correction CCT' = 'M',
    'Correction CCT / Employeur' = 'N',
    'Déduction CCT' = 'P',
    'Déduction CCT / Employeur' = 'Q',
    'Correction de bases (déduction+taux)' = 'F',
    'Base ou montant soumis CCT' = 'O',
    'Minimum vital (OP)' = 'V'

}

export interface SalaryContent {
    type: string | number;
    saes: any[];
}
