import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('lookups', '', 'common')
export class Lookups {
    @IwRestEntityId() public lkupname?: string;
    public cvalue?: string;
    public lookupId?: string;
    public cvalue1?: string;
    public cvalue2?: string;
    public cvalue3?: string;
    public cvalue4?: string;
    public cvalue5?: string;
    public cid?: string;
    public lkupData?: string;
}
