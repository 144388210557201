<iw-modal-form (pressCancel)="onPressCancel()"
               (pressOk)="onPressOk()"
               [title]="title">
    <iw-dropdown #drpConseiller
                 [(value)]="selectedConseiller"
                 [isDisabled]="false"
                 [noLabel]="true"
                 [options]="consDropdownOptions"
                 class="col-xs-4"></iw-dropdown>
</iw-modal-form>
