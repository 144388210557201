import {createSelector, select} from '@ngrx/store';
import {Observable} from 'rxjs';

import {LicenseOptions} from '../models';
import {AppState} from '../reducers';

export const selectLicense = (state: AppState) => state.license;

export function selectLicenseOption<T>(option: keyof LicenseOptions): (source$: Observable<AppState>) => Observable<boolean> {
    const selector = (licenseOption: keyof LicenseOptions) => createSelector(selectLicense, (license) => !!(license.options && license.options[licenseOption]));
    return select(selector(option));
}
