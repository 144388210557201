/* eslint-disable max-classes-per-file */
import {Action} from '@ngrx/store';

export const UPDATE_DIALOG_ID = '[placement] update dialog id';

export class UpdateDialogState implements Action {
    public readonly type = UPDATE_DIALOG_ID;

    constructor(public readonly formId: string) {
    }
}

export type DialogActions = UpdateDialogState;
