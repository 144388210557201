import {IwRestEntity} from '@app/sam-base/core/rest-api';

import {IwRestEntityId} from '../../core/rest-api/entity/rest-entity-id.decorator';

@IwRestEntity('sagstype')
export class Sagstype {
    @IwRestEntityId() public gstype?: string;
    public titre?: string;
    public titre2?: string;
    public titre3?: string;
    public titre4?: string;
    public titre5?: string;
}
