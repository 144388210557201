import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {IwStoreService} from '@app/sam-base/core/store';
import {isObject, isStringArray} from '@app/sam-base/helpers/guards';
import {Lookups} from '@app/sam-base/models/common';
import {ProfileService} from '@shared/profile/profile.service';
import {UserProfile} from '@shared/profile/user-profile.model';
import {AutoComplete, AutoCompleteCompleteEvent} from 'primeng/autocomplete';
import {Subscription} from 'rxjs';

type LookupsDisplay = keyof Lookups | (keyof Lookups)[] | ((e: Lookups) => string);

@Component({
    selector: 'iw-lookups-dropdown',
    templateUrl: './iw-lookups-dropdown.component.html',
    providers: [buildCustomValueProvider(IwLookupsDropdownComponent)]
})
export class IwLookupsDropdownComponent implements OnInit, ControlValueAccessor {

    @Input() public labelAlign: 'top' | 'left' | 'right' = 'top';

    @Input() public label = '';

    @Input() public noLabel = false;

    @Input() public warningMessage?: string;

    @Input() public size: 'small' | 'medium' | 'large' | 'auto' = 'medium';

    /** The string to show for each option */
    public display: LookupsDisplay = 'cvalue';

    @Input() public isDisabled = false;
    @Input() public selected?: Lookups;
    /** Field to use has id */
    @Input() public idField: keyof Lookups = 'cid';
    @Output() public selectedIdChange = new EventEmitter<string>();
    @Output() public valueChange = new EventEmitter<string>();
    @Output() public selectedChange = new EventEmitter<Lookups>();
    @ViewChild('item', {static: true}) public autoComplete?: AutoComplete;
    public items: Lookups[] = [];
    public dropdownOptions: Lookups[] = [];
    protected _onChange?: any;
    protected _subscription: Subscription | undefined;

    constructor(private readonly _store: IwStoreService, private elRef: ElementRef, private readonly _profile: ProfileService) {
    }

    public get value() {
        return this.selectedId;
    }

    @Input()
    public set value(v: string | undefined) {
        this.selectedId = v;
    }

    public get classValue() {
        return {
            [this.labelAlign]: true, ['size-' + this.size]: true
        };
    }

    protected _selectedId?: string;

    public get selectedId() {
        return this._selectedId;
    }

    @Input() /** The id of the selected option */ public set selectedId(id: string | undefined) {
        if (this._selectedId === id) {
            return;
        }
        this._selectedId = id;
        this.loadSelected();
    }

    protected _lkname = '';

    public get lkname() {
        return this._lkname;
    }

    @Input()
    public set lkname(v: string) {
        this._lkname = v;
        this.subscriveValues();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public ngOnInit() {
        this.loadLanguage();
    }

    public writeValue(obj: any): void {
        this.selectedId = obj;
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public getLabelValueGenerator() {
        return (e: Lookups) => this.getLabelValue(e);
    }

    // eslint-disable-next-line complexity
    public getLabelValue(e: Lookups) {
        switch (typeof this.display) {
            case 'function':
                return this.display(e);
            case 'string':
                return ((e || {})[this.display]) || ((e || {})['cvalue']) || '';
            case 'object':
                return this.generateLabelFromArray(e, this.display);
            default:
                return e;
        }
    }

    public onChange() {
        this._selectedId = this.getSelectedId();
        this.selectedChange.emit(this.selected);
        this.selectedIdChange.emit(this.selectedId);
        this.valueChange.emit(this.value);

        if (typeof this._onChange === 'function') {
            this._onChange(this.selectedId);
        }
    }

    // eslint-disable-next-line complexity
    public onBlur() {
        if (!this.selected && this.dropdownOptions) {
            const data = this.dropdownOptions;
            if (data && data.length === 1) {
                this.selected = data[0];
                this.onChange();
            }
        }
    }

    public onQueryChange({query}: AutoCompleteCompleteEvent) {
        if (!query) {
            this.dropdownOptions = [...this.items];
            return;
        }

        this.dropdownOptions = this.items
            .filter(l => this.compareEntityToQuery(l, query));
    }

    private loadLanguage() {
        this._profile.loadProfile()
            .subscribe((userProfile: UserProfile) => {
                this.mapCvalueToLanguage(ProfileService.langParser(userProfile?.lang));
            });
    }

    private mapCvalueToLanguage(locale?: string) {
        switch (locale) {
            case 'en':
                this.display = 'cvalue2';
                break;
            case 'de':
                this.display = 'cvalue3';
                break;
            case 'it':
                this.display = 'cvalue4';
                break;
            case 'es':
                this.display = 'cvalue5';
                break;
            default:
                this.display = 'cvalue';
                break;
        }
    }

    private getSelectedId() {
        if (isObject(this.selected)) {
            return (this.selected)[this.idField];
        }

        return undefined;
    }

    private loadSelected() {
        if (!this._selectedId && !!this.selected) {
            this.selected = undefined;
            this.selectedChange.emit(this.selected);
            return;
        }
        this.selected = this.items.find(e => e[this.idField] === this._selectedId);
        this.selectedChange.emit(this.selected);
    }

    private subscriveValues() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }

        this._subscription = this._store
            .lookups(this._lkname)
            .subscribe(r => {
                this.items = r;
                this.loadSelected();
            });
    }

    private generateLabelFromArray(e: Lookups, list: (keyof Lookups)[]): string {
        if (isStringArray(list)) {
            return list.map(s => e[s])
                .join(' ');
        }

        return '';
    }

    private compareEntityToQuery(e: Lookups, query: string): boolean {
        if (!query) {
            return true;
        }
        const value = '' + this.getLabelValue(e);
        return value.toLocaleLowerCase()
            .indexOf(query.toLocaleLowerCase()) !== -1;
    }

}
