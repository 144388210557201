import {Type} from '@angular/core';
import {PpacoView} from '@sam-base/models/placement/ppacoview';

import {Ppabs} from './ppabs';
import {Ppacattest} from './ppacattest';
import {Ppacattestview} from './ppacattestview';
import {Ppaco} from './ppaco';
import {Ppagence} from './ppagence';
import {Ppagi} from './ppagi';
import {Ppagidet} from './ppagidet';
import {Ppagiview} from './ppagiview';
import {Ppapt} from './ppapt';
import {Ppaptattrib} from './ppaptattrib';
import {Ppcct} from './ppcct';
import {Ppcctgrp} from './ppcctgrp';
import {Ppcde} from './ppcde';
import {Ppcdf} from './ppcdf';
import {Ppcds} from './ppcds';
import {Ppcfs} from './ppcfs';
import {Ppcha} from './ppcha';
import {Ppckw} from './ppckw';
import {Ppcli} from './ppcli';
import {Ppcliadr} from './ppcliadr';
import {Ppcliatt} from './ppcliatt';
import {Ppclicat} from './ppclicat';
import {Ppclicdes} from './ppclicdes';
import {Ppclicdfs} from './ppclicdfs';
import {Ppclimis} from './ppclimis';
import {Ppclipointeuse} from './ppclipointeuse';
import {Ppcnt} from './ppcnt';
import {Ppcon} from './ppcon';
import {Ppdoctype} from './ppdoctype';
import {Ppekw} from './ppekw';
import {Ppemp} from './ppemp';
import {Ppemp000} from './ppemp000';
import {Ppempapt} from './ppempapt';
import {Ppempaptattr} from './ppempaptattr';
import {Ppempcct} from './ppempcct';
import {Ppempdoc} from './ppempdoc';
import {Ppempdocview} from './ppempdocview';
import {Ppemprfid} from './ppemprfid';
import {Ppempsui} from './ppempsui';
import {Ppempview} from './ppempview';
import {Ppgrh} from './ppgrh';
import {Ppgri} from './ppgri';
import {Ppgrt} from './ppgrt';
import {Ppheu} from './ppheu';
import {Pphis} from './pphis';
import {Pphiscon} from './pphiscon';
import {Pphma} from './pphma';
import {Ppijfiohis} from './ppijfiohis';
import {Ppind} from './ppind';
import {Ppjf} from './ppjf';
import {Ppkw} from './ppkw';
import {Pplpp} from './pplpp';
import {Ppmis} from './ppmis';
import {Ppmisih} from './ppmisih';
import {Ppmismdl} from './ppmismdl';
import {Ppmissal} from './ppmissal';
import {Ppodos} from './ppodos';
import {Ppofa} from './ppofa';
import {Pposa} from './pposa';
import {Pppar} from './pppar';
import {Ppper} from './ppper';
import {Ppplf} from './ppplf';
import {Pppoint1} from './pppoint1';
import {Pppoint2} from './pppoint2';
import {Pppoint3} from './pppoint3';
import {Pppoint4} from './pppoint4';
import {Pppointfimp} from './pppointfimp';
import {Pppointj} from './pppointj';
import {Pppointrgen} from './pppointrgen';
import {Ppprasa} from './ppprasa';
import {Ppprasadec} from './ppprasadec';
import {Ppprasaempsal} from './ppprasaempsal';
import {Ppqua} from './ppqua';
import {Ppquacat} from './ppquacat';
import {Ppquagrp} from './ppquagrp';
import {Ppquasal} from './ppquasal';
import {Pprap} from './pprap';
import {Pprapihdet} from './pprapihdet';
import {Pprapihtot} from './pprapihtot';
import {Pprapview} from './pprapview';
import {Ppsecteur} from './ppsecteur';
import {Ppsmio} from './ppsmio';
import {Ppsmioage} from './ppsmioage';
import {Ppsmiolog} from './ppsmiolog';
import {Ppsuivi} from './ppsuivi';
import {Ppwxdocs} from './ppwxdocs';
import {ScheduleEvent} from './schedule-event';
import {Template} from './template';

export const ENTITIES: Type<any>[] = [
    Ppabs,
    Ppacattest,
    Ppaco,
    PpacoView,
    Ppagence,
    Ppagi,
    Ppagidet,
    Ppapt,
    Ppaptattrib,
    Ppcct,
    Ppcctgrp,
    Ppcde,
    Ppcdf,
    Ppcds,
    Ppcfs,
    Ppcha,
    Ppckw,
    Ppcli,
    Ppcliadr,
    Ppcliatt,
    Ppclicat,
    Ppclipointeuse,
    Ppcnt,
    Ppcon,
    Ppekw,
    Ppemp,
    Ppemp000,
    Ppempapt,
    Ppempaptattr,
    Ppempcct,
    Ppempdoc,
    Ppempdocview,
    Ppemprfid,
    Ppempsui,
    Ppgrh,
    Ppgri,
    Ppgrt,
    Ppheu,
    Pphis,
    Pphiscon,
    Pphma,
    Ppijfiohis,
    Ppind,
    Ppjf,
    Ppkw,
    Pplpp,
    Ppmis,
    Ppmisih,
    Ppmismdl,
    Ppmissal,
    Ppofa,
    Pposa,
    Ppodos,
    Ppper,
    Ppplf,
    Pppoint1,
    Pppoint2,
    Pppoint3,
    Pppoint4,
    Pppointfimp,
    Pppointj,
    Pppointrgen,
    Ppprasa,
    ScheduleEvent,
    Ppprasadec,
    Ppprasaempsal,
    Ppqua,
    Ppquacat,
    Ppquagrp,
    Ppquasal,
    Pprap,
    Pprapihdet,
    Pprapihtot,
    Ppsecteur,
    Ppsmio,
    Ppsmioage,
    Ppsmiolog,
    Ppsuivi,
    Ppwxdocs,
    Ppclimis,
    Ppclicdes,
    Ppdoctype,
    Pprapview,
    Ppagiview,
    Ppacattestview,
    Pppar,
    Template,
    Ppacattest,
    Ppclicdfs,
    Ppempview];

export type PlacementEntity =
    | Pplpp
    | Pppoint4
    | Ppemp
    | Ppacattest
    | Ppclipointeuse
    | Ppprasadec
    | Ppcde
    | Ppsmiolog
    | Ppprasa
    | Ppcliadr
    | Pprap
    | Ppekw
    | Ppaptattrib
    | Ppcon
    | Ppkw
    | Pposa
    | Ppgri
    | Pprapihdet
    | Ppcfs
    | Ppabs
    | Ppheu
    | Ppcdf
    | Pphma
    | Ppmisih
    | Ppmissal
    | Ppwxdocs
    | Ppquacat
    | Ppquagrp
    | Ppmis
    | Ppper
    | Ppempcct
    | Ppcds
    | Pppoint1
    | Ppempdoc
    | Ppempdocview
    | Ppemprfid
    | Pppoint2
    | Ppcliatt
    | Ppind
    | Ppmismdl
    | Ppcha
    | Ppempsui
    | Ppempaptattr
    | Pphiscon
    | Ppsuivi
    | Ppquasal
    | Ppsecteur
    | Pprapihtot
    | Ppclicat
    | Ppjf
    | Ppqua
    | Ppsmioage
    | Ppcct
    | Ppplf
    | Ppcli
    | Pppointrgen
    | Ppgrh
    | Ppckw
    | Pppointfimp
    | Ppemp000
    | Ppijfiohis
    | Ppagidet
    | Ppapt
    | Pphis
    | Ppsmio
    | Ppagence
    | Ppempapt
    | Ppprasaempsal
    | Ppgrt
    | Ppcnt
    | Ppofa
    | Pppointj
    | Pppoint3
    | Ppodos
    | Ppcctgrp
    | Ppagi
    | Ppagiview
    | Ppacattestview
    | Ppaco
    | Ppclimis
    | Ppclicdes
    | Ppdoctype
    | Pprapview
    | Pppar
    | Template
    | Ppacattest
    | Ppclicdfs
    | Ppempview;
