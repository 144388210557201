import {ConfigTemplate} from '@app/sam-base/core/config-template/models/config-template.model';
import {Action, createAction, props} from '@ngrx/store';

export const CONFIG_TEMPLATE_ADD = 'ADD CONFIG TEMPLATE';
export const CONFIG_TEMPLATE_ADD_SUCCESSFUL = 'ADD CONFIG TEMPLATE SUCCESSFUL';

export class CreateConfigTemplate<T> implements Action {
    public readonly type = CONFIG_TEMPLATE_ADD;

    constructor(public readonly payload: ConfigTemplate<T>) {
    }
}

export const configTemplateCreated = createAction(CONFIG_TEMPLATE_ADD_SUCCESSFUL, props<{
    config: ConfigTemplate<any>
}>());

export type ConfigTemplateActions<T> = CreateConfigTemplate<T>;
