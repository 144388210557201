import {Component, EventEmitter, Input, Output} from '@angular/core';

import {BadgeColors, BadgeSize} from './iw-badge.enums';

/**
 * Default badge component
 */

@Component({
    selector: 'iw-badge', templateUrl: './iw-badge.component.html', styleUrls: ['./iw-badge.component.scss']
})
export class IwBadgeComponent {

    @Output() public clicked: EventEmitter<void> = new EventEmitter();

    @Input() public value: string | number = 2;

    @Input() public color: BadgeColors = BadgeColors.blue;

    @Input() public size: BadgeSize = BadgeSize.normal;

    @Input() public tooltip = '';

    @Input() public tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'bottom';

    constructor() {
    }

    public badgePressed() {
        this.clicked.emit();
    }

}
