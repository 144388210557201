<div [ngClass]="className" class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" class="iw-form-group-label" for="item">{{ label | translate }}
    </div>
    <div class="p-inputgroup iw-input">
        <span class="p-inputgroup-addon"><span class="fa fa-search"></span></span> <input #control [(ngModel)]="value"
                                                                                          [placeholder]="placeholder | translate"
                                                                                          pInputText type="text"
                                                                                          width="250px">
    </div>
</div>
