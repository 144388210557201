import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('gecli')
export class Gecli {
    @IwRestEntityId() @IwColumn({
        name: 'id',
        index: 0,
        type: 'cliStatus'
    }) public cliId?: string;
    @IwColumn({
        name: 'nom',
        index: 1
    }) public nom?: string;
    public cliIndex?: string;
    public pgnIndex?: string;
    public mastcliId?: string;
    public ftrcliId?: string;
    @IwColumn({
        name: 'status',
        index: 2,
        type: 'status',
        valueFormat: 'ppcli_status_'
    }) public clistatustext?: string;
    public clistatus?: number;
    public ccliStatus?: string;
    public ccliStatus2?: string;
    public ccliStatu3?: string;
    public mfId?: string;
    public prefixe?: string;
    @IwColumn({
        name: 'adresse1',
        index: 3,
        hidden: true
    }) public adresse1?: string;
    @IwColumn({
        name: 'adresse2',
        index: 4,
        hidden: true
    }) public adresse2?: string;
    public adresse3?: string;
    @IwColumn({
        name: 'npa',
        index: 5,
        hidden: true
    }) public npa?: string;
    @IwColumn({
        name: 'lieu',
        index: 6
    }) public lieu?: string;
    public pays?: string;
    @IwColumn({
        name: 'tel1',
        index: 7,
        hidden: true
    }) public tel1?: string;
    @IwColumn({
        name: 'tel2',
        index: 8,
        hidden: true
    }) public tel2?: string;
    @IwColumn({
        name: 'natel',
        index: 9,
        hidden: true
    }) public natel?: string;
    @IwColumn({
        name: 'fax',
        index: 10,
        hidden: true
    }) public fax?: string;
    @IwColumn({
        name: 'email',
        index: 12
    }) public email?: string;
    public www?: string;
    public paicondId?: string;
    public langue?: number;
    public formejuri?: string;
    public crelimite?: number;
    public bvrMpaId?: string;
    public escompte?: number;
    public txRist?: number;
    public cptrist?: string;
    public cptprvrist?: string;
    public cliattId?: string;
    public cliadrId?: string;
    public facadrId?: string;
    public rpladrId?: string;
    public nbexfact?: number;
    public facfrx?: string;
    public facAttn?: string;
    public facfrxyb?: string;
    public sendrappel?: boolean;
    @IwColumn({
        name: 'fact_email',
        index: 11,
        type: 'boolean'
    })
    public facbyemail?: boolean;
    public facemail?: string;
    public facfrxem?: string;
    public rplfrx?: string;
    public rplAttn?: string;
    public rplbyemail?: boolean;
    public rplemail?: string;
    public rpladrmode?: number;
    public cligrpId?: string;
    public categories?: string;
    public categids?: string;
    public remarques?: string;
    public mutfactors?: string;
    public nonfactors?: boolean;
    public bnofactors?: string;
    public horsTva?: boolean;
    public tvaCodeht?: string;
    public fachdrrnd5?: boolean;
    public facelernd5?: boolean;
    public tvaNo?: string;
    public tvaNoIde?: string;
    public reeNo?: string;
    public USERTAG?: string;
    public kredchk?: boolean;
    public krchkdate?: Date;
    public krchrate?: number;
    public dtcalcsldm?: Date;
    public facxmlmode?: number;
    public ybactif?: boolean;
    public ebillacc?: string;
    public ybType?: number;
    public ybNote?: string;
    public ybData?: string;
    public lexpfac2ij?: boolean;
    public ifac2ijmod?: number;
    public ijFacmail?: string;
    public syncflag?: number;
    public cliData?: string;
    public xmlData?: string;
    public modCnt?: number;
    public modData?: string;
    public userId?: string;
    public dtAnyModif?: Date;
}
