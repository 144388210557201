import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Gepaihdr} from '@app/sam-base/models/invoices/gepaihdr';
import {
    CalcSoldeAndModeDataManyCli,
    GepaihdrDataManyCli
} from '@modules/sam-main/salary/components/encaissement/encaissement-attribution-many-cli.component';
import {
    CalcSoldeAndModeData,
    CountFacSelected,
    FacToExec,
    GepaihdrData,
    OpenInvoice
} from '@modules/sam-main/salary/components/encaissement/encaissement-attribution.component';
import {environment} from '@root/environments/environment';
import {GepaiCalcPayData} from '@sam-base/models/invoices/gepaicalcpaydata.model';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class GepaihdrService extends RestClient<Gepaihdr> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getByPayId(payId: string): Observable<Gepaihdr> {
        return this._http.get<Gepaihdr>(environment.backendURL + `gepaihdr/${payId}`, undefined);
    }

    public deleteSolde(payId: string): Observable<void> {
        return this._http.post<void>(environment.backendURL + `gepaihdr/deleteSolde/${payId}`, undefined);
    }

    public searchOpenFactures(cliId: string): Observable<OpenInvoice[]> {
        return this._http.get<OpenInvoice[]>(environment.backendURL + `gepaihdr/searchOpenFactures/${cliId}`, undefined);
    }

    public searchOpenFacturesManyCli(): Observable<OpenInvoice[]> {
        return this._http.get<OpenInvoice[]>(environment.backendURL + `gepaihdr/searchOpenFactures/`, undefined);
    }

    public calcPayDataSingleCli(gepaiCalcPayData: GepaiCalcPayData): Observable<any> {
        return this._http.post<any>(environment.backendURL + `gepaihdr/calcPayDataSingleCli`, gepaiCalcPayData);
    }

    public calcPayData(sMntPay: number, numberOfFacSelected: number, mntFacSelected: number,
                       lMiseEnCompte: boolean): Observable<any> {
        return this._http.get<any>(environment.backendURL + `gepaihdr/calcPayData/${sMntPay}/${numberOfFacSelected}/
        ${mntFacSelected}/${lMiseEnCompte}`, undefined);
    }

    public execLettrage1Cli(gepaihdrData: GepaihdrData): Observable<void> {
        return this._http.post<void>(this._baseUrl + `gepaihdr/execLettrageCli`, gepaihdrData);
    }

    public execLettrage(gepaihdrDataManyCli: GepaihdrDataManyCli): Observable<void> {
        return this._http.post<void>(this._baseUrl + `gepaihdr/execLettrage`, gepaihdrDataManyCli);
    }

    public countFacSelected(facToExecList: FacToExec[]): Observable<CountFacSelected> {
        return this._http.post<CountFacSelected>(environment.backendURL + `gepaihdr/countFacSelected`, facToExecList);
    }

    public calcSoldeAndMode1Cli(calcSoldeAndModeData: CalcSoldeAndModeData): Observable<void> {
        return this._http.post<void>(environment.backendURL + `gepaihdr/calcSoldeAndMode1Cli`, calcSoldeAndModeData);
    }

    public calcSoldeAndMode(calcSoldeAndModeDataManyCli: CalcSoldeAndModeDataManyCli): Observable<void> {
        return this._http.post<void>(environment.backendURL + `gepaihdr/calcSoldeAndMode`, calcSoldeAndModeDataManyCli);
    }

    public desattribuer(payId: string, cliId: string): Observable<void> {
        return this._http.post<void>(environment.backendURL + `gepaihdr/desattribuer/${payId}/${cliId}`, undefined);
    }

    public desattribuerCli(payId: string, cliId: string): Observable<void> {
        return this._http.post<void>(environment.backendURL + `gepaihdr/desattribuer/${payId}/${cliId}`, undefined);
    }

    public desattribuerManyCli(gepaihdr: Gepaihdr, facId: string, lAjusterTotalPaiement: boolean): Observable<void> {
        return this._http.post<void>(environment.backendURL + `gepaihdr/desattribuerManyCli/${facId}/${lAjusterTotalPaiement}`, gepaihdr);
    }

    public recalcPaiement(gepaihdr: Gepaihdr): Observable<void> {
        return this._http.post<void>(environment.backendURL + `gepaihdr/recalcPaiement`, gepaihdr);
    }

    public checkPaiement(gepaihdr: Gepaihdr, opeMode: string): Observable<boolean> {
        return this._http.post<boolean>(environment.backendURL + `gepaihdr/checkPaiement/${opeMode}`, gepaihdr);
    }

    public canDeleteGepaihdr(ded: Gepaihdr): Observable<boolean> {
        return this._http.post<boolean>(this._baseUrl + `gepaihdr/canDelete`, ded);
    }
}

