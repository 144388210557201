import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppcliatt} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-cliatt-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwCliattDropdownComponent)]
})
export class IwCliattDropdownComponent extends BaseEntityDropdown<Ppcliatt> {
    @Input() public width?: string | number;

    protected getEntityType() {
        return Ppcliatt;
    }

    protected buildLabel(e: Ppcliatt): string {
        return e.nomcomplet || '';
    }
}
