import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IwEventHubService} from '@sam-base/core/events';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {AgencyHandlerService} from '../services/agency-handler.service';

export const HEADER_AGENCY_ID = 'X-SUBTENANT-ID';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    /** Path to inject headers */
    private blacklist: string[] = ['/api'];

    /** Path to ignore */
    private whitelist: string[] = [
        '/api/config',
        '/api/tenant/migrated-gestions',
        '/api/translation/fr'];

    /** Path to ignore */
    private adminlist: string[] = [
        '/api/gesaexeview',
        '/api/saparass/',
        '/api/common/tt6',
        '/tenant/gestions',
        '/api/swissdec',
        '/api/sasx5dec',
        '/api/sasx5institution',
        '/api/employeeDeclaration',
        '/api/tenant/gestions'];

    constructor(private readonly _agencyService: AgencyHandlerService,
                private readonly _events: IwEventHubService<string>) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isBlacklist = this.inList(req.url, this.blacklist);
        const isWhitelist = this.inList(req.url, this.whitelist);

        if (isWhitelist || !isBlacklist) {
            return next.handle(req);
        }
        const adminSubtenantId = this._agencyService.adminSubtenantId;
        if (adminSubtenantId) {
            console.log('adminSubtenantId', adminSubtenantId);
            const isAdminList = this.inList(req.url, this.adminlist);
            if (isAdminList) {
                console.log('isAdminList', isAdminList);
                const authReq = req.clone({
                    headers: new HttpHeaders({[HEADER_AGENCY_ID]: adminSubtenantId})
                });
                return next.handle(authReq);
            } else {
                console.error(req);
                throw new Error('ERROR');
            }
        }

        return this._agencyService.loadAgencyId()
            .pipe(switchMap(id => {
                const authReq = req.clone({
                    headers: new HttpHeaders({[HEADER_AGENCY_ID]: id})
                });
                this._events.emit('request_done', id);
                return next.handle(authReq);
            }));
    }

    /** Check if URL is in list */
    private inList(url: string, pathList: string[]): boolean {
        // Normalize url to be matched by the pathList.
        if (!url.startsWith('/')) {
            url = '/' + url;
        }
        return pathList
            .filter(e => url.startsWith(e)).length > 0;
    }

}
