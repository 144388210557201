import {IwRestEntity, IwRestEntityId, SearchEntityStatusOperator} from '@app/sam-base/core/rest-api';

import {IwColumn} from '../../core/column/decorators/iw-column.decorator';

@IwRestEntity('sapardedview')
export class Sapardedview {
    @IwRestEntityId() public id?: string;

    @IwColumn({name: 'code', index: 0}) public dedCode?: string;
    @IwColumn({name: 'type', index: 1}) public dedType?: string;
    @IwColumn({name: 'titre', index: 2}) public dedTitre?: string;
    @IwColumn({
        name: 'active',
        index: 6,
        type: 'status',
        valueFormat: 'saparded_',
        statusQuery: [true, false],
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public dedActive?: number;
    @IwColumn({name: 'calcOrder', index: 7}) public dedOrder?: string;
    @IwColumn({name: 'auto', index: 8}) public tauxauto?: number;
    @IwColumn({name: 'calcul', index: 3}) public calcType?: string;
    @IwColumn({name: 'decompte', index: 4}) public decType?: string;
    @IwColumn({name: 'di', index: 5}) public dedorind?: string;
    @IwColumn({name: 'annee', index: 6}) public annee?: string;
    public dateDelet?: string;
    public dateModif?: string;
    public dateCreat?: string;
}
