<div [ngStyle]="style" class="iw-table-edit flex flex-h">
    <iw-rest-grid #grid (selected)="onSelect($event)" [autoFormClick]="false" [defaultSelected]="true"
                  [hasGridColumnMenu]="false" [hasSortIcon]="false" [isDisabled]="isEditMode" [queryStatements]="query"
                  [type]="entity" class="flex-grow padding-right-5"></iw-rest-grid>
    <div [ngStyle]="buttonStyle" class="flex-shrink flex flex-v flex-evenly">
        <ng-container *ngIf="!buttonIcons">
            <iw-button (press)="onCreateClick()" *ngIf="!isEditMode" [text]="'sidemenu_new' | translate"
                       class="btn-block"></iw-button>
            <iw-button (press)="onEditClick()" *ngIf="!isEditMode" [isDisabled]="btnEditDisabled"
                       [text]="'sidemenu_edit' | translate" class="btn-block"></iw-button>
            <iw-button (press)="onSaveClick()" *ngIf="isEditMode" [isDisabled]="!saveValid"
                       [text]="'sidemenu_save' | translate" class="btn-block"></iw-button>
            <iw-button (press)="onCancelClick()" *ngIf="isEditMode" [text]="'sidemenu_cancel' | translate"
                       class="btn-block"></iw-button>
            <iw-button (press)="onDeleteClick()" *ngIf="!isEditMode" [isDisabled]="btnDeleteDisabled"
                       [text]="'sidemenu_delete' | translate" class="btn-block"></iw-button>
        </ng-container>
        <ng-container *ngIf="buttonIcons">
            <iw-button *ngIf="!isEditMode" class="btn-block" iconClass="fas fa-file"></iw-button>
            <iw-button *ngIf="!isEditMode" [isDisabled]="btnEditDisabled" class="btn-block"
                       iconClass="fas fa-edit"></iw-button>
            <iw-button *ngIf="isEditMode" class="btn-block" iconClass="fas fa-save"></iw-button>
            <iw-button *ngIf="isEditMode" class="btn-block" iconClass="fas fa-ban"></iw-button>
            <iw-button *ngIf="!isEditMode" [isDisabled]="btnDeleteDisabled" class="btn-block"
                       iconClass="fas fa-trash"></iw-button>
        </ng-container>
    </div>
</div>
