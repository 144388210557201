import {EventEmitter, Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DialogStateService {
    public focusChange = new EventEmitter<string>();

    private _focusId?: string;

    public setFocusId(id?: string) {
        this._focusId = id;
        this.focusChange.emit(id);
    }

    /**
     * Removes the focus from the current element.
     *
     * This method sets the _focusId property to undefined and emits a focusChange event with undefined as the value.
     * Thus setting no dialog to focused
     *
     * @return {void}
     */
    public removeFocus() {
        this._focusId = undefined;
        this.focusChange.emit(undefined);
    }

    public getFocusId() {
        return this._focusId;
    }

}
