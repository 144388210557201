<div [ngClass]="className" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition"
     class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" class="iw-form-group-label" for="item" title="{{label | translate}}">{{ label | translate }}
    </div>
    <div class="input-container">
        <div [attr.data-suffix]="suffix" class="p-inputgroup iw-input">
            <p-inputMask #iwInputAvsMask *ngIf="!isUnknown" [(ngModel)]="value" [autoClear]="false"
                         [disabled]="isDisabled" [placeholder]="placeholder | translate" [unmask]="true"
                         class="col-xs-11 no-padding" mask="999.9999.9999.99"></p-inputMask>

            <input #control *ngIf="isUnknown" [placeholder]="'avs_is_set_to_unkkown' | translate" autocomplete="no"
                   class="col-xs-11 no-padding" disabled="true" pInputText>
            <p-checkbox [(ngModel)]="value" [binary]="true" [disabled]="isDisabled" [falseValue]="''"
                        [trueValue]="'UNKNOWN'" class="col-xs-1"></p-checkbox>
        </div>
        <div #TxtwarningMessage *ngIf="isInvalid && warningMessage">
            <small [style]="{'color': '#ff0000', 'font-size':'10px'}"
                   class="iw-input col-xs-12 padding-left-0"> {{ warningMessage | translate }} </small>
        </div>
        <div>
            <small [style]="{'font-size':'10px'}"
                   class="iw-input col-xs-12 padding-left-0"> {{ 'avs_hint_unknown' | translate }} </small>
        </div>
    </div>
</div>
