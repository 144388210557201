<div (dblclick)="openEvent()" *ngIf="mode === 'monthly'" [ngStyle]="style" class="col-xs cell iw-strong"
     title="{{title}}">
    {{ labelName }}
</div>
<div *ngIf="mode === 'weekly'" class="col-xs cell no-padding" style="height: 35px; width: 168px;">
    <div *ngFor="let slot of schedule; let i = index" class="row no-padding no-margin"
         style="border: 1px solid lightgray; height: inherit; width: 7px;">
        <div *ngIf="slot === 1" [ngStyle]="style" class="no-padding"></div>
    </div>
</div>
