<div [ngStyle]="ngStyle" class="row padding-0">
    <div class="table-content col-xs-9 padding-0">
        <iw-table [(selected)]="selectedItems" [columns]="tableColumn" [data]="listValues" [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"></iw-table>
    </div>
    <div class="col-xs-3 padding-right-0 flex flex-v flex-between">
        <div class="flex flex-v flex-between" style="height: 50px;">
            <iw-button (press)="onSuprimirClick()" [isDisabled]="selectedItems.length === 0"
                       [text]="'supprimer' | translate" class="btn-block"></iw-button>
            <iw-button (press)="onRazClick()" [text]="'raz' | translate" class="btn-block"></iw-button>
        </div>
        <iw-button (press)="closed.emit()" *ngIf="showClose" [text]="'fermer' | translate"
                   class="btn-block"></iw-button>
    </div>
</div>
