import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('schedule_event')
export class ScheduleEvent {

    @IwRestEntityId() public id?: number;
    @IwColumn({name: 'schedule_event_date_schedule', index: 3, type: 'timeslot'}) public schedule?: (0 | 1)[];
    @IwColumn({
        name: 'schedule_event_weekday', index: 2, type: 'weekday'
    }) public weekday?: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
    @IwColumn({name: 'schedule_event_date_start', index: 0, type: 'date'}) public dateStart?: string;
    @IwColumn({name: 'schedule_event_date_end', index: 1, type: 'date'}) public dateEnd?: string;
    public available?: boolean;
    @IwColumn({name: 'abs_code', index: 4}) public absType?: string;
    public parentId?: string;
    public parentType?: 'ppemp' | 'ppmis' | 'ppcde';
    public flexible?: boolean;
    public weeklyHours?: number;
    public ocupation?: number;
    public flexMisid?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public dateTrack?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public additionalText?: string;
}
