import {Component, ElementRef, Input} from '@angular/core';
import {BaseEntityDropdown, buildCustomValueProvider} from '@app/sam-base/base';
import {Template} from '@sam-base/models/placement';


@Component({
    selector: 'iw-template-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwTemplateDropdownComponent)]
})
export class IwTemplateDropdownComponent extends BaseEntityDropdown<Template> {
    @Input() public width?: string | number;
    @Input() public warningMessage?: string;
    @Input() public payTypeFilter?: number[];
    public selectedValue = undefined;

    constructor(private elRef: ElementRef) {
        super();
    }

    @Input()
    public set report(v: string) {
        if (v.length) {
            this.resultFilters = v ? (e: Template) => v === e.report : (_: Template) => true;
        } else {
            this.resultFilters = (e) => true;
        }
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType() {
        return Template;
    }

    protected buildLabel(e: Template): string {
        return e.name || '';
    }
}
