import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('gecliadr')
export class Gecliadr {
    @IwRestEntityId() public cliadrId?: string;
    public cliId?: string;
    @IwColumn() public adrlib?: string;
    public prefixe?: string;
    public nom?: string;
    public adresse1?: string;
    public adresse2?: string;
    public adresse3?: string;
    public npa?: string;
    public lieu?: string;
    public pays?: string;
}
