import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

import {runEventHandlers} from './iw-event-handler.decorator';

export interface IwEventResult<T, P> {
    type: T;
    payload?: P;
}

export interface IwEventOpenModalPayload<T> {
    report: string;
    entityId?: string | number;
    entityName: string;
    entity: T;
    typeOfPrint?: string;
    templateId?: number;
}

@Injectable()
export class IwEventHubService<T extends string> {
    private _eventEmitter = new EventEmitter<IwEventResult<T, any>>();

    public get events() {
        return this._eventEmitter.pipe();
    }

    public emit<P>(type: T, payload?: P): void;
    public emit(type: T, payload?: any) {
        if (!type) {
            return;
        }
        this._eventEmitter.emit({
            type,
            payload
        });
        runEventHandlers(type, payload);
    }

    /** Listening for event that match type */
    public forType<P>(...types: T[]): Observable<IwEventResult<T, P>>;
    public forType(...types: T[]): Observable<IwEventResult<T, any>> {
        return this._eventEmitter
            .pipe(filter(e => types.indexOf(e.type) !== -1));
    }
}
