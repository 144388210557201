export interface MsgMap {
    [key: string]: () => string
}

export interface DeleteWarningMap {
    [key: string]: () => string | string[]
}


export type EntityEndpoints =
    'employee'
    | 'mission'
    | 'client'
    | 'commande'
    | 'commandefixe'
    | 'suivi'
    | 'acompte'
    | 'indemnite'
    | 'rapport'
    | 'ppplf'
    | 'sags'
    | 'saempc'
    | 'saparded'
    | 'gepaihdr'
    | 'gefachdr';

export const deletableEntities = [
    'ppmis',
    'ppemp',
    'ppcli',
    'ppcde',
    'ppcdf',
    'ppsuivi',
    'ppind',
    'ppaco',
    'pprapview',
    'ppplf',
    'sags',
    'saempc',
    'saparded',
    'gepaihdr',
    'gefachdr'
];

export const deleteWarningMap: DeleteWarningMap = {
    employee: () => 'delete_employee',
    mission: () => 'delete_mission',
    client: () => 'delete_client',
    commande: () => 'delete_commande',
    commandefixe: () => 'delete_commande',
    suivi: () => 'delete_suivi',
    acompte: () => 'delete_acompte',
    indemnite: () => 'delete_indemnite',
    rapport: () => [
        'delete_rapport_1',
        'delete_rapport_2'],
    ppplf: () => 'delete_ppplf',
    sags: () => 'delete_genre_salaire',
    saempc: () => 'delete_saempc',
    saparded: () => 'delete_saparded',
    gepaihdr: () => 'delete_gepaihdr',
    gefachdr: () => 'delete_gefachdr'
};

export const empMsgMap: MsgMap = {
    ppmis: () => 'employee_has_missions',
    ppind: () => 'employee_has_indemnites',
    ppaco: () => 'employee_has_acomptes',
    ppsuivi: () => 'employee_has_suivis'
};

export const cliMsgMap: MsgMap = {
    ppmis: () => 'client_has_missions',
    ppcde: () => 'client_has_commandes',
    ppsuivi: () => 'client_has_suivis'
};

export const misMsgMap: MsgMap = {
    pprap: () => 'mission_has_rapports',
    ppsuivi: () => 'mission_has_suivis'
};

export const cmdMsgMap: MsgMap = {
    ppmis: () => 'commande_has_missions'
};

export const cmdFixeMsgMap: MsgMap = {
    ppplf: () => 'commande_has_ppplf'
};

export const acoMsgMap: MsgMap = {
    payed: () => 'acompte_is_payed',
    salary: () => 'acompte_has_salary'
};

export const indMsgMap: MsgMap = {
    salary: () => 'indemnite_has_salary'
};

export const rapMsgMap: MsgMap = {
    invoice: () => 'rapport_has_invoice',
    salary: () => 'rapport_has_salary'
};

export const ppplfMsgMap: MsgMap = {
    invoice: () => 'ppplf_has_invoice'
};

export const sagsMsgMap: MsgMap = {
    used: () => 'genre_salaire_used'
};

export const saempcMsgMap: MsgMap = {};

export const sapardedMsgMap: MsgMap = {
    default: () => 'saparded_is_default'
};
export const gepaihdrMsgMap: MsgMap = {
    used: () => 'gepaihdr_is_used'
};
export const gefachdrMsgMap: MsgMap = {
    'business-error.invoice.wrong-status': () => 'business-error.invoice.wrong-status',
    'business-error.invoice.delete.pt': () => 'business-error.invoice.delete.pt'
};
