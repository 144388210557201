/* eslint-disable max-classes-per-file */
import {Type} from '@angular/core';
import {Action} from '@ngrx/store';

import {EntitySelectState} from '../models/entity-select.state';

/** Start select state */
export const ENTITY_SELECT_INIT = '[ENTITY SELECT] initialize selection';
/** Init completed */
export const ENTITY_SELECT_INIT_SUCCESS = '[ENTITY SELECT] initialize success';
/** When a item is selected */
export const ENTITY_SELECT_SUCCESS = '[ENTITY SELECT] select success';
/** When a selection is canceled */
export const ENTITY_SELECT_CANCEL = '[ENTITY SELECT] select cancel';
/** Mark selection as completed */
export const ENTITY_SELECT_COMPLETE = '[ENTITY SELECT] completed';

/** Start selection proccess */
export class EntitySelectInit<T> implements Action {
    public readonly type = ENTITY_SELECT_INIT;

    constructor(public readonly state: EntitySelectState<T>) {
    }
}

/** Trigger selected items */
export class EntitySelectInitComplete<T> implements Action {
    public readonly type = ENTITY_SELECT_INIT_SUCCESS;

    constructor(public readonly entityType: Type<T>) {
    }
}

/** Trigger selected items */
export class EntitySelectSuccess<T> implements Action {
    public readonly type = ENTITY_SELECT_SUCCESS;

    constructor(public readonly entityType: Type<T>, public readonly selected: T[]) {
    }
}

/** Cancel select proccess */
export class EntitySelectCancel<T> implements Action {
    public readonly type = ENTITY_SELECT_CANCEL;

    constructor(public readonly entityType: Type<T>) {
    }
}

/** Complete select proccess */
export class EntitySelectComplete<T> implements Action {
    public readonly type = ENTITY_SELECT_COMPLETE;

    constructor(public readonly entityType: Type<T>) {
    }
}

export type EntitySelectAction<T> =
    | EntitySelectInit<T>
    | EntitySelectSuccess<T>
    | EntitySelectCancel<T>
    | EntitySelectComplete<T>
    | EntitySelectInitComplete<T>;
