import {Type} from '@angular/core';

import {OptionalDictionary} from '../../../models';
import {isRestEntity} from '../entity/rest-entity-guards';

/** Class to store instances of Rest clients to avoid duplicated instaciation */
export abstract class RestServiceStore<TService> {
    private readonly _instances: OptionalDictionary<TService> = {};
    private readonly _namedType: OptionalDictionary<Type<any>> = {};

    public register<T>(t: Type<T>) {
        const name = this.extractName(t);
        this._namedType[name] = t;
    }

    public getService<T>(t: Type<T>): TService {
        const name = this.extractName(t);
        let instance = this._instances[name];
        if (instance) {
            return instance;
        } else {
            instance = this.factory(t);
            this._instances[name] = instance;
            return instance;
        }
    }

    public getServiceByName(name: string): TService {
        const type = this._namedType[name];
        if (!type) {
            throw new Error(`Name [${name}] is not a registered type`);
        }
        return this.getService(type);
    }

    protected abstract factory<T>(t: Type<T>): TService;

    protected extractName<T>(t: Type<T>) {
        const instance = new t();
        if (isRestEntity(instance)) {
            return instance.$entity + (instance.$prefix || '');
        } else {
            throw new Error('Object is not an valid entity');
        }
    }
}
