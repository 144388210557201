import {Component, Input, Type} from '@angular/core';
import {BaseEntityDropdown, buildCustomValueProvider} from '@app/sam-base/base';
import {ContactNcs} from '@app/sam-base/models/salary/contact-ncs.model';

@Component({
    selector: 'iw-contact-ncs-combo-box',
    templateUrl: './contact-ncs-combo-box.component.html',
    providers: [buildCustomValueProvider(ContactNcsComboBoxComponent)]
})
export class ContactNcsComboBoxComponent extends BaseEntityDropdown<ContactNcs> {

    @Input() public noLabel = false;

    protected getEntityType(): Type<ContactNcs> {
        return ContactNcs;
    }

    protected buildLabel(e: ContactNcs) {
        return `${e.fullName} - ${e.department}`;
    }
}
