import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppcct} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-ppcct-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwPpcctDropdownComponent)]
})
export class IwPpcctDropdownComponent extends BaseEntityDropdown<Ppcct> {
    @Input() public width?: string | number;

    protected getEntityType() {
        return Ppcct;
    }

    protected buildLabel(e: Ppcct): string {
        return e.cctLib || '';
    }
}
