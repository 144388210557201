<div [ngClass]="className" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition"
     class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" class="iw-form-group-label" for="item" title="{{label | translate}}">{{ label | translate }}
    </div>
    <div class="input-container">
        <div [attr.data-suffix]="suffix" class="p-inputgroup iw-input">
            <ng-container *ngIf="!!icon">
                <span class="p-inputgroup-addon"><span class="fa fa-{{icon}}"></span></span>
            </ng-container>
            <input #control (blur)="onBlur()" (focus)="onFocus()" *ngIf="mask" [(ngModel)]="value"
                   [disabled]="isDisabled" [dropSpecialCharacters]="false" [mask]="mask" [ngClass]="inputClass"
                   [placeholder]="placeholder | translate" [readOnly]="isReadOnly" [type]="'text'" autocomplete="no"
                   pInputText width="100%"> <input #control (blur)="onBlur()" (focus)="onFocus()" *ngIf="!mask"
                                                   [(ngModel)]="value" [disabled]="isDisabled" [ngClass]="inputClass"
                                                   [placeholder]="placeholder | translate" [readOnly]="isReadOnly"
                                                   [type]="'text'" autocomplete="no" pInputText width="100%">
        </div>
        <div #TxtwarningMessage *ngIf="isInvalid && warningMessage">
            <small class="iw-input col-xs-12 padding-left-0"
                   style="color: #ff0000; font-size:10px"> {{ warningMessage | translate }} </small>
        </div>
    </div>
</div>
