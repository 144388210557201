import {Directive, Input, ElementRef, AfterViewInit} from '@angular/core';

@Directive({
    selector: '[iwAutofocus]'
})
export class IwAutofocusDirective implements AfterViewInit {
    private _enabled = false;

    constructor(private el: ElementRef) {
    }

    @Input()
    public set iwAutofocus(condition: boolean) {
        this._enabled = condition;
    }


    public ngAfterViewInit(): void {
        if (this._enabled) {
            this.el.nativeElement.focus();
        }
    }
}
