import {Type} from '@angular/core';
import {GridProfile} from '@app/sam-base/core/grid-profile/models';
import {EsQueryStatement} from '@app/sam-base/core/rest-api';
import {OptionalDictionary} from '@app/sam-base/models';

import {DynamicPrintParams} from './dynamic-print-request.model';
import {PrintTemplate} from './print-template.model';

export type PrintFormat = 'xls' | 'pdf';

export interface BasePrintEntity {
    format: PrintFormat;
    parameters: OptionalDictionary<string>;
}

export interface PrintDynamicEntity<T> extends BasePrintEntity {
    group: GridProfile<T>;
    extraStatements: EsQueryStatement<T>[];
    parameters: DynamicPrintParams;
    entity: Type<T>;
}

export interface PrintReportEntity<T> extends BasePrintEntity {
    template: PrintTemplate;
    entity: T | Type<T>;
    entityId?: string | number;
}

export interface PrintReport extends BasePrintEntity {
    templateName: string;
}

export type PrintEntity<T> = PrintDynamicEntity<T> | PrintReportEntity<T> | PrintReport;

export function isDynamicPrint<T>(input: PrintEntity<T>): input is PrintDynamicEntity<T> {
    return input && (<PrintDynamicEntity<T>>input).group instanceof GridProfile;
}

export function isPrintReportEntity<T>(input: PrintEntity<T>): input is PrintReportEntity<T> {
    return input && (<PrintReportEntity<T>>input).template instanceof PrintTemplate;
}
