import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppaptattrib')
export class Ppaptattrib {
    @IwRestEntityId() public aptattrId?: string;
    public titre?: string;
    public aptId?: string;
    public attrType?: string;
    public attrGenre?: string;
}
