import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('sads')
export class Sads {
    @IwRestEntityId() public dsId?: string;
    public empId?: string;
    public gsId?: string;
    public annee?: string;
    public base?: number;
    public montant?: number;
    public frequence?: number;
    public lfacteurs?: boolean;
    public ifact?: number;
    public ifactfull?: number;
    public ifact2?: number;
    public ifact2full?: number;
    public cwhen?: string;
    public texte?: string;
    public ldivimois?: boolean;
    public nbmois?: number;
    public iroundmode?: number;
    public debut?: Date;
    public fin?: Date;
    public salIdDe?: string;
    public salIdA?: string;
    public centre?: string;
    public cptdebit?: string;
    public cptcredit?: string;
    public userId?: string;
    public dtanymodif?: Date;

}
