/**
 * Mark the property as Id to be used
 * by the restEntityClient
 */
export function IwRestEntityId(): PropertyDecorator {
    return (target: Object, name: string | symbol) => {
        target.constructor.prototype.$pk = name;
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        target.constructor.prototype.$getPk = function () {
            return this[this.$pk];
        };
    };
}

/**
 * @param composedId Defined as the url path to be requested
 * @returns the property decorator Object with the prototype cointaning $pk as defined
 * and a function $getPk() that evaluates the decomposed pk list so it dynamically
 * replaces the values of the ids
 */
export function IwRestEntityIds(composedId: string): PropertyDecorator {
    return (target: Object) => {

        target.constructor.prototype.$pk = composedId;

        target.constructor.prototype.$getPk = function () {
            const decomposedPkList = this.$pk.split('/');
            let pkValueComposition = '';
            for (const index of decomposedPkList.keys()) {
                pkValueComposition += this[decomposedPkList[index]] + '/';
            }

            /** Remove last '/' and return evaluated composed keys */
            return pkValueComposition.slice(0, -1);
        };
    };
}
