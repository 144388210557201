import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppdoctype')
export class Ppdoctype {
    @IwRestEntityId() @IwColumn({index: 0, name: 'ppdoctype_pdtId'}) public pdtId?: string;
    @IwColumn({index: 3, name: 'ppdoctype_dateTrack', type: 'date'}) public dateTrack?: string;
    @IwColumn({index: 2, type: 'docTranslate', name: 'ppdoctype_partype'}) public pdtPartype?: string;
    @IwColumn({index: 1, name: 'ppdoctype_libelle'}) public pdtLibelle?: string;
    public mandatory?: boolean;
}
