<div [formGroup]="formGroup" class="row">
    <iw-npa-textfield #txtNpa (relatedLieu)="updateRelatedLieu($event)" [formControlName]="npaFormControlName"
                      [isDisabled]="isDisabled" [labelAlign]="labelNpaAlign" [label]="labelNpa"
                      class="iw-input col-xs-4 no-padding"></iw-npa-textfield>

    <ng-container *ngIf="labelNpaAlign === 'top'; else noLabel">
        <iw-dropdown #drpLieu *ngIf="isCH" [formControlName]="lieuFormControlName" [isDisabled]="isDisabled"
                     [labelAlign]="'top'" [label]="' '" [options]="optionsNpa"
                     class="iw-input col-xs-8 no-padding"></iw-dropdown>
        <iw-textfield #drpLieu *ngIf="!isCH" [formControlName]="lieuFormControlName" [isDisabled]="isDisabled"
                      [labelAlign]="'top'" [label]="' '" class="iw-input col-xs-8 no-padding"></iw-textfield>
    </ng-container>
    <ng-template #noLabel>
        <iw-dropdown #drpLieu *ngIf="isCH" [formControlName]="lieuFormControlName" [isDisabled]="isDisabled"
                     [options]="optionsNpa" class="iw-input col-xs-8 no-padding"></iw-dropdown>
        <iw-textfield #drpLieu *ngIf="!isCH" [formControlName]="lieuFormControlName" [isDisabled]="isDisabled"
                      class="iw-input col-xs-8 no-padding"></iw-textfield>
    </ng-template>
</div>
