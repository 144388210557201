import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Ppper} from '@app/sam-base/models/placement';
import {ParametersService} from '@core/services/parameters.service';

import {GeperService} from '@sam-base/core/services/geper.service';
import {SagenService} from '@sam-base/core/services/sagen.service';
import {Sapar} from '@sam-base/models/salary';
import {Sagen} from '@sam-base/models/salary/sagen';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderToolbarService extends RestClient<{}> {

    constructor(http: HttpClient,
                private _parametersService: ParametersService, private _sagenService: SagenService,
                private _geperService: GeperService) {
        super(http);
    }

    public getActivePeriod(): Observable<Ppper> {
        return this.GET(undefined, 'period', 'active');
    }

    public getActiveExercise(): Observable<Sapar> {
        return this._parametersService.getCurrentSapar();
    }

    public getSagen(salId: string): Observable<Sagen> {
        return this._sagenService.getSagenBySalId(salId);
    }

    public isGeperSalIdActive(salId: string): Observable<boolean> {
        return this._geperService.checkFacDate(salId);
    }

}
