import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ListItem} from './../../form-components/list-box/list-item.model';

@Component({
    selector: 'iw-modal-selection-lists', templateUrl: './modal-selection-lists.component.html'
})
export class ModalSelectionListsComponent {

    @Input() public title = '';

    @Input() public firstList: ListItem<string>[] = [];

    @Input() public secondList: ListItem<string>[] = [];

    @Output() public pressOk = new EventEmitter<ListItem<string>[]>();

    @Output() public pressCancel = new EventEmitter<void>();

    constructor() {
    }

    public onPressOk() {
        this.pressOk.emit(this.secondList);
    }

    public onPressCancel() {
        this.pressCancel.emit();
    }
}
