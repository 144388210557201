import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(private keycloakService: KeycloakService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Disable login for assets folder
        if (req.url.indexOf('/assets') !== -1 || req.url.indexOf('/config') !== -1) {
            return next.handle(req);
        }

        if (this.keycloakService.isLoggedIn()) {
            return next.handle(req);
        }
        return from(this.keycloakService.login())
            .pipe(switchMap(() => next.handle(req)));
    }

}
