import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {IsSalaryCalculableResp} from '@modules/sam-main/salary/components/fiche-salaire/SalAllowResponce';
import {Saems} from '@sam-base/models/salary/saems';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';


@Injectable({providedIn: 'root'})
export class SalaryService {

    constructor(private readonly _restApiService: RestApiService) {
    }

    public calculate(saemsId: string): Observable<Saems> {
        return this._restApiService.http
            .post<Saems>(environment.backendURL + `salary/calculate/${saemsId}`, null);
    }

    public publish(saemsId: string): Observable<Saems> {
        return this._restApiService.http
            .post<Saems>(environment.backendURL + `salary/publish/${saemsId}`, null);
    }

    public sendByMail(saemsId: string) {
        return this._restApiService.http
            .post<Saems>(environment.backendURL + `salary/send-by-email/${saemsId}`, null);
    }

    public download(saemsIds: string[]) {
        return this._restApiService.http
            .post(environment.backendURL + 'salary/download', saemsIds, {
                observe: 'response',
                responseType: 'blob'
            })
            .pipe(map((res: HttpResponse<Blob>) => {
                let blob = new Blob([res.body!], {type: res.headers.get('Content-Type') || ''});
                let fileUrl = URL.createObjectURL(blob);

                let link = document.createElement('a');
                link.href = fileUrl;

                let contentDisposition = res.headers.get('Content-Disposition');
                let filename = contentDisposition?.slice(contentDisposition?.indexOf('=') + 1);
                link.download = filename ?? '';

                link.click();
                URL.revokeObjectURL(link.href);
            }));
    }

    // eslint-disable-next-line max-len
    public checkCreateFicheSalaryPossible(empId: string, salId: string): Observable<IsSalaryCalculableResp> {
        return this._restApiService.http.get<IsSalaryCalculableResp>(environment.backendURL + `salary/calculate/${empId}/${salId}`);
    }

}
