import {flatten} from 'lodash';
import {forkJoin, lastValueFrom, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {RestEntityParams, RestPagination, RestResponse} from '../models';
import {RestEntityClient} from './rest-entity-client';

/**
 * Provides navigation in a entity list
 */
export class RestEntityNavigation<T> {
    protected _currentPage = 0;
    protected _totalItems = 0;
    protected _pageSize = 15;
    protected _sort = '';
    protected _loadedPages: Array<T[]> = [];

    constructor(protected readonly _entityService: RestEntityClient<T>) {
    }

    public get currentPageItems(): T[] {
        return this._loadedPages[this._currentPage];
    }

    public get entityName() {
        return this._entityService.EntityName;
    }

    protected _totalPages = 0;

    public get totalPages() {
        return this._totalPages;
    }

    public static async initNavigation<T>(service: RestEntityClient<T>, pageSize?: number) {
        const nav = new RestEntityNavigation(service);
        await lastValueFrom(nav.getList(pageSize));
        return nav;
    }

    public async setSort(sort: string) {
        return this.setPageSizeAndSort(this._pageSize, sort);
    }

    public async setPageSize(pageSize: number) {
        return this.setPageSizeAndSort(pageSize, this._sort);
    }

    public async setPageSizeAndSort(pageSize: number, sort: string) {
        if (!pageSize) {
            return;
        }
        this._pageSize = pageSize;
        this._sort = sort;
        this._currentPage = 0;
        this.clearCached();
        await lastValueFrom(this.getList());
    }

    /** Get list of items for current page */
    public getList(pageSize?: number) {
        if (pageSize) {
            this._pageSize = pageSize;
        }
        return this.loadCurrentPage();
    }

    /** Return all items of all pages */
    public getAllItems(): Observable<T[]> {
        return this.loadPage(0) // Load the first page to access the number of pages
            .pipe(mergeMap(initial => {
                const requests: Observable<T[]>[] = [of(initial)];
                const pages = this._totalPages;
                for (let p = 1; p < pages; p++) {
                    requests.push(this.loadPage(p));
                }

                return forkJoin(requests)
                    .pipe(map(e => flatten(e)));
            }));
    }

    /** Jump to a specific page */
    public jumpTo(page: number) {
        if (this._totalPages === 0) {
            return this.loadCurrentPage();
        }

        this.changePage(page);
        return this.loadCurrentPage();
    }

    /** Jump to page relative to [size] number */
    public navigate(size: number) {
        if (this._totalPages === 0) {
            return this.loadCurrentPage();
        }

        this.naviagtePage(size);
        return this.loadCurrentPage();
    }

    /** Clear all loaded pages */
    public clearCached() {
        this._loadedPages = [];
    }

    protected loadCurrentPage() {
        return this.loadPage(this._currentPage);
    }

    protected loadPage(pageNumber: number) {
        const elems = this._loadedPages[pageNumber];
        if (elems) {
            return of(elems);
        }

        return this._entityService
            .getList(this.getReqParams(pageNumber))
            .pipe(map(r => this.mapRequest(r, pageNumber)));
    }

    protected getReqParams(page: number): RestEntityParams {
        return {
            page: page.toString(),
            size: this._pageSize.toString(),
            sort: this._sort
        };
    }

    protected mapRequest(response: RestResponse<T>, page: number) {
        let elem: T[] | undefined;
        if (response._embedded) {
            elem = response._embedded && response._embedded[this.entityName];
        } else if (response.content) {
            elem = response.content
        }
        if (!elem) {
            throw new Error(`Can't find entity: ${this.entityName}`);
        }

        const p = response.page;
        this.setPaging(p);
        this._loadedPages[page] = elem.map(e => this._entityService.construct(e));

        return elem;
    }

    /**
     * Change current page by [value]
     *
     * @param value number of pages to move
     */
    protected naviagtePage(value: number) {
        const page = this._currentPage + value;
        this.changePage(page);
    }

    /**
     * Change current page and make sure it is in boundaries
     *
     * @param page number of page to move
     */
    protected changePage(page: number) {
        if (page >= this._totalPages) {
            page = this._totalPages - 1;
        }

        if (page < 0) {
            page = 0;
        }

        this._currentPage = page || 0;
    }

    private setPaging(p: RestPagination) {
        if (!!p) {
            if (!!p.totalPages) {
                this._totalPages = p.totalPages;
            } else {
                this._totalPages = 1;
            }
            this._totalItems = p.totalElements;
        }
    }
}
