import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppcha')
export class Ppcha {
    @IwColumn({name: 'id'}) @IwRestEntityId() public clichaId?: string;
    public pays?: string;
    public cliId?: string;
    public chaNote?: string;
    public dateDelet?: string;
    public userId?: string;
    public lieu?: string;
    public chaData?: string;
    public dateModif?: string;
    public userCreat?: string;
    public actif?: string;
    public dateCreat?: string;
    public userDelet?: string;
    public geoLon?: string;
    public chafacref?: string;
    public adresse1?: string;
    public geoLat?: string;
    public geoRad?: string;
    public npa?: string;
    @IwColumn({name: 'chantier'}) public chantier?: string;
    public userModif?: string;
}
