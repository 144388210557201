import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppsmio')
export class Ppsmio {
    @IwRestEntityId() public smioId?: string;
    public userModif?: string;
    public ageTo?: string;
    public keyvalue?: string;
    public iotype?: string;
    public smiomode?: string;
    public userDelet?: string;
    public ageFrom?: string;
    public tableno?: string;
    public userCreat?: string;
    public smioflag?: string;
}
