import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pppointfimp')
export class Pppointfimp {
    @IwRestEntityId() public ptficId?: string;
    public fcontent?: string;
    public cerrors?: string;
    public manycli?: string;
    public fname?: string;
    public userImp?: string;
    public pointtype?: string;
    public dtImp?: string;
    public ficstatus?: number;
    public cliId?: string;
    public ficData?: string;
}
