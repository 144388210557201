import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {IwContextMenuClass} from '@sam-base/core';
import {gefacliContextMenu} from '@sam-base/models/invoices/context-menu/gefacliview-context-menu';
import {Gefachdr} from '@sam-base/models/invoices/gefachdr';

export enum GefacStatus {
    OPEN = "0", CLOSE = "1", WRONG = "9"
}

export function factureStatusColorMapper(row: Gefacliview | Gefachdr): string {
    if (row.facStatus === GefacStatus.OPEN) {
        if (!row.nbOfPrint && !row.nbOfMail) {
            return "#008000";
        } else {
            return "#80ff80"
        }
    } else if (row.facStatus === GefacStatus.CLOSE) {
        return "#C0C0C0";
    }
    return '';
}

@IwRestEntity('gefacliview') @IwContextMenuClass(...gefacliContextMenu)
export class Gefacliview {
    @IwRestEntityId() @IwColumn({
        name: 'idFacture',
        index: 11,
        hidden: true,
    }) public facId?: string;

    @IwColumn({
        name: 'facornc',
        index: 3
    }) public facornc?: string;

    @IwColumn({
        name: 'facNo',
        index: 0,
        type: 'number',
        colorMapper: (row: Gefacliview) => {
            return factureStatusColorMapper(row);
        }
    }) public facNo?: string;

    @IwColumn({
        name: 'status',
        index: 1,
        type: 'status',
        valueFormat: 'facture_status_'
    })
    public facStatus?: GefacStatus;


    @IwColumn({
        name: 'date_facture',
        type: 'date',
        index: 6
    }) public dateFac?: string;

    @IwColumn({
        name: 'date_cpta',
        type: 'date',
        index: 7
    }) public dateCpta?: string;

    @IwColumn({
        name: 'published',
        index: 8,
        type: 'boolean'
    })
    public published?: boolean;


    @IwColumn({
        name: 'cliNomFactureNC',
        index: 4
    }) public nom?: string;

    @IwColumn({
        name: 'cliId',
        index: 9
    }) public cliId?: string;

    @IwColumn({
        name: 'mntttc',
        index: 5,
        type: 'mnt',
        align: 'right'
    }) public mntTtc?: number;

    @IwColumn({
        name: 'age_id',
        index: 10
    }) public ageId?: string;

    @IwColumn({
        name: 'date_creat',
        type: 'dateTime',
        index: 12
    }) public dateCreat?: string;

    @IwColumn({
        name: 'date_modif',
        type: 'dateTime',
        index: 13
    }) public dateModif?: string;

    @IwColumn({
        name: 'fact_type',
        index: 2
    }) public facType?: string;

    public nbOfMail?: number;
    @IwColumn({
        name: 'sendByMail',
        index: 11,
        hidden: true,
        type: 'boolean',
    })
    public facByEmail?: boolean;
    public nbOfPrint?: number;
}
