import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Timeslot} from '../timeslot';

@Component({
    selector: 'iw-time-slot', templateUrl: './time-slot.component.html'
})
export class TimeSlotComponent {
    @Input() public isAvaiability?: boolean;

    @Input() public hour?: Timeslot;

    @Input() public disabled = false;
    @Output() public stateChanged = new EventEmitter<Timeslot>();
    @Output() public mouseDown = new EventEmitter<Event>();
    @Output() public mouseUp = new EventEmitter<Event>();

    constructor() {
    }

    public get style() {
        let style = {};
        if (!this.isAvaiability) {
            style = {
                'background-color': this.hour?.active ? '#c00d0d' : '#008b29'
            };
        } else {
            style = {
                'background-color': this.hour?.active ? '#008b29' : '#c00d0d'
            };
        }

        style = {...style, 'pointer-events': this.disabled ? 'none' : 'auto'};

        return style;
    }

    private _selectOnMouseOver = false;

    public get selectOnMouseOver() {
        return this._selectOnMouseOver;
    }

    @Input()
    public set selectOnMouseOver(v: boolean) {
        this._selectOnMouseOver = v;
    }

    public onMouseDown(event: Event) {
        this.toggleHour();
        this.mouseDown.emit(event);
    }

    public onMouseUp(event: Event) {
        this.mouseUp.emit(event);
    }

    public onMouseEnter() {
        if (!this.selectOnMouseOver) {
            return;
        }
        this.toggleHour();

    }

    public toggleHour() {
        if (!this.hour) {
            return;
        }
        this.hour.active = !this.hour.active;
        this.stateChanged.emit(this.hour);
    }
}
