import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity} from '@app/sam-base/core/rest-api/entity/index';

import {
    IwRestEntityId
} from '../../core/rest-api/entity/rest-entity-id.decorator';

@IwRestEntity('ppclimiscde')
export class Ppclimiscde {
    @IwRestEntityId() public misId?: string;
    public empId?: string;
    public cliId?: string;
    public cdeId?: string;
    public conId?: string;
    @IwColumn({type: 'date'}) public datedebut?: string;
    @IwColumn({type: 'date'}) public datefin?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public dateTrac?: string;
    public userCreat?: string;
    public userModif?: string;
    public userDelet?: string;
}
