import {Component, EventEmitter, OnInit} from '@angular/core';
import {HtmlElementStatus, IwGridColumn, ModalComponent} from '@app/sam-base/models';
import {TranslateService} from '@ngx-translate/core';

import {ModalRapportDataOptions} from './modal-rapport-invoice.options';

@Component({
    templateUrl: './modal-rapport-invoice.component.html'
})
export class ModalRapportInvoiceComponent implements ModalComponent<void, ModalRapportDataOptions>, OnInit {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();

    public cancelShow: HtmlElementStatus = {
        enabled: true, value: 'Cancel', visible: false
    };
    public value = '0';
    public textLog = this._translate.instant('generating');

    /** Grid columns */
    public tableColumn: IwGridColumn<any>[] = this.getColumns();

    private _options!: ModalRapportDataOptions;
    private _ok?: (result: void) => void;
    private _cancel?: (error?: any) => void;

    constructor(private _translate: TranslateService) {
    }

    public get title() {
        return this._options.title;
    }

    public get confirmMessage(): string {
        return this.confirmMessage;
    }

    public get width() {
        return this._options.width;
    }

    public get height() {
        return this._options.height;
    }

    public get obj(): any {
        return this._options.obj.invoice;
    }

    public ngOnInit(): void {
        this.obj['facele']?.forEach((elem: any) => {
            elem.nqte = this.maskMnt(elem.cqte);
            elem.nqte = `${elem.cqte} ${elem.unite}`;
        });
    }

    public onPressOk() {
        if (this._ok) {
            this._ok();
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: ModalRapportDataOptions): Promise<void> {
        this._options = data;
        if (!this._options.showCancel) {
            this.cancelShow = {
                ...this.cancelShow, enabled: false, visible: false
            };
        }
    }

    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public maskMnt(value: number): string {
        const val = value.toString();
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2
        })
            .format(parseFloat(val));
    }

    private getColumns(): IwGridColumn<any>[] {
        return [{
            prop: 'libelle', name: 'desig', index: 0
        }, {
            prop: 'parent', name: 'rapport', index: 1
        }, {
            prop: 'nqte', name: 'qte', index: 2
        }, {
            prop: 'prixunite', name: 'prix_uni', index: 3
        }, {
            prop: 'montant', name: 'montant', index: 1
        }];
    }
}
