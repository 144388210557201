import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IwGridColumn} from '@sam-base/models';

@Injectable()
export class MatchingHelpers {

    constructor(private _translate: TranslateService) {
    }

    public convertEnumValue(value: string | number, column: IwGridColumn<any>): string {
        if (column.enumPrefix) {
            return this._translate.instant(`enum.${column.enumPrefix}.${value}`);
        }
        return this._translate.instant(`enum.${column.prop}.${value}`);
    }

    /** Converts a numeric week days array to the correct real name */
    public convertNumericWeekdays(weekDays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[]): string[] {
        const convertedDays: string[] = [];
        weekDays.forEach((day) => {
            convertedDays.push(this.convertNumericWeekday(day));
        });
        return convertedDays;
    }

    /** Converts a numeric week day to the correct real name */
    // eslint-disable-next-line complexity
    private convertNumericWeekday(weekDay: 0 | 1 | 2 | 3 | 4 | 5 | 6): string {
        switch (weekDay) {
            case 0:
                return this._translate.instant('monday');
            case 1:
                return this._translate.instant('tuesday');
            case 2:
                return this._translate.instant('wednesday');
            case 3:
                return this._translate.instant('thursday');
            case 4:
                return this._translate.instant('friday');
            case 5:
                return this._translate.instant('saturday');
            case 6:
                return this._translate.instant('sunday');
        }
    }

}
