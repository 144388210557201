import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pphiscon')
export class Pphiscon {
    @IwRestEntityId() public conId?: string;
    public userModif?: string;
    public salId?: string;
    public agemode?: string;
    public dateModif?: string;
    public mb?: string;
    public caFixe?: string;
    public pf?: string;
    public dateCreat?: string;
    public nbh?: string;
    public ageId?: string;
    public mbpc?: string;
    public mbpcTempo?: string;
    public ca?: string;
    public caProll?: string;
    public sa?: string;
    public userCreat?: string;
    public caTempo?: string;
    public cliId?: string;
    public caOther?: string;
    public mbTempo?: string;
}
