import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ModalComponent} from '@app/sam-base/models';
import {HtmlElementStatus} from '@app/sam-base/models/html-element-status.model';

import {ModalNumberSubmitOptions} from './modal-number-submit.options';

@Component({
    selector: 'iw-modal-message', templateUrl: './modal-number-submit.component.html'
})
export class ModalNumberSubmitComponent implements ModalComponent<number, ModalNumberSubmitOptions>, OnInit, AfterViewInit {

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public okDisabled: HtmlElementStatus = {
        enabled: true, value: 'Ok', visible: true
    };
    public numberInput = new UntypedFormControl(0);
    @ViewChild('number') public numberInputElement?: ElementRef;
    private _options = new ModalNumberSubmitOptions();
    private _ok?: (result: number) => void;
    private _cancel?: (error?: any) => void;

    public get title() {
        return this._options.title;
    }

    public get showCancel() {
        return this._options.showCancel;
    }

    public get maxNumber() {
        return this._options.maxNumber;
    }

    public get minNumber() {
        return this._options.minNumber;
    }

    public ngOnInit() {
        if (this._options.okDisabled) {
            this.okDisabled = {
                ...this.okDisabled, enabled: false
            };
        }

        this.numberInput.setValidators([Validators.max(this.maxNumber), Validators.min(this.minNumber)]);

        this.numberInput.statusChanges.subscribe(status => {
            this.okDisabled.enabled = status === 'VALID';
        });
    }

    public ngAfterViewInit() {
        this.preSelectInput();
    }

    public onPressOk() {
        if (!this.okDisabled.enabled) {
            return;
        }
        if (this._ok) {
            this._ok(this.numberInput.value);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: ModalNumberSubmitOptions): Promise<void> {
        this._options = data;
    }

    public registerOk(action: (result: number) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    private preSelectInput() {
        if (!this.numberInputElement) {
            return;
        }
        const current = this.numberInputElement.nativeElement.value;
        this.numberInputElement.nativeElement.focus();
        this.numberInputElement.nativeElement.setSelectionRange(0, current.length);
    }
}
