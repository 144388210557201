export function checkStatusColor(status?: string): string {
    switch (status) {
        case 'saemp_inactive':
            return '#C0C0C0';
        case 'saemp_emp_not_ok':
            return '#FF0000';
        case 'saemp_active':
            return '#80FF80';
        case 'saemp_working_year':
            return '#EFAC52';
        default:
            return '#94018F';
    }
}
