<iw-dialog #dialog (beforeHide)="onBeforeHide()" [bodyPadding]="'10'" [modal]="true" [resizable]="false"
           [title]="title | translate" [visible]="display" [width]="700">

    <div #form class="row categories-container">

        <div class="col-xs-10">
            <iw-selection-lists (firstListChange)="setInput($event)" (secondListChange)="setOutput($event)"
                                [customSort]="customSortValue" [firstList]="firstList"
                                [secondList]="secondList"></iw-selection-lists>
        </div>

        <div class="col-xs-2 categories-sidemenu">

            <div class="row">
                <iw-button (press)="onPressOk()" [text]="'ok' | translate" class="btn-block"></iw-button>
            </div>
            <div class="row">
                <iw-button (press)="onPressCancel()" [text]="'sidemenu_cancel' | translate"
                           class="btn-block"></iw-button>
            </div>

            <div class="radio-list-container">
                <iw-radio-list (valueChange)="customSort($event)" [radioOptions]="radioOptions"
                               [value]="selectedOrder"></iw-radio-list>
            </div>

        </div>
    </div>

</iw-dialog>
