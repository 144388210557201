<div class="tab-panel col-xs-12">
    <div class="tab-header col-xs-2">
        <div (click)="onTabClick(tab)" *ngFor="let tab of tabs; let i = index" [class.active]="selectedTab === tab"
             [class.isDisabled]="isDisabled(tab?.title)" [id]="'tab'+i" [pTooltip]="'invalid_tab_form' | translate"
             [tooltipDisabled]="!isInvalid(tab?.title)" class="tab">
      <span *ngIf="isInvalid(tab?.title)"
            class="fa fa-exclamation-circle invalid-alert"></span>{{ tab?.title | translate }}
        </div>
    </div>

    <div class="tab-content col-xs-10">
        <ng-content></ng-content>
    </div>
</div>
