import * as actions from '../actions/navigation.actions';
import {NavigationState, NavigationStore} from '../models';

export function reducer(state: NavigationState = {}, action: actions.NavigationActions): NavigationState {

    switch (action.type) {
        case actions.NAVIGATION_INIT_SUCCESS: {
            const store: NavigationStore<any> = {
                entity: action.entity,
                position: action.position,
                profile: action.profile,
                values: action.values,
                uuid: action.uuid
            };

            return {
                ...state, [action.uuid]: store
            };
        }

        case actions.NAVIGATION_GOTO_POSITION: {
            const store = state[action.uuid];

            return {
                ...state, [action.uuid]: {
                    ...store, position: action.position
                }
            };
        }
    }

    return state;
}
