import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppcfs')
export class Ppcfs {
    @IwRestEntityId() public cfsId?: string;
    public rappeldone?: boolean;
    public dateDelet?: string;
    public userId?: string;
    public userDelet?: string;
    public cdfId?: string;
    public userCreat?: string;
    public rappelflag?: boolean;
    public action?: string;
    public conId?: string;
    public dateaction?: string;
    public daterappel?: string;
    public empId?: string;
    public dateModif?: string;
    public dateCreat?: string;
    public userModif?: string;
    public notes?: string;
}
