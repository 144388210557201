import { HttpClient } from '@angular/common/http';
import {Injectable, Type} from '@angular/core';
import {GridProfile} from '@app/sam-base/core/grid-profile/models';
import {EsQueryStatement} from '@app/sam-base/core/rest-api';
import {TranslateService} from '@ngx-translate/core';
import {saveAs} from 'file-saver';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {DynamicPrintParams, DynamicPrintRequest} from './models/dynamic-print-request.model';
import {PrintDynamicEntity, PrintFormat} from './models/print-entity';
import {PrintTemplate} from './models/print-template.model';

@Injectable()
export class DynamicPrintService {
    public static readonly TEMPLATE_NAME = '$dynamic_template';

    constructor(private readonly httpClient: HttpClient, private readonly _translation: TranslateService) {
    }

    public print<T>(c: PrintDynamicEntity<T>) {
        return this.printProfile(c.group, c.entity, c.extraStatements, c.parameters, c.format);
    }

    public printProfile<T>(profile: GridProfile<T>, entity: Type<T>, extraStatements: EsQueryStatement<T>[] = [], params: DynamicPrintParams = {}, format: PrintFormat = 'pdf') {
        return this.makeRequest(new DynamicPrintRequest(profile, entity, extraStatements, this._translation.currentLang, params, format));
    }

    /** Return the template used to generate a file */
    public getDynamicTemplate(): PrintTemplate {
        const ref: Type<PrintTemplate> = PrintTemplate;
        return new ref(<PrintTemplate>{
            entity: '',
            excel: false,
            pdf: true,
            templateName: DynamicPrintService.TEMPLATE_NAME,
            grid: true,
            reportName: DynamicPrintService.TEMPLATE_NAME,
            xls: false
        });
    }

    private makeRequest<T>(req: DynamicPrintRequest<T>) {
        const mediaType: string = req.format === 'pdf' ? 'application/pdf' : 'application/vnd.ms-exce';
        return this.httpClient
            .post('/api/print/dynamic/' + req.format, req, {responseType: 'arraybuffer'})
            .pipe(map(is => {
                saveAs(new Blob([is], {type: mediaType}), 'dynamic.' + req.format);
                return true;
            }), catchError(() => of(false)));
    }
}
