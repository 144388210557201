import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppheu')
export class Ppheu {
    @IwRestEntityId() public heuId?: string;
    public nbheu100?: number;
    public nbheuxxx?: number;
    public nbheuequip?: number;
    public nbheupause?: number;
    public nbheunuit?: number;
    public rapId?: string;
    public nbheuequix?: number;
    public date?: string;
    public nbheu125?: number;
    public nbheu200?: number;
    public lnuit?: string;
    public nbheu150?: number;
    public joustatus?: number;
}
