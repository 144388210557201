import {Injectable, NgZone, Type} from '@angular/core';
import {Action, ActionsSubject, ReducerManager, select, StateObservable, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {GlobalFormState, LicenseOptions} from './models';
import {EntitySelectState} from './models/entity-select.state';
import {AppState} from './reducers';
import {selectEntity, selectFormState, selectLicenseOption} from './selectors';
import {selectEntitySelectState} from './selectors/entity-select.selectors';
import {
    selectFormEntity, selectFormEntityState, selectFormIsLoading, selectFormType
} from './selectors/form-entity.selectors';
import {
    selectGlobalFormByEntityName,
    selectGlobalFormByEntityNameAndEntityId,
    selectGlobalFormDirty,
    selectGlobalFormEditState,
    selectGlobalFormLoading,
    selectGlobalFormNewState,
    selectGlobalFormReadonly,
    selectGlobalFormState,
    selectGlobalFormValidState,
    selectGlobalFormValue
} from './selectors/global-form.selectors';
import {selectLookupsByName} from './selectors/lookups.selectors';

/**
 * Because  Sancha ExJS run logic out of NgZone, this service is
 * required for components to detect changes in store
 */
@Injectable()
export class IwStoreService extends Store<AppState> {

    constructor(state$: StateObservable, actionsObserver: ActionsSubject, reducerManager: ReducerManager, private readonly _ngZone: NgZone) {
        super(state$, actionsObserver, reducerManager);
    }

    /** Make sures the dispatch occur in ngZone */
    public dispatch(action: Action) {
        if (NgZone.isInAngularZone()) {
            super.dispatch(action);
        } else {
            this._ngZone.run(() => super.dispatch(action));
        }
    }

    public entity<T>(type: string | Type<T>, id: string | number) {
        return this.pipe(selectEntity<T>(type, id));
    }

    public formEntity<T>(uuid: string) {
        return {
            entity: this.pipe(selectFormEntity<T>(uuid)),
            isLoading: this.pipe(selectFormIsLoading<T>(uuid)),
            type: this.pipe(selectFormType<T>(uuid)),
            state: this.pipe(select(e => selectFormEntityState(e, uuid)), filter(e => !!e))
        };
    }

    public globalForm<T>(uuid: string) {
        return {
            /** Current entity value */
            entity: this.pipe(selectGlobalFormValue<T>(uuid)), /** Loading state */
            isLoading: this.pipe(selectGlobalFormLoading(uuid)), /** IsNew state */
            isNew: this.pipe(selectGlobalFormNewState(uuid)), /** IsValid state */
            isValid: this.pipe(selectGlobalFormValidState(uuid)), /** Edit mode state */
            mode: this.pipe(selectGlobalFormEditState(uuid)), /** Readonly status */
            readonly: this.pipe(selectGlobalFormReadonly(uuid)), /** Readonly dirty */
            isDirty: this.pipe(selectGlobalFormDirty(uuid)), /** Global state */
            state: this.pipe(selectGlobalFormState<T>(uuid), filter(e => !!e), map(e => <GlobalFormState<T>>e) // Force type, filter operator won't
            ), /** Check if uuid is available */
            available: this.pipe(selectGlobalFormState<T>(uuid), map(s => !!s))
        };
    }

    public entitySelect<T>(type: Type<T> | string): Observable<EntitySelectState<T>> {
        /** Force type response, TS does not detect filter in pipe */
        return <any>this.pipe(selectEntitySelectState(type), filter(e => typeof e !== 'undefined'));
    }

    public formState(uuid: string) {
        return {
            state: this.pipe(selectFormState(uuid))
        };
    }

    public getFormByEntityName(entityName: string) {
        return this.pipe(selectGlobalFormByEntityName(entityName));
    }

    public getFormByEntityNameAndEntityId(entityName: string, entityId: string | number) {
        return this.pipe(selectGlobalFormByEntityNameAndEntityId(entityName, entityId));
    }

    public lookups(name: string) {
        return this.pipe(selectLookupsByName(name));
    }

    public getLicenseOption(option: keyof LicenseOptions) {
        return this.pipe(selectLicenseOption(option));
    }

    public navigationEntity<T>(uuid: string) {
        return {
            state: this.pipe(select(e => e.navigation[uuid]), filter(e => !!e))
        };
    }
}
