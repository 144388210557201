<iw-dialog #dialog [bodyPadding]="'10'" [height]="435" [modal]="true" [resizable]="false"
           [title]="'gestion_title' | translate" [visible]="display" [width]="500">
    <div [style.height]="'380px'" class="row col-xs-12">
        <div class="col-xs-9">
            <iw-table #grdInd (rowDoubleClick)="openGestionEvent($event)" (selectedChange)="setSelectedRow($event)"
                      [columns]="columns" [data]="agencies" [isFilterEnable]="false" [isGridColumnMenuEnable]="false"
                      [isSortIconEnable]="false" [rawTableMode]="true" class="col-xs-12 no-padding"></iw-table>
        </div>

        <div class="col-xs-3 row">
            <iw-button (press)="openGestion()" [text]="'ouvrir' | translate" class="iw-button col-xs-12"
                       id="select-gestion"></iw-button>

            <iw-button (press)="onPressCancel()" [text]="'annuler' | translate"
                       class="iw-button col-xs-12 margin-top-auto"></iw-button>
        </div>
    </div>
</iw-dialog>
