import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {checkStatusColor} from '@sam-base/helpers/employee-helpers';

@Component({
    selector: 'iw-cell-status-employee',
    templateUrl: './iw-cell-status.component.html',
    providers: [buildCustomValueProvider(IwCellStatusEmployeeComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IwCellStatusEmployeeComponent implements OnInit {

    @Input() public row?: { cempstatus?: number, status?: string };


    @Input() public color = '#80ff80'; // Default color

    @Input() public value = '';

    public ngOnInit() {
        if (this.row && (this.row.cempstatus !== undefined)) {
            this.checkColor(this.row.cempstatus.toString());
        } else if (this.row && (this.row.status !== undefined)) {
            this.color = checkStatusColor(this.row.status);
        }
    }

    // eslint-disable-next-line complexity
    public checkColor(status: string) {
        switch (status) {
            case '0':
                this.color = '#008000';
                break;
            case '1':
                this.color = '#00C000';
                break;
            case '2':
                this.color = '#80ff80';
                break;
            case '5':
                this.color = '#80ffff';
                break;
            case '8':
                this.color = '#faba8f';
                break;
            case '9':
                this.color = '#c0c0c0';
                break;
            case 'C':
                this.color = '#FD5AD7';
                break;
            default:
                this.color = '#80ff80';
                break;
        }
    }
}
