import {Ppmis, Ppmisih} from '@app/sam-base/models/placement';

export class MisheuOptions {
    public ppmis?: Ppmis;
    public ppmisih?: Ppmisih;
    public title?: string;
    public isAdding?: boolean;
    public isEditing?: boolean;
    public lMisHasSal?: boolean;
}
