import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppper')
export class Ppper {
    @IwRestEntityId() public salId?: string;
    public date?: string;
    public datedebut?: string;
    public userDelet?: string;
    public texte?: string;
    public userModif?: string;
    public status?: number;
    public saistatus?: string;
    public dateDelet?: string;
    public dateTxf?: string;
    public dateModif?: string;
    public dateTxs?: string;
    public dateMajlp?: string;
    public userCreat?: string;
    public datefin?: string;
    public dateTxslp?: string;
    public dateCreat?: string;
}
