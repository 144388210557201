<iw-modal-form (pressCancel)="onPressCancel()"
               (pressOk)="onPressOk()"
               [btnOk]="btnOk"
               [leftBusy]="0"
               [loading]="loading"
               [title]="'fiche_sal_create'"
               [topBusy]="0"
               [width]="350">
    <div class="row">

        <iw-dropdown #drpPeriod
                     (valueChange)="onPeriodChange($event)"
                     [(value)]="choicemodal!.salId"
                     [label]="'combo_periode_salaire' | translate"
                     [options]="optionPeriode"
                     class="iw-input col-xs-12"></iw-dropdown>
    </div>

    <div class="row">
        <iw-entity-textfield #ctnSelEmp
                             (valueChange)="onEmpChange($event)"
                             [(value)]="choicemodal!.empId"
                             [allowSearch]="true"
                             [entity]="entityEmploye"
                             [label]="'empId' | translate"
                             [name]="['nom', 'prenom']"
                             [showIdField]="false"
                             class="iw-input col-xs-12"></iw-entity-textfield>
    </div>
</iw-modal-form>
