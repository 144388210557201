import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppkw')
export class Ppkw {
    @IwRestEntityId()
    @IwColumn({
        index: 0,
        name: 'id'
    })
    public kwId?: string;
    public aptId?: string;
    @IwColumn({
        index: 1,
        name: 'Keyword'
    })
    public kw?: string;
    public descr?: string;
    public dateTrack?: string;
}
