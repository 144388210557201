import {forkJoin, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {
    createEntity,
    isDeletedEntity,
    isNewEntity,
    markEntityAsDeleted,
    RestApiService,
    RestEntityClient,
    RestQueryOperation
} from '@app/sam-base/core/rest-api';
import {OptionalDictionary} from '@app/sam-base/models';
import {Ppekw} from '@app/sam-base/models/placement';

@Injectable()
export class PpekwService {
    public readonly store: OptionalDictionary<Ppekw[]> = {};
    private readonly _ppekwClient: RestEntityClient<Ppekw>;

    constructor(private readonly _apiService: RestApiService) {
        this._ppekwClient = _apiService.getEntityClient(Ppekw);
    }

    public create(id: string, type: 'cliId' | 'empId', keyword: string): Ppekw {
        return createEntity(Ppekw, {
            [type]: id, ctype: type === 'cliId' ? 'C' : 'E', kw: keyword
        });
    }

    public delete(entity: Ppekw): Ppekw {
        return markEntityAsDeleted(entity);
    }

    public update(entities: Ppekw[]) {
        const toUpdate = this.buildUpdateRequest(entities);
        return forkJoin(toUpdate);
    }

    public getByClient(id: string) {
        return this._apiService.getEntityQuery(Ppekw, {
            operation: RestQueryOperation.Equals, prop: 'cliId', value: id
        })
            .scroll();
    }

    public getByEmployId(id: string) {
        return this._apiService.getEntityQuery(Ppekw, {
            operation: RestQueryOperation.Equals, prop: 'empId', value: id
        })
            .scroll();
    }

    private buildUpdateRequest(entities: Ppekw[]) {
        // Check if update is required
        const requireUpdate = (e: Ppekw) => !!isNewEntity(e) || isDeletedEntity(e);

        // Build create request
        const getCreateReq = (e: Ppekw) => this._ppekwClient.create(e)
            .pipe(catchError(_ => of(false)));

        // Build delete request
        const getDeleteReq = (e: Ppekw) => this._ppekwClient.delete(e.ekwId || '')
            .pipe(catchError(_ => of(false)), map(res => !!res));

        // Map entity to update request
        const mapRequest = ((e: Ppekw) => !!isNewEntity<Ppekw>(e) ? getCreateReq(e) : getDeleteReq(e));

        return entities
            .filter(requireUpdate)
            .map(mapRequest);
    }
}
