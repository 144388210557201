<iw-table #table
          (applyFilter)="onApplyRowFilter($event)"
          (contextMenuClick)="onContextMenu($event)"
          (dataChanged)="onDataChanged($event)"
          (rowClicked)="onRowClick($event)"
          (rowDoubleClick)="onRowDoubleClick($event)"
          (selectedChange)="onSelection($event)"
          [columnResize]="true"
          [columns]="columns"
          [dataKey]="entityPk"
          [defaultSelected]="defaultSelected"
          [forceSelected]="forceSelected"
          [innerWidth]="innerWidth"
          [isDisabled]="isDisabled"
          [isFilterEnable]="hasFilter"
          [isGridColumnMenuEnable]="hasGridColumnMenu"
          [isSortIconEnable]="hasSortIcon"
          [options]="tableOptions"
          [pageSize]="itemsPerPage"
          [rawTableMode]="rawTableMode"
          [scrollHeight]="scrollHeight"
          [selectedRow]="selectedData"
          [selectionMode]="selectMode"
          [showGridDetails]="showGridDetails"
          [virtualScrollDelay]="virtualScrollDelay"
          [virtualScrollItemSize]="virtualScrollItemSize"
          [virtualScroll]="virtualScroll"
></iw-table>
<iw-context-menu #menu
                 [context]="contextMenuData"
                 [items]="contextMenuItems"
                 [popup]="true"></iw-context-menu>
