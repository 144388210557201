import {IwColumn} from '@app/sam-base/core/column';
import {IwContextMenuClass} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

import {ppsuiviContextMenu} from './context-menu/ppsuivi-context-menu';

@IwContextMenuClass(...ppsuiviContextMenu) @IwRestEntity('ppsuivi')
export class Ppsuivi {
    @IwRestEntityId() public suiId?: string;
    @IwColumn({index: 0, name: 'qui'}) public userId?: string;
    @IwColumn({index: 1, type: 'translate', hidden: true, name: 'type'}) public suitypetext?: string;
    @IwColumn({index: 2, name: 'conseiller'}) public nomconseille?: string;
    @IwColumn({index: 3}) public nomclient?: string;
    @IwColumn({index: 4, name: 'employe'}) public nomemploye?: string;
    @IwColumn({index: 5, name: 'contact'}) public clientcontact?: string;
    @IwColumn({index: 6, type: 'date', name: 'date'}) public dateCreat?: string;
    @IwColumn({index: 7}) public action?: string;
    @IwColumn({index: 8, name: 'description'}) public notes?: string;
    @IwColumn({index: 9, type: 'date', name: 'rappel'}) public daterappel?: string;

    public suitype?: string;
    public dateaction?: string;
    public initiales?: string;
    public conId?: string;
    public misId?: string;
    public cliId?: string;
    public empId?: string;
    public dateTrack?: string;
    public dateModif?: string;
    public userModif?: string;

    public mailId?: string;
    public modecreat?: string;
    public userDelet?: string;
    public cdeId?: string;
    public suiData?: string;
    public drapdone?: string;
    public cliattId?: string;
    public userCreat?: string;
    public smsId?: string;
    public dateDelet?: string;
    public cdfId?: string;
    public rappelflag?: string;
    public rappeldone?: boolean;
    public cctId?: string;
    public origine?: string;
    public nomcliatt?: string;
    public contactprenom?: string;
    public contactnom?: string;
}
