import {Component, Input} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppshcalcmode} from '@app/sam-base/models/common/ppshcalcmode';

@Component({
    selector: 'iw-ppshcalcmode-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwPpshcalcmodeDropdownComponent)]
})
export class IwPpshcalcmodeDropdownComponent extends BaseEntityDropdown<Ppshcalcmode> {
    @Input() public width?: string | number;

    public getExtraProperties(): (keyof Ppshcalcmode)[] {
        return ['shcalcmode'];
    }

    protected getEntityType() {
        return Ppshcalcmode;
    }

    protected buildLabel(e: Ppshcalcmode): string {
        return e.titre || '';
    }
}
