import {Injectable, Type} from '@angular/core';
import {Actions, ofType} from '@ngrx/effects';
import {merge} from 'rxjs';
import {filter, withLatestFrom} from 'rxjs/operators';

import {getEntityMetadata} from '../rest-api';
import * as entityActions from './actions/entity.actions';
import * as globalActions from './actions/global-form.actions';
import {IwStoreService} from './iw-store.service';
import {GlobalFormState} from './models/global-form.state';

/** Allow to listen for store events and side effects */
@Injectable()
export class IwActionService<T> {

    constructor(private readonly _globalActions: Actions<globalActions.GlobalFormActions<T>>, private readonly _entityActions: Actions<entityActions.EntityActions<T>>, private readonly _store: IwStoreService) {
    }

    /**
     * When any entity of [type] in a GlobalForm is saved,
     * trigger this subscribe
     */
    public globalFormEntitySave<K>(type: Type<K>) {
        return this._globalActions.pipe(ofType(globalActions.GLOBAL_FORM_ENTITY_SAVE_NEW_SUCCESS, globalActions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS), selectState<T>(this._store), filterState(type));
    }

    public globalFormSaveByUuid(uuid: string) {
        return this._globalActions.pipe(ofType(globalActions.GLOBAL_FORM_ENTITY_SAVE), filter((e) => e.uuid === uuid), selectState<T>(this._store));
    }

    public globalFormSetReadModeByUuid(uuid: string) {
        return this._globalActions.pipe(ofType(globalActions.GLOBAL_FORM_SET_READ_MODE), filter(e => e.uuid === uuid));
    }

    /**
     * When any entity of [type] in the EntityState is changed,
     * trigger this subscribe
     */
    public entityStateChange<K>(type: Type<K>) {
        const meta = getEntityMetadata(type);
        return this._entityActions.pipe(ofType(entityActions.ENTITY_CREATE_SUCCESS, entityActions.ENTITY_DELETE_SUCCESS, entityActions.ENTITY_UPDATE_SUCCESS), filter(e => e.getMetadata().$entity === meta.$entity));
    }

    /** Listen for entity changes on Global and Entity states */
    public entityChange<K>(type: Type<K>) {
        const $global = this.globalFormEntitySave(type);
        const $entity = this.entityStateChange(type);
        return merge($global, $entity);
    }
}

/** Return filter for state, that filter states for this type */
function filterState<T, K>(type: Type<K>) {
    return filter<SelectStateResult<T>>(({state}) => {
        if (!state) {
            return false;
        }

        try {
            const entity = getEntityMetadata(type).$entity;
            const targetEntity = getEntityMetadata(state.entity).$entity;

            return entity === targetEntity;

        } catch {
            return false;
        }
    });
}

/** Select the current global form state for a action */
function selectState<T>(store: IwStoreService) {
    return withLatestFrom(store, (action: SelectStateResult<T>, state) => ({
        state: state.globalForm[action.uuid], uuid: action.uuid
    }));
}

interface SelectStateResult<T> {
    state?: GlobalFormState<T>;
    uuid: string;
}
