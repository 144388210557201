import {Injectable, Type} from '@angular/core';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';

import {ParametersService} from '@core/services/parameters.service';
import {entityFormAccessRoles} from '@sam-base/core';
import {Dictionary} from '@sam-base/models';
import {Ppcde, Ppcdf, Ppclicdes} from '@sam-base/models/placement';
import {ProfileService} from '@shared/profile/profile.service';
import {switchMap, take} from 'rxjs';
import {FormHandlerService} from '../form-handler';
import {GridProfile} from '../grid-profile/models';
import {WARNING} from '../logger';
import {getEntityMetadata} from '../rest-api';
import {FormEditMode, IwStoreService, SearchGridNavigateAction} from '../store';

@Injectable()
export class EntityNavigationService {
    constructor(private readonly _formHandlerService: FormHandlerService<string>,
                private readonly _store: IwStoreService, private _parametersService: ParametersService,
                private readonly _profileService: ProfileService) {
    }

    /**
     * Navigate to an entity form
     *
     * @param entity type of entity to load
     * @param entityId entity id to start the navigation
     * @param query query to filter and sort entities
     * @param navUuid uuid of form to update
     */
    // eslint-disable-next-line complexity
    public navigateToEntityForm<T>(entity: Type<T>, entityId: string | number, query?: GridProfile<T>,
                                   editMode: FormEditMode = 'read', isModal = false) {
        const ent = (entity === Ppcde || entity === Ppcdf) ? <any>Ppclicdes : entity;
        const esQuery = query ? query : GridProfile.fromType(ent);

        const entityName = getEntityMetadata(entity).$entity;

        console.group('Navigation');
        console.log('Navigate to entityName: ', entityName)
        console.log('AccessRole needed for ', entityName, 'are the following...');
        const formAccessRoles = entityFormAccessRoles(entityName);
        console.table(formAccessRoles);
        console.groupEnd();

        if (!this._profileService.checkUserPermission(formAccessRoles)) {
            WARNING('User has no access to entity: ', entityName);
            return;
        }

        if (!this._formHandlerService.findFormDescription(entityName)) {
            WARNING('CREATION NOT IMPLEMENTED FOR THIS FORM', entityName);
            return;
        }

        // Checks if multi entity window opening is activated
        this._parametersService.getParameterValue('entityMultiWindow')
            .pipe(switchMap((multiWindowEnabled) => {
                // Checks if there's a form with the same entity opened
                const form$ = multiWindowEnabled ? this._store.getFormByEntityNameAndEntityId(entityName, entityId) : this._store.getFormByEntityName(entityName);
                return form$.pipe(take(1));
                // eslint-disable-next-line max-len
            }))
            .subscribe(uuid => this._navigateToEntityForm(uuid, entityName, editMode, esQuery, entityId, isModal));
    }

    /**
     * Show a new dialog in a create entity state
     *
     * @param entity entity type to create
     * @param query navigation query
     */
    public navigateToNew<T>(entity: Type<T>, query?: GridProfile<T>, data?: Dictionary<any>) {
        const ent = (entity === Ppcde || entity === Ppcdf) ? <any>Ppclicdes : entity;
        const esQuery = query ? query : GridProfile.fromType(ent);

        const entityName = getEntityMetadata(entity).$entity;

        if (!this._formHandlerService.findFormDescription(entityName)) {
            // eslint-disable-next-line no-console
            console.warn('CREATION NOT IMPLEMENTED FOR THIS FORM');
            return;
        }

        this._store.getFormByEntityName(entityName)
            .pipe(take(1))
            .subscribe(uuid => {
                if (uuid) {
                    this._store.dispatch(new DestroyForm(uuid));
                }
                this._formHandlerService.showFormDialog<T>(entityName, data, state => ({
                    ...state,
                    isNew: true,
                    editMode: 'edit',
                    useNavigation: true,
                    navProfile: esQuery,
                    loading: false
                }));
            });
    }

    /**
     * Navigate to a search entity grid
     *
     * @param type Entity type
     * @param config Grid profile to load
     * @param navUUID Navigation UUID to reload
     */
    public navigateToGrid<T>(type: Type<T>, config?: GridProfile<T>, navUUID?: string) {
        this._store.dispatch(new SearchGridNavigateAction(type, config, navUUID));
    }

    // eslint-disable-next-line max-len
    private _navigateToEntityForm<T>(uuid: string | undefined, entityName: string, editMode: FormEditMode,
                                     esQuery: GridProfile<T>, entityId: string | number, isModal: boolean) {
        // If form already has a navigation UUID
        if (uuid) {
            this._store.dispatch(new DestroyForm(uuid));
        }
        this._formHandlerService.showFormDialog<T>(entityName, {}, state => ({
            ...state,
            isNew: false,
            editMode,
            useNavigation: true,
            navProfile: esQuery,
            entityId,
            diagModal: isModal
        }));
    }
}
