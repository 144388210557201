import {Injectable, Type} from '@angular/core';
import {ValidatorFn, Validators} from '@angular/forms';
import {getEntityMetadata} from '@app/sam-base/core/rest-api';
import {camelCase} from 'lodash';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {LocalInjectorService} from '../services';
import {mapSwaggerdefToValidators} from './form-validation-mapping';
import {SwaggerLoaderService} from './swagger-loader.service';
import {PropertyValidator, SwaggerEndpoint, ValidatorsTypes} from './swagger.model';
import {CacheItem, ValidationsCache} from './validations.model';

@Injectable()
export class ValidationsService {
    private readonly _cache: ValidationsCache = {};

    public constructor(private readonly _api: SwaggerLoaderService) {
    }

    public static getInstance() {
        return LocalInjectorService.load(ValidationsService);
    }

    public loadValidators<T>(type: Type<T> | undefined, formKey: keyof T): Observable<ValidatorFn[]> {
        if (!type) {
            return of([]);
        }

        const cache = this.loadFromCache(type, formKey);
        if (cache) {
            return of(cache);
        }

        return this._api.loadApiForEntity(type)
            .pipe(map(e => {
                if (!e) {
                    return [];
                }
                const validators = this.buildValidators(e, formKey);
                this.saveCache(type, formKey, validators);
                return validators;
            }));
    }

    private buildValidators<T>(api: SwaggerEndpoint<T>, formKey: keyof T) {
        const prop = Object.keys(api.properties)
            .find(e => camelCase(e) === camelCase(formKey as string)) as keyof T;
        const validators: ValidatorFn[] = prop ? this.buildCustomValidators(api.properties[prop]) : [];

        const requiredValidator = this.buildRequiredValidator(api, formKey);
        if (requiredValidator) {
            validators.push(requiredValidator);
        }

        return validators;
    }

    private buildRequiredValidator<T>(api: SwaggerEndpoint<T>, formKey: keyof T) {
        return api?.required?.find(e => camelCase(e as string) === camelCase(formKey as string)) ? Validators.required : undefined;
    }

    private buildCustomValidators<T>(prop?: PropertyValidator) {
        if (!prop) {
            return [];
        }
        const res = [];
        for (const [k, value] of Object.entries(prop)) {
            const validator = mapSwaggerdefToValidators(k as ValidatorsTypes, value);
            if (validator) {
                res.push(validator);
            }
        }
        return res;
    }

    private loadFromCache<T>(type: Type<T>, formKey: keyof T): ValidatorFn[] | undefined {
        const name = this.loadTypeName(type);
        const entityCache = this._cache[name] as CacheItem<T> | undefined;
        if (!entityCache) {
            return;
        }
        return entityCache[formKey];
    }

    private saveCache<T>(type: Type<T>, formKey: keyof T, val: ValidatorFn[]) {
        const name = this.loadTypeName(type);
        let entityCache = this._cache[name] as CacheItem<T>;
        if (!entityCache) {
            entityCache = {};
        }
        entityCache[formKey] = val;
    }

    private loadTypeName<T>(type: Type<T>) {
        return getEntityMetadata(type).$entity;
    }
}
