/* Service to generate UUID */
import {Injectable} from '@angular/core';
import UUID from 'uuidjs';

@Injectable()
export class UuidService {
    /** Generete a new UUID */
    public generate() {
        return UUID.genV4();
    }

    /** Generete a new UUID string */
    public generateString() {
        return this.generate()
            .toString();
    }

    /** Generete a new UUID string and format it to use as id on DOM */
    public generateDomId() {
        const PRE = 'ITEM-S';
        const id = this.generateString();
        return `${PRE}${id.replace(/-/g, '')}`;
    }

    /**
     * So long story short,
     * some entities can not be created because someone
     * decided that by default we don't have auto ID
     * so us the super front, do the unique ID thing for free (almost)
     * https://www.youtube.com/watch?v=pc0mxOXbWIU
     */
    public generateEntityId(size = 8) {
        const id = this.generateString();
        return id.replace(/-/g, '')
            .substr(0, size);
    }
}
