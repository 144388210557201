import {Type} from '@angular/core';

import {DeletedEntity, NewEntity, RestEntityMetadata} from '../models';

function isConstrutor(input: unknown): boolean {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        new (<any>input)();
        return true;
    } catch {
        return false;
    }
}

export function isRestType<T>(input: Type<T> | T): input is Type<T> {
    return isConstrutor(input);
}

export function isRestEntity(input: unknown): input is RestEntityMetadata {
    const hasPk = input && (<any>input).$pk;
    const hasName = input && (<any>input).$entity;

    return hasPk && hasName;
}

export function isNewEntity<T extends any>(input: unknown): input is NewEntity<T> {
    return typeof input === 'object'
        // eslint-disable-next-line no-null/no-null
        && input !== null && (<NewEntity<{}>>input).__isNew__ === true;
}

export function isDeletedEntity<T extends any>(input: unknown): input is DeletedEntity<T> {
    return typeof input === 'object'
        // eslint-disable-next-line no-null/no-null
        && input !== null && (<DeletedEntity<{}>>input).__isDeleted__ === true;
}

/**
 * Check if value is of type T,
 * this works by checking if entity has PK not undefiend
 * when entity is NEW, id is undefiend, in that case you can
 * pass the value [newReferenceKey] to use has validation field
 *
 * @param type type to check
 * @param value value to check
 * @param newReferenceKey key to use when pk is invalid
 */
// eslint-disable-next-line complexity
export function isEntity<T>(type: Type<T>, value: unknown, newReferenceKey?: keyof T): value is T {
    const typeInstance = new type();
    // Check if value and type are RestEntity
    if (!isRestEntity(typeInstance) || !isRestEntity(value)) {
        return false;
    }

    // Check if PK are the same
    if (typeInstance.$pk !== value.$pk) {
        return false;
    }

    // Check if has PK
    if (!!value.$getPk()) {
        return true;
    }

    return !!newReferenceKey && !!(<any>value)[newReferenceKey];
}
