import {NgModule} from '@angular/core';

import {IwStoreService} from '../store';
import {IwEventHubService, registerCurrentState} from './core';
import {IwEventRegisterService} from './core/iw-event-register.service';
import {IW_EVENT_HANDLERS} from './handlers';

@NgModule({
    providers: [IwEventHubService, ...IW_EVENT_HANDLERS]
})
export class EventsModule {
    constructor(_store: IwStoreService, _eventRegister: IwEventRegisterService) {
        _store.subscribe(state => registerCurrentState(state));
        _eventRegister.registerEventHandlerProviders(...IW_EVENT_HANDLERS);
    }
}
