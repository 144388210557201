import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Months} from './months';

@Component({
    selector: 'iw-months-picker', templateUrl: 'iw-months-picker.component.html'
})
export class IwMonthsPickerComponent {
    @Output() public selectionChange = new EventEmitter<MonthValue[]>();
    public months: Months[] = [{label: '1', val: 1, checked: false}, {label: '2', val: 2, checked: false}, {
        label: '3', val: 3, checked: false
    }, {label: '4', val: 4, checked: false}, {label: '5', val: 5, checked: false}, {
        label: '6', val: 6, checked: false
    }, {label: '7', val: 7, checked: false}, {label: '8', val: 8, checked: false}, {
        label: '9', val: 9, checked: false
    }, {label: '10', val: 10, checked: false}, {label: '11', val: 11, checked: false}, {
        label: '12', val: 12, checked: false
    }];

    @Input()
    public set monthNumber(v: MonthValue[]) {
        this.setSelections(v);
    }

    public get disabled() {
        return this._isDisabled;
    }

    private _isDisabled = false;

    @Input()
    public set isDisabled(v: boolean) {
        this._isDisabled = v;
    }

    public setSelections(selections: MonthValue[]) {
        if (selections !== undefined) {
            this.months.forEach((res) => {
                res.checked = selections.includes(res.val) ? true : false;
            });
        }
    }

    public onValueChange(checkedValue: boolean, index: number) {
        this.months[index].checked = checkedValue;
        this.selectionChange.emit(this.extractSelections());
    }

    public extractSelections() {
        const selection: MonthValue[] = [];
        this.months.forEach((res) => {
            if (res.checked) {
                selection.push(res.val);
            }
        });
        return selection;
    }
}

export type MonthValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
