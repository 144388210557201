import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {IwGridColumn, RowClickEvent} from '../../../../models';

@Component({
    selector: 'iw-table-body',
    templateUrl: './iw-table-body.component.html',
    styles: [':host { display: none }']
})
export class IwTableBodyComponent<T> implements OnInit {

    /** Enables raw table mode - hide headers and cell lines */
    @Input() public rawTableMode = false;

    /** Column list */
    @Input() public columns: IwGridColumn<T>[] = [];

    /** Visible items */
    @Input() public items: T[] = [];

    /** Current item */
    @Input() public rowItem?: T;

    /** Previous item */
    @Input() public prevItem?: T;

    @Input() public isExpanded = false;

    @Input() public showRowGrouping = false;

    @Input() public propToGroup?: Extract<keyof T, string | number>;
    @Input() public rowIndex = -1;
    @Input() public hasCheckbox = false;
    @Input() public isChecked = false;
    @Input() public isClicked = false;
    @Input() public showGridDetails = true;
    @Input() public scrollHeight?: string;
    @Output() public rowClick = new EventEmitter<RowClickEvent<T>>();
    @Output() public checkboxClick = new EventEmitter<RowClickEvent<T>>();
    @Output() public rowDoubleClick = new EventEmitter<RowClickEvent<T>>();
    @Output() public contextClick = new EventEmitter<RowClickEvent<T>>();
    @Output() public previewMouseOver = new EventEmitter<RowClickEvent<T>>();
    @ViewChild('template', {static: true}) public template?: TemplateRef<any>;

    constructor(private viewContainerRef: ViewContainerRef) {
    }

    public get propToGroupString(): string {
        if (typeof this.propToGroup === 'string') {
            return this.propToGroup;
        }

        if (typeof this.propToGroup === 'number') {
            return '' + this.propToGroup;
        }

        return '';
    }

    public get rowItemValue() {
        return (this.rowItem && this.rowItem[<keyof T>this.propToGroup]) || '';
    }

    public get countRowsValues() {
        if (this.propToGroup) {
            return this.countRows(this.propToGroup, this.rowItemValue);
        }
        return 0;
    }

    public ngOnInit() {
        if (this.template) {
            // Render template out of host
            this.viewContainerRef.createEmbeddedView(this.template);
        }
    }

    public countRows(prop: keyof T, value: any) {
        return this.items.filter(c => c[prop] === value).length;
    }

    public onRowClick(e: RowClickEvent<T>) {
        this.rowClick.emit(e);
    }

    public onCheckboxClick(e: RowClickEvent<T>) {
        this.checkboxClick.emit(e);
    }

    public onRowDoubleClick(e: RowClickEvent<T>) {
        this.rowDoubleClick.emit(e);
    }

    public onContextClick(e: RowClickEvent<T>) {
        this.contextClick.emit(e);
    }

    public onPreviewMouseOver(e: RowClickEvent<T>) {
        this.previewMouseOver.emit(e);
    }

    /** Checks if current groupy value is the same has the previous value */
    public showRowGroup() {
        if (this.showRowGrouping && this.propToGroup && this.rowItem && this.prevItem) {
            const rowGroupByValue = this.rowItem[this.propToGroup];
            const prevGroupByValue = this.prevItem[this.propToGroup];

            return rowGroupByValue !== prevGroupByValue;
        }

        return false;
    }
}
