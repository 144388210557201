import {ActionReducerMap} from '@ngrx/store';

import {
    FormEntityStore,
    GlobalFormStore,
    GridProfilesState,
    LicenseStore,
    LookupsStore,
    MainAppState as EntityState,
    NavigationState,
    SearchGridGlobalState
} from '../models';
import {ConfigTemplateStore} from '../models/config-template.state';
import {EntitySelectStore} from '../models/entity-select.state';
import * as fromConfigTemplate from './config-template.reducer';
import * as fromDialog from './dialog.reducer';
import * as fromEntitySelect from './entity-select.reducer';
import * as fromEntity from './entity.reducer';
import * as fromFormEntity from './form-entity.reducer';
import * as fromForm from './form.reducer';
import * as fromGlobalForm from './global-form.reducer';
import * as fromGridProfiles from './grid-profiles.reducer';
import * as fromLicense from './license.reducer';
import * as fromLookups from './lookups.reducer';
import * as fromNavigation from './navigation.reducer';
import * as fromSearchGrid from './search-grid.reducer';

export interface AppState {
    formStore: fromForm.FormStore;
    dialogState: fromDialog.DialogState;
    searchGrid: SearchGridGlobalState;
    gridProfiles: GridProfilesState;
    configTemplates: ConfigTemplateStore<any>;
    navigation: NavigationState;
    entities: EntityState;
    formEntity: FormEntityStore<any>;
    lookups: LookupsStore;
    globalForm: GlobalFormStore;
    entitySelect: EntitySelectStore<any>;
    license: LicenseStore;
}

export const reducers: ActionReducerMap<AppState, any> = {
    formStore: fromForm.reducer,
    dialogState: fromDialog.reducer,
    searchGrid: fromSearchGrid.reducer,
    gridProfiles: fromGridProfiles.reducer,
    configTemplates: fromConfigTemplate.reducer,
    navigation: fromNavigation.reducer,
    entities: fromEntity.reducer,
    formEntity: fromFormEntity.reducer,
    lookups: fromLookups.reducer,
    globalForm: fromGlobalForm.reducer,
    entitySelect: fromEntitySelect.reducer,
    license: fromLicense.reducer
};
