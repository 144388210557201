import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {OrdreElems} from '@modules/sam-main/placement/components/invoices/invoices.model';
import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';

export interface PrepareInvoiceClient {
    cliId?: string;
    facPrepMode: number;
}

export interface InvoicePrepareRequest {
    invoiceProcessId: number;
    finDeMois?: boolean;
    invoiceFixeAggIds?: number[];
    prepareInvoiceClients: PrepareInvoiceClient[]
}

export interface SaveInvoiceProcessRequest {
    invoiceProcessId: number;
    finDeMois?: boolean;
    invoiceFixeAggIds?: number[];
    prepareInvoiceClients: PrepareInvoiceClient[]
}

export interface GenerateInvoiceRequest {
    invoiceProcessId: number;
    dateFac: string;
    dateCompta: string;
    salId: string;
    tvaCodeHt: string;
    finDeMois: boolean;
    invoiceIdsToGenerate: number[] | undefined;
    faceleord?: OrdreElems;
}

export interface InvoiceBill {
    id?: number;
    text?: string;
    week?: string;
    vref?: string;
    seqId?: string;
    cliId?: string;
    empId?: string;
    ageId?: string;
    nbRap?: number;
    amount?: number;
    misTag?: string;
    marked?: boolean;
    facindex?: string;
    chantier?: string;
    facadrId?: string;
    invoiceId?: string;
    chantier2?: string;
    sortindex?: string;
    modePrepa?: number;
    modeprepaExt?: string;
    canbemarked?: boolean;
    cliNom?: string;
    cmodeprepa?: string;
    factype?: string;
    nom?: string;
    mntht?: string;
    facId?: string;
    facNo?: string;
    error?: BusinessExceptionErrors;
}

interface InvoiceReport {
    seqId?: string;
    rapId?: string;
    cliId?: string;
    salId?: string;
    amount?: number;
    facindex?: string;
    invoiceId?: string;
    celeorder?: string;
}

@IwRestEntity('prepare')
export class Prepare {
    @IwRestEntityId() public invoiceId?: string;
    public bills?: InvoiceBill[];
    public reports?: InvoiceReport[];
    public numberOfFilesToBeProcessed?: number;
}
