import {Component, ElementRef} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Ppgrt} from '@app/sam-base/models/placement';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';

import {map} from 'rxjs/operators';
import {IwTextfieldListComponent} from './iw-textfield-list.component';


@Component({
    templateUrl: './iw-textfield-list.component.html',
    selector: 'iw-textfield-genretravail',
    providers: [buildCustomValueProvider(IwTextfieldGenretravailComponent)]
})
export class IwTextfieldGenretravailComponent extends IwTextfieldListComponent<Ppgrt> {
    public readonly listOption: ModalSelectTableOption<Ppgrt, string>;

    constructor(modalService: ModalService, restService: RestApiService, elRef: ElementRef) {
        super(modalService, elRef, restService);
        this.propsToFilter = ['genretrav'];
        this.listOption = {
            columns: [{prop: 'genretrav'}],
            map: (e: Ppgrt) => e.genretrav || '',
            selection: 'single',
            fetchAction: () => restService
                .getEntityClient(Ppgrt)
                .getRefData()
                .pipe(map(e => e
                    .sort((a, b) => (a.genretrav || '') < (b.genretrav || '') ? -1 : 1)))
        };
    }
}
