import {EventEmitter} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {SortDirection} from '../../../models/sort-state.model';
import {QueryBuilder} from '../core/query-builder';
import {ExtractContent, RestEntityParams, RestQueryParam} from '../models';
import {RestEntityClient} from './rest-entity-client';

/**
 * Allows to request the results for an Entity query
 *
 * How to use:
 *  const query = RestEntityQuery.fromQueries<T>(service, queries);
 *  query.scroll();
 */
export class RestEntityQuery<T> {

    /** Event trigger when new results are fetched */
    public fetch = new EventEmitter();
    /** Directon of sort */
    protected _sortDirection: SortDirection = 'asc';
    /** Property to sort */
    protected _sortProperty?: keyof T;
    /** Query to filter results */
    protected _queryBuilder = new QueryBuilder<T>();
    /** Total number of pages, -1 if unkown */
    protected _totalPages = -1;
    /** Last loaded page, -1 if no page was loaded */
    protected _lastPage = -1;
    /** All results for query */
    protected _result: T[] = [];
    private readonly FIRST_PAGE = 0;
    /** Param for filters */
    private readonly FILTER_PARAM = 's';

    constructor(protected readonly _entityService: RestEntityClient<T>) {
        this.reset();
    }

    /** @readonly sort params [property, direction] */
    public get Sort() {
        return [this._sortProperty, this._sortDirection];
    }

    /** @readonly queries to filter results */
    public get query() {
        return this._queryBuilder.queries;
    }

    /** @readonly true, when all pages were loaded */
    public get isEnd() {
        return this._lastPage !== -1 && this._lastPage >= this._totalPages;
    }

    /** @readonly all fetched items */
    public get results() {
        return this._result;
    }

    /** Number of items to request */
    protected _pageSize = 1000;

    /** @readonly size of items to request */
    public get pageSize() {
        return this._pageSize;
    }

    /** Create a [RestEntityQuery] from a list of queries */
    public static fromQueries<T>(service: RestEntityClient<T>, ...queries: RestQueryParam<T, any>[]) {
        const builder = new QueryBuilder(...queries);
        const queryService = new RestEntityQuery(service);
        queryService._queryBuilder = builder;
        return queryService;
    }

    /** Request the next batch of elements */
    public scroll(): Observable<T[]> {
        return this.fetchResult()
            .pipe(map(res => {
                this.appendData(res);
                return res;
            }));
    }

    /** Reset and return the first page */
    public refresh(): Observable<T[]> {
        this.reset();
        return this.scroll();
    }

    /** Reset all parameters, so the next scroll starts from page 0 */
    public reset(): void {
        this._totalPages = this.FIRST_PAGE - 1;
        this._lastPage = this.FIRST_PAGE - 1;
        this._result = [];
    }

    /** Change page size and reset results */
    public setPageSize(size: number) {
        this._pageSize = size;
        this.reset();
    }

    /** Set the sort direction and property */
    public setSort(dir: SortDirection, prop: keyof T) {
        this._sortDirection = dir;
        this._sortProperty = prop;
        this.reset();
    }

    /** Add new entries to results and emit a fetch event */
    private appendData(data: T[]) {
        if (!data.length) {
            return;
        }
        this._result.push(...data);
        this.fetch.emit(data);
    }

    /** Return next page of results, check if end was reached before request */
    private fetchResult(): Observable<T[]> {
        if (this.isEnd) {
            return of([]);
        }
        const params = this.buildRequestParams();
        const _construtor = (e: T) => this._entityService.construct(e);
        return this._entityService.query(params)
            .pipe(map(res => {
                this._totalPages = res.totalPages;
                return ExtractContent(res)
                    .map(_construtor);
            }));
    }

    /** Convert the sort config to the request param value  */
    private buildSortParam(dir: SortDirection, prop?: keyof T) {
        if (!prop) {
            return '';
        }
        return `${String(prop)},${dir}`;
    }

    /** Build the params to request the next page */
    private buildRequestParams(): RestEntityParams {
        const params: RestEntityParams = {
            page: '' + (++this._lastPage), // Increment page
            size: '' + this._pageSize, sort: this.buildSortParam(this._sortDirection, this._sortProperty)
        };

        const filter = this._queryBuilder.getRestParams();
        if (filter.length) {
            params[this.FILTER_PARAM] = filter;
        }

        return params;
    }
}
