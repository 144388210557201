import { HttpClient } from '@angular/common/http';
import {Component, EventEmitter, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {IwEventHubService} from '@app/sam-base/core/events';
import {StoreEvents} from '@app/sam-base/core/events/actions/store.actions';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {ToastService} from '@app/sam-base/core/toast';
import {ModalComponent} from '@app/sam-base/models';
import {Ppcli, Ppemp, Ppmis} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {camelCase, mapKeys} from 'lodash';

import {MiscpyOptions} from './miscpy.model';

@Component({
    templateUrl: './miscpy.component.html'
})
export class MiscpyComponent implements ModalComponent<Ppmis, MiscpyOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();

    public entityEmploye = Ppemp;
    public okEnabled = true;
    public entityClient = Ppcli;
    public ccreceived = '';
    public cmreceived = '';
    public miscpyForm = new UntypedFormGroup({
        misId: new UntypedFormControl(''),
        lavenant: new UntypedFormControl(''),
        avemotif: new UntypedFormControl(''),
        ladvmode: new UntypedFormControl(''),
        lotheremp: new UntypedFormControl(''),
        lothercli: new UntypedFormControl(''),
        empId: new UntypedFormControl(''),
        cliId: new UntypedFormControl('')
    });

    private _options = new MiscpyOptions();
    private _ok?: (result: Ppmis) => void;
    private _cancel?: (error?: any) => void;

    constructor(private readonly _modalService: ModalService, private _translate: TranslateService, private _http: HttpClient, private _toastService: ToastService, private readonly _events: IwEventHubService<StoreEvents>) {
    }

    public ngOnInit() {
        const dt = this._options.ppmis;
        if (dt instanceof Ppmis) {
            this.miscpyForm.setValue({
                misId: dt.misId,
                lavenant: false,
                avemotif: false,
                ladvmode: false,
                lotheremp: false,
                lothercli: false,
                empId: dt.empId,
                cliId: dt.cliId
            });
            this.ccreceived = dt.ccreceived || '';
            this.cmreceived = dt.cmreceived || '';

        }
    }

    public async setData(data: MiscpyOptions): Promise<void> {
        this._options = data;
    }

    public get title() {
        return this._options.title;
    }

    public async showModal() {
        const options: ModalMessageOptions = {
            message: this.miscpyForm.controls['lavenant'].value ? [this._translate.instant('copierMission')] : [this._translate.instant('copierMissionAvenant')],
            showCancel: true,
            title: this._translate.instant('mission')
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.okEnabled = false;
            this.saveMission();
        } catch (_) {
        }
    }

    public saveMission() {
        const body = {
            misId: this.miscpyForm.controls['misId'].value,
            avenant: this.miscpyForm.controls['lavenant'].value,
            avemotif: this.miscpyForm.controls['avemotif'].value,
            cliId: this.miscpyForm.controls['cliId'].value,
            empId: this.miscpyForm.controls['empId'].value
        };
        this._http
            .post(environment.backendURL + 'mission/copy', body)
            .subscribe((mis: Ppmis) => {
                const mission: Ppmis = mapKeys(mis, (value, key) => camelCase(key));
                if (!mission || !mission.misId) {
                    return;
                }
                this._events.emit(StoreEvents.EntitySaved, {$entity: 'ppmis', ...mission});
                this.onPressOk(mission);
            });
    }

    public isOkDisabled() {
        return !this.okEnabled || (this.canSubmit() || !this.empIdExists() || !this.cliIdExists());
    }

    public canSubmit() {
        return this.miscpyForm.controls['avemotif'].value === undefined || !this.miscpyForm.controls['avemotif'].value;
    }

    public empIdExists() {
        return this.miscpyForm.controls['cliId'].value !== '';
    }

    public cliIdExists() {
        return this.miscpyForm.controls['empId'].value !== '';
    }

    public isOtherEmployeeChecked() {
        return this.miscpyForm.controls['lotheremp'].value;
    }

    public isOtherClientChecked() {
        return this.miscpyForm.controls['lothercli'].value;
    }

    public setEmployee() {
        if (!this.miscpyForm.controls['lotheremp']) {
            const dt = this._options.ppmis;
            if (dt instanceof Ppmis) {
                this.miscpyForm.patchValue({empdId: dt.empId});
            }
        }
    }

    public setClient() {
        if (!this.miscpyForm.controls['lothercli'].value) {
            const dt = this._options.ppmis;
            if (dt instanceof Ppmis) {
                this.miscpyForm.patchValue({cliId: dt.cliId});
            }
        }
    }

    public onPressOk(mission: Ppmis) {
        if (this._ok) {
            this._ok(mission);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public registerOk(action: (result: Ppmis) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }
}
