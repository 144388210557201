import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppemprfid')
export class Ppemprfid {
    @IwRestEntityId() public empId?: string;
    public pointtype?: string;
    public rfidstatus?: number;
    public rfid?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
