import {RestEntityMetadata} from '../../rest-api';
import * as actions from '../actions/entity.actions';
import {EntityStore, MainAppState as EntityState} from '../models';

export function reducer(state: EntityState = {}, action: actions.EntityActions<any>): EntityState {
    switch (action.type) {
        case actions.ENTITY_UPDATE_SUCCESS:
        case actions.ENTITY_CREATE_SUCCESS:
        case actions.ENTITY_LOAD_SUCCESS : {
            const store = selectState(state, action.getMetadata());
            const entityId = action.getMetadata()
                .$getPk()
                .toString();

            return {
                ...state, [store.metadata.$entity]: {
                    ...store, entities: {
                        ...store.entities, [entityId]: action.entity
                    }
                }
            };
        }

        case actions.ENTITY_DELETE_SUCCESS: {
            const store = selectState(state, action.getMetadata());
            delete store.entities[action.entityId];

            return {
                ...state, [store.metadata.$entity]: {
                    ...store, entities: {...store.entities}
                }
            };
        }
    }

    return state;
}

function selectState(state: EntityState, metadata: RestEntityMetadata): EntityStore<any> {
    const store = state[metadata.$entity];

    return store ? store : newEntityStore(metadata);
}

function newEntityStore(metadata: RestEntityMetadata): EntityStore<any> {
    // Extrack only the interface types
    const {$entity, $getPk, $pk} = metadata;
    return {
        metadata: {
            $entity, $pk, $getPk
        }, entities: {}
    };
}
