import {IwColumn} from '@app/sam-base/core/column';
import {Gefacele} from '@sam-base/models/invoices/gefacele';
import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('gefachdr')
export class Gefachdr {
    @IwRestEntityId() @IwColumn() public facId?: string;
    public cliId?: string;
    public facNo?: string;
    public facOrnc?: string;
    public facStatus?: string;
    public facType?: string;
    public facIndex?: string;
    public salId?: string;
    @IwColumn({
        name: 'date_facture',
        type: 'date'
    }) public dateFac?: string;
    public dateCpta?: string;
    public tvaPeriode?: string;
    public devise?: string;
    public userTag?: string;
    public ybActif?: boolean;
    public ybStatus?: string;
    public ybtxfId?: string;
    public ybtxfId2?: string;
    public ybDtSend?: string;
    public ybDtAck?: string;
    public ybData?: string;
    public modified?: boolean;
    public mntBrut?: number;
    public txRabais?: number;
    public mntRabais?: number;
    public mntHt?: number;
    public mntTva?: number;
    public mntTvaCpta?: number;
    public mntCor4Arr?: number;
    public mntTtc?: number;
    public rndTo05?: boolean;
    public mntRecu?: number;
    public mntEsc?: number;
    public mntPaiSpec?: number;
    public tvaId?: string;
    public tvaCode?: string;
    public tvaTaux?: number;
    public tvaInclus?: boolean;
    public facFrx?: string;
    public datePrt?: string;
    public nbOfPrint?: number;
    public dateMail?: string;
    public nbOfMail?: number;
    public paiCondId?: string;
    public dateEch?: string;
    public datePay?: string;
    public payId?: string;
    public payCliId?: string;
    public dateRpl?: string;
    public noRappel?: number;
    public cliAdrId?: string;
    public concerne?: string;
    public vRef?: string;
    public nRef?: string;
    public attn?: string;
    public cliAttId?: string;
    public chantier?: string;
    public chantier2?: string;
    public remarque?: string;
    public modeCreat?: string;
    public parent?: string;
    public parenType?: string;
    public parent2?: string;
    public parenType2?: string;
    public ageId?: string;
    public cptaRef?: string;
    public bvrMpaId?: string;
    public noRefBvr?: string;
    public noRefQr?: string;
    public eleCount?: number;
    public mutFactors?: string;
    public nonFactors?: boolean;
    public bnoFactors?: string;
    public facEleOrd?: string;
    public facHis?: string;
    public docLnk?: string;
    public docLnkType?: string;
    public facData?: string;
    public modCnt?: string;
    public modData?: string;
    public userId?: string;
    public creating?: string;
    public faceleList?: Gefacele[];
    public solde?: number;
    public published?: boolean;
    public nbOfPublished?: number;
    public datePublished?: string;
}
