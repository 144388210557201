import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saparncs')
export class Saparncs {

    @IwRestEntityId() public annee?: string;
    public ncsA?: boolean;
    public ncsB?: boolean;
    public ncsF?: boolean;
    public ncsG?: boolean;
    public ncsT23?: string;
    public ncsT3?: string;
    public ncsT4?: string;
    public ncsT71?: string;
    public ncsT72?: string;
    public ncs1311?: boolean;
    public ncsP1311?: boolean;
    public ncsT1312?: string;
    public ncsP1312?: boolean;
    public ncsP1321?: boolean;
    public ncsP1322?: boolean;
    public ncsT1323?: string;
    public ncsP1323?: boolean;
    public ncsP133?: boolean;
    public ncsT141?: string;
    public ncsP141?: boolean;
    public ncsT142?: string;
    public ncsP142?: boolean;
    public ncsT151?: string;
    public ncsT152?: string;
    public ncsT153?: string;
    public ncsT154?: string;
    public ncsT155?: string;
    public ncsT15?: string;
    public ncsT15mod?: number;
    public ncsPrtnbj?: boolean;
    public ncsPrtnbh?: boolean;
    public roundmnts?: boolean;
    public employeur?: string;
    public signature?: string;
    public telephone?: string;
    public email?: string;
    public isnoordre?: string;
    public ncsTxtaq?: string;
    public sdsB?: string;
    public sdsD?: string;
    public sdsI?: string;
    public nomunit?: string;
    public adrunit?: string;
    public npaunit?: string;
    public lieuunit?: string;
    public contactNcsId?: string;
}
