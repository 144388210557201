import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit} from '@angular/core';
import {CctService} from '@app/sam-base/core/services/cct.service';
import {HtmlElementStatus, ModalComponent} from '@app/sam-base/models';
import {CctComplete, CctElement} from '@app/sam-base/models/placement/cct.model';
import {MinSalaryCalc, TempdataFormResponse} from '@app/sam-base/models/placement/min-salary-calc.model';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {ModalService} from '@sam-base/core';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ModalCctLogOptions} from './cct-dynamic-form.options';

@Component({
    templateUrl: './cct-dynamic-form.component.html'
})
export class CctDynamicFormComponent implements ModalComponent<TempdataFormResponse | undefined, ModalCctLogOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public okDisabled: HtmlElementStatus = {
        enabled: true,
        value: 'Ok',
        visible: true
    };
    public cancelShow: HtmlElementStatus = {
        enabled: true,
        value: 'Cancel',
        visible: true
    };
    public results?: any;
    public _minSalaryCalc?: MinSalaryCalc;
    public struct?: CctElement[];
    public editionId = '';
    private _ok?: (result: TempdataFormResponse | undefined) => void;
    private _cancel?: (error?: any) => void;
    private _options!: ModalCctLogOptions;

    constructor(private readonly _cctService: CctService, private readonly _modalService: ModalService,
                private readonly _translate: TranslateService) {
    }

    public get width() {
        return this._options.width;
    }

    public get height() {
        return this._options.height;
    }

    public get title() {
        return this._options.title;
    }

    public get tempdataText() {
        return this._options.tempdataText;
    }

    public get tempdataObj() {
        return this._options.tempdataObj;
    }

    private get cctLanguage() {
        return this._options.cctLanguage;
    }

    public ngOnInit() {
        this.cancelShow.visible = !this._options.tempdataText;
    }

    public async setData(data: ModalCctLogOptions): Promise<void> {
        this.editionId = data.id ? data.id : '';
        this._options = data;
        this.getStruct();

    }

    /*
     + Function to get the form struct
     */
    public getStruct(): void {

        if (!this._options.tempdataText) {
            this._cctService.getStruct(this.editionId, this.cctLanguage)
                .pipe(catchError((err: HttpErrorResponse) => {
                    this.throwErrorMessage(err);
                    return of([]);
                }))
                .subscribe((struct: CctElement[]) => {
                    this.struct = struct.map((elem: CctElement) => {
                        elem.editionId = this.editionId;
                        return elem;
                    });

                    this._cctService.currentStructure = this.struct;
                });
        }
    }

    public onPressOk() {

        if (this.tempdataText) {
            this._cctService.resetCollection();
            this.pressOk.emit();
            return;
        }
        const tempdataText = this._cctService.getTempdataText();

        this._cctService.getMinimumSalary(this.editionId, this.cctLanguage)
            .pipe(catchError((err: HttpErrorResponse) => {
                this.throwErrorMessage(err);
                return of(undefined);
            }))
            .subscribe((minSalarycalc) => {
                if (this._ok && minSalarycalc) {
                    const data: TempdataFormResponse = {
                        minSalarycalc,
                        tempdataText
                    };

                    this._ok(data);
                }
                this._cctService.resetCollection();
                this.pressOk.emit();
            });
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
        this._cctService.resetCollection();
    }

    public registerOk(action: (result: TempdataFormResponse | undefined) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    /**
     * Function to build node to send to dropdown with needed editionId
     *
     * @param node actual struct element
     */
    public getNode(node: CctElement): CctComplete {
        return {
            node,
            editionId: this.editionId,
            translationLanguage: this.cctLanguage
        };
    }

    private async throwErrorMessage(err: HttpErrorResponse) {

        const options: ModalMessageOptions = {
            width: 400,
            message: [],
            showCancel: false,
            title: '',
            confirmMessage: '',
            imgPath: 'assets/info.png',
            style: {color: '#005b7e'}
        };
        if (err.status === 500) {
            options.alertsMessage = this._translate.instant('tempdata_error');

            const msg = this._translate.instant('tempdata_error_message');
            const msgGreeting = this._translate.instant('tempdata_error_greeting');
            options.message = [
                msg,
                msgGreeting];
        } else {
            options.message = this._translate.instant('tempdata_error');
        }

        try {
            await this._modalService
                .showModal(ModalMessageComponent, options);
        } catch (error) {
        }
    }
}
