import {Observable} from 'rxjs';

import {createSelector, select} from '@ngrx/store';

import {Lookups} from '../../../models/common';
import {AppState} from '../reducers';

export const selectLookups = (state: AppState) => state.lookups;

export function selectLookupsByName(name: string): (source$: Observable<AppState>) => Observable<Lookups[]> {
    const selector = (lookupName: string) => createSelector(selectLookups, (lookup) => lookup[lookupName] ?? []);
    return select(selector(name));
}
