import {Type} from '@angular/core';

import {isConstructor} from '../../../helpers/guards';
import {DeletedEntity, NewEntity, RestEntityMetadata} from '../models';
import {isRestEntity, isRestType} from './rest-entity-guards';

export function getEntityName<T>(t: Type<T> | T): string | undefined {
    const instance = isRestType(t) ? new t() : t;
    if (isRestEntity(instance)) {
        return instance.$entity;
    }
}

/**
 * Instantiate entity using class,
 * to allow it to have base Entity method (getPk())
 */
export function initEntity<T>(type: Type<T>, input: any): RestEntityMetadata & T {
    const instance = new type(input);
    if (isRestEntity(instance)) {
        return instance;
    }

    throw new Error('Instance is not an entity');
}

/** Create entity from intance, and mark it as new */
export function createEntity<T>(type: Type<T>, input: any): NewEntity<T> {
    const baseEntity = initEntity(type, input);
    return markEntityAsNew(baseEntity);
}

export function markEntityAsNew<T>(value: T): NewEntity<T> {
    if (isRestEntity(value)) {
        (<NewEntity<T>>value).__isNew__ = true;
        (<any>value)[value.$pk] = undefined; // Remove id
        return <NewEntity<T>>value;
    }

    throw new Error('Instance is not an entity');
}

export function markEntityAsDeleted<T>(value: T): DeletedEntity<T> {
    if (isRestEntity(value)) {
        (<DeletedEntity<T>>value).__isDeleted__ = true;
        return <DeletedEntity<T>>value;
    }

    throw new Error('Instance is not an entity');
}

export function getEntityMetadata<T>(input: Type<T> | T) {
    const instance = isConstructor(input) ? new input() : input;

    if (isRestEntity(instance)) {
        return instance;
    }
    throw new Error('type is not a Rest Entity!');
}
