import {Injectable, Type} from '@angular/core';
import {ConseillerFilters} from '@app/sam-base/models/entity-textfield.model';

import {ObjectMap, TableSelectionMode} from '../../models';
import {GridProfile} from '../grid-profile/models';
import {IwStoreService} from '../store';
import * as fromEntitySelect from '../store/actions/entity-select.actions';
import {createEntitySelectState} from '../store/models/entity-select.state';

/**
 * Open the a entity grid and allow user to select 1 value
 *
 * @param entity entity to select
 * @param mode single or multiple selection
 * @param profile profile to use in grid
 * @param filter used to filter results
 * @param conseillerFilters used to filter based on conseillers
 */
export interface SelectEntity<T> {
    entity: Type<T>;
    mode?: TableSelectionMode;
    profile?: GridProfile<T>;
    filter?: ObjectMap<T>;
    conseillerFilters?: ConseillerFilters;
}

/** Service to select a value from a entity grid */
@Injectable()
export class EntitySelectService<T> {
    constructor(private readonly _store: IwStoreService) {
    }

    public selectEntity(params: SelectEntity<T>) {
        if (!params.mode) {
            params.mode = 'single';
        }
        this._store.dispatch(new fromEntitySelect.EntitySelectInit(createEntitySelectState(params.entity, params.mode, params.profile, params.filter)));
    }
}
