import {IwGridColumn} from '../../../models/components/grid-column.model';
import {pushColumnMetadata} from '../helpers';

/**
 * Define a property as header for a table of this object
 *
 * @param header header name
 * @param formatter function to transform cell value
 */
export function IwColumn<T>(options?: IwGridColumn<T>): PropertyDecorator;
export function IwColumn(options?: IwGridColumn<any>): PropertyDecorator {
    return (target: Object, name: string | symbol) => {
        const extraProps = options ? options : {};

        const value: IwGridColumn<any> = {
            prop: name.toString(), ...extraProps
        };

        pushColumnMetadata(target.constructor.prototype, [value]);
    };
}
