import {Component, Input} from '@angular/core';

import {BaseFormControl} from '../../base/base-form-control';

@Component({
    selector: 'iw-textbox', templateUrl: './iw-textbox.component.html'
})
export class IwTextBoxComponent extends BaseFormControl<string> {
    @Input() public focusOnLoad = false;
}
