import {Component, ElementRef, Input} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';

import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {Lookups} from '@app/sam-base/models/common';
import {IwTextfieldListComponent} from './iw-textfield-list.component';

@Component({
    selector: 'iw-textfield-lookups',
    templateUrl: './iw-textfield-list.component.html',
    providers: [buildCustomValueProvider(IwTextfieldLookupsComponent)]
})
export class IwTextfieldLookupsComponent extends IwTextfieldListComponent<Lookups> {
    /**
     * Component developed in:
     * https://samredesign.atlassian.net/browse/SAM-4479
     */

    @Input() public lookupName = '';

    constructor(_modalService: ModalService, _store: IwStoreService, elRef: ElementRef, restService: RestApiService) {
        super(_modalService, elRef, restService);
        this.propsToFilter = ['cvalue'];
        this.listOption = {
            columns: [{prop: 'cvalue'}],
            map: (e: Lookups) => e.cvalue || '',
            selection: 'single',
            fetchAction: () => _store.lookups(this.lookupName)
        };
    }
}
