import {Subscription} from 'rxjs';

import {Hotkey} from './hotkey.model';

/** Hotkey with subscriber to event, allow to destroy */
export class HotkeyInstance {
    constructor(public readonly subscription: Subscription, public readonly hotkey: Hotkey) {
    }

    public destroy() {
        this.subscription.unsubscribe();
    }
}
