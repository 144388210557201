import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('ppshcalcmode')
export class Ppshcalcmode {
    @IwRestEntityId() public shcalcmode?: number;
    public titre?: string;
    public calcjf?: string;
    public calcvac?: string;
    public calc13?: string;
    public calcord?: string;
}
