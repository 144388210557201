<ng-container *ngIf="state?.useNavigation">
    <div class="flex flex-h flex-between">
        <iw-button (press)="triggerNavigation(0)"
                   [iconClass]="'fas fa-step-backward'"
                   [isDisabled]="navBackDisabled || isNewEntity"
                   width="45px"></iw-button>

        <iw-button (press)="triggerNavigation(2)"
                   [iconClass]="'fas fa-step-forward'"
                   [isDisabled]="navFowardDisabled || isNewEntity"
                   width="45px"></iw-button>
    </div>
    <div class="flex flex-h flex-between">
        <img *ngIf="img"
             [src]="img"
             [style.height]="'100%'"
             [style.width]="'100%'"
             alt="{{'uploaded_img' | translate }}">
    </div>
    <div class="flex flex-h flex-between margin-top-5">
        <iw-button (press)="triggerNavigation(-1)"
                   [iconClass]="'fas fa-caret-left'"
                   [isDisabled]="navBackDisabled || isNewEntity"
                   width="45px"></iw-button>

        <iw-button (press)="triggerNavigation(1)"
                   [iconClass]="'fas fa-caret-right'"
                   [isDisabled]="navFowardDisabled || isNewEntity"
                   width="45px"></iw-button>
    </div>
</ng-container>

<div *ngIf="showList"
     class="row margin-top-10">
    <iw-button (press)="goToEntityGrid()"
               [isDisabled]=""
               [text]="'sidemenu_list' | translate"
               class="btn-block"
               id="sidemenu_list"></iw-button>
</div>

<div *ngIf="showEdit"
     class="row margin-top-10">
    <ng-container *iwAccessGroups="modifyEntityRoles">
        <iw-button (press)="setEditMode()"
                   *ngIf="!isEditMode"
                   [isDisabled]="disableEdit"
                   [text]="'sidemenu_edit' | translate"
                   class="btn-block"
                   id="sidemenu_edit"></iw-button>
    </ng-container>

    <iw-button (press)="saveChanges()"
               *ngIf="isEditMode"
               [isDisabled]="!isValid"
               [text]="'sidemenu_save' | translate"
               class="btn-block"
               id="sidemenu_save"></iw-button>
</div>

<div *ngIf="showEditPrintTemplate"
     class="row margin-top-10">
    <ng-container *iwAccessGroups="modifyEntityRoles">
        <iw-button (press)="setEditModePrintTemplate()"
                   *ngIf="!isEditModePrintTemplate"
                   [isDisabled]="disableEditPrintTemplate"
                   [text]="'sidemenu_edit' | translate"
                   class="btn-block"
                   id="sidemenu_edit_print_formats"></iw-button>
    </ng-container>

    <iw-button (press)="saveChanges()"
               *ngIf="isEditMode"
               [isDisabled]="!isValid"
               [text]="'sidemenu_save' | translate"
               class="btn-block"
               id="sidemenu_save_print_formats"></iw-button>
</div>

<div class="row">
    <ng-container *iwAccessGroups="deleteEntityRoles">
        <iw-button (press)="deleteEntity()"
                   *ngIf="!isEditMode && showDelete"
                   [isDisabled]="disableEdit"
                   [text]="'sidemenu_delete' | translate"
                   class="btn-block"
                   id="sidemenu_delete"></iw-button>
    </ng-container>

    <iw-button (press)="setReadMode()"
               *ngIf="isEditMode"
               [text]="'sidemenu_cancel' | translate"
               class="btn-block"
               id="sidemenu_cancel"></iw-button>
</div>

<div *ngIf="allowPrint"
     class="row">
    <iw-button (press)="openPrint()"
               *iwAccessGroups="printEntityRoles"
               [iconClass]="'fas fa-print'"
               class="btn-block"></iw-button>
</div>
