import {isArray} from 'lodash';

import {Type} from '@angular/core';

import {IwObjectColumnMetadata} from '../models';

export function isIwObjectColumnMetadata<T>(input: unknown): input is IwObjectColumnMetadata<T> {
    // eslint-disable-next-line no-null/no-null
    if (typeof input !== 'undefined' && input !== null) {
        const metadataName: keyof IwObjectColumnMetadata<any> = '$METADATA#IW_COLUMNS';
        return isArray((<any>input)[metadataName]);
    }

    return false;
}

export function isIwObjectColumnTypeMetadata<T>(type: Type<T>): boolean {
    // eslint-disable-next-line no-null/no-null
    if (typeof type !== 'undefined' && type !== null) {
        const input = new type();
        const metadataName: keyof IwObjectColumnMetadata<any> = '$METADATA#IW_COLUMNS';
        return isArray((<any>input)[metadataName]);
    }

    return false;
}
