import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Observable} from 'rxjs';

import {Planning} from '../components/planning/planning-models';

@Injectable()
export class EmpPlanningService extends RestClient<Planning> {
    constructor(_http: HttpClient) {
        super(_http);
    }

    public getPlanning(empIds: string[], startDate: string, endDate: string): Observable<Planning[]> {
        const body = {empIds, startDate, endDate};
        return this._http.post<Planning[]>(this._baseUrl + `planning/employee`, body);
    }
}
