import {Component, ElementRef} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Ppqua} from '@app/sam-base/models/placement';
import {PpquamsComponent} from '@shared/widgets/modal-components/ppquams/ppquams.component';
import {map} from 'rxjs/operators';

import {IwMultiautocompleteSelectComponent} from './iw-multiautocomplete-select.component';

@Component({
    selector: 'iw-multiautocomplete-qualif',
    templateUrl: './iw-multiautocomplete-select.component.html',
    providers: [buildCustomValueProvider(IwMultiautocompleteQualifComponent)]
})
export class IwMultiautocompleteQualifComponent extends IwMultiautocompleteSelectComponent<Ppqua> {

    constructor(elRef: ElementRef, _restService: RestApiService, _modalService: ModalService) {
        super(elRef, _restService, _modalService);
        this.entityType = Ppqua;
        this.propToFilter = 'qualif';
        this.labelAlign = 'top';
        this.fetchAction = () => {
            return _restService.getEntityClient(Ppqua)
                .getRefData()
                .pipe(map((qualifs) => qualifs.filter(ppqua => ppqua.active)
                    .sort((a, b) => this.sortQualif(a, b))))
        };
        this.listOption = {
            columns: [{prop: 'qualif'}],
            map: (e: Ppqua) => e,
            selection: 'single',
            fetchAction: this.fetchAction,
            size: 700
        };
        this.multiSelectComponent = PpquamsComponent;
    }

    private sortQualif(a: Ppqua, b: Ppqua) {
        return (a.qualif || '') < (b.qualif || '') ? -1 : 1;
    }
}
