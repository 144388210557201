import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pppointj')
export class Pppointj {
    @IwRestEntityId() public ptijId?: string;
    public misId?: string;
    public userCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public pointNo?: string;
    public dt?: string;
    public ptficId?: string;
    public modecreat?: string;
    public dateCreat?: string;
    public geoLat?: string;
    public geoLon?: string;
    public rapId?: string;
    public userModif?: string;
    public ptstatus?: number;
    public userDelet?: string;
}
