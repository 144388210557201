<div [ngClass]="className" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition"
     class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" class="iw-form-group-label" for="item" title="{{label | translate}}">{{ label | translate }}
    </div>
    <div class="input-container">
        <div (dblclick)="onDoubleClick()" [attr.data-suffix]="suffix" class="p-inputgroup iw-input">
            <ng-container *ngIf="!!icon">
                <span class="p-inputgroup-addon"><span class="fa fa-{{icon}}"></span></span>
            </ng-container>

            <p-autoComplete #control (completeMethod)="filterSuggestions($event)" (onBlur)="onBlur()"
                            (onClear)="inputCleared()" (onFocus)="onFocus()" (onSelect)="emitFullObject($event)"
                            [(ngModel)]="value" [appendTo]="'body'" [class.active-input]="!isDisabled"
                            [class.clickable]="doubleClickable" [disabled]="isDisabled"
                            [forceSelection]="forceSelection" [minLength]="1" [ngClass]="inputClass"
                            [showEmptyMessage]="true" [suggestions]="filteredSuggestions"></p-autoComplete>
        </div>
        <div #TxtwarningMessage *ngIf="isInvalid && warningMessage">
            <small class="iw-input col-xs-12 padding-left-0"
                   style="color: #ff0000; font-size:10px"> {{ warningMessage | translate }} </small>
        </div>
    </div>
</div>
