import * as actions from '../actions/config-template.actions';
import {ConfigTemplateStore} from '../models/config-template.state';

const defaultInitialState: ConfigTemplateStore<any> = {
    '': [{}]
};

export function reducer<T>(state = defaultInitialState, action: actions.ConfigTemplateActions<T>): ConfigTemplateStore<T> {
    if (action.type === actions.CONFIG_TEMPLATE_ADD) {
        return {
            ...state, template: [action.payload]
        };
    }
    return state;
}
