import {ComponentRef} from '@angular/core';

import {OptionalDictionary} from '../models';

/** Base class to be extended by components that are loaded at runtime */
export abstract class BaseEntryComponent {
    // Form creation data
    private _data: OptionalDictionary<any> = {};
    // eslint-disable-next-line
    private __ref?: ComponentRef<BaseEntryComponent>;
    // Component reference

    // Form uuid
    private _uuid?: string;

    /** UUID for this form, set by formHandler */
    public get uuid(): string {
        if (this._uuid) {
            return this._uuid;
        }
        throw Error('uuid not available, call after uuid init');
    }

    public set uuid(v: string) {
        this._uuid = v;
        this.onUuidSet();
    }

    /** Component reference */
    public get reference() {
        if (this.__ref) {
            return this.__ref;
        }
        throw Error('Component reference not set');
    }

    public set reference(v: ComponentRef<BaseEntryComponent>) {
        this.__ref = v;
    }

    /** Set the data object */
    public setData(data: any) {
        // eslint-disable-next-line no-null/no-null
        if (typeof data === 'object' && data !== null) {
            this._data = data;
        }
    }

    /**
     * Return key value from data
     *
     * @param key key to return
     * @param warn if true, a warning is emit when value is undefined
     */
    protected getData<T>(key: string, warn = true): T | undefined {
        return this._data[key];
    }

    protected onUuidSet() {
    }
}
