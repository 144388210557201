import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pprapihdet')
export class Pprapihdet {
    @IwRestEntityId() public rapId?: string;
    public misihId?: string;
    public indorheu?: string;
    public date?: string;
    public nb?: number;
    public cid?: string;
}
