import {
    IwRestEntityId
} from '../../core/rest-api/entity/rest-entity-id.decorator';
import {IwRestEntity} from '../../core/rest-api/entity/rest-entity.decorator';

@IwRestEntity('sapaimode')
export class Sapaimode {
    @IwRestEntityId() public mpaId?: string;
    public titre?: string;
    public titre1?: string;
    public titre2?: string;
    public titre3?: string;
    public titre4?: string;
    public titre5?: string;
    public payType?: number;
    public dtatype?: number;
    public dtadelai?: number;
    public banque?: string;
    public banqueClr?: string;
    public banqueCpt?: string;
    public banqueSwi?: string;
    public iban?: string;
    public dtaiban?: string;
    public dtadonid?: string;
    public compte?: string;
    public compte2?: string;
    public compte3?: string;
    public actif?: boolean;
    public userId?: string;
    public pmData?: string;
}
