import * as lookupActions from '../actions/lookups.actions';
import {LookupsStore} from '../models';

export function reducer(state: LookupsStore = {}, action: lookupActions.LookupsActions): LookupsStore {
    switch (action.type) {
        case lookupActions.LOOKUPS_LOAD_SUCCESS: {
            return action.results
                .reduce((store, l) => {
                    const name = l.lkupname || 'unkown';
                    const list = store[name] || [];
                    list.push(l);
                    store[name] = list;
                    return store;
                }, <LookupsStore>{});
        }
    }

    return state;
}
