import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('configtemplate')
export class ConfigTemplate<T> {
    @IwRestEntityId() public id?: number;
    public userId?: string;
    public title?: string;
    public target?: string;
    public type?: string;
    public template?: T;
}
