import {Type} from '@angular/core';

import {IwGridColumn} from '../../../models';
import {IwObjectColumnMetadata} from '../models';
import {isIwObjectColumnMetadata, isIwObjectColumnTypeMetadata} from './guard';

export function getMetadataPropName(): keyof IwObjectColumnMetadata<any> {
    return '$METADATA#IW_COLUMNS';
}

function hasMetadataFromType(input: any) {
    return !isIwObjectColumnMetadata(input) && isIwObjectColumnTypeMetadata(input);
}

/** Load column metadata */
export function loadColumnMetadata<T>(input: any): IwGridColumn<T>[] | undefined {
    if (isIwObjectColumnMetadata(input) || isIwObjectColumnTypeMetadata(input)) {
        let instance = input;
        if (hasMetadataFromType(input)) {
            instance = new input();
        }
        const columns = instance['$METADATA#IW_COLUMNS'];
        if (columns) {
            return columns.map((c: IwGridColumn<T>) => ({...c}));
        }
    }

    return undefined;
}

export function loadColumnMetadataFromType<T>(type: Type<T>): IwGridColumn<T>[] {
    const instance = new type();
    return loadColumnMetadata(instance) || [];
}


/** Set value for column metadata, if undefined creates a metadata entry */
export function setColumnMetadata<T>(target: any, columns: IwGridColumn<T>[]) {
    // eslint-disable-next-line no-null/no-null
    if (typeof target !== 'undefined' || target !== null) {
        target[getMetadataPropName()] = columns;
        return target;
    }

    return undefined;
}

/** Add values for column metadata, if undefined creates a metadata entry */
export function pushColumnMetadata<T>(target: any, columns: IwGridColumn<T>[]) {
    if (isIwObjectColumnMetadata<any>(target)) {
        target[getMetadataPropName()].push(...columns);
        return target;
    }

    // eslint-disable-next-line no-null/no-null
    if (typeof target !== 'undefined' || target !== null) {
        return setColumnMetadata(target, columns);
    }

    return undefined;
}
