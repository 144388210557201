import {ApplicationRef, Injectable, Type} from '@angular/core';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {ConfigService} from '@sam-base/core/services/config.service';
import {KeycloakOptions, KeycloakService} from 'keycloak-angular';
import {KeycloakConfig} from 'keycloak-js';
import {lastValueFrom} from 'rxjs';
import {map} from 'rxjs/operators';
import {getEnvironment} from '../../environment/environment';
import {LocalInjectorService} from '../../services/local-injector.service';

/** Service to handle application bootstrap */
@Injectable()
export class BootstrapAuthService {
    constructor(private readonly _configService: ConfigService, private readonly _keycloakService: KeycloakService, // Make sure that is instatiated at bootstrap
                public readonly _: LocalInjectorService) {
    }

    /** Bootstrap angular with keycloak protection */
    public async bootstrapWithAuth(appRef: ApplicationRef, bootstrapComponent: Type<any>) {
        const initOptions = await this.getKeycloakInitSettings();
        this._keycloakService.init(initOptions)
            .then(hasAuth => {
                // If has authentication token, start app
                if (hasAuth) {
                    appRef.bootstrap(bootstrapComponent);
                    /** Starts the refresh token process */
                    this._keycloakService.updateToken(60);
                    return;
                }

                // If not authenticated, proced with auth process
                this._keycloakService.login()
                    .then(() => appRef.bootstrap(bootstrapComponent));

            })
            .catch(err => {
                ERROR('Error at authentication');
                if (!getEnvironment().production) {
                    ERROR(err);
                }
            });
    }

    /** Load keycloak init options */
    private async getKeycloakInitSettings(): Promise<KeycloakOptions> {
        const config = await this.getKeycloakConfig();
        // eslint-disable-next-line no-console
        console.warn('Starting keycloak with: ' + config.url);
        const options: KeycloakOptions = {
            config,
            bearerExcludedUrls: ['/api/config', '/assets', '/kibana/app/kibana'],
            loadUserProfileAtStartUp: true,
            enableBearerInterceptor: true,
            initOptions: {
                checkLoginIframe: false, redirectUri: window.location.href
            }
        };

        return options;
    }

    /** Load keycloak configuration from HEADERS */
    private async getKeycloakConfig(): Promise<KeycloakConfig> {
        return lastValueFrom(this._configService.getConfig()
            .pipe(map(h => ({
                clientId: h.clientId, url: h.authUrl, realm: h.realm
            } as KeycloakConfig))));
    }
}
