<div [ngStyle]="style" class="time-slots" style="display: flex; user-select: none;">
    <iw-button (press)="toggleAll()" [height]="18" [isDisabled]="isDisabled" [text]="'s_all_btn' | translate"
               [tooltip]="'selected_all' | translate" style="padding-right: 10px;"></iw-button>
    <div (mouseleave)="onMouseLeave()" class="row">
        <iw-time-slot (mouseDown)="onMouseDownEvent()" (mouseUp)="onMouseUpEvent()"
                      (stateChanged)="updateSelectedHours($event, i)" *ngFor="let slot of timeSlots; let i = index"
                      [disabled]="isDisabled" [hour]="slot" [isAvaiability]="isAvaiability"
                      [selectOnMouseOver]="isMouseDownMode"></iw-time-slot>
    </div>
</div>
