<ng-template #template>
    <tr [attr.tabindex]="rowIndex"
        [class.clicked]="isClicked"
        [class.disabled]="rowItem.disabled"
        [class.no-border]="rawTableMode"
        [class.selected]="isChecked"
        [id]="rowIndex"
        [style.height]="virtualScrollItemSize + 'px'"
        class="table-row selectable-row row-{{rowIndex}}">
        <ng-container *ngIf="hasCheckbox">
            <td (click)="onCheckboxClick($event)"
                class="iw-table-checkbox">
                <p-checkbox [disabled]="true"
                            [ngModel]="isChecked"
                            binary="true"></p-checkbox>
            </td>
        </ng-container>
        <td #tdRef
            (click)="onRowClick($event, col)"
            (contextmenu)="onContextMenuClick($event, col)"
            (dblclick)="onRowDoubleClick($event, col)"
            (mouseenter)="onMouseEnter($event, tdRef, col)"
            (mouseleave)="onMouseLeave()"
            *ngFor="let col of columns"
            [class.closed-period]="col.type === 'activePer' && rowItem.status === 1"
            [class]="col.prop"
            [pTooltip]="currentTooltip"
            [style.text-align]="col.icon ? 'center' : col.align ? col.align : 'left'"
            [style.width]="col.width ? (col.width || '') + 'px' : '150px'"
            class="text-overflow iw-table-cell-child">
            <span *ngIf="!isSpecialCell(col)"
                  class="table-cell">
                    <iw-cell-status-color *ngIf="hasColorMapper(col)"
                                          [colorMapper]="colorMapper(col)"
                                          [row]="rowItem"
                                          [value]="rowItem[col.prop] | iwCell: col.type:col"
                                          class="table-cell-default"></iw-cell-status-color>
        <ng-container *ngIf="!hasColorMapper(col)">
                {{ rowItem[col.prop] | iwCell: col.type:col }}
        </ng-container>
            </span>
            <iw-table-cell *ngIf="isSpecialCell(col)"
                           [column]="col"
                           [row]="rowItem"></iw-table-cell>
        </td>
    </tr>
    <ng-container *ngIf="!!(rowItem?.__isActive) && showGridDetails">
        <tr class="row-detail">
            <td [colSpan]="columns.length + (hasCheckbox ? 1 : 0)">
                <iw-table-details [row]="rowItem"></iw-table-details>
            </td>
        </tr>
    </ng-container>
</ng-template>

<p-overlayPanel #mouseOverDetails
                [appendTo]="'body'">
    <iw-mouse-over-details *ngIf="showMouseOver"
                           [details]="rowItem"></iw-mouse-over-details>
</p-overlayPanel>
