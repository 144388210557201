<iw-dialog [closeOnEscape]="true" [draggable]="false" [modal]="true" [title]="employeeName" [width]="1390">
    <div class="row">
        <div class="row col-xs-3">
            <ng-container *ngIf="result.length === 1">
                <iw-button (press)="triggerMode('weekly')" [text]="'week' | translate"
                           class="iw-button padding-0 col-xs-4 padding-right-5"></iw-button>
                <iw-button (press)="triggerMode('monthly')" [text]="'month' | translate"
                           class="iw-button padding-0  col-xs-4 padding-right-5"></iw-button>
                <iw-button #SEbotton (press)="openSE()" *ngIf="result.length === 1"
                           [text]="'schedule_event_form_title' | translate"
                           class="iw-button col-xs-4 padding-0 padding-bottom-5"></iw-button>
            </ng-container>
        </div>
        <div class="col-xs-8 flex-text-center iw-strong">
            {{ currentMonth }}, {{ currentYear }} <span *ngIf="mode === 'weekly'">- {{ currentWeek }}</span></div>
        <div class="row col-xs-1">
            <iw-button (press)="triggerNavigation(-1)" [iconClass]="'fas fa-caret-left'"
                       class="iw-button padding-0 col-xs-6 flex-align-end" width="45px"></iw-button>
            <iw-button (press)="triggerNavigation(1)" [iconClass]="'fas fa-caret-right'"
                       class="iw-button padding-0 col-xs-6  flex-align-end" width="45px"></iw-button>
        </div>
    </div>
    <div class="flex flex-between flex-column">
        <div>
            <iw-planning-days *ngIf="mode === 'monthly'" [employees]="result" [mode]="'monthly'"
                              [planningStartDate]="startDate" style="margin-left: 55px;"></iw-planning-days>
            <iw-planning-days *ngIf="mode === 'weekly'" [employees]="result" [mode]="'weekly'"
                              [planningStartDate]="startDate"></iw-planning-days>
            <div class="col-xs-1 flex-text-left iw-strong">
                <span (mouseout)="mouseOut($event)" (mouseover)="mouseOver($event)" style="cursor: default">...</span>
            </div>
        </div>

    </div>
    <div class="row flex flex-hr">
        <iw-button #Closebutton1 (press)="closeDialog()" [text]="'fermer' | translate"
                   class="iw-button col-xs-1 col-xs-offset-11 padding-0"></iw-button>
    </div>
</iw-dialog>
