<iw-busy *ngIf="showBusy" [ajustLeft]="0" [ajustTop]="ajustTop" [isBusy]="loading"></iw-busy>
<div *ngIf="show" class="mouse-box">
    <img [src]="imgSrc" alt="photo" class="col-xs-4">
    <div class="col-xs-8 details-info no-padding">
        <div class="col-xs-12">
            <span>Email: </span> <span>{{ details?.email }}</span>
        </div>

        <div class="col-xs-12">
            <span>Natel: </span> <span>{{ details?.natel }}</span>
        </div>

        <div class="col-xs-12">
            <span>Lieu: </span> <span>{{ details?.lieu }}</span>
        </div>

        <div class="col-xs-12">
            <span>AVS: </span> <span>{{ details?.noavs13 | mask: '000.0000.0000.00' }}</span>
        </div>
    </div>
</div>
