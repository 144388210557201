import {Component, ElementRef, Input} from '@angular/core';

import {BaseFormControl, buildCustomValueProvider} from '@sam-base/base';

@Component({
    selector: 'iw-hours-input',
    templateUrl: './iw-hours-input.component.html',
    providers: [buildCustomValueProvider(IwHoursInputComponent)]
})
export class IwHoursInputComponent<T> extends BaseFormControl<T | T[keyof T]> {
    @Input() public warningMessage?: string;

    @Input() public tooltip?: string;

    @Input() public tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';

    @Input() public suffix?: string;

    constructor(private elRef: ElementRef) {
        super();
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }
}
