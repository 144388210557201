import {Component} from '@angular/core';
import {BaseFormControl} from '@app/sam-base/base/base-form-control';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';

@Component({
    selector: 'iw-form-header',
    templateUrl: './form-header.component.html',
    providers: [buildCustomValueProvider(FormHeaderComponent)]
})
export class FormHeaderComponent extends BaseFormControl<string> {
}
