<iw-modal-form (pressCancel)="onPressCancel()" (pressOk)="onPressOk()" [btnOk]="btnOk" [height]="'auto'"
               [title]="'doc_manag_document'" [width]="500">
    <div class="row">
        <div class="col-xs-4">
            <iw-textfield #type [(value)]="ppempdoc.empdoctype" [isDisabled]="true" [labelAlign]="'top'"
                          [label]="'type' | translate"></iw-textfield>
        </div>
        <div class="col-xs-12 padding-top-10"></div>
        <div class="col-xs-8">
            <iw-dropdown #filename [(value)]="ppempdoc.wxdocId" [labelAlign]="'top'" [label]="'file' | translate"
                         [options]="filenameOptions"></iw-dropdown>
        </div>
        <div class="col-xs-1 flex-end">
            <iw-button #eraseFilename (press)="removeFile()" [text]="'0' | translate" width="45px"></iw-button>
        </div>
        <div class="col-xs-12 padding-top-10"></div>
        <div class="col-xs-2 column">
            <iw-checkbox #received [(value)]="ppempdoc.received" [label]="'received' | translate"
                         class="iw-input flex-align-end"></iw-checkbox>
        </div>
        <div class="col-xs-6 column">
            <iw-date-picker #dateReceived [(value)]="ppempdoc.dateRece" [isDisabled]="!isDocumentReceived"
                            class="iw-input flex-align-end"></iw-date-picker>
        </div>
        <div class="col-xs-4"></div>
        <div class="col-xs-2 padding-top-10">
            <iw-checkbox #mandatory [(value)]="ppempdoc.oblig" [isDisabled]="true" [label]="'mandatory' | translate"
                         class="iw-input flex-align-end"></iw-checkbox>
        </div>
    </div>
</iw-modal-form>
