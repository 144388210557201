import {createMissionRoles} from '@app/sam-base/core/auth/access-rules/ppemp-context-menu';
import {MenuItem} from '@app/sam-base/models';
import {contextMenuPrintRoles} from '@sam-base/core/auth/access-rules/client-form';
import * as clientRoles from '@sam-base/core/auth/access-rules/client-form';
import {PlacementEvent} from '../events.models';

export const ppcliContextMenu: MenuItem[] = [
    {
        label: 'ppcli_contextmenu_sendsms',
        event: PlacementEvent.PPCLI_SEND_SMS,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'all'
    },
    {
        label: 'ppcli_contextmenu_sendemail',
        event: PlacementEvent.SEND_EMAIL_CLIENT,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'all'
    },
    {
        label: 'ppcli_contextmenu_placement',
        event: '',
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppcli_contextmenu_orders',
        event: PlacementEvent.PPCLI_OPEN_PPCDE_GRID,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppcli_contextmenu_orders_fixe',
        event: PlacementEvent.PPCLI_OPEN_PPCDFS_GRID,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppcli_contextmenu_print',
        event: PlacementEvent.PRINT_REPORT_CLI,
        accessRoles: clientRoles.contextMenuPrintRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'contextmenu_suivi',
        event: PlacementEvent.PPSUI_NEW,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'contextmenu_mission',
        event: PlacementEvent.PPMISS_NEW,
        contextMenuVisibleMode: 'single',
        accessRoles: createMissionRoles()
    },
    {
        label: 'context_menu_mission',
        event: PlacementEvent.PPCLI_OPEN_MISSION,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'context_menu_rapports',
        event: PlacementEvent.PPCLI_OPEN_RAPPORTS,
        contextMenuVisibleMode: 'single'
    }];
