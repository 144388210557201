import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppagidet')
export class Ppagidet {
    @IwRestEntityId() public agiId?: string;

    @IwColumn({index: 1, name: 'H.', decimals: 2}) public nbheu?: number;

    public misId?: string;
    public lprov13?: string;

    @IwColumn({index: 2, name: 'Base', decimals: 2}) public salBase?: number;

    @IwColumn({index: 0, name: 'Taux (%)', decimals: 1}) public txheu?: number;

    public mntJf?: number;
    public mntVac?: number;

    @IwColumn({index: 6, name: 'Global', decimals: 2}) public salGlobal?: number;

    public mnt13?: number;

    @IwColumn({index: 7, name: 'SalAVS', decimals: 2}) public salAvs?: number;

    public lprovVac?: string;

    @IwColumn({index: 3, name: 'Vac. (%)', decimals: 2}) public txVac?: number;

    @IwColumn({index: 5, name: 'J.F. (%)', decimals: 2}) public txJf?: number;

    public lprovJf?: string;

    @IwColumn({index: 4, name: '13e (%)', decimals: 2}) public tx13?: number;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
