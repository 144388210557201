import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

import {RestApiService} from '../../rest-api';
import {ToastService} from '../../toast';
import * as actions from '../actions/entity.actions';

@Injectable()
export class EntityEffects<T> {

    public load$ = createEffect(() => this._actions.pipe(ofType(actions.ENTITY_LOAD), mergeMap(action => {
        const client = this._restService.getEntityClient(action.entityType);
        return client
            .getById(action.entityId.toString())
            .pipe(map(e => new actions.EntityLoadSuccess(e)), catchError((err) => of(new actions.EntityLoadFail(action.entityType, action.entityId, err))));
    })));

    public update$ = createEffect(() => this._actions.pipe(ofType(actions.ENTITY_UPDATE), mergeMap(action => {
        const client = this._restService
            .getEntityClient<T>(action.entity);
        return client
            .update(action.entity)
            .pipe(map(e => new actions.EntityUpdateSuccess(e)), catchError((err) => of(new actions.EntityUpdateFail(action.entity, err))));
    })));

    public create$ = createEffect(() => this._actions.pipe(ofType(actions.ENTITY_CREATE), mergeMap(action => {
        const client = this._restService
            .getEntityClient<T>(action.entity);
        return client
            .create(action.entity)
            .pipe(map(e => new actions.EntityCreateSuccess(e)), catchError((err) => of(new actions.EntityCreateFail(action.entity, err))));
    })));

    public delete$ = createEffect(() => this._actions.pipe(ofType(actions.ENTITY_DELETE), mergeMap(action => {
        const client = this._restService
            .getEntityClient<T>(action.entityType);
        return client
            .delete(action.entityId.toString())
            .pipe(map(() => new actions.EntityDeleteSuccess(action.entityType, action.entityId)), catchError((err) => of(new actions.EntityDeleteFail(action.entityType, action.entityId, err))));
    })));

    public onError$ = createEffect(() => this._actions.pipe(ofType(actions.ENTITY_CREATE_FAIL, actions.ENTITY_DELETE_FAIL, actions.ENTITY_LOAD_FAIL, actions.ENTITY_UPDATE_FAIL), map(e => {
        this._toast.error(this._translate.instant('entity_action_fail'), e.error.message);
        return {type: 'toast_show'};
    })));

    public onSuccess$ = createEffect(() => this._actions.pipe(ofType(actions.ENTITY_CREATE_SUCCESS, actions.ENTITY_DELETE_SUCCESS, actions.ENTITY_LOAD_SUCCESS, actions.ENTITY_UPDATE_SUCCESS), map(e => ({type: 'toast_show'}))));

    constructor(private readonly _actions: Actions<actions.EntityActions<T>>, private readonly _restService: RestApiService, private readonly _toast: ToastService, private _translate: TranslateService) {
    }

}
