import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppmisindview')
export class Ppmisind {
    @IwRestEntityId() public misId?: string;
    @IwColumn({index: 0, name: 'id'}) public indId?: string;
    @IwColumn({index: 1, name: 'date', type: 'date'}) public indDate?: string;
    @IwColumn({index: 2, name: 'type'}) public griId?: string;
    @IwColumn({index: 3}) public calcul?: string;
    @IwColumn({index: 4, name: 'periode'}) public period?: string;
    @IwColumn({index: 5}) public montant?: string;
    @IwColumn({index: 6}) public libelle?: string;
    @IwColumn({index: 7}) public mc?: string;
    @IwColumn({index: 8}) public session?: string;
}
