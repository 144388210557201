import {
    IwColumn
} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppagi')
export class Ppagi {
    @IwRestEntityId() @IwColumn({index: 0, name: 'att_no'}) public agiId?: string;
    public h6?: string;
    public lactfinful?: string;
    public h1?: string;
    public lactgoon?: string;
    public h22?: string;
    public indMan?: number;
    public h27?: string;
    public totBase?: number;
    public supJf?: number;
    public lallocdem?: string;
    public h11?: string;
    public h20?: string;
    public h31?: string;
    public tot13?: number;
    public crefusplus?: string;
    public h4?: string;
    public lallocnon?: string;
    public h21?: string;
    public lvac?: string;
    public nbsalh?: number;
    public lactgoond?: string;
    public profession?: string;
    public lallocoui?: string;
    public h15?: string;
    public datePrt?: string;
    public indJf?: number;
    public indCm?: number;
    public indApg?: number;
    public txVac?: number;
    public h18?: string;
    @IwColumn({index: 2, name: 'mois'}) public salId?: string;
    public totVac?: number;
    public cconvcoll?: string;
    public totJf?: number;
    public salavsforc?: number;
    public totheurap?: number;
    public h12?: string;
    public nbofprint?: number;
    public h23?: string;
    public notesint?: string;
    public lconvcoll?: string;
    @IwColumn({index: 1, name: 'date', type: 'date'}) public dateagi?: string;
    public caissecomp?: string;
    public revactind?: number;
    public h30?: string;
    public tx13?: number;
    public h8?: string;
    public indOth?: number;
    public ldureehebd?: string;
    public allfornb?: number;
    public remarques?: string;
    public h3?: string;
    public h28?: string;
    public h13?: string;
    public totheu?: number;
    public h17?: string;
    public h19?: string;
    public jwithraps?: string;
    public h25?: string;
    public h29?: string;
    public allnb?: number;
    public misId?: string;
    public agistatus?: number;
    public cactnogow?: string;
    public supVac?: number;
    public allpx?: number;
    public lplusdheu?: string;
    public lactnogo?: string;
    public pdfname?: string;
    public h2?: string;
    public lactfinpar?: string;
    public modecalc?: number;
    public salH?: number;
    public salhormode?: number;
    public indCna?: number;
    public h7?: string;
    public cactnogop?: string;
    public salM?: number;
    public sup13?: number;
    public txJf?: number;
    public caisselpp?: string;
    public totheuman?: number;
    public modecreat?: string;
    public dedLpp?: number;
    public cnolppwhy?: string;
    public lactfincho?: string;
    public cactnogoq?: string;
    public cdureehebd?: string;
    public dtanymodif?: string;
    public lforceavs?: string;
    public datemail?: string;
    @IwColumn({index: 1, name: 'emp_id'}) public empId?: string;
    public h10?: string;
    public indemnites?: number;
    public lsoumislpp?: string;
    public ageId?: string;
    public pdftplname?: string;
    public caissecho?: string;
    public allenfnb?: number;
    public dactgoond?: string;
    public mntAll?: number;
    public cdurhebdn?: string;
    public callocnon?: string;
    public h16?: string;
    public ljf?: string;
    public indEq?: number;
    public h9?: string;
    public allforpx?: number;
    public h26?: string;
    public salIdSal?: string;
    public cplusdheu?: string;
    public salavs?: number;
    public h24?: string;
    public nbofmail?: number;
    public h14?: string;
    public salBase?: number;
    public allenfpx?: number;
    public h5?: string;
    public l13?: string;
    public cactnogom?: string;
    public userId?: string;
    public agiData?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
