import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {Location, MapsResponse} from '../../models/maps-response.model';
import {RestClient} from '../rest-api/core';

function mapCoords(e: MapsResponse): Location | undefined {
    if (e.results[0]) {
        return e.results[0].geometry.location;
    }
    return undefined;
}

@Injectable()
export class MapsService extends RestClient<string> {

    private _key?: string;

    constructor(http: HttpClient) {
        super(http);
    }

    public setApiKey(key: string) {
        this._key = key;
    }

    public getGeolocation(address: string) {
        return this.GET<MapsResponse>(undefined, `/maps/${address}`);
    }

    public getCoordenates(address: string) {
        return this.getGeolocation(address)
            .pipe(map(mapCoords));
    }
}

