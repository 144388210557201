import {RestQueryOperation, RestQueryParam} from '../models';

/** Helper class to build queries for rest entities */
export class QueryBuilder<T> {
    private readonly _queries: RestQueryParam<T, any>[];

    constructor(...queries: RestQueryParam<T, any>[]) {
        this._queries = queries;
    }

    /** Current queries */
    public get queries() {
        return [...this._queries];
    }

    /**
     * Add a new query to list
     *
     * @param key key of entity to apply query
     * @param value value to query
     * @param op operation to use, equals by default
     */
    public addQuery<K>(key: keyof T, value: K, op: RestQueryOperation = RestQueryOperation.Equals) {
        this._queries.push({
            operation: op, prop: key, value
        });
    }

    /** Return list of queries in format to be used on the request */
    public getRestParams(): string[] {
        return this._queries
            .map(s => this.convertToParam(s));
    }

    private convertToParam(q: RestQueryParam<T, any>): string {
        return `${String(q.prop)},${q.operation}=${q.value}`;
    }
}
