import {Injectable} from '@angular/core';
import {PrintReportService} from '@app/sam-base/core/services/print-report.service';

import {AppState, IwStoreService, StartEditMode, StartReadMode} from '../../store';
import {FormEntitySave} from '../../store/actions/form-entity.actions';
import {FormEvents} from '../actions';
import {StoreEvents} from '../actions/store.actions';
import {IwEventHandler, IwEventOpenModalPayload} from '../core';
import {IwEventHubService} from '../core/iw-event-hub.service';

@Injectable()
export class FormEventHandlers {

    public constructor(private _store: IwStoreService, private _events: IwEventHubService<StoreEvents>,
                       private _reports: PrintReportService) {
    }

    @IwEventHandler(FormEvents.setEditMode)
    public setEditMode(state: AppState) {
        if (state.dialogState.formId) {
            this._store.dispatch(new StartEditMode(state.dialogState.formId));
        }
    }

    @IwEventHandler(FormEvents.setReadMode)
    public setReadMode(state: AppState) {
        if (state.dialogState.formId) {
            this._events.emit(StoreEvents.FormEntityStoreCancelEdit, state.dialogState.formId);
            this._store.dispatch(new StartReadMode(state.dialogState.formId));
        }
    }

    @IwEventHandler(FormEvents.saveCurrentEntity)
    public saveEntity(state: AppState) {
        if (state.dialogState.formId) {
            this._store.dispatch(new StartReadMode(state.dialogState.formId));
            this._store.dispatch(new FormEntitySave(state.dialogState.formId));
        }
    }

    @IwEventHandler(FormEvents.removeCurrentEntity)
    public delEntity(_: AppState) {
        // eslint-disable-next-line no-console
        console.warn('IMPLEMENT DELETE!');
    }

    @IwEventHandler(FormEvents.openPrintModal)
    public async openPrintModal(state: AppState, printEntity: IwEventOpenModalPayload<any>) {
        this._reports.getPdfReport(printEntity.report, printEntity.entityName, printEntity.entityId || '', printEntity.entity, printEntity.typeOfPrint, printEntity.templateId);
    }

}
