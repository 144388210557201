import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {createEntity} from '@app/sam-base/core/rest-api';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {OptionalDictionary} from '@app/sam-base/models';
import {Ppkw} from '@app/sam-base/models/placement/ppkw';
import {Observable} from 'rxjs';

type KeywordType = 'ppcli' | 'ppemp' | 'ppcde' | 'ppcdf';

@Injectable()
export class PpkwService extends RestClient<Ppkw[]> {

    public readonly store: OptionalDictionary<Ppkw[]> = {};

    constructor(_httpClient: HttpClient) {
        super(_httpClient);
    }

    public create(keyword: string): Ppkw {
        return createEntity(Ppkw, {
            kw: keyword
        });
    }

    public getKeywordByName(name: string) {
        return this.GET<Ppkw>(undefined, `/keywords/${name}`);
    }

    public getKeywords(id: string, type: KeywordType) {
        return this.GET<Ppkw[]>(undefined, `/keywords/${type}/${id}`);
    }

    public saveKeywords(id: string, keywords: Ppkw[], type: string): Observable<boolean> {
        return this.POST<boolean>(keywords, undefined, `/keywords/${type}/${id}`);
    }
}
