import {Observable, of, OperatorFunction} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ERROR} from './logger';

/**
 * Catch error and map it to undefined
 * Write a error message
 *
 * @export
 * @param message message to log
 */
export function mapError<T>(message?: string): OperatorFunction<T, T | undefined> {
    return catchError<T, Observable<undefined>>((err: any) => {
        ERROR(message, err);
        return of(undefined);
    });
}

/**
 * Catch error and map it to [defaultValue]
 *
 * @export
 * @template T
 * @param defaultValue
 * @param [message]
 * @returns
 */
export function mapErrorTo<T, R>(defaultValue: R, message?: string): OperatorFunction<T, T | R> {
    return catchError<T, Observable<R>>(err => {
        ERROR(message, err);
        return of(defaultValue);
    });
}
