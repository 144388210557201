import {ElementRef, Injectable} from '@angular/core';
import {ERROR} from '@app/sam-base/core/logger/logger';

import {UuidService} from '../../uuid/uuid.service';

/** Service to manipualte `ElementRef` */
@Injectable()
export class ElementRefService {
    constructor(private readonly _uuid: UuidService) {
    }

    /** If not present, Assigns an ID and return the current ID */
    public assignId(elRef: ElementRef): string {
        if (!elRef.nativeElement?.id) {
            const id = this._uuid.generateDomId();
            elRef.nativeElement.id = id;
            return id;
        }

        return elRef.nativeElement.id;
    }

    /** Return element using a ElementRef as reference */
    public querySelector(elRef: ElementRef, selector?: string): Element | undefined {
        try {
            const id = this.assignId(elRef);
            return document.querySelector(`#${id} ${selector || ''}`) || undefined;
        } catch (err) {
            ERROR('Error quering element: ', err);
            return;
        }
    }

    /** Bring selected element to view */
    public scrollIntoView(elRef: ElementRef, selector?: string) {
        const elem = this.querySelector(elRef, selector);
        if (elem) {
            elem.scrollIntoView({block: 'nearest'});
        }
    }

    /** Set focus to elem */
    public focus(elRef: ElementRef, selector?: string) {
        const elem = this.querySelector(elRef, selector);
        if (elem && (<any>elem).focus) {
            (<any>elem).focus();
        }
    }

    /** Enable focus in component */
    public enableFocus(elRef: ElementRef, selector?: string) {
        const elem = this.querySelector(elRef, selector);
        if (elem) {
            elem.setAttribute('tabIndex', '0');
        }
    }
}
