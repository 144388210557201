import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {Config} from '../../models/config.model';

/** Service to retrieve configuration headers */
@Injectable()
export class ConfigService {
    private _config?: Config;

    constructor(private readonly _http: HttpClient) {
    }

    /** Return configuration, it will use cached value if exist */
    public getConfig(): Observable<Config> {
        if (this._config) {
            return of(this._config);
        }

        return this.requestConfig()
            .pipe(map(h => {
                this._config = h;
                return h;
            }));
    }

    private requestConfig(): Observable<Config> {
        return this._http.get<Config>('api/config');
    }
}
