import {KeyModifier} from '../models/key-modifier.model';

export function isModifier(modifier: KeyModifier, value: KeyModifier): boolean {
    // eslint-disable-next-line no-bitwise
    return !!(modifier & value);
}

export const isCtrl = (mod: KeyModifier) => isModifier(KeyModifier.ctrl, mod);
export const isMeta = (mod: KeyModifier) => isModifier(KeyModifier.meta, mod);
export const isShift = (mod: KeyModifier) => isModifier(KeyModifier.shift, mod);
export const isAlt = (mod: KeyModifier) => isModifier(KeyModifier.alt, mod);
