import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('frxlist', '', 'common')
export class Frxlist {
    @IwRestEntityId() public frxtype?: string;
    public frxfile?: string;
    public frxdir?: string;
    public lspool?: boolean;
    public lcustom?: boolean;
    public lsetup?: boolean;
    public frxrepbeha?: string;
    public frxname?: string;
    public frxData?: string;
    public gesallwd?: string;
    public notes?: string;
    public frxname1?: string;
    public frxname2?: string;
    public frxname3?: string;
    public frxname4?: string;
    public frxname5?: string;
}
