import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppprasaempsal')
export class Ppprasaempsal {
    @IwRestEntityId() public salId?: string;
    public empId?: string;
    public dateCreat?: string;
    public sal?: string;
    public mutation?: string;
    public dateModif?: string;
    public entree?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public base?: string;
    public langue?: string;
    public note?: string;
    public dateDelet?: string;
    public sortie?: string;
    public degreinv?: string;
}
