import {SamUserRole} from '@sam-base/models/admin/sam-user';

/**
 * Retrieves a list of access roles that have permissions for client block actions.
 *
 * @return {SamUserRole[]} An array of user roles that include SAM_PLACEMENT_RW, SAM_PLACEMENT_ADMIN, and SAM_ADMIN_GOD_MODE.
 */
export function clientBockActionAccessRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Retrieves a list of user roles that are granted access to the client unblock functionality.
 *
 * @return {SamUserRole[]} An array of roles which include SAM_PLACEMENT_CLIENT_ADMIN and SAM_ADMIN_GOD_MODE.
 */
export function clientUnblockAccessRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_CLIENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_PARAM,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Retrieves a list of roles that have access to client communication.
 * @return {SamUserRole[]} An array of SamUserRole values that specify permitted roles.
 */
export function clientCommunicationAccessRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_COM,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

export function contextMenuPrintRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_COM,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Defines the roles that can use mission and plac fixe button
 */
export function clientOpenMissionCommandRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Retrieves a list of specific user roles related to client commands and dossier management.
 *
 * @return {SamUserRole[]} An array of user roles which include SAM_PLACEMENT_RO,
 *                         SAM_PLACEMENT_RW, SAM_PLACEMENT_ADMIN, and SAM_ADMIN_GOD_MODE.
 */
export function clientCommandeAndDossierRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Defines the roles that can use button stat
 * from client side menu
 */
export function clientStatisticsRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Defines the roles that can use button situation
 * from client side menu
 */
export function clientPrintRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_PRINT,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Defines the roles that can use button situation
 * from client side menu
 */
export function clientSituationRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Retrieves a list of user roles that are authorized for client hours operations.
 *
 * @return {SamUserRole[]} An array of `SamUserRole` enums, which includes:
 *         `SAM_PLACEMENT_RO`, `SAM_PLACEMENT_RW`, `SAM_PLACEMENT_ADMIN`, and `SAM_ADMIN_GOD_MODE`.
 */
export function clientHoursRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Returns an array of roles for the client site debiteur.
 *
 * @return {SamUserRole[]} An array of SamUserRole enums representing the roles.
 */
export function clientSiteDebiteurRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}
