import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppabs')
export class Ppabs {
    @IwRestEntityId() public absId?: string;
    public date?: string;
    public ijplaNote?: string;
    public indId?: string;
    public planNote?: string;
    public ijplaCode?: number;
    public empId?: string;
    public timeslot?: string;
    public absData?: string;
    public planCode?: number;
    public absCode?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
