import {getDeepCopy} from '../../../helpers/objects-parser';
import {GridProfile} from '../../grid-profile/models';
import * as actions from '../actions/grid-profiles.actions';
import {GridProfilesState, GridProfilesStore} from '../models';

const defaultInitialState: GridProfilesState = {
    $dynamic: {}, $local: {}
};

export function reducer(state = defaultInitialState, action: actions.GridProfileActions): GridProfilesState {
    switch (action.type) {
        case actions.PROFILE_LOAD_LIST_SUCCESS: {
            return {
                ...state, $dynamic: mapProfiles({}, action.payload)
            };
        }
        case actions.PROFILE_ADD_LOCAL: {
            const $local = mapProfiles(state.$local, addLocalId(action.payload));
            return {
                ...state, $local
            };
        }
        case actions.PROFILE_UPDATE_DEFAULT_SUCCESS: {
            const updatedProfileIndex = state.$dynamic[action.payload.entity]
                .findIndex(profile => profile.cfgid === action.payload.cfgid);
            const updatedProfiles: GridProfile<any>[] = getDeepCopy(state.$dynamic[action.payload.entity]);
            updatedProfiles[updatedProfileIndex].defaultProfile = action.payload.defaultProfile;
            return {
                ...state, $dynamic: {
                    [action.payload.entity]: updatedProfiles
                }
            };
        }
    }

    return state;
}

/** Add local id to profile */
function addLocalId<T>(list: GridProfile<T>[]): GridProfile<T>[] {
    return list.map(e => {
        e.cfgid = getId(e.cfgid);
        return e;
    });
}

/** Map list to grid profile store */
function mapProfiles<T>(store: GridProfilesStore<T> = {}, list: GridProfile<T>[]): GridProfilesStore<T> {
    return list.reduce((_store, profile) => insertProfile(_store, profile), store);
}

function insertProfile<T>(store: GridProfilesStore<T>, p?: GridProfile<T>): GridProfilesStore<T> {
    if (p) {
        return {
            ...store, [p.entity || '']: [...(store[p.entity || ''] || []), p]
        };
    }

    return store;
}

function getId(cfgId?: string) {
    return cfgId || '$local_' + (Date.now() + Math.random());
}
