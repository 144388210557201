/**
 * Defines class as an entity
 * set the path by default as the class name in lowercase
 *
 * @param name entity path
 */
export function IwRestEntity(name?: string, prefix?: string, schema?: 'common') {
    return <T extends new(...args: any[]) => {}>(constructor: T) => class extends constructor {
        public readonly $entity = name || constructor.name.toLowerCase();
        public readonly $prefix = prefix || '';
        public readonly $pk = constructor.prototype.$pk;

        /** This allow the object to be instaciated from another object */
        constructor(...args: any[]) {
            super(...args);

            // eslint-disable-next-line complexity
            args.forEach(o => {
                if (o && typeof o === 'object') {
                    for (const [key, value] of Object.entries(o)) {
                        if (!key.length || (key[0] === '$')) {
                            continue;
                        }
                        (<any>this)[key] = value;
                    }
                }
            });
        }
    };
}
