<iw-modal-form (pressCancel)="onPressCancel()"
               (pressOk)="onPressOk()"
               [btnCancel]="cancelShow"
               [btnOk]="okDisabled"
               [height]="'auto'"
               [title]="title"
               [width]="width">
    <ng-container *ngIf="imgPath; then imgTemplate else defaultOrTextAreaTemplate"></ng-container>
</iw-modal-form>

<ng-template #defaultOrTextAreaTemplate>
    <ng-container *ngIf="textarea; then textareaTemplate; else defaultTemplate"></ng-container>
</ng-template>
<ng-template #defaultTemplate>
    <div *ngIf="!multiline">
        <p *ngIf="message.length > 0"
           class="margin-left-5"><strong>{{ alertsMessage }}</strong></p>
        <ol *ngIf="message.length > 1">
            <li *ngFor="let msg of message"
                class="margin-top-5">
                {{ msg | translate }}
            </li>
        </ol>
        <div *ngIf="message.length === 1"
             class="margin-left-5 margin-top-10">{{ message[0] | translate }}
        </div>
        <div class="margin-left-5"
             id="confirm-message"><strong>{{ confirmMessage }}</strong></div>
        <div class="margin-top-5"></div>
    </div>
    <div *ngIf="multiline">
        <div *ngIf="message.length > 1"
             class="margin-left-10 margin-right-10 margin-top-10">
            <div *ngFor="let msg of message">
                {{ msg | translate }}
            </div>
        </div>
        <div *ngIf="message.length === 1"
             class="margin-left-5 margin-top-10">{{ message }}
        </div>
        <div class="margin-top-5"></div>
    </div>
</ng-template>

<ng-template #imgTemplate>
    <div [ngStyle]="style"
         class="row">
        <div class="col-xs-4">
            <img [alt]="'noImg'"
                 [src]="imgPath"
                 style="width: 75%; margin-top: 10px;">
        </div>

        <div class="col-xs-8 margin-top-10">
            <p *ngIf="message.length > 0"><strong>{{ alertsMessage }}</strong></p>
            <ng-container *ngIf="message.length > 1">
                <div *ngFor="let msg of message"
                     class="margin-top-5">
                    {{ msg }}
                </div>
            </ng-container>
            <div *ngIf="message.length === 1">{{ message }}</div>
            <div class="margin-left-5"
                 id="confirm-message"><strong>{{ confirmMessage }}</strong></div>
        </div>
    </div>

</ng-template>
<ng-template #textareaTemplate>
    <iw-textarea #edtSalary
                 [height]="300"
                 [isDisabled]="true"
                 [value]="message"
                 class="iw-textarea margin-left-5 col-xs-12"></iw-textarea>
    <div class="margin-left-5"
         id="confirm-message"><strong>{{ confirmMessage }}</strong></div>
    <div class="margin-top-5"></div>
</ng-template>

