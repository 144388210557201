<form [formGroup]="formGroup"
      class="row padding-5"
      style="border: 1px solid rgba(0,0,0,0.5);">
    <div class="col-xs-6">
        <div class="row"
             style="height: 100%;">
            <div class="row col-xs-4">
                <iw-multiautocomplete-qualif #txtProfession
                                             [doubleClickable]="true"
                                             [labelAlign]="'top'"
                                             [label]="'professionQualification' | translate"
                                             [multiple]="true"
                                             class="iw-input col-xs-12 col-no-padding"
                                             formControlName="professions"></iw-multiautocomplete-qualif>

                <iw-textfield #edtRegions
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'regions' | translate"
                              [value]="regionsVal"
                              class="iw-input col-xs-10 col-no-padding flex-end"></iw-textfield>
                <iw-button #btnRegions
                           (press)="openRegionsSelection()"
                           [text]="'TEXT' | translate"
                           class="iw-button col-xs-2 without-label no-padding flex-end"></iw-button>
            </div>

            <div class="col-xs-4">
                <iw-ppkw-matching #kwMatching
                                  [allowNewEntries]="false"
                                  [isReadonly]="false"
                                  [label]="'mots_clefs' | translate"
                                  class="col-xs-8 no-padding"></iw-ppkw-matching>
            </div>

        </div>
    </div>

    <div class="col-xs-5">
        <div class="row">
            <div class="col-xs-5">
                <div class="row">
                    <iw-date-picker #txtDateFrom
                                    [labelAlign]="'top'"
                                    [label]="'datedebut' | translate"
                                    class="iw-input col-xs-6"
                                    formControlName="datefrom"></iw-date-picker>
                    <iw-date-picker #txtDateTo
                                    [labelAlign]="'top'"
                                    [label]="'datefin' | translate"
                                    class="iw-input col-xs-6"
                                    formControlName="dateto"></iw-date-picker>
                </div>
            </div>
            <div class="col-xs-7"
                 style="margin-top: 24px;">
                <iw-weekday-picker #weekdayPicker></iw-weekday-picker>
            </div>
        </div>
        <div class="row"
             style="margin-top: 15px;">
            <iw-time-slots #timeslotsPicker
                           (timeSlotsUpdated)="setTimeSlot($event)"></iw-time-slots>
        </div>
    </div>
    <div class="row col-xs-1 flex flex-end">
        <div class="row"
             style="padding: 0 14px; margin-top: 15px;">
            <iw-button (press)="clearSearch()"
                       [text]="'adv_search_clear_btn' | translate"
                       [width]="90"></iw-button>
        </div>
        <div class="row"
             style="padding: 0 14px; margin-top: 15px;">
            <iw-button (press)="applySearch()"
                       [text]="'adv_search_match_btn' | translate"
                       [width]="90"></iw-button>
        </div>
        <div class="row"
             style="padding: 0 14px; margin-top: 15px;">
            <iw-button (press)="openPlanning()"
                       [isDisabled]="isBtnPlanningDisabled()"
                       [text]="'adv_search_planning_btn' | translate"
                       [width]="90"></iw-button>
        </div>
    </div>
</form>
<iw-planning (closeDialogEvent)=closeDialogPlanning()
             *ngIf="showPlanningPanel"
             [result]="result"
             [startDate]="planningDateFrom"></iw-planning><!-- PLANNING FOR AN EMPLOYEE -->
<iw-planning (closeDialogEvent)="showEmployePlanning = false"
             *ngIf="showEmployePlanning"
             [result]="[planningEmployee]"
             [startDate]="planningDateFrom"></iw-planning>
