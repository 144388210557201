import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppclimis')
export class Pprapmisact {
    @IwColumn({name: 'mission_no', index: 0}) @IwRestEntityId() public misId?: string;
    @IwColumn({name: 'employe', index: 1}) public nomemploye?: string;
    @IwColumn({name: 'client', index: 2}) public nomclient?: string;
    @IwColumn({name: 'debut', index: 3, type: 'date'}) public datedebut?: string;
    @IwColumn({name: 'duree', index: 4}) public duree?: string;
    @IwColumn({name: 'agence_short', index: 5}) public ageId?: string;
    @IwColumn({name: 'secteur_short', index: 6}) public secId?: string;
    @IwColumn({name: 'cct', index: 7}) public cctId?: string;
    @IwColumn({name: 'status', index: 8, type: 'rapMisStatus'}) public misstatustext?: number;
    @IwColumn({name: 'fin', index: 9, type: 'date'}) public datefin?: string;
    @IwColumn({name: 'chantier', index: 10}) public chantier?: string;
    @IwColumn({name: 'emp_no', index: 11}) public empId?: string;
    @IwColumn({name: 'cli_no', index: 12}) public cliId?: string;
    @IwColumn({index: 13}) public misTermin?: boolean; // MISSING

    public misstatus?: number;
}
