import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Saparded} from '@app/sam-base/models/salary';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class SapardedService extends RestClient<any> {
    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getInfoText(dedCode: string): Observable<string> {
        return this._http.get(this._baseUrl + `saparded/${dedCode}/informations`, {responseType: 'text'});
    }

    public getDedCodeTitreByDedCode(dedCode: string): Observable<string> {
        return this._http.get(this._baseUrl + `saparded/titre/${dedCode}`, {responseType: 'text'});
    }

    public getTauxAutoByDedCode(dedCode: string): Observable<string> {
        return this._http.get(this._baseUrl + `saparded/taux/${dedCode}`, {responseType: 'text'});
    }

    public getAssuranceByAnnee(dedCode: string): Observable<string[]> {
        return this._http.get<string[]>(this._baseUrl + `saparded/assCode/${dedCode}`);
    }

    public getDedCodes(): Observable<string[]> {
        return this._http.get<string[]>(this._baseUrl + `saparded/dedCodes`);
    }

    public canAddDeduction(ded: Saparded): Observable<boolean> {
        return this._http.post<boolean>(this._baseUrl + `saparded/canAdd`, ded);
    }

    public canDeleteDeduction(ded: Saparded): Observable<boolean> {
        return this._http.post<boolean>(this._baseUrl + `saparded/canDelete`, ded);
    }
}
