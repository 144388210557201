<iw-modal-form [btnCancel]="false"
               [btnOk]="false"
               [height]="260"
               [title]="title"
               [width]="460">
    <form [formGroup]="miscpyForm"
          class="row space-form">

        <iw-textfield #txtMis_id
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'missionNo' | translate"
                      [ngClass]="'mis-color-2'"
                      class="iw-input col-xs-4 col-no-padding"
                      formControlName="misId"></iw-textfield>

        <div class="col-xs-3 col-xs-offset-5 margin-top-5">
            <div class="row margin-top-5">
                <iw-button #btnOk
                           (press)="showModal()"
                           [isDisabled]="isOkDisabled()"
                           [text]="'ok' | translate"
                           class="btn-block"></iw-button>
            </div>

            <div class="row margin-top-5">
                <iw-button #Standardbutton2
                           (press)="onPressCancel()"
                           [text]="'annuler' | translate"
                           class="btn-block "></iw-button>
            </div>

        </div>

        <iw-lookups-dropdown #cboXaveMotif
                             [labelAlign]="'top'"
                             [label]="'motif' | translate"
                             [lkname]="'MISAVEMOTIF'"
                             class="col-xs-8 padding-5"
                             formControlName="avemotif"></iw-lookups-dropdown>

        <iw-checkbox #chkLavenant
                     [label]="'avenant' | translate"
                     class="iw-input col-xs-3 col-xs-offset-1 margin-top-25"
                     formControlName="lavenant"></iw-checkbox>

        <iw-checkbox #chkLotherEmp
                     (valueChange)="setEmployee()"
                     [label]="'autreEmploye' | translate"
                     class="iw-input col-xs-4"
                     formControlName="lotheremp"></iw-checkbox>

        <!-- disable search if  autre employé is checked -->
        <iw-entity-textfield #ctnSelEmp
                             [allowSearch]="isOtherEmployeeChecked()"
                             [entity]="entityEmploye"
                             [name]="['nom', 'prenom']"
                             [showIdField]="false"
                             class="iw-input col-xs-8"
                             formControlName="empId"></iw-entity-textfield>

        <iw-checkbox #chkLotherCli
                     (valueChange)="setClient()"
                     [label]="'autreClient' | translate"
                     class="iw-input col-xs-4"
                     formControlName="lothercli"></iw-checkbox>

        <iw-entity-textfield #ctnSelCli
                             [allowSearch]="isOtherClientChecked()"
                             [entity]="entityClient"
                             [name]="['nom']"
                             [showIdField]="false"
                             class="iw-input col-xs-8"
                             formControlName="cliId"></iw-entity-textfield>

    </form>
</iw-modal-form>
