import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId, SearchEntityStatusOperator} from '@app/sam-base/core/rest-api';

export enum GepaicliPayStatus {
    OPEN = 0, CLOSE = 1, WRONG = 9
}

const GepaicliPayStatusColorMap = {
    [GepaicliPayStatus.OPEN]: '#008000',
    [GepaicliPayStatus.CLOSE]: '#C0C0C0',
    [GepaicliPayStatus.WRONG]: '#ff8000',
};

@IwRestEntity('gepaicliview')
export class Gepaicliview {
    @IwRestEntityId()
    @IwColumn({
        name: 'combo_number',
        index: 0,
        colorMapper: (row: Gepaicliview) => GepaicliPayStatusColorMap[row['payStatus'] as GepaicliPayStatus] || ''
    })
    public payId?: string;
    @IwColumn({
        name: 'status',
        type: 'enum',
        statusQuery: [
            true,
            false,
            true],
        valueFormat: 'encaissement_status_',
        filterOperator: SearchEntityStatusOperator.NotInclude,
        index: 1
    })
    public payStatus?: GepaicliPayStatus;
    @IwColumn({
        name: 'date',
        type: 'date',
        index: 2
    })
    public datePay?: string;
    @IwColumn({
        name: 'client',
        index: 3
    })
    public nom?: string;
    @IwColumn({
        name: 'cli_id',
        index: 4
    })
    public cliId?: string;
    @IwColumn({
        name: 'bvr',
        index: 5
    })
    public bvrId?: string;
    @IwColumn({
        name: 'mode',
        index: 6
    })
    public mpaId?: string;
    @IwColumn({
        name: 'montant',
        align: 'right',
        type: 'mnt',
        index: 7
    })
    public montant?: string;
    @IwColumn({
        name: 'solde',
        align: 'right',
        type: 'mnt',
        index: 8
    })
    public solde?: string;
    @IwColumn({
        name: 'date_creat',
        type: 'dateTime',
        index: 9
    })
    public dateCreat?: string;
    @IwColumn({
        name: 'date_modif',
        type: 'dateTime',
        index: 10
    })
    public dateModif?: string;
}
