import {Injectable} from '@angular/core';

@Injectable()
export class ClipboardService {

    constructor() {
    }

    public addToClipboard(value: string) {
        document
            .addEventListener('copy', function listener(ev: ClipboardEvent) {
                if (ev.clipboardData) {
                    ev.clipboardData.setData('text/plain', value);
                    ev.preventDefault();
                }
                document.removeEventListener('copy', listener);
            });
        document.execCommand('copy');
    }
}
