import {IwColumn} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {IwRestEntity, IwRestEntityId, SearchEntityStringOperator} from '@app/sam-base/core/rest-api';

@IwRestEntity('saems')
export class Saems {
    @IwColumn() @IwRestEntityId() public id?: string;
    public empId?: string;
    @IwColumn({
        name: 'periode',
        index: 4,
        type: 'salId',
        filterQuery: '',
        filterOperator: SearchEntityStringOperator.Like
    })
    public salId?: string;
    public ficsalId?: string;
    public categorie1?: string;
    public categorie2?: string;
    public categorie3?: string;
    public session?: string;
    public usertag?: string;
    public cantontrav?: string;
    public cantonhab?: string;
    public cantonimp?: string;
    public centotal?: number;
    public abstotal?: number;
    public nbjavs?: number;
    public joursvac?: number;
    public soldevac?: number;
    public dateDe?: Date;
    public dateA?: Date;
    public dateisDe?: Date;
    public dateisA?: Date;
    public nbjimp?: number;
    public dateBs?: Date;
    public params?: string;
    public ppparams?: string;
    public sd4params?: string;
    public calculated?: boolean;
    public published?: boolean;
    public paid?: boolean;
    public bloque?: boolean;
    public mntnap?: number;
    public mntpaid?: number;
    public mntrep?: number;
    public nbofgen?: number;
    public nbofcalc?: number;
    public nbOfPublished?: number;
    public nbofprint?: number;
    public nbofmail?: number;
    public nbofsdij?: number;
    public salgenfreq?: number;
    public facId?: string;
    public facNo?: string;
    public sent2fac?: boolean;
    public isSxId?: string;
    public mailinfos?: string;
    public notesbs?: string;
    public notesint?: string;
    public emsLog?: string;
    public emsData?: string;
    public xmlData?: string;
    public calcrevno?: string;
    public modecreat?: string;
    public sxId?: string;
    public userCalc?: string;
    public userPrnt?: string;
    public dateCalc?: Date;
    public datePublished?: Date;
    public datePrnt?: Date;
    public dateMail?: Date;
    public dateSdij?: Date;
    public dateCreat?: Date;
    public userCreat?: string;
    public dateModif?: Date;
    public userModif?: string;
    public nbjTravTot?: number;
    public nbjTravCh?: number;

}
