import * as actions from '../actions/global-form.actions';
import {GlobalFormState, GlobalFormStore} from '../models';

// eslint-disable-next-line complexity
export function reducer<T>(store: GlobalFormStore = {}, action: actions.GlobalFormActions<T>): GlobalFormStore {

    switch (action.type) {
        case actions.GLOBAL_FORM_INIT: {
            const state = store[action.uuid];
            if (state && !!state.__init__) {
                return {...store};
            }

            return {
                ...store, [action.state.uuid]: {
                    ...action.state,
                    loading: !action.state.isNew,
                    __init__: action.state.isNew && !action.state.useNavigation
                }
            };
        }

        case actions.GLOBAL_FORM_INIT_WAITING: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, editMode: 'waiting', entityId: '', entity: undefined, loading: false
            }));
        }

        case actions.GLOBAL_FORM_INIT_WAIT_LOADING: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, editMode: 'waitLoading', entityId: '', entity: undefined, loading: true
            }));
        }

        case actions.GLOBAL_FORM_NAVIGATION_INIT: {
            return applyState<T>(store, action.uuid, s => ({
                ...s,
                navProfile: action.profile || s.navProfile,
                entityId: action.entityId || s.entityId,
                useNavigation: true
            }));
        }

        case actions.GLOBAL_FORM_NAVIGATION_INIT_SUCCESS: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, navValues: action.result
            }));
        }

        case actions.GLOBAL_FORM_NAVIGATION_ENTITY: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, entityId: action.id, loading: true
            }));
        }

        case actions.GLOBAL_FORM_ENTITY_LOAD: {
            return applyState<T>(store, action.uuid, s => {

                // Prevent entity change when not in read mode
                if (s.__init__ && s.editMode !== 'read') {
                    return {...s};
                }

                return {
                    ...s, loading: true
                };
            });
        }

        case actions.GLOBAL_FORM_ENTITY_LOAD_SUCCESS: {
            return applyState<T>(store, action.uuid, s => {

                // Prevent entity change when not in read mode
                if (s.__init__ && s.editMode === 'edit') {
                    return {...s};
                }

                return {
                    ...s, entity: action.value, loading: false, dirty: false, __init__: true
                };
            });
        }

        case actions.GLOBAL_FORM_SET_VALID: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, isValid: action.isValid
            }));
        }

        case actions.GLOBAL_FORM_SET_WAIT_MODE: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, isNew: false, editMode: 'waiting', entityId: '', entity: undefined, __init__: false
            }));
        }

        case actions.GLOBAL_FORM_SET_WAIT_MODE_WITH_DATA: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, editMode: 'waiting', __init__: false
            }));
        }

        case actions.GLOBAL_FORM_SET_READ_MODE: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, editMode: 'read', dirty: false
            }));
        }

        case actions.GLOBAL_FORM_SET_EDIT_MODE: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, editMode: 'edit'
            }));
        }

        case actions.GLOBAL_FORM_SET_NEW_MODE: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, isNew: true, editMode: 'edit', entity: undefined, entityId: ''
            }));
        }

        case actions.GLOBAL_FORM_SET_LOADING: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, loading: action.loading
            }));
        }

        case actions.GLOBAL_FORM_SET_WIDTH: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, width: action.width
            }));
        }

        case actions.GLOBAL_FORM_SET_CUSTOM_ENTITY_URL: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, customEntityURL: action.customEntityURL
            }));
        }

        case actions.GLOBAL_FORM_SET_READONLY: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, readonly: action.readonly
            }));
        }

        case actions.GLOBAL_FORM_ENTITY_UPDATE: {
            return applyState<T>(store, action.uuid, s => {
                // If is readonly, don't change the value
                if (s.readonly && !action.patch) {
                    return {...s};
                }

                return {
                    ...s, entity: action.value, dirty: true
                };
            });
        }

        case actions.GLOBAL_FORM_ENTITY_RESET: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, loading: true, editMode: 'read', dirty: false
            }));
        }

        case actions.GLOBAL_FORM_ENTITY_SAVE: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, editMode: 'read', dirty: false, loading: true, patch: action.patch, __save__: true
            }));
        }

        case actions.GLOBAL_FORM_ENTITY_SAVE_NEW_SUCCESS: {
            return applyState<T>(store, action.uuid, s => ({
                ...s,
                entityId: action.entityId,
                entity: action.entity,
                isNew: false,
                navValues: [...s.navValues, action.entityId],
                loading: false,
                editMode: 'read',
                dirty: false,
                __save__: false
            }));
        }

        case actions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS: {
            return applyState<T>(store, action.uuid, s => ({
                ...s, entity: action.entity, loading: false, editMode: 'read', dirty: false, __save__: false
            }));
        }

        case actions.GLOBAL_FORM_DESTROY: {
            const newState = {...store};
            delete newState[action.uuid];
            return newState;
        }
    }

    return store;
}

/** Check if uuid is valid and apply state change */
function applyState<T>(store: GlobalFormStore, uuid: string, reduce: (s: GlobalFormState<T>) => GlobalFormState<T>) {
    // Grab state for uuid
    const state = store[uuid];
    // If no state, return
    if (!state) {
        return {...store};
    }
    // If state, apply reduce fn
    const newState = reduce(state);
    // Return new state
    return {
        ...store, [uuid]: {...newState}
    };
}
