import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('cctdectype', 'common')
export class Cctdectype {
    @IwRestEntityId() public cctdectype?: string;
    public titre?: string;
    public titre2?: string;
    public titre3?: string;
    public titre4?: string;
    public titre5?: string;
}
