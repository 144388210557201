import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppcnt')
export class Ppcnt {
    @IwRestEntityId() public keyName?: string;
    public userDelet?: string;
    public padzero?: string;
    public userCreat?: string;
    public keyLen?: number;
    public userModif?: string;
    public keyValue?: string;
    public ageprefixe?: string;
}
