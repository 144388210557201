import {Type} from '@angular/core';
import {GridProfile} from '@app/sam-base/core/grid-profile/models';
import {EsQueryStatement, QueryBuilder} from '@app/sam-base/core/rest-api';
import {OptionalDictionary} from '@app/sam-base/models';

import {PrintFormat} from './print-entity';

export interface DynamicPrintParams extends OptionalDictionary<string> {
    p_t_title?: string;
}

export class DynamicPrintRequest<T> {

    public columns: string[];
    public entity: string;
    public language: string;
    public params: DynamicPrintParams;
    public groupBy: string;
    public esQuery: string;
    public format: PrintFormat;

    public type: Type<T>;

    constructor(profile: GridProfile<T>, entity: Type<T>, extraStatements: EsQueryStatement<T>[] = [], language = 'fr',
                params: DynamicPrintParams = {}, format: PrintFormat = 'pdf') {
        this.type = entity;
        this.columns = this.buildColumnArray(profile);

        this.entity = profile.entity;

        this.language = language;

        this.params = this.buildParams(profile, params);
        this.groupBy = (profile.columns.groupBy || '').toString();
        this.esQuery = this.buildJSONQuery(profile, extraStatements);

        this.format = format;
    }

    private buildJSONQuery(profile: GridProfile<T>, extraStatements: EsQueryStatement<T>[] = []): string {
        const queryBuilder = QueryBuilder.fromGridProfile(profile, this.type);
        queryBuilder.addStatement(...extraStatements);
        return JSON.stringify(queryBuilder.getRequest(10000));
    }

    private buildParams(profile: GridProfile<T>, params: DynamicPrintParams = {}): DynamicPrintParams {
        return {
            p_t_title: this.getTitleKey(profile.entity), ...params
        };
    }

    private getTitleKey(entityName: string): string {
        return `print_title_${entityName}`;
    }

    private buildColumnArray(profile: GridProfile<T>): string[] {
        return Object.values(profile.columns.available).map(e => e.prop as string);
    }
}