import {OperationEvents} from '@app/modules/sam-main/salary/events';
import * as roles from '@sam-base/core/auth/access-rules/facture-roles';
import {MenuItem} from '../../components';


export const gefacliContextMenu: MenuItem[] = [
    {
        label: 'facture-context-menu.create_note_credit',
        event: OperationEvents.CREATE_NOTE_CREDIT,
        accessRoles: roles.factureRWRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'facture-context-menu.download_facture',
        event: OperationEvents.DOWNLOAD_FACTURE,
        accessRoles: roles.facturePrintRoles(),
        contextMenuVisibleMode: 'all'
    },
    {
        label: 'facture-context-menu.send_mail',
        event: OperationEvents.FACTURE_EMAIL,
        accessRoles: roles.factureComRoles(),
        contextMenuVisibleMode: 'single',
        filter: {
            'published': true,
            'facByEmail': true
        }
    },
    {
        label: 'facture-context-menu.delete',
        event: OperationEvents.FACTURE_DELETE,
        accessRoles: roles.factureSuppressionRole(),
        contextMenuVisibleMode: 'all'
    }
];
