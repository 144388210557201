import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppckw')
export class Ppckw {
    @IwRestEntityId() public ckwId?: string;
    public kw?: string;
    public ctype?: string;
    public dateDelet?: string;
    public cid?: string;
    public userModif?: string;
    public userCreat?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public userDelet?: string;
}
