export interface ModalManualdtaOptions {
    showCancel?: boolean;
    title: string;
    confirmMessage?: string;
    okDisabled?: boolean;
    width?: number;
    height?: number;
    okColor?: string;
    cancelColor?: string;
    empId?: string;
    salId?: string;
    onOk?: (value: ModalManualdtaOptions) => void;
    onCancel?: () => void;
}

export enum EncaissementCreationMode {
    CAMT, MANUAL
}

export interface ManualDtaChoicemodal {
    mode?: EncaissementCreationMode;
}
