import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';

import {initEntity, isRestEntity, QueryBuilder, RestApiService} from '../../rest-api';
import {ToastService} from '../../toast';
import * as actions from '../actions/navigation.actions';
import {IwStoreService} from '../iw-store.service';
import {selectNavigation} from '../selectors';

@Injectable()
export class NavigationEffects {

    public initNavigation$ = createEffect(() => this._actions.pipe(ofType(actions.NAVIGATION_INIT), withLatestFrom(this._store, (action, state) => ({
        action, state
    })), mergeMap(({action}) => {
        const instance = new action.entity();
        if (!isRestEntity(instance)) {
            return buildInitFail('navigation_init_fail_entity');
        }

        const query = QueryBuilder.fromGridProfile(action.profile)
            .getRequest();
        delete query.from;
        query.size = 10000; // Max allowed items in elastic

        return this._restService.elasticSearchService
            .query(query)
            .pipe(map(resp => {
                const queryError = 'navigation_init_fail_query';
                if (resp.success) {
                    try {
                        const values = resp.result.hits.hits
                            .map(e => initEntity(action.entity, e._source)
                                .$getPk())
                            .filter(e => !!e); // Remove values without pk

                        const index = values.indexOf(action.startFromId || 0);
                        const position = index === -1 ? 0 : index;

                        return new actions.NavigationInitSuccess(action.uuid, action.entity, action.profile, position, values);
                    } catch (err) {
                        return new actions.NavigationInitFail(queryError);
                    }
                }

                return new actions.NavigationInitFail(queryError);
            }));
    })));

    public onNavigateNext$ = createEffect(() => this._actions.pipe(ofType(actions.NAVIGATION_NEXT), withLatestFrom(this._store, (action, state) => selectNavigation<any>(state, action.uuid)), filter(e => !!e), map((navStore) => {
        if (navStore) {
            const pos = navStore.position + 1;
            return new actions.NavigationGoToPostion(navStore.uuid, pos);
        }

        return buildUuidFail();
    })));

    public onNavigatePrev$ = createEffect(() => this._actions.pipe(ofType(actions.NAVIGATION_PREV), withLatestFrom(this._store, (action, state) => selectNavigation<any>(state, action.uuid)), filter(e => !!e), map((navStore) => {
        if (navStore) {
            const pos = navStore.position - 1;
            return new actions.NavigationGoToPostion(navStore.uuid, pos);
        }

        return buildUuidFail();
    })));

    public onNavigateStart$ = createEffect(() => this._actions.pipe(ofType(actions.NAVIGATION_FIRST), withLatestFrom(this._store, (action, state) => selectNavigation<any>(state, action.uuid)), filter(e => !!e), map((navStore) => {
        if (navStore) {
            return new actions.NavigationGoToPostion(navStore.uuid, 0);
        }

        return buildUuidFail();
    })));

    public onNavigateEnd$ = createEffect(() => this._actions.pipe(ofType(actions.NAVIGATION_LAST), withLatestFrom(this._store, (action, state) => selectNavigation<any>(state, action.uuid)), filter(e => !!e), map((navStore) => {
        if (navStore) {
            const pos = navStore.values.length - 1;
            return new actions.NavigationGoToPostion(navStore.uuid, pos);
        }

        return buildUuidFail();
    })));

    public onNavigateEntity$ = createEffect(() => this._actions.pipe(ofType(actions.NAVIGATION_GOTO_ID), withLatestFrom(this._store, (action, state) => ({
        action, store: selectNavigation<any>(state, action.uuid)
    })), filter(e => !!e.store), map(({action, store}) => {
        if (store) {
            const entityPos = store.values.indexOf(action.entityId);
            return entityPos === -1 ? new actions.NavigationGoToEntityFail(action.uuid, action.entityId) : new actions.NavigationGoToPostion(action.uuid, entityPos);
        }

        return buildUuidFail();
    })));

    public onNavigationFail$ = createEffect(() => this._actions.pipe(ofType(actions.NAVIGATION_FAIL, actions.NAVIGATION_INIT_FAIL), map(e => {
        this._toast.error(e.error.toLowerCase()
            .replace(/' '/g, '_'));
        return {type: 'toast_show'};
    })));

    public onNavigationIdFail$ = createEffect(() => this._actions.pipe(ofType(actions.NAVIGATION_GOTO_ID_FAIL), map(e => {
        this._toast.error(e.type.toLowerCase()
            .replace(/' '/g, '_'));
        return {type: 'toast_show'};
    })));

    constructor(private _actions: Actions<actions.NavigationActions>, private _store: IwStoreService, private _restService: RestApiService, private _toast: ToastService) {
    }
}

function buildInitFail(error: string) {
    return of(new actions.NavigationInitFail(error));
}

function buildUuidFail() {
    const error = 'navigation_fail_uuid';
    return new actions.NavigationFail(error);
}
