import {Component, Type} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Ppabscode} from '@app/sam-base/models/common/ppabscode';

@Component({
    selector: 'iw-ppabscode-combo-box',
    templateUrl: './ppabscode-combo-box.component.html',
    providers: [buildCustomValueProvider(IwPpabscodeComboboxComponent)]
})
export class IwPpabscodeComboboxComponent extends BaseEntityDropdown<Ppabscode> {

    public getExtraOptions(): Ppabscode[] {
        return [
            {
                desc: 'Mission',
                id: 'M'
            }];
    }

    protected getEntityType(): Type<Ppabscode> {
        return Ppabscode;
    }

    protected buildLabel(e: Ppabscode) {
        return `${e.id ? e.id + ' - ' : ''}${e.desc || 'Pas d\'absence'}`;
    }
}
