/* eslint-disable max-classes-per-file */
import {Action} from '@ngrx/store';

import {FormStateOptions} from '../../../models/form-state-options.model';
import {SidemenuState} from '../reducers/form.reducer';

export const INIT_FORM_DIALOG = '[placement] init form dialog';
export const DESTROY_FORM_DIALOG = '[placement] destroy form dialog';
export const DESTROYED_FORM_DIALOG = '[placement] destroyed form dialog';
export const UPDATE_SIDE_MENU_STATE = '[palcement] update side menu state';
export const UPDATE_FORM_WITH = '[placement] update form width';
export const INIT_FORM_ENTITY = '[palcement] init form entity';

export const START_EDIT_MODE = '[placement] set form to edit mode';
export const START_READ_MODE = '[placement] set form to read mode';

export const SET_LOADING_STATE = '[form] set loading state';
export const SET_READONLY = '[placement] set form to readonly';
export const SET_MODAL = '[placement] set modal window true';

/** Initialize the form state */
export class InitFormDialog implements Action {
    public readonly type = INIT_FORM_DIALOG;

    constructor(public readonly uuid: string) {
    }
}

export class InitFormEntity implements Action {
    public readonly type = INIT_FORM_ENTITY;

    constructor(public readonly uuid: string, public readonly options: FormStateOptions) {
    }
}

export class SetFormLoading implements Action {
    public readonly type = SET_LOADING_STATE;

    constructor(public readonly uuid: string, public readonly isLoading: boolean) {
    }
}

/** Destroy form dialog */
export class DestroyFormDialog implements Action {
    public readonly type = DESTROY_FORM_DIALOG;

    constructor(public readonly uuid: string) {
    }
}

/** Emited after form dialog is destroyed */
export class DestroyedFormDialog implements Action {
    public readonly type = DESTROYED_FORM_DIALOG;

    constructor(public readonly uuid: string) {
    }
}

export class UpdateSideMenuState implements Action {
    public readonly type = UPDATE_SIDE_MENU_STATE;

    constructor(public readonly uuid: string, public readonly state: SidemenuState) {
    }
}

export class UpdateFormWidth implements Action {
    public readonly type = UPDATE_FORM_WITH;

    constructor(public readonly uuid: string, public readonly width: number) {
    }
}

export class StartEditMode implements Action {
    public readonly type = START_EDIT_MODE;

    constructor(public readonly uuid: string) {
    }
}

export class StartReadMode implements Action {
    public readonly type = START_READ_MODE;

    constructor(public readonly uuid: string) {
    }
}

export class FormSetReadonly implements Action {
    public readonly type = SET_READONLY;

    constructor(public readonly uuid: string, public readonly readonly: boolean) {
    }
}

export class SetModalWindow implements Action {
    public readonly type = SET_MODAL;

    constructor(public readonly uuid: string, public readonly isModal: boolean) {
    }
}

export type FormActions =
    InitFormDialog
    | InitFormEntity
    | DestroyFormDialog
    | DestroyedFormDialog
    | UpdateSideMenuState
    | UpdateFormWidth
    | StartEditMode
    | StartReadMode
    | SetModalWindow
    | SetFormLoading
    | FormSetReadonly;
