import {Injectable, Type} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {map, mergeMap, withLatestFrom} from 'rxjs/operators';

import {StoreEvents} from '../../events/actions/store.actions';
import {IwEventHubService} from '../../events/core/iw-event-hub.service';
import * as fromEntityActions from '../actions/entity.actions';
import * as fromFormEntityActions from '../actions/form-entity.actions';
import * as globalFormActions from '../actions/global-form.actions';
import * as fromNavActions from '../actions/navigation.actions';
import {IwStoreService} from '../iw-store.service';
import {selectNavigation} from '../selectors';

@Injectable()
export class FormEntityEffects<T> {

    public onFormInit$ = createEffect(() => this._formEntityActions.pipe(ofType(fromFormEntityActions.FORM_ENTITY_INIT), mergeMap(e => {
        if (e.useNavigation) {
            return [{type: 'using_navigation'}];
        }

        return [new fromEntityActions.EntityLoad(e.entityType, e.entityId)];
    })));

    /**
     * When navigation initializesd, trigger the
     * loading for that entity and reset the
     * form entity state
     */
    public onNavigateInitSuccess$ = createEffect(() => this._navActions.pipe(ofType(fromNavActions.NAVIGATION_INIT_SUCCESS), map(action => <EntityNavigationProjection<T>>{
        entity: action.entity, entityId: action.values[action.position], uuid: action.uuid
    }), mergeMap(e => projectEntityNavigation(e))));


    public onNavigatePosSuccess$ = createEffect(() => this._navActions.pipe(ofType(fromNavActions.NAVIGATION_GOTO_POSITION), withLatestFrom(this._store, (action, state) => selectNavigation<T>(state, action.uuid)), mergeMap(state => {
        if (!state) {
            return [{type: 'state_invalid'}];
        }

        const projection: EntityNavigationProjection<T> = {
            entity: state.entity, entityId: state.values[state.position], uuid: state.uuid
        };
        return projectEntityNavigation(projection);
    })));

    /**
     * On entity loaded, update all
     * entities in forms
     */
    public onEntityLoadSuccess$ = createEffect(() => this._entityActions.pipe(ofType(fromEntityActions.ENTITY_LOAD_SUCCESS, fromEntityActions.ENTITY_UPDATE_SUCCESS), withLatestFrom(this._store, (action, state) => ({
            action, state
        })), // eslint-disable-next-line complexity
        mergeMap(({action, state}) => {
            const updateEvents: Action[] = [];
            for (const [uuid, navState] of Object.entries(state.navigation || {})) {
                if (navState) {
                    const entityId = navState.values[navState.position];
                    if (entityId !== action.getMetadata()
                        .$getPk()) {
                        continue;
                    }
                    updateEvents.push(new fromFormEntityActions.FormEntityNavigate(uuid, action.entity));
                }
            }
            for (const [uuid, formState] of Object.entries(state.formEntity || {})) {
                if (formState) {
                    const entityId = formState.entityId;
                    if (entityId !== action.getMetadata()
                        .$getPk()) {
                        continue;
                    }
                    updateEvents.push(new fromFormEntityActions.FormEntityNavigate(uuid, action.entity));
                }
            }
            return updateEvents;
        })));

    public onUpdateSuccess$ = createEffect(() => this._entityActions.pipe(ofType(fromEntityActions.ENTITY_CREATE_SUCCESS, fromEntityActions.ENTITY_UPDATE_SUCCESS, globalFormActions.GLOBAL_FORM_ENTITY_SAVE_NEW_SUCCESS, globalFormActions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS), withLatestFrom(this._store, (action, state) => ({
        action, state
    })), mergeMap(({action, state}) => {
        this._events.emit(StoreEvents.EntitySaved, action.entity);
        return [] as Action[];
    })));

    public onEntitySave$ = createEffect(() => this._formEntityActions.pipe(ofType(fromFormEntityActions.FORM_ENTITY_SAVE), withLatestFrom(this._store, (action, state) => ({
        state: state.formEntity[action.uuid], uuid: action.uuid
    })), map(({state, uuid}) => {
        // Send a global event for save
        this._events.emit(StoreEvents.FormEntityStoreSave, uuid);
        return new fromEntityActions.EntityUpdate(new state.type(state.entity));
    })));

    constructor(private readonly _entityActions: Actions<fromEntityActions.EntityActions<T>>, private readonly _navActions: Actions<fromNavActions.NavigationActions>, private readonly _formEntityActions: Actions<fromFormEntityActions.FormEntityActions<T>>, private readonly _store: IwStoreService, private readonly _events: IwEventHubService<StoreEvents>) {
    }
}

interface EntityNavigationProjection<T> {
    uuid: string;
    entity: Type<T>;
    entityId: string | number;
}

function projectEntityNavigation<T>(data: EntityNavigationProjection<T>) {
    return [new fromEntityActions.EntityLoad(data.entity, data.entityId), new fromFormEntityActions.FormEntityInit(data.uuid, data.entity, data.entityId)];
}

