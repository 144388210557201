import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';

import {GridProfileService} from '../../grid-profile/grid-profile.service';
import {ToastService} from '../../toast';
import * as actions from '../actions/grid-profiles.actions';

@Injectable()
export class GridProfileEffects {

    public loadDynamicGridProfiles$ = createEffect(() => this._actions.pipe(ofType(actions.PROFILE_LOAD_LIST), mergeMap(() => this._gridProfileService
        .GetProfiles<any>()
        .pipe(map(e => new actions.LoadGridProfilesSuccess(e)), catchError(error => of(new actions.LoadGridProfilesFail(error)))))));

    public createDynamicGridProfile$ = createEffect(() => this._actions.pipe(ofType(actions.PROFILE_CREATE_DYNAMIC), mergeMap((action) => this._gridProfileService
        .AddProfiles(action.payload)
        .pipe(map(() => new actions.CreateGridProfilesSuccess()), catchError(error => of(new actions.CreateGridProfilesFail(error)))))));

    public updateDynamicGridProfile$ = createEffect(() => this._actions.pipe(ofType(actions.PROFILE_UPDATE_DYNAMIC), mergeMap((action) => this._gridProfileService
        .UpdateProfiles(action.payload)
        .pipe(map(() => new actions.UpdateGridProfilesSuccess()), catchError(error => of(new actions.UpdateGridProfilesFail(error)))))));

    public updateDynamicGridProfileDefault$ = createEffect(() => this._actions.pipe(ofType(actions.PROFILE_UPDATE_DEFAULT), mergeMap((action) => this._gridProfileService
        .UpdateProfileDefault(action.payload)
        .pipe(map(() => new actions.UpdateGridProfileDefaultSuccess(action.payload)), catchError(error => of(new actions.UpdateGridProfileDefaultFail(error)))))));

    public deleteGridProfile$ = createEffect(() => this._actions.pipe(ofType(actions.PROFILE_DELETE_DYNAMIC), mergeMap((action) => this._gridProfileService
        .DeleteProfiles(action.cfgid)
        .pipe(map(() => new actions.DeleteGridProfileSuccess()), catchError(error => of(new actions.DeleteGridProfileFail(error)))))));

    public onErrorOfGridProfileRequest$ = createEffect(() => this._actions.pipe(ofType(actions.PROFILE_CREATE_DYNAMIC_FAIL, actions.PROFILE_LOAD_LIST_FAIL, actions.PROFILE_UPDATE_DYNAMIC_FAIL, actions.PROFILE_DELETE_DYNAMIC_FAIL, actions.PROFILE_UPDATE_DEFAULT_FAIL), switchMap(_ => {
        this._toastService.error('profile_update_fail');
        return [] as Action[];
    })));

    public reloadProfiles$ = createEffect(() => this._actions.pipe(ofType(actions.PROFILE_CREATE_DYNAMIC_SUCCESS, actions.PROFILE_UPDATE_DYNAMIC_SUCCESS, actions.PROFILE_DELETE_DYNAMIC_SUCCESS, actions.PROFILE_UPDATE_DEFAULT_SUCCESS), map(() => {
        this._toastService.success('profile_updated');
        return new actions.LoadGridProfiles();
    })));

    constructor(private _actions: Actions<actions.GridProfileActions>, private _gridProfileService: GridProfileService, private _toastService: ToastService) {
    }
}
