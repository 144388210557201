import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';


@IwRestEntity('ppacoview')
export class PpacoView {
    @IwRestEntityId()
    public acoId?: string;
    public empId?: string;
    public prenom?: string;
    public nom?: string;
    public nomemploye?: string;
    public salId?: string;
    public acodate?: string;
    public acotype?: number;
    public acopaytype?: number;
    public acotypetext?: string;
    public acopaytypetext?: string;
    public montant?: number;
    public libelle?: string;
    public acopaidtext?: string;
    public sent2saltext?: string;
    public acopaid?: boolean;
    public datePmnt?: string;
    public userCreat?: string;
    public session?: string;
    public dateTrack?: string;
    public dateDelet?: string;
    public dtaId?: string;
}
