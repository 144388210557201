import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';

import {UuidService} from '../../uuid/uuid.service';
import * as searchGridActions from '../actions/search-grid.actions';

@Injectable()
export class SearchGridEffects {

    public navigateToEntity$ = createEffect(() => this._actions.pipe(ofType(searchGridActions.SEARCH_GRID_NAVIGATE), map((action) => {
        const uuid = this._uuid.generateString();
        this._router.navigate([{
            outlets: {
                topPanel: ['search', 'entity', uuid]
            }
        }], {state: action.extras});
        return new searchGridActions
            .SearchGridInitAction(uuid, action.entityType, action.profile, action.sourceFormUuid, action.filter, action.extras);
    })));

    constructor(private readonly _actions: Actions<searchGridActions.SearchGridActions>, private readonly _uuid: UuidService, private readonly _router: Router) {
    }

}
