import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {Agetime} from '@app/sam-base/models/salary/agetime';


@IwRestEntity('ppagence')
export class Ppagence {
    @IwRestEntityId() @IwColumn({
        index: 0,
        name: 'code'
    }) public ageId?: string;
    public ageFax?: string;
    public plffrx?: string;
    public cmavfrx?: string;
    public ageNpa?: string;
    public boonly?: boolean;
    public geoLon?: number;
    public cdffrx?: string;
    public ageData?: string;
    public ageAdr1?: string;
    public noGestion?: string;
    public ageAbrev?: string;
    public cmfrxJ?: string;
    public ccfrxM?: string;
    public ageLieu?: string;
    public ccfrx?: string;
    public agi2Frx?: string;
    public ageTel?: string;
    public cmfrx?: string;
    public lecParS?: number;
    @IwColumn({
        index: 3,
        name: 'canton'
    }) public ageCanton?: string;
    public actif?: boolean;
    @IwColumn({
        index: 2,
        name: 'prefixe'
    }) public agePrefix?: string;
    public ccavfrx?: string;
    public atttravfrx?: string;
    public ppempfrx?: string;
    public cmfrxM?: string;
    @IwColumn({
        index: 1,
        name: 'libelle'
    }) public ageLib?: string;
    public ageAdr2?: string;
    public reeNo?: string;
    public smioactif?: boolean;
    public ageIdSyn?: string;
    public ageWww?: string;
    public cdefrx?: string;
    public geoLat?: number;
    public cmfinfrx?: string;
    public ageAutor?: string;
    public ccfrxJ?: string;
    public ageOfsnr?: number;
    public ageEmail?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public hParS?: number;
    public dateTrack?: string;
    public signature?: string;

    // Form agence
    public ageTimes?: Agetime[];
    public smtpConfigId?: number;
}
