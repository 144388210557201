import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IwStoreService} from '../../../core/store/iw-store.service';

@Component({
    selector: 'iw-form-status',
    templateUrl: './form-status.component.html',
    styleUrls: ['./form-status.component.scss']
})
export class IwFormStatusComponent implements AfterViewInit, OnDestroy {

    @Input() public formId = '';
    public formState = '';
    public data = '';

    private _subs = new Subject();

    constructor(private _store: IwStoreService, private _translate: TranslateService) {
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public ngAfterViewInit() {
        this._store
            .globalForm(this.formId)
            .entity
            .pipe(takeUntil(this._subs))
            .subscribe((entity) => {
                this.data = this.getData(entity);
            });
        this._store
            .globalForm(this.formId)
            .mode
            .pipe(takeUntil(this._subs))
            .subscribe((state) => {
                setTimeout(() => {
                    this.formState = state;
                }, 0);
            });
    }

    public getData(entity: any): string {
        let label = '';
        if (entity) {
            label += this.getDate('creation_short', entity.dateCreat);
            label += this.getUsername(entity.userCreat);
            label += this.getDate('modification_short', entity.dateModif);
            label += this.getUsername(entity.userModif);
        }
        return label;
    }

    private getUsername(username?: string): string {
        if (username) {
            return `/ ${username} `;
        }
        return '';
    }

    private getDate(label: string, date?: string): string {
        if (date) {
            return ` ${this._translate.instant(label)} ${IwDateHelper.dateTimeFormat(date)} `;
        }
        return '';
    }
}
