<div class="row">
    <div class="col-lg-5 status-item"></div>
    <div class="col-lg-5 status-item flex-center">
        {{ data }}
    </div>
    <div class="col-lg-2 status-item flex-center">
    <span *ngIf="formState === 'edit'">
      {{ 'form_status_edit' | translate }}
    </span>
    </div>
</div>
