import moment from 'moment';

export function parseStringToDate(date: string, format: string = 'YYYY-MM-DD'): Date | undefined {
    const mDate = moment(date, format);
    return mDate.isValid() ? mDate.toDate() : undefined;
}

export function parseDateToString(date: Date, format: string = 'YYYY-MM-DD'): string | undefined {
    return moment(date)
        .format(format);
}

export function compareDates(date1: string | undefined, date2: Date) {
    const entityDate = parseStringToDate(date1 || '');
    if (!entityDate || entityDate < date2) {
        return -1;
    }
    if (entityDate > date2) {
        return 1;
    }
    return 0;
}

/**
 * Parse date from an entity property
 *
 * @param value entity value
 * @param key key to parse date
 */
export function parseDateFrom<T>(value: T, key: keyof T) {
    if (!value || !key) {
        return undefined;
    }
    const prop = value[key];
    if (typeof prop === 'string') {
        return parseStringToDate(prop);
    }
    return undefined;
}

/**
 * Return first date of a year
 *
 * @param from years to go back
 */
export function getFirstDateOfYear(from: number = 0) {
    const year = new Date().getFullYear() - from;
    return moment(year + '0101', 'YYYYMMDD')
        .toDate();
}

/**
 * Return last date of a year
 *
 * @param from years to go back
 */
export function getLastDateOfYear(from: number = 0) {
    const date = getFirstDateOfYear(from - 1);
    return moment(date)
        .subtract(1, 'day')
        .toDate();
}

/**
 * Function to get age by date of birthday
 *
 * @param dateOfBirth date of birthday
 * @returns age in years
 */
export function getAgeByDateOfBirth(dateOfBirth: string): number {
    return moment()
        .diff(dateOfBirth, 'years');
}

/**
 * Function to calculate days beetwen two dates
 *
 * @param startDate Initial Date
 * @param endDate  Final Date
 */
export function daysBetweenDates(startDate: string, endDate: string) {
    return (moment(endDate)
        .diff(moment(startDate), 'days') + 1);
}

/**
 * Function to calculate months beetwen two dates
 *
 * @param startDate Initial Date
 * @param endDate  Final Date
 */
export function monthsBetweenDates(startDate: string, endDate: string) {
    return (moment(endDate)
        .diff(moment(startDate), 'months'));
}

/**
 * Function to calculate years beetwen two dates
 *
 * @param startDate Initial Date
 * @param endDate  Final Date
 */
export function yearsBetweenDates(startDate: string, endDate: string) {
    return (moment(new Date(endDate))
        .diff(moment(new Date(startDate)), 'years'));
}

/**
 * Function to get week of date
 *
 * @param date
 */
export function getWeekNumber(date: string) {
    return moment(date)
        .week();
}

/**
 * Function to get year by date
 *
 * @param date
 */
export function getYearByDate(date: string) {
    return moment(date)
        .year();
}

/**
 * Function to get month by date
 * Plus 1 because month method return numbers from 0 to 11
 * Months are zero indexed, so January is month 0
 *
 * @param date
 */
export function getMonthByDate(date: string) {
    return moment(date)
        .month() + 1;
}
