import {IwColumn} from '@app/sam-base/core/column';
import {IwContextMenuClass} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {MenuItem} from '@app/sam-base/models';

import {PlacementEvent} from './events.models';

const entityContextMenu: MenuItem<Ppaco>[] = [
    {
        label: 'ppaco_delete',
        event: PlacementEvent.PPACO_DELETE,
        contextMenuVisibleMode: 'single'
    },
    {
        separator: true
    }];

@IwContextMenuClass(...entityContextMenu) @IwRestEntity('ppaco')
export class Ppaco {
    @IwColumn({
        index: 0,
        name: 'empId'
    })
    public nomemploye?: string;
    @IwColumn({
        index: 1,
        name: 'periode',
        type: 'activePer'
    })
    public salId?: string;
    @IwColumn({
        index: 2,
        type: 'date',
        name: 'date'
    })
    public acodate?: string;
    @IwColumn({
        index: 3,
        name: 'type',
        type: 'translate'
    })
    public acotypetext?: string;
    @IwColumn({
        index: 4,
        name: 'acotype',
        hidden: true
    })
    public acotype?: number;
    @IwColumn({
        index: 5,
        name: 'mode',
        type: 'translate'
    })
    public acopaytypetext?: string;
    @IwColumn({
        index: 6,
        name: 'payepar',
        hidden: true
    })
    public acopaytype?: number;
    @IwColumn({
        index: 7,
        type: 'mnt',
        align: 'right',
    })
    public montant?: number;
    @IwColumn({index: 8})
    public libelle?: string;
    @IwColumn({
        index: 9,
        name: 'lacompteAEtePaye',
        hidden: true
    })
    public acopaid?: boolean;
    @IwColumn({
        index: 10,
        name: 'paye'
    })
    public acopaidtext?: string;
    @IwColumn({
        index: 11,
        type: 'date',
        name: 'payeLe'
    })
    public datePmnt?: string;
    @IwColumn({
        index: 12,
        name: 'salary',
        type: 'translate'
    })
    public sent2Saltext?: string;
    @IwColumn({
        index: 13,
        hidden: true
    })
    public session?: string;
    @IwColumn({
        index: 14,
        name: 'utilisateur'
    })
    public userCreat?: string;
    @IwRestEntityId() @IwColumn({
        index: 15,
        hidden: true,
        name: 'id'
    })
    public acoId?: string;
    @IwColumn({
        index: 16,
        hidden: false,
        name: 'dta_id_title'
    })
    public dtaId?: string;

    public empId?: string;
    public rapId?: string;
    public rapNo?: string;
    public banqueSwi?: string;
    public dtanymodif?: string;
    public mpaId?: string;
    public acodtatype?: number;
    public ageId?: string;
    public nocheque?: string;
    public banqueCpt?: string;
    public userId?: string;
    public salIdSal?: string;
    public banque?: string;
    public epId?: string;
    public iban?: string;
    public banqueClr?: string;
    public banqueAdr?: string;
    public conId?: string;
    public lnoaco?: string;
    public misId?: string;
    public aconbmod?: number;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userDelet?: string;
    public userModif?: string;
    public sent2sal?: string;

    /** Exclusively used in the front end
     * to make use of it in the store
     */
    public amoutAllowed?: number;
}
