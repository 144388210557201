import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {mergeMap, withLatestFrom} from 'rxjs/operators';
import {FormActions, START_READ_MODE} from '../actions';

import * as fromEntityActions from '../actions/entity.actions';
import {IwStoreService} from '../iw-store.service';

@Injectable()
export class FormEffects<T> {

    public onSetReadMode$ = createEffect(() => this._formEntityActions.pipe(ofType(START_READ_MODE), withLatestFrom(this._store, (action, state) => (state.formEntity[action.uuid])), mergeMap((state) => {
        if (!state) {
            return [];
        }
        return [new fromEntityActions.EntityLoad(state.type, state.entityId)];
    })));

    constructor(private readonly _formEntityActions: Actions<FormActions>, private readonly _store: IwStoreService) {
    }
}
