<iw-modal-form (pressCancel)="onPressCancel()" (pressOk)="onPressOk()" [btnCancel]="cancelShow" [height]="height"
               [title]="title" [width]="width">

    <div class="row" style="margin-top: 20px;">
        <div class="col-xs-7" style="margin-bottom: 1rem;">
            <div class="col-xs-10"><strong>Période {{ obj['header'].date_de }} {{ obj['header'].date_a }}</strong></div>
            <div class="col-xs-10">No AVS {{ obj['employee'].noavs13 }}</div>
        </div>
        <div class="col-xs-5" style="margin-bottom: 1rem;">
            <div class="col-xs-10">{{ obj['employee'].nom }}{{ obj['employee'].prenom }}</div>
            <div class="col-xs-10">{{ obj['employee'].adresse1 }}</div>
            <div class="col-xs-10">{{ obj['employee'].npa }}, {{ obj['employee'].lieu }}</div>
        </div>
    </div>
    <div *ngIf="!!obj['saes']" class="row" style="margin: 20px;">
        <div class="col-xs-12">
            <div *ngFor="let ty of types"></div>
            <!-- Table to show the content with gs_ctype == 1 (header only visible on first table)-->
            <p-table [columns]="tableColumn" [value]="getTableVal('1')">
                <ng-template let-columns pTemplate="header">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <th *ngIf="col.name === 'GS'" class="id-column" id="{{col.prop}}">
                                {{ col.name | translate }}
                            </th>
                            <th *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ col.name | translate }}
                            </th>
                            <th *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-header"
                                id="{{col.prop}}">
                                {{ col.name | translate }}
                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- Salaire brut total -->
        <span *ngIf="exists('400')" class="col-xs-12" class="total-display">
        <div class="col-xs-1 total-content">400</div>
        <div class="col-xs-10 total-content"><strong>Salaire Brut</strong></div>
        <div class="col-xs-1 align-right total-content">{{ maskMnt(getValue('400')) }}</div>
    </span>

        <div class="col-xs-12">
            <!-- Table to show the content with gs_ctype == B (no header)-->
            <div *ngIf="existsKey('B')" class="col-xs-10" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('B') }}</strong></div>
            <p-table *ngIf="existsKey('B')" [columns]="tableColumn" [value]="getTableVal('B')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>

            <!-- Table to show the content with gs_ctype == D (no header)-->
            <div *ngIf="existsKey('D')" class="col-xs-10" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('D') }}</strong></div>
            <p-table *ngIf="existsKey('D')" [columns]="tableColumnType48D" [value]="getTableVal('D')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS' && col.name !== 'taux'"
                                class="mnt-columns" id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                            <td *ngIf="col.name === 'taux'" class="mnt-columns" id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}%
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>

            <!-- Table to show the content with gs_ctype == 7 (no header)-->
            <div *ngIf="existsKey('7')" class="col-xs-10"><strong>{{ getTitleByValue('7') }}</strong></div>
            <p-table *ngIf="existsKey('7')" [columns]="tableColumn" [value]="getTableVal('7')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table to show the content with gs_ctype == 4 (no header)-->
            <div *ngIf="existsKey('4')" class="col-xs-10" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('4') }}</strong></div>
            <p-table *ngIf="existsKey('4')" [columns]="tableColumnType48D" [value]="getTableVal('4')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS' && col.name !== 'taux'"
                                class="mnt-columns" id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                            <td *ngIf="col.name === 'taux'" class="mnt-columns" id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}%
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>

            <!-- Table to show the content with gs_ctype == 2 (no header)-->
            <div *ngIf="existsKey('2')" class="col-xs-10" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('2') }}</strong></div>
            <p-table *ngIf="existsKey('2')" [columns]="tableColumn" [value]="getTableVal('2')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>

            <!-- Table to show the content with gs_ctype == P (no header)-->
            <div *ngIf="existsKey('P')" class="col-xs-10" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('P') }}</strong></div>
            <p-table *ngIf="existsKey('P')" [columns]="tableColumn" [value]="getTableVal('P')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- Deductions total from types6 -->
        <span *ngIf="exists('500')" class="col-xs-12" class="total-display">
      <div class="col-xs-1 total-content">500</div>
      <div class="col-xs-10 total-content"><strong>Total déductions</strong></div>
      <div class="col-xs-1 align-right total-content">{{ getValue('500') }}</div>
    </span> <span *ngIf="exists('502')" class="col-xs-12" class="total-display">
      <div class="col-xs-1 total-content">502</div>
      <div class="col-xs-10 total-content"><strong>SALAIRE NET</strong></div>
      <div class="col-xs-1 align-right total-content"><strong>{{ maskMnt(getValue('502')) }}</strong></div>
    </span>

        <div class="col-xs-12">
            <!-- Table to show the content with gs_ctype == M (no header)-->
            <div *ngIf="existsKey('M')" class="col-xs-12" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('M') }}</strong></div>
            <p-table *ngIf="existsKey('M')" [columns]="tableColumn" [value]="getTableVal('M')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>

            <!-- Table to show the content with gs_ctype == 3 (no header)-->
            <div *ngIf="existsKey('3')" class="col-xs-12" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('3') }}</strong></div>
            <p-table *ngIf="existsKey('3')" [columns]="tableColumn" [value]="getTableVal('3')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <!-- Total retenues/indemnités from types6 array-->
        <span *ngIf="exists('600')" class="col-xs-12" class="total-display">
      <div class="col-xs-1 total-content">600</div>
      <div class="col-xs-10 total-content"><strong>Total retenues / indemnités non soumises</strong></div>
      <div class="col-xs-1 align-right total-content">{{ getValue('600') }}</div>
    </span> <span *ngIf="exists('602')" class="col-xs-12" class="total-display">
      <div class="col-xs-1 total-content">602</div>
      <div class="col-xs-10 total-content"><strong>NET A PAYER</strong></div>
      <div class="col-xs-1 align-right total-content"><strong>{{ maskMnt(getValue('602')) }}</strong></div>
    </span>

        <div class="col-xs-12">
            <!-- Table to show the content with gs_ctype == C (no header)-->
            <div *ngIf="existsKey('C')" class="col-xs-12" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('C') }}</strong></div>
            <p-table *ngIf="existsKey('C')" [columns]="tableColumn" [value]="getTableVal('C')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>

            <!-- Table to show the content with gs_ctype == 5 (no header)-->
            <div *ngIf="existsKey('5')" class="col-xs-12" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('5') }}</strong></div>
            <p-table *ngIf="existsKey('5')" [columns]="tableColumn" [value]="getTableVal('5')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>

            <!-- Table to show the content with gs_ctype == AFV (no header)-->
            <div *ngIf="existsKey('AFV')" class="col-xs-12" style="margin-top: 15px;">
                <strong>{{ getTitleByValue('AFV') }}</strong></div>
            <p-table *ngIf="existsKey('AFV')" [columns]="tableColumn" [value]="getTableVal('AFV')">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'GS'" class="id-column padding-left-8" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'Keyword'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'Keyword' && col.name !== 'GS'" class="mnt-columns"
                                id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>

            <!-- Table to show the content of saep object with the paiements (no header)-->
            <div *ngIf="obj['saep'].length" class="col-xs-12" style="margin-top: 25px;"><strong>Paiement(s)</strong>
            </div>
            <p-table *ngIf="obj['saep'].length" [columns]="tableSaepColumn" [value]="obj['saep']">
                <ng-template let-columns="columns" let-rowData pTemplate="body">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.name === 'id'" class="id-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name === 'desig'" class="desig-column" id="{{col.prop}}">
                                {{ rowData[col.prop] }}
                            </td>
                            <td *ngIf="col.name !== 'desig' && col.name !== 'id'" class="mnt-columns" id="{{col.prop}}">
                                {{ maskMnt(rowData[col.prop]) }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</iw-modal-form>
