import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppcde')
export class Ppcde {
    @IwColumn({name: 'id'}) @IwRestEntityId() public cdeId?: string;
    @IwColumn({name: 'date', type: 'date'}) public dateCde?: string;
    @IwColumn({name: 'agence'}) public ageId?: string;
    @IwColumn({name: 'qualification'}) public qualif?: string;
    public qualifDescription?: string;
    public genretrav?: string;
    @IwColumn({name: 'client'}) public lieu1?: string;
    @IwColumn({name: 'clistatustext'}) public cdestatus?: number;
    @IwColumn({name: 'cli_id'}) public cliId?: string;
    public wkldMax?: number;
    public xmlData?: string;
    public lieu2?: string;
    public mfId?: string;
    @IwColumn({type: 'date'}) public dateModif?: string;
    public orprsncan?: string;
    public dipl1?: string;
    public mnteqemp?: number;
    public webFin?: string;
    public isexe?: number;

    public datefin?: string;
    public lweb?: string;
    public apt9?: string;
    public orpstatus?: string;
    public region?: string;
    public orpDeb?: string;
    public apt2?: string;
    public dipl2?: string;
    public secId?: string;
    public factbyrap?: string;
    public jupbra?: string;
    public mnteqcli?: number;
    public apt10?: string;
    public heurequipe?: string;
    public orplastexp?: string;
    public cliId2?: string;
    public jobroomId?: string;
    public webData?: string;
    public facmois?: number;
    public typehor?: number;
    public dateDelet?: string;
    public foncdet?: string;
    public lorp?: string;
    public resp?: string;
    public cdeData?: string;
    public apt6?: string;
    public modData?: string;
    public orpEnd?: string;
    public orpfoncdet?: string;
    public relances?: string;
    public lorppubdis?: string;
    public email?: string;
    public wkldMin?: number;
    public salmois?: number;
    public frais?: string;
    public weblastexp?: string;
    public dureetype?: string;
    public codecna?: string;
    public nref2fac?: string;
    public ijData?: string;
    public jobsprof?: string;
    public websendrtf?: string;
    public misNpa?: string;
    public jrResp?: string;
    public userModif?: string;
    public webFdmode?: number;
    @IwColumn({type: 'date'}) public dateCreat?: string;
    public userDelet?: string;
    public orpquaId?: string;
    public jrStatus?: string;
    public fax?: string;
    public jupemploy?: string;
    public satisfaite?: string;
    public refclient?: string;
    public jupsubcat?: string;
    public dipl3?: string;
    public jobstype?: string;
    public websendhtm?: string;
    public natel?: string;
    public expmin?: string;
    public jupcanton?: string;
    public conId?: string;
    public primId?: number;
    public cctId?: string;
    @IwColumn({type: 'date'}) public datedebut?: string;
    public quagrpId?: string;
    public quaId?: string;
    public diplbin?: number;
    public jobspos?: string;
    public apt7?: string;
    public orpHis?: string;
    public ljobtic?: string;
    public orpNote?: string;
    public userCreat?: string;
    public nogestion?: string;
    public apt5?: string;
    public refinterne?: string;
    public ljobup?: string;
    public langue1?: string;
    public ljobs?: string;
    public langue2?: string;
    public usertag?: string;
    public aptId?: string;
    public ccfrx?: string;
    public langue4?: string;
    public chantier?: string;
    public horaire?: string;
    public nbEmp?: number;
    public permis?: string;
    public orpData?: string;
    public nbMis?: number;
    public misfacmode?: number;
    public missalmode?: number;
    public forcechant?: string;
    public jrResp2?: string;
    public apt1?: string;
    public orpdtcan?: string;
    public swissonly?: string;
    public jobsbra?: string;
    public ageMin?: number;
    public grtId?: string;
    public concerne?: string;
    public clitarif?: number;
    public attn?: string;
    public freezchant?: string;
    public modCnt?: number;
    public tel?: string;
    public dipl5?: string;
    public service?: string;
    public note?: string;
    public modefactu?: number;
    public cliattId?: string;
    public apt3?: string;
    public clichaId?: string;
    public cliadrId?: string;
    public motifSat?: string;
    public modecreat?: string;
    public lorpeurdis?: string;
    public apt4?: string;
    public orpfrstexp?: string;
    public horairetxt?: string;
    public langue3?: string;
    public typehortxt?: string;
    public misLieu?: string;
    public cmfrx?: string;
    public ageMax?: number;
    public vref2fac?: string;
    public userId?: string;
    public dipl4?: string;
    public lexp2Ij?: string;
    public salfacmode?: number;
    public orpquaTxt?: string;
    public keywords?: string;
    public cdeKeywords?: string;
    public dtanymodif?: string;
    public orpFin?: string;
    public lieu3?: string;
    public jupspecrit?: string;
    public apt8?: string;
    public jobticcat?: string;
    public duree?: string;
    public webfrstexp?: string;
    public aptbin?: number;
    public jobsreg?: string;
    public profession?: string;

    public needSummary?: string;
}
