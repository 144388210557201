import {Injector, Type} from '@angular/core';

let _injector: Injector | undefined;

export const registerInjector = (injector: Injector) => _injector = injector;

export function loadInjector<T>(type: Type<T>) {
    if (!_injector) {
        throw new Error('Injector not registered');
    }

    return _injector.get(type, undefined);
}
