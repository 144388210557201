import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';
import {ComboboxItem, OptionalDictionary} from '../../models';

@Component({
    selector: 'iw-enum-dropdown',
    templateUrl: './iw-enum-dropdown.component.html',
    providers: [buildCustomValueProvider(IwEnumDropdownComponent)]
})
export class IwEnumDropdownComponent<T extends OptionalDictionary<any>> extends BaseFormControl<string | number | undefined> {

    @Input() public translateOptions = true;
    @Input() public width?: string | number;
    @Input() public warningMessage?: string;
    @Input() public labelWidth?: string;
    @Input() public disabledOptions?: T[];
    @Output() public selectedChanged = new EventEmitter<string | number>();
    // And not in the form
    @Output() public valueSelected = new EventEmitter<void>();

    // Event is triggered only on the value is selected in the component
    @Input() public defaultOption?: T;
    public options: ComboboxItem<string | number | undefined>[] = [];

    constructor(private readonly _translate: TranslateService, private elRef: ElementRef) {
        super();
    }

    @Input() // eslint-disable-next-line complexity
    public set inputEnum(v: T | undefined) {
        if (v) {
            this.options = [
                {
                    name: this._translate.instant('combo_no_option'),
                    value: undefined
                }];
            for (const [key, value] of Object.entries(v)) {
                if (!isNaN(+key)) {
                    continue;
                }
                this.options.push({
                    name: this.translateOptions ? this._translate.instant('combo_' + key) : key,
                    value: value === 'undefined' ? undefined : value,
                    disabled: this.disabledOptions ? this.disabledOptions?.filter(disabledOpt => disabledOpt === value)?.length > 0 : false
                });
            }
        }
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }
}
