export enum ModesFacturation {
    une_facture = 1,
    employe = 2,
    mois = 3,
    rapport = 4,
    chantier = 5,
    chantier_employe = 6,
    semaine = 7,
    semaine_chantier = 8,
    rapport_force = 9,
    semaine_employe = 10,
    employe_mois = 11,
    manuel = 12,
    semaine_chantier_employe = 13,
    mission = 14,
    ref_client = 15,
    employe_ref_client = 16,

}
