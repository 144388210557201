import {IwColumn} from '@app/sam-base/core/column';
import {
    IwContextMenuClass
} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {
    IwRestEntity, IwRestEntityId, SearchEntityStatusOperator, SearchExactMatch
} from '@app/sam-base/core/rest-api';
import {MenuItem} from '@app/sam-base/models';

import {PlacementEvent} from './events.models';

const entityContextMenu: MenuItem[] = [{
    label: 'contextmenu_suivi', event: PlacementEvent.PPSUI_NEW, contextMenuVisibleMode: 'single'
}, {
    separator: true
}, {
    label: 'ppcde_contextmenu_launch_advsearch',
    event: PlacementEvent.PPCDE_LAUNCH_ADVSEARCH,
    contextMenuVisibleMode: 'single'
}];

@IwContextMenuClass(...entityContextMenu) @IwRestEntity('ppclicdes')
export class Ppclicdes {
    @IwColumn({index: 0, type: 'comStatus', width: 70}) @IwRestEntityId() public cdeId?: string;
    public cdeType?: string;
    @IwColumn({
        index: 2,
        type: 'status',
        valueFormat: 'ppcde_status_',
        width: 80,
        filterQuery: '',
        statusQuery: [],
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public cdestatustext?: number;
    public status?: number;
    @IwColumn({name: 'Client', index: 3}) public nomclient?: string;
    @IwColumn({index: 4, type: 'date', width: 120}) public dateCde?: string;
    @IwColumn({name: 'profession', index: 5}) public profession?: string;
    public fonction?: string;
    public qualif?: string;
    @IwColumn({index: 7, width: 70}) public debut?: string;
    @IwColumn({index: 8, type: 'date', width: 70}) public fin?: string;
    @IwColumn({index: 9, hidden: true}) public horaire?: string;
    @IwColumn({index: 10, hidden: true}) public ageId?: string;
    public conId?: string;
    @IwColumn({index: 12, hidden: true}) public secId?: string;
    @IwColumn({index: 13, hidden: true, type: 'dateTime'}) public dateCreat?: string;
    @IwColumn({index: 14, type: 'dateTime'}) public dateModif?: string;
    public cliId?: string;
    public npaclient?: string;
    public foncdet?: string;
    public swissonly?: string;
    public permis?: string;
    public ageMin?: string;
    public ageMax?: string;
    public sexe?: string;
    public aptId?: string;
    public aptbin?: string;
    public motscles?: string;
    public langue1?: string;
    public langue2?: string;
    public langue3?: string;
    public langue4?: string;
    public conIni?: string;
    @IwColumn({index: 11, hidden: true}) public conseiller?: string;
    public marked?: string;
    public userId?: string;
    public type?: string;
    @IwColumn({index: 6, name: 'Keywords'}) public keywords?: string;
}
