import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppqua')
export class Ppqua {
    @IwRestEntityId() @IwColumn({index: 0, name: 'id'}) public quaId?: string;
    public userDelet?: string;
    public quacatId?: string;
    public quaData?: string;
    public orpquaId?: string;
    public userCreat?: string;
    public quaNo?: string;
    public dateModif?: string;
    public userModif?: string;
    public dateCreat?: string;
    public dateTrack?: string;
    @IwColumn({index: 1, name: 'profession'}) public qualif?: string;
    public dateDelet?: string;
    public quagrpId?: string;
    public iscoCatCode?: string;
    public active?: boolean;

    public iscoDescriptionFr?: string;
    public pe?: string;

}
