import {HttpClient} from '@angular/common/http';
import {Directive, EventEmitter, Input, Type} from '@angular/core';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {joinPath} from '../../../helpers/path';
import {getEnvironment} from '../../environment/environment';
import {EsError, EsRequest, EsSearchResult, EsSearchSuccess, mapEsSearchResult} from './models';
import {PitDeleteModel, PitDeleteResponse} from './models/es-scroll-request.model';
import {EsSearchResponseSuccess} from './models/response/es-response.model';

@Directive()
export class EsScrollQuery<T> {

    /** Emit on error */
    public errors = new EventEmitter<EsError>();
    /** Emit on new data are append */
    public newData = new EventEmitter<T[]>();
    /** Emit on data change */
    public fullData = new EventEmitter<T[]>();
    /** Time to keep scroll alive */
    private _scrollKeepAlive = 5;
    /** Scroll id to allow fetch of new values */
    private _scrollId?: string;
    private _sort?: any[];
    private _id?: string;
    private _inProgress = false;

    constructor(private readonly _http: HttpClient, private readonly _type: Type<T>,
                private readonly _request: EsRequest<T>, private readonly _size: number = 100) {
    }

    private _totalHits?: number;

    public get totalHits() {
        return this._totalHits;
    }

    /** Fetched items */
    private _data: T[] = [];

    /** Fetched items */
    public get data() {
        return this._data;
    }

    public get keepAlive() {
        return this._scrollKeepAlive;
    }

    /** Time to keep scroll alive, in minutes */
    @Input()
    public set keepAlive(t: number) {
        this._scrollKeepAlive = t;
    }

    /** Request used in scroll */
    public get request() {
        return this._request;
    }

    /** Number of items fetched per request */
    public get size() {
        return this._size;
    }

    public get pitDeleteUrl() {
        return joinPath(this.url, 'search', 'scroll', 'delete');
    }

    public get searchUrl() {
        return joinPath(this.url, 'search');
    }

    // Elastic search url endpoint
    private get url() {
        return getEnvironment().esURL;
    }

    /** Call backend for data, if a call is on progress it returns empty */
    public next(): Observable<T[]> {
        if (this._inProgress) {
            return of([]);
        }
        this._inProgress = true;
        return this.fetchData()
            .pipe(map(e => {
                this._inProgress = false;
                return e;
            }));
    }

    /** Close active scroll */
    public close() {
        this.killScroll(this._scrollId)
            .subscribe();
    }

    /** Load data from scroll append to current data */
    private fetchData() {
        return this.scroll()
            .pipe(map(resultSet => {
                this.appendData(resultSet);
                return resultSet;
            }));
    }

    /** Add data to values and emit events */
    private appendData(data: T[]) {
        this._data.push(...data);
        this.newData.emit(data);
        this.fullData.emit(data);
    }

    /** Request data from scroll */
    private scroll() {
        return this.doScroll(!!this._scrollId);
    }

    /** Initialize the scroll request or scrolls using stored pit */
    private doScroll(scroll?: boolean): Observable<T[]> {
        this._request.size = this._size;
        let reqUrl = '';
        if (!scroll) {
            // Perform a new scroll
            reqUrl = this.searchUrl + `?scroll=${this._scrollKeepAlive}m`;
        } else {
            // Uses current stored point in time
            reqUrl = this.searchUrl + `?pit=true`;
        }

        return this._http
            .post<EsSearchSuccess<T>>(reqUrl, this.getScrollBody(scroll))
            .pipe(map(e => mapEsSearchResult<T>(e)), map(e => {
                if (e.success) {
                    this._scrollId = e.result.pit_id;
                    this._id = this.getLastId(e);
                    this._sort = this.getLastSort(e);
                    if (this._sort && this._sort.length < 2) {
                        this._sort?.push(this._id);
                    }
                    this._totalHits = e.result.hits.total?.value;
                    return this.mapResults(e.result.hits.hits);
                } else {
                    ERROR('There was a problem executing es search', e.result);
                    this.errors.emit(<any>e.result);

                    this.killScroll(this._scrollId)
                        .subscribe();
                    this._scrollId = undefined;
                    return [];
                }
            }));
    }

    /**
     * Sets the request body to either
     * contain the scroll context or create a new pit
     */
    // eslint-disable-next-line complexity
    private getScrollBody(scroll?: boolean): EsRequest<T> {
        if (this._scrollId && this._scrollKeepAlive && this._sort) {
            const sort: any[] = this._request.sort ?? [];
            // Add tie-breaker to sort
            if (sort.length < 2) {
                sort.push({'documentId': 'asc'});
            }
            return !scroll ? // New scroll
                this._request : // Uses current scroll context
                {
                    ...this._request,
                    pit: {
                        id: this._scrollId,
                        keep_alive: `${this._scrollKeepAlive}m`
                    },
                    search_after: this._sort,
                    sort
                };
        } else {
            return this._request;
        }
    }

    /** Checks if the last scroll contains results */
    private getLastSort(e: EsSearchResponseSuccess<T>): any[] | undefined {
        if (e.result.hits.hits.length) {
            return e.result.hits.hits[e.result.hits.hits.length - 1].sort;
        }
        return this._sort;
    }

    /** Checks if the last scroll contains results and returns the element id */
    private getLastId(e: EsSearchResponseSuccess<T>): string | undefined {
        if (e.result.hits.hits.length) {
            return e.result.hits.hits[e.result.hits.hits.length - 1]._source.documentId;
        }
        return this._id;
    }

    /** Delete scroll stash */
    private killScroll(scrollId?: string): Observable<PitDeleteResponse> {
        if (scrollId) {
            const body: PitDeleteModel = {
                id: scrollId
            };
            return this._http.post<PitDeleteResponse>(this.pitDeleteUrl, body);
        }

        return of({
            succeeded: false,
            num_freed: 0
        });
    }

    private mapResults(items: EsSearchResult<T>[]): T[] {
        return items.map(e => new this._type(e._source));
    }

}
