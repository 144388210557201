import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pposa')
export class Pposa {
    @IwRestEntityId() public userId?: string;
    public dateModif?: string;
    public libheu?: string;
    public sagsnuiprv?: string;
    public sagspause?: string;
    public sagsjfhprv?: string;
    public libhnuit?: string;
    public sags13prv?: string;
    public alfmode?: string;
    public sagsvacprv?: string;
    public userDelet?: string;
    public sagsxxx?: string;
    public satblreq?: string;
    public sags100prv?: string;
    public sagsnuit?: string;
    public iscantmode?: string;
    public sagsnuippr?: string;
    public sagsjour?: string;
    public acobankids?: string;
    public sags200?: string;
    public sagsjf?: string;
    public raplibmode?: string;
    public satblded1f?: string;
    public satblded4h?: string;
    public sagsfrais1?: string;
    public saded2def?: string;
    public libhequip?: string;
    public sagsjfhprz?: string;
    public satblded2h?: string;
    public libweek?: string;
    public pgmmode?: string;
    public sags125?: string;
    public userCreat?: string;
    public grivacppr?: string;
    public sags13?: string;
    public sagsvac?: string;
    public saded3def?: string;
    public sagsjfhppr?: string;
    public userModif?: string;
    public raplibdata?: string;
    public libhpause?: string;
    public gri13ppr?: string;
    public cctwbase?: string;
    public sags13ppr?: string;
    public lprevsalid?: string;
    public sags100?: string;
    public osaData?: string;
    public grijfhppr?: string;
    public impotmode?: string;
    public satblded1h?: string;
    public sagsmois?: string;
    public saded4def?: string;
    public libhequix?: string;
    public sags150?: string;
    public sagsop?: string;
    public satblded3h?: string;
    public satblded4f?: string;
    public sagsfrais2?: string;
    public satblded2f?: string;
    public sagsvacppr?: string;
    public trcantmode?: string;
    public cctlsinsa?: string;
    public paucalcmod?: string;
    public sagshlpp?: string;
    public lppmode?: string;
    public saded1def?: string;
    public sagsjfhor?: string;
    public cnamode?: string;
    public sagsequip?: string;
    public sagsequix?: string;
    public annee?: string;
    public satblded3f?: string;
}
