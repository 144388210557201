import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppquagrp')
export class Ppquagrp {
    @IwRestEntityId() public quagrpId?: string;
    public dateModif?: string;
    public dateCreat?: string;
    public userModif?: string;
    public shcalcmode?: number;
    public shbasemin?: number;
    public cctText?: string;
    public dateDelet?: string;
    public cctId?: string;
    public userCreat?: string;
    public quagData?: string;
    public userDelet?: string;
    public quagrpLib?: string;
}
