/* eslint-disable max-classes-per-file */
import {isArray} from 'lodash';

import {BoolResult} from '../../../../../models';
import {EsCount} from './es-count.model';
import {EsError} from './es-error.model';
import {EsSearchSuccess} from './es-search-success.model';

export class EsSearchResponseSuccess<T> implements BoolResult {
    public readonly success = true;

    constructor(public readonly result: EsSearchSuccess<T>) {
        this.mapSourceAsMap();
    }

    // eslint-disable-next-line complexity
    private mapSourceAsMap() {
        if (this.result && this.result.hits && this.result.hits.hits && this.result.hits.hits.length && this.result.hits.hits[0].sourceAsMap) {
            this.result.hits.hits = this.result.hits.hits.map(r => ({
                ...r, _source: r.sourceAsMap
            }));
        }
    }
}

export class EsCountResponseSuccess implements BoolResult {
    public readonly success = true;

    constructor(public readonly result: EsCount) {
    }
}

export class EsResponseFail implements BoolResult {
    public readonly success = false;

    constructor(public readonly result: EsError) {
    }
}

export type EsSearchResponse<T> = EsSearchResponseSuccess<T> | EsResponseFail;

export type EsCountResponse = EsCountResponseSuccess | EsResponseFail;

export function isEsSearchSuccess<T>(input: unknown): input is EsSearchSuccess<T> {
    return (typeof input === 'object'
        // eslint-disable-next-line no-null/no-null
        && input !== null && isArray((<any>input).hits.hits));
}

export function mapEsSearchResult<T>(e: EsSearchSuccess<T> | EsError): EsSearchResponse<T> {
    if (isEsSearchSuccess<T>(e)) {
        return new EsSearchResponseSuccess(e);
    } else {
        return new EsResponseFail(e);
    }
}

export function isEsCount(input: unknown): input is EsCount {
    return (typeof input === 'object'
        // eslint-disable-next-line no-null/no-null
        && input !== null && typeof ((<any>input).count) === 'number');
}

export function mapEsCountResult(e: EsCount | EsError): EsCountResponse {
    if (isEsCount(e)) {
        return new EsCountResponseSuccess(e);
    } else {
        return new EsResponseFail(e);
    }
}


