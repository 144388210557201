import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
    Overlay, OverlayPositionBuilder, OverlayRef
} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {
    AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import {TranslateService} from '@ngx-translate/core';

import {Calendar, CalendarOptions, EventApi, EventSourceInput} from '@fullcalendar/core';
import {IwCalendarTooltipComponent} from './iw-calendar-tooltip/iw-calendar-tooltip.component';
import {FullCalendarComponent} from '@fullcalendar/angular';

@Component({
    selector: 'iw-calendar', templateUrl: './iw-calendar.component.html'
})
export class IwCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input()
    public set events(v: EventSourceInput[] | undefined) {
        if (!this.calendarApi) {
            return;
        }
        this.calendarApi.removeAllEventSources();
        for (const e of (v || [])) {
            this.calendarApi.addEventSource(e);
        }
        this.loading = false;
    }

    public get calendarApi(): Calendar {
        return this.fc.getApi();
    }

    public loading = true;

    @ViewChild('fc', {static: true}) public fc!: FullCalendarComponent;

    @ViewChild('calendarContainer', {static: false}) public calendarContainer!: ElementRef;

    public selectedTooltipInfo: string[] = [];

    // ########### INPUT PROPERTIES ###########
    /** The default view of calendar */
    @Input() public defaultView: 'timeGridWeek' | 'dayGridMonth' = 'dayGridMonth';

    /** The header change view buttons */
    @Input() public availableViews = 'timeGridDay,timeGridWeek,dayGridMonth';
    /**
     * The initial date displayed when the calendar first loads.
     */
    @Input() public defaultDate?: number = Date.now();

    /**
     * Determines the first time slot that will be displayed for each day.
     */
    @Input() public minTime = '00:00:00';

    /**
     * Determines the last time slot that will be displayed for each day.
     */
    @Input() public maxTime = '23:59:59';

    /**
     * Determines if the “all-day” slot is displayed at the top of the calendar.
     */
    @Input() public allDaySlot = false;

    /**
     * Whether or not to display a marker indicating the current time.
     */
    @Input() public nowIndicator = true;

    /**
     * Determines whether the events on the calendar can be modified.
     */
    @Input() public editable = false;

    // ########### OUTPUT EVENTS ###########
    /** Event when prev, next or today button is pressed */
    @Output() public eventClick = new EventEmitter<Date[]>();

    @Output() public eventDoubleClick = new EventEmitter<EventApi>();

    public _todayText = '';

    public options: CalendarOptions = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: this.defaultView,
        initialDate: this.defaultDate,
        headerToolbar: {
            left: 'prev,next today', center: 'title', right: this.availableViews
        },
        windowResize: _ => {
            this.calendarApi.setOption('height', this.calendarContainer.nativeElement.offsetHeight);
        },
        editable: this.editable,
        slotMinTime: this.minTime,
        slotMaxTime: this.maxTime,
        displayEventTime: false,
        customButtons: {
            prev: {
                text: 'prev', click: () => {
                    if (this.fc) {
                        this.loading = true;
                        this.fc.getApi()
                            .prev();
                        this.eventClick.emit([this.calendarApi.view.currentStart, this.calendarApi.view.currentEnd]);
                    }
                }
            }, next: {
                text: 'next', click: () => {
                    if (this.fc) {
                        this.loading = true;
                        this.calendarApi.next();
                        this.eventClick.emit([this.calendarApi.view.currentStart, this.calendarApi.view.currentEnd]);
                    }
                }
            }, today: {
                text: '', click: () => {
                    if (this.fc) {
                        this.loading = true;
                        this.calendarApi.today();
                        this.eventClick.emit([this.calendarApi.view.currentStart, this.calendarApi.view.currentEnd]);
                    }
                }
            }
        },
        locale: 'fr',
        allDaySlot: this.allDaySlot,
        nowIndicator: this.nowIndicator,
        eventMouseEnter: (ev) => {
            this.selectedTooltipInfo = ev.event.extendedProps.tooltipMessages ?? [];

            if (this.selectedTooltipInfo.length === 0) {
                return;
            }
            const positionStrategy = this.overlayPositionBuilder
                .flexibleConnectedTo(ev.el)
                .withPositions([{
                    originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 8
                }]);
            this.overlayRef = this.overlay.create({positionStrategy});
            const tooltipPortal = new ComponentPortal(IwCalendarTooltipComponent);
            if (!this.overlayRef) {
                return;
            }
            const tooltipRef: ComponentRef<IwCalendarTooltipComponent> = this.overlayRef.attach(tooltipPortal);
            tooltipRef.instance.text = ev.event.extendedProps.tooltipMessages ?? [];

        },
        eventMouseLeave: (info) => {
            if (!this.overlayRef) {
                return;
            }
            this.overlayRef.detach();
            this.overlayRef.dispose();
        },
        eventDidMount: (info) => {
            info.el.style.cursor = 'pointer';
            info.el.ondblclick = (() => {
                this.eventDoubleClick.emit(info.event);
            });
        }
    };
    private overlayRef?: OverlayRef;

    // ############ INTERNAL ############

    private _subs = new Subject();

    constructor(private overlayPositionBuilder: OverlayPositionBuilder, private overlay: Overlay, private readonly _translate: TranslateService) {
    }

    public ngOnInit() {
        this._translate.stream(['aujourdhui'])
            .pipe(takeUntil(this._subs))
            .subscribe(() => this._reloadTranslations());

        this.options = {
            ...this.options, initialView: this.defaultView, allDaySlot: this.allDaySlot, headerToolbar: {
                left: 'prev,next today', center: 'title', right: this.availableViews
            }
        };
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public ngAfterViewInit() {
        this.calendarApi.setOption('height', this.calendarContainer.nativeElement.offsetHeight);
    }

    public reloadCalendar() {
        this.loading = true;
        this.eventClick.emit([this.calendarApi.view.currentStart, this.calendarApi.view.currentEnd]);
    }

    private _reloadTranslations() {
        const buttonText = {
            month: this._translate.instant('mois'),
            week: this._translate.instant('semaine'),
            day: this._translate.instant('day')
        };
        const locale = this._translate.currentLang;
        if (this.options.customButtons) {
            this.options.customButtons['today'] = {
                ...this.options.customButtons['today'], text: this._translate.instant('aujourdhui')
            };
        }
        this.options = {...this.options, buttonText, locale};
    }
}
