import {Component} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Cctdectype} from '@app/sam-base/models/common/cctdectype';

@Component({
    selector: 'iw-cctdectype-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwCctdectypeDropdownComponent)]
})
export class IwCctdectypeDropdownComponent extends BaseEntityDropdown<Cctdectype> {

    protected getEntityType() {
        return Cctdectype;
    }

    protected buildLabel(e: Cctdectype): string {
        return e.titre || '';
    }
}
