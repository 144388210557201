/* eslint-disable max-classes-per-file */

import {Type} from '@angular/core';
import {Action} from '@ngrx/store';

import {getEntityMetadata, RestEntityMetadata} from '../../rest-api';

abstract class BaseEntityAction<T> implements Action {
    public abstract type: string;

    constructor(protected _type: Type<T> | T) {
    }

    public getMetadata(): RestEntityMetadata {
        return getEntityMetadata(this._type);
    }
}

// ############ ENTITY LOAD ############################
export const ENTITY_LOAD = 'Entity load';
export const ENTITY_LOAD_FAIL = 'Entity load fail';
export const ENTITY_LOAD_SUCCESS = 'Entity load success';

export class EntityLoad<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_LOAD;

    constructor(public readonly entityType: Type<T>, public readonly entityId: string | number) {
        super(entityType);
    }
}

export class EntityLoadFail<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_LOAD_FAIL;

    constructor(public readonly entityType: Type<T>, public readonly entityId: string | number, public readonly error?: any) {
        super(entityType);
    }
}

export class EntityLoadSuccess<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_LOAD_SUCCESS;

    constructor(public readonly entity: T) {
        super(entity);
    }
}

// ############ ENTITY CREATE ############################
export const ENTITY_CREATE = 'Entity create';
export const ENTITY_CREATE_FAIL = 'Entity create fail';
export const ENTITY_CREATE_SUCCESS = 'Entity create success';

export class EntityCreate<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_CREATE;

    constructor(public readonly entity: T) {
        super(entity);
    }
}

export class EntityCreateFail<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_CREATE_FAIL;

    constructor(public readonly entity: T, public readonly error: any) {
        super(entity);
    }
}

export class EntityCreateSuccess<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_CREATE_SUCCESS;

    constructor(public readonly entity: T) {
        super(entity);
    }
}

// ############ ENTITY UPDATE ##########################
export const ENTITY_UPDATE = 'Entity update';
export const ENTITY_UPDATE_FAIL = 'Entity update fail';
export const ENTITY_UPDATE_SUCCESS = 'Entity update success';

export class EntityUpdate<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_UPDATE;

    constructor(public readonly entity: T, public readonly patch: boolean = false) {
        super(entity);
    }
}

export class EntityUpdateFail<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_UPDATE_FAIL;

    constructor(public readonly entity: T, public readonly error: any) {
        super(entity);
    }
}

export class EntityUpdateSuccess<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_UPDATE_SUCCESS;

    constructor(public readonly entity: T) {
        super(entity);
    }
}

// ############ ENTITY DELETE ##########################
export const ENTITY_DELETE = 'Entity delete';
export const ENTITY_DELETE_FAIL = 'Entity delete fail';
export const ENTITY_DELETE_SUCCESS = 'Entity delete success';

export class EntityDelete<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_DELETE;

    constructor(public readonly entityType: Type<T> | T, public readonly entityId: string | number) {
        super(entityType);
    }

    public static fromEntity<T>(entity: T) {
        const metadata = getEntityMetadata(entity);
        return new EntityDelete<T>(metadata, metadata.$getPk());
    }
}

export class EntityDeleteFail<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_DELETE_FAIL;

    constructor(public readonly entityType: Type<T> | T, public readonly entityId: string | number, public readonly error?: any) {
        super(entityType);
    }
}

export class EntityDeleteSuccess<T> extends BaseEntityAction<T> {
    public readonly type = ENTITY_DELETE_SUCCESS;

    constructor(public readonly entityType: Type<T> | T, public readonly entityId: string | number) {
        super(entityType);
    }
}

// ############ ENTITY ACTION TYPE #######################

export type EntityActions<T> =
    | EntityLoad<T>
    | EntityLoadFail<T>
    | EntityLoadSuccess<T>
    | EntityCreate<T>
    | EntityCreateFail<T>
    | EntityCreateSuccess<T>
    | EntityUpdate<T>
    | EntityUpdateFail<T>
    | EntityUpdateSuccess<T>
    | EntityDelete<T>
    | EntityDeleteFail<T>
    | EntityDeleteSuccess<T>;
