import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';

@IwRestEntity('salbuf')
export class Salary {
    @IwRestEntityId() public salaryProcessId?: string;
    public sessionOwner?: string;
    public nom?: string;
    public nbrap?: number;
    public mntrapheu?: string;
    public mntrapind?: string;
    public mntacorap?: string;
    public nbaco?: number;
    public mntaco?: string;
    public nbind?: number;
    public mntind?: string;
    public codecna?: string;
    public codepgm?: string;
    public ageId?: string;
    public secId?: string;
    public cliId?: string;
    public nbhlpp?: number;
    public debutlpp?: string;
    public shouldlpp?: boolean;
    public salgenfreq?: number;
    public firstday?: string;
    public lastday?: string;
    public salidLast?: string;
    public datenaiss?: string;
    public dateanni?: string;
    public ageanni?: number;
    public cremark?: string;
    public marked?: boolean;
    public canbemark?: boolean;
    public misId?: string;
    public nbmis?: number;
    public missalmode?: string;
}

export enum SalaryTimeMode {
    MONTH = 'MONTH', HOURS = 'HOURS', MIXED = 'MIXED'
}

export enum SalBufferMenuEvent {
    FICHE_SAL = 'FICHE_SAL',
    EMPLOYE = 'EMPLOYE'
}

export enum SalarySalbufStatus {
    PREPARED = 'PREPARED', GENERATED = 'GENERATED', CALCULATED = 'CALCULATED'
}

export interface SalaryCountRequest {
    force: boolean;
    salaryProcessType: SalaryTimeMode;
    salId: string;
}

export interface SalaryProcess {
    id: number;
    owner: string;
    type: SalaryTimeMode;
    version: string;
    salId: string;
}


export interface SalBuffer {
    id: number;
    salaryProcessId: number;
    empId: string;
    nom: string;
    nbrap: number;
    mntrapheu: number;
    mntrapind: number;
    mntacorap: number;
    nbaco: number;
    mntaco: number;
    nbind: number;
    mntind: number;
    codecna: string;
    codepgm: string;
    ageId: string;
    secId: string;
    cliId: string;
    nbhlpp: number;
    debutlpp: string;
    shouldlpp: Boolean;
    firstday: string;
    lastday: string;
    salidLast: string;
    datenaiss: string;
    dateanni: string;
    ageanni: number;
    cremark: string;
    marked: Boolean;
    canbemark: Boolean;
    missalmode: number;
    nbmis: number;
    misId: string;
    timeMode: SalaryTimeMode;
    error?: BusinessExceptionErrors;
    status: SalarySalbufStatus;
}

export interface SalaryProcessDetails {

    salaryProcess: SalaryProcess;
    salBuffers: SalBuffer[];
}


export interface SalaryPrepareRequest {
    salaryProcessId: number;
    empIds: string[];
}

export interface SalaryGenerateRequest {
    salaryProcessId: number;
    empIds: string[];
}

export interface SalaryInitRequest {

    salId: string;
    salaryProcessType: SalaryTimeMode;
    force: boolean;
    empIds: string[];
}

export interface SalaryReq {
    salgenfreq?: number;
    ageId?: string;
    secId?: string;
    empId?: string;
    prepared?: boolean;
    empWithEleOnly?: boolean;
    markedEmployees?: string[] | undefined;
    timeMode?: string | undefined;
    empIdsWithErrors?: string[];
    force?: boolean;
}

export interface SalaryDelReq {
    empId?: string;
    salId?: string;
    timeMode?: string;
}

export interface DelResponse {
    locked: boolean;
    lockOwner?: string;
}

export interface FullSalary {
    salBufferList: Salary[];
    locked: boolean;
    lockOwner: string;
    empIdsWithErrors: string[];
}

export interface SalaryCountResponse {
    ageId: string;
    secId: string;
    empIds: string[];
    salaryCount: number;
}

export interface SalaryResponse {
    salBufferPreparedList: Salary[];
    hasError: boolean;
}

export interface SalaryStatus {
    empId: string;
    status: string;
}

export interface SalaryGenResponse {
    listUuidEmp: string[];
    hasError: boolean;
    salaryStatus: SalaryStatus[];
}

export enum SalaryProcessEvent {
    REASSIGN = 'REASSIGN'
}
