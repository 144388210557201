import {Injectable} from '@angular/core';

import {EnvironmentModel, getEnvironment, setEnvironment} from './environment';

/** Service to allow SamCore to access environment data */
@Injectable()
export class EnvironmentService {

    public get environment() {
        return getEnvironment();
    }

    public static forEnv(env?: Partial<EnvironmentModel>) {
        if (env) {
            setEnvironment(env);
        }
        return new EnvironmentService();
    }
}
