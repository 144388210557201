import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pprapihtot')
export class Pprapihtot {
    @IwRestEntityId() public misihId?: string;
    public rapId?: string;
    public datelundi?: string;
    public cid?: string;
    public indorheu?: string;
    public nbtot?: number;
}
