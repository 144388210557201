import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('canton', '', 'common')
export class Canton {
    @IwRestEntityId() public canton = '';

    public libelle = '';
    public isbaremes = '';
    public isbarc3?: string;
    public isnbheures?: number;
    public isnbjours?: number;
    public isnordre?: string;
    public iscommper?: number;
    public ishcalcmd?: number;
    public ismcalcmd?: number;
    public issd4?: number;
    public isbigot?: number;
    public bardAuto?: number;
    public bardSeuil?: number;
    public ismd2Auto?: number;
    public ismd2Seui?: number;
    public ismd3Extd?: number;
    public ismd6Auto?: number;
    public ismd6Seui?: number;
    public isData?: string;
}
