import {HttpClient} from '@angular/common/http';
import {Type} from '@angular/core';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {from, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../helpers/guards';
import {RestClient} from '../core';
import {ExtractContent, RestEntityParams, RestQueryResponse, RestResponse} from '../models';
import {isRestEntity} from './rest-entity-guards';
import {RestEntityNavigation} from './rest-entity-navigation';

export class RestEntityClient<T> extends RestClient<T> {
    protected readonly _entity: string;
    protected readonly _pk: string;
    protected readonly _prefix: string;

    private _navigation?: RestEntityNavigation<T>;

    constructor(protected _http: HttpClient, protected readonly _constructor: Type<T>) {
        super(_http);
        const METADATA = new _constructor();

        if (!this.validateMetadata(METADATA)) {
            // eslint-disable-next-line max-len
            throw new Error(`Can't create a rest client for entity: ${this._constructor.name}`);
        }

        this._entity = METADATA.$entity;
        this._pk = METADATA.$pk;
        this._prefix = METADATA.$prefix || '';
    }

    public get EntityName() {
        return this._entity;
    }

    public get EntityIdProp() {
        return this._pk;
    }

    /** Instantiate entity */
    public construct(entity: T): T {
        return new this._constructor(entity);
    }

    public update(entity: T, patch = false, params?: RestEntityParams) {
        const data = new this._constructor(entity);
        if (!isRestEntity(data)) {
            throw new Error('Entity does not support rest calls');
        }

        if (patch) {
            return this.PATCH<T>(data, params, data.$getPk()
                .toString())
                .pipe(map(e => new this._constructor(e)));
        }

        return this.PUT<T>(data, params, data.$getPk()
            .toString())
            .pipe(map(e => new this._constructor(e)));
    }

    public create(entity: T, params?: RestEntityParams) {
        return this.POST<T>(entity, params)
            .pipe(map(e => new this._constructor(e)));
    }

    public delete(entityId: string, params?: RestEntityParams) {
        return this.DELETE<any>(params, entityId);
    }

    public getById(entityId: string) {
        return this.GET<T>(undefined, entityId.toString())
            .pipe(map(e => new this._constructor(e)));
    }

    public getList(params?: RestEntityParams) {
        return this.GET<RestResponse<T>>(params);
    }

    public getFirst(params?: RestEntityParams): Observable<T | undefined> {
        return this.getFilterList(params)
            .pipe(map(e => e.length > 0 ? e[0] : undefined));
    }

    public getFilterList(params?: RestEntityParams) {
        return this.getList(params)
            .pipe(map(e => ExtractContent(e, this._entity)
                .map(i => new this._constructor(i))));
    }

    public query(params?: RestEntityParams) {
        return this.GET<RestQueryResponse<T>>(params, 'filter');
    }

    public getRefData(): Observable<T[]> {
        return from(this.getRefDataNavigation(1000))
            .pipe(switchMap((e: RestEntityNavigation<T>) => e.getAllItems()));
    }

    public getRefDataPerms(): Observable<T[]> {
        return from(this.getRefDataNavigation(1000))
            .pipe(switchMap((e: RestEntityNavigation<T>) => e.getAllItems()));
    }

    public async getRefDataNavigation(pageSize = 1000): Promise<RestEntityNavigation<T>> {
        return RestEntityNavigation.initNavigation(this, pageSize);
    }

    protected getRequestUrl(...parts: string[]) {
        return super.getRequestUrl(this._prefix, this._entity, ...parts);
    }

    protected validateMetadata(METADATA: any): METADATA is { $entity: string; $pk: string; $prefix?: string } {
        if (isNullOrUndefined(METADATA.$entity)) {
            ERROR(`Entity path not found for ${this._constructor.name}`);
            return false;
        }

        if (isNullOrUndefined(METADATA.$pk)) {
            ERROR(`Primary key not found for ${this._constructor.name}`);
            return false;
        }

        return true;
    }
}
