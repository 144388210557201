import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppmisrapview')
export class Ppmisrap {
    @IwRestEntityId() public misId?: string;
    @IwColumn({index: 0, name: 'id'}) public rapId?: string;
    @IwColumn({index: 2, name: 'date', type: 'date', width: 100}) public datelundi?: string;
    @IwColumn({index: 3, name: 'se', width: 55}) public datelundiWeek?: string;
    @IwColumn({index: 1, name: 'rap_no'}) public rapNo?: string;
    @IwColumn({index: 4, name: 'periode'}) public sal?: string;
    @IwColumn({index: 5, name: 'heures'}) public nbheutot?: string;
    @IwColumn({index: 6, name: 'cfChantier'}) public chantier?: string;
    @IwColumn({index: 7, width: 65}) public mc?: string;
    @IwColumn({index: 8}) public session?: string;
    @IwColumn({index: 9, width: 90}) public facNo?: string;
    @IwColumn({index: 10}) public ijrapId?: string;
}
