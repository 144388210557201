import {Component, Input} from '@angular/core';
import {BaseEntityDropdown, buildCustomValueProvider} from '@app/sam-base/base';
import {Gecliadr} from '@app/sam-base/models/invoices/gecliadr';


@Component({
    selector: 'iw-gecliadr-dropdown',
    templateUrl: './dropdown-template.html',
    providers: [buildCustomValueProvider(IwGeCliAdrDropdownComponent)]
})
export class IwGeCliAdrDropdownComponent extends BaseEntityDropdown<Gecliadr> {

    @Input() public width?: string | number;

    @Input() public showId ? = false;

    protected getEntityType() {
        return Gecliadr;
    }

    protected buildLabel(e: Gecliadr): string {
        if (!this.showId) {
            return e.adrlib || '';
        } else {
            return (e.adrlib + ' | ' + e.cliadrId) || '';
        }
    }
}
