import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('saconj')
export class Saconj {
    @IwRestEntityId() public conjId?: string;
    public dateCreat?: string;
    public dateTrack?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public empId?: string;
    public nom?: string;
    public prenom?: string;
    public adresse1?: string;
    public adresse2?: string;
    public npa?: string;
    public lieu?: string;
    public pays?: string;
    public noavs13?: string;
    public ltravail?: boolean;
    public cantontrav?: string;
    public paystrav?: string;
    public travtype?: string;
    public revenutype?: string;
    public adrother?: string;
    public datenaiss?: Date;
    public travdebut?: Date;
    public travfin?: Date;
    public travrevenu?: string;
}
