<div class="row doc-manager-container">
    <div class="col-xs-9 grid-container">
        <iw-rest-grid #docGrid
                      (rowDoubleClick)="onRowDoubleClick($event)"
                      (selected)="onRowSelect($event)"
                      [disableContextMenu]="isReadonly"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="false"
                      [queryStatements]="queryStatements"
                      [type]="type"
                      id="docTypeGrid"></iw-rest-grid>
    </div>
    <div class="col-xs-3">
        <p-fileUpload #fileUploader
                      (onClear)="fileUnselected()"
                      (onSelect)="fileSelected()"
                      (uploadHandler)="uploadDocument($event)"
                      [accept]="'.png, .pdf, .jpeg, .jpg, .gif, .doc, .docx, .xls, .xlsx'"
                      [cancelLabel]="'cancel' | translate"
                      [chooseLabel]="'choose' | translate"
                      [disabled]="isReadonly && !forceActive"
                      [uploadLabel]="'upload' | translate"
                      customUpload="true"
                      id="fileUploader"
                      invalidFileTypeMessageDetail=""
                      invalidFileTypeMessageSummary="Invalid"
                      name="myfile[]"></p-fileUpload>
        <iw-doc-types-combo-box #cboSel_DocTypeUpl
                                [(value)]="doccategory"
                                [isDisabled]="!hasFile"
                                [labelAlign]="'top'"
                                [label]="'type' | translate"
                                [type]="entityRef"
                                class="iw-input"></iw-doc-types-combo-box>

    </div>
</div>

<iw-dialog [(visible)]="displayRenameDialog"
           [closable]="true"
           [draggable]="false"
           [modal]="true"
           [showHeader]="false">

    <div class="row">
        <div class="col-xs-12">
            <iw-textbox [(value)]="documentSelected.libelle"
                        [label]="'renommer' | translate"></iw-textbox>
            <iw-doc-types-combo-box #cboSel_DocType
                                    [(value)]="documentSelected.doccategory"
                                    [labelAlign]="'top'"
                                    [label]="'type' | translate"
                                    [type]="entityRef"
                                    class="iw-input col-xs-12"></iw-doc-types-combo-box>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6">
            <iw-button (press)="onSave()"
                       [text]="'ok' | translate"></iw-button>
        </div>
        <div class="col-xs-6">
            <iw-button (press)="onCancel()"
                       [text]="'cancel' | translate"></iw-button>
        </div>
    </div>
</iw-dialog>
