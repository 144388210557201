import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';

@IwRestEntity('team')
export class Team {
    @IwRestEntityId()
    public teamId?: number;
    @IwColumn({
        index: 1,
        name: 'nom'
    })
    public name?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public dateTrack?: string;
}