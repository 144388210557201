import {Injectable, Type} from '@angular/core';

import {ModalComponent, OptionalDictionary} from '../../models';
import {ViewContainerRefService} from './view-container-ref.service';

export type ModalStore = OptionalDictionary<Type<ModalComponent<any, any>>>;

/** Handle modals instances */
@Injectable()
export class ModalService {

    constructor(private readonly _viewRefService: ViewContainerRefService) {
    }

    // Show modal and register events for ok/cancel
    public showModal<TResult, TData>(type: Type<ModalComponent<TResult, TData>>, data?: TData): Promise<TResult> {
        return new Promise(async (ok, cancel) => {
            const instance = await this._viewRefService.loadComponent(type);
            if (data) {
                instance.setData(data);
            }
            instance.registerOk(e => {
                ok(e);
                instance.__ref.destroy();
            });
            instance.registerCancel(e => {
                cancel(e);
                instance.__ref.destroy();
            });
        });
    }
}
