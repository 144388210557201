<iw-dialog [draggable]="true" [height]="400" [modal]="true" [title]="'signature_form' | translate" [visible]="true"
           [width]="800">
    <iw-busy [ajustLeft]="5" [ajustTop]="5" [isBusy]="isLoading"></iw-busy>

    <form [formGroup]="formGroup" class="row space-form">

        <iw-editor #edtMsg_text (valueChange)="setMsgText($event)" [(value)]="msgText" [labelAlign]="'left'"
                   [labelHeight]="'250px'" [label]="'signature' | translate" class="iw-input col-xs-9"></iw-editor>

    </form>

    <div class="row col-xs-12 padding-top-20">
        <iw-button (press)="onOk()" [text]="'save_sig' | translate" class="col-xs-offset-7 col-xs-3"></iw-button>
        <iw-button #fermer (press)="onCancel()" [text]="'fermer' | translate" class="col-xs-2"></iw-button>
    </div>
</iw-dialog>
