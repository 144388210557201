import {Type} from '@angular/core';
import {EsGridProfileQuery, EsQueryStatement, GridProfile, loadColumnMetadataFromType} from '@sam-base/core';
import {IwColumnDataType, IwGridColumn, SortDirection} from '@sam-base/models';
import {camelCase} from 'lodash';
import {isRestEntity} from '../../entity';
import {EsRequest} from '../models';

/**
 * Helper to generate a Elastic Search request
 */
export class QueryBuilder<T> {
    private static readonly SORTABLE_TYPES: IwColumnDataType[] = [
        'date',
        'number',
        'dateTime',
        'mnt',
        'boolean',
        'salId',
        'status',
        'keyword',
        'phonenumber'];
    private _sortProp?: keyof T;
    private _sorDirection: SortDirection = 'asc';
    private _statements: EsQueryStatement<T>[] = [];

    constructor(private readonly type?: Type<T>) {
    }

    /** Instaciate query builder with type statement */
    public static fromEntity<T>(entity: Type<T>): QueryBuilder<T> {
        const buidler = new QueryBuilder<T>(entity);
        const instance = new entity();
        if (isRestEntity(instance)) {
            buidler.addStatement(EsQueryStatement.fromTerm<any>({type: instance.$entity}, 'filter'));
        }

        return buidler;
    }

    public static fromGridProfile<T>(p: GridProfile<T>, type?: Type<T>) {
        const buidler = new QueryBuilder<T>(type);
        if (p.columns.sortBy) {
            buidler.setSort(<any>p.columns.sortBy, p.columns.sordDir);
        }
        buidler.addStatement(...EsGridProfileQuery.buildStatements(p));
        return buidler;
    }

    public getStatements() {
        return this._statements;
    }

    public getSort() {
        if (this._sortProp) {
            return {[this._sortProp]: this._sorDirection};
        }
    }

    public setSort(prop: keyof T, direction?: SortDirection) {
        this._sortProp = prop;
        this._sorDirection = direction || 'asc';
    }

    public addStatement(...s: EsQueryStatement<T>[]) {
        this._statements.push(...s);
    }

    public getRequest(size?: number, from?: number): EsRequest<T> {
        const req: EsRequest<any> = {
            timeout: '1m', // Timeout 1 minute
            from,
            size,
            query: {
                bool: {
                    must: [],
                    must_not: [],
                    filter: []
                }
            }
        };

        if (this._sortProp) {
            // fixme reverted this comment : // commented the get sort suffix has it does not seem to be needed anymore with the way we index from java now
            // the sortSuffix probably need to be changed and adapted with our new way of sorting
            const sortProp = String(this._sortProp) + this.getSortSuffix(this._sortProp);
            req.sort = [
                {
                    [sortProp]: {
                        order: this._sorDirection,
                        unmapped_type: 'keyword'
                    }
                }];
        }

        for (const s of this._statements) {
            req.query.bool[s.type].push(s.statement);
        }

        // Remove deleted results
        req.query.bool.must_not
            .push(EsQueryStatement.fromExists('dateDelet').statement);

        return req;
    }

    private filterColumnMetadata(propertyName: keyof T): IwGridColumn<T> | undefined {
        if (!this.type) {
            return;
        }
        const columnMetadata = loadColumnMetadataFromType(this.type);
        return columnMetadata.find(column => camelCase(<string>column.prop) === camelCase(<string>propertyName));
    }

    /** Checks if '.keyword' is required to sort in ES */
    private getSortSuffix(propertyName: keyof T) {
        if (!this.type) {
            return '.keyword';
        }

        const matchedColumn = this.filterColumnMetadata(propertyName);

        if (matchedColumn && matchedColumn.type && QueryBuilder.SORTABLE_TYPES.includes(matchedColumn.type)) {
            return '';
        }

        return '.keyword';
    }
}
