import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {OverlayPanel} from 'primeng/overlaypanel';

import {IwGridColumn} from '../../../../models';

@Component({
    selector: 'iw-table-cell',
    templateUrl: './iw-table-cell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IwTableCellComponent<T> {
    @Input() public row?: T;

    @Input() public column?: IwGridColumn<T>;

    @Output() public previewOver = new EventEmitter<any>();

    public currentPreviewId?: T[Extract<keyof T, string | number>];

    @ViewChild('previewOp', {static: true}) public previewOp?: OverlayPanel;
    public imgToShow?: SafeUrl;

    constructor(private _translate: TranslateService, private _http: HttpClient, private sanitizer: DomSanitizer) {
    }

    public get value(): any {
        if (this.row && this.column && this.column.prop) {
            return this.row[this.column.prop];
        }

        return '';
    }

    public get colorMapper(): ((value: any) => string) | undefined {
        return this.column?.colorMapper;
    }

    // eslint-disable-next-line complexity
    public onPreviewMouseOver(event: MouseEvent, column?: IwGridColumn<T>) {
        this.previewOver.emit(event);

        if (this.row && this.column && this.column.prop) {
            this.currentPreviewId = this.row[this.column.prop];
            this.getPreviewImg();
        }

        if (!this.previewOp) {
            return;
        }

        if (this.previewOp.overlayVisible) {
            this.previewOp.hide();
        } else {
            this.previewOp.toggle(event);
        }
    }

    public getPreviewImg() {
        if (!this.currentPreviewId) {
            return;
        }

        this._http.get('/api/documents/preview/' + this.currentPreviewId, {responseType: 'blob'})
            .subscribe((img: Blob) => {
                this.createImageFromBlob(img);
            });
    }

    protected getDocTrans(value: string) {
        return this._translate.instant(`docType_${value}`);
    }

    private createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.imgToShow = this.sanitizer
                .bypassSecurityTrustUrl('' + reader.result);
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

}
