<div [ngClass]="className" class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label" class="iw-form-group-label" for="item">{{ label | translate }}
    </div>
    <div class="input-container">
        <div class="p-inputgroup iw-input">
            <ng-container *ngIf="!!icon">
                <span class="p-inputgroup-addon"><span class="fa fa-{{icon}}"></span></span>
            </ng-container>
            <input #control (dblclick)="onDoubleClick()" [(ngModel)]="value"
                   [class.active-input]="!isDisabled && !isReadOnly" [disabled]="isDisabled" [readOnly]="true"
                   [readonly]="isReadOnly" class="clearing-input" pInputText type="text" width="100%">
        </div>
        <div #TxtWarningMessage *ngIf="isInvalid && warningMessage">
            <small class="iw-input col-xs-12 padding-left-0"
                   style="color: #ff0000; font-size:10px"> {{ warningMessage | translate }} </small>
        </div>
    </div>
</div>
