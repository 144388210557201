<div class="row lists-container">
    <div class="col-lg-5 col-no-padding" id="first-list">
        <iw-list-box [(selectedOptions)]="firstListCurrent" [listFocus]="true" [optionsList]="firstList"></iw-list-box>
    </div>

    <div class="col-lg-2 col-no-padding selection-container">
        <div class="row">
            <iw-button (press)="passSelectedToSecond()" [isDisabled]="disabled" iconClass="fa fa-angle-right"
                       id="add-element" width="45px"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="passAllToSecond()" [isDisabled]="disabled" iconClass="fa fa-angle-double-right"
                       width="45px"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="passSelectedToFirst()" [isDisabled]="disabled" iconClass="fa fa-angle-left"
                       width="45px"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="passAllToFirst()" [isDisabled]="disabled" iconClass="fa fa-angle-double-left"
                       width="45px"></iw-button>
        </div>
    </div>

    <div class="col-lg-5 col-no-padding" id="second-list">
        <iw-list-box [(selectedOptions)]="secondListCurrent" [optionsList]="secondList"></iw-list-box>
    </div>
</div>