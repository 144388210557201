import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {BaseFormControl} from '@app/sam-base/base/base-form-control';
import {FileUpload} from 'primeng/fileupload';

/** Component developed in: https://samredesign.atlassian.net/browse/SAM-4207 */

@Component({
    selector: 'iw-uploader',
    templateUrl: './iw-uploader.component.html'
})
export class IwUploaderComponent extends BaseFormControl<string> {

    /** Max-height value for the whole component to use */
    @Input() public maxHeight = '174px';

    @Input() public acceptedFileType: string = '.png, .pdf, .jpeg, .jpg, .gif, .doc, .docx, .xls, .xlsx, .xml';

    @ViewChild('fileUploader', {static: true}) public fileUpload?: FileUpload;
    @Output() public fileSelect = new EventEmitter<File[]>();

    constructor() {
        super();
    }

    public get className() {
        return {
            'btn-text': !!this.label
        };
    }


    public fileSelected(ev: { currentFiles: File[] }) {
        this.fileSelect.emit(ev?.currentFiles ?? []);
    }

    public async uploadDocument({files}: { files: File[] }) {
        if (files && files.length && files[0]) {
            const file: File = files[0];
            try {
                this.value = await this.getBase64(file);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err);
            }
            this.clearFile();
        }
    }

    private getBase64(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    private clearFile() {
        if (this.fileUpload) {
            this.fileUpload.clear();
        }
    }
}
