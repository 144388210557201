<iw-modal-form (pressCancel)="onPressCancel()" (pressOk)="onPressOk()" [height]="600"
               [title]="'select_missions' | translate" [width]="1500">
    <p-table #dt [(selection)]="selectedMissions" [filterDelay]="0" [loading]="false"
             [paginatorDropdownAppendTo]="'body'" [paginator]="true" [rowHover]="true" [rowsPerPageOptions]="[10,25,50]"
             [rows]="10" [showCurrentPageReport]="true" [value]="missions"
             currentPageReportTemplate="{currentPage} - {totalPages}" dataKey="misId" id="rappreprt-table">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="misId">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'mission' | translate }}
                        <p-sortIcon field="misId"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="misId" type="text"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="nomemploye">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'employe' | translate }}
                        <p-sortIcon field="nomemploye"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="nomemploye" type="text"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="nomclient">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'nomclient' | translate }}
                        <p-sortIcon field="nomclient"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="nomclient" type="text"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="datedebut">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'start' | translate }}
                        <p-sortIcon field="datedebut"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="datedebut" type="date"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="datefin">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'datefin' | translate }}
                        <p-sortIcon field="datefin"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="datefin" type="date"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="ageId">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'agence' | translate }}
                        <p-sortIcon field="ageId"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="ageId" type="text"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="secId">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'nomsecteur' | translate }}
                        <p-sortIcon field="secId"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="secId" type="text"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="misstatustext">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'status' | translate }}
                        <p-sortIcon field="misstatustext"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="misstatustext"
                                        type="text"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="empId">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'emp_id' | translate }}
                        <p-sortIcon field="empId"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="empId" type="text"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="cliId">
                    <div class="flex justify-content-between align-items-center">
                        {{ 'cli_id' | translate }}
                        <p-sortIcon field="cliId"></p-sortIcon>
                        <p-columnFilter class="ml-auto" display="menu" field="cliId" type="text"></p-columnFilter>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template let-mission pTemplate="body">
            <tr class="p-selectable-row">
                <td>
                    <p-tableCheckbox [value]="mission"></p-tableCheckbox>
                </td>
                <td>
                    {{ mission.misId }}
                </td>
                <td>
                    {{ mission.nomemploye }}
                </td>
                <td>
                    {{ mission.nomclient }}
                </td>
                <td>
                    {{ mission.datedebut | date: 'MM.dd.yyyy' }}
                </td>
                <td>
                    {{ mission.datefin | date: 'MM.dd.yyyy' }}
                </td>
                <td>
                    {{ mission.ageId }}
                </td>
                <td>
                    {{ mission.secId }}
                </td>
                <td>
                    {{ mission.misstatustext }}
                </td>
                <td>
                    {{ mission.empId }}
                </td>
                <td>
                    {{ mission.cliId }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">{{ 'no_missions_found' | translate }}</td>
            </tr>
        </ng-template>
    </p-table>
</iw-modal-form>
