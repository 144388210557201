import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('pphis')
export class Pphis {
    @IwRestEntityId() public hisId?: string;
    public lastday?: string;
    public nbnuits?: number;
    public userDelet?: string;
    public userCreat?: string;
    public nbhlpp?: number;
    public dateDelet?: string;
    public dateCreat?: string;
    public empId?: string;
    public userModif?: string;
    public firstday?: string;
    public nbj?: number;
    public nbjsal?: number;
    public nbh?: number;
    public salId?: string;
    public nbhequip?: number;
    public dateModif?: string;
    public nbjequip?: number;
    public nbind?: number;
    public nbhsal?: number;
    public nbhnuit?: number;
}
