import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    AdvancedSearchComponent
} from '@app/modules/sam-main/placement/components/advanced-search/advanced-search.component';
import {
    PlanningDayComponent
} from '@app/modules/sam-main/placement/components/planning/planning-day/planning-day/planning-day.component';
import {
    PlanningDaysComponent
} from '@app/modules/sam-main/placement/components/planning/planning-days/planning-days/planning-days.component';
import {PlanningComponent} from '@app/modules/sam-main/placement/components/planning/planning.component';
import {
    PpekwMatchingComponent
} from '@app/modules/sam-main/placement/components/ppekw-matching/ppekw-matching.component';
import {PpkwMatchingComponent} from '@app/modules/sam-main/placement/components/ppkw-matching/ppkw-matching.component';
import {
    ScheduleEventComponent
} from '@app/modules/sam-main/placement/components/schedule-event/schedule-event.component';
import {PpekwService} from '@app/modules/sam-main/placement/services/ppekw.service';
import {SamComponentsModule} from '@app/sam-base/components/sam-components.module';
import {TwoDecimalsInputDirective} from '@app/sam-base/core/directives';
import {TranslateModule} from '@ngx-translate/core';
import {IwInvoicePrintDropdownComponent} from '@sam-base/components/dropdown/iw-invoiceprint-dropdown.component';
import {
    ModalPrintDropdownComponent
} from '@sam-base/components/modal/modal-confirm-dialog/modal-print-dropdown.component';
import {IwAgenceDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-agence-dropdown.component';
import {IwAgencyComboBoxComponent} from '@shared/widgets/iw-components/dropdown/iw-agency-combo-box.component';
import {
    IwConseillerPpconDropdownComponent
} from '@shared/widgets/iw-components/dropdown/iw-conseiller-ppcon-dropdown.component';
import {IwCttDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-ctt-dropdown.component';
import {IwGeCliAdrDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-ge-cli-adr-dropdown.component';
import {IwGepaicondDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-gepaicond-dropdown.component';
import {IwGepaimodeDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-gepaimode-dropdown.component';
import {IwGltvadeftypeDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-gltvadef-dropdown.component';
import {IwSacs06defDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-sacs06def-dropdown.component';
import {
    IwSagssd4istypeDropdownComponent
} from '@shared/widgets/iw-components/dropdown/iw-sagssd4istype-dropdown.component';
import {
    IwSagssd4statantypeDropdownComponent
} from '@shared/widgets/iw-components/dropdown/iw-sagssd4statantype-dropdown.component';
import {
    IwSagssd4statocttypeDropdownComponent
} from '@shared/widgets/iw-components/dropdown/iw-sagssd4statocttype-dropdown.component';
import {IwSagstypeDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-sagstype-dropdown.component';
import {IwSapaimodeDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-sapaimode-dropdown.component';
import {
    IwSasd4emitypeDropdownComponent
} from '@shared/widgets/iw-components/dropdown/iw-sasd4emitype-dropdown.component';
import {IwSasdstblDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-sasdstbl-dropdown.component';
import {IwSecteurDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-secteur-dropdown.component';
import {IwSmtpConfigDropdownComponent} from '@shared/widgets/iw-components/dropdown/iw-smtp-dropdown.component';
import {
    EntityDetailFactureComponent
} from '@shared/widgets/iw-components/entity-details/entity-detail-facture.component';
import {EntityDetailSaempComponent} from '@shared/widgets/iw-components/entity-details/entity-detail-saemp.component';
import {IwMonthsPickerComponent} from '@shared/widgets/iw-components/iw-months-picker/iw-months-picker.component';
import {IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {NgxPaginationModule} from 'ngx-pagination';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {EditorModule} from 'primeng/editor';
import {FileUploadModule} from 'primeng/fileupload';
//import { FullCalendarModule } from 'primeng/fullcalendar';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MenuModule} from 'primeng/menu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {
    IwConseillerDropdownComponent
} from 'src/app/shared/widgets/iw-components/dropdown/iw-conseiller-dropdown/iw-conseiller-dropdown.component';
import {EntityTextfieldComponent} from '../form-components/entity-textfield/entity-textfield.component';
import {
    MultiEntityTextfieldComponent
} from '../form-components/multi-entity-textfield/multi-entity-textfield.component';
import {
    IwMultiautocompleteQualifComponent
} from '../form-components/multiautocomplete-select/iw-multiautocomplete-qualif.component';
import {IwCantonDropdownComponent} from './dropdown/iw-canton-dropdown.component';
import {IwCctDropdownComponent} from './dropdown/iw-cct-dropdown.component';
import {IwCctdectypeDropdownComponent} from './dropdown/iw-cctdectype-dropdown.component';
import {IwCctgroupDropdownComponent} from './dropdown/iw-cctgroup-dropdown.component';
import {IwClientDropdownComponent} from './dropdown/iw-cli-dropdown.component';
import {IwCliAdrDropdownComponent} from './dropdown/iw-cliadr-dropdown.component';
import {IwCliattDropdownComponent} from './dropdown/iw-cliatt-dropdown.component';
import {IwEmployeDropdownComponent} from './dropdown/iw-emp-dropdown.component';
import {IwGecligrpDropdownComponent} from './dropdown/iw-gecligrp-dropdown.component';
import {IwGedtaDropdownComponent} from './dropdown/iw-gedta-dropdown.component';
import {IwPaysDropdownComponent} from './dropdown/iw-pays-dropdown.component';
import {IwPdflistDropdownComponent} from './dropdown/iw-pdflist-dropdown.component';
import {IwPpaptDropdownComponent} from './dropdown/iw-ppapt-dropdown';
import {IwPpcctDropdownComponent} from './dropdown/iw-ppcct-dropdown.component';
import {IwPpgriDropdownComponent} from './dropdown/iw-ppgri-dropdown.component';
import {IwPpjfDropdownComponent} from './dropdown/iw-ppjf-dropdown.component';
import {IwPpshcalcmodeDropdownComponent} from './dropdown/iw-ppshcalcmode-dropdown.component';
import {IwSagsDropdownComponent} from './dropdown/iw-sags-dropdown.component';
import {IwTemplateDropdownComponent} from './dropdown/iw-template-dropdown.component';
import {EntityDetailClientComponent} from './entity-details/entity-detail-client.component';
import {EntityDetailCommandFixeComponent} from './entity-details/entity-detail-command-fixe.component';
import {EntityDetailCommandComponent} from './entity-details/entity-detail-command.component';
import {EntityDetailEmployeeComponent} from './entity-details/entity-detail-employee.component';
import {EntityDetailMissionComponent} from './entity-details/entity-detail-mission.component';
import {EntityDetailComponent} from './entity-details/entity-detail.component';
import {IwEditorComponent} from './iw-editor/iw-editor.component';
import {IwHoursGridComponent} from './iw-hours-grid/iw-hours-grid.component';
import {IwPrintEntityComponent} from './iw-print-entity/iw-print-entity.component';
import {TimeSlotComponent} from './iw-time-slots/components/time-slot.component';
import {IwTimeSlotsComponent} from './iw-time-slots/iw-time-slots.component';
import {IwUploaderComponent} from './iw-uploader/iw-uploader.component';
import {IwWeekdaysPickerComponent} from './iw-weekday-picker/iw-weekday-picker.component';

const comps: any[] = [
    AdvancedSearchComponent,
    EntityDetailClientComponent,
    EntityDetailCommandComponent,
    EntityDetailCommandFixeComponent,
    EntityDetailComponent,
    EntityDetailEmployeeComponent,
    EntityDetailFactureComponent,
    EntityDetailSaempComponent,
    EntityDetailMissionComponent,
    EntityTextfieldComponent,
    IwAgenceDropdownComponent,
    IwAgencyComboBoxComponent,
    IwCantonDropdownComponent,
    IwCctDropdownComponent,
    IwCctdectypeDropdownComponent,
    IwCctgroupDropdownComponent,
    IwCliAdrDropdownComponent,
    IwCliattDropdownComponent,
    IwClientDropdownComponent,
    IwConseillerDropdownComponent,
    IwConseillerPpconDropdownComponent,
    IwCttDropdownComponent,
    IwEditorComponent,
    IwEmployeDropdownComponent,
    IwGeCliAdrDropdownComponent,
    IwGecligrpDropdownComponent,
    IwGedtaDropdownComponent,
    IwGepaicondDropdownComponent,
    IwGepaimodeDropdownComponent,
    IwGltvadeftypeDropdownComponent,
    IwHoursGridComponent,
    IwInvoicePrintDropdownComponent,
    IwMonthsPickerComponent,
    IwMultiautocompleteQualifComponent,
    IwPaysDropdownComponent,
    IwSmtpConfigDropdownComponent,
    IwPdflistDropdownComponent,
    IwPpaptDropdownComponent,
    IwPpcctDropdownComponent,
    IwPpgriDropdownComponent,
    IwPpjfDropdownComponent,
    IwPpshcalcmodeDropdownComponent,
    IwPrintEntityComponent,
    IwSacs06defDropdownComponent,
    IwSagsDropdownComponent,
    IwSagssd4istypeDropdownComponent,
    IwSagssd4statantypeDropdownComponent,
    IwSagssd4statocttypeDropdownComponent,
    IwSagstypeDropdownComponent,
    IwSapaimodeDropdownComponent,
    IwSasd4emitypeDropdownComponent,
    IwSasdstblDropdownComponent,
    IwSecteurDropdownComponent,
    IwTemplateDropdownComponent,
    IwTimeSlotsComponent,
    IwUploaderComponent,
    IwWeekdaysPickerComponent,
    ModalPrintDropdownComponent,
    MultiEntityTextfieldComponent,
    PlanningComponent,
    PlanningDayComponent,
    PlanningDaysComponent,
    PpekwMatchingComponent,
    PpkwMatchingComponent,
    ScheduleEventComponent,
    TimeSlotComponent];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxPaginationModule,
        OverlayPanelModule,
        DropdownModule,
        MenuModule,
        ContextMenuModule,
        TableModule,
        TranslateModule,
        CheckboxModule,
        InputTextareaModule,
        CalendarModule,
        DialogModule,
        ButtonModule,
        TooltipModule,
        RadioButtonModule,
        AutoCompleteModule,
        FileUploadModule,
        ReactiveFormsModule,
        OverlayModule,
        EditorModule,
        NgxMaskDirective,
        NgxMaskPipe,
        SamComponentsModule],
    declarations: [
        ...comps,
        TwoDecimalsInputDirective
    ],
    exports: [
        SamComponentsModule,
        TwoDecimalsInputDirective,
        ...comps
    ],
    providers: [
        PpekwService,
        provideNgxMask()]
})
export class IwComponentsModule {
}

