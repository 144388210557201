<div class="col-xs-12 row no-padding">
    <div class="col-xs-2 row no-padding-left">
        <iw-button #hhMM (press)="changeHoursFormat()" [isDisabled]="changingHoursFormat" [text]="'h:m'"
                   class="iw-button col-xs-6 no-padding"></iw-button>

        <iw-textfield #chkLhhmm (valueChange)="resetLhmm()" [(value)]="lhhmm" class="iw-input col-xs-6 no-padding"
                      isDisabled="true"></iw-textfield>
    </div>

    <iw-button #raz (press)="resetHours()" [isDisabled]="disableEdit" [text]="'raz' | translate"
               class="iw-button col-xs-2"></iw-button>

    <iw-button #luVen (press)="showLuveModal()" [isDisabled]="lhhmm === '1/60' || disableEdit || !weekHoursEnabled()"
               [text]="'luven' | translate" class="iw-button col-xs-2"></iw-button>

    <div #heureLabel class="iw-label col-xs-1" title="{{'heure' | translate}}">{{ 'heure' | translate }}
    </div>

    <iw-dropdown #addHoursDropdown *ngIf="canAddHours" [(value)]="selectedHourToAdd" [isDisabled]="false"
                 [options]="hoursToAdd" class="iw-input col-xs-3"></iw-dropdown>

    <iw-button #addHourButton (press)="addHour()" [isDisabled]="!selectedHourToAdd" [text]="'ajouter' | translate"
               class="iw-button col-xs-2"></iw-button>
</div>
<div class="row col-xs-12 no-padding">
    <iw-textfield #chkLhhmm [(value)]="hoursLabel" [labelAlign]="'top'" [label]="'heures' | translate"
                  class="iw-input col-xs-3 no-padding" isDisabled="true"></iw-textfield>
    <iw-textfield #chkLhhmm [labelAlign]="'top'" [label]="'employe' | translate" [value]="employeHeader"
                  class="iw-input col-xs-3 no-padding" isDisabled="true"></iw-textfield>
    <iw-textfield #chkLhhmm [labelAlign]="'top'" [label]="'client' | translate" [value]="clientHeader"
                  class="iw-input col-xs-3 no-padding" isDisabled="true"></iw-textfield>
    <iw-textfield #chkLhhmm [(value)]="coeffHeader" [labelAlign]="'top'" [label]="'coeff' | translate"
                  class="iw-input col-xs-3 no-padding" isDisabled="true"></iw-textfield>
</div>
<div class="table-hours-grid">
    <div [class.selection-checkbox]="true" [style.min-height]="'250px'" class="iw-table flex flex-v">
        <div [class.overflow]="true" class="flex-grow iw-table-content">
            <p-table #ptable (selectionChange)="fullfillHeader()" [(selection)]="selectedHour" [scrollable]="true"
                     [value]="ppheuSaidInput?.gridHours" dataKey="grhId" scrollHeight="'flex'">
                <ng-template pTemplate="header">
                    <tr>
                        <th id="th-ws"></th>
                        <th id="th-time-label">T</th>
                        <th id="th-label">{{ 'libelle' | translate }}</th>
                        <th id="th-code">Code</th>
                        <th id="th-ws2">{{ 'week_short_2' | translate }} {{ getNextDay(0) }}</th>
                        <th id="th-ws3">{{ 'week_short_3' | translate }} {{ getNextDay(1) }}</th>
                        <th id="th-ws4">{{ 'week_short_4' | translate }} {{ getNextDay(2) }}</th>
                        <th id="th-ws5">{{ 'week_short_5' | translate }} {{ getNextDay(3) }}</th>
                        <th id="th-ws6">{{ 'week_short_6' | translate }} {{ getNextDay(4) }}</th>
                        <th class="highlighted" id="th-ws7">{{ 'week_short_7' | translate }} {{ getNextDay(5) }}
                        </th>
                        <th class="highlighted" id="th-ws1">{{ 'week_short_1' | translate }} {{ getNextDay(6) }}
                        </th>
                        <th class="highlighted" id="th-total">{{ 'total' | translate }}
                        </th>
                    </tr>
                </ng-template>
                <ng-template let-gridHour pTemplate="body">
                    <tr [pSelectableRow]="gridHour" class="no-focus"
                        id="{{gridHour.grhId + '-' + ppheuSaidInput?.heurepause.auto}}">
                        <td>
                            <p-tableRadioButton [value]="gridHour"></p-tableRadioButton>
                        </td>
                        <td> {{ gridHour.indorheu }}</td>
                        <td #libelle (mouseenter)="setTooltip(libelle, gridHour.libelle)" (mouseleave)="clearTooltip()"
                            [pTooltip]="currentTooltip"> {{ gridHour.libelle }}
                        </td>
                        <td> {{ gridHour.grhId || gridHour.griId }}</td>
                        <td [ngClass]="{'highlighted' : isDisabled(1, gridHour)}" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input (ngModelChange)="updateHours(1, gridHour)" [(ngModel)]="gridHour.day1Hour"
                                           [disabled]="isDisabled(1, gridHour)" [indemnity]="gridHour?.indorheu === 'I'"
                                           [mode]="inputType" iwDurationInput>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ gridHour.day1Hour || 0 }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td [ngClass]="{'highlighted' : isDisabled(2, gridHour)}" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input (ngModelChange)="updateHours(2, gridHour)" [(ngModel)]="gridHour.day2Hour"
                                           [disabled]="isDisabled(2, gridHour)" [indemnity]="gridHour?.indorheu === 'I'"
                                           [mode]="inputType" iwDurationInput>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ gridHour.day2Hour || 0 }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td [ngClass]="{'highlighted' : isDisabled(3, gridHour)}" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input (ngModelChange)="updateHours(3, gridHour)" [(ngModel)]="gridHour.day3Hour"
                                           [disabled]="isDisabled(3, gridHour)" [indemnity]="gridHour?.indorheu === 'I'"
                                           [mode]="inputType" iwDurationInput>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ gridHour.day3Hour || 0 }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td [ngClass]="{'highlighted' : isDisabled(4, gridHour)}" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input (ngModelChange)="updateHours(4, gridHour)" [(ngModel)]="gridHour.day4Hour"
                                           [disabled]="isDisabled(4, gridHour)" [indemnity]="gridHour?.indorheu === 'I'"
                                           [mode]="inputType" iwDurationInput>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ gridHour.day4Hour || 0 }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td [ngClass]="{'highlighted' : isDisabled(5, gridHour)}" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input (ngModelChange)="updateHours(5, gridHour)" [(ngModel)]="gridHour.day5Hour"
                                           [disabled]="isDisabled(5, gridHour)" [indemnity]="gridHour?.indorheu === 'I'"
                                           [mode]="inputType" iwDurationInput>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ gridHour.day5Hour || 0 }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td [ngClass]="{'highlighted' : isDisabled(6, gridHour)}" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input (ngModelChange)="updateHours(6, gridHour)" [(ngModel)]="gridHour.day6Hour"
                                           [disabled]="isDisabled(6, gridHour)" [indemnity]="gridHour?.indorheu === 'I'"
                                           [mode]="inputType" iwDurationInput>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ gridHour.day6Hour || 0 }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td [ngClass]="{'highlighted' : isDisabled(7, gridHour)}" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input (ngModelChange)="updateHours(7, gridHour)" [(ngModel)]="gridHour.day7Hour"
                                           [disabled]="isDisabled(7, gridHour)" [indemnity]="gridHour?.indorheu === 'I'"
                                           [mode]="inputType" iwDurationInput>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ gridHour.day7Hour || 0 }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="highlighted"> {{ gridHour.totalHours }}</td>

                    </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                    <tr class="focus-row footer">
                        <td class="no-borders"></td>
                        <td class="no-borders"></td>
                        <td class="no-borders" style="width: 120px;"></td>
                        <td class="no-borders"></td>
                        <td>{{ hoursPerDay.day1 }}</td>
                        <td>{{ hoursPerDay.day2 }}</td>
                        <td>{{ hoursPerDay.day3 }}</td>
                        <td>{{ hoursPerDay.day4 }}</td>
                        <td>{{ hoursPerDay.day5 }}</td>
                        <td>{{ hoursPerDay.day6 }}</td>
                        <td>{{ hoursPerDay.day7 }}</td>
                        <td>{{ totalHours() }}</td>

                    </tr>
                </ng-template>
            </p-table>

        </div>
    </div>
</div>
<div class="row col-xs-12 no-padding">
    <iw-textfield #chkLhhmm [(value)]="mntAvsEmp" [labelAlign]="'top'" [label]="'avsEmploye' | translate"
                  class="iw-input col-xs-4 no-padding" isDisabled="true" stepValue="0.01" type="number"></iw-textfield>
    <iw-textfield #chkLhhmm [(value)]="totalEmploye" [labelAlign]="'top'" [label]="'totalEmploye' | translate"
                  class="iw-input col-xs-4 no-padding" isDisabled="true" stepValue="0.01" type="number"></iw-textfield>
    <iw-textfield #chkLhhmm [(value)]="totalClient" [labelAlign]="'top'" [label]="'totalclient' | translate"
                  class="iw-input col-xs-4 no-padding" isDisabled="true" stepValue="0.01" type="number"></iw-textfield>
</div>
