import {ElementRef, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';

import {OptionalDictionary} from '../../models';
import {DomEventService} from '../dom';
import {IwEventHubService} from '../events';
import {UuidService} from '../uuid/uuid.service';
import {HotkeyEvent} from './models/hotkey-event.model';
import {HotkeyInstance} from './models/hotkey-instance.model';
import {Hotkey, TogglePanelModel} from './models/hotkey.model';
import {keyMatch} from './tools/compare';

const KEY_PRESS_EVENT = 'keydown';

@Injectable()
export class HotkeyService implements OnDestroy {
    private readonly _store: OptionalDictionary<HotkeyInstance> = {};
    private _togglePanelSub: Subject<TogglePanelModel> = new Subject();

    constructor(private readonly _domEvents: DomEventService, private readonly _uuid: UuidService,
                private readonly _events: IwEventHubService<string>) {
        this._togglePanelSub.pipe(debounceTime(300))
            .subscribe((info) => this.tooglePanel(info));
    }

    public ngOnDestroy() {
        this._togglePanelSub.unsubscribe();
    }

    /**
     * Register a hotkey
     *
     * @param hotkey the hotkey to register
     * @param target target a element or a selector,
     *               define as `window` to be a global hotkey
     *
     * @returns unique code that identifies the hotkey subscription
     */
    public registerHotkey(hotkey: Hotkey,
                          target: ElementRef | Element | string | 'window' = 'window'): string | undefined {
        const uuid = this._uuid.generateString();
        let obs: Observable<KeyboardEvent> | undefined;

        const listener = (e: { e: KeyboardEvent; h: Hotkey }) => {
            if (hotkey.preventDefaultAction) {
                // eslint-disable-next-line no-console
                console.warn('preventing default for:', hotkey);
                e.e.preventDefault();
            }

            const event: HotkeyEvent = {
                hotkey: e.h,
                source: e.e,
                subscriptionId: uuid
            };

            this._togglePanelSub.next({
                hotkey,
                event,
                e
            });
        };

        if (target === 'window') {
            obs = this._domEvents.window.keydown.get(0);
        } else {
            const el = typeof target === 'string' ? document.querySelector(target) : target;
            if (el) {
                obs = this._domEvents
                    .addListener<KeyboardEvent>(el, KEY_PRESS_EVENT)
                    .get(0);
            }
        }

        if (!obs) {
            return;
        }

        this._store[uuid] = new HotkeyInstance(obs.pipe(filter(e => keyMatch(e, hotkey)), map(e => ({
            e,
            h: hotkey
        })))
            .subscribe(listener), hotkey);

        return uuid;
    }

    /** Remove subscription from event */
    public removeHotkey(uuid: string): boolean {
        const hot = this._store[uuid];
        if (hot) {
            hot.destroy();
            return true;
        }

        return false;
    }

    /**
     * Triggers side panel if the F4 (used to be ESC) button is pressed
     *
     * @info Necessary info to toggle the panel
     */
    private tooglePanel(info: TogglePanelModel) {
        this._events.emit(info.e.h.eventType, info.event);
        if (typeof info.hotkey.fn === 'function') {
            info.hotkey.fn(info.event);
        }
    }
}
