import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId, SearchEntityStringOperator} from '@app/sam-base/core/rest-api';


@IwRestEntity('ppcct')
export class Ppcct {
    @IwRestEntityId() @IwColumn({
        index: 0,
        name: 'code'
    }) public cctId?: string;
    public raAgeA?: number;
    public exGsCot?: string;
    public pgmcodesf?: string;
    public compteRa?: string;
    public cnacodes?: string;
    public canton?: string;
    public tx13?: number;
    public pgmcodesh?: string;
    public remarques?: string;
    public cctAbrev?: string;
    public compteEx?: string;
    public ageallowed?: string;
    @IwColumn({
        index: 2,
        name: 'status',
        filterQuery: '1',
        filterOperator: SearchEntityStringOperator.Like
    }) public cctstatus?: string;
    public fcGsCoe?: string;
    public fcGsBas?: string;
    public exGsCfe?: string;
    public fcGsCof?: string;
    public txVac4?: number;
    public exMntEmp?: number;
    public raTxEmp?: number;
    public dateDe?: string;
    public shsupmode?: number;
    public raAgeDe?: number;
    public exDedmod?: number;
    public exTxEmp?: number;
    public noteHor?: string;
    public cctData?: string;
    public canallowed?: string;
    public txJf?: number;
    public fcMntEmp?: number;
    public fcTxSoc?: number;
    public dateA?: string;
    public raGsBas?: string;
    public paucalcmod?: number;
    public defcodpgmf?: string;
    public fcMntSoc?: number;
    public typedec?: number;
    public modCnt?: number;
    public exGsCof?: string;
    public fcDedmod?: number;
    public annee?: string;
    public lexfcgroup?: string;
    public fcGsCfe?: string;
    @IwColumn({
        index: 1,
        name: 'description'
    }) public cctLib?: string;
    public raGsSal?: string;
    public sourceEd?: number;
    public cctgrpId?: string;
    public compteFc?: string;
    public exGsBas?: string;
    public gavNumber?: number;
    public raGsCfe?: string;
    public exTxSoc?: number;
    public raAgemode?: number;
    public exGsCoe?: string;
    public raMntSoc?: number;
    public shcalcmode?: number;
    public raMntEmp?: number;
    public txVac5?: number;
    public exMntSoc?: number;
    public pgmcodexpl?: string;
    public raGsCot?: string;
    public modData?: string;
    public fcGsSal?: string;
    public heurepause?: string;
    public raGsCof?: string;
    public exGsSal?: string;
    public defcodpgmh?: string;
    public modecreat?: string;
    public cctIdOri?: string;
    public raDedmod?: number;
    public raTxSoc?: number;
    public raGsCoe?: string;
    public maxheuweek?: number;
    public txPause?: number;
    public fcGsCot?: string;
    public fcTxEmp?: number;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public dateTrack?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    @IwColumn({
        index: 4,
        name: 'contractNo'
    }) public contractNo?: string;
    public contractName?: string;
    public jfNonPayes?: boolean;
    public tempdataTrans?: string;
    public tempdataTitle?: string;
}
