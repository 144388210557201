import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppquasal')
export class Ppquasal {
    @IwRestEntityId() public quasalId?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public qualif?: string;
    public quagrpId?: string;
    public txJf?: string;
    public dateDelet?: string;
    public quaId?: string;
    public notes?: string;
    public userCreat?: string;
    public quacatId?: string;
    public userModif?: string;
    public userDelet?: string;
    public tx13?: string;
    public cctId?: string;
    public canton?: string;
    public shcalcmode?: string;
    public txVac?: string;
    public salBase?: string;
}
