import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Npa} from '@app/sam-base/models';
import {Observable} from 'rxjs';
import {RestClient} from '../rest-api/core';

@Injectable()
export class NpaService extends RestClient<Npa> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    public getAllByNpa(npa?: string): Observable<Npa[]> {
        return this._http.get<Npa[]>(this._baseUrl + `npa/${npa}/all`);
    }

}
