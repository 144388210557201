import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

/**
 * Service to access global APP settings
 */
@Injectable()
export class GlobalSettingsService {

    // TODO: implement global settings
    public getLanguages(): Observable<string[]> {
        return of(['Français', 'English', 'Deutch', 'Italiano', 'Espagnol']);
    }

}
