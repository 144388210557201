/* eslint-disable max-classes-per-file */

import {Action} from '@ngrx/store';

import {Lookups} from '@sam-base/models';

export const LOOKUPS_LOAD = 'lookups load list';
export const LOOKUPS_LOAD_SUCCESS = 'lookups load list success';
export const LOOKUPS_LOAD_FAIL = 'lookups load list fail';

export class LoadLookups implements Action {
    public readonly type = LOOKUPS_LOAD;
}

export class LoadLookupsFail implements Action {
    public readonly type = LOOKUPS_LOAD_FAIL;

    constructor(public readonly error: any) {
    }
}

export class LoadLookupsSuccess implements Action {
    public readonly type = LOOKUPS_LOAD_SUCCESS;

    constructor(public readonly results: Lookups[]) {
    }
}

export type LookupsActions = LoadLookups | LoadLookupsFail | LoadLookupsSuccess;
