import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppcctgrp')
export class Ppcctgrp {
    @IwRestEntityId() public cctgrpId?: string;
    public cctgrpLib?: string;
    public userCreat?: string;
    public miscData?: string;
    public fouId?: string;
    public typedec?: string;
    public compte?: string;
    public userDelet?: string;
    public dateCreat?: string;
    public dateDelet?: string;
    public dateModif?: string;
    public raCollect?: string;
    public notes?: string;
    public userModif?: string;
}
