import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('bank', '', 'common')
export class Bank {
    group?: string;
    @IwRestEntityId()
    iid?: number;
    branchId?: string;
    sicIid?: string;
    headOffice?: number;
    iidType?: string;
    validSince?: string;
    sicParticipation?: string;
    euroSicParticipation?: string;
    language?: string;
    shortName?: string;
    bankOrInstitutionName?: string;
    domicileAddress?: string;
    postalAddress?: string;
    zipCode?: string;
    place?: string;
    phone?: string;
    fax?: string;
    diallingCode?: string;
    countryCode?: string;
    bic?: string;
}
