<div [ngClass]="className"
     class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label"
         [ngStyle]="{'max-width': labelWidth }"
         class="iw-form-group-label"
         for="item"
         title="{{ label | translate }}">{{ label | translate }}
    </div>
    <div class="dropdown-container">
        <p-dropdown #item
                    (onBlur)="onBlur()"
                    (onChange)="valueSelected.emit($event)"
                    (onClick)="onClick($event)"
                    [(ngModel)]="primeNgValue"
                    [disabled]="isDisabled"
                    [dropdownIcon]="'pi pi-caret-down'"
                    [options]="options"
                    [showClear]="showClear"
                    [style]="styleValue"
                    class="iw-dropdown"
                    optionLabel="name">
            <ng-template pTemplate="selectedItem">
                <div *ngIf="primeNgValue"
                     class="flex align-items-center gap-2">{{ primeNgValue?.name }}
                </div>
            </ng-template>

        </p-dropdown>
        <div #TxtWarningMessage
             *ngIf="isInvalid && warningMessage">
            <small class="iw-input col-xs-12 padding-left-0"
                   style="color: #ff0000; font-size:10px"> {{ warningMessage | translate }} </small>
        </div>
        <div #TxtHintMessage
             *ngIf="hintMessage">
            <small class="iw-input col-xs-12 padding-left-0"
                   style="font-size:10px"> {{ hintMessage | translate }} </small>
        </div>
    </div>
</div>
