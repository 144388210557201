import {Directive, EventEmitter, Input, Output, Type} from '@angular/core';

import {SortDirection} from '../models';
import {BaseFormControl} from './base-form-control';

@Directive()
export abstract class BaseEntityDropdown<T> extends BaseFormControl<string | number> {

    @Input() public width?: string | number;

    @Output() public selectedChanged = new EventEmitter<T>();

    @Output() public entitySelected = new EventEmitter<T>();

    @Input() public hasEmptyChoice = false;

    @Input() public emptyChoice?: T;

    @Input() public selectedChoice?: number;

    @Input() public preselectedId?: number;

    @Input() public loadAtStartUp = false;

    @Input() public selectedId?: number;

    public get entityType() {
        return this.getEntityType();
    }

    public getLabelBuilder() {
        return (e: T) => this.buildLabel(e);
    }

    @Input() public resultFilters: (e: T) => boolean = e => true;

    public onSelect(event: T) {
        this.selectedChanged.emit(event);
    }

    /** Override to sort results */
    public getSortProperty(): (keyof T | undefined) {
        return undefined;
    }

    public getSorDirection(): SortDirection {
        return 'asc';
    }

    public getExtraProperties(): ((keyof T)[] | undefined) {
        return undefined;
    }

    public onBlur() {
        super.onBlur();
    }

    protected abstract getEntityType(): Type<T>;

    protected abstract buildLabel(e: T): string;
}
