import {Type} from '@angular/core';
import {createSelector, select} from '@ngrx/store';
import {Observable} from 'rxjs';

import {getEntityMetadata} from '../../rest-api';
import {EntityStore} from '../models';
import {AppState} from '../reducers';

/** Select entities state from a AppState */
export const selectEntitiesState = (state: AppState) => state.entities;

/** Select entity state with a name  */
export function selectEntityState(state: AppState, name: string): EntityStore<any>;
export function selectEntityState<T>(state: AppState, name: string): EntityStore<T> {
    return selectEntitiesState(state)[name];
}

interface Props {
    name: string;
    id: string;
}

/** Select a specific entity by id */
export function selectEntity<T>(type: string | Type<T>, id: string | number): (source$: Observable<AppState>) => Observable<T | undefined> {
    const name = typeof type === 'string' ? type : getEntityMetadata<T>(type).$entity;

    const selectEntityByNameAndId = (entityName: string, entityId: string | number) => createSelector(selectEntitiesState, (entities) => entities[entityName].entities[entityId]);

    return select(selectEntityByNameAndId(name, id));
}


