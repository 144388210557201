import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('saeio')
export class Saeio {
    @IwRestEntityId() @IwColumn({name: 'Id', index: 0}) public saeioId?: number;
    public empId?: string;
    public no?: number;
    public motif?: string;
    @IwColumn({name: 'entree', index: 1, type: 'date'}) public dateIn?: Date;
    @IwColumn({name: 'sortie', index: 2, type: 'date'}) public dateOut?: Date;
    public salIdIn?: string;
    public salIdOut?: string;
}
