import * as actions from '../actions/form-entity.actions';
import {FormEntityStore} from '../models';

// eslint-disable-next-line complexity
export function reducer<T>(state: FormEntityStore<T> = {}, action: actions.FormEntityActions<T>): FormEntityStore<T> {
    switch (action.type) {
        case actions.FORM_ENTITY_INIT: {
            return {
                ...state, [action.uuid]: {
                    dirty: false,
                    loading: true,
                    entityId: action.entityId,
                    type: action.entityType,
                    useNavigation: action.useNavigation
                }
            };
        }

        case actions.FORM_ENTITY_NAVIGATE: {
            const feState = state[action.uuid];

            if (!feState) {
                return state;
            }

            return {
                ...state, [action.uuid]: {
                    ...feState, loading: false, dirty: false, entity: action.entity
                }
            };
        }

        case actions.FORM_ENTITY_UPDATE: {
            const feState = state[action.uuid];

            if (!feState) {
                return state;
            }

            return {
                ...state, [action.uuid]: {
                    ...feState, loading: false, dirty: true, entity: action.entity
                }
            };
        }

        case actions.FORM_ENTITY_SAVE: {
            const feState = state[action.uuid];

            if (!feState) {
                return state;
            }

            return {
                ...state, [action.uuid]: {
                    ...feState, loading: true, dirty: true
                }
            };
        }
    }

    return state;
}
