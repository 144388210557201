import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppgrt')
export class Ppgrt {
    @IwRestEntityId() @IwColumn({index: 0, name: 'id', width: 20}) public grtId?: string;
    public grtData?: string;
    @IwColumn({index: 1, name: 'genretrav', width: 80}) public genretrav?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public dateTrack?: string;
}
