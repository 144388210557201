import {random} from 'lodash';

export function getDummyList<T extends {}>(obj: T, length = 10): T[] {
    const arr: T[] = [];

    for (let i = 0; i < length; i++) {
        arr.push(getDummyData(obj));
    }

    return arr;
}

/** Fill object with dummy values */
export function getDummyData<T extends {}>(obj: T): T {
    const n: any = {};

    for (const [key, value] of Object.entries(obj)) {
        n[key] = getRandom(value);
    }

    return n;
}

function getRandom(prop: unknown): any {
    switch (typeof prop) {
        case 'boolean':
            return getRandomBoolean();
        case 'string':
            return getRandomString();
        case 'object':
            return getRandom(prop || 0);
    }

    return random(0, 99999, false);
}

function getRandomBoolean() {
    return random() % 2 === 0;
}

function getRandomString() {
    const strings = ['Tom Waits', 'Pink Floyd', 'Nick Cave & The Bad Seeds', 'Radiohead', 'Ludovico Einaudi', 'Bob Dylan', 'Sigur Rós', 'Mão Morta', 'The White Stripes', 'The National', 'Linda Martini', 'In This Moment', 'Amon Amarth', 'The Beatles', 'The Kills', 'PJ Harvey', 'Rammstein', 'Patti Smith', 'The Doors', 'Cho Young-Wuk', 'Yeah Yeah Yeahs', 'Led Zeppelin', 'Frédéric Chopin', 'Metric', 'Rush', 'David Bowie', 'Black Sabbath', 'The Rolling Stones', 'Ensiferum', 'Moby', 'Belle and Sebastian', 'Steven Wilson', 'Arch Enemy', 'Air', 'Miles Davis', 'Laura Marling', 'Cat Power', 'Eluvium', 'The Dead Weather', 'The Smashing Pumpkins', 'Frank Zappa', 'The Jimi Hendrix Experience', 'Opeth', 'José Cid', 'Modest Mouse', 'Portishead', 'Regina Spektor', 'Da Weasel', 'David Housden', 'Clint Mansell'];

    const rnd = random(0, (strings.length - 1), false);
    return strings[rnd];
}
