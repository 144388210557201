import {ComponentRef, Injectable, Type} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, first} from 'rxjs/operators';

import {isDynamicComponent} from '../../helpers';
import {DynamicComponent} from '../../models/components';
import {ContainerDirective} from '../directives/container.directive';

export interface ContainerStore {
    [key: string]: ContainerDirective | undefined;
}

/** Service to handle containers and inject components into containers */
@Injectable()
export class ViewContainerRefService {
    private readonly _containerStore: ContainerStore = {};
    private readonly _registerContainer = new Subject<ContainerDirective>();

    constructor() {
    }

    /**
     * Create instance of a component and inject it into a container
     * returns instance of component
     *
     * @param component component type to instantiate
     * @param containerName container to inject the component
     */
    public async loadComponent<T>(component: DynamicComponent | Type<T>, containerName: string = 'root'): Promise<T & {
        __ref: ComponentRef<T>
    }> {

        // Check input params
        const compType = isDynamicComponent(component) ? component.type : component;

        // Load reference
        const ref = await this
            .getComponentReference<T>(compType, containerName);

        const instance = ref.instance;

        // Set data for instance
        if (isDynamicComponent(component)) {
            (<any>instance).data = component.data;
        }

        (<any>instance).__ref = ref;

        return <any>instance;
    }

    /**
     * Instatiate a component into a container
     *
     * @param component Component to instatiate
     * @param containerName name of container to insert component
     */
    public async instantiateComponent<T>(component: Type<T>, containerName: string = 'root') {
        return await this.getComponentReference<T>(component, containerName);
    }

    /** Register a new container or replace an exiting one */
    public registerContainer(host: ContainerDirective, replace: boolean = false): boolean {
        if (!replace && this._containerStore[host.name]) {
            return false;
        }

        this._containerStore[host.name] = host;
        this._registerContainer.next(host);
        return true;
    }

    /** Remove container form store */
    public unregisterContainer(name: string): boolean {
        return delete this._containerStore[name];
    }

    /** Return current container source */
    public getContainerStore(): ContainerStore {
        return this._containerStore;
    }

    /** Get container by name, it will wait for the container to be avaible */
    public getContainer(name: string): Promise<ContainerDirective> {
        const container = this._containerStore[name];
        if (container) {
            return Promise.resolve(container);
        }

        return new Promise(resolve => {
            this._registerContainer
                .pipe(filter(e => !!(e && e.name === name)), first())
                .subscribe(e => resolve(e));
        });
    }

    /** Clear container */
    public clearContainer(name: string): void {
        const container = this._containerStore[name];
        container?.viewContainerRef.clear();
    }

    private async getComponentReference<T>(type: Type<T>, containerName: string): Promise<ComponentRef<T>> {
        const container = await this.getContainer(containerName);

        /*
        Because ExtJs runs outside ngZone
        we are required to manually trigger for changes
        */
        container.triggerRender();

        return container.viewContainerRef.createComponent(type);
    }
}
