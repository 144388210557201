import {
    IwColumn
} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppacattestview')
export class Ppacattestview {
    @IwRestEntityId() @IwColumn({index: 1, name: 'id'}) public acaId?: string;
    @IwColumn({index: 0, name: 'date', type: 'date'}) public dateaca?: string;
    @IwColumn({index: 4, name: 'limite'}) public salIdLim?: string;
    @IwColumn({index: 2, name: 'nomemploye'}) public nomemploye?: string;
    @IwColumn({index: 3, name: 'empId'}) public empId?: string;
}
