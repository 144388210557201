import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('sadsview')
export class Sadsview {
    @IwRestEntityId() public dsId?: string;
    public empId?: string;
    @IwColumn() public gsId?: string;
    public montant?: number;
    public frequence?: number;
    public debut?: Date;
    public fin?: Date;
    public gsCtype?: number;
    public titre?: string;
}
