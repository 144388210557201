import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppsmiolog')
export class Ppsmiolog {
    @IwRestEntityId() public smioId?: string;
    public userCreat?: string;
    public ageTo?: string;
    public processed?: string;
    public ageFrom?: string;
    public dateend?: string;
    public userModif?: string;
    public smiodir?: string;
    public userId?: string;
    public datestart?: string;
    public nrecords?: string;
    public imporexp?: string;
    public userDelet?: string;
    public smiomode?: string;
}
