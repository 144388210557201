import {IwColumn} from '@app/sam-base/core/column';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppmisindihview')
export class PpminIndihView {
    @IwColumn({index: 5, name: 'id'}) @IwRestEntityId() public misihId?: string;
    public misId?: string;
    public cdeormis?: string;
    public indorheu?: string;
    public griId?: string;
    @IwColumn({index: 0, name: 'libelle'}) public libelle?: string;
    @IwColumn({index: 1, name: 'saisie', type: 'translate'}) public timeframe?: string;
    public qte?: string;
    @IwColumn({index: 2, name: 'mnt_emp'}) public mntemp?: string;
    @IwColumn({index: 3, name: 'mnt_cli'}) public mntcli?: string;
    @IwColumn({index: 4, name: 'coeff'}) public cliTempRacio?: string;
}
