import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppquacat')
export class Ppquacat {
    @IwRestEntityId() public quacatId?: string;
    public userModif?: string;
    public quacatLib?: string;
    public userDelet?: string;
    public cctId?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public quagrpId?: string;
    public userCreat?: string;
    public quacData?: string;
    public dateCreat?: string;
}
