import {ComponentRef, EventEmitter, Injectable, Type} from '@angular/core';

import {BaseFormComponent, BaseSideFormComponent} from '../../base';
import {FormHostDialogComponent} from '../../components/form-handler/dialog/form-host-dialog.component';
import {Dictionary, FormComponentMetadata} from '../../models';
import {ViewContainerRefService} from '../services/view-container-ref.service';
import {GlobalFormState, IwStoreService} from '../store';
import {Initialize} from '../store/actions/global-form.actions';
import {UuidService} from '../uuid/uuid.service';
import {FormCollection, FormDescription} from './models';
import {FormHostData} from './models/form-host-data.model';

type StateGenerator<T> = (state: GlobalFormState<T>) => GlobalFormState<T>;
type ValidComponents<T> = FormHostDialogComponent<T> | BaseFormComponent<T> | BaseSideFormComponent<T>;

export interface ComponentStore {
    [key: string]: FormComponentMetadata;
}

/**
 * Contains the registered forms and allow to inject a new one
 * Used to show a new form
 */
@Injectable()
export class FormHandlerService<TKey extends string> {

    public store = new EventEmitter<ComponentStore>();

    private _components: ComponentStore = {};
    /** Key-value store */
    private _store: Dictionary<FormDescription<any>> = {};

    constructor(private readonly _storeService: IwStoreService, private readonly _uuidGenerator: UuidService, private readonly _viewHost: ViewContainerRefService) {
    }

    public registerComponents(store: ComponentStore) {
        this._components = {
            ...this._components, ...store
        };
        this.store.emit(this._components);
    }

    /** Register a collection of FormDescription */
    public registerForms(formCollection: FormCollection<TKey>) {
        for (const key in formCollection) {
            if (formCollection[key]) {
                this.register<any>(key, formCollection[key]);
            }
        }
    }

    /** Register a new form configuration */
    public register<K>(key: TKey, value: FormDescription<K>) {
        this._store[key] = value;
    }

    /** Load a form description */
    public load<K>(key: TKey): FormDescription<K> {
        const desc = this._store[key];
        if (desc) {
            return desc;
        }
        throw new Error('Not form description found for: ' + key);
    }

    /** Clear container */
    public clearContainer(name: string): void {
        this._viewHost.clearContainer(name);
    }

    /** True, if a description is found for an entity */
    public findFormDescription(key: TKey) {
        return !!this._store[key];
    }

    /**
     * Initialize a form state and show a new dialog for that form
     *
     * @param key key that references the form
     * @param data extra data to include in the form
     * @param stateGenerator state modifier
     */
    public async showFormDialog<TEntityType>(key: TKey, data?: Dictionary<any>, stateGenerator?: StateGenerator<TEntityType>) {
        // Get form descriptor
        const desc = this.load<TEntityType>(key);
        // Generate a copy of the state
        const state = stateGenerator ? stateGenerator({...desc.state}) : {...desc.state};
        // Generate a uuid and set it in state
        const uuid = this.getUuid(state);
        state.uuid = uuid;

        // Trigger GlobalFormState init
        this._storeService.dispatch(new Initialize<TEntityType>(uuid, state));

        // Build form host data
        const formData: FormHostData<TEntityType> = {
            ...desc, state, data: data || {}
        };
        //  Instantiate form host component
        const formDiagRef = await this.instantiate<TEntityType, FormHostDialogComponent<TEntityType>>(FormHostDialogComponent, uuid, data || {});
        // Set form host data
        formDiagRef.instance.hostData = formData;

        //  Instantiate main form
        await this.instantiate(desc.form, uuid, data, uuid);
        //  Instantiate side menu
        if (desc.sideMenu) {
            this.instantiate(desc.sideMenu, uuid, data, 'side-' + uuid);
        }
    }

    /**
     *  Instantiate a component and set the UUID and data
     *
     * @param component Component to  instantiate
     * @param uuid UUID to use
     * @param data extra data params to pass
     * @param container container name
     */
    public async instantiate<TData, TComponent extends ValidComponents<TData>>(component: Type<TComponent>, uuid: string, data?: Dictionary<TData>, container?: string): Promise<ComponentRef<TComponent>> {
        const ref = await this._viewHost.instantiateComponent(component, container);

        ref.instance.reference = ref;
        ref.instance.setData(data || {});
        ref.instance.uuid = uuid;

        return ref;
    }

    private getUuid<TEntityType>(state: GlobalFormState<TEntityType>): string {
        if (state.uuid && state.uuid.length > 0) {
            return state.uuid;
        }

        const uuid = this._uuidGenerator.generateString();
        return uuid;
    }
}
