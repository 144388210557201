import {Component, Type} from '@angular/core';
import {BaseEntityDropdown} from '@app/sam-base/base/base-entity-dropdown';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {Frxlist} from '@app/sam-base/models/common/frxlist';

@Component({
    selector: 'iw-frxmis-combo-box',
    templateUrl: './frxmis-combo-box.component.html',
    providers: [buildCustomValueProvider(FrxmisComboBoxComponent)]
})
export class FrxmisComboBoxComponent extends BaseEntityDropdown<Frxlist> {

    public selectedValue = undefined;

    constructor() {
        super();
        this.valueChange.subscribe((val: string) => {
            this.updateSelectedValue(val);
        });
        this.resultFilters = (e: Frxlist) => e.frxtype === 'MIS';
    }

    public updateSelectedValue(value?: string) {
        if (value === undefined) {
            this.selectedValue = undefined;
        }
    }

    protected getEntityType(): Type<Frxlist> {
        return Frxlist;
    }

    protected buildLabel(e: Frxlist) {
        return `${e.frxname} ${e.frxfile}`;
    }
}
