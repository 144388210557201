import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppempsui')
export class Ppempsui {
    @IwRestEntityId() public empsuiId?: string;
    public action?: string;
    public rappelflag?: string;
    public empId?: string;
    public notes?: string;
    public daterappel?: string;
    public userId?: string;
    public conId?: string;
    public dateaction?: string;
    public cdfId?: string;
    public rappeldone?: string;
    public cdeId?: string;
    public suitype?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
