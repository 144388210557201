import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

import {ERROR} from '@app/sam-base/core/logger/logger';
import {getEntityMetadata} from '@app/sam-base/core/rest-api';
import {ToastService} from '@app/sam-base/core/toast';
import {OptionalDictionary} from '@app/sam-base/models';
import {environment} from '@root/environments/environment';
import {saveAs} from 'file-saver';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PrintFormat, PrintReportEntity} from './models/print-entity';

@Injectable()
export class ReportPrintService {
    constructor(private readonly _toast: ToastService, private readonly _httpService: HttpClient) {
    }

    public print<T>(c: PrintReportEntity<T>) {
        try {
            const metadata = getEntityMetadata(c.entity);
            const entityName = metadata.$entity;
            const id = c.entityId || metadata.$getPk();
            return this.printEntityReport(c.template.templateName, c.format, entityName, id, c.parameters);
        } catch (err) {
            ERROR('error_print_report', err);
            return of(false);
        }
    }

    public printEntityReport(templateName: string, format: 'xls' | 'pdf', entityName: string, id?: string | number, params: OptionalDictionary<string | number> = {}): Observable<boolean> {
        try {
            const idParam = id ? `/${id}` : '';

            // eslint-disable-next-line max-len
            const url = `/api/print/${entityName}${idParam}/${format}?templateName=${templateName}`;
            return this._httpService
                .post(url, params, {responseType: 'arraybuffer'})
                .pipe(map((r) => {
                    const name = `${templateName}.${format}`;
                    saveAs(new Blob([r], {type: 'application/pdf'}), name);
                    return true;
                }), catchError(() => of(false)));
        } catch (err) {
            ERROR('error_print_report', err);
            return of(false);
        }
    }

    public printReport(templateName: string, format: PrintFormat, parameters: any): Observable<boolean> {
        // eslint-disable-next-line max-len
        const url = `${environment.backendURL}/print/${format}?templateName=${templateName}`;
        try {
            return this._httpService
                .post(url, {parameters}, {responseType: 'arraybuffer'})
                .pipe(map(r => {
                    const name = `${templateName}.${format}`;
                    saveAs(new Blob([r], {type: 'application/pdf'}), name);
                    return true;
                }), catchError(() => of(false)));
        } catch (err) {
            ERROR('error_print_report', err);
            return of(false);
        }
    }
}
