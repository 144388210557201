import {Injectable} from '@angular/core';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';

import {Lookups} from '../../../models/common';
import {RestApiService, RestEntityClient} from '../../rest-api';
import {ToastService} from '../../toast/toast.service';
import * as actions from '../actions/lookups.actions';

@Injectable()
export class LookupsEffects {

    private readonly _restClient: RestEntityClient<Lookups>;
    // fixme ULTIMATE - operations was this._restClient.getRefData()
    public loadLookupsEffects$ = createEffect(() => this._actions.pipe(ofType(actions.LOOKUPS_LOAD), mergeMap(action => this._restClient.getRefDataPerms()
        .pipe(catchError(err => {
            const message = action.type.toLocaleLowerCase()
                .replace(/ /g, '_');
            ERROR(message, err);
            this._toastService.error(message);
            // eslint-disable-next-line no-console
            console.error(message, err);
            return [];
        }), map(result => new actions.LoadLookupsSuccess(result))))));

    constructor(private readonly _actions: Actions<actions.LookupsActions>, private readonly _toastService: ToastService, public readonly rest: RestApiService) {
        this._restClient = rest.getEntityClient(Lookups);
    }
}
