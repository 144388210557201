import {Injectable, Type} from '@angular/core';

import {IwStoreService} from '../store';
import {isSearchOptions, SearchOptions, toSearchGridNavigateAction} from './search-navigation.model';

@Injectable()
export class SearchNavigationService {
    constructor(private readonly _store: IwStoreService) {
    }

    /**
     * Navigate to a entity search grid
     *
     * @template T type of entity
     * @template K type of extras
     * @param {SearchOptions<T, K>} options options to use
     * @memberof SearchNavigationService
     */
    public navigate<T, K extends {}>(options: SearchOptions<T, K>): void;
    /**
     * Navigate to a entity search grid
     *
     * @template T type of entity
     * @param {Type<T>} type type of grid to show
     * @memberof SearchNavigationService
     */
    public navigate<T>(type: Type<T>): void;
    public navigate<T, K extends {}>(input: Type<T> | SearchOptions<T, K>): void {
        // Convert input to search options
        const navData = isSearchOptions<T, K>(input) ? input : {entity: input};
        this._store.dispatch(toSearchGridNavigateAction(navData));
    }
}
