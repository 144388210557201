export enum ReportOutputType {
    Excel = 'xlsx', Html = 'html', Pdf = 'pdf',
}

type IwReportContentType = {
    [key in ReportOutputType]: string;
};

// eslint-disable-next-line
export const IwReportContentType: IwReportContentType = {
    [ReportOutputType.Excel]: 'application/vnd.ms-excel',
    [ReportOutputType.Html]: 'text/html',
    [ReportOutputType.Pdf]: 'application/pdf'
};
