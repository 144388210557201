import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {HotkeyService} from './hotkey.service';

@NgModule({
    imports: [CommonModule], providers: [HotkeyService]
})
export class HotkeyModule {
}
