/* eslint-disable max-classes-per-file */
import {Action} from '@ngrx/store';

import {GridProfile} from '../../grid-profile/models';
import {GlobalFormState} from '../models';

// ######################################################################
// ######################## INIT FORM STATE #############################
// ######################################################################

/** Initialize a new form */
export const GLOBAL_FORM_INIT = '[GLOBAL FORM] initialize form';
export const GLOBAL_FORM_INIT_WAITING = '[GLOBAL FORM] init waiting mode';
export const GLOBAL_FORM_INIT_WAIT_LOADING = '[GLOBAL FORM] init wait loading mode';

/** Initialize the form state and trigger the entity load */
export class Initialize<T> implements Action {
    public readonly type = GLOBAL_FORM_INIT;

    /**
     * Initialize the form state and trigger the entity load
     *
     * @param state initial form state
     */
    constructor(public readonly uuid: string, public readonly state: GlobalFormState<T>) {
    }
}

/**
 * Form stays in a pause state, user can not edit or submit while not in new
 * or while does not navigate to entityId
 */
export class InitializeWaiting implements Action {
    public readonly type = GLOBAL_FORM_INIT_WAITING;

    constructor(public readonly uuid: string) {
    }
}

/**
 * Form stays in a pause state, user can not edit or submit while not in new
 * or while does not navigate to entityId. The form also stays loading
 */
export class InitializeWaitLoading implements Action {
    public readonly type = GLOBAL_FORM_INIT_WAIT_LOADING;

    constructor(public readonly uuid: string) {
    }
}

// ######################################################################
// ###################### Navigation State ##############################
// ######################################################################

export const GLOBAL_FORM_NAVIGATION_INIT = '[GLOBAL FORM] navigation init';
export const GLOBAL_FORM_NAVIGATION_INIT_SUCCESS = '[GLOBAL FORM] navigation init SUCCESS';

export const GLOBAL_FORM_NAVIGATION_NEXT = '[GLOBAL FORM] navigation move next';
export const GLOBAL_FORM_NAVIGATION_PREV = '[GLOBAL FORM] navigation move prev';
export const GLOBAL_FORM_NAVIGATION_FIRST = '[GLOBAL FORM] navigation move first';
export const GLOBAL_FORM_NAVIGATION_LAST = '[GLOBAL FORM] navigation move last';

export const GLOBAL_FORM_NAVIGATION_ENTITY = '[GLOBAL FORM] navigation jump to entity id';
export const GLOBAL_FORM_NAVIGATION_GOTO_POSITION = '[GLOBAL FORM] navigation jump to position';

export const GLOBAL_FORM_NAVIGATION_FAIL = '[GLOBAL FORM] navigation fail';

/** Trigger the load of the navigation entites */
export class NavigationInit<T> implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_INIT;

    constructor(public readonly uuid: string, public readonly profile?: GridProfile<T>, public readonly entityId?: string | number) {
    }
}

/** Action when load of the navigation entites is successfully */
export class NavigationInitSuccess implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_INIT_SUCCESS;

    constructor(public readonly uuid: string, public readonly result: Array<string | number>) {
    }
}

export class NavigationPrev implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_PREV;

    constructor(public readonly uuid: string) {
    }
}

export class NavigationNext implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_NEXT;

    constructor(public readonly uuid: string) {
    }
}

export class NavigationFirst implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_FIRST;

    constructor(public readonly uuid: string) {
    }
}

export class NavigationLast implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_LAST;

    constructor(public readonly uuid: string) {
    }
}

/** Jump to a specific entity id */
export class NavigationEntity implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_ENTITY;

    constructor(public readonly uuid: string, public readonly id: string | number) {
    }
}

/** Jump to a specific position in the navigation */
export class NavigationPosition implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_GOTO_POSITION;

    constructor(public readonly uuid: string, public readonly position: number) {
    }
}

/** Generic error on navigation */
export class NavigationFail implements Action {
    public readonly type = GLOBAL_FORM_NAVIGATION_FAIL;

    constructor(public readonly uuid: string, public readonly errorKey: string) {
    }
}

// ######################################################################
// ####################### Form mode state ##############################
// ######################################################################

export const GLOBAL_FORM_SET_VALID = '[GLOBAL FORM] set form to save valid/invalid';
export const GLOBAL_FORM_SET_WAIT_MODE = '[GLOBAL FORM] set form to wait mode';
export const GLOBAL_FORM_SET_WAIT_MODE_WITH_DATA = '[GLOBAL FORM] set form to wait mode with data';
export const GLOBAL_FORM_SET_READ_MODE = '[GLOBAL FORM] set form to read mode';
export const GLOBAL_FORM_SET_EDIT_MODE = '[GLOBAL FORM] set form to edit mode';
export const GLOBAL_FORM_SET_EDIT_MODE_PRINT_TEMPLATE = '[GLOBAL FORM] set form to edit mode print tenplate';
export const GLOBAL_FORM_SET_NEW_MODE = '[GLOBAL FORM] set form to new mode';
export const GLOBAL_FORM_SET_LOADING = '[GLOBAL FORM] change form to loading state';
export const GLOBAL_FORM_SET_READONLY = '[GLOBAL FORM] change form to readonly state';
export const GLOBAL_FORM_SET_WIDTH = '[GLOBAL FORM] set form width';
export const GLOBAL_FORM_SET_CUSTOM_ENTITY_URL = '[GLOBAL FORM] set custom entity url';
export const GLOBAL_FORM_DESTROY = '[GLOBAL FORM] destroy form';

/** Change the current mode to READ */
export class SetReadMode implements Action {
    public readonly type = GLOBAL_FORM_SET_READ_MODE;

    constructor(public readonly uuid: string) {
    }
}

/** Change the current mode to WAIT */
export class SetWaitMode implements Action {
    public readonly type = GLOBAL_FORM_SET_WAIT_MODE;

    constructor(public readonly uuid: string) {
    }
}

/** Change the current mode to WAIT */
export class SetWaitModeWithData implements Action {
    public readonly type = GLOBAL_FORM_SET_WAIT_MODE_WITH_DATA;

    constructor(public readonly uuid: string) {
    }
}

/** Change the current mode to EDIT */
export class SetEditMode implements Action {
    public readonly type = GLOBAL_FORM_SET_EDIT_MODE;

    constructor(public readonly uuid: string) {
    }
}

export class SetEditModePrintTemplate implements Action {
    public readonly type = GLOBAL_FORM_SET_EDIT_MODE_PRINT_TEMPLATE;

    constructor(public readonly uuid: string) {
    }
}

/** Change the current mode to NEW */
export class SetNewMode implements Action {
    public readonly type = GLOBAL_FORM_SET_NEW_MODE;

    constructor(public readonly uuid: string) {
    }
}

/** Change the loading state */
export class SetLoading implements Action {
    public readonly type = GLOBAL_FORM_SET_LOADING;

    constructor(public readonly uuid: string, public readonly loading: boolean) {
    }
}

/** Change the save valid state */
export class SetValid implements Action {
    public readonly type = GLOBAL_FORM_SET_VALID;

    constructor(public readonly uuid: string, public readonly isValid: boolean) {
    }
}

/** Set the form width */
export class SetWidth implements Action {
    public readonly type = GLOBAL_FORM_SET_WIDTH;

    constructor(public readonly uuid: string, public readonly width: number) {
    }
}

/** Set the custom requestURL */
export class SetCustomEntityURL implements Action {
    public readonly type = GLOBAL_FORM_SET_CUSTOM_ENTITY_URL;

    constructor(public readonly uuid: string, public readonly customEntityURL: string) {
    }
}

/** Change the readonly state */
export class SetReadonly implements Action {
    public readonly type = GLOBAL_FORM_SET_READONLY;

    constructor(public readonly uuid: string, public readonly readonly: boolean) {
    }
}

/** Remove form from story */
export class DestroyForm implements Action {
    public readonly type = GLOBAL_FORM_DESTROY;

    constructor(public readonly uuid: string) {
    }
}

// ######################################################################
// ########################## Entity CRUD ###############################
// ######################################################################

export const GLOBAL_FORM_ENTITY_UPDATE = '[GLOBAL FORM] update entity';
export const GLOBAL_FORM_ENTITY_RESET = '[GLOBAL FORM] reset entity';
export const GLOBAL_FORM_ENTITY_SAVE = '[GLOBAL FORM] save entity';
export const GLOBAL_FORM_ENTITY_SAVE_SUCCESS = '[GLOBAL FORM] save entity success';
export const GLOBAL_FORM_ENTITY_DELETE = '[GLOBAL FORM] delete entity';
export const GLOBAL_FORM_ENTITY_SAVE_NEW = '[GLOBAL FORM] save new entity';
export const GLOBAL_FORM_ENTITY_SAVE_NEW_SUCCESS = '[GLOBAL FORM] save new entity success';
export const GLOBAL_FORM_ERROR = '[GLOBAL FORM] error';
export const GLOBAL_FORM_ENTITY_LOAD = '[GLOBAL FORM] load current entity';
export const GLOBAL_FORM_ENTITY_LOAD_SUCCESS = '[GLOBAL FORM] load entity id success';
export const GLOBAL_FORM_ENTITY_LOAD_FAIL = '[GLOBAL FORM] load entity id failed';

/** Update the value in the store for the current entity */
export class UpdateEntity<T> implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_UPDATE;

    constructor(public readonly uuid: string, public readonly value: T, public readonly patch = false) {
    }
}

/** Request the value from database and reset current one */
export class ResetEntity implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_RESET;

    constructor(public readonly uuid: string) {
    }
}

/** Save current entity to database */
export class SaveEntity implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_SAVE;

    /**
     * Save current entity to database
     *
     * @param uuid form id
     * @param patch use PATCH instead of PUT
     */
    constructor(public readonly uuid: string, public readonly patch: boolean = false) {
    }
}

/** Trigger save success */
export class SaveEntitySuccess<T> implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_SAVE_SUCCESS;

    /**
     * Trigger save success
     *
     * @param uuid form id
     * @param entity
     */
    constructor(public readonly uuid: string, public readonly entity: T) {
    }
}

/** Delete current entity to database */
export class DeleteEntity implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_DELETE;

    /**
     * Save current entity to database
     *
     * @param uuid form id
     */
    constructor(public readonly uuid: string) {
    }
}

/** Create new entity */
export class SaveNewEntity implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_SAVE_NEW;

    /**
     * Create current entity in database
     *
     * @param uuid form id
     */
    constructor(public readonly uuid: string) {
    }
}

/** Create new entity was successfully */
export class SaveNewEntitySuccess<T> implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_SAVE_NEW_SUCCESS;

    constructor(public readonly uuid: string, public readonly entityId: string | number, public readonly entity: T) {
    }
}

/** Trigger loading of current entity */
export class LoadEntity implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_LOAD;

    constructor(public readonly uuid: string) {
    }
}

/** Jump to a specific entity id successed */
export class LoadEntitySuccess<T> implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_LOAD_SUCCESS;

    constructor(public readonly uuid: string, public readonly value: T) {
    }
}

export class LoadEntityFailed<T> implements Action {
    public readonly type = GLOBAL_FORM_ENTITY_LOAD_FAIL;

    constructor(public readonly uuid: string) {
    }
}

/** Log a new error in form */
export class TriggerError implements Action {
    public readonly type = GLOBAL_FORM_ERROR;

    constructor(public readonly uuid: string, public readonly code: string) {
    }
}

// ######################################################################
// ######################## Global Actions ##############################
// ######################################################################

export type GlobalFormActions<T> =
    | Initialize<T>
    | InitializeWaiting
    | InitializeWaitLoading
    | NavigationInit<T>
    | NavigationInitSuccess
    | NavigationPrev
    | NavigationNext
    | NavigationFirst
    | NavigationLast
    | NavigationEntity
    | LoadEntity
    | LoadEntitySuccess<T>
    | LoadEntityFailed<T>
    | NavigationPosition
    | NavigationFail
    | SetValid
    | SetWaitMode
    | SetWaitModeWithData
    | SetReadMode
    | SetEditMode
    | SetNewMode
    | SetLoading
    | SetWidth
    | SetCustomEntityURL
    | SetReadonly
    | UpdateEntity<T>
    | SaveEntity
    | SaveEntitySuccess<T>
    | DeleteEntity
    | SaveNewEntity
    | SaveNewEntitySuccess<T>
    | ResetEntity
    | TriggerError
    | DestroyForm;
