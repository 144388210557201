import {Component, Input, Type, ViewChild} from '@angular/core';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {getEntityMetadata} from '@app/sam-base/core/rest-api';
import {RestQueryParam} from '@app/sam-base/core/rest-api/models';
import {EntityDelete, FormEditMode} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {Subscription} from 'rxjs';

/**
 * Component that has a list of entities and a add, edit and remove buttons.
 * It uses the store to list the entities and edit them
 */

@Component({
    selector: 'iw-table-edit', templateUrl: './iw-table-edit.component.html'
})
export class IwTableEditComponent<T> {

    /** Defines the value of the css property, height */
    @Input() public height = '100%';

    /** If true, show only button icons */
    @Input() public buttonIcons = false;

    /** Defines if user can save */
    @Input() public saveValid = true;

    /**
     * Propery name to send to the form
     * If [DEFAULT], the value is got form the @IwRestId prop
     */
    @Input() public propId: keyof T | 'DEFAULT' = 'DEFAULT';

    /** Query to filter results */
    @Input() public query: RestQueryParam<T, any>[] = [];
    @ViewChild('grid', {static: true}) public grid?: IwRestGridComponent<T>;
    /** True, if is new entity */
    private _isNew = false;
    /** Selected entityId */
    private _selectedId: string | number | undefined;
    /** Subscription to form state */
    private _subs: Subscription[] = [];

    // ##########################################################################
    constructor(private readonly _store: IwStoreService) {
    }

    /** Read-only, css style of container */
    public get style() {
        return {height: this.height};
    }

    /** Read-only styles of the buttons container */
    public get buttonStyle() {
        return {'min-width': this.buttonIcons ? '50px' : '120px'};
    }

    /** True, if is in write mode */
    public get isEditMode() {
        return this._isNew || this._formMode === 'edit';
    }

    public get btnDeleteDisabled() {
        return !this._selectedId;
    }

    public get btnEditDisabled() {
        return !this._selectedId;
    }

    /** Form id to trigger edit/read status and to set the entity id */
    private _formId?: string;

    public get formId() {
        return this._formId;
    }

    /** Form id in the store, used to emit events */
    @Input()
    public set formId(t: string | undefined) {
        this.setFormId(t);
    }

    /** Entity to list */
    private _entity?: Type<T>;

    public get entity() {
        return this._entity;
    }

    /** Entity to use in list */
    @Input()
    public set entity(t: Type<T> | undefined) {
        this.setEntity(t);
    }

    /** Current form edit mode */
    private _formMode: FormEditMode = 'read';

    // ##########################################################################
    // ########################## CONSTRUCTOR ###################################

    /** Current form status, read-only */
    public get formMode() {
        return this._formMode;
    }

    // ##########################################################################

    /** On item select */
    public onSelect(item: T[]) {
        const main = item[0];
        const id = this.getEntityIdValue(main);
        if (id !== this._selectedId) {
            this._selectedId = id;
            this.goToEntity(id);
        }
    }

    /** On create button press */
    public onCreateClick() {
        if (this._formId) {
            this._store.dispatch(new actions.SetNewMode(this._formId));
        }
        this.clearSelection();
    }

    public onSaveClick() {
        if (this._formId) {
            this._store.dispatch(new actions.SaveEntity(this._formId));
        }
    }

    public onCancelClick() {
        if (this._formId) {
            if (this._isNew) {
                this._store.dispatch(new actions.SetWaitMode(this._formId));
            } else {
                this._store.dispatch(new actions.SetReadMode(this._formId));
            }
        }
    }

    public onEditClick() {
        if (this._formId) {
            this._store.dispatch(new actions.SetEditMode(this._formId));
        }
    }

    public onDeleteClick() {
        if (this._selectedId && this._formId && this._entity) {
            this._store.dispatch(new EntityDelete(this._entity, this._selectedId));
            this._store.dispatch(new actions.SetWaitMode(this._formId));
            this._selectedId = undefined;
        }
    }

    /** Navigate to selected entity */
    private goToEntity(id: string | number | undefined) {
        if (!this._formId) {
            return;
        }
        if (id) {
            this._store.dispatch(new actions.NavigationEntity(this._formId, id));
        } else if (id === undefined && !this._isNew) {
            this._store.dispatch(new actions.SetWaitMode(this._formId));
        }
    }

    /** Defines the current entity */
    private setEntity(t: Type<T> | undefined) {
        if (t !== this._entity) {
            this._entity = t;
        }
    }

    /** Set the formId and update subscriptions */
    private setFormId(id: string | undefined) {
        if (id === this._formId) {
            return;
        }
        if (this._subs) {
            for (const s of this._subs) {
                s.unsubscribe();
            }
        }

        if (id) {
            this._formId = id;
            this._subs = [this._store.globalForm(id)
                .mode
                .subscribe(mode => {
                    this._formMode = mode || 'read';
                }), this._store.globalForm(id)
                .isNew
                .subscribe(n => this._isNew = n)];
        }
    }

    private getEntityIdValue(e: T | undefined): string | number | undefined {
        try {
            if (e) {
                return getEntityMetadata(e)
                    .$getPk();
            }
        } catch {
        }
        return;
    }

    private clearSelection() {
        setTimeout(() => {
            if (this.grid) {
                this.grid.clearSelection();
            }
        }, 0);
    }
}
