<ng-template #template>
    <ng-container *ngIf="!showRowGrouping">
        <iw-table-body-row (checkboxClick)="onCheckboxClick($event)"
                           (contextClick)="onContextClick($event)"
                           (previewOver)="onPreviewMouseOver($event)"
                           (rowClick)="onRowClick($event)"
                           (rowDoubleClick)="onRowDoubleClick($event)"
                           [columns]="columns"
                           [hasCheckbox]="hasCheckbox"
                           [isChecked]="isChecked"
                           [isClicked]="isClicked"
                           [items]="items"
                           [rawTableMode]="rawTableMode"
                           [rowIndex]="rowIndex"
                           [rowItem]="rowItem"
                           [showGridDetails]="showGridDetails"></iw-table-body-row>
    </ng-container>
    <ng-container *ngIf="showRowGrouping">
        <tr *ngIf="rowIndex === 0 || showRowGroup()"
            class="table-header">
            <td [attr.colspan]="columns.length">
                <a [pRowToggler]="rowItem"
                   href="#"> <span
                        [ngClass]="isExpanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></span>
                    <span>{{ propToGroupString | translate }}: {{ rowItemValue }} ({{ countRowsValues }})</span> </a>
            </td>
        </tr>
    </ng-container>
</ng-template>
