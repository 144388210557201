<div class="iw-table-config padding-5">
    <div class="columns">
        <div *ngFor="let c of columns" class="column">
            <iw-checkbox (valueChange)="c.hidden = !$event" [id]="c.prop" [label]="(c.name || c.prop) | translate"
                         [value]="!c.hidden"></iw-checkbox>
        </div>
    </div>
    <div class="buttons">
        <iw-button (press)="apply()" [text]="'apply' | translate" class="btn-block"></iw-button>
        <iw-button (press)="reset()" [text]="'reset' | translate" class="btn-block"></iw-button>
    </div>
</div>
