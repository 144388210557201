/**
 * If number returns it, els returns default value
 *
 * @param input number
 * @param defaultValue defgault value to return
 */
// eslint-disable-next-line id-blacklist
export function number(input: unknown, defaultValue = 0): number {
    if (typeof input === 'number' && !Number.isNaN(input)) {
        return input;
    }

    return defaultValue;
}

/**
 * Return a number with a set fracion decimals size
 *  5 -> 5.00
 * when input is invalid number, 0.00 is returned
 *
 * @export
 * @param input value to use
 * @param fraction number of fration decimals
 * @returns
 */
export function toFixedNumber(input: unknown, fraction: number) {
    return number(Number(input))
        .toFixed(fraction);
}
