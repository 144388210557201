import {trimString} from './string';

// ############################################################
// ###############  Help functions to handle paths ############
// ############################################################

/**
 * Build a path from multiple parts
 * (a, b, c) => a/b/c
 *
 * @param parts parts of path
 */
export function joinPath(...parts: string[]): string {
    const path = parts
        .map(e => trimString(e, '/'))
        .filter(e => !!e) // Remove empty values
        .join('/');

    return (parts[0] && parts[0][0] === '/') ? '/' + path : path;
}
