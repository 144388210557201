import {Component, ElementRef} from '@angular/core';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Ppcha} from '@app/sam-base/models/placement/ppcha';
import {PpchaComponent} from '@shared/widgets/modal-components/ppcha/ppcha.component';
import {map} from 'rxjs/operators';

import {IwMultiautocompleteSelectComponent} from './iw-multiautocomplete-select.component';

@Component({
    selector: 'iw-multiautocomplete-chantiers',
    templateUrl: './iw-multiautocomplete-select.component.html',
    providers: [buildCustomValueProvider(IwMultiautocompleteChantiersComponent)]
})
export class IwMultiautocompleteChantiersComponent extends IwMultiautocompleteSelectComponent<Ppcha> {

    constructor(elRef: ElementRef, _restService: RestApiService, _modalService: ModalService) {
        super(elRef, _restService, _modalService);
        this.entityType = Ppcha;
        this.propToFilter = 'chantier';
        this.labelAlign = 'top';
        this.fetchAction = () => _restService.getEntityClient(Ppcha)
            .getRefData()
            .pipe(map(chantiers => chantiers
                .filter(cha => cha.cliId === this.multiSelectInputs.cliId)), map(chantiers => [...chantiers].sort((a, b) => this.sortChantiers(a, b))));
        this.listOption = {
            columns: [{prop: 'chantier'}], map: (e: Ppcha) => e, selection: 'single', fetchAction: this.fetchAction
        };
        this.multiSelectComponent = PpchaComponent;
    }

    private sortChantiers(a: Ppcha, b: Ppcha) {
        return (a.lieu || '') < (b.lieu || '') ? -1 : 1;
    }
}
