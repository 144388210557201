import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseFormControl} from '@app/sam-base/base/base-form-control';

import {Timeslot} from './timeslot';

@Component({
    selector: 'iw-time-slots', templateUrl: './iw-time-slots.component.html'
})
export class IwTimeSlotsComponent extends BaseFormControl<number[]> {
    @Input() public isAvaiability?: boolean;

    @Output() public timeSlotsUpdated = new EventEmitter<(0 | 1)[]>();
    public timeSlots: Timeslot[] = [];
    public isMouseDownMode = false;
    public selectedHours: (1 | 0)[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    constructor() {
        super();
        this.iniatilizeTimeslots();

    }

    public get style() {
        if (!this.isDisabled) {
            return;
        }
        return {
            opacity: 0.8
        };
    }

    public get allSelected() {
        return this.selectedHours.every(e => e === 1);
    }

    /** Updates selected hours each time child timeslot is changed */
    public updateSelectedHours(timeslot: Timeslot, index: number) {
        this.selectedHours[index] = timeslot.active ? 1 : 0;
        this.timeSlotsUpdated.emit(this.selectedHours);
    }

    /** Sets selected hours from parent */
    public setSelectedHours(selectedHours?: (1 | 0)[]) {
        if (selectedHours) {
            this.selectedHours = selectedHours;
        }
        this.timeSlots.forEach((ts, i) => ts.active = this.selectedHours[i] === 1);
        this.timeSlotsUpdated.emit(this.selectedHours);
    }

    /** Selects or diselects all */
    public toggleAll() {
        if (this.allSelected) {
            this.selectedHours = this.selectedHours.map(e => 0);
        } else {
            this.selectedHours = this.selectedHours.map(e => 1);
        }
        this.setSelectedHours();
    }

    public selectAllHours() {
        this.selectedHours = this.selectedHours.map(e => 1);
        this.setSelectedHours();
    }

    public reset() {
        this.selectedHours = this.selectedHours.map(e => 0);
        this.timeSlotsUpdated.emit(this.selectedHours);
        this.timeSlots?.forEach((ts) => ts.active = false);
    }

    public onMouseDownEvent() {
        this.isMouseDownMode = true;
    }

    public onMouseUpEvent() {
        this.isMouseDownMode = false;
    }

    public onMouseLeave() {
        this.isMouseDownMode = false;
    }

    private iniatilizeTimeslots() {
        for (let i = 0; i < 24; i++) {
            this.timeSlots.push({label: i, active: false});
        }
    }
}
