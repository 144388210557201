import {
    ChangeDetectionStrategy, Component, Input, OnInit
} from '@angular/core';
import {
    buildCustomValueProvider
} from '@app/sam-base/base/build-value-provider';

@Component({
    selector: 'iw-cell-status-missions',
    templateUrl: './iw-cell-status.component.html',
    providers: [buildCustomValueProvider(IwCellStatusMissionsComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IwCellStatusMissionsComponent implements OnInit {

    @Input() public row?: { misstatus: number; ccnbofprt: number; cmnbofprt: number };

    @Input() public color = '#80ff80'; // Default color

    @Input() public value = '';

    private _ccnbofprt?: number;
    private _cmnbofprt?: number;

    constructor() {
    }

    public ngOnInit() {
        if (this.row && (this.row.misstatus !== undefined)) {
            this._ccnbofprt = this.row.ccnbofprt;
            this._cmnbofprt = this.row.cmnbofprt;
            this.checkColor(this.row.misstatus);
        }
    }

    // eslint-disable-next-line complexity
    public checkColor(status: number) {
        if (status <= 1) {
            if (this._ccnbofprt === 0 || this._cmnbofprt === 0) {
                this.color = '#0080ff';
            } else {
                this.color = '#008000';
            }
        } else if (status === 2) {
            this.color = '#80ff80';
        } else if (status === 3) {
            this.color = '#ff8000';
        } else if (status === 8) {
            this.color = '#ffff80';
        } else {
            this.color = '#C0C0C0';
        }
    }
}
