import {SamUserRole} from '@sam-base/models/admin/sam-user';

//PCA: TDDO: add ULTIMATE ROLES

/** Helper functions */
function isAcompte(entity: string): boolean {
    return entity === 'ppaco';
}

function isIndemnite(entity: string): boolean {
    return entity === 'ppind';
}

function isEmpComMisPlacf(entity: string): boolean {
    // eslint-disable-next-line complexity
    return (entity === 'ppemp' ||
        entity === 'ppclicdfs' ||
        entity === 'ppclicdes' ||
        entity === 'ppclimis' ||
        entity === 'ppplf' ||
        entity === 'ppclimis' ||
        entity === 'ppagi' ||
        entity === 'ppacattest' ||
        entity === 'pprap' ||
        entity === 'pprapview');
}

function isClient(entity: string): boolean {
    // eslint-disable-next-line complexity
    return (entity === 'ppcli');
}

function isFacture(entity: string): boolean {
    // eslint-disable-next-line complexity
    return (entity === 'gefachdr' || entity === 'gecliview' || entity === 'gefacliview');
}

function isSalaire(entity: string): boolean {
    // eslint-disable-next-line complexity
    return (entity === 'saemsview' || entity === 'saemp' || entity === 'saempc' || entity === 'saempview');

}

function isConfSalaire(entity: string): boolean {
    // eslint-disable-next-line complexity
    return (entity === 'sags' || entity === 'sapardedview');

}


//PCA: TDDO: add ULTIMATE ROLES
export function createEntityAccessRoles(entityName: string): string[] {
    if (isAcompte(entityName)) {
        return [
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_PLACEMENT_ADMIN,
            SamUserRole.SAM_PLACEMENT_RW,
            SamUserRole.SAM_PLACEMENT_ACOMPTE_ADMIN];
    } else if (isIndemnite(entityName)) {
        return [
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_PLACEMENT_ADMIN,
            SamUserRole.SAM_PLACEMENT_RW];
    } else if (isEmpComMisPlacf(entityName)) {
        return [
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_PLACEMENT_ADMIN,
            SamUserRole.SAM_PLACEMENT_RW,
            SamUserRole.SAM_PLACEMENT_CLIENT_ADMIN];
    } else if (isClient(entityName)) {
        return [
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_PLACEMENT_ADMIN,
            SamUserRole.SAM_PLACEMENT_RW];
    } else if (isFacture(entityName)) {
        return [
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_FACTURE_ADMIN,
            SamUserRole.SAM_FACTURE_RW
        ];
    } else if (isSalaire(entityName)) {
        return [
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_SALAIRE_ADMIN,
            SamUserRole.SAM_SALAIRE_RW
        ];
    } else if (isConfSalaire(entityName)) {
        return [
            SamUserRole.SAM_ADMIN_GOD_MODE,
            SamUserRole.SAM_SALAIRE_ADMIN
        ];
    }
    return Object.values(SamUserRole);
}

/**
 * Defines the roles that can modify the entity from the entity form
 *
 * @param entity Name of the entity
 */
// eslint-disable-next-line complexity
//PCA: TDDO: add ULTIMATE ROLES
export function canModifyEntityAccessRoles(entity?: string): string[] {
    switch (entity) {
        case 'ppplf':
        case 'ppclicdes':
        case 'ppclicdfs':
        case 'ppemp':
        case 'ppagi':
        case 'ppacattest':
        case 'pprap':
        case 'pprapview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW];
        case 'ppclimis':
        case 'ppcli':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_CLIENT_ADMIN
            ];
        case 'ppaco':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_ACOMPTE_ADMIN];
        case 'saemsview':
        case "saemp":
        case "saempc":
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_SALAIRE_ADMIN,
                SamUserRole.SAM_SALAIRE_RW];
        case 'gefachdr':
        case 'gecliview':
        case 'gefacliview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_FACTURE_ADMIN,
                SamUserRole.SAM_FACTURE_RW];
        case 'sags':
        case 'sapar':
        case 'sapardedview':

            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_SALAIRE_ADMIN];
        default:
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_FACTURE_ADMIN,
                SamUserRole.SAM_SALAIRE_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_FACTURE_RW,
                SamUserRole.SAM_SALAIRE_RW];

    }
}

/**
 * Defines the roles that can print the entity from the entity form
 *
 * @param entity Name of the entity
 */
// eslint-disable-next-line complexity
//PCA: TDDO: add ULTIMATE ROLES
export function canPrintEntityAccessRoles(entity?: string): string[] {
    switch (entity) {
        case 'ppplf':
        case 'ppclicdes':
        case 'ppclicdfs':
        case 'ppemp':
        case 'ppclimis':
        case 'ppcli':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_PRINT];
        case 'saemsview':
        case "saemp":
        case "saempc":
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_SALAIRE_PRINT];
        case 'gefachdr':
        case 'gefachdrview':
        case 'gecli':
        case 'gecliview':
        case 'gefacliview':
        case 'gefacli':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_FACTURE_PRINT];
        default:
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE];
    }
}

/**
 * Defines the roles that can delete the entity from the entity form
 *
 * @param entity Name of the entity
 */
// eslint-disable-next-line complexity
//PCA: TDDO: add ULTIMATE ROLES
export function canDeleteEntityAccessRoles(entity?: string): string[] {
    switch (entity) {
        case 'ppemp':
        case 'ppclicdes':
        case 'ppclicdfs':
        case 'ppplf':
        case 'ppagi':
        case 'ppacattest':
        case 'pprap':
        case 'pprapview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW];
        case 'ppclimis':
        case 'ppcli':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_CLIENT_ADMIN
            ];
        case 'ppaco':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_ACOMPTE_ADMIN];
        //PCA: TDDO: add ULTIMATE ROLES
        case 'saemsview':
        case "saemp":
        case "saempc":
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_SALAIRE_ADMIN,
                SamUserRole.SAM_SALAIRE_RW];
        case 'gefachdr':
        case 'gefacliview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_FACTURE_DELETE];

        case 'sags':
        case 'sapar':
        case 'sapardedview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_SALAIRE_ADMIN];
        default:
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_FACTURE_ADMIN,
                SamUserRole.SAM_SALAIRE_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_FACTURE_RW,
                SamUserRole.SAM_SALAIRE_RW];
    }
}

// eslint-disable-next-line complexity
//PCA: TDDO: add ULTIMATE ROLES
export function showGridDetailsAccessRoles(entityName: string): string[] {
    switch (entityName) {
        case 'ppemp':
        case 'ppind':
        case 'ppclicdes':
        case 'ppclicdfs':
        case 'ppagiview':
        case 'ppacattestview':
        case 'pprap':
        case 'pprapview':
            return [
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_RO];
        case 'ppclimis':
        case 'ppcli':
            return [
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_RO,
                SamUserRole.SAM_PLACEMENT_CLIENT_ADMIN];
        case 'ppaco':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_ACOMPTE_ADMIN];
        case 'saemsview':
        case 'saems':
        case "saemp":
        case "saempview":
        case "saempc":
        case 'sags':
        case 'sapar':
        case 'sapardedview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_SALAIRE_ADMIN,
                SamUserRole.SAM_SALAIRE_RW,
                SamUserRole.SAM_SALAIRE_RO];
        case 'gefachdr':
        case 'gefachdrview':
        case 'gefacliview':
        case 'gecliatt':
        case 'gecli':
        case 'gecliview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_FACTURE_ADMIN,
                SamUserRole.SAM_FACTURE_RW,
                SamUserRole.SAM_FACTURE_RO];
        //PCA: TDDO: add ULTIMATE ROLES
        default:
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_FACTURE_ADMIN,
                SamUserRole.SAM_SALAIRE_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_FACTURE_RW,
                SamUserRole.SAM_SALAIRE_RW,
                SamUserRole.SAM_PLACEMENT_RO,
                SamUserRole.SAM_FACTURE_RO,
                SamUserRole.SAM_SALAIRE_RO];
    }
}


/** RULES */
/**
 * Defines the roles that can open the entity forms from the main grids
 *
 * @param entityName Name of the entity
 */

export function entityFormAccessRoles(entityName?: string): string[] {
    switch (entityName) {
        case 'ppemp':
        case 'ppsuivi' :
        case 'ppclicdes':
        case 'ppclicdfs':
        case 'ppplf':
        case 'ppclimis':
        case 'ppagi':
        case 'ppacattest':
        case 'pprap':
        case 'pprapview':
        case 'ppagiview':
        case 'ppacattestview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_RO];
        case 'ppcli':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_RO,
                SamUserRole.SAM_PLACEMENT_CLIENT_ADMIN]
                ;
        case 'ppaco':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_PLACEMENT_RO];

        case 'saemsview':
        case 'saems':
        case "saemp":
        case "saempview":
        case "saempc":
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_SALAIRE_ADMIN,
                SamUserRole.SAM_SALAIRE_RW,
                SamUserRole.SAM_SALAIRE_RO,
                SamUserRole.SAM_SALAIRE_COM,
                SamUserRole.SAM_SALAIRE_PRINT];
        case 'sags':
        case 'sapar':
        case 'sapardedview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_SALAIRE_ADMIN];
        case 'gefachdr':
        case 'gefachdrview':
        case 'gefacliview':
        case 'gecliview':
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_FACTURE_ADMIN,
                SamUserRole.SAM_FACTURE_RW,
                SamUserRole.SAM_FACTURE_RO,
                SamUserRole.SAM_FACTURE_COM,
                SamUserRole.SAM_FACTURE_PRINT,
                SamUserRole.SAM_FACTURE_DELETE];
        //PCA: TDDO: add ULTIMATE ROLES
        default:
            return [
                SamUserRole.SAM_ADMIN_GOD_MODE,
                SamUserRole.SAM_PLACEMENT_ADMIN,
                SamUserRole.SAM_FACTURE_ADMIN,
                SamUserRole.SAM_SALAIRE_ADMIN,
                SamUserRole.SAM_PLACEMENT_RW,
                SamUserRole.SAM_FACTURE_RW,
                SamUserRole.SAM_SALAIRE_RW,
                SamUserRole.SAM_PLACEMENT_RO,
                SamUserRole.SAM_FACTURE_RO,
                SamUserRole.SAM_SALAIRE_RO];
    }
}