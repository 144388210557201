import {
    IwColumn
} from '@app/sam-base/core/column/decorators/iw-column.decorator';
import {
    IwRestEntity, IwRestEntityId
} from '@app/sam-base/core/rest-api';

@IwRestEntity('ppattestemp')
export class Ppacattest {
    @IwRestEntityId() @IwColumn({index: 0, name: 'id'}) public acaId?: string;
    public datePrt?: string;
    @IwColumn({index: 1, name: 'date', type: 'date'}) public acaData?: string;
    public empNom?: string;
    public dateGen?: string;
    public modecreat?: string;
    public acaInfo?: string;
    public acaxmldata?: string;
    public acatype?: string;
    public pdfname?: string;
    public nbofprint?: number;
    public salIdLim?: string;
    public dateaca?: string;
    public dtanymodif?: string;
    public firstMis?: string;
    public userId?: string;
    public pdftplname?: string;
    public empId?: string;
    public lastMis?: string;
    public acastatus?: number;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}
