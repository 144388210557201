import {ModuleWithProviders, NgModule} from '@angular/core';

import {EnvironmentModel} from './environment';
// eslint-disable-next-line
import {EnvironmentService} from './environment.service';

@NgModule()
export class EnvironmentModule {
    public static forRoot(env?: Partial<EnvironmentModel>): ModuleWithProviders<EnvironmentModule> {
        return {
            ngModule: EnvironmentModule, providers: [{
                provide: EnvironmentService, useValue: EnvironmentService.forEnv(env)
            }]
        };
    }
}
